import { format } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux';

import { searchFilterBoardData } from '../../../../../actions';
import axios from '../../../../../util/Api';

class SearchBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boardData: [],
            content: ""
        }
        this.timeout = 0
    }

    componentDidMount() {
        this.setState({ boardData: this.props.boardData.storeData })
        // if (this.props.boardData.boardID !== '') {
        //     axios.get(`/workspace/getWorkspaceBoardById/${this.props.boardData.boardID}`).then(({ data }) => {
        //         this.setState({ boardData: data.workspaceBoard.group })
        //     }).catch(err => {
        //         console.log("err", err)
        //     })
        // }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("prevProps===>", prevProps.boardData.storeData)
        if (prevProps.boardData.boardID !== this.props.boardData.boardID) {
            this.setState({ content: "" })
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.log("nextProps===>", nextProps.boardData.storeData)
        this.setState({ boardData: nextProps.boardData.storeData })

        if (this.props.boardData.boardID !== '') {
            // axios.get(`/workspace/getWorkspaceBoardById/${this.props.boardData.boardID}`).then(({ data }) => {
            //     this.setState({ boardData: JSON.parse(data.workspaceBoard.group) })
            // }).catch(err => {
            //     console.log("err", err)
            // })

            this.setState({ boardData: nextProps.groups })
        }
    }

    // searchStart = () => {
    //     axios.get(`/workspace/getWorkspaceBoardById/${this.props.boardData.boardID}`).then(({ data }) => {            
    //         this.setState({ boardData: JSON.parse(data.workspaceBoard.group) })
    //     }).catch(err => {
    //         console.log("err", err)
    //     })
    // }

    searchBoardGroup = (event) => {
        if (this.state.content !== "") {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                let newBoardData = this.getSearchBoardGroupData();
                // console.log("new boardData===>", newBoardData)
                this.props.searchFilterBoardData({
                    boardData: newBoardData
                })
            }, 600);
        } else {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.props.searchFilterBoardData({
                    boardData: this.state.boardData
                })
            }, 600);
        }
    }

    getSearchBoardGroupData = () => {
        let newBoardGroups = [];
        let boardData = this.state.boardData;
         console.log("boardData===>", boardData)
        let text = this.state.content
        for (let i = 0; i < boardData.length; i++) {
            let grouptitle = boardData[i].label.toLowerCase().search(text.toLowerCase());
            if (grouptitle !== -1) {
                newBoardGroups.push(boardData[i]);

            } else {
                let newGroupItems = [];
                let groupItems = boardData[i].groupItems;
                for (let j = 0; j < groupItems.length; j++) {
                    if (groupItems[j].category === 'header') {
                        newGroupItems.push(groupItems[j]);
                    } else {
                        let groupItemIitle = groupItems[j].title.toLowerCase().search(text.toLowerCase());
                        if (groupItemIitle !== -1) {
                            newGroupItems.push(groupItems[j]);
                        } else {
                            let cellItems = groupItems[j].cellItems;
                            let flag = false;
                            for (let k = 0; k < cellItems.length; k++) {
                                if (cellItems[k].key === 'tags' || cellItems[k].key === 'dropdown') {
                                    let labels = JSON.parse(cellItems[k].body);
                                    for (let h = 0; h < labels.length; h++) {
                                        let n = labels[h].name.toLowerCase().search(text.toLowerCase());
                                        if (n !== -1) {
                                            flag = true;
                                        }
                                    }
                                } else if (cellItems[k].key === 'people') {
                                    let labels = JSON.parse(cellItems[k].body);
                                    console.log("labels", labels)
                                    for (let h = 0; h < labels.length; h++) {
                                        console.log("labels[h].name:", labels[h].name)
                                        let n = labels[h].name.toLowerCase().search(text.toLowerCase());
                                        console.log("n:", n)
                                        if (n !== -1) {
                                            flag = true;
                                            break;
                                        }
                                    }
                                } else if (cellItems[k].key === 'numbers') {
                                    if (cellItems[k].body !== "" && parseInt(cellItems[k].body) === parseInt(text)) {
                                        flag = true;
                                        // console.log("parseInt(cellItems[k].body)", parseInt(cellItems[k].body));
                                        // console.log("parseInt(text)", parseInt(text))
                                    }
                                } else if (cellItems[k].key === 'date') {
                                    if (cellItems[k].body !== "") {
                                        let body = format(new Date(cellItems[k].body), 'MMM d, y').toLowerCase().search(text.toLowerCase());
                                        if (body !== -1) {
                                            flag = true;
                                        }
                                    }
                                } else if (cellItems[k].key === 'timeline') {
                                    if (cellItems[k].body !== '') {
                                        let timelineArr = cellItems[k].body.split("-");
                                        let startDate = timelineArr[0];
                                        let endDate = timelineArr[1];
                                        let timeline = format(new Date(startDate), 'MMM dd') + ' - ' + format(new Date(endDate), 'MMM dd');
                                        let body = timeline.toLowerCase().search(text.toLowerCase());
                                        if (body !== -1) {
                                            flag = true;
                                        }
                                    }
                                } else if (cellItems[k].key === 'file' || cellItems[k].key === 'autoNumber' || cellItems[k].key === 'lastUpdated') {
                                    flag = false
                                } else {
                                    debugger;
                                    console.log("cellItems[k].key", cellItems[k].key)
                                    let body = String(cellItems[k].body).toLowerCase().search(text.toLowerCase());
                                    if (body !== -1) {
                                        flag = true;
                                    }
                                }

                                if (flag) {
                                    break;
                                }
                            }

                            if (flag) {
                                newGroupItems.push(groupItems[j]);
                            }
                        }
                    }
                }

                if (newGroupItems.length > 1) {
                    let newGroup = boardData[i];
                    newGroup.groupItems = newGroupItems;
                    newBoardGroups.push(newGroup);
                }
            }
        }

        return newBoardGroups;
    }

    render() {
        return (
            <>

 {/* <div
                style={{
                    marginLeft: "20px",
                    display: "inline-flex",
                    marginTop: "3px",
                }}
            >
                <input
                    type="text"
                    placeholder="Search Here . . ."
                    style={{
                        border: "1px solid #C5C5C5",
                        borderRadius: "15px",
                        padding: "1px 0px 0px 10px",
                        fontSize: "13px",
                        marginRight: "10px",
                        marginTop: "4px",
                        width: "350px"
                    }}
                    // onClick={() => this.searchStart()}
                    value={this.state.content}
                    onChange={(event) => this.setState({ content: event.target.value })}
                    onKeyUp={this.searchBoardGroup}
                />
                </div> */}

            

    <div className="search__container">
    <i class="fal fa-search ser-board" style={{ 'position': 'relative' , 'z-index' : '77' , 'top' : '7px' , 'left' : '290px' , 'color' : '#96979a' }}></i>
            <input className="search__input" type="text" placeholder="What are you looking for ?" value={this.state.content}
                    onChange={(event) => this.setState({ content: event.target.value })}
                    onKeyUp={this.searchBoardGroup} />    
    </div>

</>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { searchFilterBoardData };

export default connect(mapStateToProps, mapDispatchToProps)(SearchBoard)