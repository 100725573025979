import { INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_DATA_SET, USER_TOKEN_SET } from "../constants/ActionTypes";

const token = localStorage.getItem('token');
const user = localStorage.getItem('user');

const INIT_STATE = {
   token: token ? JSON.parse(token) : null,
   initURL: '',
   authUser: user ? JSON.parse(user) : null,
};


export default (state = INIT_STATE, action) => {
   switch (action.type) {
      case INIT_URL: {
         return { ...state, initURL: action.payload };
      }

      case SIGNOUT_USER_SUCCESS: {
         return {
            ...state,
            token: null,
            authUser: null,
            initURL: ''
         }
      }

      case USER_DATA: {
         return {
            ...state,
            authUser: action.payload,
         };
      }

      case USER_DATA_SET: {
         return {
            ...state,
            authUser: action.payload.user,
            token: action.payload.token
         }
      }

      case USER_TOKEN_SET: {
         return {
            ...state,
            token: action.payload,
         };
      }

      default:
         return state;
   }
}
