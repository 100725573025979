import React, { useEffect } from 'react';
import { useHistory } from "react-router-dom";
import DiscoverToolbar from './discover-toolbar';
import DiscoverAddCard from './discover-addcard'
import DiscoverCard from './discover-card';
import { useSelector, useDispatch } from "react-redux";
import { getWorkspaces } from "../../actions";
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';


const Discover = props => {
  let history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    const getWorkspace = async () => {

      await dispatch(getWorkspaces());

    };
    getWorkspace();
  }, []);

  const discoverList = useSelector(state => state.discover.discoverWorkspace);

  const handleClick = () => {
    history.push("/discover-workspace",);
  }


  return (
    <>
      <MainMenu link={`discover`} />
      <div id="whole-layout">
        <NavBar />
        <div id="appdata" className='app-content content fixed-top content-lay-out'>
          <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
          <div className="content-wrapper">
            <div id="mySidenav" className="sidenav">
              <a href="#" className="closebtn" onClick={() => {
                document.getElementById("mySidenav").style.width = "0%";
              }}>&times;</a>
            </div>
            <DiscoverToolbar></DiscoverToolbar> 

            <div className="content-wrapper" style={{ marginTop: '30px' }}>
              <div className="content-body">
                <div className="row">
                  <div className="col-12">
                    <div className="card border-0">
                      <nav className="nav">
                        <a className="discover-link" href="/discover" target="_self" style={{ borderTop: '1px solid #000', color: '#000', marginLeft: '50px' }}>Discover</a>

                      </nav>
                      <div className="card-content" style={{ height: '100%', borderRadius: '30px', borderWidth: '10px !important', marginTop: '20px', marginLeft: '1px' }}>
                        <div className="form-group row" id="divdiscovers" style={{ marginLeft: '25px' }}>
                          <DiscoverAddCard parentId={0} parentType={0} discoverName={'Workspace'} discoverType={1} actionType={0} ></DiscoverAddCard>
                          {discoverList.data.length > 0 && discoverList.data.map((item, index) => (
                            <DiscoverCard key={index}  {...item} parentId={0} parentType={0} discoverName={'Workspace'} discoverType={1} isRedirect={true}></DiscoverCard>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Discover;
