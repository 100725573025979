import { getNumberFormat } from ".";
import { calculator } from "./calculator";

export const getBoardGroupFormulaCellItemsArray = (groupItems, columnId) => {
    let newColumnArray = [];
    for (let i = 0; i < groupItems.length; i++) {
        if (groupItems[i].category === 'body') {
            let cellItems = groupItems[i].cellItems;
            let cellItem = cellItems.find(cell => cell.columnId === columnId);
            if (cellItem) {
                let equation = cellItem.equation;
                if (equation === "") {
                    newColumnArray.push(0);
                } else {
                    let activeItems = cellItem.activeItems;
                    let newCellITems = getColumnFormula(cellItems);
                    let _strEqu = equation;
                    if (activeItems !== undefined) {


                        for (let j = 0; j < activeItems.length; j++) {
                            let otherCellItem = newCellITems.find(cell => cell.columnId === activeItems[j].columnId);
                            if (otherCellItem) {
                                if (_strEqu !== undefined) {
                                    _strEqu = _strEqu.replaceAll(`{${activeItems[j].name}}`, `{${otherCellItem.name}}`)
                                }

                            }
                        }
                    }

                    let value = calculatorEquation(_strEqu, newCellITems, groupItems[i], cellItems);
                    let text = value.replaceAll(",", "");
                    newColumnArray.push(text);

                }
            }
        }
    }
    return newColumnArray;
}

export const getBoardFormulaCellItemsArray = (groups, columnId) => {
    let newColumnArray = [];
    for (let i = 0; i < groups.length; i++) {
        let groupItems = groups[i].groupItems;
        for (let j = 0; j < groupItems.length; j++) {
            if (groupItems[j].category === 'body') {
                let cellItems = groupItems[j].cellItems;
                let cellItem = cellItems.find(cell => cell.columnId === columnId);
                if (cellItem) {
                    let equation = cellItem.equation;
                    if (equation === "") {
                        newColumnArray.push(0);
                    } else {
                        let activeItems = cellItem.activeItems;
                        let newCellITems = getColumnFormula(cellItems);
                        let _strEqu = equation;
                        if (activeItems !== undefined) {
                            for (let k = 0; k < activeItems.length; k++) {
                                let otherCellItem = newCellITems.find(cell => cell.columnId === activeItems[k].columnId);
                                if (otherCellItem) {
                                    if(_strEqu !== undefined) {
                                        _strEqu = _strEqu.replaceAll(`{${activeItems[k].name}}`, `{${otherCellItem.name}}`)
                                    }                                    
                                }
                            }
                        }

                        let value = calculatorEquation(_strEqu, newCellITems, groupItems[j], cellItems);
                        let val = value.replaceAll(",", "");
                        newColumnArray.push(val);

                    }
                }
            }
        }
    }

    return newColumnArray;
}

export const getColumnContent = (item) => {
    switch (item.key) {
        case 'status':
            return 'Insert the status label from Status column (defaults to "").'
        case 'date':
            return 'Insert the date from Date column (defaults to "").'
        case 'numbers':
            return 'Insert the numeric value of Numbers1 column (defaults to empty).'
        default:
            return `Insert the value of ${item.body} column (defaults to "").`
    }
}


export const getColumnFormula = (cellItems) => {
    let columns = [];
    let nameColumn = {
        _id: 'formula_0',
        name: 'Name',
        funcType: 'column',
        content: 'Insert the value from Name column (defaults to "")',
        example: '',
        columnId: 'column_0'
    }
    columns.push(nameColumn)
    for (let i = 0; i < cellItems.length; i++) {
        let cellItem = cellItems[i];
        if (cellItem.key !== 'formula') {
            let content = getColumnContent(cellItem);
            let cellColumn = {
                _id: cellItem.columnId,
                name: cellItem.body,
                funcType: 'column',
                content: content,
                example: '',
                columnId: cellItem.columnId
            }
            columns.push(cellColumn)
        }
    }

    return columns;
}

export const calculatorEquation = (strEquation, columns, groupItem, cellItems) => {
    try {
        let _strEquation;
        if(strEquation === undefined) {
             _strEquation = "";
        } else {
            _strEquation = strEquation.replace(/(\r\n|\n|\r)/gm, "");
        }
        
        // console.log("columns", columns)
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].columnId === 'column_0') {
                // let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
                _strEquation = _strEquation.replaceAll(`{${columns[i].name}}`, `"${groupItem.title}"`)
            } else {
                let column = cellItems.find(cell => cell.columnId === columns[i].columnId);
                if (column) {
                    if (column.key === 'numbers') {
                        if (column.body === '') {
                            _strEquation = _strEquation.replaceAll(`{${columns[i].name}}`, `"${column.body}"`)
                        } else {
                            _strEquation = _strEquation.replaceAll(`{${columns[i].name}}`, column.body)
                        }

                    } else {
                        _strEquation = _strEquation.replaceAll(`{${columns[i].name}}`, `"${column.body}"`)
                    }
                }
            }
        }
        // console.log('_strEquation', _strEquation)

        let value = calculator(_strEquation);
        // console.log("value==>", value);

        // console.log('calculator-result---', typeof value);
        if (value === true) {
            return true;
        } else if (value === false) {
            return false;
        } else if (typeof value === 'number' && isNaN(value)) {
            return 'Error';
        } else {
            // console.log("parseFloat(value)", parseFloat(value))
            let match = value.toString().match(/^[+-]?\d+(\.\d+)?$/);
            // console.log("match", match)
            if (match) {
                return getNumberFormat(parseFloat(value));
            } else {
                return value;
            }

        }

    } catch (error) {
        console.log("Calcualtor function===>", error)
        return 'Error'
    }
}
