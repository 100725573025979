import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { formulaFunctions, constants } from '../../../../../constants/formula';
import { getNewGroupsChangedFormula } from '../../../../../util';
import { changeFormulaField, fetchError } from '../../../../../actions';
import { calculatorEquation } from '../../../../../util/bottom';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../../util/permission';

class FormulaItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            formulaTitle: '',
            formulaDesc: '',
            formulaExam: '',
            formulaSyntax: '',
            formularId: '',
            textValue: '',
            selectionStart: 0,
            selectionEnd: 0,
            content: '',
            columns: [],
            direction: 'left',
            unit: 'none',
            actionPermission: 'edit'
        }

        this.dropdownContainer = React.createRef();
        this.textareaRef = React.createRef();
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false });
        }
    }

    componentDidMount() {
        if (this.props.boardData.boardID !== '') {
            let columns = this.getColumnFormula(this.props.boardData.data[0].groupItems[0].cellItems);
            this.setState({ columns: columns });
            let activeItems = [];
            if (this.props.activeItems) {
                activeItems = this.props.activeItems;
            }
            let numbersProperties = this.props.boardData.numbersProperties;
            if (numbersProperties.length > 0) {
                let property = numbersProperties.find(x => x.columnId === this.props.columnId);
                if (property !== undefined) {
                    this.setState({
                        unit: property.unit,
                        direction: property.direction
                    })
                }
            }
            // console.log("this.props.body===>", this.props.equation)
            this.initialEquation(this.props.equation, activeItems, columns);
        }

        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem.cellItems;
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }

        window.addEventListener('click', this.onClickOutsideHandler);
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem.cellItems;
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.boardData.boardID !== '') {
            let numbersProperties = nextProps.boardData.numbersProperties;
            if (numbersProperties.length > 0) {
                let property = numbersProperties.find(x => x.columnId === this.props.columnId);
                if (property !== undefined) {
                    this.setState({
                        unit: property.unit,
                        direction: property.direction
                    })
                }
            }

            let columns = this.getColumnFormula(nextProps.boardData.data[0].groupItems[0].cellItems);
            this.setState({ columns: columns });
            let activeItems = [];
            if (nextProps.activeItems) {
                activeItems = nextProps.activeItems;
            }

            this.initialEquation(nextProps.equation, activeItems, columns);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    openEditor = () => {
        this.setState({ open: !this.state.open })
    }

    initialEquation = (equation, activeItems, cellItems) => {
        if (equation !== '' && equation !== undefined) {
            let _strEqu = equation;
            for (let i = 0; i < activeItems.length; i++) {
                let cellItem = cellItems.find(cell => cell.columnId === activeItems[i].columnId);
                if (cellItem) {
                    _strEqu = _strEqu.replaceAll(`{${activeItems[i].name}}`, `{${cellItem.name}}`)
                }
            }
            this.calculator(_strEqu, cellItems);
            this.setState({ textValue: _strEqu })
        }
    }

    getColumnFormula = (cellItems) => {
        let columns = [];
        let nameColumn = {
            _id: 'formula_0',
            name: 'Name',
            funcType: 'column',
            content: 'Insert the value from Name column (defaults to "")',
            example: '',
            columnId: 'column_0'
        }
        columns.push(nameColumn)
        for (let i = 0; i < cellItems.length; i++) {
            let cellItem = cellItems[i];
            if (cellItem.key !== 'formula') {
                let content = this.getColumnContent(cellItem);
                let cellColumn = {
                    _id: cellItem.columnId,
                    name: cellItem.body,
                    funcType: 'column',
                    content: content,
                    example: '',
                    columnId: cellItem.columnId
                }
                columns.push(cellColumn)
            }
        }

        return columns;
    }

    getColumnContent = (item) => {
        switch (item.key) {
            case 'status':
                return 'Insert the status label from Status column (defaults to "").'
            case 'date':
                return 'Insert the date from Date column (defaults to "").'
            case 'numbers':
                return 'Insert the numeric value of Numbers1 column (defaults to empty).'
            default:
                return `Insert the value of ${item.body} column (defaults to "").`
        }
    }

    showFormulaDesc = (formulaFunction) => {
        let formulaSyntax = formulaFunction.syntax ? formulaFunction.syntax : '';
        this.setState({
            formulaTitle: formulaFunction.name,
            formulaSyntax: formulaSyntax,
            formulaExam: formulaFunction.example,
            formulaDesc: formulaFunction.content,
            formularId: formulaFunction._id
        })
    }

    changeText = (event) => {

        let selectionStart = event.target.selectionStart;
        let selectionEnd = event.target.selectionEnd;
        this.setState({ textValue: event.target.value, selectionStart: selectionStart, selectionEnd: selectionEnd })
    }

    addFunction = (formulaFunction) => {
        let textValue = this.state.textValue;
        let expression = formulaFunction.expression;
        // console.log("this.state.selectionStart", this.state.selectionStart)
        let newText = textValue.slice(0, this.state.selectionStart) + '' + expression + '' + textValue.slice(this.state.selectionEnd);
        let newPosition = this.state.selectionStart + expression.search('\\(') + 1;
        // console.log("newPosition===>", newPosition);
        this.setState({ textValue: newText, selectionStart: newPosition, selectionEnd: newPosition });

        this.textareaRef.current.focus();

        setTimeout(() => {
            this.textareaRef.current.selectionStart = newPosition;
            this.textareaRef.current.selectionEnd = newPosition;

        }, 1)
    }

    addConstant = (constant) => {
        let textValue = this.state.textValue;
        let expression = constant.expression;
        let newText = textValue.slice(0, this.state.selectionStart) + '' + expression + '' + textValue.slice(this.state.selectionEnd);
        let newPosition = this.state.selectionStart + expression.length;

        this.setState({ textValue: newText, selectionStart: newPosition, selectionEnd: newPosition });

        this.textareaRef.current.focus();

        setTimeout(() => {
            this.textareaRef.current.selectionStart = newPosition;
            this.textareaRef.current.selectionEnd = newPosition;

        }, 1)
    }

    addColumn = (column) => {
        let textValue = this.state.textValue;
        let expression = `{${column.name}}`;

        let newText = textValue.slice(0, this.state.selectionStart) + '' + expression + '' + textValue.slice(this.state.selectionEnd);
        let newPosition = this.state.selectionStart + expression.length;
        this.setState({ textValue: newText, selectionStart: newPosition, selectionEnd: newPosition });

        this.textareaRef.current.focus();

        setTimeout(() => {
            this.textareaRef.current.selectionStart = newPosition;
            this.textareaRef.current.selectionEnd = newPosition;

        }, 1)
    }

    setFormula = async () => {
        // await this.calculator(this.state.textValue);
        let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        let groups = getNewGroupsChangedFormula(this.props.boardData.data, this.props.columnId, this.state.textValue, this.state.columns, groupItem);
        let message = `Formula set as ${this.state.textValue}`
        this.props.changeFormulaField({
            boardID: this.props.boardData.boardID,
            groups: groups,
            message: message
        })
    }

    calculator = (strEquation, columns = this.state.columns) => {
        let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        let cellItems = this.props.cellItems;
        let value = calculatorEquation(strEquation, columns, groupItem, cellItems);
        this.setState({ content: value })
    }

    checkNumber = (string) => {
        let text = string.replaceAll(",", "");
        let match = text.match(/^[+-]?\d+(\.\d+)?$/);

        return match ? true : false;
    }



    render() {
        const columns = this.getColumnFormula(this.props.boardData.data[0].groupItems[0].cellItems);
        // console.log("this.state.content,", this.state.content)
        // console.log('columns', columns);
        let marginTop = -1 * (this.props.rowInd - 1) * 65;
        return (
            <div className="board-cell-item p-1" style={{ 'border' : '1px solid #EAEAEA' ,
            'border-top' : 'none' ,
           ' border-bottom' : 'none' ,
           'border-right' : 'none'
           }} ref={this.dropdownContainer}>
                <div className="board-cell-item-body" onClick={() => {
                    if (this.state.actionPermission === 'edit'  && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                        this.openEditor()
                    } else {
                        this.props.fetchError("You don't have permission. You can only view")
                    }
                }}>
                    {this.state.content === 'Error' ? (
                        <span className='d-flex justify-content-center align-items-center ' style={{ color: '#fff', backgroundColor: '#ff0000', borderRadius: '50%', width: '16px', height: '16px' }}>!</span>
                    ) : this.checkNumber(this.state.content) ? (
                        <>
                            {this.state.direction === 'left' ? (
                                <span style={{ display: this.state.content === '0' ? 'none' : this.state.content === '' ? 'none' : '', fontSize: '16px' }}>
                                    {this.state.unit !== 'none' ? `${ReactHtmlParser(this.state.unit)} ` : ''}  {this.state.content}
                                </span>
                            ) :
                                (
                                    <span style={{ display: this.state.content === '0' ? 'none' : this.state.content === '' ? 'none' : '', fontSize: '16px' }}>
                                        {this.state.content} {this.state.unit !== 'none' ? `${ReactHtmlParser(this.state.unit)}` : ''}
                                    </span>
                                )}
                        </>
                    ) : (
                                <span>{this.state.content}</span>

                            )}
                </div>
                {this.state.open ? (
                    <div className="formula-dropdown" style={{ marginTop: `${marginTop}px` }}>
                        <div className={`dropdown-menu multi-level animate slideIn w-100 ${this.state.open ? 'show' : ''}`} role="menu">
                            <div className="formula-content">
                                <div className="formula-editor p-2">
                                    <div className='row'>
                                        <div className='col-9'>
                                            <textarea className="formula-editor-area" role="12"
                                                ref={this.textareaRef}
                                                value={this.state.textValue}
                                                onChange={this.changeText}
                                                onClick={(event) => {
                                                    const cursorPosition = event.target.selectionStart;
                                                    const selectionEnd = event.target.selectionEnd;
                                                    this.setState({
                                                        selectionStart: cursorPosition,
                                                        selectionEnd: selectionEnd,
                                                    })
                                                }}
                                            />
                                        </div>
                                        <div className='col-3'>
                                            <div className="d-flex justify-content-center align-items-center w-100" style={{ height: '100%' }}>
                                            
<button className='btn btn-primary' onClick={() => this.setFormula()}>
    <i className="fa fa-check-circle" style={{color: '#fff', verticalAlign: 'middle', position: 'relative', top: '0px', right: '3px'}} />
     Set</button>
                                                


                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='row formula-function-details'>
                                    <div className='col-5'>
                                        <ul className="formula-nav p-1">
                                            <li className="nav-item nav-inside-item" style={{ width: '100%' }}>
                                                <div>
                                                    <a className="nav-link collapsed p-1"
                                                        href={`#formula-columns`}
                                                        style={{ width: '100%' }}
                                                        data-toggle="collapse"
                                                        data-target={`#formula-columns`}>
                                                        <i className="fa fa-folder-o"></i> Columns
                                                    </a>
                                                </div>
                                                <div className="collapse show" id="formula-columns" aria-expanded='false'>
                                                    <ul className="flex-column sub-nav pl-3">
                                                        {columns.map((column, index) => (
                                                            <li className={`nav-item nav-inside-item ${this.state.formularId === column._id ? 'active' : ''}`} key={index}
                                                                onMouseEnter={() => this.showFormulaDesc(column)}
                                                                onClick={() => { this.addColumn(column) }}
                                                            >
                                                                {/* <i className="fa fa-bars" aria-hidden="true" style={{ color: '#000', paddingRight: '5px' }}></i> */}
                                                                <span>{column.name}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="nav-item nav-inside-item" style={{ width: '100%' }}>
                                                <div>
                                                    <a className="nav-link collapsed p-1"
                                                        href={`#formula-functions`}
                                                        style={{ width: '100%' }}
                                                        data-toggle="collapse"
                                                        data-target={`#formula-functions`}>
                                                        <i className="fa fa-folder-o"></i> Functions
                                                    </a>
                                                </div>
                                                <div className="collapse show" id="formula-functions" aria-expanded='false'>
                                                    <ul className="flex-column sub-nav pl-3">
                                                        {formulaFunctions.map((formulaFunction, index) => (
                                                            <li className={`nav-item ${this.state.formularId === formulaFunction._id ? 'active' : ''}`} key={index}
                                                                onMouseEnter={() => this.showFormulaDesc(formulaFunction)}
                                                                onClick={() => this.addFunction(formulaFunction)}
                                                            >
                                                                <i className="icon formula" style={{ width: '20px', height: '20px' }}>fx</i>
                                                                <span style={{ fontSize: '12px' }}>{formulaFunction.name}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                            <li className="nav-item nav-inside-item" style={{ width: '100%' }}>
                                                <div>
                                                    <a className="nav-link collapsed p-1"
                                                        href={`#formula-constants`}
                                                        style={{ width: '100%' }}
                                                        data-toggle="collapse"
                                                        data-target={`#formula-constants`}>
                                                        <i className="fa fa-folder-o"></i>  Contants
                                                    </a>
                                                </div>
                                                <div className="collapse show" id="formula-constants" aria-expanded='false'>
                                                    <ul className="flex-column sub-nav pl-3">
                                                        {constants.map((cell, index) => (
                                                            <li className={`nav-item ${this.state.formularId === cell._id ? 'active' : ''}`} key={index}
                                                                onMouseEnter={() => this.showFormulaDesc(cell)}
                                                                onClick={() => this.addConstant(cell)}
                                                            >
                                                                <i className="icon formula" style={{ width: '20px', height: '20px' }}>&#x3C0;</i>
                                                                <span style={{ fontSize: '12px' }}>{cell.name}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-7' style={{ paddingLeft: '0px' }}>
                                        <div className='pr-1'>
                                            <div className="formula-item">
                                                <h5 style={{ display: this.state.formulaTitle === '' ? 'none' : '', marginTop: '15px' }}>{this.state.formulaTitle}</h5>
                                                <p>{this.state.formulaDesc}</p>
                                            </div>
                                            <div className="formula-item" style={{ display: this.state.formulaSyntax === '' ? 'none' : '' }}>
                                                <h5>Syntax:</h5>
                                                <p>{this.state.formulaSyntax}</p>
                                            </div>
                                            <div className="formula-item" style={{ display: this.state.formulaExam === '' ? 'none' : '' }}>
                                                <h5>Examples:</h5>
                                                <p>{this.state.formulaExam}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''}
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeFormulaField, fetchError }

export default connect(mapStateToProps, mapDispatchToProps)(FormulaItem)