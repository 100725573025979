import React, { Component, state, useState } from "react";
import { Link} from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import ReactNotification from "react-notifications-component";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { store } from "react-notifications-component";
import 'react-notifications-component/dist/theme.css'
import config from '../services/config.json';
import http from '../services/http-service';
import notificationDataService from "../../data-services/notification-data-services";
import CellComponent from '../workspace/components/board/body/CellComponent';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
const socket = require("socket.io-client")(config.baseUrlEndPoint);
const Push = require("push.js");
const moment = require('moment');

class EngageNotification extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      id: '',
      userId: '',
      date: new Date(),
      snoozeType: '-1',
      startDate: new Date(),
      snoozeOther: 'none'
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.snoozeHandleChange = this.snoozeHandleChange.bind(this);
    this.authUser = JSON.parse(localStorage.getItem('authUser'));

  }
  isFormValid = () => {
    const { snoozeType } = this.state

    return snoozeType
  }

  handleDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  snoozeHandleChange(event) {
    this.setState({
      snoozeType: event.target.value
    });

    if (event.target.value === '0') {
      this.setState({
        snoozeOther: 'block'
      });

    }
    else {
      this.setState({
        snoozeOther: 'none'
      });
    }
  }





  handleCloseModal = () => {
    this.setState({ isOpen: false });

  };

  
  
  componentDidMount() {


    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
    socket.emit('selfRoom',this?.authUser?._id);

    socket.on('notifications', (data) => {
      store.addNotification({
        title: data.title,
        content: (
          <div className="alert alert-success mb-0 w-100">
            <p className="alert-heading">{data.title} <sub>at <small className="text-white-50">{moment(moment.utc(data.date).toDate()).local().format("DD MMM YYYY | h:mmA")}</small></sub></p>
            <small>{data.description}</small>
            <hr></hr>
            <button className="d-none btn btn-outline-secondary mr-2" onClick={e => { this.handleClick(e, data) }}>Snooze</button>
            <button className="btn btn-danger">Close</button>
          </div>
        ),
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        close: true,
        dismiss: {
          duration: 1000,
          onScreen: false,
          pauseOnHover: true,
          waitForAnimation: false,
          showIcon: true,
          click: true,
          touch: true
        }
      });


      Push.create(data.title, {
        body: data.description,
        icon: data.icon,
        //link: 'https://www.youtube.com/',
        timeout: 4000,
        onClick: function () {
          window.focus();
          this.close();
        },
        vibrate: [200, 100, 200, 100, 200, 100, 200]
      });

    });

    socket.on('rowReminderNotifications', (data) => {
      store.addNotification({
        title: data.title,
        content: (

          <div className="alert bg-white shadow mb-0 w-100">
            <p className="alert-heading"><i class="fa fa-bell faa-ring animated" aria-hidden="true"></i>
              {data.title} <sub>at <small>{moment(moment.utc(data.date).toDate()).local().format("DD MMM YYYY | h:mmA")}</small></sub> <small className="float-right">{data.description}</small></p>

            <div class="table-responsive my-2" style={{ width: '700' }}>
              <table class="mb-0 table table-bordered table-striped">
                <tbody>
                  <tr>
                    {data.cellHeaders
                      .map((item, index) => (
                        <td className="py-2" key={index}>
                          {item.body}
                        </td>))}
                  </tr>
                  <tr className="text-black-50">
                    {data.cellItems
                      .map((item, index) => (
                        <td className="py-3" key={index}>
                          {(item.key === 'status' || item.key === 'text' || item.key === 'timeline' || item.key === 'date') &&
                            item.body
                          }
                          {(item.key === 'reminder') &&

                            <span className="badge badge-dark">{item.body.frequency}</span>
                          }
                          {(item.key === 'people') &&
                            <i class="fa fa-users" aria-hidden="true"> <span className="badge badge-dark">{JSON.parse(item.body).length}</span></i>
                          }
                        </td>))}
                  </tr>
                </tbody>
              </table>
            </div>
            <Link className="btn btn-danger text-white mr-2" //to={'/board/'+data.boardId} 
            to ={{
              pathname: '/board/'+data.boardId, 
              state: { 
                  groupIndex: data.groupInd,
                  rowIndex: data.rowInd
              }
             }}
            >
            Go To Board
            </Link>
            {/* <a className="btn btn-danger mr-2" href={'/board/'+data.boardId}>Go To Board</a> */}

            <button className="d-none btn btn-outline-secondary mr-2" onClick={e => { this.handleOpenModal(e, data) }}>Snooze</button>
            <button className="btn btn-danger">Close</button>
          </div>
        ),
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        close: true,
        dismiss: {
          duration: 100000,
          onScreen: false,
          pauseOnHover: true,
          waitForAnimation: false,
          showIcon: true,
          click: true,
          touch: true
        }
      });


      Push.create(data.title, {
        body: data.description,
        icon: data.icon,
        //link: 'https://www.youtube.com/',
        timeout: 4000,
        onClick: function () {
          window.focus();
          this.close();
        },
        vibrate: [200, 100, 200, 100, 200, 100, 200]
      });

    });

    socket.on('chatReminderNotifications', (data) => {
    
      store.addNotification({
        title: data.title,
        content: (

          <div className="alert bg-white shadow mb-0 w-100">
            <div>
            <p className="alert-heading"><i class="fa fa-bell faa-ring animated"  aria-hidden="true"></i>
              {data.title} <sub>at <small>{moment(moment.utc(data.date).toDate()).local().format("DD MMM YYYY | h:mmA")}</small></sub> </p>
            <p><small> {ReactHtmlParser(data.description)}</small></p>
            </div>
           

            <Link className="btn btn-danger text-white mr-2" 
            //to={'/board/'+data.boardId} 
             to ={{
              pathname: '/board/'+data.boardId, 
              state: { 
                  groupIndex: data.groupInd,
                  rowIndex: data.rowInd,
                  messageID: data.messageID,
                  isChatNotification:true
              }
             }}
            >
            Go To Message
            </Link>
            {/* <a className="btn btn-danger mr-2" href={'/board/'+data.boardId}>Go To Board</a> */}

            <button className="d-none btn btn-outline-secondary mr-2" onClick={e => { this.handleOpenModal(e, data) }}>Snooze</button>
            <button className="btn btn-danger">Close</button>
          </div>
        ),
        type: "success",
        insert: "top",
        container: "bottom-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        close: true,
        dismiss: {
          duration: 1000000,
          onScreen: false,
          pauseOnHover: true,
          waitForAnimation: false,
          showIcon: true,
          click: true,
          touch: true
        }
      });


      Push.create(data.title, {
        body: data.description,
        icon: data.icon,
        //link: 'https://www.youtube.com/',
        timeout: 4000,
        onClick: function () {
          window.focus();
          this.close();
        },
        vibrate: [200, 100, 200, 100, 200, 100, 200]
      });

    });

  }
  onSubmit(e) {
    e.preventDefault();

    var snoozeDelay = moment(this.state.date).seconds(0).milliseconds(0).add(5, 'm').toDate();
    if (this.state.snoozeType !== '0') {
      snoozeDelay = moment(this.state.date).seconds(0).milliseconds(0).add(this.state.snoozeType, 'm').toDate();

    }
    else {
      snoozeDelay = moment(this.state.startDate).seconds(0).milliseconds(0).toDate();
    }

    const snooze = {
      id: this.state.id,
      userId: this.state.userId,
      date: snoozeDelay
    }

    http.post(config.apiEndPoint + notificationDataService.createSnooze(snooze))
      .then(response => {
        this.setState({ isOpen: false, snoozeType: '-1' });
        console.log(response.data);
      }).catch(e => {
        console.log(e);
      });

  }


  render() {

    return (

      <div>
        <ReactNotification />
        <Modal open={this.state.isOpen} onClose={this.handleCloseModal}>
          <form onSubmit={this.onSubmit}>
            <p className="title">Snooze for:</p>

            <ul>
              <li>
                <label>
                  <input
                    type="radio"
                    value="5"
                    checked={this.state.snoozeType === "5"}
                    onChange={this.snoozeHandleChange}
                  />
              5 minute
            </label>
              </li>

              <li>
                <label>
                  <input
                    type="radio"
                    value="10"
                    checked={this.state.snoozeType === "10"}
                    onChange={this.snoozeHandleChange}
                  />
              10 minutes
            </label>
              </li>

              <li>
                <label>
                  <input
                    type="radio"
                    value="15"
                    checked={this.state.snoozeType === "15"}
                    onChange={this.snoozeHandleChange}
                  />
              15 minutes
            </label>
              </li>
              <li>
                <label>
                  <input
                    type="radio"
                    value="30"
                    checked={this.state.snoozeType === "30"}
                    onChange={this.snoozeHandleChange}
                  />
              30 minutes
            </label>
              </li>
              <li>
                <label>
                  <input
                    type="radio"
                    value="60"
                    checked={this.state.snoozeType === "60"}
                    onChange={this.snoozeHandleChange}
                  />
              One hour
            </label>
              </li>
              <li>
                <label>
                  <input
                    type="radio"
                    value="120"
                    checked={this.state.snoozeType === "120"}
                    onChange={this.snoozeHandleChange}
                  />
              Two hours
            </label>
              </li>
              <li>
                <label>
                  <input
                    type="radio"
                    value="1440"
                    checked={this.state.snoozeType === "1440"}
                    onChange={this.snoozeHandleChange}
                  />
              A day
            </label>
              </li>
              <li>
                <label>
                  <input
                    type="radio"
                    value="10080"
                    checked={this.state.snoozeType === "10080"}
                    onChange={this.snoozeHandleChange}
                  />
              A week
            </label>
              </li>
              <li>
                <label>
                  <input
                    type="radio"
                    value="0"
                    checked={this.state.snoozeType === "0"}
                    onChange={this.snoozeHandleChange}
                  />
              Other
            </label>
                <div style={{ display: this.state.snoozeOther ? 'block' : 'none' }}>
                  <DatePicker
                    selected={this.state.startDate}
                    onChange={this.handleDateChange}
                    showTimeSelect timeIntervals={1} dateFormat="Pp" />
                </div>

              </li>

            </ul>

            <input type="submit" value="Snooze" className="btn btn-primary mr-1 mb-1" disabled={this.state.snoozeType.trim() === "-1"} />

          </form>

        </Modal>


      </div>
    );
  }
}

export default EngageNotification;



