import React, { Component } from 'react'
import GlobalGeneralForm from './global-general-form';
import GlobalEmailForm from './global-email-form';
const GlobalContentForm = () => { 

  return ( 
    <>
                <div className="col-md-12">
                <div className="card" style={{ backgroundColor : '#FAFAFA' }}>
                  <div className="card-content" >
                    <div className="card-body">
                      <div className="tab-content">
                          <GlobalGeneralForm ></GlobalGeneralForm>
                          
                          <GlobalEmailForm></GlobalEmailForm>
    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
    
    </>
    
             );
}

export default GlobalContentForm;