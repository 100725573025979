import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import { removeRowItem, duplicateRow, fetchError } from '../../../../../actions';
import { getMyPermission,getMyGroupEditPermission } from '../../../../../util/permission';
import { initialGroupCellItemBody, makeid } from '../../../../../util/index';

class GroupRowEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            color: false,
            isModalOpen: false,
            isNewRowModal: false,
            actionPermission: 'view',
            value:"",
            addRowAfter: true
        }
        this.dropdownContainer = React.createRef();
    }
    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) this.setState({ open: false });
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    duplicateRow = () => {

     
       
            const { groupInd, rowInd, groupId, id } = this.props;
            const boardId = this.props.boardData.boardID;
             const currentRowHeaderData = this.props.boardData.data[0].groupItems[0].cellItems;
             const groupItems = this.props.boardData.data[groupInd].groupItems;
            const title = this.props.boardData.data[groupInd].groupItems[rowInd].title;
           
            const newCellItems = this.props.boardData.data[groupInd].groupItems[rowInd].cellItems;
          

            const newRowItem = {
                _id: makeid('row', 5),
                title: title + " Copy",
                category: 'body',
                cellItems: newCellItems
            }

            this.props.duplicateRow({
                boardId: boardId,
                groupID: groupId,
                newGroupItem: newRowItem,
                groupInd: groupInd,
                rowInd: rowInd+1
            })
            this.setState({ open: false })
          
        
    }

    addNewRow = () => {

        if(this.state.value === ""){
            return;
        }
       
        const { groupInd, rowInd, groupId, id } = this.props;
        const boardId = this.props.boardData.boardID;
         const currentRowHeaderData = this.props.boardData.data[0].groupItems[0].cellItems;
         const groupItems = this.props.boardData.data[groupInd].groupItems;
       // const title = this.props.boardData.data[groupInd].groupItems[rowInd].title;
       
       // const newCellItems = this.props.boardData.data[groupInd].groupItems[rowInd].cellItems;
        const newCellItems = [];
        for (let i = 0; i < currentRowHeaderData.length; i++) {
            let body = initialGroupCellItemBody(currentRowHeaderData[i].key);
            let cellItem = { _id: makeid('col', 7), key: currentRowHeaderData[i].key, body: body, columnId: currentRowHeaderData[i].columnId };
            newCellItems.push(cellItem);
        }

        const newRowItem = {
            _id: makeid('row', 5),
            title: this.state.value,
            category: 'body',
            cellItems: newCellItems
        }

        let rowindex;
    
        if(this.state.addRowAfter){
            rowindex=rowInd+1
        }else{
            if(rowInd===0){
                rowindex=0;
            }else{
                rowindex=rowInd
            }
        }
        this.props.duplicateRow({
            boardId: boardId,
            groupID: groupId,
            newGroupItem: newRowItem,
            groupInd: groupInd,
            rowInd: rowindex
        })
        this.setState({ open: false })
}
    render() {
        return (
            <>      
            <div className="dropdown board-dropdown-ellipsis" ref={this.dropdownContainer} >
                <button className="btn dropdown-toggle" style={{ padding: '3px 3px 2px 0' }} type="button"
                    onClick={() => {
                        if(this.state.actionPermission === 'edit'  && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members)) {
                            this.setState({ open: !this.state.open })
                        } else {
                            this.props.fetchError("You don't have permission. You can only view.")
                        }
                    } }
                    onMouseEnter={() => this.setState({ color: true })}
                    onMouseLeave={() => this.setState({ color: false })}
                >
                    {/* <box-icon name='caret-down-square' style={{ width: '18px', height: '18px' }} color={this.state.color ? `#5a5a5f` : `#d2d2dd`}></box-icon> */}
                    <i className="bx bx-dots-vertical-rounded" style={{ fontSize: '13px', fontWeight : 'bold' , 
                    color: this.state.color ? '#929494fc' : '929494fc' , 'border' : '2px solid #c7c1c1' , 'borderRadius' : '20px' , 'marginTop': '7px' ,
                    'marginLeft' : '9px' }} ></i>
                </button>
                {this.state.open ? (
                    <ul className={`dropdown-menu multi-level animate slideIn board-items-dropdown ${this.state.open ? `show` : ``}`} role="menu" aria-labelledby="dropdownMenu"
                        style={{ marginLeft: '10px', boxShadow: '0 4px 17px 6px rgba(0, 0, 0, 0.1)', width: "264px" }}>
                        <button className="dropdown-item btn-group-action"
                            style={{ paddingRight: '50px' }}
                            onClick={() => {
                                this.setState({ open: false })
                                this.setState({ isModalOpen: true })
                            }}
                        >
                            <i className="fal fa-trash" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }} ></i>Delete</button>

                            <button className="dropdown-item btn-group-action"
                            style={{ paddingRight: '50px' }}
                            onClick={() => {
                                this.duplicateRow()                             
                            }}
                        >
                            <i className="fal fa-copy" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}   ></i>Duplicate Row</button>

                            <button className="dropdown-item btn-group-action"
                            style={{ paddingRight: '50px' }}
                            onClick={() => {
                                this.setState({ open: false })
                                this.setState({ isNewRowModal: true })  
                                this.setState({ value: "" })  
                                this.setState({ addRowAfter: true })                    
                            }}
                        >
                            <i className="fal fa-long-arrow-down" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}   ></i>Add Row After</button>

                            <button className="dropdown-item btn-group-action"
                            style={{ paddingRight: '50px' }}
                            onClick={() => {
                                this.setState({ open: false })
                                this.setState({ isNewRowModal: true })  
                                this.setState({ value: "" })  
                                this.setState({ addRowAfter: false })                    
                            }}
                        >
                            <i className="fal fa-long-arrow-up" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}   ></i>Add Row Before</button>
                    </ul>
                ) : ''}
                <Modal isOpen={this.state.isModalOpen}>
                    <ModalHeader toggle={() => this.setState({ isModalOpen: false })}>
                        Delete this item?
                    </ModalHeader>
                    <ModalFooter>
                        <button className='btn btn-light' onClick={() => { this.setState({ isModalOpen: false }) }}>Cancel</button>{' '}
                        <button className='btn btn-primary'
                            onClick={() => {
                                this.props.removeRowItem({
                                    groupInd: this.props.groupInd,
                                    rowInd: this.props.rowInd,
                                    groupId: this.props.groupId,
                                    groupItemId: this.props.id,
                                    boardId: this.props.boardData.boardID
                                })
                                this.setState({ isModalOpen: false })
                            }}
                        >Delete</button>
                    </ModalFooter>
                </Modal>
           
                
                <Modal isOpen={this.state.isNewRowModal}>
                    <ModalHeader toggle={() => this.setState({ isNewRowModal: false })}>
                        Delete this item?
                    </ModalHeader>
                    <ModalBody>
                    <div class="form-group">
                  
                    <input type="text" class="form-control" 
                        value={this.state.value}                     
                        onChange={(ev) => { this.setState({ value: ev.target.value }) }}
                        placeholder="Row Title"
                    />
                    </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className='btn btn-light' onClick={() => { this.setState({ isNewRowModal: false }) }}>Cancel</button>{' '}
                        <button className='btn btn-primary'
                            onClick={() => {
                                this.addNewRow()
                                this.setState({ isNewRowModal: false })
                            }}
                        >Add Row</button>
                    </ModalFooter>
                </Modal>
           
                
           
            </div>




</>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { removeRowItem,duplicateRow, fetchError };

export default connect(mapStateToProps, mapDispatchToProps)(GroupRowEvent);