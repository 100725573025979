import React from 'react';
import "./style/workspace.css";
import './style/sidebar.css';
import WorkspaceNavbar from './components/workspaceNavBar/WorkspaceNavBar';
import Board from './components/board/board';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

class WorkSpace extends React.Component {
    constructor(props) {
        super(props);

    }
    render() {
        return (
            <React.Fragment>
                <MainMenu link={`board`} />
                <div id="whole-layout">
                    <NavBar />
                    <div id="appdata" className='app-content content fixed-top' style={{
                           position: "relative",
                           top: "76px" , 'max-height' : 'calc(100% - 6rem)' }}
                         >
                        <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
                        <div className="content-wrapper">
                            <div id="mySidenav" className="sidenav">
                                <a href="#" className="closebtn" onClick={() => {
                                    document.getElementById("mySidenav").style.width = "0%";
                                }}>&times;</a>
                            </div>
                            <div className="wrapper d-flex align-items-stretch">
                                <WorkspaceNavbar></WorkspaceNavbar>
                                <Board></Board>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default WorkSpace;