import React from 'react';

function RecentActiveworkspaceWidget(props) {
    const { ShowRecentActiveWorkSpaceWidgetValue, lastUpdated_10_workspace, WorkSpaceTasksCount } = props;
    return (
        <>
            {
                ShowRecentActiveWorkSpaceWidgetValue &&

                <div className="col-md-9 col-lg-9">
                    <div className="" style={{ borderRadius: '15px', boxShadow: '0px 0px 9px 2px #EAEAEA' }}>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2" id="top-section">
                            <div style={{ float: 'left', marginLeft: '15px' }}>
                                <h5 className="left-head">Recent Active WorkSpaces </h5>
                            </div>
                            <div style={{ float: 'right', marginTop: '-2px' }}>
                                <a title="Reply" href="/board">
                                    <span className="hidden-sm-down right-head">View All</span>
                                </a>
                            </div>
                        </div>
                        {lastUpdated_10_workspace.map((workspace, index) => (
                            <a href="/board">
                                <div className="">
                                    <div className="" style={{ display: 'contents' }}>
                                        <div className="main-third">
                                            <div className="" style={{ backgroundColor: workspace.dashboardColor }}>
                                                {
                                                    WorkSpaceTasksCount.map((tasksCount) => (
                                                        <><span className="task-count">{workspace.members.length}</span><br /></>
                                                    ))
                                                }

                                                <span className="task-name" style={{ fontWeight: 500 }}>Members</span>
                                            </div>
                                            <span className="member-name">{workspace.name}</span><br />
                                        </div>
                                    </div>

                                </div>
                            </a>
                        ))
                        }
                    </div>
                </div>
            }
        </>
    );
}
export default RecentActiveworkspaceWidget;