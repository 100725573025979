import React from 'react';
import Select from 'react-select';

const SelectOption = ({ label ,  error , ...rest }) => {
    return (
        <>
            <Select {...rest} />
                <label>{label}<span>*</span></label>
            { error && <div className="alert alert-danger">{error.message}</div>}
        </>
    )
}
export default SelectOption; 


            