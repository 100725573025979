import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import axios from '../../../../util/Api';
import { reloadWorkspace, showMessage } from '../../../../actions';

class WorkspaceFilter extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            workspaceTitle: '',
            filteredSubCats: [],
            filteredNestedCats: [],

        }
        this.filteredCats = []
        this.filteredSubCats = []
        this.filteredNestedCats = []

        this.modalContainer = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        console.log('this.props', this.props)
    }

    handleInputChange = (event) => {

        const cat = event.target
        const catVal = JSON.parse(cat.value)

        if (this.filteredCats ? this.filteredCats.includes(catVal._id) : false) {
            this.filteredCats.pop(catVal._id);
            event.target.checked = false;
            let items = this.state.filteredSubCats
            let nesteditems = this.state.filteredNestedCats
            for (let i = 0; i < catVal.subworkspaces.length; i++) {
              //  this.setState({ filteredSubCats: this.state.filteredSubCats.filter(item => item._id !== catVal.subworkspaces[i]._id ) });

              var index =  items.findIndex(function(o){
                return o._id === catVal.subworkspaces[i]._id;
              })
              if (index !== -1) {               

                  
                  for (let j = 0; j < items[index]?.subworkspaces?.length; j++) {
                      
                    var nestedindex =  nesteditems.findIndex(function(o){
                        return o._id === items[index]?.subworkspaces[j]._id;
                      })
                      if(nestedindex !== -1){
                        nesteditems.splice(nestedindex, 1);
                        this.filteredNestedCats.pop(items[index].subworkspaces[j]._id)
                        this.setState({ filteredNestedCats: nesteditems }); 
                       
                      }
                                                                                                              
                    }  
                    this.filteredSubCats.pop(items[index]._id)
                    items.splice(index, 1);
                }
            this.setState({ filteredSubCats: items });
            
            }
           
        } else {
            this.filteredCats.push(catVal._id);
            event.target.checked = true;
            this.setState({ filteredSubCats: this.state.filteredSubCats.concat(catVal.subworkspaces) });
        }

        this.props._onChange(this.filteredCats,this.filteredSubCats,this.filteredNestedCats);


        //  console.log('hh',this.filteredSubCats)
    }

    handleInputChangeForSubCat = (event) => {

        const cat = event.target
        const catVal = JSON.parse(cat.value)

        if (this.filteredSubCats ? this.filteredSubCats.includes(catVal._id) : false) {
            this.filteredSubCats.pop(catVal._id);
            event.target.checked = false;
            let items = this.state.filteredNestedCats
            this.setState({ filteredNestedCats: []});
            for (let i = 0; i < catVal.subworkspaces.length; i++) {
               // this.setState({ filteredNestedCats: this.state.filteredNestedCats.filter(item => item._id !== catVal.subworkspaces[i]._id ) });
               
               var index =  items.findIndex(function(o){

                    return o._id === catVal.subworkspaces[i]._id;
                  })
                  if (index !== -1) items.splice(index, 1);
                this.setState({ filteredNestedCats: items });
                 
            }
           
        } else {
            this.filteredSubCats.push(catVal._id);
            event.target.checked = true;
            this.setState({ filteredNestedCats: this.state.filteredNestedCats.concat(catVal.subworkspaces) });
           console.log('this',this.state.filteredNestedCats)            
        }

        // if(!(this.state.filteredNestedCats.length>0 && this.state.filteredSubCats.length>0)){
        //     this.filteredSubCats=[];
        //     this.filteredNestedCats=[];
        // }

        this.props._onChange(this.filteredCats,this.filteredSubCats,this.filteredNestedCats);

    }

    handleInputChangeForNestedCat = (event) => {

        const cat = event.target
        const catVal = cat.value

        if (this.filteredNestedCats ? this.filteredNestedCats.includes(catVal) : false) {
            this.filteredNestedCats.pop(catVal);
            event.target.checked = false;
               
        } else {
            this.filteredNestedCats.push(catVal);
            event.target.checked = true;
       }

        this.props._onChange(this.filteredCats,this.filteredSubCats,this.filteredNestedCats);

    }

    clearFilters =()=>{
        let cats = document.getElementsByClassName('form-check-input')
        for (let j = 0; j < cats.length; j++) {
            cats[j].checked =false
        }    
        
        this.setState({ filteredSubCats: [] });
        this.setState({ filteredNestedCats: [] });
        this.filteredCats =[]
        this.filteredSubCats=[]
        this.filteredNestedCats=[]
        this.props._onChange(this.filteredCats,this.filteredSubCats,this.filteredNestedCats);
        
    }

    render() {
        return (
            <div className="dropdown workspace-ellipsis pl-0" style={{ position: 'absolute', top: '25px', right : '50px' }}>

                <button className="btn dropdown-toggle ml-n4" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ display: 'flex' }}>
                    {/* <i id="dot-icon-workspace" className="bx bx-filter" ></i> */}
                    <i className="fal fa-filter category-bars"/>
                </button>

                <ul className="dropdown-menu multi-level animate slideIn left-workspace-filter-dropdown" role="menu" aria-labelledby="dropdownMenu" style={{ marginLeft: '20px', minWidth: this.state.filteredSubCats.length>0?'500px':'200px', 
                paddingLeft: '20px', paddingTop: '20px' , 'overflow-y' : 'auto' , 'height' : 'calc(100vh - 200px)' }}>
                    <div className="row mt-3 mx-0">
                        <div className={this.state.filteredSubCats.length>0?'col-xl-6':'col-12'}>
                            <p class="filter-head">Categories</p>
                            
                            <form>
                                {this.props.categories.map((category, index) =>
                                    <li key={index}>
                                        <div className="form-check">
                                            <label className="form-check-label" style={{ textTransform: 'none'}}>
                                                <input type="checkbox" className="form-check-input"
                                                    type="checkbox"
                                                    //  checked={this.filteredCats ? this.filteredCats.includes(category._id) : false}
                                                    onChange={this.handleInputChange}
                                                    value={JSON.stringify(category)}
                                                    name={category._id} />{category.title}
                                            </label>

                                        </div>

                                    </li>
                                )
                                }
                            </form>
                        </div>
                       {this.state.filteredSubCats.length>0 && <div className="col-xl-6 border-right border-left">
                            <p class="filter-head">Subcategories</p>
                          
                            <form>
                                {this.state.filteredSubCats.map((category, index) =>
                                    <li key={index}>
                                        <div className="form-check">
                                            <label className="form-check-label" style={{ textTransform: 'none'}}>
                                                <input type="checkbox" className="form-check-input"
                                                    type="checkbox"
                                                    //  checked={this.filteredCats ? this.filteredCats.includes(category._id) : false}
                                                    className="subCat"
                                                    onChange={this.handleInputChangeForSubCat}
                                                    value={JSON.stringify(category)}
                                                    name={category._id} /> {category.title}
                                            </label>

                                        </div>

                                    </li>
                                )
                                }
                            </form>


                        </div>}
                        {this.state.filteredNestedCats.length>0 && this.state.filteredSubCats.length>0 && <div className="col-xl-4">
                            <p class="filter-head">Nested categories</p>
                           
                            <form>
                                {this.state.filteredNestedCats.map((category, index) =>
                                    <li key={index}>
                                        <div className="form-check">
                                            <label className="form-check-label" style={{ textTransform: 'none'}}>
                                                <input type="checkbox" className="form-check-input"
                                                    type="checkbox"
                                                    //  checked={this.filteredCats ? this.filteredCats.includes(category._id) : false}
                                                    className="subCat"
                                                    onChange={this.handleInputChangeForNestedCat}
                                                    value={category._id}
                                                    name={category._id} /> {category.title}
                                            </label>

                                        </div>

                                    </li>
                                )
                                }
                            </form>


                        </div>}
                  
                  
                       {this.filteredCats.length > 0 && <div className="col-12">
                                <button 
                                className="btn btn-primary btn-sm float-left"
                                onClick={this.clearFilters}
                                >Clear Filter</button>
                        </div>
                        }
                    </div>


                </ul>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { reloadWorkspace, showMessage }

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceFilter);