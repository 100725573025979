import React from 'react';

function ShowRecentCallHistoryWidget(props) {
	const { ShowRecentCallHistoryWidgetValue } = props;
	return (
		ShowRecentCallHistoryWidgetValue &&
		<div className="col-md-12 border fourth-third-section" style={{ minHeight: "400px", minWidth: "500px" }}>
			<div className="" id="top-section">
				<div style={{ float: 'left', marginLeft: '15px' }}>
					<h5 className="left-head">Recent Call History</h5>
				</div>
				<div style={{ float: 'right', marginTop: '-2px' }}>
					<a title="Reply" data-toggle="modal" data-target="#modal-City">
						<span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
					</a>
				</div>
			</div>
			<div className="col-md-12 recent-call-section d-flex">
				<div className="avatar mr-75">
					<span className="avatar-status-online" />
					<img src="./images/usericon.png" alt="avtar images" style={{ width: '45px', height: '45px' }} />
				</div>
				<div className="align-items-center" style={{ lineHeight: '14px', marginTop: '11px', width: '140px' }}>
					<span style={{ fontSize: '13px', color: '#575756' }}>Marie Jane</span><br />
					<span style={{ fontSize: '10.5px', color: '#b2b2b2' }}>Outgoing 10:12pm</span>
				</div>
				<div className="align-items-center" style={{ lineHeight: '14px', marginTop: '11px' }}>
					<img src="./images/call-button.png" style={{ width: '20px', verticalAlign: 'baseline' }} />
					<i className="bx bx-chevron-right" style={{ color: '#C5C5C5', fontSize: '28px' }} />
				</div>
			</div>
			<div className="col-md-12 recent-call-section d-flex">
				<div className="avatar mr-75">
					<span className="avatar-status-online" />
					<img src="./images/usericon.png" alt="avtar images" style={{ width: '45px', height: '45px' }} />
				</div>
				<div className="align-items-center" style={{ lineHeight: '14px', marginTop: '11px', width: '140px' }}>
					<span style={{ fontSize: '13px', color: '#575756' }}>Benny Varghese</span><br />
					<span style={{ fontSize: '10.5px', color: '#b2b2b2' }}>Missed 08:12pm</span>
				</div>
				<div className="align-items-center" style={{ lineHeight: '14px', marginTop: '11px' }}>
					<img src="./images/video-call.png" style={{ width: '20px', verticalAlign: 'baseline' }} />
					<i className="bx bx-chevron-right" style={{ color: '#C5C5C5', fontSize: '28px' }} />
				</div>
			</div>
		</div>
	);
}

export default ShowRecentCallHistoryWidget;