import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './allrightslide.css'
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import FilterDropdown from './borad-data/header/FilterDropdown';
import BoardData from './borad-data/index'
import axios from '../../util/Api';
import { format } from 'date-fns';
import {
  getTaskManagerData
} from '../../actions/TaskManager'

function OnGoing() {
  //const [resultData, setData] = useState([])
  const dispatch = useDispatch();
  const [seacrhvalue, setSearchValue] = useState('')
  const taskmanager = useSelector(state => state.taskmanager);
  const {resultData,filterData} = taskmanager
  const [filtervalue, setFilterValue] = useState({})
  useEffect(() => {
   dispatch(getTaskManagerData(JSON.parse(localStorage.getItem('authUser'))._id));
  }, [dispatch])

  const setSearchData = (value) => {
    setFilterValue(value)
  }

  const getSearchBoardGroupData = (boardDatas) => {
    let newBoardGroups = [];
    let boardData = boardDatas;
    let text = "Done"
    for (let i = 0; i < boardData.length; i++) {
      let grouptitle = boardData[i].label.toLowerCase().search(text.toLowerCase());
      if (grouptitle !== -1) {
        newBoardGroups.push(boardData[i]);
      } else {
        let newGroupItems = [];
        let groupItems = boardData[i].groupItems;
        for (let j = 0; j < groupItems.length; j++) {
          if (groupItems[j].category === 'header') {
            newGroupItems.push(groupItems[j]);
          } else {
            let groupItemIitle = groupItems[j].title.toLowerCase().search(text.toLowerCase());
            if (groupItemIitle !== -1) {
              newGroupItems.push(groupItems[j]);
            } else {
              let cellItems = groupItems[j].cellItems;
              let flag = true;
              for (let k = 0; k < cellItems.length; k++) {
                if (cellItems[k].key === 'status') {
                  let body = cellItems[k].body.toString().toLowerCase().search(text.toLowerCase());
                  if (body !== -1) {
                    flag = false;
                  }
                }
                if (!flag) {
                  break;
                }
              }
              if (flag) {
                newGroupItems.push(groupItems[j]);
              }
            }
          }
        }
        if (newGroupItems.length > 1) {
          let newGroup = boardData[i];
          newGroup.groupItems = newGroupItems;
          newBoardGroups.push(newGroup);
        }
      }
    }
    return newBoardGroups;
  }

  return (
    <>
      <MainMenu link={`tasks`} />
      <div id="whole-layout">
        <NavBar />
        <div id="appdata" className='app-content content fixed-top content-lay-out'>
          <div class="row">
            <div class="col-md-4">
              <fieldset class="form-label-group mb-0 mt-3 ml-3">
                <input type="text" className="form-control" value={seacrhvalue} onChange={(e) => {
                  setSearchValue(e.target.value)
                }} />
                <label class="user-profile-label">Search in task</label>
              </fieldset>
            </div>
          </div>
          <div className="content-wrapper" style={{ 'padding': '20px' }}>
            {
              resultData && resultData.length &&filterData&&filterData.length && resultData.map((item, index) => {
                let group = JSON.parse(item.group)
                item.group = JSON.stringify(getSearchBoardGroupData(group))

                let filterGroup=filterData[index].group
                let groups = JSON.parse(filterGroup)

                filterData[index].group=JSON.stringify(getSearchBoardGroupData(groups))

                let newfilterGroup=filterData[index].group
                let newgroups = JSON.parse(newfilterGroup)

                return (
                  <>
                  <div class="layout__wrapper">
                    <FilterDropdown groups={newgroups} columns={item.columnHeaders}
                      boardId={item._id} resultData={resultData} filterData={filterData} filterfunc={setSearchData} />
                    <BoardData item={item} index={index} key={item._id} boardId={item._id} filtervalue={filtervalue}
                      columnHeader={item.columnHeaders} searchTerm={seacrhvalue} />
                  </div>
                  </>
                  )
              }
              )
            }
          </div>
        </div>
      </div>

    </>
  )
}

export default OnGoing
