import React from 'react';

class BoardSecondEllipsis extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="dropdown">

            </div>
        )
    }
}

export default BoardSecondEllipsis;