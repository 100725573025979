
import axios from 'axios'
import { toast } from 'react-toastify';
import * as UserConstant from '../../components/auth/constant/users-contant';

axios.defaults.headers.common['authorization'] = localStorage.getItem('token');


axios.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
        config.headers.common['authorization'] = `Bearer ${token}`
    }
    return config;
}, err => {
    Promise.reject(err)
})

axios.interceptors.response.use((config) => {
    return config;
}, error => {
    const ExpectedErrors = error.response && error.response.status >= 400 && error.response.status < 500;
    if (ExpectedErrors) {
        console.log("intyerceptors Called", error);
        toast.error("Some Thing Went Wrong...Please Try Again Or Contact With Support");
        // toast("Some Thing Went Wrong ... Try Again"); 

    } else {
        console.log("intyerceptors Called", error);
        toast.error(error);
        console.log(error);
    }

    return Promise.reject(error);

});

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete

}