export const getAllTagSearch = "tags/getAllTagSearch";
export const getMaxHotTags = "tags/getMaxHotTags";
export const getTagSearchById = "tags/getTagSearchById";
export const saveTagSearch = "tags/saveTagSearch";
export const updateTagSearch = "tags/updateTagSearch";
export const deleteTagSearch = "tags/deleteTagSearch";
export const getTagByName = "tags/getTagByName"
export default {
    getAllTagSearch: getAllTagSearch,
    getMaxHotTags: getMaxHotTags,
    getTagSearchById: getTagSearchById,
    saveTagSearch: saveTagSearch,
    updateTagSearch: updateTagSearch,
    deleteTagSearch: deleteTagSearch,
    getTagByName: getTagByName

}
