import React from 'react';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { changeBoardItem, fetchError } from '../../../../../actions';
import '../../../style/react-datepicker.css'
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../../util/permission';


class DateItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionPermission: 'edit'
        }
    }

    componentDidMount() {
        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem?.cellItems || [];
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem.cellItems;
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    render() {
        const { groupInd, rowInd, colInd, body, _id } = this.props;
        // console.log('date item props========>', this.props)   
        let value = "";
        if (body !== "") {
            value = new Date(body)
        }
        return (
            <div className="date-component">
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <DatePicker
                        selected={value}
                        onChange={date => {
                            if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members) && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                                let formatDate = date.getFullYear() + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + ("0" + date.getDate()).slice(-2);
                                this.props.changeBoardItem({
                                    groupInd: groupInd,
                                    rowInd: rowInd,
                                    colInd: colInd,
                                    body: formatDate,
                                    cellItemID: _id,
                                    boardId: this.props.boardData.boardID
                                })
                            } else {
                                this.props.fetchError("You don't have permission. You can only view")
                            }
                        }}
                        dateFormat="MMM d, y"
                        className='bg-transparent workspace-board'
                    />
                    {value !== '' ? (
                        <div className="date-item-close" onClick={() => {
                            if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members) && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                                this.props.changeBoardItem({
                                    groupInd: groupInd,
                                    rowInd: rowInd,
                                    colInd: colInd,
                                    body: "",
                                    cellItemID: _id,
                                    boardId: this.props.boardData.boardID
                                })
                            } else {
                                this.props.fetchError("You don't have permission. You can only view")
                            }
                        }}>
                            <i className="fa fa-close"></i>
                        </div>
                    ) : ''}

                </div>
            </div>
        )

    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeBoardItem, fetchError };

export default connect(mapStateToProps, mapDispatchToProps)(DateItem);