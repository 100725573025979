import http from '../components/services/http-service';
import axios from '../util/Api';
import db from '../components/tasks/db'

export const getTaskManagerData = (userid) => {
    
    return (dispatch) => {
        db.table('taskmanager')
            .get(userid)
            .then((data) => {
                if (data) {
                    dispatch({
                        type: "ONGETDATA",
                        payload: data
                    })
                    dispatch({
                        type: "ONGETFILTERDATA",
                        payload: data
                    })
                }
            })
            axios.get(`/search/getSearchWorkspaceBoardyById/?value=&id=${userid}`)
            .then(({ data }) => {
                 console.log("getTaskManagerData")
                data.userid=userid
                // console.log("resultData--",data)
                db.table('taskmanager')
                    .put(data)
                    .then(() => {

                        dispatch({
                            type: "ONGETDATA",
                            payload: data
                        })
                        dispatch({
                            type: "ONGETFILTERDATA",
                            payload: data
                        })
                    })

            })
    }
}
export const searchFilterBoardData = (data) => {
    // console.log("ONFILTERGETDATA",data)
    return (dispatch) =>{
        dispatch({
            type: "ONGETDATA",
            payload: data
        })
    }
 }
export const getUnreadTaskManagerData = (userid) => {
    return (dispatch) => {
        db.table('taskmanagerunread')
            .get(userid)
            .then((data) => {
                if (data) {
                    dispatch({
                        type: "ONUNREADGETDATA",
                        payload: data
                    })
                    dispatch({
                        type: "ONGETFILTERDATA",
                        payload: data
                    })
                }
            })
            axios.get(`/search/getUnreadTask/?userid=${userid}`)
            .then(({ data }) => {
                data.userid=userid
                //console.log("resultData--",data)
                db.table('taskmanagerunread')
                    .put(data)
                    .then(() => {
                         
                        dispatch({
                            type: "ONUNREADGETDATA",
                            payload: data
                        })
                        dispatch({
                            type: "ONGETFILTERDATA",
                            payload: data
                        })
                    })

            })
    }
}