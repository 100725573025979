import React from 'react';
import AutoNumberItem from '../items/AutoNumberItem';
import DateItem from '../items/DateItem';
import DropdownItem from '../items/DropdownItem';
import FileItem from '../items/FileItem';
import FormulaItem from '../items/FormulaItem';
import LastUpdatedItem from '../items/lastUpdatedItem';
import LocationItem from '../items/LocationItem';
import LongTextItem from '../items/LongTextItem';
import NumbersItem from '../items/NumbersItem';
import PeopleItem from '../items/PeopleItem';
import ProgressTrackingItem from '../items/ProgressTrackerItem';
import RatingItem from '../items/RatingItem';
import StatusItem from '../items/StatusItem';
import TagsItem from '../items/TagsItem';
import TextItem from '../items/TextItem';
import TimelineItem from '../items/TimelineItem';
import ReminderItem from '../items/ReminderItem';
class CellComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { groupInd, rowInd, colInd, item } = this.props;
        
    

        switch (item.key) {
            case 'status':
                return (
                    <StatusItem 
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'date':
                return (
                    <DateItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'timeline':
                return (
                    <TimelineItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'text':
                return (
                    <TextItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'numbers':
                return (
                    <NumbersItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'tags':
                return (
                    <TagsItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'dropdown':
                return (
                    <DropdownItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'formula':
                return (
                    <FormulaItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'people':
                return (
                    <PeopleItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'file':
                return (
                    <FileItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'autoNumber':
                return (
                    <AutoNumberItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'location':
                return (
                    <LocationItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'rating':
                return (
                    <RatingItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'longText':
                return (
                    <LongTextItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'progressTracking':
                return (
                    <ProgressTrackingItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'lastUpdated':
                return (
                    <LastUpdatedItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} lastUpdated={this.props.lastUpdated} {...item} cellItems={this.props.cellItems} />
                )
                case 'reminder':
                    return (
                        <ReminderItem
                        isWorkspaceOwner={this.props.isWorkspaceOwner}
                        isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                        isBoardAdmin={this.props.isBoardAdmin} 
                        groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                    )
            default:
                return (
                    <div>{item.body}</div>
                    
                );
        }

    }
}

export default CellComponent;

