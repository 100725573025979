import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs,
    onClickTag, onSavedSearchDelete, getHotTags
} from '../../../actions/GlobalSearch'

class MainHotTags extends Component {
    constructor(props) {
        super(props)
        this.onTagClick = this.onTagClick.bind(this)
    }

    onTagClick = (i, value) => {
       // alert(value)
        this.props.onClickTag(i, value)
        
        this.props.onChangeSearch(this.props.search.savedsearch, value,
            this.props.search.userdetails._id, 1,this.props.search.files)
            this.props.fnc(value)
    }
    componentDidMount() {
        this.props.getHotTags(this.props.search.userdetails._id)
    }
    render() {
        return (
            <div class="card-body pt-1" style={{ textAlign: 'left' }}>

                {this.props.search.tags.map((item,index) => (

                    <p style={{ color: item.color }} key={index}>
                        <a onClick={() => this.onTagClick(1, "#"+item.name)}>#{item.name}</a>
                    </p>
                ))
                }
            </div>
        )
    }
}
const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs, onClickTag,
    onSavedSearchDelete, getHotTags
};

export default connect(mapStateToProps, mapDispatchToProps)(MainHotTags);