import React from 'react';
import './css/style.css'; 
import {Link} from 'react-router-dom'; 

function NotFound(props) {
    return (
        <div>
            <div id="notfound">
                <div class="notfound">
                    <div class="notfound-404">
                        <h1>Oops!</h1>
                    </div>
                    <h2>404 - Page not found</h2>
                    <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                    <Link to="/">Go To Home</Link>  
                </div>
            </div>

        </div>
    );
}

export default NotFound;