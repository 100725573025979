import React from 'react';

function RegisterRightSide(props) {
    return (
          <div className="col-md-6 d-md-block h-100 d-none text-center align-self-center p-3" style={{ background: 'linear-gradient(to top left,#e8378c,#4E287A,#24214C)', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px' }}>
            <div className="card-content" style={{ padding: '20px' }}>
              <h2 style={{ textAlign: 'left', color: 'white', position: 'absolute', top: '100px', fontWeight: 400 }}>Where work is<br /> Simplified.</h2>
              <img className="img-fluid" src="/images/icon/auth/Signupbg.png" alt="branding logo" />
            </div>
          </div>

    );
}

export default RegisterRightSide;