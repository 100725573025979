import React, { Component } from 'react';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

class UserList extends Component {
  state = {}
  render() {
    return (

      <>
        <MainMenu link={`userlist`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <div className="row dash-blue fixed-top">
                  <div className="col-md-1 col-lg-1">
                  </div>
                  <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
                    <div>
                      <div className="inner-section">
                        <h3>Master Settings</h3>
                        <div style={{ marginTop: '-10px' }}>
                          <p className="mt-1">User List</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
                    <table className="align-items-center table-dashboard-section-one">
                      <tbody><tr>
                        <td rowSpan={2} style={{ width: '300px', textAlign: 'left' }}>
                          <input className="search-dashboard" type="text" name="search" placeholder="Search Here.." />
                        </td>
                      </tr>
                        <tr style={{ fontWeight: 600 }}>
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                        </tr>
                      </tbody></table>
                    <table className="align-items-center table-dashboard-section-last d-flex">
                      <tbody><tr>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-27.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-38.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-37.png" /></a></td>
                        <td rowSpan={2} style={{ fontWeight: 100, display: 'inline-flex' }}>&nbsp;&nbsp;Sort <img src="/images/icon/dashboard-icons/Panel icons-32.png" style={{ marginLeft: '10px' }} /></td>
                      </tr>
                        <tr>
                          <td>&nbsp;&nbsp;</td>
                        </tr>
                        <tr>
                        </tr></tbody></table>
                  </div>
                </div>
                <div className="content-wrapper" ng-app="myApp">
                  <div className="company-table">
                    <div className="row">
                      <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                        <div style={{ float: 'left' }}>
                          <h5 className="left-head">User List</h5>
                        </div>
                        <div style={{ float: 'right', marginTop: '-2px', marginRight: '-15px' }}>
                          <a title="Reply" data-toggle="modal" ng-click="showRegisterModal('',0,0)">
                            <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;New User <i className="bx bxs-plus-circle" /></span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <label style={{ fontSize: '13px' }}>Search Key</label>
                            {/*style="height:26px;font-size:12px;font-weight:100;background-color: #e5f1f9;*/}
                            <input type="text" placeholder="Eg: Firstname,Lastname,Designation,Phone Number,Email Id,Role Name" className="form-control" ng-model="searchKey" style={{ height: '35px' }} />
                          </div>
                          <div className="col-md-4">
                            {/*style="border:1px solid #999;cursor:pointer;background-color: #e5f1f9; border: 1px solid #21396c;"> */}
                            <label style={{ fontSize: '13px' }}>Role</label>
                            <select className="form-control" id="basicSelect" ng-model="selectedRole" required style={{ fontSize: '14px', height: '35px' }}>
                              <option value={0} selected>All</option>
                              <option ng-repeat="role in roles" value="{{role.id}}">{'{'}{'{'}role.roleName{'}'}{'}'}</option>
                            </select>
                          </div>
                          <div className="col-md-4">
                            <label style={{ fontSize: '10px' }} />
                            <button className="btn btn-light-secondary " ng-click="searchUser()" style={{ marginTop: '22px' }}>Search</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    {/* Start Table Content*/}
                    <table className="table-striped module-table" aria-describedby="example_info">
                      <thead>
                        <tr>
                          <th style={{ paddingLeft: '15px', width: '80px' }}>Sl No.</th>
                          <th>Name</th>
                          <th>Designation</th>
                          <th>Email ID</th>
                          <th>Phone No</th>
                          <th>Role Name</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr ng-repeat="item in filteredItems track by $index">
                          <td style={{ paddingLeft: '20px' }}>{'{'}{'{'}itemsPerPage *(curPage-1)+$index+1{'}'}{'}'}</td>
                          <td>{'{'}{'{'} item.firstName {'}'}{'}'} {'{'}{'{'} item.lastName {'}'}{'}'}</td>
                          <td>{'{'}{'{'}item.designation{'}'}{'}'}</td>
                          <td>{'{'}{'{'}item.emailId{'}'}{'}'}</td>
                          <td>{'{'}{'{'}item.phoneNumber{'}'}{'}'}</td>
                          <td>{'{'}{'{'}item.roleName{'}'}{'}'}</td>
                          <td>
                            <div className="badge badge-light-success mr-1 mb-1" ng-if="item.isactive===1">Active</div>
                            <div className="badge badge-light-secondary mr-1 mb-1" ng-if="item.isactive===0">Inactive</div>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <a ng-click="showRegisterModal(item.roleIds,1,item.userId)"><i className="bx bxs-pencil text-dark" /></a>{/*ng-if="rolePermission.canedit"*/}
                            <a ng-click="deleteUser(item.userId)"><i className="bx bxs-trash-alt text-dark" /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* End Table Content*/}
                    {/* Pagination Starts here*/}
                    <br />
                    <div className="row">
                      <div className="col-md-12 justify-content-center">
                        <div data-pagination data-num-pages="numOfPages()" data-current-page="curPage" data-max-size="maxSize" data-boundary-links="true" style={{ width: '80%', margin: '0 auto', justifyContent: 'center' }} className="mb-1">
                        </div>
                      </div>
                    </div>
                    {/* Pagination Ends here*/}
                  </div>  {/* End Company Table*/}
                </div> {/* End Content Wrapper*/}
                <div className="modal fade" id="inlineForm" data-backdrop="static" data-keyboard="false" aria-labelledby="myModalLabel33">
                  <div className="modal-dialog modal-lg modal-plus" role="document">
                    <div className="modal-content">
                      <div className="modal-header modal-bg">
                        <span style={{ marginLeft: '-15px' }}>Register User</span>
                        <div style={{ marginRight: '-15px' }}>
                          <button type="button" className="close-pink" ng-click="closePopup()" aria-label="Close" data-dismiss="modal">
                            <div style={{ marginTop: '-4px', marginLeft: '1px' }}><span className="cross">×</span></div>
                          </button>
                        </div>
                      </div>
                      <div className="modal-body">
                        <form className="form-horizontal" ng-submit="submitForm(roleForm.$valid)" noValidate name="roleForm">
                          <div className="form-group row">
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <select className="form-control outline" id="basicSelect" ng-model="salutation" required>
                                    <option ng-repeat="salute in salutations" ng-selected="salute.id==salutation" value="{{salute.id}}">{'{'}{'{'}salute.salutationname{'}'}{'}'}</option>
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="text" className="form-control outline" ng-model="firstname" required placeholder="First name" />
                                  <label htmlFor="inputfirstname4">First Name</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="text" className="form-control outline" ng-model="lastname" required placeholder="Last name" />
                                  <label htmlFor="inputlastname4">Last Name</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="number" className="form-control outline" ng-model="phonenumber" required placeholder="Phone Number" />
                                  <label htmlFor="exampleInputUsername1">Phone Number</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="email" className="form-control outline" ng-model="emailid" required placeholder="Email Address" />
                                  <label htmlFor="exampleInputUsername1" style={{ backgroundColor: '#ffffff' }}>&nbsp;&nbsp;Email Address&nbsp;&nbsp;</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="password" className="form-control outline" ng-model="password" required placeholder="Password" />
                                  <label htmlFor="exampleInputPassword1">&nbsp;&nbsp;Password&nbsp;&nbsp;</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="password" compare-to="password" className="form-control outline" ng-model="confirmpassword" required placeholder="Confirm Password" />
                                  <label htmlFor="exampleInputPassword1">Confirm Password</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <select className="form-control outline" id="ddlUserRoles" />
                                </div>
                              </div>
                              <div className="col-md-6 col-6">
                                <div style={{ float: 'left', marginLeft: '-15px', verticalAlign: 'baseline' }}>
                                  <label htmlFor="checkboxshadow1" id="active-txt">Active</label>
                                  <input type="checkbox" className="checkbox-input" id="checkboxshadow1" ng-model="isactiveuser" style={{ border: '1px solid #999999' }} />
                                </div>
                              </div>
                              <div className="col-md-6 col-lg-6 col-12">
                                <div style={{ float: 'left' }}>
                                  <button type="submit" className="btn-custom-1" id="saveUpdateBtn" ng-disabled="userForm.$invalid || ( userForm.confirmpassword.$dirty && userForm.confirmpassword.$valid && !userForm.confirmpassword.$invalid )
    && ( userForm.password.$modelValue != userForm.confirmpassword.$modelValue )">
                                    Save
                          </button> &nbsp;&nbsp;&nbsp;
                          <button type="button" className="btn-custom-2" ng-click="closePopup()">Cancel</button>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix" />
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>




      </>
    );
  }
}

export default UserList;