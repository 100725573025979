import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './style.css'

import GroupColumnTitle from './group-column-title';
import GroupListRowTitle from './group-row-title';
import CellComponent from './cell-componant';
import BoardMessageContainer from '../../workspace/components/board/chatIM/boardMessageContainer';
import http from '../../services/http-service';
import * as workspaceApi from '../../global-search/constants'
import { Link} from "react-router-dom";

class SearchDroppableRow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            showBoardMessages: false
        };
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    componentDidMount() {

        const { cellItems } = this.props;
        console.log("Cell Items", this.props.searchItem)
        this.setState({ items: cellItems });
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ items: nextProps.cellItems });
    }
    render() {
        const { _id, title } = this.props;
        console.log('group title', this.props)
        return (
            <Droppable droppableId={_id} type='data' direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <div className="d-flex">
                            <div className={`flex-item w-100 border-hearder  data-0 bg-white mb-1`}>
                                <div className='bg-white'
                                    style={{ opacity: this.props.category === "header" ? 0 : 1, minWidth: '500px', width: '100%', 'height': '38px', cursor: 'pointer' }}
                                    // onClick={() => {
                                    //     // http.get(workspaceApi.GET_WORKSPACE_BY_BOARDID + "?id=" + this.props.mainBoardId)
                                    //     //     .then((result) => {
                                    //     //         window.location = "/board/"+ this.props.mainBoardId
                                    //     //     }).catch(error => {

                                    //     //     })

                                    //     window.location = "/board/" + this.props.mainBoardId
                                    // }}
                                    >

                                    <GroupListRowTitle
                                        id={_id}
                                        title={title}
                                        groupId={this.props.groupId}
                                        groupInd={this.props.groupInd}
                                        rowInd={this.props.rowInd}
                                        groupItemProvided={this.props.groupItemProvided}
                                    />

                                    {this.props.category !== "header" &&

                                        <div id="chatIM" className="d-inline-flex">
                                            <Link className="btn btn-danger text-white mr-2" //to={'/board/'+data.boardId} 
                                                to={{
                                                    pathname: '/board/' + this.props.mainBoardId,
                                                    state: {
                                                        groupIndex: this.props.groupInd,
                                                        rowIndex: this.props.rowInd
                                                    }
                                                }}
                                            >
                                                Go To Board
                                            </Link>
                                            {/* <div className="mt-2">
                                            <i class="fa fa-comments-o fa-lg text-primary cursor-pointer"
                                                onClick={() => this.setState({ showBoardMessages: !this.state.showBoardMessages })}
                                            ></i>
                                        </div> */}
                                            {/* {this.state.showBoardMessages && <div className="backfrop" onClick={() => this.setState({ showBoardMessages: !this.state.showBoardMessages })}></div>} */}
                                            {/* {this.state.showBoardMessages && <BoardMessageContainer
                                            id={_id}
                                            title={title}
                                            groupId={this.props.groupId}
                                            groupInd={this.props.groupInd}
                                            rowInd={this.props.rowInd}
                                            groupItemProvided={this.props.groupItemProvided}
                                            cellSuggestions={this.props.cellSuggestions}
                                            cellItems={this.props.cellItems}
                                            chatRoomID={this.props.chatroom}
                                            message={this.props.boardData.message}
                                            handleClose={this.closeModal}

                                            isWorkspaceOwner={this.props.isWorkspaceOwner}
                                            isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                                            isBoardAdmin={this.props.isBoardAdmin}
                                            lastUpdated={this.props.lastUpdated ? this.props.lastUpdated : null}


                                        ></BoardMessageContainer>} */}

                                        </div>}

                                </div>
                            </div>
                            {this.props.cellItems
                                .filter(cellItems => cellItems.restrictedMembers ? !cellItems.restrictedMembers.includes(this.authUser._id) : true)
                                .map((item, index) => {
                                    if (this.props.category === "header") return (
                                        <div key={item._id} className={`flex-item w-100 border-hearder bg-white mb-1 
                                        ${this.props.targetAreaIndex === index ? "DragingHover" : "none"} data-${item._id} 
                                        ${(item.restrictedMembers ? item.restrictedMembers.includes('ViewOnly_' + this.authUser._id) : false) ? "disablediv" : ""}`}

                                        >
                                            <div style={{ width: '100%' }}>
                                                <Draggable
                                                    draggableId={item._id}
                                                    index={index}
                                                >
                                                    {(provided) => {
                                                        // console.log(" Provided: ", provided);
                                                        return (
                                                            <div
                                                                className='p-2'
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                            >
                                                                {/* {item.body} */}
                                                                <GroupColumnTitle groupInd={this.props.groupInd} rowInd={this.props.rowInd} colInd={index} {...item} />
                                                            </div>
                                                        );
                                                    }}
                                                </Draggable>
                                            </div>
                                        </div>
                                    );
                                    else {
                                        //var searchItemIndex = this.props.cellItems.findIndex(x => x.body === this.props.searchItem);
                                        //console.log("------------->",item)
                                        return (
                                            <div key={item._id + this.props.index} className={`flex-item w-100 border-hearder  ${this.props.targetAreaIndex === index ? "DragingHover" : "none"} data-${item._id} ${(item.restrictedMembers ? item.restrictedMembers.includes('ViewOnly_' + this.authUser._id) : false) ? "disablediv" : ""}`}>
                                                <div className='bg-white' style={{ width: '100%' }}>

                                                    <CellComponent
                                                        isWorkspaceOwner={this.props.isWorkspaceOwner}
                                                        isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                                                        isBoardAdmin={this.props.isBoardAdmin}
                                                        groupInd={this.props.groupInd}
                                                        lastUpdated={this.props.lastUpdated ? this.props.lastUpdated : null}
                                                        rowInd={this.props.rowInd} colInd={index} item={item} cellItems={this.props.cellItems}
                                                        columnHeader={this.props.columnHeader} />
                                                </div>
                                            </div>
                                        );

                                    }


                                })}
                            {provided.placeholder}
                        </div>
                    </div>
                )
                }
            </Droppable >
        );
    }
}

export default SearchDroppableRow;