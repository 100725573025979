import React from 'react';

class PersonAction extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div
                style={{
                    cursor: "pointer",
                    fontFamily: "myriad-pro,sans-serif !important",
                    fontWeight: "300",
                }}
            >
                <div className="dropdown" id="sort-dropdown">
                    <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenu1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <i className="bx bxs-group mr-2" ></i>
                        Person
                    </button>
                    <div class="person-dropdown-en"
                        style={{
                            position: "absolute",
                            width: "250px",
                            top: "4px",
                            left: "0px",
                        }}
                    >
                        <ul
                            className="dropdown-menu multi-level animate slideIn"
                            role="menu"
                            aria-labelledby="dropdownMenu"
                            style={{ width: "350px !important" }}
                        >
                            <li className="dropdown-divider"></li>
                            <a href="" style={{ fontWeight: "400" }}>
                                Save as New View
                            </a>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

export default PersonAction;