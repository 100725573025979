export const  getAllState = "state/getAllState"; 
export const  getStateById = "state/getStateById"; 
export const  saveState = "state/saveState" ; 
export const  updateState = "state/updateState" ; 
export const  deleteState = "state/deleteState" ;
export const  getStateByCountryId = "state/getStatesByCountryId" ;

export default {
    getAllState : getAllState , 
    getStateById : getStateById , 
    saveState : saveState ,  
    updateState : updateState , 
    deleteState : deleteState , 
    getStateByCountryId : getStateByCountryId 
}


