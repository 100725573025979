import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './allrightslide.css'
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import BoardData from './borad-data/index'
import FilterDropdown from './borad-data/header/FilterDropdown';
// import axios from '../../util/Api';
import {
  getTaskManagerData,searchFilterBoardData
} from '../../actions/TaskManager'

function Tasks() {
  //const [resultData, setData] = useState([])
  const dispatch = useDispatch();
  const [seacrhvalue, setSearchValue] = useState('')
  const taskmanager = useSelector(state => state.taskmanager);
  const { resultData,filterData } = taskmanager
  const [filtervalue, setFilterValue] = useState({})

  useEffect(() => {
    // axios.get(`/search/getSearchWorkspaceBoardyById/?value=&id=${JSON.parse(localStorage.getItem('authUser'))._id}`).then(({ data }) => {
    //   console.log("board data==>", data)
    //   setData(data.workspaceBoard)
    // }).catch(err => {
    //   console.log("Error:", err)
    // })
    dispatch(getTaskManagerData(JSON.parse(localStorage.getItem('authUser'))._id));

  }, [dispatch])

  const setSearchData = (value) => {
    
    setFilterValue(value)
  }

  return (
    <>
      <MainMenu link={`tasks`} />
      <div id="whole-layout">
        <NavBar />


        <div className="row dash-blue fixed-top" id="bluetop">
          <div className="col-xl-12 col-sm-12 col-md-4 col-lg-4 col-12" id="dashboard-layout">
            <div>
              <div className="inner-section">
                <h3 style={{fontWeight: 600, marginTop: '10px'}}>All Tasks</h3>
                <div style={{marginTop: '-10px'}}><p className="mt-1" /></div>
                </div>
                </div>
                
                </div>
                <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
                  <table className="align-items-center newtb" style={{ 'width' : '10% !important'}}>
                    <tbody>
                      <tr>
                        <td rowSpan={2} style={{width: '50px'}}>
                          <ul className="nav navbar-nav bookmark-icons">
                            <li className="nav-item d-none d-lg-block nav-search" data-placement="top">
                              <a className="nav-link nav-link-search cursor-pointer" data-toggle="tooltip" data-original-title="Search your Project">
                                <img src="./images/icon/dashboard-icons/white icons-39.png"  style={{cursor: 'pointer' ,  'height' : '25px' , "width" : '25px' }}/>
                                </a></li></ul></td><td rowSpan={2} style={{width: '50px'}}><a data-action="expand" ng-click="fullScreen(this)"><img src="./images/icon/dashboard-icons/module-icons/white icons-28.png" style={{cursor: 'pointer' ,  'height' : '25px' , "width" : '25px' }} /></a></td><td rowSpan={2} style={{width: '50px'}}><a data-action="expand"><img src="./images/icon/dashboard-icons/module-icons/white icons-27.png" style={{cursor: 'pointer' ,  'height' : '25px' , "width" : '25px'}} /></a></td><td rowSpan={2} style={{width: '50px'}}><a data-toggle="modal" data-target="#default"><img src="./images/icon/dashboard-icons/module-icons/white icons-38.png" style={{cursor: 'pointer' ,  'height' : '25px' , "width" : '25px' }} /></a></td><td rowSpan={2} style={{width: '50px'}}><a><img src="./images/icon/dashboard-icons/module-icons/white icons-37.png" style={{cursor: 'pointer' ,  'height' : '25px' , "width" : '25px' }} /></a></td></tr><tr style={{fontWeight: 600}}><td id="value" /><td id="value" /><td id="value" /><td id="value" /><td id="value" />                               
                                <td>


<fieldset class="form-label-group mb-0 ml-3">
{/* <span class="user-profile-label" style={{ 'color' : '#fff !important' }}>Search in Task</span> */}
<i class="fal fa-search ser-board-task" aria-hidden="true" style= {{ 'position': 'absolute' , 'z-index' : '77' , 'top' : '8px' , 'left': '12px' , 'color' : 'rgb(66 136 245)' }}></i>
<input type="text" className="form-control toolbar-task-search" placeholder="Search Board & Group Data" value={seacrhvalue} onChange={(e) => {
setSearchValue(e.target.value)
}} />

</fieldset>
      </td>
                                
                                </tr></tbody>
                                
                                </table>




                                </div>
                          </div>


        <div id="appdata" className='app-content content fixed-top content-lay-out'>
          <div class="row">
            <div class="col-md-4">
             
            </div>
          </div>
          <div className="content-wrapper" style={{ 'padding': '20px' }}>
            {resultData && resultData.length &&filterData&&filterData.length && resultData.map((item, index) => {
              let filterGroup=filterData[index].group
              let groups = JSON.parse(filterGroup)
              // console.log("initData",filterGroup)
              return (
                <>
                <div class="layout__wrapper" style={{ 'position' : 'relative' , width: 'calc(100% - 20px)' ,
    'overflow-x' : 'scroll' , 'marginBottom' : '50px'  }}> 
                  <FilterDropdown groups={groups} columns={item.columnHeaders}
                    boardId={item._id} resultData={resultData} filterData={filterData} filterfunc={setSearchData} />
                  <BoardData item={item} index={index} key={item._id} boardId={item._id} filtervalue={filtervalue}
                    columnHeader={item.columnHeaders} searchTerm={seacrhvalue} />
                </div>
                </>
              )
            })
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Tasks
