import React, { Component } from 'react';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

class DefaultMenu extends Component {
    state = {}
    render() {
        return (
            <>
                <MainMenu link={`defaultmenu`} />
                <div id="whole-layout">
                    <NavBar />
                    <div id="appdata" className='app-content content fixed-top content-lay-out'>
                        <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
                        <div className="content-wrapper">
                            <div id="mySidenav" className="sidenav">
                                <a href="#" className="closebtn" onClick={() => {
                                    document.getElementById("mySidenav").style.width = "0%";
                                }}>&times;</a>
                            </div>
                            <div>





                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default DefaultMenu;