import React, { Component } from "react";
import http from "../services/http-service";
import * as SavedSearch from "./saved-search/constant";
import * as RecentSearch from "./recent-search/constant";
import * as Tags from "./tag-search/constant";

class QuickSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedSearch: [],
      recentSerach: [],
      hotTags: [],
    };
  }
  componentDidMount() {
    this._initData();
  }

  _initData = async () => {
    await http.get(Tags.GET_MAX_TAGS).then((result) => {
      this.setState({
        hotTags: result.data.response,
      });
    });

    await http.get(SavedSearch.GET_MAX_SAVED_SEARCH).then((result) => {
      this.setState({
        savedSearch: result.data.response,
      });
    });

    await http.get(RecentSearch.GET_MAX_RECENT_SEARCH).then((result) => {
      this.setState({
        recentSerach: result.data.response,
      });
    });
  };

  onTriggerHotTag = (searchValueName) => {
    this.props.quickSearchHotTag(4, searchValueName);
  };

  onTriggerMyFiles = (searchValueName) => {
    this.props.quickSearchMyFiles(2, searchValueName);
  };

  onTriggerIWasMentioned = (searchValueName) => {
    this.props.quickSearchIwasMentioned(5, searchValueName);
  };

  onTriggerIDidntReply = (searchValueName) => {
    this.props.quickSearchIDidntReply(5, searchValueName);
  };

  onTrigger_Saved_RecentSearch = (searchType, searchValueName) => {
    switch (searchType) {
      case "files":
        this.props.quickSearchSavedSearch(2, searchValueName, searchType);
        break;
      case "People":
        this.props.quickSearchSavedSearch(3, searchValueName, searchType);
        break;
      case "Tag":
        this.props.quickSearchSavedSearch(4, searchValueName, searchType);
        break;
      case "Message":
        this.props.quickSearchSavedSearch(5, searchValueName, searchType);
        break;
      default:
        this.props.quickSearchSavedSearch(-1, searchValueName, searchType);
    }
  };

  render() {
    const { savedSearch, hotTags, recentSerach } = this.state;
    return (
      <>
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-3" style={{ float: "left" }}>
              <div className="well">Hot Tags</div>
              <ul>
                {hotTags.map((tag, index) => (
                  <li key={index} style={{ listStyle: "none" }}>
                    <button
                      type="button"
                      onClick={() => this.onTriggerHotTag(tag.tagName)}
                      className="btn btn-link"
                    >
                      {tag.tagName}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-md-3" style={{ float: "left" }}>
              <div className="well">Quick Search</div>
              <ul>
                {/* <li style={{ listStyle: "none" }}>
                  <button
                    type="button"
                    // onClick={() => this.onTriggerTabIndex(tag.tagName)}
                    className="btn btn-link"
                  >
                    I'm Assigned to
                  </button>
                </li> */}

                <li style={{ listStyle: "none" }}>
                  <button
                    type="button"
                    onClick={() => this.onTriggerMyFiles("QuickSearchMyFiles")}
                    className="btn btn-link"
                  >
                    My Files
                  </button>
                </li>

                <li style={{ listStyle: "none" }}>
                  <button
                    type="button"
                    onClick={() =>
                      this.onTriggerIWasMentioned("QuickSearchIWasMentioned")
                    }
                    className="btn btn-link"
                  >
                    I Was mentioned
                  </button>
                </li>

                <li style={{ listStyle: "none" }}>
                  <button
                    type="button"
                    onClick={() =>
                      this.onTriggerIDidntReply(
                        "QuickSearchIDidntReplyMessages"
                      )
                    }
                    className="btn btn-link"
                  >
                    I didn't reply
                  </button>
                </li>

                {/* <li style={{ listStyle: "none" }}>
                  <button
                    type="button"
                    // onClick={() => this.onTriggerTabIndex(tag.tagName)}
                    className="btn btn-link"
                  >
                    Arcived Boards
                  </button>
                </li> */}
              </ul>
            </div>

            <div className="col-md-3" style={{ float: "left" }}>
              <div className="well">Saved Search</div>
              <ul style={{ listStyle: "none" }}>
                {savedSearch.map((savedItem, index) => (
                  <li key={index}>
                    <li key={index} style={{ listStyle: "none" }}>
                      <button
                        type="button"
                        onClick={() =>
                          this.onTrigger_Saved_RecentSearch(
                            savedItem.searchType,
                            savedItem.searchName
                          )
                        }
                        className="btn btn-link"
                      >
                        {savedItem.searchName}
                      </button>
                    </li>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-md-3" style={{ float: "left" }}>
              <div className="well">Recent Search</div>
              <ul style={{ listStyle: "none" }}>
                {recentSerach.map((recentItem, index) => (
                  <li key={index}>
                    <li key={index} style={{ listStyle: "none" }}>
                      <button
                        type="button"
                        onClick={() =>
                          this.onTrigger_Saved_RecentSearch(
                            recentItem.searchType,
                            recentItem.searchName
                          )
                        }
                        className="btn btn-link"
                      >
                        {recentItem.searchName}
                      </button>
                    </li>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default QuickSearch;
