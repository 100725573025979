import {
   ADD_COLUMN,
   CHANGE_ITEM,
   FETCH_POSTS_STARTED,
   FETCH_POSTS_FAILURE,
   FETCH_POSTS_SUCCESS,
   CHNAGE_GROUP_TITLE,
   CHANGE_GROUP_MEMBERS,
   CHANGE_GROUP_COLOR,
   CHANGE_GROUP_LIST_ROW_TITLE,
   DRAG_DROP,
   ADD_ROW,
   DUPLICATE_ROW,
   ADD_GROUP,
   REMOVE_GROUP,
   REMOVE_ROW,
   REMOVE_COLUMN,
   CHANGE_WORKSPACE_BOARD,
   NAVIGATE_TO_BOARD_FROM_NOTIFICATION,
   FETCH_START,
   FETCH_ERROR,
   SHOW_MESSAGE,
   CHANGE_BOARD_TITLE,
   CHANGE_BOARD_DESC,
   RELOAD_WORKSPACE,
   INITIALIZE_BOARD,
   FETCH_SUCCESS,
   CHANGE_STATUS_LABELS,
   CHANGE_BOARD_NUMBER_UNIT,
   CHANGE_BOARD_NUMBER_DIRECTION,
   CHANGE_BOARD_NUMBER_ACTION,
   CHANGE_GROUP_COLUMN_TITLE,
   CHANGE_GROUP_COLUMN_MEMBERS,
   UPDATE_BOARD_TAGS_SUGGESTION,
   UPDATE_BOARD_DROPDOWN_SUGGESTION,
   SEARCH_FILTER_BOARD_DATA,
   UPDATE_BORD_GROUP_DATA,
   UPDATE_COLUMN_WIDTH,
   UPDATE_WORKSPACE_BOARD_MEMBERS,
   SEND_BOARD_CHAT_MESSAGE,
   UPDATE_MESSAGE_LIKES,
   UPDATE_MESSAGE_SEEN_BY,
   UPDATE_CHATROOM_COLUMNS,
   GET_CHATROOM_BY_ID,
   SEND_MESSAGE_REPLY,
   UPDATE_NESTED_MESSAGE_LIKES,
   UPDATE_UNREAD_MESSAGE_COUNT,
   UPDATE_MESSAGE_REMINDER,
   CHANGE_GROUP_COLUMN_STATUS_PROGRESS
} from "../constants/ActionTypes";
import axios from '../util/Api';
import db from '../db';


export const changeWorkspaceBoard = ({ id, workspaceID, workspace, isNavigation }) => {
   return (dispatch) => {
      if(isNavigation){
         db.table('boards')
         .get(id)
         .then((board) => {
            
            dispatch({
               type: CHANGE_WORKSPACE_BOARD, payload: {
                  data: board,
                  workspaceID: workspaceID,
                  workspace: workspace
               }
            });
            
            axios.get(`/workspace/getWorkspaceBoardById/${id}`).then(({ data }) => {
               if (data.workspaceBoard !== null) {
                  console.log("b112 received", data);
                  db.table('boards')
                     .put(data.workspaceBoard)
                     .then(() => {
                        dispatch({
                           type: CHANGE_WORKSPACE_BOARD, payload: {
                              data: data.workspaceBoard,
                              workspaceID: workspaceID,
                              workspace: workspace
                           }
                        });
                        //dispatch({ type: FETCH_SUCCESS });
                     });
      
               } else {
                  dispatch({ type: FETCH_ERROR, payload: "No Data" });
               }
            }).catch(error => {
               dispatch({ type: FETCH_ERROR, payload: error.message });
               console.log("Error****:", error.message);
            })

         }).catch(error => {
            axios.get(`/workspace/getWorkspaceBoardById/${id}`).then(({ data }) => {
               if (data.workspaceBoard !== null) {
                  console.log("b112 received", data);
                  db.table('boards')
                     .put(data.workspaceBoard)
                     .then(() => {
                        dispatch({
                           type: CHANGE_WORKSPACE_BOARD, payload: {
                              data: data.workspaceBoard,
                              workspaceID: workspaceID,
                              workspace: workspace
                           }
                        });
                        //dispatch({ type: FETCH_SUCCESS });
                     });
      
               } else {
                  dispatch({ type: FETCH_ERROR, payload: "No Data" });
               }
            }).catch(error => {
               dispatch({ type: FETCH_ERROR, payload: error.message });
               console.log("Error****:", error.message);
            })
         })
      }else{
         axios.get(`/workspace/getWorkspaceBoardById/${id}`).then(({ data }) => {
            if (data.workspaceBoard !== null) {
               console.log("b112 received", data);
               db.table('boards')
                  .put(data.workspaceBoard)
                  .then(() => {
                     dispatch({
                        type: CHANGE_WORKSPACE_BOARD, payload: {
                           data: data.workspaceBoard,
                           workspaceID: workspaceID,
                           workspace: workspace
                        }
                     });
                     //dispatch({ type: FETCH_SUCCESS });
                  });
   
            } else {
               dispatch({ type: FETCH_ERROR, payload: "No Data" });
            }
         }).catch(error => {
            dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
         })
      }
       
      console.log("b112 requested");
     



   }
}

export const navigateToBoardFromNotification = ({ id, groupIndex, rowIndex, messageID, isChatNotification }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.get(`/workspace/getWorkspaceBoardById/${id}`).then(({ data }) => {
         console.log("board data==>", data);

         if (data.workspaceBoard !== null) {
            if (isChatNotification) {
               dispatch({
                  type: NAVIGATE_TO_BOARD_FROM_NOTIFICATION, payload: {
                     data: data.workspaceBoard,
                     groupIndex: groupIndex,
                     rowIndex: rowIndex,
                     messageID: messageID,
                     isChatNotification: true
                  }
               });
            } else {
               dispatch({
                  type: NAVIGATE_TO_BOARD_FROM_NOTIFICATION, payload: {
                     data: data.workspaceBoard,
                     groupIndex: groupIndex,
                     rowIndex: rowIndex
                  }
               });
            }

           // dispatch({ type: FETCH_SUCCESS });
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const updateWorkspaceBoard = ({ workspaceBoard, workspaceID }) => {
   return {
      type: CHANGE_WORKSPACE_BOARD,
      payload: {
         data: workspaceBoard,
         workspaceID: workspaceID
      }
   }
}

export const updateCellTagsSuggestion = ({ boardId, suggestions, columnId }) => {
   return (dispatch) => {
      axios.post('/workspace/updateCellTagsSuggestion', {
         boardId: boardId,
         suggestions: suggestions,
         columnId: columnId
      }).then(({ data }) => {
         dispatch({
            type: UPDATE_BOARD_TAGS_SUGGESTION,
            payload: {
               suggestions: data.suggestions
            }
         })
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const updateCellDropdownSuggestion = ({ boardId, suggestions, columnId }) => {
   return (dispatch) => {
      axios.post('/workspace/updateCellDropdownSuggestion', {
         boardId: boardId,
         suggestions: suggestions,
         columnId: columnId
      }).then(({ data }) => {
         dispatch({
            type: UPDATE_BOARD_DROPDOWN_SUGGESTION,
            payload: {
               suggestion: data.suggestions
            }
         })
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}


export const updateColumnWidth = ({ boardId, width, columnId }) => {

   return (dispatch) => {
      axios.post('/workspace/updateColumnWidth', {
         boardId: boardId,
         width: width,
         columnId: columnId
      }).then(({ data }) => {
         dispatch({
            type: UPDATE_COLUMN_WIDTH,
            payload: {
               suggestion: data.suggestions
            }
         })
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const changeWorkspaceBoardTitle = ({ boardId, boardTitle }) => {
   return (dispatch) => {
      axios.post('/workspace/changeBoardTitle', {
         boardID: boardId,
         title: boardTitle
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: CHANGE_BOARD_TITLE,
               payload: { title: boardTitle }
            });
         } else {
            dispatch({ type: FETCH_ERROR, payload: "Board title changed failed!" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const updateWorkspaceBoardMembers = ({ members, workspaceBoardId, workspaceId, message }) => {
   return (dispatch) => {
      axios.post('/workspace/updateWorkspaceBoardMembers', {
         members: members,
         workspaceBoardId: workspaceBoardId,
         workspaceId: workspaceId,
         message: message
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: UPDATE_WORKSPACE_BOARD_MEMBERS,
               payload: {
                  workspaceBoard: data.workspaceBoard
               }
            });

           // dispatch({ type: SHOW_MESSAGE, payload: message });
         } else {
            dispatch({ type: FETCH_ERROR, payload: "Something went wrong, please try again." });
         }
      }).catch(error => {
         console.log("Error****:", error.message);
         dispatch({ type: FETCH_ERROR, payload: error.message });

      })

   }
}


export const changeWorkspaceBoardDesc = ({ boardId, boardDesc }) => {
   return (dispatch) => {
      axios.post('/workspace/changeBoardDesc', {
         boardID: boardId,
         description: boardDesc
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: CHANGE_BOARD_DESC,
               payload: { description: boardDesc }
            });
         } else {
            dispatch({ type: FETCH_ERROR, payload: "Board description changed failed!" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const addGroup = ({ boardID, newGroup, addGroupIndex }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/workspace/addWorkspaceBoardGroup', {
         boardID: boardID,
         group: newGroup,
         addGroupIndex: addGroupIndex
      }).then(({ data }) => {
         console.log("saved data==>", data)
         if (data.workspaceBoard !== null) {
            dispatch({
               type: ADD_GROUP, payload: {
                  data: data.workspaceBoard
               }
            });
            // dispatch({
            //    type: SHOW_MESSAGE,
            //    payload: 'New group added successfully!'
            // });

         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const addRow = ({ boardId, groupID, newGroupItem, groupInd }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/workspace/addWorkspaceBoardGroupItem', {
         groupID: groupID,
         newGroupItem: newGroupItem,
         boardId: boardId
      }).then(({ data }) => {
        // console.log("add row saved data===>", data);
         if (data.success === 200) {
            dispatch({
               type: ADD_ROW,
               payload: {
                  data: newGroupItem,
                  groupInd: groupInd,
                  workspaceBoard: data.workspaceBoard
               }
            });
            // dispatch({
            //    type: SHOW_MESSAGE,
            //    payload: `New Item ${newGroupItem.title} added successfully!`
            // })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const duplicateRow = ({ boardId, groupID, newGroupItem, groupInd, rowInd }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/workspace/addWorkspaceBoardGroupItem', {
         groupID: groupID,
         newGroupItem: newGroupItem,
         boardId: boardId,
         rowInd: rowInd
      }).then(({ data }) => {
        // console.log("add row saved data===>", data);
         if (data.success === 200) {
            dispatch({
               type: DUPLICATE_ROW,
               payload: {
                  data: newGroupItem,
                  groupInd: groupInd,
                  workspaceBoard: data.workspaceBoard
               }
            });
            // dispatch({
            //    type: SHOW_MESSAGE,
            //    payload: `New Item ${newGroupItem.title} added successfully!`
            // })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}


export const createNewChatRoom = ({ boardId, groupID, newGroupItem, groupInd }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/workspace/createNewChatRoom', {

         members: [],
         columns: []
      }).then(({ data }) => {
         console.log("add row saved data===>", data);
         if (data.success === 200) {

            // dispatch({
            //    type: SHOW_MESSAGE,
            //    payload: `Chat room added successfully!`
            // })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const sendBoardChatMessage = ({ chatRoom, to, from, body }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      let message = {
         chatRoom: chatRoom,
         to: to,
         from: from,
         body: body
      }
      axios.post('/workspace/sendBoardChatMessage', message).then(({ data }) => {
         console.log("add row saved data===>", data);

         if (data.message) {
            dispatch({
               type: SEND_BOARD_CHAT_MESSAGE,
               payload: {
                  message: data.message
               }
            });

         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const sendMessageReply = ({ parent, to, from, body, chatroomID }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      let message = {
         parent: parent,
         to: to,
         from: from,
         body: body,
         chatroomID: chatroomID
      }
      axios.post('/workspace/sendMessageReply', message).then(({ data }) => {
         console.log("add row saved data===>", data);

         if (data.reply) {
            dispatch({
               type: SEND_MESSAGE_REPLY,
               payload: {
                  reply: data.reply
               }
            });

         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}


export const changeBoardItem = ({ groupInd, rowInd, colInd, body, cellItemID, boardId }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/updateCellItem', {
         cellItemID: cellItemID,
         boardId: boardId,
         groupInd: groupInd,
         rowInd: rowInd,
         colInd: colInd,
         body: body
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: CHANGE_ITEM, payload: {
                  groupInd: groupInd,
                  rowInd: rowInd,
                  colInd: colInd,
                  body: body,
                  lastUpdate: data.lastUpdate
               }
            });
            //dispatch({ type: SHOW_MESSAGE, payload: 'Item changed successfully!' });
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}


export const saveRowReminder = ({ groupInd, rowInd, colInd, body, cellItemID, boardId, reminderDate, frequency }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/saveRowReminder', {
         reminderDate: reminderDate,
         frequency: frequency,
         cellItemID: cellItemID,
         boardId: boardId,
         groupInd: groupInd,
         rowInd: rowInd,
         colInd: colInd,
         body: body
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: CHANGE_ITEM, payload: {
                  groupInd: groupInd,
                  rowInd: rowInd,
                  colInd: colInd,
                  body: body,
                  lastUpdate: data.lastUpdate
               }
            });
           // dispatch({ type: SHOW_MESSAGE, payload: 'Item changed successfully!' });
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}



export const changeBoardColumnTitle = ({ rowInd, colInd, body, cellItemID, boardId }) => {
   return (dispatch) => {
      axios.post('/workspace/updateBoardColumnTitle', {
         boardId: boardId,
         cellItemID: cellItemID,
         rowInd: rowInd,
         colInd: colInd,
         body: body
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: CHANGE_GROUP_COLUMN_TITLE,
               payload: {
                  groups: data.groups
               }
            });
            //dispatch({ type: SHOW_MESSAGE, payload: 'Column title changed successfully!' });
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}


export const changeBoardColumnMembers = ({ groupInd, colInd, restrictedMembers, cellItemID, boardId }) => {
   return (dispatch) => {
      axios.post('/workspace/updateBoardColumnMembers', {
         groupInd: groupInd,
         boardId: boardId,
         cellItemID: cellItemID,
         colInd: colInd,
         restrictedMembers: restrictedMembers
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: CHANGE_GROUP_COLUMN_MEMBERS,
               payload: {
                  groups: data.groups
               }
            });
            //dispatch({ type: SHOW_MESSAGE, payload: 'Column Restricted Members changed successfully!' });
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}



export const changeBoardColumnStatusProgress = ({ groupInd,boardId, colInd, progressConfig, cellItemID }) => {
   return (dispatch) => {
      axios.post('/workspace/updateBoardColumnStatusProgress', {
         groupInd: groupInd,
         boardId: boardId,
         cellItemID: cellItemID,
         colInd: colInd,
         progressConfig: progressConfig
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: CHANGE_GROUP_COLUMN_STATUS_PROGRESS,
               payload: {
                  groups: data.groups
               }
            });
            //dispatch({ type: SHOW_MESSAGE, payload: 'Column Restricted Members changed successfully!' });
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const changeGroupTitle = ({ groupInd, groupTitle, groupID, boardId }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/updateGroupTitle', {
         boardId: boardId,
         groupID: groupID,
         title: groupTitle
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: CHNAGE_GROUP_TITLE, payload: {
                  groupInd: groupInd,
                  groupTitle: groupTitle,
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const updateChatRoomColumns = ({ chatroomID, columns }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/updateChatRoomColumns', {
         chatroomID: chatroomID,
         columns: columns
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: UPDATE_CHATROOM_COLUMNS, payload: {
                  chatroom: data.chatroom
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const updateMessageLikes = ({ messageID, likes }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/updateMessageLikes', {
         messageID: messageID,
         likes: likes
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: UPDATE_MESSAGE_LIKES, payload: {
                  message: data.message
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const updateMessageReminder = (reminderObj) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/updateMessageReminder', reminderObj).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: UPDATE_MESSAGE_REMINDER, payload: {
                  message: data.message
               }

            })

         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}


export const updateMessageSeenBy = ({ messageID, seenBy, chatRoomID }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/updateMessageSeenBy', {
         messageID: messageID,
         seenBy: seenBy,
         chatRoom: chatRoomID
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: UPDATE_MESSAGE_SEEN_BY, payload: {
                  message: data.message
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const getUnreadMsgCount = ({ chatRoomID, userid }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/getUnreadMsgCount', {

         chatRoom: chatRoomID,
         userId: userid
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: UPDATE_UNREAD_MESSAGE_COUNT, payload: {
                  unreadMsgCount: data.unreadMsgCount
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const updateNestedMessageLikes = ({ messageID, likes }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/updateNestedMessageLikes', {
         messageID: messageID,
         likes: likes
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: UPDATE_NESTED_MESSAGE_LIKES, payload: {
                  reply: data.message
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const getChatRoomById = ({ chatroomID }) => {
   return (dispatch) => {

      axios.post(`/workspace/getChatRoomById`, {
         chatroomID: chatroomID
      }).then(({ data }) => {
         if (data.success === 200) {

            dispatch({
               type: GET_CHATROOM_BY_ID, payload: {
                  chatroom: data.chatroom
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })

   }
}

export const changeGroupMembers = ({ groupInd, groupMembers, groupID, boardId }) => {
   return (dispatch) => {

      // dispatch({ type: FETCH_START });
      axios.post('/workspace/updateGroupMembers', {
         boardId: boardId,
         groupID: groupID,
         groupMembers: groupMembers
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: CHANGE_GROUP_MEMBERS, payload: {
                  groupInd: groupInd,
                  groupMembers: groupMembers,
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const changeGroupColor = ({ groupInd, color, groupID, boardId }) => {
   return (dispatch) => {


      axios.post('/workspace/updateGroupColor', {
         boardId: boardId,
         groupID: groupID,
         color: color
      }).then(({ data }) => {
         if (data.success === 200) {

            dispatch({
               type: CHANGE_GROUP_COLOR, payload: {
                  groupInd: groupInd,
                  color: color
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "No Data" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const changeGroupListRowTitle = ({ groupInd, rowInd, rowTitle, groupItemID, boardId }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/updateGroupItemTitle', {
         groupItemID: groupItemID,
         groupInd: groupInd,
         title: rowTitle,
         boardId: boardId
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: CHANGE_GROUP_LIST_ROW_TITLE,
               payload: {
                  workspaceBoard: data.workspaceBoard
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "Failed saved!" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const addColumn = ({ boardID, key, body, leftIndex, addType }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/workspace/addBoardGroupColumn', {
         boardID: boardID,
         key: key,
         body: body,
         leftIndex: leftIndex,
         addType: addType
      }).then(({ data }) => {
         if (data.workspaceBoard !== null) {
            dispatch({
               type: ADD_COLUMN,
               payload: {
                  data: data.workspaceBoard
               }
            });

          //  dispatch({ type: SHOW_MESSAGE, payload: `${key} column added successfully!` })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "Failed saved!" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
};

export const duplicateColumn = ({ boardID, key, body, columnId, leftIndex }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/workspace/addBoardGroupColumn', {
         boardID: boardID,
         key: key,
         body: body,
         leftIndex: leftIndex,
         columnId: columnId,
         addType: "duplicate"
      }).then(({ data }) => {
         if (data.workspaceBoard !== null) {
            dispatch({
               type: ADD_COLUMN,
               payload: {
                  data: data.workspaceBoard
               }
            });

          //  dispatch({ type: SHOW_MESSAGE, payload: `${key} column duplicated successfully!` })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "Failed saved!" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
};

export const dragDropChangedGroup = ({ boardID, sourceIndex, targetIndex, group }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/changeBoardGroupOrder', {
         boardId: boardID,
         sourceIndex: sourceIndex,
         targetIndex: targetIndex
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: DRAG_DROP,
               payload: {
                  items: group
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "Failed changed!" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const dragDropChangedGroupItem = ({ sourceGroupID, destinationGroupID, sourceGroupItemIndex, destinationGroupItemIndex, boardId, group }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      axios.post('/workspace/changeBoardGroupItemOrder', {
         sourceGroupID: sourceGroupID,
         destinationGroupID: destinationGroupID,
         sourceGroupItemIndex: sourceGroupItemIndex,
         destinationGroupItemIndex: destinationGroupItemIndex,
         boardId: boardId
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: DRAG_DROP,
               payload: {
                  items: group
               }
            })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "Failed changed!" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const dragDropChangedGroupColumnOrder = ({ sourceIndex, destinationIndex, boardID, group }) => {
   return (dispatch) => {
      // dispatch({ type: FETCH_START });
      dispatch({
         type: DRAG_DROP,
         payload: {
            items: group
         }
      })
      axios.post('/workspace/changeBoardGroupColumnOrder', {
         sourceIndex: sourceIndex,
         destinationIndex: destinationIndex,
         group: group,
         boardID: boardID
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: DRAG_DROP,
               payload: {
                  items: group
               }
            })

         } else {
            dispatch({ type: FETCH_ERROR, payload: "Failed changed!" });
         }

      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const removeGroup = ({ groupInd, groupId, boardID }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/workspace/deleteBoardGroup', {
         boardID: boardID,
         groupId: groupId,
         groupInd: groupInd
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: REMOVE_GROUP,
               payload: {
                  groupInd: groupInd
               }
            });

            dispatch({ type: SHOW_MESSAGE, payload: 'Group deleted successfully!' })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "Group deleted failed!" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}


export const removeRowItem = ({ groupInd, rowInd, groupId, groupItemId, boardId }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/workspace/removeGroupItem', {
         groupID: groupId,
         rowInd: rowInd,
         groupItemID: groupItemId,
         boardId: boardId
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: REMOVE_ROW,
               payload: {
                  data: data.workspaceBoard
               }
            })
            dispatch({ type: SHOW_MESSAGE, payload: 'Item deleted successfully!' })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "Item deleted failed!" });
         }
      }).catch(error => {
        // dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const removeColumn = ({ colInd, cellItemID, columnId, boardId }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/workspace/removeGroupColumn', {
         colInd: colInd,
         cellItemID: cellItemID,
         boardID: boardId,
         columnId: columnId
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: REMOVE_COLUMN,
               payload: {
                  data: data.workspaceBoard
               }
            });
            dispatch({ type: SHOW_MESSAGE, payload: 'Column deleted successfully!' })
         } else {
            dispatch({ type: FETCH_ERROR, payload: "Item deleted failed!" });
         }
      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const reloadWorkspace = (data) => {
   return {
      type: RELOAD_WORKSPACE,
      payload: data
   }
}

export const intialize = () => {
   return {
      type: INITIALIZE_BOARD
   }
}

export const changeStatusItems = ({ boardId, labels, columnId }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/workspace/changeStatusItems', {
         boardId: boardId,
         columnId: columnId,
         labels: labels
      }).then(({ data }) => {
         if (data.success === 200) {
            dispatch({
               type: CHANGE_STATUS_LABELS,
               payload: {
                  items: data.suggestions
               }
            });
         } else {
            dispatch({ type: FETCH_ERROR, payload: 'Board status is not found!' });
         }

      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const changeFormulaField = ({ boardID, groups, message }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/workspace/changeFormula', {
         boardId: boardID,
         groups: groups,
         message: message
      }).then(({ data }) => {
         dispatch({
            type: UPDATE_BORD_GROUP_DATA,
            payload: {
               workspaceBoard: data.workspaceBoard
            }
         })

      }).catch(error => {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      })
   }
}

export const changeBoardNumbersUnit = ({ unit, columnId }) => {
   return {
      type: CHANGE_BOARD_NUMBER_UNIT,
      payload: {
         unit: unit,
         columnId: columnId
      }
   }
}

export const changeBoardNumberDirection = ({ direction, columnId }) => {
   return {
      type: CHANGE_BOARD_NUMBER_DIRECTION,
      payload: {
         direction: direction,
         columnId: columnId
      }
   }
}

export const changeBoardNumberActionType = ({ actionType, columnId }) => {
   return {
      type: CHANGE_BOARD_NUMBER_ACTION,
      payload: {
         actionType: actionType,
         columnId: columnId
      }
   }
}

export const searchFilterBoardData = ({ boardData }) => {
   return {
      type: SEARCH_FILTER_BOARD_DATA,
      payload: {
         boardData: boardData
      }
   }
}

const fetchPostsStarted = () => {
   return {
      type: FETCH_POSTS_STARTED,
      payload: {
         isLoading: true
      }
   };

};

export const fetchPostsSuccess = data => {
   return {
      type: FETCH_POSTS_SUCCESS,
      payload: {
         data
      }
   };
};

const fetchPostsFailed = error => {
   return {
      type: FETCH_POSTS_FAILURE,
      payload: {
         error
      }
   };
};