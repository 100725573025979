import {
   ADD_COLUMN,
   CHANGE_ITEM,
   CHNAGE_GROUP_TITLE,
   CHANGE_GROUP_MEMBERS,
   CHANGE_GROUP_COLOR,
   CHANGE_GROUP_LIST_ROW_TITLE,
   DRAG_DROP,
   ADD_ROW,
   DUPLICATE_ROW,
   ADD_GROUP,
   REMOVE_GROUP,
   REMOVE_ROW,
   REMOVE_COLUMN,
   CHANGE_WORKSPACE_BOARD,
   NAVIGATE_TO_BOARD_FROM_NOTIFICATION,
   CHANGE_BOARD_TITLE,
   CHANGE_BOARD_DESC,
   RELOAD_WORKSPACE,
   INITIALIZE_BOARD,
   CHANGE_STATUS_LABELS,
   CHANGE_BOARD_NUMBER_UNIT,
   CHANGE_BOARD_NUMBER_DIRECTION,
   CHANGE_BOARD_NUMBER_ACTION,
   CHANGE_GROUP_COLUMN_TITLE,
   CHANGE_GROUP_COLUMN_MEMBERS,
   CHANGE_GROUP_COLUMN_STATUS_PROGRESS,
   UPDATE_BOARD_TAGS_SUGGESTION,
   UPDATE_BOARD_DROPDOWN_SUGGESTION,
   SEARCH_FILTER_BOARD_DATA,
   UPDATE_BORD_GROUP_DATA,
   UPDATE_WORKSPACE_BOARD_MEMBERS,
   UPDATE_COLUMN_WIDTH,
   SEND_BOARD_CHAT_MESSAGE,
   UPDATE_MESSAGE_LIKES,
   UPDATE_MESSAGE_SEEN_BY,
   UPDATE_NESTED_MESSAGE_LIKES,
   UPDATE_CHATROOM_COLUMNS,
   GET_CHATROOM_BY_ID,
   SEND_MESSAGE_REPLY,
   UPDATE_UNREAD_MESSAGE_COUNT,
   UPDATE_MESSAGE_REMINDER
} from '../constants/ActionTypes';
import 'react-day-picker/lib/style.css';

const initialStates = {
   data: '',
   storeData: '',
   boardID: '',
   workspaceID: '',
   workspaceBoard: '',
   boardTitle: 'Board Title',
   boardDescription: 'Add description',
   numbersProperties: [],
   cellSuggestions: [],
   message: [],
};


const alterGroupTitle = (items, groupInd, groupTitle) => {
   let newItems = [...items];
   newItems[groupInd].label = groupTitle;
   return newItems;
}

const alterGroupMembers = (items, groupInd, groupMembers) => {
   let newItems = [...items];
   newItems[groupInd].members = groupMembers;
   return newItems;
}

const alterGroupColor = (items, groupInd, color) => {

   let newItems = [...items];
   newItems[groupInd].color = color;
   return newItems;
}

const alterBoardMessages = (messages) => {

   return messages;
}

const alterBoardItem = (items, groupInd, rowInd, colInd, body, lastUpdated) => {
   let newItems = [...items];
   newItems[groupInd].groupItems[rowInd].cellItems[colInd].body = body;
   newItems[groupInd].groupItems[rowInd].lastUpdated = lastUpdated;
   // console.log("new items==>", newItems)
   return newItems;
}

const alterDragDropData = (items, changedItems) => {
   let newItems = changedItems;
   return newItems;
}

const removeGroupItems = (items, groupInd) => {
   let newItems = [...items];
   const [deleteItem,] = newItems.splice(groupInd, 1);
   // console.log("deleteItems", deleteItem);
   // console.log("newITems", newItems);
   return newItems;
}

const removeRowItems = (items, groupInd, rowInd) => {

   let newItems = [...items];
   let rowItems = newItems[groupInd].groupItems;
   const [deleteRowItem,] = rowItems.splice(rowInd, 1);
   // console.log("deleteRowITem==>", deleteRowItem)
   // console.log("newITems", newItems)
   return newItems;
}

const changeNumbersUnit = (numbersProperties, unit, columnId) => {
   let numbersProperty = numbersProperties.find(property => property.columnId === columnId);
   if (numbersProperty) {
      let Index = numbersProperties.findIndex(property => property.columnId === columnId);
      numbersProperty.unit = unit;
      numbersProperties[Index] = numbersProperty;
   } else {
      let newProperty = {
         unit: unit,
         direction: 'left',
         action: 'none',
         columnId: columnId
      }

      numbersProperties.push(newProperty);
   }

   return numbersProperties;
}

const changeNumberDirection = (numbersProperties, direction, columnId) => {
   let numbersProperty = numbersProperties.find(property => property.columnId === columnId);
   if (numbersProperty) {
      let Index = numbersProperties.findIndex(property => property.columnId === columnId);
      numbersProperty.direction = direction;
      numbersProperties[Index] = numbersProperty;
   } else {
      let newProperty = {
         unit: 'none',
         direction: direction,
         action: 'none',
         columnId: columnId
      }

      numbersProperties.push(newProperty);
   }

   return numbersProperties;
}

const changeNumberAction = (numbersProperties, actionType, columnId) => {
   let numbersProperty = numbersProperties.find(property => property.columnId === columnId);
   if (numbersProperty) {
      let Index = numbersProperties.findIndex(property => property.columnId === columnId);
      numbersProperty.action = actionType;
      numbersProperties[Index] = numbersProperty;
   } else {
      let newProperty = {
         unit: 'none',
         direction: 'left',
         action: actionType,
         columnId: columnId
      }

      numbersProperties.push(newProperty);
   }

   return numbersProperties;
}

export default (state = initialStates, action) => {

   switch (action.type) {
      case INITIALIZE_BOARD:
         return {
            ...state,
            data: '',
            storeData: '',
            boardID: '',
            boardTitle: 'Board Title',
            boardDescription: 'Add description',
            loadWorkspace: !state.loadWorkspace,
            workspaceBoard: ''
         }
      case CHANGE_BOARD_TITLE:
         return {
            ...state,
            boardTitle: action.payload.title
         }
      case CHANGE_BOARD_DESC:
         return {
            ...state,
            boardDescription: action.payload.description
         }
      case CHANGE_WORKSPACE_BOARD:
         return {
            ...state,
            data: JSON.parse(action.payload.data.group),
            storeData: JSON.parse(action.payload.data.group),
            boardID: action.payload.data._id,
            boardTitle: action.payload.data.title,
            boardDescription: action.payload.data.description,
            cellSuggestions: action.payload.data.columnHeaders,
            workspaceID: action.payload.workspaceID,
            workspace: action.payload.workspace,
            workspaceBoard: action.payload.data,
            groupIndex: '',
            rowIndex: '',
            messageID:'',
            isChatNotification:true

         }
         case NAVIGATE_TO_BOARD_FROM_NOTIFICATION:
         return {
            ...state,
            data: JSON.parse(action.payload.data.group),
            storeData: JSON.parse(action.payload.data.group),
            boardID: action.payload.data._id,
            boardTitle: action.payload.data.title,
            boardDescription: action.payload.data.description,
            cellSuggestions: action.payload.data.columnHeaders,
            workspaceID: action.payload.workspaceID,
            workspace: action.payload.workspace,
            workspaceBoard: action.payload.data,
            groupIndex: action.payload.groupIndex,
            rowIndex: action.payload.rowIndex,
            messageID:action.payload.messageID,
            isChatNotification: action.payload.isChatNotification
         }
      case SEARCH_FILTER_BOARD_DATA:
         return {
            ...state,
            data: action.payload.boardData
         }
      case UPDATE_BOARD_TAGS_SUGGESTION:
         return {
            ...state,
            cellSuggestions: action.payload.suggestions
         }
      case UPDATE_BOARD_DROPDOWN_SUGGESTION:
         return {
            ...state,
            cellSuggestions: action.payload.suggestion
         }

      case ADD_GROUP:
         return {
            ...state,
            data: JSON.parse(action.payload.data.group),
            storeData: JSON.parse(action.payload.data.group),
         }
      case UPDATE_COLUMN_WIDTH:
         return {
            ...state,
            cellSuggestions: action.payload.suggestion
         }
      case ADD_COLUMN:
         return {
            ...state,
            data: JSON.parse(action.payload.data.group),
            storeData: JSON.parse(action.payload.data.group),
            cellSuggestions: action.payload.data.columnHeaders
         };
      case ADD_ROW:
         return {
            ...state,
            data: JSON.parse(action.payload.workspaceBoard.group),
            storeData: JSON.parse(action.payload.workspaceBoard.group),
         }
      case DUPLICATE_ROW:
            return {
               ...state,
               data: JSON.parse(action.payload.workspaceBoard.group),
               storeData: JSON.parse(action.payload.workspaceBoard.group),
            }
      case REMOVE_GROUP:
         return {
            ...state,
            data: removeGroupItems(state.data, action.payload.groupInd),
            storeData: removeGroupItems(state.data, action.payload.groupInd),
         }
      case REMOVE_ROW:
         return {
            ...state,
            data: removeRowItems(state.data, action.payload.groupInd, action.payload.rowInd),
            storeData: removeRowItems(state.data, action.payload.groupInd, action.payload.rowInd),
         }
      case REMOVE_COLUMN:
         return {
            ...state,
            data: JSON.parse(action.payload.data.group),
            storeData: JSON.parse(action.payload.data.group),
            cellSuggestions: action.payload.data.columnHeaders
         }
      case DRAG_DROP:
         return {
            ...state,
            data: alterDragDropData(state.data, action.payload.items),
            storeData: alterDragDropData(state.data, action.payload.items),
         }
      case CHANGE_ITEM:
         return {
            ...state,
            data: alterBoardItem(state.data, action.payload.groupInd, action.payload.rowInd, action.payload.colInd, action.payload.body, action.payload.lastUpdate),
            storeData: alterBoardItem(state.data, action.payload.groupInd, action.payload.rowInd, action.payload.colInd, action.payload.body, action.payload.lastUpdate),
         };
      case CHNAGE_GROUP_TITLE:
         return {
            ...state,
            data: alterGroupTitle(state.data, action.payload.groupInd, action.payload.groupTitle),
            storeData: alterGroupTitle(state.data, action.payload.groupInd, action.payload.groupTitle),
         }
      case CHANGE_GROUP_MEMBERS:
         return {
            ...state,
            data: alterGroupMembers(state.data, action.payload.groupInd, action.payload.groupMembers),
            storeData: alterGroupMembers(state.data, action.payload.groupInd, action.payload.groupMembers),
         }
      case CHANGE_GROUP_COLOR:
         return {
            ...state,
            data: alterGroupColor(state.data, action.payload.groupInd, action.payload.color),
            storeData: alterGroupColor(state.data, action.payload.groupInd, action.payload.color),
         }
      case CHANGE_GROUP_COLUMN_TITLE:
         return {
            ...state,
            data: JSON.parse(action.payload.groups),
            storeData: JSON.parse(action.payload.groups),
         }
      case CHANGE_GROUP_COLUMN_MEMBERS:
         return {
            ...state,
            data: JSON.parse(action.payload.groups),
            storeData: JSON.parse(action.payload.groups),
         }
         case CHANGE_GROUP_COLUMN_STATUS_PROGRESS:
            return {
               ...state,
               data: JSON.parse(action.payload.groups),
               storeData: JSON.parse(action.payload.groups),
            }
      case CHANGE_GROUP_LIST_ROW_TITLE:
         return {
            ...state,
            data: JSON.parse(action.payload.workspaceBoard.group),
            storeData: JSON.parse(action.payload.workspaceBoard.group),
         }
      case RELOAD_WORKSPACE:
         return {
            ...state,
            loadWorkspace: action.payload.reload
         }
      case CHANGE_STATUS_LABELS:
         return {
            ...state,
            cellSuggestions: action.payload.items
         }
      case CHANGE_BOARD_NUMBER_UNIT:
         return {
            ...state,
            numbersProperties: changeNumbersUnit(state.numbersProperties, action.payload.unit, action.payload.columnId)
         }

      case CHANGE_BOARD_NUMBER_DIRECTION:
         return {
            ...state,
            numbersProperties: changeNumberDirection(state.numbersProperties, action.payload.direction, action.payload.columnId)
         }
      case CHANGE_BOARD_NUMBER_ACTION:
         return {
            ...state,
            numbersProperties: changeNumberAction(state.numbersProperties, action.payload.actionType, action.payload.columnId)
         }
      case UPDATE_BORD_GROUP_DATA:
         return {
            ...state,
            data: JSON.parse(action.payload.workspaceBoard.group),
            storeData: JSON.parse(action.payload.workspaceBoard.group)
         }
      case UPDATE_WORKSPACE_BOARD_MEMBERS:
         return {
            ...state,
            members: JSON.parse(action.payload.workspaceBoard.members)

         }
      case SEND_BOARD_CHAT_MESSAGE:

         return {
            ...state,
            message: action.payload.message

         }
         case UPDATE_UNREAD_MESSAGE_COUNT:

            return {
               ...state,
               unreadMsgCount: action.payload.unreadMsgCount
   
            }
      case UPDATE_MESSAGE_LIKES:

         return {
            ...state,
            message: action.payload.message

         }
         case UPDATE_MESSAGE_REMINDER:

            return {
               ...state,
               message: action.payload.message
   
            }
         case UPDATE_MESSAGE_SEEN_BY:

         return {
            ...state,
            message: action.payload.message

         }
         case UPDATE_NESTED_MESSAGE_LIKES:

            return {
               ...state,
               reply: action.payload.reply
   
            }
      case UPDATE_CHATROOM_COLUMNS:

         return {
            ...state,
            chatroom: action.payload.chatroom

         }
      case GET_CHATROOM_BY_ID:
         return {
            ...state,
            chatroom: action.payload.chatroom

         }
      case SEND_MESSAGE_REPLY:

         return {
            ...state,
            reply: action.payload.reply

         }
      default:
         return state;
   }
};
