import React from 'react';
import { connect } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { createImageFromInitials } from '../../../../../util/People';
import { format } from 'date-fns';
import { getLocationTime } from '../../../../../util';

class PeopleItemShowDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            copied: false,
            authUserEmail: ''
        }
    }

    componentDidMount() {
        let authUser = JSON.parse(localStorage.getItem('authUser'));
        this.setState({ authUserEmail: authUser.email })
        if (this.state.copied) {
            setTimeout(() => {
                this.setState({ copied: false })
            }, 3000)
        }
    }

    getTimeFormat = (timezone) => {
        let now = new Date();

        let date = now.toTimeString();
        console.log("date", date)
    }

    render() {
        // let date = this.getTimeFormat(this.props.people.timezone)
        return (
            <div className="people-info">
                <div className="people-info-content">
                    <div className="people-info-body p-2">
                        <div className="row">
                            <div className="col-4">
                                <div className="text-center">
                                    <img src={createImageFromInitials(80, this.props.people.name, this.props.people.color)} style={{ borderRadius: '50%' , width : '60px' , height : 'auto'  }} />
                                </div>
                            </div>
                            <div className="col-8 d-flex align-items-center mt-2">
                                <div>
                                    <h5><i className="fa fa-user" style={{ color : '#666' }}></i> {this.props.people.name}</h5>
                                    <p><i className="fa fa-map-marker" style={{ color : '#666' }}></i> {format(getLocationTime(this.props.people.timezoneOffset), 'hh:mm a')}, Beijing </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="people-info-footer" >
                        <div className="d-flex w-100">
                            <div className="text-center w-100 p-2"
                                onMouseEnter={() => this.setState({ open: true })}
                                onMouseLeave={() => this.setState({ open: false })}
                            >
                                <div className='w-100' style={{ backgroundColor : '#007bff' , color : '#fff' ,  borderRadius : '5px' , padding : '5px 3px 5px' }}>
                                    Contact Details
                                </div>
                                {this.state.open ? (
                                    <div className="people-info-contact">
                                        <div className='people-info-contact-detail'>
                                            <div className='w-100 text-left d-flex'>
                                                <i className='fa fa-envelope-o' style={{ 'margin-right' : '5px' , 'margin-top' : '5px' }}></i> <span style={{ verticalAlign : 'middle' }}>{this.props.people.email}</span>
                                            </div>
                                            {this.state.copied ? (
                                                <button className='btn btn-success'>
                                                    <i className="fa fa-check"></i>
                                                </button>
                                            ) : (
                                                    <CopyToClipboard text={this.props.people.email}
                                                        onCopy={() => this.setState({ copied: true })}>
                                                        <button className='btn btn-primary'>Copy</button>
                                                    </CopyToClipboard>
                                                )}

                                        </div>
                                    </div>
                                ) : ''}
                            </div>
                            {this.state.authUserEmail !== this.props.people.email ? (
                                <div className="text-center w-100 p-2" style={{ borderLeft: '1px solid #000000', display: this.state.authUserEmail === this.props.people.email ? 'none' : '' }}>
                                    <i className='fa fa-commenting-o'></i> <span>Ask for an Update</span>
                                </div>
                            ) : ''}

                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth }
}

const mapDispatchToProps = {}
export default connect(mapStateToProps)(PeopleItemShowDetail);
