import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import GroupTitle from '../titles/GroupTitle';
import AddRow from './AddRow';
import BoardGroupBottom from './BoardGroupBottom';
import DroppableRow from './DroppableRow';
import axios from '../../../../../util/Api';
class DroppableList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupTitle: this.props.label,
            isWorkspaceOwner: this.props.isWorkspaceOwner,
            isWorkspaceAdmin: this.props.isWorkspaceAdmin,
            isBoardAdmin: this.props.isBoardAdmin,
            editRowRestrictedMembers: this.props.editRowRestrictedMembers,
            permission: this.props.permission,
            onRowHover: false,

        }
        this.authUser = JSON.parse(localStorage.getItem("authUser"));
    }

    componentDidMount() {

        this.setState({ isWorkspaceOwner: this.props.isWorkspaceOwner })
        this.setState({ isWorkspaceAdmin: this.props.isWorkspaceAdmin })
        this.setState({ isWorkspaceAdmin: this.props.isBoardAdmin })
        this.setState({ editRowRestrictedMembers: this.props.editRowRestrictedMembers })
        this.setState({ permission: this.props.permission })
    }


    componentWillReceiveProps(nextProps) {
        this.setState({ isWorkspaceOwner: nextProps.isWorkspaceOwner })
        this.setState({ isWorkspaceAdmin: nextProps.isWorkspaceAdmin })
        this.setState({ isBoardAdmin: nextProps.isBoardAdmin })
        this.setState({ editRowRestrictedMembers: nextProps.editRowRestrictedMembers });
        this.setState({ permission: this.props.permission })
    }

    filterRowForRestrictedMembers = (cellItems, cat) => {
        if (this.state.isWorkspaceOwner || this.state.isWorkspaceAdmin || this.state.isBoardAdmin) {
            return true;
        }
        if (cat === 'header') {
            return true;
        }
        if (this.state.editRowRestrictedMembers ? this.state.editRowRestrictedMembers.includes(this.authUser._id) : false) {
            let Index = cellItems.findIndex(cell => cell.key === 'people');
            if (cellItems[Index].body ? cellItems[Index].body.includes(this.authUser._id) : false) {
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }

    }


    filterRowForRestrictedViews = (cellItems, cat) => {
        if (this.state.isWorkspaceOwner || this.state.isWorkspaceAdmin || this.state.isBoardAdmin || this.state.permission != 'edit-personal') {
            return false;
        }

        let Index = cellItems.findIndex(cell => cell.key === 'people');
        if (cellItems[Index].body ? cellItems[Index].body.includes(this.authUser._id) : false) {
            return true;
        } else {
            return false;
        }
    }
    render() {
       
        const { _id, groupItems, label, tint, members, color } = this.props;
       
        return (
            <Droppable droppableId={_id}>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <div className={`holder card p-2 holder--tint-${tint} ${(members ? members.includes('ViewOnly_' + this.authUser._id) : false) ? "disablediv" : ""}`}
                            style={{ position: "relative", display: 'inline-flex', 'border': 'none',
                             'backgroundColor': '#efefef', marginLeft : '-6px' , marginBottom : '0px' , 'min-width' : '100%'}}>
                            <GroupTitle id={_id} groupInd={this.props.groupInd} groupTitle={label} {...groupItems} groupDragProvided={this.props.groupDragProvided} provided={this.props.provided} />
                            <div className='holder__content'>
                                <div className='list panel-collapse collapse show' id="table-collapse" >
                                    {groupItems
                                        .filter(items => this.state.permission == 'edit-personal' ? this.filterRowForRestrictedMembers(items.cellItems, items.category) : true)
                                        .map((item, index) => {
                                            if (item.category === 'header') {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div
                                                            className='list__item drag-feature'
                                                            style={{borderLeft: '5px solid', borderColor: color?color:'#bdbdbd' , 
                                                            textAlign : 'center' , 'background-color' : '#E6E3E3' }}
                                                        >
                                                            <div className=''>
                                                                <DroppableRow
                                                                    {...item}
                                                                    groupId={_id}
                                                                    groupInd={this.props.groupInd}
                                                                    rowInd={index}
                                                                    targetAreaIndex={this.props.targetAreaIndex}
                                                                    category={item.category}
                                                                    groupItemProvided={null}
                                                                    isWorkspaceOwner={this.state.isWorkspaceOwner}
                                                                    isWorkspaceAdmin={this.state.isWorkspaceAdmin}
                                                                    isBoardAdmin={this.state.isBoardAdmin}
                                                                    cellSuggestions={this.props.cellSuggestions}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className='list__item d-none'
                                                        // key={item.id}
                                                        >
                                                            <Draggable
                                                                draggableId={item._id}
                                                                index={index}
                                                            >
                                                                {(provided) => {
                                                                    return (
                                                                        <div
                                                                            className='group-row'
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={provided.innerRef}
                                                                        >

                                                                        </div>
                                                                    );
                                                                }}
                                                            </Draggable>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            } else {
                                                return (
                                             
                                            <>

                                       
                                                       {/* <div style= {{ backgroundColor : this.state.color?this.state.color:'#bdbdbd' , 'width' : '6px' , 'left' : '-1px' , 'position' : 'sticky' , 'height': '35px'  , 'z-index' : '8888'  }}></div>  */}
                                                  
                                                    <div 
                                                    className={`list__item ${this.state.onRowHover=== item._id?'#EAEAEA':'bg-white'}`}
                                                       // className='list__item'
                                                        //  className={this.filterRowForRestrictedViews(item.cellItems)?'list__item':'list__item disablediv'}
                                                       
                                                        style={{ borderLeft: '5px solid', 
                                                        borderColor: color?color:'#bdbdbd',
                                                        }}
                                                        key={item._id}
                                                        onMouseOver={() => {
                                                            this.setState({onRowHover:item._id})
                                                            
            
                                                        }}
                                                        onMouseOut={() => {this.setState({onRowHover:false})}} 
                                                    >
                                                        <Draggable
                                                            draggableId={item._id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => {
                                                                // console.log(" Provided: ", provided);
                                                                return (
                                                                    <div
                                                                        className='group-row'
                                                                        {...provided.draggableProps}
                                                                        ref={provided.innerRef}
                                                                        style={{
                                                                            height: snapshot.isDragging ? '0px !important' : 'auto',
                                                                            ...provided.draggableProps.style,
                                                                        }}
                                                                    // {...provided.dragHandleProps}
                                                                    >
                                                                        {/* {item.id} */}
                                                                        <div>
                                                                            <DroppableRow
                                                                                {...item}
                                                                                groupId={_id}
                                                                                groupInd={this.props.groupInd}
                                                                                rowInd={index}
                                                                                targetAreaIndex={this.props.targetAreaIndex}
                                                                                category={item.category}
                                                                                groupItemProvided={provided}
                                                                                isWorkspaceOwner={this.state.isWorkspaceOwner}
                                                                                isWorkspaceAdmin={this.state.isWorkspaceAdmin}
                                                                                isBoardAdmin={this.state.isBoardAdmin}
                                                                                cellSuggestions={this.props.cellSuggestions}
                                                                                boardID={this.props.boardID}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    
                                                                );
                                                            }}
                                                            
                                                        </Draggable>
                                                      
                                                    </div>
                                                 
                                                 </>   
                                                )
                                            }
                                        })}
                                    {provided.placeholder}
                                    <AddRow groupInd={this.props.groupInd} id={_id} {...groupItems} />
                                    <BoardGroupBottom groupInd={this.props.groupInd} groupId={_id} groupItems={groupItems} 
                                     cellSuggestions={this.props.cellSuggestions}
                                     boardID={this.props.boardID}
                                    />
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                  
                )}
            </Droppable >
        
        );
       
    }
 
}

// const mapDispatchToProps = { changeGroupTitle };

// export default connect(() => ({}), mapDispatchToProps)(DroppableList);

export default DroppableList;
