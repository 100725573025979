import React, { Component } from 'react';
import RegisterTextInput from './register-text-input';
import RegisterSelectOption from './register-select-options';
import LoginInputInputFisrt from './login-text-input_1';
import LoginInputInputSecond from './login-text-input_2';
import LoginCheckBoxInput from './login-checkbox-input';
import RegisterCheckBox from './register-checkbox';
import UserProfileTextInput from './user-profile-text-input';
import UserProfileSelectOption from './user-profile-select-options';
import UserProfileCalendar from './user-profile-calendar';

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    renderRegisterInput(type = "text", value, label, error, onChange) {   //here default value for type is text it can be changed in any matter...
        return (
            <RegisterTextInput
                type={type}
                value={value}
                label={label}
                onChange={onChange}
                error={error}
               
            />
        );
    }
    renderRegisterSelectOptionsControl(value, onChange, options, label) {
        return (
            <RegisterSelectOption
                value={value}
                onChange={onChange}
                options={options}
                labels={label}
                
            />
        )
    }

    renderRegisterCheckBox(label, isChecked, onValChange) {
        return (
            <RegisterCheckBox
                label={label}
                isChecked={isChecked}
                onValChange={onValChange}
            />
        )
    }

    renderLoginInput_1(type = "text", value, label, onChange, error) {
        return (
            <LoginInputInputFisrt
                type={type}
                value={value}
                label={label}
                onChange={onChange}
                error={error}
            />
        );
    }
    renderLoginInput_2(type = "text", value, label, onChange, error) {
        return (
            <LoginInputInputSecond
                type={type}
                value={value}
                label={label}
                onChange={onChange}
                error={error}
            />
        );
    }

    renderLoginCheckBox(label, text, isChecked, onValChange, openModal) {
        return (
            <LoginCheckBoxInput
                label={label}
                text={text}
                isChecked={isChecked}
                onValChange={onValChange}
                openModal={openModal}
            />
        );
    }

    renderUserProfileInput(type = "text", value, label, error, onChange, fieldtype) {
        return (
            <UserProfileTextInput
                type={type}
                value={value}
                label={label}
                onChange={onChange}
                fieldtype={fieldtype}
                error={error}
            />
        );
    }

    renderUserProfileSelectOptionsControl(value, onChange, options, label, error, fieldtype) {
        return (
            <UserProfileSelectOption
                value={value}
                onChange={onChange}
                options={options}
                label={label}
                fieldtype={fieldtype}
            />
        )
    }

    renderUserProfileCalendar(type = "text", value, label, error, onChange) {
        return (
            <UserProfileCalendar
                type={type}
                value={value}
                label={label}
                onChange={onChange}
                error={error}
            />
        )
    }
}

export default Form;