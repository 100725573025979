import React, { Component } from 'react';
import { useHistory } from 'react-router-dom';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

function StickyMenu() {
  const history = useHistory()
  return (

    <>
      <MainMenu link={`stickymenu`} />
      <div id="whole-layout">
        <NavBar />
        <div id="appdata" className='app-content content fixed-top content-lay-out'>
          <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
          <div className="content-wrapper">
            <div id="mySidenav" className="sidenav">
              <a href="#" className="closebtn" onClick={() => {
                document.getElementById("mySidenav").style.width = "0%";
              }}>&times;</a>
            </div>
            <div className="icon-bar">
              <a className="nav-link nav-link-search cursor-pointer" data-toggle="tooltip" title="Search your Project"><img src="../images/icon/dashboard-icons/white icons-39.png" height="25px" /></a>
              <a href data-action="expand" ><img src="../images/icon/dashboard-icons/module-icons/white icons-27.png" height="25px" style={{ cursor: 'pointer' }} /></a>
              <a href data-action="expand" > <img src='./images/icon/dashboard-icons/module-icons/white icons-27.png' height="25px" style={{ cursor: 'pointer' }} /></a>
              <a href data-toggle="modal" data-target="#default"><img src="./images/icon/dashboard-icons/module-icons/white icons-38.png" height="25px" style={{ cursor: 'pointer' }} /></a>
            </div>
            {/* 

            <td rowSpan={2} style={{width: '50px'}}>
                  <ul className="nav navbar-nav bookmark-icons">
                    <li className="nav-item d-none d-lg-block nav-search" data-placement="top">
                      <a className="nav-link nav-link-search cursor-pointer" data-toggle="tooltip" title="Search your Project">
                        <img src="./images/icon/dashboard-icons/white icons-39.png" />
                      </a>
                    </li>
                  </ul>
                </td>
                <td rowSpan={2} style={{width: '50px'}}><a href data-action="expand" onClick={props.onRefreshClick}><img src="./images/icon/dashboard-icons/module-icons/white icons-28.png" height="25px" style={{cursor: 'pointer'}} /></a></td>
                <td rowSpan={2} style={{width: '50px'}}><a href data-action="expand" onClick={toggle}>
                  <img 
                  src={fullScreen?'./images/icon/dashboard-icons/module-icons/white icons-27.png':'./images/icon/dashboard-icons/module-icons/white icons-27.png'}
                  height="25px" style={{cursor: 'pointer'}} /></a></td>

                <td rowSpan={2} style={{width: '50px'}}><a href data-toggle="modal" data-target="#default"><img src="./images/icon/dashboard-icons/module-icons/white icons-38.png" height="25px" style={{cursor: 'pointer'}} /></a></td>
                <td rowSpan={2} style={{width: '50px'}}><a href><img src="./images/icon/dashboard-icons/module-icons/white icons-37.png" height="25px" style={{cursor: 'pointer'}} /></a></td>
 */}
          </div>
        </div>
      </div>
    </>
  );
}


export default StickyMenu;