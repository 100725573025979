import React from 'react';
import { connect } from 'react-redux';
import { changeBoardItem, updateCellTagsSuggestion, fetchError } from '../../../../actions';
import { checkTags, activeTagsSuggestion, makeid } from '../../../../util/index';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../util/permission';

class TagsItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            newTag: '',
            tags: [],
            suggestions: [],
            actionPermission: 'edit'
        }

        this.dropdownContainer = React.createRef();
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false });
        }
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
        // console.log("this.props.body", this.props);
        let cellSuggestion =  this.props.columnHeader;
        let tagSuggestions = cellSuggestion.find(ele => ele._id === this.props.columnId);

        let tags = JSON.parse(this.props.body);
        this.setState({ tags: tags, suggestions: tagSuggestions.suggestions });

        // if (this.props.boardData.boardID) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem.cellItems;
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentDidUpdate(prevProps, prevStates) {
        // if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem.cellItems;
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    componentWillReceiveProps(nextProps) {
        let cellSuggestion =  this.props.columnHeader;
        let tagSuggestions = cellSuggestion.find(ele => ele._id === nextProps.columnId);
        this.setState({ suggestions: tagSuggestions.suggestions })
    }

    onDelete(i) {
        const tags = this.state.tags.slice(0)
        tags.splice(i, 1)
        this.setState({ tags })
    }

    onAddition(tag) {
        const tags = [].concat(this.state.tags, tag)
        this.setState({ tags })
    }

    onChangeAddTagInput = (event) => {
        this.setState({ newTag: event.target.value })
    }

    removeTags = (index) => {
        const tags = this.state.tags.slice(0);
        tags.splice(index, 1)
        const { groupInd, rowInd, colInd, _id } = this.props;
        this.props.changeBoardItem({
            groupInd: groupInd,
            rowInd: rowInd,
            colInd: colInd,
            body: JSON.stringify(tags),
            cellItemID: _id,
            boardId: this.props.search.boardID
        });
        this.setState({ tags })
    }

    onAddTags = (event) => {
        console.log("event===>", event)
        if (event.keyCode === 13 && event.target.value !== '') {
            let checkTag = checkTags(this.state.tags, this.state.newTag);
            if (!checkTag) {
                var randomColor = "#000000".replace(/0/g, function () { return (~~(Math.random() * 16)).toString(16); });

                // console.log("randomcolor===>", randomColor);

                let newTag = {
                    _id: makeid('tag', 4),
                    name: this.state.newTag,
                    color: randomColor
                }

                const tags = [].concat(this.state.tags, newTag);
                const { groupInd, rowInd, colInd, _id } = this.props;
                this.props.changeBoardItem({
                    groupInd: groupInd,
                    rowInd: rowInd,
                    colInd: colInd,
                    body: JSON.stringify(tags),
                    cellItemID: _id,
                    boardId: this.props.boardData.boardID
                });

                let checkSug = checkTags(this.state.suggestions, this.state.newTag);
                if (!checkSug) {
                    const suggestions = [].concat(this.state.suggestions, newTag);
                    this.props.updateCellTagsSuggestion({
                        boardId: this.props.boardData.boardID,
                        suggestions: suggestions,
                        columnId: this.props.columnId
                    });
                }
                this.setState({ tags: tags, open: false, newTag: '' });
            }
            event.target.value = '';
        }
    }

    addSuggestionTags = (tag) => {
        let checkTag = checkTags(this.state.tags, this.state.newTag);
        if (!checkTag) {
            const tags = [].concat(this.state.tags, tag);
            const { groupInd, rowInd, colInd, _id } = this.props;
            this.props.changeBoardItem({
                groupInd: groupInd,
                rowInd: rowInd,
                colInd: colInd,
                body: JSON.stringify(tags),
                cellItemID: _id,
                boardId: this.props.boardData.boardID
            });
            this.setState({ tags: tags, open: false });
        }
    }

    addNewTags = () => {
        if (this.state.newTag !== '') {
            let checkTag = checkTags(this.state.tags, this.state.newTag);
            if (!checkTag) {
                var randomColor = "#000000".replace(/0/g, function () { return (~~(Math.random() * 16)).toString(16); });

                // console.log("randomcolor===>", randomColor);

                let newTag = {
                    _id: makeid('tag', 4),
                    name: this.state.newTag,
                    color: randomColor
                }

                const tags = [].concat(this.state.tags, newTag);
                const { groupInd, rowInd, colInd, _id } = this.props;
                this.props.changeBoardItem({
                    groupInd: groupInd,
                    rowInd: rowInd,
                    colInd: colInd,
                    body: JSON.stringify(tags),
                    cellItemID: _id,
                    boardId: this.props.boardData.boardID
                });

                let checkSug = checkTags(this.state.suggestions, this.state.newTag);
                if (!checkSug) {
                    const suggestions = [].concat(this.state.suggestions, newTag);
                    this.props.updateCellTagsSuggestion({
                        boardId: this.props.boardData.boardID,
                        suggestions: suggestions,
                        columnId: this.props.columnId
                    });
                }
                this.setState({ tags: tags, open: false });
            }

            this.setState({ newTag: '' });
        }
    }

    render() {
        const { tags, suggestions } = this.state;
        // console.log("tags===>", tags)
        let activeSuggestionTags = activeTagsSuggestion(tags, suggestions);
        return (
            <div className='dropdown-tags-header' ref={this.dropdownContainer} >
                <div className='p-1 tags-cell-component' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '38px' }}
                    onClick={() => {
                        // if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.search.boarddata[this.props.groupInd].members) && getMyColumnEditPermission(this.props.search.boarddata[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                        //     this.setState({ open: true })
                        // } else {
                        //     this.props.fetchError("You don't have permission. You can only view")
                        // }
                    }}
                >
                    <div className='add-hint-component add-button'>
                        <i className='fa fa-plus-circle' style={{ color: '#007bff !important' }}></i>
                    </div>
                    {tags.length > 2 ? (
                        <>
                            <div className='tag-prevent-open-dialog'>
                                {tags.map((tag, index) => (
                                    index < 2 ? (
                                        <div className='ds-text-component' key={index} style={{ color: `${tag.color}` }}># {tag.name}</div>
                                    ) : ""
                                ))}
                            </div>
                            <div className="dropdown-counter-component">
                                <div className="dropdown-counter-text">+{tags.length - 2}</div>
                            </div>
                        </>
                    ) : (
                            <div className='tag-prevent-open-dialog'>
                                {tags.map((tag, index) => {
                                    return (
                                        <div className='ds-text-component' key={index} style={{ color: `${tag.color}` }}># {tag.name}</div>
                                    )
                                })}

                            </div>
                        )}


                </div>
                {this.state.open ? (
                    <div className={`dropdown-tags-body ${this.state.open ? 'show' : ''}`}>
                        <div className='dropdown-tags-component'>
                            <div className='dropdown-tags-list'>
                                {this.state.tags.map((tag, index) => {
                                    return (
                                        <div className='dropdown-tags-item' key={tag._id}>
                                            <div className="dropdown-tag" style={{ color: `${tag.color}` }}># {tag.name}</div>
                                            <div className="dropdwon-tag-remove" style={{ border: `1px solid ${tag.color}` }}
                                                onClick={() => this.removeTags(index)}
                                            >
                                                <i className="fa fa-close" style={{ color: `${tag.color}` }}></i>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='dropdown-tags-edit'>
                                <div className='p-2'>
                                    <div className='dropdown-tags-edit-add p-1'>
                                        <input type='text'
                                            className="tags-input"
                                            placeholder='Add tags'
                                            value={this.state.newTag}
                                            onChange={this.onChangeAddTagInput}
                                            onKeyDown={this.onAddTags}
                                        />
                                    </div>
                                    <div className='dropdown-tags-edit-add p-1'>
                                        {activeSuggestionTags.map((tag, index) => {
                                            return (
                                                <div className="suggetion-tag" key={index} onClick={() => this.addSuggestionTags(tag)}>
                                                    <div className="suggetion-tag-name" style={{ color: `${tag.color}` }}># {tag.name}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='dropdown-tag-edit-add p-1'>
                                        <div className="suggetion-tag-button text-center" onClick={() => this.addNewTags()}>
                                            + Create new tag
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : ''}

            </div>
        )
    }
}

const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = { changeBoardItem, updateCellTagsSuggestion, fetchError }

export default connect(mapStateToProps, mapDispatchToProps)(TagsItem);