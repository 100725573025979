import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { duplicateColumn } from '../../../../../actions/BoardData';
import { removeColumn, fetchError } from '../../../../../actions';
import {  getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission} from '../../../../../util/permission';
import AddColumn from '../body/AddColumn';
import ProgressTrackerCoumnSettings from '../body/ProgressTrackerCoumnSettings';
import PeopleItemAdd from './restrictColumnMembers';
import axios from '../../../../../util/Api';
class GroupColumnEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isModalOpen: false,
            title: this.props.title,
            addColumnOpen: false,
            ProgressColumnSettingOpen: false,
            actionPermission: 'view',
            isWorkspaceOwner: false,
            isWorkspaceAdmin: false,
            boardMembers: [],
        }
        this.dropdownContainer = React.createRef();
        this.modalContainer = React.createRef();
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) this.setState({ open: false });
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
           
        }
        // if (this.authUser._id == this.props.boardData.workspace.CreatedBy) {
        //     this.setState({ isWorkspaceOwner: true })
        //     this.setState({ isWorkspaceAdmin: true })
        // }
        // if (this.props.boardData.workspace.admins.includes(this.authUser._id)) {
        //     this.setState({ isWorkspaceAdmin: true })
        // }
        axios.get('/workspace/getWorkSpaceById/', {
            params: { id: this.props.boardData.workspaceID }
        }).then(({ data }) => {
            let workspace = data.workspaces;           
            this.setState({ workspaceAdmins: workspace.admins })
            this.setState({ workspaceOwner: workspace.CreatedBy })
             if (this.authUser._id == workspace.CreatedBy) {
                this.setState({ isWorkspaceOwner: true })
                this.setState({ isWorkspaceAdmin: true })
            }
            if (workspace.admins.includes(this.authUser._id)) {
                this.setState({ isWorkspaceAdmin: true })
            }
            if(this.state.isWorkspaceOwner || this.state.isWorkspaceAdmin || this.isBoardAdmin()){
                this.setState({ actionPermission: 'edit' })
            }
           
        }).catch(err => {
            console.log("Error: ", err)
        })  
        let boardMembers = this.props.boardData.workspaceBoard.members ? JSON.parse(this.props.boardData.workspaceBoard.members) : [];
        this.setState({ boardMembers: boardMembers })
    }
    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
        if(this.props.boardData.boardID !== prevProps.boardData.boardID){
            axios.get('/workspace/getWorkSpaceById/', {
                params: { id: this.props.boardData.workspaceID }
            }).then(({ data }) => {
                let workspace = data.workspaces;           
                this.setState({ workspaceAdmins: workspace.admins })
                this.setState({ workspaceOwner: workspace.CreatedBy })
               
                if (this.authUser._id == workspace.CreatedBy) {
                    this.setState({ isWorkspaceOwner: true })
                    this.setState({ isWorkspaceAdmin: true })
                }else{
                    this.setState({ isWorkspaceOwner: false })
                    this.setState({ isWorkspaceAdmin: false })
                }
                if (workspace.admins.includes(this.authUser._id)) {
                    this.setState({ isWorkspaceAdmin: true })
                }else{
                    this.setState({ isWorkspaceAdmin: false }) 
                }     
                if(this.state.isWorkspaceOwner || this.state.isWorkspaceAdmin || this.isBoardAdmin()){
                    this.setState({ actionPermission: 'edit' })
                }
               
            }).catch(err => {
                console.log("Error: ", err)
            })  
            let boardMembers = this.props.boardData.workspaceBoard.members ? JSON.parse(this.props.boardData.workspaceBoard.members) : [];
            this.setState({ boardMembers: boardMembers })
        }
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    duplicateColumn = () => {
      
        const boardID = this.props.boardData.boardID;

        if (boardID !== "") {
            this.props.duplicateColumn({
                boardID: boardID,
                key: this.props.cellItems[this.props.colInd].key,
                body: this.props.title,
                leftIndex: this.props.colInd,
                columnId: this.props.columnId
            })
        }
    }
    isBoardAdmin = () => {
   
        let members = this.state.boardMembers;
        let Index = members.findIndex(member => member._id === this.authUser._id);
        if (Index == -1) {
            return false;
        }
        if (members[Index].is_bAdmin) {
            return true;
        } else {
            return false;
        }
    }

    render() {
      
        console.log("this row title props==>1", this.props)  
        return (

        <>

        <div className="dropdown" ref={this.dropdownContainer}>
                <button className="btn dropdown-toggle" style={{ padding: '5px 3px 2px 0' }} type="button"
                    onClick={() => {                      
         
                        if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members) && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                            this.setState({ open: !this.state.open })
                        } else {
                            this.props.fetchError("You don't have permission. You can only view.")
                        }
                    }}
                    onMouseEnter={() => this.setState({ color: true })}
                    onMouseLeave={() => this.setState({ color: false })}
                >
                    {/* <box-icon name='caret-down-square' style={{ width: '18px', height: '18px' }} color={this.state.color ? `#fff` : `#d2d2dd`}></box-icon> */}
                    <i className='fas fa-sort-down column-down-arrow' style={{ 'fontSize' : '15px' , color: '#000000' , 'position' : 'absolute' , 'top': '9px' , 'right' : '10px' , 'cursor' : 'pointer' }} ></i>
                </button>
                {this.state.open ? (
                    <>
                        <ul className={`dropdown-menu multi-level animate slideIn column-header-dropdown ${this.state.open ? `show` : ``}`} role="menu" aria-labelledby="dropdownMenu"
                            style={{ marginLeft: '-100px', boxShadow: '0 4px 17px 6px rgba(0, 0, 0, 0.1)'}}>
                            
                            { (this.state.isWorkspaceAdmin || this.state.isWorkspaceOwner || this.isBoardAdmin()) && this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].body!=='Assignee' && this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].body!=='Followers' && <li>
                            <div className="dropright">
                                <button className="dropdown-toggle ml-n3 bg-transparent dropdown-item btn-group-action" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fal fa-ban" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>  Restrict Members 
                                </button>
                                <div className="dropdown-menu">
                                <form>
                               
                                    <PeopleItemAdd colIndex={this.props.colInd} groupInd={this.props.groupInd} groupItem={this.props.boardData.data[this.props.groupInd]}/>
                              
                                </form>
                                
                                </div>
                                </div>
                            </li>}

                          {this.props.cellItems[this.props.colInd].key === "progressTracking" && <li
                                onMouseLeave={() => { this.setState({ ProgressColumnSettingOpen: false }) }}>
                                <button className="dropdown-item btn-group-action" style={{ padding: '2px 6px' }}
                                    onMouseEnter={() => { this.setState({ ProgressColumnSettingOpen: true }) }}
                                >
                                    <i className="bx bx-plus-circle dot-icons" ></i> <span className="mx-2">Column Settings</span> 
                                    <i className="fal fa-chevron-right" aria-hidden="true" style={{ fontSize: '13px', float: 'right', marginTop: '5px' }}></i>
                                </button>
                                <ul className={`dropdown-menu multi-level add-right-column-dropdown ${this.state.ProgressColumnSettingOpen ? 'show' : ''}`} style={{ position: 'absolute',width: 300 }}>
                                    <ProgressTrackerCoumnSettings colInd={this.props.colInd} groupInd={this.props.groupInd} cellItems={this.props.cellItems} addColumnEvent={() => this.setState({ open: false, ProgressColumnSettingOpen: false })} />
                                </ul>
                            </li>}
                            
                            <li
                                onMouseLeave={() => { this.setState({ addColumnOpen: false }) }}>
                                
                                
                                <button className="dropdown-item btn-group-action" style={{ padding: '2px 6px'}}
                                    onMouseEnter={() => { this.setState({ addColumnOpen: true }) }}
                                >
                                    <i className="fal fa-plus-circle dot-icons" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i> Add Column to the Right
                               

                                    <i className="fal fa-chevron-right" aria-hidden="true" style={{ fontSize: '13px', float: 'right', marginTop: '5px' , marginLeft : '15px' }}></i>
                                </button>
                               
                                <ul className={`dropdown-menu multi-level add-right-column-dropdown ${this.state.addColumnOpen ? 'show' : ''}`} style={{ position: 'absolute' }}>
                                    <AddColumn leftIndex={this.props.colInd} addColumnEvent={() => this.setState({ open: false, addColumnOpen: false })} />
                                </ul>
                            </li>
                            {this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].body!=='Assignee' && this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].body!=='Followers' && <li>
                                <button className="dropdown-item btn-group-action"
                                    style={{ padding: '2px 6px' }}
                                    onClick={() => {
                                        this.setState({ open: false })
                                        this.duplicateColumn()
                                    }}
                                >
                                    <i className="fal fa-copy" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }} ></i>Duplicate Column</button>
                            </li>}
                           { (this.state.isWorkspaceAdmin || this.state.isWorkspaceOwner || this.isBoardAdmin()) && this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].body!=='Assignee' && this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].body!=='Followers' && <li>
                                <button className="dropdown-item btn-group-action"
                                    style={{ padding: '2px 6px' }}
                                    onClick={() => {
                                        this.setState({ open: false })
                                        this.setState({ isModalOpen: true })
                                    }}
                                >
                                    <i class="fal fa-trash-alt" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }} ></i>Delete</button>
                            </li>
    }
                        </ul>
                    </>
                ) : ''}
                <Modal isOpen={this.state.isModalOpen} ref={this.modalContainer}>
                    <ModalHeader toggle={() => this.setState({ isModalOpen: false })}>
                        Delete {this.state.title} column?
                    </ModalHeader>
                    <ModalFooter>
                        <button className='btn btn-light' onClick={() => { this.setState({ isModalOpen: false }) }}>Cancel</button>{' '}
                        <button className='btn btn-primary'
                            onClick={() => {
                                this.props.removeColumn({
                                    colInd: this.props.colInd,
                                    cellItemID: this.props.cellItemID,
                                    columnId: this.props.columnId,
                                    boardId: this.props.boardData.boardID
                                })
                                this.setState({ isModalOpen: false })
                            }}
                        >Delete</button>
                    </ModalFooter>
                </Modal>

            </div>

            </>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData };
}

const mapDispatchToProps = { removeColumn, duplicateColumn, fetchError };

export default connect(mapStateToProps, mapDispatchToProps)(GroupColumnEvent);