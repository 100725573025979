import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs,
    onClickTag, onSavedSearchDelete, getHotTags
} from '../../../actions/GlobalSearch'
class Files extends Component {
    constructor(props) {
        super(props)
        this.onTagClick = this.onTagClick.bind(this)
    }
    onTagClick = (i, value) => {
        this.props.onClickTag(i, value)
    }
    componentDidMount() {
    }
    render() {
        const group = this.props.item
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((r, e) => {
                const key = e.name[0];
                if (!r[key]) r[key] = []
                r[key].push(e);
                return r;
            }, {});

        // return <div>
        //     {Object.entries(group)
        //         .map(([key, value], i) => {
        //             return <div key={i} className="card" style={{ textAlign: 'left' }}>
        //                 <div class="card-header"><strong>{key}</strong></div>
        //                 {value.map((item, j) => <div key={j} className="card-body" onClick={() =>this.onTagClick(1,'#'+item.name)}>#{item.name}</div>)}
        //             </div>
        //         })}
        // </div>
        return <div class="row app-file-recent-access">
            {this.props.item.map((item) => (
                <div class="col-md-3 col-6" onClick={()=>{
                    //alert(item.boardId)
                    window.location="/board/"+item.boardId
                }}>
                    <div class="card border shadow-none mb-1 app-file-info">
                        <div class="app-file-content-logo card-img-top">
                            <img class="d-block mx-auto" src={item.path} style={{ 'width': '50%' }} alt="Card image cap" />
                        </div>
                        <div class="card-body p-50">
                            <div class="app-file-recent-details">
                                <div class="app-file-name font-size-small font-weight-bold">{item.name}</div>
                                <div class="app-file-size font-size-small text-muted mb-25">12.85kb</div>
                                <div class="app-file-last-access font-size-small text-muted">Last accessed : 3 hours ago</div>
                            </div>
                        </div>
                    </div>
                </div>
            ))
            }
        </div>
    }
}

const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs, onClickTag,
    onSavedSearchDelete, getHotTags
};

export default connect(mapStateToProps, mapDispatchToProps)(Files);