export const getMyPermission = (permission, members, people = []) => {
  
    let authUser = JSON.parse(localStorage.getItem('authUser'));
    let me = members.find(member => member._id === authUser._id);
    //console.log('meee',members)
    if (me?.permission === 'full') {
        return 'super'
    } else {
        if (permission === 'edit-everything') {
            return 'super'
        } else if (permission === 'edit-content') {
            return 'high';
        } else if (permission === 'edit-personal') {
            if (people.length === 0) {
                return 'low'
            } else {
                let person = people.find(member => member._id === authUser._id);
                if (person) {
                    return 'middle';
                } else {
                    return 'low'
                }
            }
        } else {
            return 'low'
        }
    }
}


export const getMyGroupEditPermission = (restrictedMembers) => {

    let authUser = JSON.parse(localStorage.getItem('authUser'));
    if(restrictedMembers){
        if(restrictedMembers.includes('ViewOnly_'+authUser._id)){
            return false;
        }else{
            return true;
        }
    }else{
        return true;
    }
}


export const getMyColumnEditPermission = (restrictedMembers) => {
    let authUser = JSON.parse(localStorage.getItem('authUser'));
    if(restrictedMembers){
        if(restrictedMembers.includes('ViewOnly_'+authUser._id)){
            return false;
        }else{
            return true;
        }
    }else{
        return true;
    }
}

