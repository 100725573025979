import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import {  toast } from 'react-toastify';
import { showMessage, changeWorkspaceBoard, reloadWorkspace, fetchStart, intialize, updateWorkspaceBoard } from '../../../../../actions';
import axios from '../../../../../util/Api';
import { initialWorkspaceBoardGroupData, cellStatusInitalData } from '../../../../../constants/Dump';
import WorkspaceMembersPopup from "./WorkspaceMembersPopup";
class WorkspaceAction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            modalHeader: "",
            modalContent: "",
            modalInputValue: "",
            modalTextareaValue: "",
            modalType: "",
            boardMemberOpen: false,
            isWorkspaceOwner: false,
            isWorkspaceAdmin: false
        }

        this.modalContainer = React.createRef();
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    componentDidMount() {
        if (this.authUser._id == this.props.category.CreatedBy) {
            this.setState({ isWorkspaceOwner: true })
            this.setState({ isWorkspaceAdmin: true })
        }
        if (this.props.category.admins.includes(this.authUser._id)) {
            this.setState({ isWorkspaceAdmin: true })
        }
    }

    boardMembers = () => {

        this.setState({ boardMemberOpen: true })

    }

    createSubworkspace = () => {
        this.setState({
            isModalOpen: true,
            modalHeader: 'Create New Subworkspace',
            modalContent: 'Subworkspace',
            modalType: "create"
        })
    }

    createBoardForWorkspace = () => {
        this.setState({
            isModalOpen: true,
            modalHeader: 'Create New Workspace Board',
            modalContent: 'Board',
            modalType: "create"
        })
    }

    createSubworkspaceAndBoard = () => {
     
        if (this.state.modalInputValue !== '')
            this.setState({ isModalOpen: false }); {
            if (this.state.modalContent === 'Subworkspace') {
                if(this.state.modalInputValue.length < 1){
                    toast.error('Oops! Name can not be empty');
                    return;
                }
                this.props.fetchStart();
                axios.post('/workspace/createSubworkspace', {
                    workspace_id: this.props.category._id,
                    title: this.state.modalInputValue
                }).then(({ data }) => {
                    if (data.success === 200) {

                        this.props.reloadWorkspace({
                            reload: !this.props.boardData.loadWorkspace
                        });

                        this.props.showMessage(`${this.state.modalInputValue} created successfully!`);
                        this.setState({ modalInputValue: "" });
                    }
                }).catch(err => {
                    this.setState({ isModalOpen: false });
                    console.log("Error====", err)
                })
            } else if (this.state.modalContent === 'Board') {
                if(this.state.modalInputValue.length < 1){
                    toast.error('Oops! Name can not be empty');
                    return;
                }
               
                this.props.fetchStart();
                let admins=[];
                if(this.props.category.CreatedBy != this.authUser._id){
                    admins.push(this.props.category.CreatedBy);
                }
                axios.post('/workspace/createBoardToWorkspace', {
                    title: this.state.modalInputValue,
                    description: this.state.modalTextareaValue,
                    workspaceId: this.props.category._id,
                    group: initialWorkspaceBoardGroupData,
                    cellStatusItems: cellStatusInitalData,
                    wsAdmins: admins.concat(this.props.category.admins)
                }).then(({ data }) => {
                    console.log("new workspace board===>", data)
                    if (data.success === 200) {
                        this.props.showMessage(`${this.state.modalInputValue} created successfully!`);
                        this.props.updateWorkspaceBoard({
                            workspaceBoard: data.workspaceBoard,
                            workspaceID: this.props.category._id,
                        })

                        this.setState({ modalInputValue: "", modalTextareaValue: "" });
                    }
                }).catch(err => {
                    this.setState({ isModalOpen: false });
                    console.log("err==>", err)
                })
            }
        }
    }

    remove = () => {
        this.setState({
            isModalOpen: true,
            modalHeader: `Delete ${this.props.category.title}?`,
            modalType: "remove"
        })
    }

    removeWorkspace = () => {
        this.setState({ isModalOpen: false });
        this.props.fetchStart();
        axios.post('/workspace/deleteWorkSpace', {
            workspace_id: this.props.category._id
        }).then(({ data }) => {
            if (data.success === 200) {
                this.props.showMessage(`${data.workspaceTitle} deleted successfully!`);
                this.props.intialize();
            }
        }).catch(err => {
            console.log("error==>", err)
        })
    }

    renameWorkspace = () => {
        this.setState({
            modalHeader: 'Edit workspace',
            modalContent: 'Workspace',
            modalType: 'rename',
            modalInputValue: this.props.category.title,
            isModalOpen: true
        })
    }

    renameSave = () => {
        if (this.state.modalInputValue !== '') {
            this.setState({ isModalOpen: false });
            this.props.fetchStart();
            axios.post('/workspace/renameWorkspace', {
                id: this.props.category._id,
                title: this.state.modalInputValue
            }).then(({ data }) => {
                if (data.success === 200) {
                    this.props.showMessage('Workspace updated successfully!');
                    this.props.reloadWorkspace({
                        reload: !this.props.boardData.loadWorkspace
                    });

                    this.setState({ modalInputValue: "" })
                }
            })
        }
    }


    render() {
       // console.log('wpaction', this.props)
        return (
            <>
                <div className='d-flex just-content-sb'>
                    <a className='nav-link collapsed py-1 pl-2' href={`#subcategory_${this.props.workspaceInd}`} data-toggle="collapse" data-target={`#subcategory_${this.props.workspaceInd}`}>
                        <span><b><i className="bx bxs-square" style={{ 'color' : 'orange' , 'position' : 'relative' , 'top': '3px' , 'margin-right' : '5px' }}></i> {ReactHtmlParser(this.props.category.title)}</b></span>
                    </a>

                    <div className="dropdown">
                        <button className="btn dropdown-toggle btn-nav-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ display: 'flex' }}>
                            <i className="bx bx-dots-vertical-rounded dot-icons" style={{ 'border-radius' : '20px' , 'background-color' : '#4189f6' , 'color' : '#fff' }}></i>
                        </button>
                        <ul className="dropdown-menu animate slidein first-dropdown" role="menu" aria-labelledby="dropdownMenu" 
                      >
                            {this.state.isWorkspaceOwner && <li className="dropdown-item">
                                <button className="btn-sub-nav"
                                    onClick={() => this.renameWorkspace()}
                                >
                                    <i className="fal fa-pencil icon-style" style={{ fontSize: '14px' }}></i>&nbsp;&nbsp;Rename</button>
                            </li>
                            }
                            <li className="dropdown-item"
                                onClick={() => this.boardMembers()}
                            >
                                <button className="btn-sub-nav"
                                // onClick={() => this.createBoardForWorkspace()}
                                >
                                    <i className="fal fa-users icon-style" style={{ fontSize: '13px' }}></i>&nbsp;Manage Members</button>
                            </li>
                            <WorkspaceMembersPopup
                                workspaceData={this.props.category}
                               
                                modalOpen={this.state.boardMemberOpen}
                                closeModal={(event) => this.setState({ boardMemberOpen: event })}
                            ></WorkspaceMembersPopup>

                            
                            {this.state.isWorkspaceAdmin && <li className="dropdown-item">
                                <button className="btn-sub-nav"
                                    onClick={() => this.createSubworkspace()}
                                >
                                    <i className="fal fa-plus-circle icon-style" style={{ fontSize: '14px' }}></i>&nbsp;&nbsp;Add New Subworkspace</button>
                            </li>
                            }
                            {this.state.isWorkspaceAdmin && <li className="dropdown-item">
                                <button className="btn-sub-nav"
                                    onClick={() => this.createBoardForWorkspace()}
                                >
                                    <i className="fal fa-plus-circle icon-style" style={{ fontSize: '14px' }}></i>&nbsp;Add New Workspace Board</button>
                            </li>
                            }
                            {this.state.isWorkspaceOwner && <li className="dropdown-item">
                                <button className="btn-sub-nav"
                                    onClick={() => this.remove()}
                                >
                                    <i className="fal fa-trash icon-style" style={{ fontSize: '14px' }}></i>&nbsp;&nbsp;Delete</button>
                            </li>}
                        </ul>
                    </div>
                   
                </div>


                <Modal isOpen={this.state.isModalOpen} ref={this.modalContainer}>
                    <ModalHeader toggle={() => this.setState({ isModalOpen: false })}>
                        {this.state.modalHeader}
                    </ModalHeader>
                    <ModalBody style={{ display: this.state.modalType === 'remove' ? 'none' : '' }}>
                        <div className='row'>
                            <label className='col-4' htmlFor='workspace-title'>{this.state.modalContent} Name</label>
                            <div className='col-8'>
                                <input className='form-control' value={this.state.modalInputValue} id='workspace-title' onChange={(e) => { this.setState({ modalInputValue: e.target.value }) }} />
                            </div>
                        </div>
                        <div className='row mt-2' style={{ display: this.state.modalContent === 'Board' ? 'flex' : 'none' }}>
                            <label className='col-4' htmlFor='workspace-desc'>{this.state.modalContent} Description</label>
                            <div className='col-8'>
                                <textarea className='form-control' value={this.state.modalTextareaValue} id='workspace-desc' onChange={(e) => { this.setState({ modalTextareaValue: e.target.value }) }} ></textarea>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className='btn btn-light' onClick={() => { this.setState({ isModalOpen: false }) }}>Cancel</button>{' '}
                        <button className='btn btn-primary'
                            style={{ display: this.state.modalType === 'create' ? 'black' : 'none' }}
                            onClick={() => this.createSubworkspaceAndBoard()}
                        >Create</button>
                        <button className='btn btn-danger'
                            style={{ display: this.state.modalType === 'remove' ? 'black' : 'none' }}
                            onClick={() => this.removeWorkspace()}
                        >Delete</button>
                        <button className='btn btn-success'
                            style={{ display: this.state.modalType === 'rename' ? 'block' : 'none' }}
                            onClick={() => this.renameSave()}
                        ><i className="fa fa-floppy-o"></i> Save</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { updateWorkspaceBoard, changeWorkspaceBoard, showMessage, reloadWorkspace, fetchStart, intialize };

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceAction)