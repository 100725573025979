import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ModalActivity from './modal-activity';
import ModalClient from './modal-client';
import ModalEvent from './modal-event'
import NavBody from './nav-body';

function NavBar(props) {
  const { user } = props;
  const { location, token } = props;
  if (token === null) return "";
  // const isInvalidPath = (
  //   location.pathname === '/login'
  //   || location.pathname === '/register'
  // );

  // if (isInvalidPath) return null;
  else return (
    <>
      <NavBody user={user} />
      <div>
        <ModalActivity />
        <ModalClient />
        <ModalEvent />
      </div>
    </>
  );
}

export default withRouter(NavBar);

