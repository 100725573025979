import React, { Component } from 'react';
import RegisterModal from './register-modal.jsx';
import HeaderPart from './register-header-part.jsx';
import RegisterForm from './register-form.jsx';
import RegisterRightSide from './register-righ-side.jsx';
import Notification from '../../notification/notification';

class Registration extends Component {
  state = {
    email: '',
    rememberToken: ''
  }

  componentDidMount() {
    console.log("this.props.location", this.props.location)
    if (this.props.location.state !== undefined) {
      console.log("this.props.location.state.email: ", this.props.location.state.email)
      this.setState({
        email: this.props.location.state.email,
        rememberToken: this.props.location.state.token
      })
    }
  }
  render() {
    return (
      <>
        <div id="whole-layout">
          <div id="appdata" className='app-content content fixed-top messenger-layout'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div className="row" style={{ height: '100vh', backgroundColor: '#ffffff' }}>
                <div className="col-md-6 col-12 px-0" >
                  <div className="card disable-rounded-left mb-0 p-2 h-100 d-flex justify-content-center" style={{ borderRadius: '0px !important', border: 'none' }}>
                    <div className="card-content register" style={{border: 'none', padding: '20px', margin: 'auto' }}>
                      <HeaderPart />
                      <div className="card-body" style={{ marginTop: '-50px' }}>
                        <div className="text-left">
                          <div className="divider-text text-muted">
                            <label className="font-medium-2" style={{ textTransform: 'none', color: '#24214C', fontWeight: '400' }}>Sign Up</label>
                          </div>
                        </div>
                        <br />
                        <RegisterForm rememberToken={this.state.rememberToken} email={this.state.email} />
                      </div>
                    </div>
                  </div>
                </div>
                <RegisterRightSide />
                <RegisterModal />
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default Registration;