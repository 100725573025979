import React, { Component, useState, useEffect } from 'react';
import { Modal } from "react-responsive-modal";
import PropTypes from 'prop-types'
const SchedulerExport = (props) => {
    const [value, setValue] = useState('exporttopdf')
    console.log({ ...props });
    const handleOnExport = (e) => {
        props.onExportClick(value);
        props.onClose();
    }

    return (
        <div>
            <Modal open={props.open} onClose={props.onClose} closeOnOverlayClick={props.closeOnOverlayClick} showCloseIcon={false}>

                <div className="modal-dialog modal-dialog-scrollable" role="document">
                    <div className="modal-content" style={{ width: '520px' }}>
                        <div className="modal-header" style={{ backgroundColor: '#22386B', color: '#fff', height: '45px', fontSize: '14px' }}>
                            <h4 className="modal-title  text-white" id="myModalLabel1">Save As</h4>
                            <button type="button" className="close-pink" data-dismiss="modal" aria-label="Close" onClick={props.onClose}>
                                <i className="bx bx-x" style={{ color: '#ffffff', marginTop: '2px' }}></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group row">
                                <div className="col-lg-2"></div>
                                <div className="col-lg-4">
                                    <label className="form-control-label" style={{ color: '#575756', paddingTop: 'unset !important' }}>Report Type:</label>
                                </div>
                                <div className="col-lg-4">
                                    <div className="custom-control-inline">
                                        <div className="" style={{ display: 'flex' }}>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value={value}
                                                    checked={value === "exporttopdf"}
                                                    onChange={() => setValue("exporttopdf")}
                                                />
                                                <img src="images/icon/dashboard-icons/module-icons/pdficon.png" width="24" height="24" />
                                            </label>
                                        </div>
                                        <div className="" style={{ display: 'flex' }}>

                                            <label>
                                                <input
                                                    type="radio"
                                                    value={value}
                                                    checked={value === "exporttoexcel"}
                                                    onChange={() => setValue("exporttoexcel")}
                                                />
                                                <img src="images/icon/dashboard-icons/module-icons/excelicon.png" width="24" height="24" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-2"></div>

                            </div>
                        </div>
                        <div className="modal-footer">
                            <div className="col-lg-12 text-center">

                                <button type="button" className="btn-custom-1" id="dwld" onClick={handleOnExport}>Download</button> &nbsp;&nbsp;&nbsp;
                </div>
                        </div>
                    </div>
                </div>

            </Modal>
        </div>
    );

}
SchedulerExport.defaultProps = {
    closeOnOverlayClick: true
}

export default SchedulerExport;