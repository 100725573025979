/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import http from '../../services/http-service';
import * as CityConstant from './constant';
import * as CountryConstant from '../country/constant.jsx';
import * as StateConstant from '../state/constant';
import CityTable from './cityTable';
import Modal from './modal';
import Joi from 'joi-browser';
import StaticHTML from '../common/static-html.jsx';
import Form from '../common/Form.jsx';
import MainMenu from '../../../shared/components/main-menu';
import NavBar from '../../../shared/components/navbar/navbar';
import Notification from '../../notification/notification';
import UserService from "../../auth/auth-services/user-service";

var options_country = [];
var options_state = [];

class City extends Form {
  constructor(props) {
    super(props);
    let _currentUSer = UserService.getUserByToken("token").id;

    this.state = {
      selectedOption_country: null,
      selectedOption_state: null,
      Id: "",
      CreatedBy: _currentUSer,
      ModifiedBy: _currentUSer,
      IsDeleted: false,
      IsActive: true,
      //load data
      cityData: [],
      TableData: [],
      //paging
      pageSize: 5,
      currentPage: 1,
      //validation
      countryToValidate: { Name: "", CountryId: "", StateId: "" },
      errorCityName: "",
      errorCountryId: "",
      errorStateStateId: "",
      isUpdate: false,
    };
  }

  schema = {
    Name: Joi.string().required().label("Name"),
    CountryId: Joi.string().required().label("Country"),
    StateId: Joi.string().required().label("State"),
  };

  async resetStateChange() {
    this.setState({
      selectedOption_state: "",
    });
  }

  handleChange_country = (selectedOption_country) => {
    //reset state options//
    this.resetStateChange();
    this.setState({ selectedOption_country }, () =>
      console.log(
        `selectedOption_country selected:`,
        this.state.selectedOption_country
      )
    );
    this.setState({
      countryToValidate: {
        ...this.state.countryToValidate,
        CountryId: selectedOption_country.value,
      },
    });
    //Load States
    options_state = [];
    //Get State Data
    http
      .get(
        StateConstant.State_GetBYCOUNTR_URL +
          `?countryId=${selectedOption_country.value}`
      )
      .then((data) => {
        data.data.message.map((data) => {
          options_state.push({ value: data._id, label: data.Name });
        });
      });

    if (this.state.countryToValidate.StateId != "") {
      this.setState({ isUpdate: true });
    }
  };

  handleChange_state = (selectedOption_state) => {
    this.setState({ selectedOption_state }, () =>
      console.log(
        `selectedOption_state selected:`,
        this.state.selectedOption_state
      )
    );
    this.setState({
      countryToValidate: {
        ...this.state.countryToValidate,
        StateId: selectedOption_state.value,
      },
    });

    if (this.state.countryToValidate.CountryId) {
      this.setState({ isUpdate: true });
    }
  };

  async componentDidMount() {
    this.loadFirstData();
  }

  loadFirstData = async () => {
    try {
      //Get Country Data
      await http.get(CountryConstant.Country_GetALL_URL).then((data) => {
        data.data.response.map((data) => {
          options_country.push({ value: data._id, label: data.Name });
        });
      });
      //Get City Data
      await http.get(CityConstant.City_GetALL_URL).then((data) => {
        this.state.cityData = data.data.response;
      });
      let cityList = this.state.cityData.filter(
        (x) =>
          x.IsActive === true &&
          x.IsDeleted === false &&
          (x.CreatedBy != null) & (x.ModifiedBy != null) &&
          x.CountryId != null &&
          x.StateId != null
      );
      cityList.map((data, index) => {
        this.state.TableData.push({
          itemId: data._id,
          h_0: index + 1,
          h_1: data.Name,
          h_2: data.CountryId.Name,
          h_3: data.StateId.Name,
          h_4: data.CreatedBy.firstname,
          h_5: data.createdAt,
          h_6: data.ModifiedBy.firstname,
          h_7: data.updatedAt,
        });
      });
      this.setState({
        TableData: this.state.TableData,
      });
    } catch (e) {
      alert(
        "Some Thing Went Wrong In Load Data , Please Reload Page Again Or Contact With Support..."
      );
      console.log(e.message, "Error Message");
    }
  };

  checkStateIdError = () => {
    if (this.state.countryToValidate.StateId == "") {
      const error = this.validateProperty(
        this.state.countryToValidate.StateId,
        this.schema.StateId
      );
      this.setState({ errorStateStateId: error });
      this.setState({ isUpdate: false });
      return true;
    }
  };

  checkCountryIdError = () => {
    if (this.state.countryToValidate.CountryId == "") {
      const error = this.validateProperty(
        this.state.countryToValidate.CountryId,
        this.schema.CountryId
      );
      this.setState({ errorCountryId: error });
      this.setState({ isUpdate: false });
      return true;
    }
  };

  SaveOrUpdate = async () => {
    this.checkStateIdError();
    this.checkCountryIdError();
    if (this.checkStateIdError() || this.checkCountryIdError()) return;

    // this.handleSaveSubmit();
    const data = {
      id: this.state.Id,
      Name: this.state.countryToValidate.Name,
      CountryId: this.state.countryToValidate.CountryId,
      StateId: this.state.countryToValidate.StateId,
      CreatedBy: this.state.CreatedBy,
      ModifiedBy: this.state.ModifiedBy,
      IsDeleted: this.state.IsDeleted,
      IsActive: this.state.IsActive,
    };
    this.doSaveOrUpdate(
      data,
      CityConstant.City_ADD_URL,
      CityConstant.City_Update_URL
    );
  };

  loadCity = async (id) => {
    await http.get(CityConstant.City_GetByID_URL + `?id=${id}`).then((data) => {
      this.setState({
        countryToValidate: {
          Name: data.data.response.Name,
          CountryId: data.data.response.CountryId,
          StateId: data.data.response.StateId,
        },
      });
      this.setState({
        Id: data.data.response._id,
      });
    });
    //Get Country
    await http
      .get(
        CountryConstant.Country_GetByID_URL +
          `?id=${this.state.countryToValidate.CountryId}`
      )
      .then((data) => {
        this.setState({
          selectedOption_country: {
            value: data.data.response.id,
            label: data.data.response.Name,
          },
        });
      });
    //Get State
    await http
      .get(
        StateConstant.State_GetByID_URL +
          `?id=${this.state.countryToValidate.StateId}`
      )
      .then((data) => {
        this.setState({
          selectedOption_state: {
            value: data.data.response.id,
            label: data.data.response.Name,
          },
        });
      });
    //Load State options Data
    http
      .get(
        StateConstant.State_GetBYCOUNTR_URL +
          `?CountryId=${this.state.countryToValidate.CountryId}`
      )
      .then((data) => {
        data.data.message.map((data) => {
          options_state.push({ value: data._id, label: data.Name });
        });
      });
  };

  //Soft Delete????
  deleteRecord = async (id) => {
    this.doDeleteRecord(id, CityConstant.City_DELETE_URL);
  };

  onChangeCityName = (e) => {
    if (e.target.value != "") this.setState({ isUpdate: true })
    this.setState({ countryToValidate: { ...this.state.countryToValidate, Name: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.Name);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorCityName: error })
  }

  resetModal = () => {
    this.setState({
      countryToValidate: { Name: "", CountryId: "", StateId: "" },
    });
    this.setState({
      Id: "",
    });
    this.setState({
      selectedOption_country: "",
    });
    this.setState({
      selectedOption_state: "",
    });
  };

  render() {
    const { selectedOption_country, selectedOption_state, TableData, countryToValidate, errorCityName,
      errorCountryId, errorStateStateId, isUpdate } = this.state;
    return (
      <>
        <MainMenu link={`city`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <StaticHTML />
                <div className="content-wrapper" >
                  <div className="company-table">
                    <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                      <div style={{ float: 'left' }}>
                        <h5 className="left-head">City</h5>
                      </div>
                      <div style={{ float: 'right', marginTop: '-2px', marginRight: '-15px' }}>
                        <a onClick={this.resetModal} title="Reply" data-toggle="modal" data-target="#modal-Country">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;New City <i className="bx bxs-plus-circle" /></span>
                        </a>
                      </div>
                    </div>
                    <CityTable TableData={TableData} loadCity={this.loadCity} deleteRecord={this.deleteRecord} />
                  </div> {/* End Top Section*/}
                </div> {/* ENd Company Table*/}

                <Modal Name={countryToValidate.Name} onChangeCityName={this.onChangeCityName} selectedOption_country={selectedOption_country}
                  options_country={options_country} selectedOption_state={selectedOption_state} options_state={options_state}
                  handleChange_country={this.handleChange_country} handleChange_state={this.handleChange_state} SaveOrUpdate={this.SaveOrUpdate}
                  errorCityName={errorCityName} isUpdate={isUpdate} errorCountryId={errorCountryId} errorStateStateId={errorStateStateId}
                />
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}

export default City;
