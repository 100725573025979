import React from 'react';

class MainAction extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div style={{ width: "60%" }}>
                <div className="dropdown">
                    <button
                        className="btn dropdown-toggle"
                        type="button"
                        id="dropdownMenu1"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{
                            position: "relative",
                            top: "10px",
                            color: "#000000",
                            padding: '2px' , 
                            marginRight : '10px' 
                        }}
                    >
       
                        <i className="fal fa-table pr-2"></i>&nbsp;<span style={{ verticalAlign:' middle' , marginRight : '10px' }}>Main Table </span>
                        <i className="fal fa-chevron-down"></i>
                    </button>
                    <ul
                        className="dropdown-menu multi-level animate slideIn mainactiondrop"
                        role="menu"
                        aria-labelledby="dropdownMenu"
                        style={{
                            fontFamily: "myriad-pro",
                            fontWeight: "300 !important",
                            marginLeft: "2px",
                            width: "220px",
                        }}
                    >

                        <h4 style={{fontSize: '15px', padding: '16px 20px 0px', height: '45px', 
                        backgroundColor: '#21376b', display: 'flex', justifyContent: 'center',
                         color: '#fff'}}>Select View </h4>


                        <li className="dropdown-item">
                            <a href="#">
                                <i className="fal fa-table icons-right-board"></i>
                                <span class="icon-position">Table</span>
                            </a>
                        </li>
                        <li className="dropdown-item">
                            <a href="#">
                                {/* <i className="fa fa-calendar-alt icons-right-board"></i> */}
                                <i className="fal fa-stream icons-right-board"></i>
                                <span class="icon-position">Timeline</span>
                            </a>
                        </li>
                        <li className="dropdown-item">
                            <a href="#">
                                <i class="fal fa-calendar icons-right-board" ></i>
                                <span class="icon-position">Calendar</span>
                            </a>
                        </li>
                        <li className="dropdown-item">
                            <a href="#">
                                <i class="fal fa-clipboard icons-right-board"></i>
                                <span class="icon-position">Kanban</span>
                            </a>
                        </li>
                        <li className="dropdown-item">
                            <a href="#">
                                {/* <i className="fa fa-chart-line icons-right-board" ></i> */}
                                <i class="fal fa-chart-bar icons-right-board icons-right-board"></i>
                                <span class="icon-position">Chart</span>
                            </a>
                        </li>

                        <li className="dropdown-item">
                            <a href="#">
                                <i className="fa fa-wpforms icons-right-board" ></i>
                                <span class="icon-position">Form</span>
                            </a>
                        </li>
                        <li className="dropdown-item">
                            <a href="#">
                                <i className="fal fa-tasks icons-right-board" ></i>
                                <span class="icon-position">Work Load</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default MainAction