import React from 'react';
import { connect } from 'react-redux';
import DropdownItemEdit from './DropdownItemEdit';
import { checkTags, activeTagsSuggestion, searchDropdownLabels, makeid } from '../../../../../util/index';
import { changeBoardItem, updateCellDropdownSuggestion, fetchError } from '../../../../../actions';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../../util/permission';

class DropdownItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openEdit: false,
            newLabel: '',
            labelLists: [],
            suggestLabels: [],
            actionPermission: 'edit'
        }

        this.dropdownContainer = React.createRef();
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false, openEdit: false });
        }
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);

        let cellSuggestion = this.props.boardData.cellSuggestions;
        // let columnHeader = this.props.boardData.data[0].groupItems[0].cellItems[this.props.colInd];
        let suggestLabels = cellSuggestion.find(ele => ele._id === this.props.columnId);

        const labelLists = JSON.parse(this.props.body);
        this.setState({ labelLists: labelLists, suggestLabels: suggestLabels.suggestions })

        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem.cellItems;
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem.cellItems;
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        let cellSuggestion = nextProps.boardData.cellSuggestions;
        // let columnHeader = nextProps.boardData.data[0].groupItems[0].cellItems[this.props.colInd];
        // console.log("columnId===>", nextProps.columnId);
        let suggestLabels = cellSuggestion.find(ele => ele._id === nextProps.columnId);
        this.setState({ suggestLabels: suggestLabels.suggestions })
    }

    addDropdown = (label) => {
        let labelLists = this.state.labelLists;
        labelLists.push(label);
        const { groupInd, rowInd, colInd, _id } = this.props;
        this.props.changeBoardItem({
            groupInd: groupInd,
            rowInd: rowInd,
            colInd: colInd,
            body: JSON.stringify(labelLists),
            cellItemID: _id,
            boardId: this.props.boardData.boardID
        });
        this.setState({ labelLists: labelLists, open: false, openEdit: false })
    }

    removeDropdown = (label) => {
        let index = this.state.labelLists.findIndex(item => item._id === label._id);
        const labels = this.state.labelLists.slice(0);
        labels.splice(index, 1);
        const { groupInd, rowInd, colInd, _id } = this.props;
        this.props.changeBoardItem({
            groupInd: groupInd,
            rowInd: rowInd,
            colInd: colInd,
            body: JSON.stringify(labels),
            cellItemID: _id,
            boardId: this.props.boardData.boardID
        });
        this.setState({ labelLists: labels, open: false, openEdit: false })
    }

    addNewDropdownLabel = (event) => {
        if (event.keyCode === 13 && event.target.value !== '') {
            let checkSuggestion = checkTags(this.state.suggestLabels, this.state.newLabel);
            if (!checkSuggestion && this.state.newLabel !== "") {
                let suggestLabels = this.state.suggestLabels;
                let newLabel = {
                    _id: makeid('dropdown', 4),
                    name: this.state.newLabel
                }

                suggestLabels.push(newLabel);
                this.props.updateCellDropdownSuggestion({
                    boardId: this.props.boardData.boardID,
                    suggestions: suggestLabels,
                    columnId: this.props.columnId
                })

                this.setState({ suggestLabels: suggestLabels, newLabel: "" })
            }
        }
    }

    createNewLabel = () => {
        let checkSuggestion = checkTags(this.state.suggestLabels, this.state.newLabel);
        if (!checkSuggestion && this.state.newLabel !== "") {
            let suggestLabels = this.state.suggestLabels;
            let newLabel = {
                _id: makeid('dropdown', 4),
                name: this.state.newLabel
            }

            suggestLabels.push(newLabel);
            this.props.updateCellDropdownSuggestion({
                boardId: this.props.boardData.boardID,
                suggestions: suggestLabels,
                columnId: this.props.columnId
            })

            this.setState({ suggestLabels: suggestLabels, newLabel: "" })
        }
    }


    removeLabel = (label) => {
        let labels = this.state.suggestLabels;
        let index = labels.findIndex(x => x._id === label._id);

        const newLabels = labels.slice(0);
        newLabels.splice(index, 1);
        this.props.updateCellDropdownSuggestion({
            boardId: this.props.boardData.boardID,
            suggestions: newLabels,
            columnId: this.props.columnId
        })
        this.setState({ suggestLabels: newLabels });
    }

    updateLable = (label, name) => {
        let labels = this.state.suggestLabels;
        let index = labels.findIndex(x => x._id === label._id);
        labels[index].name = name;
        this.props.updateCellDropdownSuggestion({
            boardId: this.props.boardData.boardID,
            suggestions: labels,
            columnId: this.props.columnId
        })
        this.setState({ suggestLabels: labels });
    }

    renderAddLabelTemplate = (labels) => {

        let template = [];
        let count = labels.length / 3 + 1;
        for (let i = 0; i < count; i++) {
            let children = [];
            for (let j = 0; j < 3; j++) {
                if (labels[i * 3 + j] !== undefined) {
                    children.push(labels[i * 3 + j]);
                }
            }
            if (children.length > 0) {
                template.push(children);
            }
        }

        return template;
    }

    showDropdowns = (labels, suggestions) => {
        // console.log("suggestions: ", suggestions)
        let newLabels = [];
        for (let i = 0; i < labels.length; i++) {
            let label = labels[i];
            let newLabel = suggestions.find(dropdown => dropdown._id === label._id);
            newLabels.push(newLabel);
        }

        return newLabels;
    }

    render() {
        // console.log("this. dropdown props===>", this.props);

        let labels = this.showDropdowns(this.state.labelLists, this.state.suggestLabels);

        let templates = this.renderAddLabelTemplate(labels);
        let activeSuggestionLabels = activeTagsSuggestion(labels, this.state.suggestLabels);

        let suggetionLabels = searchDropdownLabels(activeSuggestionLabels, this.state.newLabel);

        let editLabels = searchDropdownLabels(this.state.suggestLabels, this.state.newLabel);
        // console.log("editLabels ===>", editLabels)

        return (
            <div className='dropdown-items' ref={this.dropdownContainer} style={{ position: 'relative' }}>
                <div className="dropdown-cell-item" onClick={() => {
                    if (this.state.actionPermission === 'edit'  && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                        this.setState({ open: true })
                    } else {
                        this.props.fetchError("You don't have permission. You can only view")
                    }
                }}>
                    <div className="add-hint-component add-button">
                        <i className='fa fa-plus-circle' style={{ color: '#007bff !important' }}></i>
                    </div>
                    <div className="dropdown-option-wrapper">
                        {
                            labels.length > 2 ? (
                                <>
                                    <div className="option-names with-counter-options">
                                        {labels.map((label, index) => (
                                            index < 2 ? (
                                                <div className="option-name cell_focused" key={index}>
                                                    <div className="ds-text-component">{label?.name}</div>
                                                </div>
                                            ) : ''
                                        ))}
                                    </div>
                                    <div className="dropdown-counter-component">
                                        <div className="dropdown-counter-text">+{labels.length - 2}</div>
                                    </div>
                                </>
                            ) : (
                                    <>
                                        <div className="option-names with-counter-options">
                                            {labels.map((label, index) => (
                                                <div className="option-name cell_focused" key={index}>
                                                    <div className="ds-text-component">{label?.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )
                        }
                    </div>
                </div>
                {this.state.open ? (
                    <div className={`drowpdown-edit ${this.state.open ? 'show' : ''}`}>
                        <div className="p-2">
                            <div className="multiple-items-box-component dropdown-chosen-options-wrapper">
                                {templates.map((template, index) => (
                                    <div className="item-tokens-line" key={index}>
                                        {template.map((item, index_1) => (
                                            <div className="single-box-item-component item-token can-change-content" key={index_1}>
                                                <div className="item-wrapper">
                                                    <div className="dropdown-item-name">{item.name}</div>
                                                </div>
                                                <div className="clear-button" onClick={() => {
                                                    if (this.state.actionPermission === 'edit'  && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                                                        this.removeDropdown(item)
                                                    } else {
                                                        this.props.fetchError("You don't have permission. You can only view")
                                                    }
                                                }}>
                                                    <i className="fa fa-close"></i>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                            <div>
                                <input type='text'
                                    className="add-dropdown-item"
                                    placeholder="Create or find labels"
                                    onChange={(event) => this.setState({ newLabel: event.target.value })}
                                    onKeyUp={(event) => {
                                        if (this.state.actionPermission === 'edit'  && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                                            this.addNewDropdownLabel(event)
                                        } else {
                                            this.props.fetchError("You don't have permission. You can only view")
                                        }
                                    }}
                                />
                            </div>
                            <div className="dropdown-label-list">
                                <div className="dropdown-label-suggest" style={{ display: `${this.state.openEdit ? 'none' : ''}` }}>
                                    {suggetionLabels.map((label, index) => (
                                        <div className="pt-1 pb-1" key={index}>
                                            <div className="dropdown-label-item" onClick={() => this.addDropdown(label)}>{label.name}</div>
                                        </div>
                                    ))}
                                </div>
                                <div className="dropdown-label-suggest" style={{ display: `${!this.state.openEdit ? 'none' : ''}` }}>
                                    {editLabels.map((label, index) => (
                                        <div key={index}>
                                            <DropdownItemEdit label={label} removeLabel={() => this.removeLabel(label)} updateLabel={(name) => this.updateLable(label, name)} />
                                        </div>

                                    ))}

                                </div>
                            </div>
                            <div className="mt-1">
                                <button type='button' className="btn btn-add w-100" onClick={() => this.createNewLabel()} >+ Create new label</button>
                            </div>
                            <div className="mt-1" style={{ display: `${this.state.openEdit ? 'none' : ''}` }}>
                                <button type='button' className="btn btn-add w-100" onClick={() => { this.setState({ openEdit: true }) }} >Edit labels</button>
                            </div>
                            <div className="mt-1" style={{ display: `${!this.state.openEdit ? 'none' : ''}` }}>
                                <button type='button' className="btn btn-add w-100" onClick={() => { this.setState({ openEdit: false }) }}>Apply</button>
                            </div>
                        </div>
                    </div>
                ) : ''}

            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeBoardItem, updateCellDropdownSuggestion, fetchError }

export default connect(mapStateToProps, mapDispatchToProps)(DropdownItem)