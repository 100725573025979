/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { RichEditor } from '../components/editor/editor'
import People from '../components/people/people'
import messageDataService from '../data-services/message-data-services'
import http from '../components/services/http-service'
import config from '../components/services/config.json'
import moment from 'moment'
import $ from 'jquery'
import Modal from './fileupload'
import './allrightslide.css'
const socket = require('socket.io-client')(config.baseUrlEndPoint, {
  transports: ['websocket'],
  rejectUnauthorized:   false,
})

function openFile (file) {
  var extension = file.substr(file.lastIndexOf('.') + 1)
  switch (extension) {
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'JPG':
      return file
      break
    case 'zip':
    case 'rar':
      return 'icons/zip.png'
      break
    case 'pdf':
      return 'icons/pdf.png'
      break
    case 'txt':
      return 'icons/txt.png'
      break
    case 'ae':
      return 'icons/ae.png'
      break
    case 'ai':
      return 'icons/ai.png'
      break
    case 'au':
      return 'icons/au.png'
      break
    case 'avi':
      return 'icons/avi.png'
      break
    case 'br':
      return 'icons/br.png'
      break
    case 'css':
      return 'icons/css.png'
      break
    case 'csv':
      return 'icons/csv.png'
      break
    case 'dbf':
      return 'icons/dbf.png'
      break
    case 'doc':
    case 'docx':
      return 'icons/doc.png'
      break
    case 'dw':
      return 'icons/dw.png'
      break
    case 'dwg':
      return 'icons/dwg.png'
      break
    case 'exe':
      return 'icons/exe.png'
      break
    case 'fi':
      return 'icons/fi.png'
      break
    case 'fla':
      return 'icons/fla.png'
      break
    case 'fw':
      return 'icons/fw.png'
      break
    case 'html':
      return 'icons/html.png'
      break
    case 'id':
      return 'icons/id.png'
      break
    case 'iso':
      return 'icons/iso.png'
      break
    case 'js':
      return 'icons/js.png'
      break
    case 'json':
      return 'icons/json.png'
      break
    case 'mp3':
      return 'icons/mp3.png'
      break
    case 'mp4':
      return 'icons/mp4.png'
      break
    case 'pi':
      return 'icons/pi.png'
      break
    case 'ppt':
      return 'icons/ppt.png'
      break
    case 'pr':
      return 'icons/pr.png'
      break
    case 'ps':
      return 'icons/ps.png'
      break
    case 'psd':
      return 'icons/psd.png'
      break
    case 'rtf':
      return 'icons/rtf.png'
      break
    case 'svg':
      return 'icons/svg.png'
      break
    case 'xls':
    case 'xlsx':
      return 'icons/xls.png'
      break
    case 'xml':
      return 'icons/xml.png'
      break
    default:
      return 'icons/other.png'
  }
}

function MessageModal ({ showvalue, setMessageShow }) {
  const [showmmodal, setShowModal] = useState(showvalue)
  const [messages, setMessages] = useState([])
  const [show, setShow] = useState(false)
  //const [file, setFile] = useState('')

  const [data, getFile] = useState({ name: '', path: '' })
  const [progress, setProgess] = useState(0) // progess bar
  const el = useRef() // accesing input element
  const closeModal = () => {
    setMessageShow()
  }
  const handleChange = e => {
    setProgess(0)
    const file = e.target.files // accesing file
    console.log(file)
    const formData = new FormData()
    for (const key of Object.keys(file)) {
      formData.append('file', file[key])
    }
    http
      .post(config.apiEndPoint + 'message/upload', formData, {
        onUploadProgress: ProgressEvent => {
          let progress =
            Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) + '%'
          setProgess(progress)
        }
      })
      .then(res => {
        console.log(res)
        getFile({
          name: res.data.name,
          path: config.baseUrlEndPoint + res.data.path
        })
      })
      .catch(err => console.log(err))
  }

  let history = useHistory()
  useEffect(() => {
    http
      .get(config.apiEndPoint + messageDataService.getAllMessages)
      .then(res => {
        console.log(res.data.data)
        setMessages(res.data.data)
      })
    socket.on('CreateMessage', data => {
      setMessages(messages => [...messages, data])
    })
    socket.on('DeletedMessage', data => {
      alert(data)
      setMessages(messages =>
        messages.map(el => (el._id === data._id ? { ...el, status: data.status } : el))
      )
    })
  }, [])
  const displayStyle =
    showmmodal == true ? 'modal right fade show' : 'modal right fade'
  return (
    <>
      <div
        class={displayStyle} //'modal right fade'
        style={{ display: showmmodal ? 'block' : 'none' }}
        id='myModal2'
        tabIndex={-1}
        role='dialog'
        aria-labelledby='myModalLabel2'
      >
        <div class='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-header'>
              <div style={{ width: '90%', 'padding-left': '10px' }}>
                <button class='custom-task-button'>Task Completed</button>
              </div>
              {/* <input
                    type='file'
                    ref={el}
                    onChange={handleChange}
                    multiple='multiple'
                  />
                  <div className='progessBar' style={{ width: progress }}>
                    {progress}
                  </div> */}
              <i
                class='fa fa-paperclip'
                aria-hidden='true'
                style={{ 'font-size': '20px' }}
                data-toggle='tooltip'
                data-title='Upload Attachment'
                onClick={() => {
                  setShow(true)
                }}
              ></i>
              <button
                class='btn dropdown-toggle'
                type='button'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
                style={{ display: 'flex', float: 'right' }}
              >
                <i
                  id='dot-icon-workspace'
                  class='bx bx-dots-horizontal-rounded dot-icons'
                  style={{
                    'background-color': '#EAEAEA !important',
                    'border-radius': '100px'
                  }}
                ></i>
              </button>

              <button
                type='button'
                className='close'
                data-dismiss='modal'
                aria-label='Close'
                style={{
                  'margin-right': '10px',
                  height: '27px',
                  'margin-top': '-20px'
                }}
                data-toggle='tooltip'
                data-title='Close'
                onClick={() => {
                  closeModal()
                }}
              >
                <span aria-hidden='true' style={{ 'font-size': '13px' }}>
                  ×
                </span>
              </button>
            </div>
            <div
              className='modal-body'
              style={{
                'overflow-y': 'auto',
                'background-color': '#ffffff'
                // 'max-height': '700px'
              }}
            >
              <div class='modal-inner-content'>
                <div style={{ height: '200px' }}>
                <People/>
                </div>
                <div class='col-md-12'>
                  <div class='d-flex align-items-center mb-1'>
                    <div class='avatar mr-75'>
                      <img
                        src='https://www.pixinvent.com/demo/frest-bootstrap-laravel-admin-dashboard-template/demo-1/images/portrait/small/avatar-s-3.jpg'
                        alt='charlie'
                        width='38'
                        height='38'
                      />
                    </div>
                    <div
                      class='avatar-content'
                      style={{ 'min-width': '60%', 'max-width': '65%' }}
                    >
                      <b>Sanal created this task</b>
                    </div>
                    <small
                      class='ml-75 text-muted'
                      style={{ width: '30%', 'text-align': 'right' }}
                    >
                      13 days ago
                    </small>
                  </div>
                  <hr />
                  {  messages.map(item => (
                    <div
                      class='d-flex'
                      style={{
                        'word-break': 'break-all',
                        'margin-bottom': '20px'
                      }}
                    >
                      <div class='avatar mr-75'>
                        <img
                          src='https://www.pixinvent.com/demo/frest-bootstrap-laravel-admin-dashboard-template/demo-1/images/portrait/small/avatar-s-3.jpg'
                          alt='charlie'
                          width='38'
                          height='38'
                        />
                      </div>
                      {item.status == 0 &&<div class="avatar-content" style={{'min-width':' 60%', 'max-width': '65%'}}> 
                      <i>This message was deleted</i>
                      </div>}
                      {item.type == 1 && item.status == 1 && (
                        <div
                          class='avatar-content'
                          style={{
                            'min-width': '60%',
                            'max-width': '65%'
                          }}
                        >
                          <b>
                            {item.createdby.firstname +
                              ' ' +
                              item.createdby.lastname}
                          </b>

                          {/* <button
                            class='btn '
                            type='button'
                            id='dropdownMenu2'
                            data-toggle='dropdown'
                            aria-haspopup='true'
                            aria-expanded='false'
                            style={{ display: 'flex' }}
                          >
                           
                          </button> */}
                          <i
                            class='bx bx-dots-horizontal-rounded cursor-pointer '
                            id={item._id}
                            data-toggle='dropdown'
                          ></i>
                          <ul
                            class='dropdown-menu'
                            role='menu'
                            aria-labelledby={item._id}
                            style={{
                              'margin-left': '14px',
                              'margin-top': '-14px'
                            }}
                          >
                            <li
                              class='dropdown-item'
                              data-toggle='modal'
                              data-target='#modal-BussinessCat'
                              data-backdrop='true'
                            >
                              <a href='#'>
                                <i class='bx bx-reply icon-style'></i>
                                Reply
                              </a>
                            </li>
                            <li
                              class='dropdown-item'
                              data-toggle='modal'
                              data-target='#browseworkspace'
                            >
                              <a
                                onClick={() => {
                                  let data = {
                                    id: item._id,
                                    status: 0
                                  }
                                  http
                                    .put(
                                      config.apiEndPoint +
                                        messageDataService.updatemessage,
                                      data
                                    )
                                    .then(res => {
                                      // console.log(res.data)
                                      //setMessages(res.data.data)
                                    })
                                }}
                              >
                                <i class='bx bx-trash icon-style'></i>
                                Delete
                              </a>
                            </li>
                          </ul>

                          <label
                            dangerouslySetInnerHTML={{
                              __html:
                                item.status == 1
                                  ? item.message
                                  : 'This message was deleted'
                            }}
                            style={{
                              'font-size': '.9rem',
                              'text-transform': 'none',
                              width: '100%'
                            }}
                          ></label>
                        </div>
                      )}
                      {item.type == 2 && item.status == 1 &&
                        openFile(item.messagedet) == item.messagedet && (
                          <div
                            class='avatar-content'
                            style={{
                              'min-width': '60%',
                              'max-width': '65%'
                            }}
                          >
                            <b>
                              {item.createdby.firstname +
                                ' ' +
                                item.createdby.lastname}
                            </b>
                            <span>{' ' + item.message}</span>
                            <i
                              class='bx bx-dots-horizontal-rounded cursor-pointer'
                              id={item._id}
                              data-toggle='dropdown'
                            ></i>
                            <ul
                              class='dropdown-menu'
                              role='menu'
                              aria-labelledby={item._id}
                              style={{
                                'margin-left': '14px',
                                'margin-top': '-14px'
                              }}
                            >
                              <li
                                class='dropdown-item'
                                data-toggle='modal'
                                data-target='#modal-BussinessCat'
                                data-backdrop='true'
                              >
                                <a href='#'>
                                  <i class='bx bx-reply icon-style'></i>
                                  Reply
                                </a>
                              </li>
                              <li
                                class='dropdown-item'
                                data-toggle='modal'
                                data-target='#browseworkspace'
                              >
                                <a onClick={() => {
                                  let data = {
                                    id: item._id,
                                    status: 0
                                  }
                                  http
                                    .put(
                                      config.apiEndPoint +
                                        messageDataService.updatemessage,
                                      data
                                    )
                                    .then(res => {
                                      // console.log(res.data)
                                      //setMessages(res.data.data)
                                    })
                                }}>
                                  <i class='bx bx-trash icon-style'></i>
                                  Delete
                                </a>
                              </li>
                            </ul>
                            <img
                              src={
                                config.baseUrlEndPoint +
                                '/' +
                                openFile(item.messagedet)
                              }
                              style={{
                                width: '80%',
                                'box-shadow': ' 0px 0px 5px 2px #d5d2d2',
                                'margin-top': '12px'
                              }}
                            alt=""/>
                          </div>
                        )}
                      {item.type == 2 && item.status == 1 &&
                        openFile(item.messagedet) != item.messagedet && (
                          <div
                            class='avatar-content'
                            style={{
                              'min-width': '60%',
                              'max-width': '65%'
                            }}
                          >
                            <b>
                              {item.createdby.firstname +
                                ' ' +
                                item.createdby.lastname}
                            </b>
                            <span>{' ' + item.message}</span>
                            <i
                              class='bx bx-dots-horizontal-rounded cursor-pointer '
                              id={item._id}
                              data-toggle='dropdown'
                            ></i>
                            <ul
                              class='dropdown-menu'
                              role='menu'
                              aria-labelledby={item._id}
                              style={{
                                'margin-left': '14px',
                                'margin-top': '-14px'
                              }}
                            >
                              <li
                                class='dropdown-item'
                                data-toggle='modal'
                                data-target='#modal-BussinessCat'
                                data-backdrop='true'
                              >
                                <a href='#'>
                                  <i class='bx bx-reply icon-style'></i>
                                  Reply
                                </a>
                              </li>
                              <li
                                class='dropdown-item'
                                data-toggle='modal'
                                data-target='#browseworkspace'
                              >
                                <a onClick={() => {
                                  let data = {
                                    id: item._id,
                                    status: 0
                                  }
                                  http
                                    .put(
                                      config.apiEndPoint +
                                        messageDataService.updatemessage,
                                      data
                                    )
                                    .then(res => {
                                      // console.log(res.data)
                                      //setMessages(res.data.data)
                                    })
                                }}>
                                  <i class='bx bx-trash icon-style'></i>
                                  Delete
                                </a>
                              </li>
                            </ul>
                            <div
                              class='d-flex'
                              style={{
                                border: ' 1px solid #EAEAEA',
                                'box-shadow':
                                  'rgb(241 240 240) -1px 1px 4px 1px',
                                padding: '5px',
                                'margin-top': '12px',
                                width: '100%'
                              }}
                            >
                              <div>
                                <img
                                  src={openFile(item.messagedet)}
                                  style={{ width: '80%' }}
                                />
                              </div>

                              <div style={{ 'margin-top': '10px' }}>
                                <h5 style={{ 'font-size': '12.5px' }}>
                                  {item.messagedet}
                                </h5>
                              </div>
                            </div>
                          </div>
                        )}
                      <small
                        class='ml-75 text-muted'
                        style={{ width: '30%', 'text-align': 'right' }}
                      >
                        {moment(item.createdat).format('ll')}
                      </small>
                    </div>
                  ))}
                  {data.path && <img src={data.path} alt={data.name} />}
                </div>
              </div>
            </div>
            <div
              class='modal-footer'
              style={{
                'background-color': 'rgb(249 248 248)',
                height: 'auto'
              }}
            >
              <div id='replyDiv'></div>
              <RichEditor />
            </div>
          </div>
          {/* modal-content */}
        </div>
        {/* modal-dialog */}
      </div>
      {show == true && <Modal show={show} closeModal={closeModal} />}
    </>
  )
}

export default MessageModal
