import React from 'react';

function ModalEmailRequestFailed(props) {
    return (
        <div className="modal popups" data-backdrop="true" data-keyboard="false" id="failedpass" role="dialog" aria-labelledby="failedPass" aria-hidden="true" style={{ margin: '200px auto' }}>
        <div className="modal-content" style={{ minHeight: '282px', margin: '0 auto', padding: '23px 33px 20px 25px', maxWidth: '570px', borderRadius: '25px', overflow: 'hidden' }}>
          <div className="modal-header" style={{ height: '23px', backgroundColor: '#ffffff' }}>
            <h3 className="modal-title" id="failedPass" />
            <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
              <i className="bx bx-x" />
            </button>
          </div>
          <div className="modal-body" style={{ padding: '10px 10px 10px 10px' }}>
            <div className="row">
              <div className="col-md-6">
                <img src="/images/icon/auth/Email-Failed-Request.png" style={{ width: '86%' }} />
              </div>
              <div className="col-md-6">
                <div className="row mt-50">
                  <h4 style={{ color: '#2c4d74' }}>Email Request Failed</h4>
                </div>
                <div className="row mt-50">
                  <span style={{ fontWeight: 300 }}>Email you entered doesn't match our record</span>
                </div>
                <div className="row mt-50">
                  <input type="email" className="form-control h-50" placeholder="Please try again" style={{ fontSize: '13px', fontWeight: 300 }} />
                </div>
                <div className="row mt-2">
                  <button className="btn btn-custom-1" style={{ width: '120px', marginTop: '20px', border: '1px solid #EAEAEA !important' }} >Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ModalEmailRequestFailed;