import React from 'react';
import ReminderList from '../../scheduler/reminder-list';

function ReminderMessangerWidget(props) {
    const { ShowMessangerWidgetValue } = props;
    return (
        <>
            <div>
                {
                    ShowMessangerWidgetValue &&
                    <div className="row border right-section-third p-1" style={{ minHeight: '250px' }}>
                        <div className="col-md-12 col-lg-12 col-xl-12 mb-1" id="top-section">
                            <div style={{ float: 'left', marginLeft: '15px' }}>
                                <h5 className="left-head">Messenger</h5>
                            </div>
                            <div style={{ float: 'right', marginTop: '-2px' }}>
                                <a title="Reply" data-toggle="modal" data-target="#modal-City">
                                    <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                                </a>
                            </div>
                        </div>
                        <div className="col-md-12 recent-call-section d-flex">
                            <div className="avatar mr-75">
                                <span className="avatar-status-online" />
                                <img src="./images/usericon.png" alt="avtar images" style={{ width: '45px', height: '45px' }} />
                            </div>
                            <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '11px', width: '140px' }}>
                                <span style={{ fontSize: '13px', color: '#575756' }}>Christina</span><br />
                                <span style={{ fontSize: '10.5px', color: '#b2b2b2' }}>Let's do it...</span>
                            </div>
                            <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                <span style={{ fontSize: '12px', color: '#999' }}>07:00 PM</span>
                            </div>
                        </div>
                        <div className="col-md-12 recent-call-section d-flex">
                            <div className="avatar mr-75">
                                <span className="avatar-status-online" />
                                <img src="./images/usericon.png" alt="avtar images" style={{ width: '45px', height: '45px' }} />
                            </div>
                            <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '11px', width: '140px' }}>
                                <span style={{ fontSize: '13px', color: '#575756' }}>James</span><br />
                                <span style={{ fontSize: '10.5px', color: '#b2b2b2' }}>see you</span>
                            </div>
                            <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                <span style={{ fontSize: '12px', color: '#999' }}>07:00 PM</span>
                            </div>
                        </div>
                        <div className="col-md-12 recent-call-section d-flex">
                            <div className="avatar mr-75">
                                <span className="avatar-status-online" ></span>
                                <img src="./images/usericon.png" alt="avtar images" style={{ width: '45px', height: '45px' }} />
                            </div>
                            <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '11px', width: '140px' }}>
                                <span style={{ fontSize: '13px', color: '#575756' }}>Maria</span><br />
                                <span style={{ fontSize: '10.5px', color: '#b2b2b2' }}>me too thanks</span>
                            </div>
                            <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                <span style={{ fontSize: '12px', color: '#999' }}>07:00 PM</span>
                            </div>
                        </div>

                        <div className="col-md-12 recent-call-section d-flex">
                            <div className="avatar mr-75">
                                <span className="avatar-status-online" />
                                <img src="./images/usericon.png" alt="avtar images" style={{ width: '45px', height: '45px' }} />
                            </div>
                            <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '11px', width: '140px' }}>
                                <span style={{ fontSize: '13px', color: '#575756' }}>Jane</span><br />
                                <span style={{ fontSize: '10.5px', color: '#b2b2b2' }}>me too thanks</span>
                            </div>
                            <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                <span style={{ fontSize: '12px', color: '#999' }}>07:00 PM</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default ReminderMessangerWidget;