import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import axios from '../../../../util/Api';
import { reloadWorkspace, showMessage } from '../../../../actions';

class WorkspaceEllipsis extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            workspaceTitle: ''
        }

        this.modalContainer = React.createRef();
    }

    createWorkspace = () => {
        if (this.state.workspaceTitle !== '') {
            axios.post('/workspace/createWorkspace', {
                title: this.state.workspaceTitle
            }).then(({ data }) => {
                console.log("data===>", data)

                this.props.showMessage(`${this.state.workspaceTitle} created successfully!`)
                
                this.props.reloadWorkspace({
                    reload: !this.props.boardData.loadWorkspace
                })
            })
        }

        this.setState({ isModalOpen: false })
        this.setState({ workspaceTitle: '' })
    }

    render() {
        
        return (
            <div className="dropdown workspace-ellipsis pl-0 float-left">
                <h4 ><b>Workspaces</b></h4>
                <button className="btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ display: 'flex' }}>
                    <i className="fal fa-cog fa-spin left-section-cog" ></i>
                </button>
                <ul className="dropdown-menu multi-level animate slideIn left-cog-workspace" role="menu" aria-labelledby="dropdownMenu" style={{ marginLeft: '150px' }}>
                    <li className="dropdown-item" data-toggle="modal" data-target="#modal-BussinessCat" data-backdrop="true">
                        <button className="btn-nav"
                            onClick={() => this.setState({ isModalOpen: true })}
                        >
                            <i className="fal fa-plus-circle icon-style"></i>&nbsp; Add New Workspace</button>
                    </li>
                    {/* <li className="dropdown-item" data-toggle="modal" data-target="#browseworkspace">
                        <a >
                            <i className="bx bxs-user-plus icon-style" ></i>&nbsp;Browse All Workspace
                        </a>
                    </li> */}
                    {/* <li className="dropdown-item"><a href="#"><i className="bx bxs-analyse icon-style" ></i>&nbsp;&nbsp;Collapse All</a></li> */}
                </ul>
                {/* <AddWorkspacePopup></AddWorkspacePopup>
                <BrowseWorkspacePopup></BrowseWorkspacePopup> */}
                <Modal isOpen={this.state.isModalOpen} ref={this.modalContainer}  className='right' data-backdrop="true" >
                    <ModalHeader toggle={() => this.setState({ isModalOpen: false })
                }>
                        Create New Workspace
                    </ModalHeader>
                    <ModalBody>
                        <div className='row'>
                            <label className='col-4' htmlFor='workspace-title'>Workspace Name</label>
                            <div className='col-8'>
                                <input className='form-control' value={this.state.workspaceTitle} id='workspace-title' onChange={(e) => { this.setState({ workspaceTitle: e.target.value }) }} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className='btn btn-light' onClick={() => { this.setState({ isModalOpen: false })
                     this.setState({ workspaceTitle: '' })
                    }}>Cancel</button>{' '}
                        <button className='btn btn-primary'
                            onClick={() => this.createWorkspace()}
                        >Create</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { reloadWorkspace, showMessage }

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceEllipsis);