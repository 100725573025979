import React from 'react';
import { connect } from 'react-redux';

import { changeBoardItem, fetchError } from '../../../../actions'
import { getNumberFormat } from '../../../../util';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../util/permission';

class NumbersItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: this.props.body === '' ? '' : parseFloat(this.props.body),
            openEdit: false,
            direction: 'left',
            unit: 'none',
            actionPermission: 'edit'
        }
        this.contentContainer = React.createRef();
        this.textInput = React.createRef();
        this.timeout = 0;
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.openEdit && !this.contentContainer.current.contains(ev.target)) {
            this.setState({ openEdit: false });
        }
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
        if (this.state.openEdit) {
            this.textInput.current.focus();
        }
        if (this.props.search.boardID !== "") {
            let numbersProperties = this.props.search.numbersProperties;
            if (numbersProperties.length > 0) {
                let property = numbersProperties.find(x => x.columnId === this.props.columnId);
                if (property !== undefined) {
                    this.setState({
                        unit: property.unit,
                        direction: property.direction
                    })
                }
            }

            // let workspaceBoard = this.props.boardData.workspaceBoard;
            // let boardPermission = workspaceBoard.permission;
            // let members = JSON.parse(workspaceBoard.members);
            // let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            // let cellItems = groupItem.cellItems;
            // let peoples = cellItems.find(cell => cell.key === 'people');
            // let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            // let permission = getMyPermission(boardPermission, members, people);
            // if((this.props.isWorkspaceAdmin || this.props.isWorkspaceOwner || this.props.isBoardAdmin)){
            //     this.setState({ actionPermission: 'edit' })
            // }else{
            //     if (permission !== 'low' && (getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members) && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers))) {
            //         this.setState({ actionPermission: 'edit' })
            //     } else {
            //         this.setState({ actionPermission: 'view' })
            //     }
            // }
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.state.openEdit) {
            this.textInput.current.focus();
        }

        // if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem.cellItems;
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if((this.props.isWorkspaceAdmin || this.props.isWorkspaceOwner || this.props.isBoardAdmin)){
        //         this.setState({ actionPermission: 'edit' })
        //     }else{
        //         if (permission !== 'low' && (getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members) && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers))) {
        //             this.setState({ actionPermission: 'edit' })
        //         } else {
        //             this.setState({ actionPermission: 'view' })
        //         }
        //     }
        // }
    }
    componentWillReceiveProps(nextProps) {
        let numbersProperties = nextProps.search.numbersProperties;
        if (numbersProperties.length > 0) {
            let property = numbersProperties.find(x => x.columnId === this.props.columnId);
            if (property !== undefined) {
                this.setState({
                    unit: property.unit,
                    direction: property.direction
                })
            }
        }

    }
    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    showEditInput = () => {
        this.setState({ openEdit: true });
        this.textInput.current.focus();
    }

    onChangeNumber = (event) => {
        const re = /^(\d*)((\.(\d{0,6})?)?)$/i;
        if (event.target.value === '' || re.test(event.target.value)) {
            this.setState({ content: event.target.value })
        }
    }

    changeNumber = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            let content = this.state.content === "" ? this.state.content : parseFloat(this.state.content)
            const { groupInd, rowInd, colInd, _id } = this.props;
            this.props.changeBoardItem({
                groupInd: groupInd,
                rowInd: rowInd,
                colInd: colInd,
                body: content,
                cellItemID: _id,
                boardId: this.props.search.boardID
            });
        }, 800);
    }

    render() {
        // console.log("this.props===>", this.props)
        const { body } = this.props

        return (
            <div className='p-1 w-100 text-center' style={{ height: '95%' }} ref={this.contentContainer}>
                <div style={{ display: this.state.openEdit ? 'none' : 'flex', width: '100%', height: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}
                    onClick={() => {
                        // if (this.state.actionPermission === 'edit') {
                        //     this.showEditInput()
                        // } else {
                        //     this.props.fetchError("You don't have permission. You can only view")
                        // }
                    }}
                >
                    {this.state.direction === 'left' ? (
                        <span style={{ display: body === '0' ? 'none' : body === '' ? 'none' : '', fontSize: '16px' }}
                        >
                            {/* {this.state.unit !== 'none' ? `${ReactHtmlParser(this.state.unit)} ` : ''}   */}
                            {getNumberFormat(Math.round(parseFloat(body) * 1000) / 1000)}
                        </span>
                    ) :
                        (
                            <span style={{ display: body === '0' ? 'none' : body === '' ? 'none' : '', fontSize: '16px' }}>
                                {getNumberFormat(Math.round(parseFloat(body) * 1000) / 1000)} 
                                {/* {this.state.unit !== 'none' ? `${ReactHtmlParser(this.state.unit)}` : ''} */}
                            </span>
                        )}

                </div>
                <div style={{ display: !this.state.openEdit ? 'none' : '' }} >
                    <input
                        type='text'
                        className='form-cell-input'
                        ref={this.textInput}
                        style={{ width: '80%' }}
                        value={this.state.content}
                        onChange={(event) => this.onChangeNumber(event)}
                        onKeyUp={() => this.changeNumber()}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ search }) => {
    return { search };
}

const mapDispatchToProps = { changeBoardItem, fetchError }

export default connect(mapStateToProps, mapDispatchToProps)(NumbersItem)