// import http from "../http-common";

// class NotificationDataService {
// //   getAll() {
// //     return http.get("workspace​/getAllWorkSpaces");
// //   }

// //   get(id) {
// //     return http.get(`workspace/getWorkSpaceById/${id}`);
// //   }

//   createSnooze(data) {
//     return http.post("notifications/saveSnooze", data);
//   }

// //   update(id, data) {
// //     return http.put(`​workspace​/updateWorkSpace/${id}`, data);
// //   }

// //   delete(id) {
// //     return http.delete(`​workspace​/deleteWorkSpace/${id}`);
// //   }



// }

// export default new NotificationDataService();

export const  createSnooze = "notifications/saveSnooze"; 

export default {
  createSnooze : createSnooze 
}