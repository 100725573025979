import React, { Component, useState } from 'react'
import PropTypes from "prop-types";
import AvatarUsers from '../avatar/avatarusers';
import DiscoverSettingsDropDown from './discover-settings-dropdown';
import MessageModal from '../../models/message'
import { useHistory, Route, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getSubWorkspaces, getBoards, getSubBoards, getGroups, getGroupItems, getSubWorkspacesL2 }  from "../../actions";

const DiscoverCard = (props) => {
   const history = useHistory();
   const dispatch = useDispatch();
   // const [showModal, setShowModal] = useState(false);
   const [showMessage, setShowMessageModal] = useState(false)
   const [cardBgColor, setCardBgColor] = useState('')
   let bgColor = (props.discoverType === 1 ? '#e8378f' : props.discoverType === 2 ? '#fad42d' : props.discoverType === 3 ? '#fad42d' : props.discoverType === 7 ? '#00ca72' : '#e8378f')
   
   const setMessageShow = () => {
      setShowMessageModal(false)
   }
   const params = {
      _id: props._id,
      discoverName: props.discoverName,
      discoverType: props.discoverType,
      parentId: props.parentId,
      parentType: props.parentType,
      CreatedBy: props.CreatedBy,
      admins: props.admins
   }

   const getSubWorkspace = async () => {

      await dispatch(getSubWorkspaces({ ...params }));

   };
   const getBoard = async () => {

      await dispatch(getBoards({ ...params }));

   };
   const getSubBoard = async () => {

      await dispatch(getSubBoards({ ...params }));

   };
   const getGroup = async () => {

      await dispatch(getGroups({ ...params }));

   };
   const getGroupItem = async () => {

      await dispatch(getGroupItems({ ...params }));

   };
   const getSubWorkspaceL2 = async () => {
      
         await dispatch(getSubWorkspacesL2({ ...params }));
   };
   
   const handleOnClick = () => {



      if (props.isRedirect) {
         if (props.discoverType === 1) {
            history.push({
               pathname:  '/discover-workspace',
               state: { params }
            });
            getSubWorkspace();
            getBoard();
         }
         else if (props.discoverType === 2) {
            history.push({
               pathname: '/discover-subworkspace',
               state: { params }
            });
            getSubWorkspaceL2();
            getBoard();
         }
         else if (props.discoverType === 3) {
            window.location="/board/"+props._id
            
            // history.push({
            //    pathname:'/discover-board',
            //    state: { params }
            // });
         }
         // else if (props.discoverType === 6) {
         //   history.push('/discover-subboard');
         // }
         // else if (props.discoverType === 4) {
         //    history.push('/discover-group');
         // }
         else if (props.discoverType === 7) {
            history.push({
               pathname:'/discover-subworkspace-l2',
               state: { params }
            });
            getBoard();
         }

      }
      else {

        console.log('nnns-->',props);

         props.handleActive(props._id);
          
         if (props.discoverType === 1) {   // Work Space
            getSubWorkspace();
            getBoard();

         }
         else if (props.discoverType === 2) {  // Sub Work Space
            getSubWorkspaceL2();
            getBoard();
         }
         else if (props.discoverType === 3) {   // Board
            //  getSubBoard();
            // alert('board click')
            // return;
            getGroup();
         }
         // else if (props.discoverType === 6) {   // Sub Board
         //   getGroup();
         // }
         // else if (props.discoverType === 4) {   // Group
         //    getGroupItem();
         // }
         // else if (props.discoverType === 5) {   // Group Item/ Task
         //    /* open container  */
         //    setShowMessageModal(true)
         // }
         else if (props.discoverType === 7) {   // Sub Work Space L2
               getBoard();
         }
      }
   };
   return (
      <>
         <div className={props.active === true ? 'row text-center align-items-center justify-content-center mr-3 shadow discovercard active' : 'row text-center align-items-center justify-content-center mr-3 shadow'} style={{ display: 'block', width: '120px', height: '120px', borderRadius: '17px', marginLeft: '15px', marginBottom: '10px', backgroundColor: props.discoverType === 1 ? '#e8378f' : props.discoverType === 2 ? '#7a66df' : props.discoverType === 3 ? '#04b1c3' : props.discoverType === 7 ? '#F2668B' : '#e8378f' }}>


            {/* <i className="bx bx-dots-horizontal-rounded font-medium-3 cursor-pointer bx-align-right" style={{ position: 'relative', marginTop: '10px', marginLeft: '80px', color: '#ffffff' }} onClick={() => setShowModal(true)} />
        <div style={{ cursor: 'pointer', marginTop: '10px' }} title={props.title} onClick={handleOnClick}>
          <div className="align-top" style={{ fontWeight: 100, whiteSpace: 'nowrap', overflow: 'hidden', width: '90px', marginLeft: '15px', color: '#ffffff', fontWeight: '400', 'font-family': 'myriad-pro' }}>
            {props.title} 
          </div>

        </div> */}


            <div className="nav-toggle-workspace" style={{ position: 'relative' }}>
               <button className="btn dropdown-toggle btn-nav-toggle" type="button" id="dropdowMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ display: 'flex' }}>
                  <i className="bx bx-dots-horizontal-rounded font-medium-3 cursor-pointer bx-align-right" style={{ position: 'relative', left : '100px' , marginTop: '10px', color: '#ffffff' }} />
               </button>
               <div style={{ cursor: 'pointer', marginTop: '10px' }} title={props.title} onClick={handleOnClick}>
                  <div className="align-top" style={{ fontWeight: 100, whiteSpace: 'nowrap', overflow: 'hidden', width: '90px', marginLeft: '15px', color: '#ffffff', fontWeight: '400', fontFamily: 'myriad-pro' }}>
                     {props.title}
                  </div>
               </div>

               <DiscoverSettingsDropDown {...params} ></DiscoverSettingsDropDown>


            </div>



            <AvatarUsers></AvatarUsers>
         </div>


         {showMessage == true &&
            <MessageModal
               showvalue={showMessage}
               setMessageShow={setMessageShow}
            />
         }
      </>
   );

}

DiscoverCard.propTypes = {
   handleActive: PropTypes.func.isRequired
}
export default DiscoverCard;
