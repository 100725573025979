import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './allrightslide.css'
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import BoardData from './borad-data/index'
import FilterDropdown from './borad-data/header/FilterDropdown';
import axios from '../../util/Api';
import {
  getTaskManagerData
} from '../../actions/TaskManager'

function AssignedTasks() {
  // const [resultData, setData] = useState([])
  const dispatch = useDispatch();
  const [seacrhvalue, setSearchValue] = useState('')
  const taskmanager = useSelector(state => state.taskmanager);
  const { resultData, filterData } = taskmanager
  const [filtervalue, setFilterValue] = useState({})

  useEffect(() => {
    // axios.get(`/search/getSearchWorkspaceBoardyById/?value=&id=${JSON.parse(localStorage.getItem('authUser'))._id}`).then(({ data }) => {
    //   console.log("board data==>", data)
    //   setData(data.workspaceBoard)
    // }).catch(err => {
    //   console.log("Error:", err)
    // })
    dispatch(getTaskManagerData(JSON.parse(localStorage.getItem('authUser'))._id));
  }, [dispatch])
  const setSearchData = (value) => {
    setFilterValue(value)
  }
  return (
    <>
      <MainMenu link={`tasks`} />
      <div id="whole-layout">
        <NavBar />
        <div id="appdata" className='app-content content fixed-top content-lay-out'>
          <div class="row">
            <div class="col-md-4">
              <fieldset class="form-label-group mb-0 mt-3 ml-3">
                <input type="text" className="form-control" value={seacrhvalue} onChange={(e) => {
                  setSearchValue(e.target.value)
                }} />
                <label class="user-profile-label">Search in task</label>
              </fieldset>
            </div>
          </div>
          <div className="content-wrapper" style={{ 'padding': '20px' }}>
            {
              resultData && resultData.length && filterData && filterData.length && resultData.map((item, index) => {
                let count = 0;
                let group = JSON.parse(item.group)
                item.group = JSON.stringify(group.reduce((p, c) => {
                  return (c.groupItems = c.groupItems.filter(
                    sku =>
                      (sku.cellItems.some(att => (att.key === "people" &&
                        att.body.indexOf(JSON.parse(localStorage.getItem('authUser'))._id) != -1) || sku.category === "header"))
                      //    &&
                      //   sku.cellItems.some(att => att.body.indexOf(JSON.parse(localStorage.getItem('authUser'))._id)!=-1))
                      || sku.category === "header"
                  )).length && p.push(c), p
                }, []
                ))

                let filtGroup = JSON.parse(filterData[index].group)
                filterData[index].group = JSON.stringify(filtGroup.reduce((p, c) => {
                  return (c.groupItems = c.groupItems.filter(
                    sku =>
                      (sku.cellItems.some(att => (att.key === "people" &&
                        att.body.indexOf(JSON.parse(localStorage.getItem('authUser'))._id) != -1) || sku.category === "header"))
                      
                      || sku.category === "header"
                  )).length && p.push(c), p
                }, []
                ))

                let filterGroup = filterData[index].group
                let groups = JSON.parse(filterGroup)
                console.log("groups"+index,groups)

                return (
                  <>
                    <div class="layout__wrapper">
                      {
                        <FilterDropdown groups={groups} columns={item.columnHeaders}
                          boardId={item._id} resultData={resultData} filterData={filterData} filterfunc={setSearchData} />
                      }
                      <BoardData item={item} index={index} key={item._id} boardId={item._id} filtervalue={filtervalue}
                        columnHeader={item.columnHeaders} searchTerm={seacrhvalue} />
                    </div>
                  </>
                )
              }

              )
            }
          </div>
        </div>
      </div>

    </>
  )
}

export default AssignedTasks
