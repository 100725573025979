export const schedule = 'meeting/schedule';
export const history='meeting/historiesbyuserid'
export const update='meeting/updatestatus'
export const search='meeting/searchmeeting'

export default {
    schedule: schedule,
    history:history,
    update:update,
    searchmeeting:search
}