import React, { useState, useEffect } from 'react'
import Toolbar from './toolbar'
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import DatePicker from 'react-datepicker'

import meetingDataService from '../../data-services/meeting-data-services'
import http from '../services/http-service'
import config from '../services/config.json'
import { Jutsu } from 'react-jutsu'
import Modal from 'react-modal';
import './style.css'

const customStyles = {
    content: {

        left: '10%'

    }
};


function Econnect(props) {

    const [schedule, setSchedule] = useState([])
    const [history, setHistory] = useState([])
    const [call, setCall] = useState(false)
    const [room, setRoom] = useState('')
    const [name, setName] = useState('')
    const [userid, setUserId] = useState('')

    const [startDate, setStartDate] = useState(null);

    var subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }



    useEffect(() => {
        getMeetings()
    }, [])
    const getMeetings = () => {
        http
            .get(config.apiEndPoint + meetingDataService.history + "/" + JSON.parse(localStorage.getItem('authUser'))._id)
            .then(res => {
                setSchedule(res.data.schedule)
                setHistory(res.data.history)
            })
    }

    return (
        <>
            <MainMenu link={`discover`} />
            <div id="whole-layout">
                <NavBar />
                <div id="appdata" className='app-content content fixed-top content-lay-out'>
                    {/* <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div> */}
                    <div className="content-wrapper">
                        <div id="mySidenav" className="sidenav">
                            <a href="#" className="closebtn" onClick={() => {
                                document.getElementById("mySidenav").style.width = "0%";
                            }}>&times;</a>
                        </div>
                        <Toolbar getMeetings={getMeetings} />
                        {call == false &&
                            (
                                <div className="content-wrapper" style={{ marginTop: '30px' }}>
                                    <div className="content-body">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <div className="row" style={{ 'margin-left': '15px' }}>
                                                    <div style={{ 'width': '100%', 'font-weight': 'bold' }}>Scheduled Conferences</div>
                                                    {
                                                        schedule.map((item) => {
                                                            var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                                                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                                                            ];
                                                            var d = new Date(item.scheduledate)
                                                            const date = d.getDate();
                                                            const month = d.getMonth();
                                                            const year = d.getFullYear();
                                                            const dates = monthShortNames[month + 1] + " " + date + ", " + year;
                                                            return (
                                                                <div style={{ 'width': '240px', 'margin-right': '15px' }}>
                                                                    <div class="card">
                                                                        <div class="card-header d-flex justify-content-between 
                                                    align-items-center pb-50" style={{ 'background-color': 'white' }}>
                                                                            <h4 class="card-title">{item.conferencename}</h4>
                                                                            <label onClick={() => {
                                                                                http
                                                                                .put(config.apiEndPoint + meetingDataService.update + "/" + item.meetingname, { "status": "Deleted" })
                                                                                .then(res => {
                                                                                    if (res.status == 200) {
                                                                                       getMeetings()
                                                                                    }
                                                                                })
                                                                            }}>X</label>
                                                                        </div>
                                                                        <div class="card-body p-0 pb-1 ps ps--active-y" style={{ 'height': 'auto' }}>
                                                                            <ul class="list-group list-group-flush">
                                                                                <li class="list-group-item"
                                                                                    style={{ 'border-radius': '0px' }}>
                                                                                    <div class="list-left d-flex">
                                                                                        <div class="list-icon mr-1">
                                                                                            <div class="avatar bg-rgba-primary m-0">
                                                                                                <div class="avatar-content" style={{
                                                                                                    'background-color': 'aliceblue',
                                                                                                    'border-radius': '50px',
                                                                                                    'color': 'skyblue',
                                                                                                    'font-weight': 'bold'
                                                                                                }}>
                                                                                                    {item.userid.firstname.charAt(0) + item.userid.lastname.charAt(0)}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="list-content">
                                                                                            <span class="list-title">
                                                                                                {item.userid.firstname + ' ' + item.userid.lastname}
                                                                                            </span>
                                                                                            <small class="text-muted d-block">
                                                                                                {item.userid.position}
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <span>10k</span> */}
                                                                                </li>
                                                                            </ul>
                                                                            <div style={{ 'margin-left': '15px', 'margin-bottom': '15px' }}>
                                                                                {/* <input type="text" value={'https://connect.engagechats.com/' + item.meetingname} id="myInput" /> */}
                                                                                <button class="btn btn-primary" title={'https://connect.engagechats.com/' + item.meetingname}
                                                                                    onClick={() => {
                                                                                        const el = document.createElement('input');
                                                                                        el.value = 'https://connect.engagechats.com/' + item.meetingname;
                                                                                        document.body.appendChild(el);
                                                                                        el.select();
                                                                                        document.execCommand('copy');
                                                                                        document.body.removeChild(el);
                                                                                        alert("Copied " + 'https://connect.engagechats.com/' + item.meetingname + " to clipboard");
                                                                                    }}>Share Invite</button>

                                                                                <button class="btn btn-danger" style={{ 'margin-left': '20px' }} onClick={() => {
                                                                                    var result = ''
                                                                                    if (item.userid._id == JSON.parse(localStorage.getItem('authUser'))._id) {
                                                                                        http
                                                                                            .put(config.apiEndPoint + meetingDataService.update + "/" + item.meetingname, { "status": "Completed" })
                                                                                            .then(res => {
                                                                                                if (res.status == 200) {
                                                                                                    result = res.status
                                                                                                }
                                                                                            })
                                                                                    }
                                                                                    if (result != 200 && result != '') {

                                                                                    } else {
                                                                                        setUserId(item.userid._id)
                                                                                        setRoom(item.meetingname)
                                                                                        setName(item.userid.firstname + ' ' + item.userid.lastname)
                                                                                        setCall(true)
                                                                                        openModal()
                                                                                    }


                                                                                }}>{item.userid._id == JSON.parse(localStorage.getItem('authUser'))._id ? ' Start Meeting' : 'Join Meeting'}</button>
                                                                            </div>
                                                                            <div class="d-flex" style={{ 'margin-left': '15px' }}>
                                                                                <ul class="list-unstyled users-list m-0 d-flex align-items-center">
                                                                                    {item.members.map((mbritem, index) => {
                                                                                        if (index < 4) {
                                                                                            return (<li class="avatar pull-up my-0" style={{ 'width': '35px' }}>
                                                                                                <img class="media-object rounded-circle" src="https://www.pixinvent.com/demo/frest-clean-bootstrap-admin-dashboard-template/app-assets/images/portrait/small/avatar-s-20.jpg"
                                                                                                    alt="Avatar" height="35" />
                                                                                            </li>)
                                                                                        }
                                                                                    })
                                                                                    }
                                                                                </ul>
                                                                                {item.members.length > 4 && "+" + item.members.length - 4 + ' Teams'}
                                                                            </div>

                                                                        </div>
                                                                        <div class="card-footer justify-content-between align-items-center pb-50">
                                                                            <small class="text-muted d-block" style={{ 'text-transform': 'none' }}>Conference Room,{item.meetingname}</small>
                                                                            <small class="text-muted d-block">{dates} {item.scheduletime}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )
                                                        })


                                                    }
                                                </div>



                                                <div className="row" style={{ 'margin-left': '15px' }}>
                                                    <div style={{ 'width': '100%', 'font-weight': 'bold' }}>Hosted Conferences</div>
                                                    {
                                                        history.map((item) => {
                                                            var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                                                                "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                                                            ];
                                                            var d = new Date(item.scheduledate)
                                                            const date = d.getDate();
                                                            const month = d.getMonth();
                                                            const year = d.getFullYear();
                                                            const dates = monthShortNames[month + 1] + " " + date + ", " + year;
                                                            return (
                                                                <div style={{ 'width': '240px', 'margin-right': '15px' }}>
                                                                    <div class="card">
                                                                        <div class="card-header d-flex justify-content-between 
                                                    align-items-center pb-50" style={{ 'background-color': 'white' }}>
                                                                            <h4 class="card-title">{item.conferencename}</h4>
                                                                            <label onClick={() => {
                                                                                 http
                                                                                 .put(config.apiEndPoint + meetingDataService.update + "/" + item.meetingname, { "status": "Deleted" })
                                                                                 .then(res => {
                                                                                     if (res.status == 200) {
                                                                                        getMeetings()
                                                                                     }
                                                                                 })
                                                                            }}>X</label>
                                                                        </div>
                                                                        <div class="card-body p-0 pb-1 ps ps--active-y" style={{ 'height': 'auto' }}>
                                                                            <ul class="list-group list-group-flush">
                                                                                <li class="list-group-item"
                                                                                    style={{ 'border-radius': '0px' }}>
                                                                                    <div class="list-left d-flex">
                                                                                        <div class="list-icon mr-1">
                                                                                            <div class="avatar bg-rgba-primary m-0">
                                                                                                <div class="avatar-content" style={{
                                                                                                    'background-color': 'aliceblue',
                                                                                                    'border-radius': '50px',
                                                                                                    'color': 'skyblue',
                                                                                                    'font-weight': 'bold'
                                                                                                }}>
                                                                                                    {item.userid.firstname.charAt(0) + item.userid.lastname.charAt(0)}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="list-content">
                                                                                            <span class="list-title">
                                                                                                {item.userid.firstname + ' ' + item.userid.lastname}
                                                                                            </span>
                                                                                            <small class="text-muted d-block">
                                                                                                {item.userid.position}
                                                                                            </small>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* <span>10k</span> */}
                                                                                </li>
                                                                            </ul>
                                                                            <div style={{ 'margin-left': '15px', 'margin-bottom': '15px' }}>
                                                                                <button class="btn btn-primary" title={'https://connect.engagechats.com/' + item.meetingname}
                                                                                    onClick={() => {
                                                                                        const el = document.createElement('input');
                                                                                        el.value = 'https://connect.engagechats.com/' + item.meetingname;
                                                                                        document.body.appendChild(el);
                                                                                        el.select();
                                                                                        document.execCommand('copy');
                                                                                        document.body.removeChild(el);
                                                                                        alert("Copied " + 'https://connect.engagechats.com/' + item.meetingname + " to clipboard");
                                                                                    }}>Share Invite</button>
                                                                                <button class="btn btn-danger" style={{ 'margin-left': '20px' }} onClick={() => {
                                                                                    // if (item.userid._id == JSON.parse(localStorage.getItem('authUser'))._id) {
                                                                                    setRoom(item.meetingname)
                                                                                    setUserId(item.userid._id)
                                                                                    setName(item.userid.firstname + ' ' + item.userid.lastname)
                                                                                    setCall(true)
                                                                                    openModal()
                                                                                    // }
                                                                                    // else {
                                                                                    //     alert("You don't have permision to host this meeting!")
                                                                                    // }

                                                                                }}>{item.userid._id == JSON.parse(localStorage.getItem('authUser'))._id ? ' Start Meeting' : 'Join Meeting'}</button>
                                                                            </div>
                                                                            <div class="d-flex" style={{ 'margin-left': '15px' }}>
                                                                                <ul class="list-unstyled users-list m-0 d-flex align-items-center">
                                                                                    {item.members.map((mbritem, index) => {
                                                                                        if (index < 4) {
                                                                                            return (<li class="avatar pull-up my-0" style={{ 'width': '35px' }}>
                                                                                                <img class="media-object rounded-circle" src="https://www.pixinvent.com/demo/frest-clean-bootstrap-admin-dashboard-template/app-assets/images/portrait/small/avatar-s-20.jpg"
                                                                                                    alt="Avatar" height="35" />
                                                                                            </li>)
                                                                                        }
                                                                                    })
                                                                                    }
                                                                                </ul>
                                                                                {item.members.length > 4 && "+" + item.members.length - 4 + ' Teams'}
                                                                            </div>

                                                                        </div>
                                                                        <div class="card-footer justify-content-between align-items-center pb-50">
                                                                            <small class="text-muted d-block" style={{ 'text-transform': 'none' }}>Conference Room,{item.meetingname}</small>
                                                                            {/* <small class="text-muted d-block">{dates} {item.scheduletime}</small> */}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            )
                                                        })


                                                    }
                                                </div>

                                            </div>
                                            <div class="col-md-3">

                                                {/* <div class="row"> */}
                                                <div class="card" style={{ 'border': 'none', 'text-align': 'center' }}>
                                                    <fieldset style={{ 'width': '227px', 'margin': 'auto', 'margin-bottom': '10px' }}>
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"
                                                                placeholder="Enter meeting id" aria-describedby="button-addon2" onChange={(e) => {
                                                                    setRoom(e.target.value)
                                                                }} />
                                                            <div class="input-group-append" id="button-addon2">
                                                                <button class="btn" style={{'background-color':'#ff0081','color':'white'}} type="button" onClick={
                                                                    () => {
                                                                        if (room != '') {
                                                                            setUserId('')
                                                                            setName(JSON.parse(localStorage.getItem('authUser')).firstname + ' ' + JSON.parse(localStorage.getItem('authUser')).lastname)
                                                                            setCall(true)
                                                                            openModal()
                                                                        } else {
                                                                            alert('Enter room name')
                                                                        }
                                                                        //setRoom(roo)

                                                                    }
                                                                }>Join</button>
                                                            </div>
                                                        </div>
                                                    </fieldset>
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={strdate => {
                                                            //alert(date)
                                                            const d = strdate
                                                            const date = d.getDate();
                                                            const month = d.getMonth();
                                                            const year = d.getFullYear();
                                                            const dates = year + "-" + (month + 1) + "-" + date;
                                                            let data = {
                                                                id: JSON.parse(localStorage.getItem('authUser'))._id,
                                                                scheduledate: dates
                                                            }
                                                            http
                                                                .post(config.apiEndPoint + meetingDataService.searchmeeting, data)
                                                                .then(res => {
                                                                    setSchedule(res.data.schedule)
                                                                    setHistory(res.data.history)
                                                                    setStartDate(strdate)
                                                                })
                                                        }}
                                                        inline
                                                    />
                                                    <div style={{ 'text-align': 'center', 'margin-top': '10px' }}>
                                                        <button style={{ 'width': '85px','background-color':'#ff0081','color':'white' }} class="btn" 
                                                            onClick={getMeetings}>Clear Filter</button>
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {call == true && (
                            <Modal
                                isOpen={modalIsOpen}
                                // onAfterOpen={afterOpenModal}
                                onRequestClose={closeModal}
                                style={customStyles}
                                contentLabel="Example Modal"
                            >

                                {/* <h2 ref={_subtitle => (subtitle = _subtitle)}>Hello</h2> */}
                                {/* <a onClick={closeModal} style={{'float':'right'}}>x</a> */}
                                <a onClick={() => {
                                    window.location = '/econnect'
                                }} style={{ 'float': 'right', 'position': 'absolute', 'top': '-4px' }}>x</a>
                                <Jutsu
                                    domain='connect.engagechats.com'
                                    roomName={room}
                                    displayName={name}
                                    onMeetingEnd={() => alert('hi')}
                                    loadingComponent={<p>loading ...</p>}
                                    errorComponent={<p>Oops, something went wrong</p>}
                                    jwt={userid == JSON.parse(localStorage.getItem('authUser'))._id ? localStorage.token.replace(/['"]+/g, '') : ''}
                                    containerStyles={{ width: '100%', height: '100%', 'z-index': '1' }}
                                />
                            </Modal>

                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
export default Econnect