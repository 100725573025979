import {
   FETCH_WORKSPACE_DATA_REQUEST, FETCH_WORKSPACE_DATA_SUCCESS, ADD_FETCH_WORKSPACE_DATA_SUCCESS, UPDATE_FETCH_WORKSPACE_DATA_SUCCESS, WORKSPACE_DATA_ERROR, DELETE_WORKSPACE_DATA_SUCCESS,
   FETCH_SUB_WORKSPACE_DATA_REQUEST, FETCH_SUB_WORKSPACE_DATA_SUCCESS, ADD_FETCH_SUB_WORKSPACE_DATA_SUCCESS, UPDATE_FETCH_SUB_WORKSPACE_DATA_SUCCESS, DELETE_SUB_WORKSPACE_DATA_SUCCESS, SUB_WORKSPACE_DATA_ERROR,
   FETCH_BOARD_DATA_REQUEST, FETCH_BOARD_DATA_SUCCESS, ADD_FETCH_BOARD_DATA_SUCCESS, UPDATE_FETCH_BOARD_DATA_SUCCESS, BOARD_DATA_ERROR,
   FETCH_SUB_BOARD_DATA_REQUEST, FETCH_SUB_BOARD_DATA_SUCCESS, ADD_FETCH_SUB_BOARD_DATA_SUCCESS, UPDATE_FETCH_SUB_BOARD_DATA_SUCCESS,
   FETCH_GROUP_DATA_REQUEST, FETCH_GROUP_DATA_SUCCESS, ADD_FETCH_GROUP_DATA_SUCCESS, UPDATE_FETCH_GROUP_DATA_SUCCESS,
   FETCH_GROUP_ITEM_DATA_REQUEST, FETCH_GROUP_ITEM_DATA_SUCCESS, ADD_FETCH_GROUP_ITEM_DATA_SUCCESS, UPDATE_FETCH_GROUP_ITEM_DATA_SUCCESS,
   FETCH_SUB_WORKSPACE_L2_DATA_REQUEST, FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS, ADD_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS, UPDATE_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS, DELETE_SUB_WORKSPACE_L2_DATA_SUCCESS, SUB_WORKSPACE_L2_DATA_ERROR
} from '../constants/ActionTypes'
import { combineReducers } from 'redux';

const INIT_STATE = {
   error: "",
   loading: false,
   data: []
};

const discoverWorkspace = (state = INIT_STATE, action = {}) => {
   switch (action.type) {
      case FETCH_WORKSPACE_DATA_REQUEST: {
         return { ...state, error: '', data: state.data, loading: true };
      }
      case FETCH_WORKSPACE_DATA_SUCCESS: {

         return { ...state, error: '', data: action.payload, loading: false };
      }
      case ADD_FETCH_WORKSPACE_DATA_SUCCESS: {

         return { ...state, error: '', data: [...state.data, action.payload], loading: false };
      }
      case UPDATE_FETCH_WORKSPACE_DATA_SUCCESS: {

         state.data.map(ws => {
            if (ws._id === action.payload._id) {
               ws.title = action.payload.title;
               ws.description = action.payload.description;
            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case DELETE_WORKSPACE_DATA_SUCCESS: {
   
         return { ...state, data: state.data.filter(i => i._id !== action.payload._id) };
      }
      case 'DELETE_SUB_WORKSPACE_FROM_WORKSPACE': {

         state.data.map(ws => {
            if (ws._id === action.payload.workspace_id) {
               // ws.subworkspaces.remove(action.payload.subworkspace)
               console.log("NNN-->", ws)
               const deleteIndex = ws.subworkspaces.findIndex(elem => elem._id === action.payload._id);
               console.log("NNN-->", deleteIndex)
             
             
               // ws.subworkspaces.filter((item, index) => index !== deleteIndex);
               ws.subworkspaces.splice(deleteIndex, 1);
            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
         
      case 'ADD_SUB_WORKSPACE_IN_WORKSPACE':{
         state.data.map(ws => {
            if (ws._id === action.payload.workspace_id) {
               ws.subworkspaces.push(action.payload.subworkspace)
              
            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case 'ADD_BOARD_IN_WORKSPACE': {
         state.data.map(ws => {
            if (ws._id === action.payload.workspace_id) {
               ws.workspaceBoards.push(action.payload.workspaceBoard)

            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
         
      case WORKSPACE_DATA_ERROR: {
         return { ...state, loading: false, error: action.payload, data: state.data};
      }
      default:
         return state;
   }
}

const discoverSubWorkspace = (state = "", action = {}) => {
   switch (action.type) {
      case FETCH_SUB_WORKSPACE_DATA_REQUEST: {
         return { ...state, error: '', data: [], loading: true };
      }
      case FETCH_SUB_WORKSPACE_DATA_SUCCESS: {

         return { ...state, error: '', data: action.payload, loading: false };
      }
      case ADD_FETCH_SUB_WORKSPACE_DATA_SUCCESS: {

         return { ...state, error: '', data: [...state.data, action.payload], loading: false };
      }
      case UPDATE_FETCH_SUB_WORKSPACE_DATA_SUCCESS: {

         state.data.map(sws => {
            if (sws._id === action.payload._id) {
               sws.title = action.payload.title;
               sws.description = action.payload.description;
            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case DELETE_SUB_WORKSPACE_DATA_SUCCESS: {

         return { ...state, data: state.data.filter(i => i._id !== action.payload._id) };
      }
      case 'ADD_SUB_WORKSPACE_IN_SUB_WORKSPACE': {
         state.data.map(ws => {
            if (ws._id === action.payload.subworkspace_id) {

               console.log('N-->', ws);
               ws.subworkspaces.push(action.payload.subworkspace)

            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case 'DELETE_SUB_L2_FROM_SUB_WORKSPACE': {

         state.data.map(ws => {
            if (ws._id === action.payload.subworkspaceId) {

               const deleteIndex = ws.subworkspaces.findIndex(elem => elem._id === action.payload._id);

               ws.subworkspaces.splice(deleteIndex, 1);
            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case 'ADD_BOARD_IN_SUB_WORKSPACE': {
         state.data.map(sws => {
            if (sws._id === action.payload.subWorkspace_id) {
               sws.workspaceBoards.push(action.payload.workspaceBoard)

            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case SUB_WORKSPACE_DATA_ERROR: {
         return { ...state, loading: false, error: action.payload, data: state.data};
      }
      default:
         return state;
   }
}
const discoverBoard = (state = INIT_STATE, action = {}) => {
   switch (action.type) {
      case FETCH_BOARD_DATA_REQUEST: {
         return { ...state, error: '', data: [], loading: true };
      }
      case FETCH_BOARD_DATA_SUCCESS: {

         return { ...state, error: '', data: action.payload, loading: false };
      }
      case ADD_FETCH_BOARD_DATA_SUCCESS: {

         return { ...state, error: '', data: [...state.data, action.payload], loading: false };
      }
      case UPDATE_FETCH_BOARD_DATA_SUCCESS: {

         state.data.map(b => {
            if (b._id === action.payload._id) {
               b.title = action.payload.title;
               b.description = action.payload.description;
            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case BOARD_DATA_ERROR: {
         return { ...state, loading: false, error: action.payload, data: state.data };
      }
      default:
         return state;
   }
}
const discoverSubBoard = (state = INIT_STATE, action = {}) => {
   switch (action.type) {
      case FETCH_SUB_BOARD_DATA_REQUEST: {
         return { ...state, error: '', data: [], loading: true };
      }
      case FETCH_SUB_BOARD_DATA_SUCCESS: {

         return { ...state, error: '', data: action.payload, loading: false };
      }
      case ADD_FETCH_SUB_BOARD_DATA_SUCCESS: {

         return { ...state, error: '', data: [...state.data, action.payload], loading: false };
      }
      case UPDATE_FETCH_SUB_BOARD_DATA_SUCCESS: {

         state.data.map(sb => {
            if (sb._id === action.payload._id) {
               sb.title = action.payload.title;
               sb.description = action.payload.description;
            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case WORKSPACE_DATA_ERROR: {
         return { ...state, loading: false, error: action.payload, data: '' };
      }
      default:
         return state;
   }
}

const discoverGroup = (state = INIT_STATE, action = {}) => {
   switch (action.type) {
      case FETCH_GROUP_DATA_REQUEST: {
         return { ...state, error: '', data: [], loading: true };
      }
      case FETCH_GROUP_DATA_SUCCESS: {

         return { ...state, error: '', data: action.payload, loading: false };
      }
      case ADD_FETCH_GROUP_DATA_SUCCESS: {

         return { ...state, error: '', data: [action.payload,...state.data], loading: false };
      }
      case UPDATE_FETCH_GROUP_DATA_SUCCESS: {

         state.data.map(sb => {
            if (sb._id === action.payload.groupID) {
               sb.label = action.payload.title;
               // sb.description = action.payload.description;
            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case 'ADD_GROUP_ITEM_IN_GROUP': {
         state.data.map(g => {
            if (g._id === action.payload.groupId) {
               g.groupItems.push(action.payload.groupItem)

            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case WORKSPACE_DATA_ERROR: {
         return { ...state, loading: false, error: action.payload, data: '' };
      }
      default:
         return state;
   }
}
const discoverGroupItem = (state = INIT_STATE, action = {}) => {
   switch (action.type) {
      case FETCH_GROUP_ITEM_DATA_REQUEST: {
         return { ...state, error: '', data: [], loading: true };
      }
      case FETCH_GROUP_ITEM_DATA_SUCCESS: {

         return {
            ...state, error: '', data: action.payload.filter(item =>
               item.category !== 'header'), 
               loading: false };
      }
      case ADD_FETCH_GROUP_ITEM_DATA_SUCCESS: {

         return { ...state, error: '', data: [...state.data, action.payload], loading: false };
      }
      case UPDATE_FETCH_GROUP_ITEM_DATA_SUCCESS: {

         state.data.map(gi => {
            if (gi._id === action.payload.groupItemID) {
               gi.title = action.payload.title;
              // gi.description = action.payload.description;
            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case WORKSPACE_DATA_ERROR: {
         return { ...state, loading: false, error: action.payload, data: '' };
      }
      default:
         return state;
   }
}

const discoverSubWorkspaceL2 = (state = "", action = {}) => {
   switch (action.type) {
      case FETCH_SUB_WORKSPACE_L2_DATA_REQUEST: {
         return { ...state, error: '', data: [], loading: true };
      }
      case FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS: {

         return { ...state, error: '', data: action.payload, loading: false };
      }
      case ADD_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS: {

         return { ...state, error: '', data: [...state.data, action.payload], loading: false };
      }
      case UPDATE_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS: {

         state.data.map(sws => {
            if (sws._id === action.payload._id) {
               sws.title = action.payload.title;
               sws.description = action.payload.description;
            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case DELETE_SUB_WORKSPACE_L2_DATA_SUCCESS: {

         return { ...state, data: state.data.filter(i => i._id !== action.payload._id) };
      }
     
      case 'ADD_BOARD_IN_SUB_WORKSPACE_L2': {
         state.data.map(sws => {
            if (sws._id === action.payload.subWorkspace_id) {
               sws.workspaceBoards.push(action.payload.workspaceBoard)

            }
         });
         return { ...state, error: '', data: state.data, loading: false };
      }
      case SUB_WORKSPACE_L2_DATA_ERROR: {
         return { ...state, loading: false, error: action.payload, data: state.data };
      }
      default:
         return state;
   }
}

const discover = combineReducers({

   discoverWorkspace: discoverWorkspace,
   discoverSubWorkspace: discoverSubWorkspace,
   discoverBoard: discoverBoard,
   discoverSubBoard: discoverSubBoard,
   discoverGroup: discoverGroup,
   discoverGroupItem: discoverGroupItem,
   discoverSubWorkspaceL2: discoverSubWorkspaceL2

});

export default discover;


