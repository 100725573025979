
import React from 'react';
import { connect } from 'react-redux';
import { changeBoardItem, updateCellTagsSuggestion, fetchError, saveRowReminder } from '../../../../actions';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../util/permission';
import DatePicker from "react-datepicker";
//import '../../../style/react-datepicker.css'
import Select from 'react-select'
import moment from 'moment';
import { date } from 'joi';

class ReminderItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            suggestions: [],
            actionPermission: 'edit',
            dateTime: this.props.body.dateTime,
            frequency: '',
            users: '',
        }

        this.dropdownContainer = React.createRef();
        this.changeFrequncy = this.changeFrequncy.bind(this);
        this.changeUsers = this.changeUsers.bind(this);
    }

    onClickOutsideHandler = (ev) => {
        return;
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false });
        }
    }
    componentDidMount() {
      //  window.addEventListener('click', this.onClickOutsideHandler);
        // console.log("this.props.body", this.props);
        // let cellSuggestion = this.props.boardData.cellSuggestions;
     
        // if (this.props.boardData.boardID) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem.cellItems;
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentDidUpdate(prevProps, prevStates) {
        // if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem.cellItems;
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    componentWillReceiveProps(nextProps) {
        let cellSuggestion = nextProps.boardData.cellSuggestions;

        this.setState({dateTime: nextProps.body.dateTime?nextProps.body.dateTime:''})
        this.setState({frequency: nextProps.body.frequency?nextProps.body.frequency:''})
    }


    changeFrequncy = (event) => {
        this.setState({ frequency: event.target.value });
    }

    changeUsers = (event) => {
    
        this.setState({ users: event.target.value });

    }


    saveReminder = () => {
   
        let reminderBody = {
            dateTime: moment(this.state.dateTime).format(),
            frequency: this.state.frequency,
            users: this.state.users
        }

        if(this.props.body.reminderId){
            this.props.changeBoardItem({
                groupInd: this.props.groupInd,
                rowInd: this.props.rowInd,
                colInd: this.props.colInd,
                body: reminderBody,
                cellItemID: this.props._id,
                boardId: this.props.boardData.boardID
            })
        }else{
            this.props.saveRowReminder({
                reminderDate: this.state.dateTime,
                frequency: this.state.frequency,
                groupInd: this.props.groupInd,
                rowInd: this.props.rowInd,
                colInd: this.props.colInd,
                body: reminderBody,
                cellItemID: this.props._id,
                boardId: this.props.boardData.boardID
            })
        }

        this.setState({ open: false });
    }

    render() {
      
        const { groupInd, rowInd, colInd, body, _id } = this.props;
       


        return (
            <div className='dropdown-tags-header' ref={this.dropdownContainer} >
                <div className='p-1 small' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '35px' }}
                    onClick={() => {
                        // if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members) && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                        //     this.setState({ open: true })
                        // } else {
                        //     this.props.fetchError("You don't have permission. You can only view")
                        // }
                    }}
                >
                    <span className="badge badge-primary">{body.frequency?body.frequency:''}</span> &nbsp;
                    {body.dateTime?moment(body.dateTime).format('LLL'):'ADD'}

                </div>
                {this.state.open && (
                    <div className="row bg-white pt-2 border shadow" style={{ width: '440' }}>
                        <div className="col-xl-6">
                            <label>Time</label>
                            <DatePicker
                                selected={this.state.dateTime?new Date(this.state.dateTime): new Date()}
                                showTimeSelect
                                dateFormat="Pp"
                                onChange={date => {
                                    console.log(moment(Date.parse(date)).utc())
                                    this.setState({ dateTime: new Date(date) })
                                }}
                            />
                        </div>
                        <div className="col-xl-6">
                            <div class="form-group">
                                <label>Frequency</label>
                                <select class="form-control" value={this.state.frequency} onChange={this.changeFrequncy}>
                                    <option value="today">Does Not Repeat</option>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div class="form-group">
                                <label>Users</label>
                                {/* <Select
                                    isMulti
                                    name="colors"
                                    options={[
                                        { value: 'assignee', label: 'Assignee' },
                                        { value: 'followers', label: 'Followers' }
                                    ]}
                                    value={this.state.users}
                                    onChange={this.changeUsers}
                                    className="basic-multi-select"
                                    classNamePrefix="select" /> */}
                                <select class="form-control" value={this.state.users} onChange={this.changeUsers}>
                            <option value="assignee">Assignee</option>
                            <option value="followers">Followers</option>
                           
                        </select>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <button className="btn btn-danger mt-4 float-right"
                             onClick={() => {  this.setState({ open: false })}}
                            >Close</button>
                            <button className="btn btn-primary mt-4 mr-2 float-right"
                            onClick={() => { this.saveReminder() }}
                            
                            >Save</button>

                        </div>
                    </div>

                )}

            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeBoardItem, updateCellTagsSuggestion, fetchError, saveRowReminder }

export default connect(mapStateToProps, mapDispatchToProps)(ReminderItem);