import React, { Component } from 'react';
import http from '../services/http-service';
import * as WorkSpaceTasksConst from './constants';
import ReactJSPie from './chart-shapes/react-js/reactjs-pie';
import ReactJsDonant from './chart-shapes/react-js/reactjs-donant';
import LineGraph from './chart-shapes/react-js/line-graph';
import LineBarCrazy from './chart-shapes/react-js/linebar-crazy';
import HorizentalBar from './chart-shapes/react-js/horizental-bar';
import { WidthProvider, Responsive } from "react-grid-layout";
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';
import "./styles.css";

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};

class DashBoardCharts extends Component {
   constructor(props) {
      super(props);
      this.state = {
         layouts: JSON.parse(JSON.stringify(originalLayouts)),
         workspaceSelectedValue: "",
         boardsOptions: [],
         userId: "5fb65016a624bc4f507f452d",
      }
   }

   async componentDidMount() {
      //Get All User Data For Dropdown Options
      this.getAllWorkspaceByUserId();
   }

   static get defaultProps() {
      return {
         className: "layout",
         cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
         rowHeight: 30,
      };
   }

   resetLayout() {
      this.setState({ layouts: {} });
   }

   onLayoutChange(layout, layouts) {
      saveToLS("layouts", layouts);
      this.setState({ layouts });
   }


   getAllWorkspaceByUserId = async () => {
      await http.post(WorkSpaceTasksConst.getAllWorkSpaceByUser, this.state.userId)
         .then((response) => {
            response.data.data.map((data) => {
               this.state.boardsOptions.push(
                  { value: data._id, label: data.title }
               )
            })
         })
   }

   handleworkspaceChange = () => {
   }

   render() {
      return (
         <>
            <MainMenu link={`dashboard-charts`} />
            <div id="whole-layout">
               <NavBar />
               <div id="appdata" className='app-content content fixed-top messenger-layout'>
                  <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
                  <div className="content-wrapper">
                     <div id="mySidenav" className="sidenav">
                        <a href="#" className="closebtn" onClick={() => {
                           document.getElementById("mySidenav").style.width = "0%";
                        }}>&times;</a>
                     </div>
                     <div className="col-md-12">
                        <ResponsiveReactGridLayout
                           className="layout"
                           cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                           rowHeight={30}
                           layouts={this.state.layouts}
                           onLayoutChange={(layout, layouts) =>
                              this.onLayoutChange(layout, layouts)
                           }
                        >
                           <div
                              key="1"
                              data-grid={{ w: 2, h: 3, x: 0, y: 0, minW: 1, minH: 3 }}
                           >
                              <LineGraph />
                           </div>
                           <div
                              key="2"
                              data-grid={{ w: 2, h: 3, x: 2, y: 0, minW: 2, minH: 3 }}
                           >
                              <ReactJSPie />
                           </div>
                           <div
                              key="3"
                              data-grid={{ w: 2, h: 3, x: 4, y: 0, minW: 2, minH: 3 }}
                           >
                              <ReactJsDonant />
                           </div>
                           <div
                              key="4"
                              data-grid={{ w: 2, h: 3, x: 6, y: 0, minW: 2, minH: 3 }}
                           >
                              <LineBarCrazy />
                           </div>
                           <div
                              key="5"
                              data-grid={{ w: 2, h: 3, x: 8, y: 0, minW: 2, minH: 3 }}
                           >
                              <HorizentalBar />
                           </div>
                           <div
                              key="5"
                              data-grid={{ w: 2, h: 3, x: 8, y: 0, minW: 2, minH: 3 }}
                           >
                              <HorizentalBar />
                           </div>
                        </ResponsiveReactGridLayout>
                     </div>
                  </div>
               </div>
            </div>
         </>
      );
   }
}


function getFromLS(key) {
   let ls = {};
   if (global.localStorage) {
      try {
         ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
      } catch (e) {
         /*Ignore*/
      }
   }
   return ls[key];
}

function saveToLS(key, value) {
   if (global.localStorage) {
      global.localStorage.setItem(
         "rgl-8",
         JSON.stringify({
            [key]: value,
         })
      );
   }
}


export default DashBoardCharts;