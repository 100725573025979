
export const  getAllBusinessCategory = "businessCategory/getAllBusinessCategory"; 
export const  getBusinessCategoryById = "businessCategory/getBusinessCategoryById"; 
export const  saveBusinessCategory = "businessCategory/savebusinessCategory" ; 
export const  updateBusinessCategory = "businessCategory/updateBusinessCategory" ; 
export const  deleteBusinessCategory = "businessCategory/deleteBusinessCategory" ;

export default {
    getAllBusinessCategory : getAllBusinessCategory , 
    getBusinessCategoryById : getBusinessCategoryById , 
    saveBusinessCategory : saveBusinessCategory ,  
    updateBusinessCategory : updateBusinessCategory , 
    deleteBusinessCategory : deleteBusinessCategory 
}
