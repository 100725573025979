import React from 'react';
import ContentEditable from 'react-contenteditable';
import { connect } from 'react-redux';
import '../../style/workspace.css';
import '../../style/sidebar.css';
import FilterDropdown from './header/FilterDropdown';
import SortDropdwon from './header/SortDropdown';
import BoardTable from './body/BoardTable';
import { changeWorkspaceBoardTitle, changeWorkspaceBoardDesc, changeWorkspaceBoard, reloadWorkspace, showMessage, fetchStart, intialize } from '../../../../actions';
import axios from '../../../../util/Api';
import SearchBoard from './header/SearchBoard';
import Select from 'react-select';
import http from '../../../services/http-service';
import * as workspaceApi from '../../constants'

const options = [
    { value: '5ffe95e536bf244dd53a79c2', label: 'It Board' },
];

class GlobalSearchBoard extends React.Component {
    constructor(props) {
        super(props)
        this._isMounted = false;
        this.state = {
            boardName: "New Blank Board",
            boardDesc: "Add description",
            groups: [],
            columns: [],
            boardMemberOpen: false,
            boardActivity: false,
            boardPermission: false,
            boardType: {
                open: false,
                to: ''
            },
            isRemoveModal: false,
            moveWorkspaceOpen: false,
            workspaces: null,
            subworkspaces: null,
            open: false,
            selectedOption: null,

        }
        this.timeout = 0;
        this.boardNameEditable = React.createRef();
        this.descritionEditTable = React.createRef();
        this.removeModalContainer = React.createRef();
        this.dropdownContainer = React.createRef();
        this.onChangeBoardName = this.onChangeBoardName.bind(this);
        this.onBoardDescChange = this.onBoardDescChange.bind(this);
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false });
        }
    }

    componentDidMount() {
        //Get All Boards
        http.get(workspaceApi.GET_ALL_WORKSPACE_BOARD)
            .then((result) => {
                result.data.workspaceBoard.map((result) => {
                    options.push({ value: result._id, label: result.title })
                })
            })
        window.addEventListener('click', this.onClickOutsideHandler);
        this._isMounted = true;
        if (this._isMounted = true) {
        }

        const boardName = this.props.boardData.boardTitle;
        this.setState({ boardName: boardName, boardDesc: this.props.boardData.boardDescription })
        if (this.props.boardData.boardID !== '') {
            axios.get(`/workspace/getWorkspaceBoardById/${this.props.boardData.boardID}`).then(({ data }) => {
                console.log("========>data", data)
                this.setState({ groups: JSON.parse(data.workspaceBoard.group), columns: data.workspaceBoard.columnHeaders })
            }).catch(err => {
                console.log("Error:", err)
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.boardData.boardID !== '') {
            axios.get(`/workspace/getWorkspaceBoardById/${nextProps.boardData.boardID}`).then(({ data }) => {
                this.setState({ groups: JSON.parse(data.workspaceBoard.group), columns: data.workspaceBoard.columnHeaders })
            }).catch(err => {
                console.log("Error:", err)
            })
        }
    }

    onChangeBoardName = (event) => {
        this.setState({
            boardName: event.target.value
        });
    }

    onBoardDescChange = (e) => {
        this.setState({
            boardDesc: e.target.value
        })
    }

    onKeyUpChangeBoardName = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.changeWorkspaceBoardTitle({
                boardId: this.props.boardData.boardID,
                boardTitle: this.state.boardName
            })
        }, 800);
    }

    onkeyUpDescChange = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.changeWorkspaceBoardDesc({
                boardId: this.props.boardData.boardID,
                boardDesc: this.state.boardDesc
            })
        }, 800);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.boardData.boardTitle !== prevProps.boardData.boardTitle) {
            this.setState({ boardName: this.props.boardData.boardTitle });
        }
        if (this.props.boardData.boardDescription !== prevProps.boardData.boardDescription) {
            this.setState({ boardDesc: this.props.boardData.boardDescription });
        }
        if (this._isMounted) {
            if (prevProps.boardData.loadWorkspace !== this.props.boardData.loadWorkspace) {
            }
        }
    }

    renameBoard = () => {
        if (this.props.boardData.boardID !== '') {
            this.boardNameEditable.current.focus();
            setTimeout(() => {
                this.boardNameEditable.current.selectionStart = this.state.boardName.length
                this.boardNameEditable.current.selectionEnd = this.state.boardName.length
            }, 10)
        }
    }

    boardMembers = () => {
        if (this.props.boardData.boardID !== '') {
            this.setState({ boardMemberOpen: true })
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    btnAction = () => {
        this.setState({ open: true });
        axios.get('/workspace/getAllWorkspaceAndSubworkspaces').then(({ data }) => {
            this.setState({
                workspaces: data.workspaces,
                subworkspaces: data.subworkspaces
            })
        }).catch(err => {
            console.error(err)
        })
    }
    switchBoard = (id) => {
        console.log("board id==>", id)
        this.props.changeWorkspaceBoard({
            id: id,
            workspaceID: this.props.workspaceID
        })
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOption },
            () => this.switchBoard(this.state.selectedOption.value)
        );
    };


    removeCategory = () => {
        // this.setState({ open: false });
        // console.log("test")
        if (this.props.actionType === 'Subworkspace') {
            let subworkspaceID = this.props.subWorkspaceID;
            console.log("subworkspaceID===>", subworkspaceID);
            this.setState({ isRemoveModal: false });
            this.props.fetchStart();

            axios.post('/workspace/removeBoardForSub', {
                subworkspaceID: subworkspaceID,
                boardID: this.props.board._id
            }).then(({ data }) => {
                if (data.success === 200) {
                    this.props.showMessage(`${data.boardName} deleted successfully!`);
                    this.props.intialize()
                }
            }).catch(err => {
                console.log("err==>", err)
            })
        } else if (this.props.actionType === 'Workspace') {
            let workspaceID = this.props.workspaceID;

            console.log("workspace ID===>", workspaceID);
            this.setState({ isRemoveModal: false });
            this.props.fetchStart();

            axios.post('/workspace/removeBoardForWorkspace', {
                workspaceID: workspaceID,
                boardID: this.props.board._id
            }).then(({ data }) => {
                if (data.success === 200) {
                    console.log("data", data)
                    this.props.showMessage(`${data.boardName} deleted successfully!`);

                    this.props.intialize()
                }
            }).catch(err => {
                console.log("err==>", err)
            })
        }
    }

    moveToSubWorkspace = (subworkspace_id) => {
        this.setState({ open: false });
        axios.post('/workspace/moveWorkspaceboardToSub', {
            boardID: this.props.board._id,
            sourceSubId: this.props.subWorkspaceID,
            destinationId: subworkspace_id,
            workspaceID: this.props.workspaceID
        }).then(({ data }) => {
            this.props.showMessage(`${data.workspaceBoardTitle} moved successfully!`);
            this.props.reloadWorkspace({
                reload: !this.props.boardData.loadWorkspace
            })
        }).catch(error => {
            console.error(error)
        })
    }

    moveToWorkspace = (workspace_id) => {
        this.setState({ open: false });
        axios.post('/workspace/moveBoardToWorkspace', {
            boardID: this.props.board._id,
            destinationWorkspaceId: workspace_id,
            currentWorkspaceId: this.props.workspaceID,
            currentSubworkspaceId: this.props.subWorkspaceID
        }).then(({ data }) => {
            this.props.showMessage(`${data.workspaceBoardTitle} moved successfully!`);
            this.props.reloadWorkspace({
                reload: !this.props.boardData.loadWorkspace
            })
        }).catch(err => {
            console.error(err)
        })
    }

    render() {
        const { selectedOption } = this.state;

        return (
            <div id="content" className="p-4" style={{ overflowX: 'auto' }}>
                <div className="workspace-layout">
                    <div className="first-div">
                        <Select
                            isSearchable={true}
                            value={selectedOption}
                            onChange={this.handleChange}
                            options={options}
                        />

                        <div className="workspace-layout-section-one">
                            <div className="workspace-layout-header">
                                <ContentEditable
                                    className="board-name"
                                    innerRef={this.boardNameEditable}
                                    html={this.state.boardName}
                                    disabled={false}
                                    onChange={this.onChangeBoardName}
                                    onKeyUp={this.onKeyUpChangeBoardName}
                                    tagName='h5'
                                />

                                <div id="favourites-star" data-toggle="tooltip" data-placement="right" title="Add to favorites">
                                    <i className="bx bxs-star p-2 h2" style={{ color: '#efefef' }}></i>
                                </div>
                            </div>

                        </div>

                        <div className="workspace-layout-section-two">
                            <div className="workspace-layout-header">
                                <ContentEditable
                                    className="board-description"
                                    innerRef={this.descritionEditTable}
                                    html={this.state.boardDesc}
                                    disabled={false}
                                    onChange={this.onBoardDescChange}
                                    onKeyUp={this.onkeyUpDescChange}
                                    tagName='div'
                                />
                            </div>
                        </div>
                        <div className="workspace-layout-section-three">
                            <SearchBoard groups={this.state.groups} columns={this.state.columns} />
                            <FilterDropdown groups={this.state.groups} columns={this.state.columns}></FilterDropdown>
                            <SortDropdwon groups={this.state.groups} columns={this.state.columns}></SortDropdwon>
                        </div>
                    </div>
                </div>
                <BoardTable></BoardTable>
            </div>
        )
    }
}
const mapStateToProps = ({ boardData }) => {
    return { boardData }
}
const mapDispatchToProps = { changeWorkspaceBoardTitle, changeWorkspaceBoardDesc, changeWorkspaceBoard, reloadWorkspace, showMessage, fetchStart, intialize };
export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearchBoard);