import React from 'react';
import { connect } from 'react-redux';
import { changeBoardItem, fetchError } from '../../../../actions';
import http from '../../../../components/services/http-service';
import config from '../../../../components/services/config.json';
import ReactTooltip from 'react-tooltip';
import { makeid } from '../../../../util/index';
import Modal from 'react-modal';
import { createImageFromInitials, getRandomColor } from '../../../../util/People';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../util/permission';

const customStyles = {
    content: {
        padding: 0
    }
};

class FileItem extends React.Component {
    fileObj = [];
    fileArray = [];
    people = {};
    previewFileIndex = -1;

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            fileList: [],
            uploading: false,
            popoverOpen: true,
            modalIsOpen: false,
            previewFile: {},
            openThumbnailIndex: -1,
            openControlBtn: false,
            openMoreList: false,
            openMoreListIndex: -1,
            imagePreviewProperty: {},
            actionPermission: 'edit'
        }

        this.dropdownContainer = React.createRef();
        this.hiddenFileInput = React.createRef();
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false });
        }
    }

    componentDidMount() {
        // console.log('this.props', this.props)

        window.addEventListener('click', this.onClickOutsideHandler);
        const fileList = this.props.body || [];
        this.setState({ fileList: fileList });

        this.people = JSON.parse(localStorage.getItem("authUser"));
        if (!this.people?.color) {
            this.people.color = getRandomColor();
        }
    }

    componentDidUpdate(prevProps, prevStates) {

    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    openDialog = () => {
        console.log('open dialog');
        this.hiddenFileInput.current.click();
    }

    uploadMultipleFiles = async (e) => {
        console.log('click input file element!');
        this.fileObj = [];
        this.fileObj.push(e.target.files);
        this.setState({ uploading: true });

        let imageUploadPromiseArray = [];
        for (let i = 0; i < this.fileObj[0].length; i++) {
            const formData = new FormData();
            const selectedFile = this.fileObj[0][i];
            formData.append(
                "file",
                selectedFile
            );
            imageUploadPromiseArray.push(
                http.post(config.apiEndPoint + 'utils/upload', formData, {
                    onUploadProgress: ProgressEvent => {
                        let progress =
                            Math.round(
                                (ProgressEvent.loaded / ProgressEvent.total) * 100
                            ) + '%'
                    }
                })
            );
        }

        Promise.all(imageUploadPromiseArray).then(fileInfos => {
            // console.log('file upload values: ', fileInfos);

            let fileList = this.state.fileList || [];
            fileInfos.map(fileinfo => {
                const extension = fileinfo.data.name?.split(".")[1];
                const fileType = this.getFileType(extension, fileinfo.data.mimetype);
                fileList.push({ ...fileinfo.data, _id: makeid('file', 4), extension: extension, type: fileType.type, typeSort: fileType.typeSort });
            });

            const { groupInd, rowInd, colInd, _id } = this.props;
            this.props.changeBoardItem({
                groupInd: groupInd,
                rowInd: rowInd,
                colInd: colInd,
                body: fileList,
                cellItemID: _id,
                boardId: this.props.boardData.boardID
            });
            this.setState({ fileList: fileList, uploading: false, open: false });
        }).catch(err => {
            this.setState({ uploading: false, open: false });
            console.log(err)
        });
    }

    removeFileItem = (label) => {
        let index = this.state.fileList.findIndex(item => item._id === label._id);
        const fileList = this.state.fileList.slice(0);
        fileList.splice(index, 1);
        const { groupInd, rowInd, colInd, _id } = this.props;
        this.props.changeBoardItem({
            groupInd: groupInd,
            rowInd: rowInd,
            colInd: colInd,
            body: fileList,
            cellItemID: _id,
            boardId: this.props.boardData.boardID
        });
        this.setState({ fileList: fileList })
    }

    getThreefiles = () => {
        return this.state.fileList.filter((file, index) => index < 3);
    }

    openModal = (index) => {
        this.previewFileIndex = index;
        const previewFile = this.state.fileList[index];
        this.setState({ modalIsOpen: true, previewFile: previewFile });
        this.getImageProperties(previewFile);
    }

    afterOpenModal = () => {
        // references are now sync'd and can be accessed.
        console.log("test")
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    prevFile = () => {
        if (this.previewFileIndex > 0) {
            this.previewFileIndex = this.previewFileIndex - 1;
        }
        const previewFile = this.state.fileList[this.previewFileIndex];
        this.setState({ previewFile: previewFile });
        this.getImageProperties(previewFile);
    }

    nextFile = () => {
        if (this.previewFileIndex < this.state.fileList.length - 1) {
            this.previewFileIndex = this.previewFileIndex + 1;
        }
        const previewFile = this.state.fileList[this.previewFileIndex];
        this.setState({ previewFile: previewFile });
        this.getImageProperties(previewFile);
    }

    downloadImage = async (file) => {
        http.post(config.apiEndPoint + 'utils/download', { path: file.created + "_" + file.name }, { responseType: "blob" })
            .then(response => {
                console.log(response);
                let url = window.URL.createObjectURL(response?.data);
                let a = document.createElement('a');
                a.href = url;
                a.download = file.name;
                a.click();
            })
            .catch(err => console.error(err));
    }

    timestampToDate = (timestamp) => {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let date = new Date(timestamp);
        return months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear();
    }

    getImageProperties = (file) => {
        const created = this.timestampToDate(file.created);
        let fileSize = Math.round(file.size / 1024);
        if (fileSize === 0) fileSize = 1;
        this.getMeta(file.path).then(dimension => {
            this.setState({ imagePreviewProperty: { created: created, type: file.type, typeSort: file.typeSort, size: fileSize + "KB", dimension: dimension } });
        }).catch(err => {
            this.setState({ imagePreviewProperty: { created: created, type: file.type, typeSort: file.typeSort, size: fileSize + "KB", dimension: "" } });
        });
    }

    getFileType = (extension, mimetype) => {
        let type = "", typeSort = "";
        if (mimetype?.slice(0, 5) === "image") {
            type = extension + " image";
            typeSort = "image";
        } else {
            switch (extension) {
                case "txt": type = "txt plain"; typeSort = "text"; break;
                case "docx": type = "docx word"; typeSort = "word"; break;
                case "doc": type = "doc word"; typeSort = "word"; break;
                case "pdf": type = "pdf"; typeSort = "pdf"; break;
                default: type = "other"; typeSort = "other"; break;
            }
        }
        return { type, typeSort };
    }

    getMeta = (url) => {
        return new Promise((resolve, reject) => {
            var img = new Image();
            img.onload = function () {
                resolve(this.width + ' X ' + this.height);
            };
            img.onerror = function (err) {
                reject(err);
            }
            img.src = url;
        })
    }

    getFileIconAndBackGroundColor = (file) => {
        let icon1 = "", icon2 = "", color = "gray";
        switch (file.typeSort) {
            case "image": icon1 = file.path; icon2 = file.path; break;
            case "pdf": icon1 = "./images/icon/filetype/pdf.svg"; icon2 = "./images/icon/filetype/pdf.svg"; color = "#eb1508"; break;
            case "word": icon1 = "./images/icon/filetype/word.svg"; icon2 = "./images/icon/filetype/word.svg"; color = "#2a5699"; break;
            case "text": icon1 = "./images/icon/filetype/unknown.svg"; icon2 = "./images/icon/filetype/unknown.svg"; break;
            default: icon1 = "./images/icon/filetype/unknown.svg"; icon2 = "./images/icon/filetype/unknown.svg"; break;
        }
        return { icon1, icon2, color };
    }

    render() {
        return (
            <div className="d-flex" style={{ position: 'relative' }}>
                {/* upload file manage part start */}
                {
                    this.state.fileList.length > 0 ? (
                        <div className="w-100 d-flex justify-content-center align-items-center" style={{ position: 'absolute', left: 0, top: 0, height: '100%' }}>
                            {/* 3 file thumbnail images start */}
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {
                                    this.getThreefiles().map((file, index) => (
                                        <div key={index} style={{ position: 'relative' }}
                                            onMouseEnter={() => this.setState({ openThumbnailIndex: index })}
                                            onMouseLeave={() => this.setState({ openThumbnailIndex: -1 })}
                                        >
                                            <div className="d-flex align-items-center justify-content-center" style={{ width: '24px', height: '28px' }}>
                                                <img className="file-image-thumbnail" src={this.getFileIconAndBackGroundColor(file)?.icon1} onClick={() => this.openModal(index)}
                                                    style={{ height: '20px', cursor: 'pointer' }}></img>
                                            </div>
                                            {
                                                Number(this.state.openThumbnailIndex) == index ? (
                                                    <div className="p-3" style={{
                                                        position: 'absolute', top: '36px', left: '-115px', borderRadius: '12px', background: 'white',
                                                        boxShadow: 'rgba(55, 75, 90, 0.8) 1px -4px 20px -2px', zIndex: 9, width: '250px'
                                                    }}
                                                        onMouseEnter={() => this.setState({ openControlBtn: true })}
                                                        onMouseLeave={() => this.setState({ openControlBtn: false })}
                                                    >
                                                        {/* triangle arrow css element start */}
                                                        <span style={{
                                                            width: 0, height: 0, borderStyle: 'solid', borderWidth: '0 10px 10px 10px',
                                                            borderColor: 'transparent transparent white transparent', position: 'absolute', top: '-10px', left: '118px'
                                                        }}></span>
                                                        {/* triangle arrow css element end */}

                                                        {/* large thumbnail card content start */}
                                                        <div className="w-100 d-flex align-items-center justify-content-center" style={{
                                                            background: `${this.getFileIconAndBackGroundColor(file)?.color}`, borderRadius: '6px',
                                                            position: 'relative', height: '140px'
                                                        }}>
                                                            <div>
                                                                <img className="" src={this.getFileIconAndBackGroundColor(file)?.icon2} onClick={() => console.log('image onclick!')}
                                                                    style={{ height: `${file.typeSort === "image" ? '140px' : '80px'}` }}></img>
                                                                {file.typeSort !== "image" && (
                                                                    <div className="text-center text-white" style={{ fontSize: '20px' }}>
                                                                        { file.extension}
                                                                    </div>
                                                                )}
                                                            </div>
                                                            {
                                                                this.state.openControlBtn ? (
                                                                    <div className="" style={{ position: 'absolute', top: '11px', right: '7px', display: 'grid' }}>
                                                                        <i className='thumbnail-control-icon fa fa-expand' data-tip="Open file" name="openFile"
                                                                            onClick={() => this.openModal(index)} style={{ fontSize: '18px', padding: '6px', cursor: 'pointer' }}></i>
                                                                        <ReactTooltip place="top" effect="solid" />
                                                                        <i className='thumbnail-control-icon fa fa-download' data-tip="Download file" name="downloadFile"
                                                                            onClick={() => this.downloadImage(file)} style={{ fontSize: '18px', padding: '6px', cursor: 'pointer' }}></i>
                                                                        <ReactTooltip place="top" effect="solid" />
                                                                        <i className='thumbnail-control-icon fa fa-trash-o' data-tip="Delete file" name="deleteFile"
                                                                            onClick={() => this.removeFileItem(file)} style={{ fontSize: '18px', padding: '6px', cursor: 'pointer' }}></i>
                                                                        <ReactTooltip place="top" effect="solid" />
                                                                    </div>
                                                                ) : ''
                                                            }
                                                        </div>
                                                        {file.name}
                                                        {/* large thumbnail card content start */}
                                                    </div>
                                                ) : ''
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                            {/* 3 file thumbnail images end */}

                            {/* more files hover dropdown start */}
                            {
                                this.state.fileList?.length > 3 ? (
                                    <div onMouseEnter={() => this.setState({ openMoreList: true })} onMouseLeave={() => this.setState({ openMoreList: false })}
                                        style={{ position: 'relative' }}>
                                        <div className="more-plusFile-count"> +{Number(this.state.fileList?.length) - 3} </div>
                                        {
                                            this.state.openMoreList ? (
                                                <div className="p-3" style={{
                                                    position: 'absolute', top: '40px', left: '-110px', borderRadius: '9px', background: 'white',
                                                    boxShadow: 'rgba(55, 75, 90, 0.8) 1px -4px 20px -2px', zIndex: 9, width: '250px'
                                                }}>
                                                    {/* triangle arrow css element start */}
                                                    <span style={{
                                                        width: 0, height: 0, borderStyle: 'solid', borderWidth: '0 10px 10px 10px',
                                                        borderColor: 'transparent transparent white transparent', position: 'absolute', top: '-10px', left: '118px'
                                                    }}></span>
                                                    {/* triangle arrow css element end */}
                                                    {
                                                        this.state.fileList.map((file, index) => (
                                                            <div key={index} className="d-flex align-items-center py-1" style={{ position: 'relative' }}
                                                                onMouseEnter={() => this.setState({ openMoreListIndex: index })}
                                                                onMouseLeave={() => this.setState({ openMoreListIndex: -1 })}
                                                            >
                                                                <div className="d-flex align-items-center justify-content-center" style={{ maxWidth: '26px', minWidth: '26px', marginRight: '4px' }}>
                                                                    <img src={this.getFileIconAndBackGroundColor(file)?.icon2} style={{ height: '32px', maxWidth: '100%' }}></img>
                                                                </div>
                                                                <span className="text-truncate" style={{ maxWidth: '98px' }}> {file.name} </span>
                                                                {
                                                                    this.state.openMoreListIndex == index ? (
                                                                        <div className="" style={{ position: 'absolute', top: '50%', right: '0', display: 'flex', transform: 'translateY(-50%)' }}>
                                                                            <i className='thumbnail-control-icon fa fa-expand' data-tip="Open file" name="openFile"
                                                                                onClick={() => this.openModal(index)} style={{ fontSize: '18px', padding: '6px', cursor: 'pointer' }}></i>
                                                                            <ReactTooltip place="top" effect="solid" />
                                                                            <i className='thumbnail-control-icon fa fa-download' data-tip="Download file" name="downloadFile"
                                                                                onClick={() => this.downloadImage(file)} style={{ fontSize: '18px', padding: '6px', cursor: 'pointer' }}></i>
                                                                            <ReactTooltip place="top" effect="solid" />
                                                                            <i className='thumbnail-control-icon fa fa-trash-o' data-tip="Delete file" name="deleteFile"
                                                                                onClick={() => this.removeFileItem(file)} style={{ fontSize: '18px', padding: '6px', cursor: 'pointer' }}></i>
                                                                            <ReactTooltip place="top" effect="solid" />
                                                                        </div>
                                                                    ) : ''
                                                                }
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            ) : ''
                                        }
                                    </div>
                                ) : ''
                            }
                            {/* more files hover dropdown end */}
                        </div>
                    ) : ''
                }
                {/* upload file manage part end */}

                {/* File manage Dropdown start */}
                <div className='dropdown-items' ref={this.dropdownContainer}
                    style={{ position: 'relative', width: `${this.state.fileList?.length > 0 ? '24px' : '100%'}`, height: '100%' }}>
                    {/* dropdown mark element */}
                    <div className="dropdown-cell-item" onClick={() => 
                    this.setState({ open: true })
                }
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {
                            this.state.fileList.length > 0 ? (
                                <div className="add-hint-component add-button">
                                    <i className='fa fa-plus-circle' style={{ color: '#007bff !important' }}></i>
                                </div>
                            ) : (
                                    <div className="add-hint-component add-button" style={{ position: 'initial' }}>
                                        <i className='fa fa-upload' style={{ color: '#007bff !important', fontSize: '24px' }}></i>
                                    </div>
                                )
                        }
                    </div>
                    {/* dropdown item list element */}
                    {this.state.open ? (
                        <div className={`drowpdown-edit ${this.state.open ? 'show' : ''}`}>
                            <div className="p-2">
                                <div className="multiple-items-box-component dropdown-chosen-options-wrapper">
                                    {this.state.fileList.map((fileItem, index) => (
                                        <div className="item-tokens-line" key={index}>
                                            <div className="single-box-item-component item-token can-change-content">
                                                <div className="item-wrapper">
                                                    <div className="dropdown-item-name"> {fileItem.name} </div>
                                                </div>
                                                <div className="clear-button" onClick={() => {
                                                    // if (this.state.actionPermission === 'edit'  && getMyGroupEditPermission(this.props.search.boarddata[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.search.boarddata[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                                                    //     this.removeFileItem(fileItem)
                                                    // } else {
                                                    //     this.props.fetchError("You don't have permission. You can only view")
                                                    // }
                                                }}>
                                                    <i className="fa fa-close"></i>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="dropdown-label-list">
                                    <div className="dropdown-label-suggest" style={{ display: `${this.state.openEdit ? 'none' : ''}` }}>
                                        <div className="pt-1 pb-1">
                                            <div className="dropdown-label-item" onClick={() => {
                                                // if (this.state.actionPermission === 'edit'  && getMyGroupEditPermission(this.props.search.boarddata[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.search.boarddata[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                                                //     this.openDialog()
                                                // } else {
                                                //     this.props.fetchError("You don't have permission. You can only view")
                                                // }
                                            }}>
                                                <i className="fa fa-paperclip" style={{ fontSize: '18px', marginRight: '6px' }}></i>
                                                From Computer
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : ''}

                    {/* Html multiple file input element start */}
                    <input type="file"
                        ref={this.hiddenFileInput}
                        onChange={(event) => {
                            if (this.state.actionPermission === 'edit'  && getMyGroupEditPermission(this.props.search.boarddata[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.search.boarddata[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                                this.uploadMultipleFiles(event)
                            } else {
                                this.props.fetchError("You don't have permission. You can only view")
                            }
                        }}
                        className="d-none"
                        name="myImage" accept="*/*"
                        multiple
                        disabled={this.state.actionPermission === 'view' ? 'disabled' : ''}
                    />
                    {/* Html multiple file input element end */}
                </div>
                {/* File manage Dropdown start */}

                {/* Image file preview modal start */}
                <Modal
                    isOpen={this.state.modalIsOpen}
                    onAfterOpen={() => this.afterOpenModal()}
                    onRequestClose={() => this.closeModal()}
                    style={customStyles}
                    contentLabel="File Image Preview"
                >
                    <div className="w-100 h-100 d-flex">
                        <div className="w-75 h-100 bg-dark d-flex align-items-center justify-content-center" style={{ padding: '36px', position: 'relative' }}>
                            <i className="fa fa-angle-left" onClick={() => this.prevFile()}

                                style={{ position: 'absolute', left: '42px', fontSize: '56px', color: 'white', cursor: 'pointer' }}></i>

                            {this.state.previewFile.typeSort === "image" && (
                                <img src={this.state.previewFile?.path} className="h-100" alt=""></img>
                            )}

                            <i className="fa fa-angle-right" onClick={() => this.nextFile()}
                                style={{ position: 'absolute', right: '42px', fontSize: '56px', color: 'white', cursor: 'pointer' }}></i>
                        </div>
                        <div className="w-25 h-100">
                            <i className="fa fa-close" onClick={() => this.closeModal()} style={{ position: 'absolute', right: '20px', top: '20px', fontSize: '24px', cursor: 'pointer' }}></i>
                            <div className="my-3 px-4 py-5">
                                <div>
                                    <img src={createImageFromInitials(56, this.people.firstname + " " + this.people.lastname, this.people.color)} style={{ borderRadius: '50%' }} />
                                    <span style={{ fontSize: '20px', fontFamily: "'Roboto', helvetica, arial, sans-serif", marginLeft: '6px' }}> {this.people.firstname + " " + this.people.lastname} </span>
                                </div>
                                <h4 className="mt-3" style={{ color: 'black', wordBreak: 'break-all' }}> {this.state.previewFile?.name} </h4>

                                <hr className="my-5" style={{ borderTop: "2px solid rgba(0, 0, 0, 0.1)" }}></hr>

                                <div className="d-flex">
                                    <div style={{ width: '40%', fontWeight: 'bold' }}> Created: </div>
                                    <div style={{}}> {this.state.imagePreviewProperty?.created} </div>
                                </div>
                                <div className="d-flex">
                                    <div style={{ width: '40%', fontWeight: 'bold' }}> Type: </div>
                                    <div style={{}}> {this.state.imagePreviewProperty?.type} </div>
                                </div>
                                <div className="d-flex">
                                    <div style={{ width: '40%', fontWeight: 'bold' }}> Size: </div>
                                    <div style={{}}> {this.state.imagePreviewProperty?.size} </div>
                                </div>
                                {
                                    this.state.imagePreviewProperty?.dimension && (
                                        <div className="d-flex">
                                            <div style={{ width: '40%', fontWeight: 'bold' }}> Dimensions: </div>
                                            <div style={{}}> {this.state.imagePreviewProperty?.dimension} </div>
                                        </div>
                                    )
                                }

                                <hr className="my-5" style={{ borderTop: "2px solid rgba(0, 0, 0, 0.1)" }}></hr>

                                <div className="d-flex align-items-center">
                                    <i className="fa fa-download" style={{ fontSize: '20px', marginRight: '6px', color: '#0085ff' }}></i>
                                    <a href="#" download onClick={(e) => { e.preventDefault(); this.downloadImage(this.state.previewFile) }} style={{ textDecoration: 'none' }}>Download</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {/* Image file preview modal end */}
            </div>
        )
    }
}

const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = { changeBoardItem, fetchError }

export default connect(mapStateToProps, mapDispatchToProps)(FileItem)