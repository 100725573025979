import React, { Component } from 'react'
import { Link } from 'react-router-dom';

class NavComponent extends Component {
    render() {
        return (
            <div>
                <nav className="navbar navbar-light bg-light static-top">
                    <div className="container">
                        <a className="navbar-brand" href="#">Start Bootstrap</a>
                        <Link to='/login'>
                            <div className="btn btn-primary">Sign In</div>
                        </Link>
                    </div>
                </nav>
            </div>
        )
    }
}

export default NavComponent;
