import React from 'react';

function ResetPasswordModal(props) {
    return (
        <div className="modal popups" data-backdrop="true" data-keyboard="false" id="resetpass" role="dialog" aria-labelledby="resetPass" aria-hidden="true" style={{ margin: '200px auto' }}>
        <div className="modal-content" style={{ minHeight: '317px', margin: '0 auto', padding: '23px 33px 20px 25px', maxWidth: '570px', borderRadius: '25px', overflow: 'hidden' }}>
          <div className="modal-header" style={{ height: '23px', backgroundColor: '#ffffff' }}>
            <h3 className="modal-title" id="resetPass" />
            <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
              <i className="bx bx-x" />
            </button>
          </div>

          <div className="modal-body" style={{ padding: '10px 10px 10px 10px' }}>
            <div className="row">
              <div className="col-md-6">
                <img src="/images/icon/auth/logincomponants5.png" style={{ width: '100%' }} />
              </div>
              <div className="col-md-6">
                <div className="row mt-50">
                  <h4 style={{ color: '#2c4d74' }}>Password Reset</h4>
                </div>
                <div className="row mt-50">
                  <span style={{ fontWeight: 300 }}>Enter the new password</span>
                </div>
                <div className="row mt-50">
                  <fieldset className="form-label-group form-group position-relative has-icon-right" style={{ marginBottom: '0px' }}>
                    <input type="password" className="form-control h-75 bg-transparent" required placeholder="Password" style={{ height: '32px !important', fontSize: '13px', fontWeight: 300 }} />
                    <div className="form-control-position" style={{ lineHeight: '1.8' }}>
                      <img src="/images/icon/auth/Mobileicons-205w.png" style={{ width: '40%', filter: 'invert(50%)' }} />
                    </div>
                    {/*<label for="iconLabelLeft">Password</label>*/}
                  </fieldset>
                </div>
                <div className="row mt-1">
                  <fieldset className="form-label-group form-group position-relative has-icon-right">
                    <input type="password" className="form-control h-75 bg-transparent" required placeholder="Confirm Password" style={{ height: '32px !important', fontSize: '13px', fontWeight: 300 }} />
                    <div className="form-control-position" style={{ lineHeight: '1.8' }}>
                      <img src="/images/icon/auth/Mobileicons-205w.png" style={{ width: '40%', filter: 'invert(50%)' }} />
                    </div>
                    {/*<label for="iconLabelLeft">Confirm Password</label>*/}
                  </fieldset>
                </div>
                
                {/* Pervious Validation  */}

                <div className="row mt-1">
                  <button type="submit" className="btn btn-custom-1  w-100 position-relative" style={{ backgroundColor: '#ff459e !important', width: '80% !important', marginTop: '20px' }} ng-click="resetNext()">
                    Reset
                      <i id="icon-arrow" className="bx bx-right-arrow-alt" style={{ verticalAlign: 'middle' }} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default ResetPasswordModal;