import React, { useState, useEffect } from 'react';
import config from './../services/config.json';
import http from './../services/http-service';
import workspaceDataService from '../../data-services/workspace-data-services';
import DiscoverAddEditPopup from './discover-addedit.model';
import WorkspaceMembersPopup from "../workspace/components/workspaceNavBar/workspaceNavbarComponent/WorkspaceMembersPopup";
import { useSelector, useDispatch } from "react-redux";
const DiscoverSettingsDropDown = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [workSpacesSub, setWorkSpacesSub] = useState({ workspaces: [], subworkspaces: [] });
    const discoverWorkspace = useSelector(state => state.discover.discoverWorkspace);
    const params = {
        _id: props._id,
        discoverName: props.discoverName,
        discoverType: props.discoverType,
        parentId: props.parentId,
        parentType: props.parentType,
        CreatedBy: props.CreatedBy,
        admins: props.admins
    }
    const [boardMemberOpen, setBoardMemberOpen]=useState(false);
    const [actionType,setActionType]=useState(0);
   
   const  boardMembers = () => {

           setBoardMemberOpen(true);
    }
    useEffect(() => {
        getWorkSpacesandSub();
    }, [props.discoverType])

    const getWorkSpacesandSub = async () => {
      
        if (props.discoverType === 1) {

        }
       else if (props.discoverType === 2) {
            await http.get(config.apiEndPoint + workspaceDataService.getAllWorkspaceWithoutPopulate)
                .then(response => {

                    setWorkSpacesSub({ workspaces: response.data.workspaces });
                })
                .catch(error => {
                    console.log(error);

                });
        }
        else if (props.discoverType === 3) {
            await http.get(config.apiEndPoint + workspaceDataService.getAllWorkspaceWithoutPopulate)
                .then(response => {
                   
                    // setWorkSpacesSub({ workspaces: response.data.workspaces, subworkspaces: response.data.subworkspaces });
                })
                .catch(error => {
                    console.log(error);

                });
        }

    };

    return (
        <>
            <div>
                <ul className="dropdown-menu multi-level animate slideIn Discover-first-level-dropdown" role="menu" aria-labelledby="dropdownMenu" style={{ marginTop: '22px' }}>
                    <li className="dropdown-item">
                        <button className="btn-sub-nav" style={{ background: 'none', border: 'none' }} onClick={() => { setActionType(1); setShowModal(true); }}>
                            <i className="fa fa-pencil-square-o" style={{ fontSize: '13px' }}></i>&nbsp;&nbsp;Rename {props.discoverName}</button>
                    </li>
                    {/* {(props.discoverType === 2 || props.discoverType === 3) &&
                        <li className="dropdown-item" style={{ position: 'relative', minWidth: '250px' }}>
                            <button className="btn-sub-nav sub-menu-nav" style={{ 'background': 'none', border: 'none' }}>
                                <i className="fa fa-arrow-circle-o-right" style={{ fontSize: '14px' }}></i>&nbsp;&nbsp; Move to Workspace
                                  <i className="fa fa-chevron-right" aria-hidden="true" style={{ fontSize: '13px', float: 'right', marginTop: '10px' }}></i>

                                <ul className="dropdown-menu show multi-level" style={{ position: 'absolute', marginLeft: '259px', marginTop: '-8px' }}>
                                    {workSpacesSub.workspaces !== null && workSpacesSub.workspaces !== undefined &&
                                        workSpacesSub.workspaces.map((workspace, index) => (
                                            workspace._id.toString() !== props.parentId.toString() ?
                                                (
                                                    <li className='dropdown-item' key={index}>
                                                        <button className='btn-sub-nav'>
                                                            <i className="fa fa-fw fa-newspaper-o"></i> {workspace.title}
                                                        </button>
                                                    </li>
                                                ) : ''
                                        ))
                                    }
                                </ul>

                            </button>


                        </li>
                    } */}

                    {/* {(props.discoverType === 3) && */}

                    {/* <li className="dropdown-item" style={{ position: 'relative', minWidth: '250px' }}>
                            <button className="btn-sub-nav sub-menu-nav" style={{ 'background': 'none', border: 'none' }}>
                                <i className="fa fa-arrow-circle-o-right" style={{ fontSize: '14px' }}></i>&nbsp;&nbsp; Move to Sub Workspace
                                  <i className="fa fa-chevron-right" aria-hidden="true" style={{ fontSize: '13px', float: 'right', marginTop: '10px' }}></i>

                                <ul className="dropdown-menu show multi-level" style={{ position: 'absolute', marginLeft: '259px', marginTop: '-8px' }}>
                                {workSpacesSub.subworkspaces !== null && workSpacesSub.subworkspaces !== undefined &&
                                    workSpacesSub.subworkspaces.map((subworkspace, index) => (
                                        subworkspace._id.toString() !== props.parentId.toString() ?
                                                (
                                                    <li className='dropdown-item' key={index}>
                                                        <button className='btn-sub-nav'>
                                                        <i className="fa fa-fw fa-newspaper-o"></i> {subworkspace.title}
                                                        </button>
                                                    </li>
                                                ) : ''
                                        ))
                                    }
                                </ul>

                            </button>


                        </li> */}
                    {/* } */}


{/* 
                    <li className="dropdown-item">
                        <button className="btn-sub-nav" style={{ 'background': 'none', border: 'none' }}>
                            <i className="bx bxs-color-fill" style={{ fontSize: '13px' }}></i>&nbsp;&nbsp;Set Color</button>
                    </li> */}
                    {/* <li className="dropdown-item">
                        <button className="btn-sub-nav" style={{ 'background': 'none', border: 'none' }}>
                            <i className="bx bx-star" style={{ fontSize: '13px' }}></i>&nbsp;&nbsp;Add to Favourite</button>
                       
                    </li> */}
                    {/* <li className="dropdown-item">
                        <button className="btn-sub-nav" style={{ 'background': 'none', border: 'none' }} onClick={() => { setBoardMemberOpen(true)}}>
                            <i className="fa fa-fw fa-users icon-style" style={{ fontSize: '13px' }}></i>&nbsp;&nbsp;Manage Members</button>
                       
                    </li> */}
                    <li className="dropdown-item">
                        <button className="btn-sub-nav" style={{ 'background': 'none', border: 'none' }} onClick={() => { setActionType(2);  setShowModal(true); }}>
                            <i className="bx bx-trash" style={{ fontSize: '13px' }}></i>&nbsp;&nbsp;Delete</button>
                    </li>
                </ul>
            </div>
            <DiscoverAddEditPopup {...params} actionType={actionType} showModal={showModal} onHide={() => setShowModal(false)}></DiscoverAddEditPopup>

            {/* <WorkspaceMembersPopup
               
                workspaceData={discoverWorkspace.data}
                modalOpen={boardMemberOpen}
                closeModal={(event) => setBoardMemberOpen(event)}
            ></WorkspaceMembersPopup> */}
        </>
    )

}
export default DiscoverSettingsDropDown;
