import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

class ModalForgetPassword extends React.Component {
   constructor(props) {
      super(props);
      this.modalContainer = React.createRef()
   }

   render() {
      console.log("this.props.open: ", this.props.open)
      return (
         <div>
            <Modal className="forgot-password" isOpen={this.props.open} ref={this.modalContainer} style={{ margin: '200px auto' }}>
               <ModalHeader toggle={() => this.props.closeModal()}></ModalHeader>
               <ModalBody>
                  <div className="row">
                     <div className="col-md-6">
                        <img src="/images/icon/auth/Forgot-Password.png" style={{ width: '90%' }} />
                     </div>
                     <div className="col-md-6">
                        <div className="row mt-50">
                           <h4 style={{ color: '#2c4d74' }}>Forgot Password?</h4>
                        </div>
                        <div className="row mt-50">
                           <span style={{ fontWeight: 300 }}>Enter the email associated with your account</span>
                        </div>
                        <div className="row mt-50">
                           <input type="email" className="form-control h-50" placeholder="Enter Email Address" style={{ fontSize: '13px', fontWeight: 300 }} />
                        </div>
                        <div className="row mt-2">
                           <button className="btn btn-custom-1" style={{ width: '120px', marginTop: '20px', border: '1px solid #EAEAEA !important' }}>Next</button>
                        </div>
                     </div>
                  </div>
               </ModalBody>
            </Modal>
         </div>

      );
   }
}

export default ModalForgetPassword;