export const  getAllBoards = "board/getAllBoards"; 
export const  getBoardById = "board/getBoardById"; 
export const  saveBoard = "board/saveBoard" ; 
export const  updateBoard = "board/updateBoard" ; 
export const  deleteBoard = "board/deleteBoard" ;
export const  boardSearch = "board/boardSearch" ;
export const  getAllBoardNames = "board/getAllBoardNames" ;
export const  boardFilter = "board/boardFilter" ;

export default {
  getAllBoards : getAllBoards , 
  getBoardById : getBoardById , 
  saveBoard : saveBoard , 
  updateBoard : updateBoard , 
  deleteBoard : deleteBoard , 
  boardSearch : boardSearch , 
  getAllBoardNames : getAllBoardNames , 
  boardFilter : boardFilter 
}

