import React from 'react';
import { Link } from 'react-router-dom';

function RightSideLinks(props) {
  return (
    <div className="col-md-7 d-md-block d-none text-center align-self-center p-5" style={{ backgroundColor: 'white', height: '100%' }}>
      <div className="card-content">
        <h2 style={{ textAlign: 'left', color: '#ff459e', position: 'absolute', fontWeight: 400 }}>Where work is<br /> Simplified.</h2>
        <img className="img-fluid" src="/images/loginbgnew.png" alt="branding logo" style={{ width: '75%' }} />
      </div>
      <div className="row" style={{ paddingTop: '50px', marginLeft: '10px' }}>
        <div className="col-xl-2 col-md-2 col-sm-2">
          <div className="card border-0">
            <div className="card-content" style={{ position: 'relative', left: 0, top: 0, backgroundImage: 'url("/images/icon/auth/Mobileicons-202.png")', width: '120px', height: '120px' }}>
              <Link to="/register">
                <img className="img-fluid" src="images/icon/auth/Mobileicons-201.png" alt="Card image cap" style={{ marginTop: '9%', width: '55%' , marginLeft: '20%' }} />
                <div className="card-body" style={{ marginTop: '-10px' }}>
                  <label style={{ textTransform: 'none', cursor: 'pointer' }}>Sign Up</label>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-md-2 col-sm-2">
          <div className="card border-0">
            <div className="card-content" style={{ position: 'relative', left: '0', top: '0', backgroundImage: 'url("/images/icon/auth/Mobileicons-202.png")', width: '120px', height: '120px' }}>
              <Link to="/faq">
                <img className="img-fluid" src="/images/icon/auth/Mobileicons-196.png" alt="Card image cap" style={{ marginTop: '9%', width: '55%' , marginLeft: '20%' }} />
                <div className="card-body" style={{ marginTop: '-10px' }}>
                  <label style={{ textTransform: 'none', cursor: 'pointer' }}>Help</label>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-md-2 col-sm-2">
          <div className="card border-0">
            <div className="card-content" style={{ position: 'relative', left: '0', top: '0', backgroundImage: 'url("/images/icon/auth/Mobileicons-202.png")', width: '120px', height: '120px' }}>
              <Link to="/">
                <img className="img-fluid" src="/images/icon/auth/Mobileicons-197.png" alt="Card image cap" style={{ marginTop: '9%', width: '55%' , marginLeft: '20%' }} />
                <div className="card-body" style={{ marginTop: '-10px', width: '150px' }}>
                  <label style={{ textTransform: 'none', cursor: 'pointer', marginRight: '20px' }}>System Admin</label>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="col-xl-2 col-md-2 col-sm-2">
          <div className="card border-0">
            <div className="card-content" style={{ position: 'relative', left: 0, top: 0, backgroundImage: 'url("/images/icon/auth/Mobileicons-202.png")', width: '120px', height: '120px' }}>
              <Link to="/">
                <img className="img-fluid" src="/images/icon/auth/Mobileicons-198.png" alt="Card image cap" style={{ marginTop: '9%', width: '55%' , marginLeft: '20%' }} />
                <div className="card-body" style={{ marginTop: '-10px' }}>
                  <label style={{ textTransform: 'none', cursor: 'pointer' }}>App Store</label>
                </div>
              </Link>

            </div>
          </div>
        </div>
        <div className="col-xl-2 col-md-2 col-sm-2">
          <div className="card border-0">
            <div className="card-content" style={{ position: 'relative', left: 0, top: 0, backgroundImage: 'url("/images/icon/auth/Mobileicons-202.png")', width: '120px', height: '120px' }}>
              <Link to="/">
                <img className="img-fluid" src="/images/icon/auth/Mobileicons-199.png" alt="Card image cap" style={{ marginTop: '9%', width: '55%' , marginLeft: '20%' }} />
                <div className="card-body" style={{ marginTop: '-10px' }}>
                  <label style={{ textTransform: 'none', cursor: 'pointer' }}>Google Play</label>
                </div>
              </Link>
            </div>
          </div>

        </div>

      </div>

      <div style={{ width: '100vw', marginLeft: '-70px' }}>
        <label style={{ textTransform: 'none', marginRight: '15%', color: '#999999', fontWeight: '300', fontSize: '12px' }}>Copyright © 2020 Engage.All rights reserved</label>
      </div>
    </div>
  );
}

export default RightSideLinks;