import React from 'react';
import Form from '../common/Form.jsx';

class Modal extends Form {  
  render(){  
  const {Name , onChangeCityName , selectedOption_country , options_country , selectedOption_state , options_state , handleChange_country , handleChange_state , 
          SaveOrUpdate , errorCityName , errorCountryId , errorStateStateId , isUpdate} = this.props;
    return (
        <div className="modal fade" id="modal-Country">
        <div className="modal-dialog modal-lg modal-plus">
          <div className="modal-content">
               {this.renderModalHeaderTitle("Add City Details")}

            <div className="modal-body" style={{ marginLeft: '20px', marginRight: '20px' }}>
              <form className="form-horizontal" name={this.returnFormName("cityForm")}>
                <div className="form-group row">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                          {this.renderInput("text", Name, "Name", errorCityName , onChangeCityName)}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                            {this.renderSelectOptionsControl(selectedOption_country , handleChange_country , options_country , "Country Name" ,  errorCountryId)}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                            {this.renderSelectOptionsControl(selectedOption_state , handleChange_state , options_state , "Country Name" ,  errorStateStateId)}
                      </div>
                    </div>
                    
                    <div className="col-md-6 col-12">
                      {this.renderSubmitButton("submit" , (errorCityName != null || errorCountryId != null || errorStateStateId != null)  
                        && isUpdate == false , SaveOrUpdate , "Save")}
                      {this.renderCancelButton()}                                        
                    </div>

                    <div className="col-md-6 col-12">
                    </div>
                    <div className="col-md-12 col-lg-12 col-12">
                      <div style={{ float: 'left', marginLeft: '-15px', verticalAlign: 'baseline' }}>
                      </div>
                      <div style={{ float: 'right', marginRight: '-2px' }}>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </form>
            </div>
          </div>
        </div> 
      </div> 
      );
}
}
export default Modal;