import React from 'react';

function RegisterModal(props) {
    return (
        <div className="modal popups" data-backdrop="static" data-keyboard="false" id="confirmreg" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style={{marginTop: '10%'}}>
          <div className="card" style={{backgroundImage: 'url("/images/icon/auth/2-01.png")', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '282px', margin: '0 auto', maxWidth: '312px', borderRadius: '25px'}}>
            <div className="card-content text-center">
              <div className="card-body">
                <div className="modal-header">
                  <h4 className="card-title  text-center text-white" style={{fontSize: '1rem'}}>You've Got A Mail</h4>
                  <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                    <i className="bx bx-x" />
                  </button>
                </div>
              </div>
              <img className="img-fluid" src="../../Content/images/icon/auth/3-01.png" alt="Card image cap" width={100}  />
            </div>
            <div className="card-footer d-flex justify-content-between p-0" style={{margin: '0 auto'}}>
              <label className="mb-0" style={{textTransform: 'none'}}>We sent a confirmation link to:</label>
            </div>
            <div className="card-footer d-flex justify-content-between p-0" style={{margin: '0 auto'}}>
              <span>{'{'}{'{'}email{'}'}{'}'}</span>
            </div>
          </div>
        </div>
    );
}

export default RegisterModal;