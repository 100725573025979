import React from 'react';
import ReminderList from '../../scheduler/reminder-list';

function ReminderWidget(props) {
    const { ShowReminderWidgetValue } = props;
    return (
        <>
            <div>
                {
                    ShowReminderWidgetValue &&
                    <div className="row right-section-second " style={{ marginTop: '-20px' }} >
                        <ReminderList userId={1} />
                    </div>
                }

            </div>
        </>
    );
}

export default ReminderWidget;