import React, { Component } from 'react';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

class License extends Component {
  state = {}
  render() {
    return (

      <>
        <MainMenu link={`license`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <div className="row dash-blue fixed-top">
                  <div className="col-md-1 col-md-1">
                  </div>
                  <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
                    <div>
                      <div className="inner-section">
                        <h3>Master Settings</h3>
                        <div style={{ marginTop: '-10px' }}>
                          <p className="mt-1">License Key</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
                    <table className="align-items-center table-dashboard-section-one">
                      <tbody><tr>
                        <td rowSpan={2} style={{ width: '300px', textAlign: 'left' }}>
                          <input className="search-dashboard" type="text" name="search" placeholder="Search Here.." />
                        </td>
                      </tr>
                        <tr style={{ fontWeight: 600 }}>
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                        </tr>
                      </tbody></table>
                    <table className="align-items-center table-dashboard-section-last d-flex">
                      <tbody><tr>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-27.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-38.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-37.png" /></a></td>
                        <td rowSpan={2} style={{ fontWeight: 100, display: 'inline-flex' }}>&nbsp;&nbsp;Sort <img src="/images/icon/dashboard-icons/Panel icons-32.png" style={{ marginLeft: '10px' }} /></td>
                      </tr>
                        <tr>
                          <td>&nbsp;&nbsp;</td>
                        </tr>
                        <tr>
                        </tr></tbody></table>
                  </div>
                </div>
                <div className="content-wrapper" ng-app="myApp">
                  <div className="company-table">
                    {/* Start Table Content*/}
                    <table className="table-striped module-table" aria-describedby="example_info">
                      <thead>
                        <tr>
                          <th style={{ paddingLeft: '20px' }}>Install License Key</th>
                        </tr>
                      </thead>
                    </table>
                    <br /><br />
                    <form className="form-horizontal" ng-submit="Install(Form.$valid)" noValidate name="Form">
                      <div className="form-group ">
                        <div className="row">
                          <div className="col-md-4 col-12">
                            <div className="form-label-group">
                              <input ng-model="licenseKey" className="form-control outline" ng-required="true" />
                              <label className="form-control-label">&nbsp;&nbsp;License Key&nbsp;<span className="required" style={{ color: 'red' }}>*</span>&nbsp;&nbsp; </label>
                            </div>
                            <button type="button" className="btn-custom-1" style={{ float: 'right' }}>Check</button> &nbsp;&nbsp;&nbsp;
                  </div>
                        </div>
                      </div>
                    </form>
                    {/* End Table Content*/}
                  </div> {/* ENd Company Table*/}
                </div> {/* End Content- Wrapper*/}
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }
}

export default License;