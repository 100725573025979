import config from '../services/config.json';
import MessagesDataService from '../../data-services/message-data-services';
import MessageTypes from '../../data-services/messagetypes-data-service';

export const getAllMessages = config.apiEndPoint + MessagesDataService.getAllMessages;
export const getMessageByBodyContent = config.apiEndPoint + MessagesDataService.getMessageByBodyContent;

export const getMessageByIWasMentioned = config.apiEndPoint + MessagesDataService.getMessageByIWasMentioned;
export const getMessageByIWasWrote = config.apiEndPoint + MessagesDataService.getMessageByIWasWrote;
export const getAllMessagesGroupedbyUserAndFile = config.apiEndPoint + MessagesDataService.getAllMessagesGroupedbyUserAndFile;
export const getMessageByType = config.apiEndPoint + MessagesDataService.getMessageByType;

export const createmessage = config.apiEndPoint + MessagesDataService.createmessage;
export const updatemessage = config.apiEndPoint + MessagesDataService.updatemessage;
export const download = config.apiEndPoint + MessagesDataService.download;




export const getAllMessagesType = config.apiEndPoint + MessageTypes.getAllMessagesType;
export const getMessageTypeById = config.apiEndPoint + MessageTypes.getMessageTypeById;
export const saveMessageType = config.apiEndPoint + MessageTypes.saveMessageType;
export const updateMessageType = config.apiEndPoint + MessageTypes.updateMessageType;
export const deleteState = config.apiEndPoint + MessageTypes.deleteState;