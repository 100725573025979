import React from 'react';
import AutoNumberItem from './items/autonumber';
import DateItem from './items/date';
import DropdownItem from './items/dropdown';
import FileItem from './items/file';
import FormulaItem from './items/formula';
import LastUpdatedItem from './items/lastupdated';
import LocationItem from './items/location';
import LongTextItem from './items/longtext';
import NumbersItem from './items/numbers';
import PeopleItem from './items/people';
import ProgressTrackingItem from './items/progresstracking';
import RatingItem from './items/rating';
import StatusItem from './items/status';
import TagsItem from './items/tags';
import TextItem from './items/text';
import TimelineItem from './items/timeline';
import ReminderItem from './items/reminder';

class CellComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { groupInd, rowInd, colInd, item } = this.props;
        
    

        switch (item.key) {
            case 'status':
                return (
                    <StatusItem 
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'date':
                return (
                    <DateItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'timeline':
                return (
                    <TimelineItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'text':
                return (
                    <TextItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'numbers':
                return (
                    <NumbersItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'tags':
                return (
                    <TagsItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'dropdown':
                return (
                    <DropdownItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'formula':
                return (
                    <FormulaItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'people':
                return (
                    <PeopleItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'file':
                return (
                    <FileItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'autoNumber':
                return (
                    <AutoNumberItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'location':
                return (
                    <LocationItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'rating':
                return (
                    <RatingItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'longText':
                return (
                    <LongTextItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} />
                )
            case 'progressTracking':
                return (
                    <ProgressTrackingItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} cellItems={this.props.cellItems} 
                    item={this.props.item}/>
                )
            case 'lastUpdated':
                return (
                    <LastUpdatedItem
                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    isBoardAdmin={this.props.isBoardAdmin} 
                    columnHeader={this.props.columnHeader}
                    groupInd={groupInd} rowInd={rowInd} colInd={colInd} lastUpdated={this.props.lastUpdated} {...item} cellItems={this.props.cellItems} />
                )
                case 'reminder':
                    return (
                        <ReminderItem
                        isWorkspaceOwner={this.props.isWorkspaceOwner}
                        isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                        isBoardAdmin={this.props.isBoardAdmin} 
                        groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                    )
            default:
                return (
                    <div>{item.body}</div>
                );
        }
    }
}

export default CellComponent;

