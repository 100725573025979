import React from 'react';

class TagsBottom extends React.Component {
    constructor(props) {
        super(props);
    }

    getAllTags = (groupItems) => {
        let tags = [];
        let groupHeader = groupItems[0];
        let cellItemIndex = groupHeader.cellItems.findIndex(cell => cell._id === this.props.id);
        for (let i = 1; i < groupItems.length; i++) {
            let cellItem = groupItems[i].cellItems[cellItemIndex];
            let body = JSON.parse(cellItem.body);
            if (body.length > 0) {
                if (tags.length === 0) {
                    tags = [...body]
                } else {
                    for (let j = 0; j < body.length; j++) {
                        let checkTags = tags.filter(tag => tag._id === body[j]._id);
                        // console.log("checkTags===>", checkTags)
                        if (checkTags.length === 0) {
                            tags.push(body[j])
                        }
                    }
                }
            }
        }

        return tags;
    }

    render() {
        const tags = this.getAllTags(this.props.groupItems);
        // console.log("tags===>", tags)
        return (
            <div className='dropdown-tags-header pl-1 pr-1'>
                <div className='p-1 tags-cell-component'
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '38px', backgroundColor: '#e6e9ef' }}
                >
                    {tags.length > 2 ? (
                        <>
                            <div className='tag-prevent-open-dialog'>
                                {tags.map((tag, index) => (
                                    index < 2 ? (
                                        <div className='ds-text-component' key={index} style={{ color: `${tag.color}` }}># {tag.name}</div>
                                    ) : ""
                                ))}
                            </div>
                            <div className="dropdown-counter-component">
                                <div className="dropdown-counter-text">+{tags.length - 2}</div>
                            </div>
                        </>
                    ) : (
                            <div className='tag-prevent-open-dialog'>
                                {tags.map((tag, index) => {
                                    return (
                                        <div className='ds-text-component' key={index} style={{ color: `${tag.color}` }}># {tag.name}</div>
                                    )
                                })}

                            </div>
                        )}
                </div>
            </div>
        )
    }
}

export default TagsBottom;