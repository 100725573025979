import React from 'react';

function ModalSuccessResetPassword(props) {
    return (
        <div className="modal popups" data-backdrop="true" data-keyboard="false" id="confirmreset" role="dialog" aria-labelledby="confirmReset" aria-hidden="true" style={{ margin: '200px auto' }}>
        <div className="modal-content" style={{ minHeight: '317px', margin: '0 auto', maxWidth: '500px', padding: '23px 33px 20px 25px', borderRadius: '25px', backgroundImage: 'url("/images/icon/auth/logincomponants6.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', overflow: 'hidden' }}>
          <div className="modal-header" style={{ height: '23px', backgroundColor: '#ffffff' }}>
            <h3 className="modal-title" id="confirmReset" />
            <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
              <i className="bx bx-x" />
            </button>
          </div>
          <div className="modal-body" style={{ padding: '7rem 8rem 0rem 8rem', textAlign: 'center' }}>
            <div className="row">
              <div className="col-md-12">
                <h4>Your password has been set Successfully</h4>
              </div>
            </div>
          </div>
          <div className="modal-footer" style={{ padding: 0, borderTop: 'none', justifyContent: 'center' }}>
            <button type="button" data-dismiss="modal" style={{ color: 'white', backgroundColor: '#e84291', border: '1px solid #e84291', padding: '5px 10px 5px 10px', borderRadius: '8px' }}>
              <i className="bx bx-x d-block d-sm-none" />
              <span className="d-none d-sm-block">Continue Login</span>
            </button>
          </div>
        </div>
      </div>
    );
}

export default ModalSuccessResetPassword;