import React from 'react';
import { connect } from 'react-redux';
import { changeBoardItem, fetchError } from '../../../../../actions'
import ReactTooltip from 'react-tooltip';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../../util/permission';

class LongTextItem extends React.Component {
    changed = false;
    constructor(props) {
        super(props)
        this.state = {
            text: "",
            focus: false,
            actionPermission: 'edit'
        }
        this.dropdownContainer = React.createRef();
        this.changeText = this.changeText.bind(this);
    }

    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
        this.setState({ text: this.props.body || '' });

        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem.cellItems;
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem.cellItems;
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.focus && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ focus: false });
            if (this.changed) {
                this.props.changeBoardItem({
                    groupInd: this.props.groupInd,
                    rowInd: this.props.rowInd,
                    colInd: this.props.colInd,
                    body: this.state.text,
                    cellItemID: this.props.columnId,
                    boardId: this.props.boardData.boardID
                })
                this.changed = false;
            }
        }
    }

    changeText = (event) => {
        this.setState({ text: event.target.value });
        this.changed = true;
    }

    render() {
        return (
            <div className="d-flex align-items-center" style={{ position: 'relative', padding: '0 4px', height: '37px' , 'border' : '1px solid #EAEAEA' , 'border-top' :' none' ,
            'border-bottom' : 'none' ,
            'border-right' : 'none'  }} ref={this.dropdownContainer}>
                <div className="long-text-element text-truncate p-1" data-tip={this.state.text} name="openFile" style={{ cursor: 'text', height: '32px', width: '150px' }}
                    onClick={() => {                       
                        if (this.state.actionPermission === 'edit'  && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                            this.setState({ focus: true })
                        } else {
                            this.props.fetchError("You don't have permission. You can only view")
                        }
                    }}>
                    {this.state.text}
                </div>
                <ReactTooltip place="top" effect="solid" />

                {this.state.focus && (
                    <div style={{ position: 'absolute', top: 0, left: 0, zIndex: 9 }} >
                        <textarea rows={6} value={this.state.text} onChange={this.changeText} style={{ padding: '8px 4px', width: '100%' ,     'min-width' : '200px' ,
    'border':" 1px solid #EAEAEA" ,    ' background-color' : '#f5f5f5' ,
    'box-shadow' : '1px 0px 9px 1px #9a9999' , 'font-size' : '14px'
}} />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeBoardItem, fetchError }

export default connect(mapStateToProps, mapDispatchToProps)(LongTextItem)