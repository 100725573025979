export const register = 'users/register';
export const login = 'users/login';
export const getallUser = 'users/getAllUsers';
export const getUserById = 'users/getUserById';
export const getAllUsers = 'users/getAllUsers';
export const updateUser = 'users/updateUser';
export const deleteUser = 'users/deleteUser';
export const getuserCoulmn = 'users/getuserCoulmn';
export const addCoulmn = 'users/addCoulmn';
export const resetpassword = 'users/resetpassword';
export const getUserByFirstName = 'users/getUserByFirstName';
export const getUserByLastName = 'users/getUserByLastName';
export const getUserByEmail = 'users/getUserByEmail';
export const getUserByMobileNo = 'users/getUserByMobileNo';
export const getUserByPosition = 'users/getUserByPosition';

export default {
  register: register,
  login: login,
  getallUser: getallUser,
  getUserById: getUserById,
  updateUser: updateUser,
  deleteUser: deleteUser,
  getuserCoulmn: getuserCoulmn,
  addCoulmn: addCoulmn,
  resetpassword: resetpassword,
  getUserByFirstName: getUserByFirstName,
  getUserByLastName: getUserByLastName,
  getUserByEmail: getUserByEmail,
  getUserByMobileNo: getUserByMobileNo,
  getUserByPosition: getUserByPosition
}
