import jsPDF from "jspdf";
import "jspdf-autotable";
// Date Fns is used to format the dates we receive
// from our API call
import { format } from "date-fns";
import moment from 'moment';

// define a generatePDF function that accepts a tickets argument
const generatePDF = downloads => {

  // initialize jsPDF
  const doc = new jsPDF();

  // doc.autoTable({ html: '#my-table' })

  // define the columns we want and their titles
  const tableColumn = ["Sl Nos", "Title", "Description", "Date"];
  // define an empty array of rows
  const tableRows = [];

  // for each ticket pass all its data into an array
  downloads.forEach((download, i) => {
    const downloadData = [
      i + 1,
      download.title,
      download.description,
      moment(moment.utc(download.date).toDate()).local().format("DD MMM YYYY | h:mmA")

      // called date-fns to format the date on the ticket
      //format(new Date( download.date), "yyyy-MM-dd")
    ];
    // push each tickcet's info into a row
    tableRows.push(downloadData);
  });


  // startY is basically margin-top


  doc.autoTable(

    tableColumn,
    tableRows,
    {
      Y: 50,
      // styles: { fillColor: [255, 0, 0] },
      headStyles: { fillColor: "#e8378f" }
    },

  );
  const date = Date().split(" ");
  // we use a date string to generate our filename.
  const dateStr = date[0] + date[1] + date[2] + date[3] + date[4];
  // ticket title. and margin-top + margin-left
  //   doc.text("Closed tickets within the last one month.", 14, 15);
  // we define the name of our PDF file.
  doc.save(`scheduler_${dateStr}.pdf`);
};

export default generatePDF;