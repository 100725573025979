import React from 'react';
import Field from './Field';
class FieldRow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: []
        };
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }
  
    componentDidMount() {
        const { cellItems } = this.props;
        console.log('cellItemsfieldrow==>', cellItems)
        this.setState({ items: cellItems });
    }
  
    componentWillReceiveProps(nextProps) {
        this.setState({ items: nextProps.cellItems });
    }
    render() {
        const { _id, title } = this.props;
  
        return (
           
                        <div className="">
                            <div className={''}>
                                <div className=''
                                    style={{ opacity: this.props.category === "header" ? 0 : 1, minWidth: '200px', width: '100%' }}>
                                    {title}
                                </div>
                            </div>
                            <div className='row'>
                            {this.props.cellItems
                            .map((item, index) => {
                                if (this.props.category === "header") return (
                                    
                                        
                                                        <div
                                                            className='col-md-4'
                                                            
                                                        >
                                                          <span class="user-profile-label" style={{
      position: 'absolute',
      top: '-105px',
      color: '#21396c',
      left: '35px',
      backgroundColor: '#fff'}}>{item.body}<span class="asterik">*</span></span>
                                                          
                                                            {/* <GroupColumnTitle groupInd={this.props.groupInd} rowInd={this.props.rowInd} colInd={index} {...item} /> */}
                                                        </div>
                                                    
                                        
                                    
                                );
                                else return (
                                   
                                        <div className='col-md-4'>
                                          <Field item={item} colInd={index} jsonschema={this.props.jsonschema} groupInd={this.props.groupInd} rowInd={this.props.rowInd} />
                                            {/* <div>{item.body}</div> */}
                                            {/* <CellComponent
                                              isWorkspaceOwner={this.props.isWorkspaceOwner}
                                              isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                                              isBoardAdmin={this.props.isBoardAdmin}
                                            groupInd={this.props.groupInd} lastUpdated={this.props.lastUpdated ? this.props.lastUpdated : null} rowInd={this.props.rowInd} colInd={index} item={item} cellItems={this.props.cellItems} /> */}
                                        </div>
                                );
                            })}
                           </div>
                        </div>
                    
        );
    }
  }
  export default FieldRow