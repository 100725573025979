import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import React from 'react';
import { connect } from 'react-redux'
import axios from '../../../../../util/Api';
import { createImageFromInitials, getRandomColor, validateEmail } from '../../../../../util/People';
import { showMessage, fetchError, updateWorkspaceBoard } from '../../../../../actions';

class BoardMembersPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            people: [],
            allPeople: [],
            showPeople: false,
            openEdit: false,
            boardMembers: [],
            searchText: '',
            inviteInputEmail: '',
            workspaceMembers: [],
            workspaceAdmins: [],
            workspaceOwner: '',            
            isWorkspaceOwner: false,
            isWorkspaceAdmin: false,
          
        }

        this.timeout = 0
        this.modalContainer = React.createRef();
        this.dropdownContainer = React.createRef();
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.showPeople && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ showPeople: false });
        }

        if (this.state.openEdit && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ showPeople: true, openEdit: false });
        }
    }


    // componentDidMount() {
    //     document.addEventListener('mousedown', this.onClickOutsideHandler);
    //     axios.get('/workspace/getWorkSpaceById/', {
    //         params: { id: this.props.boardData.workspaceID }
    //     }).then(({ data }) => {
    //         let workspace = data.workspaces;
    //        // let members = workspace.members;
    //        // let members = this.getFilteredUserLists(workspace.members);
    //         let filteredmembers = workspace.members;
          
    //         this.setState({ workspaceMembers: filteredmembers})
    //         this.setState({ workspaceAdmins: workspace.admins })
    //         this.setState({ workspaceOwner: workspace.CreatedBy })
    //         this.setState({ people: filteredmembers, allPeople: filteredmembers });


    //         var workspaceAdminsToPush = filteredmembers.filter(function(item) {
    //             return workspace.admins.indexOf(item._id) != -1              
    //           });
    //           console.log('ww',this.props)
    //          this.pushWorkspaceAdminsToBoardMembers(workspaceAdminsToPush);
           
    //     }).catch(err => {
    //         console.log("Error: ", err)
    //     })      

    //     let boardMembers = this.props.boardData.workspaceBoard.members ? JSON.parse(this.props.boardData.workspaceBoard.members) : [];
    //     this.setState({ boardMembers: boardMembers })
    // }
    componentDidMount() {

        document.addEventListener('mousedown', this.onClickOutsideHandler);
        axios.get('/workspace/getWorkSpaceById/', {
            params: { id: this.props.boardData.workspaceID }
        }).then(({ data }) => {
            let workspace = data.workspaces;
           // let members = workspace.members;
           // let members = this.getFilteredUserLists(workspace.members);
            let filteredmembers = workspace.members;
          
            this.setState({ workspaceMembers: filteredmembers})
            this.setState({ workspaceAdmins: workspace.admins })
            this.setState({ workspaceOwner: workspace.CreatedBy })
            this.setState({ people: filteredmembers, allPeople: filteredmembers });


            // var workspaceAdminsToPush = filteredmembers.filter(function(item) {
            //     return workspace.admins.indexOf(item._id) != -1              
            //   });
             
            //  this.pushWorkspaceAdminsToBoardMembers(workspaceAdminsToPush);
             
             if (this.authUser._id === this.state.workspaceOwner) {
                this.setState({ isWorkspaceOwner: true })
                this.setState({ isWorkspaceAdmin: true })
            }
            if (this.state.workspaceAdmins.includes(this.authUser._id)) {
                this.setState({ isWorkspaceAdmin: true })
            }
           
        }).catch(err => {
            console.log("Error: ", err)
        })      

        let boardMembers = this.props.boardData.workspaceBoard.members ? JSON.parse(this.props.boardData.workspaceBoard.members) : [];
        this.setState({ boardMembers: boardMembers })



        // document.addEventListener('mousedown', this.onClickOutsideHandler);
        // let workspace = this.props.boardData.workspace;
        // let filteredmembers = this.getFilteredUserLists(workspace.members);
        // this.setState({ workspaceMembers: filteredmembers })
        // this.setState({ workspaceAdmins: workspace.admins })
        // this.setState({ workspaceOwner: workspace.CreatedBy })
        // this.setState({ people: filteredmembers, allPeople: filteredmembers });
        // var workspaceAdminsToPush = filteredmembers.filter(function (item) {
        //     return workspace.admins.indexOf(item._id) != -1
        // });
        // this.pushWorkspaceAdminsToBoardMembers(workspaceAdminsToPush);
        // let boardMembers = this.props.boardData.workspaceBoard.members ? JSON.parse(this.props.boardData.workspaceBoard.members) : [];
        // this.setState({ boardMembers: boardMembers })



       
        // if(this.isBoardAdmin()){
        //     this.setState({ isBoardAdmin: true })  
        // }
        //  console.log('board members pop3',this.isBoardAdmin())
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickOutsideHandler);
    }

    getUserLists = (members) => {
        let newMembers = [];
        for (let i = 0; i < members.length; i++) {
            // let member = {
            //     _id: members[i]._id,
            //     name: members[i].firstname ? `${members[i].firstname} ${members[i].lastname}` : '',
            //     email: members[i].email,
            //     color: members[i].color ? members[i].color : getRandomColor()
            // }

            newMembers.push(members[i])
        }
        return newMembers;
    }


    pushWorkspaceAdminsToBoardMembers = (members) => {
        for (let i = 0; i < members.length; i++) {
            // let member = {
            //     _id: members[i]._id,
            //     name: members[i].firstname+' '+members[i].lastname,
            //     email: members[i].email,
            //     is_bAdmin: true,
            //     color: members[i].color ? members[i].color : getRandomColor()
            // }

            this.addPeople(members[i]);
        }
    }

    getFilteredUserLists = (members) => {
        let newMembers = [];
        for (let i = 0; i < members.length; i++) {
            let member = {
                _id: members[i]._id,
                name: members[i].firstname + ' ' + members[i].lastname,
                email: members[i].email,
                is_bAdmin: members[i].is_bAdmin ? members[i].is_bAdmin : false,
                color: members[i].color ? members[i].color : getRandomColor()
            }

            newMembers.push(member)
        }
        console.log('beforenew', newMembers)
        return newMembers;
    }

    addPeople = (people) => {
        let members = this.state.boardMembers;

        let checkMember = members.find(member => member._id === people._id);
        if (checkMember === undefined) {
            let authUser = JSON.parse(localStorage.getItem('authUser'));
            people.permission = authUser._id === people._id ? 'full' : 'view';
            people.name = people.firstname + ' ' + people.lastname;
            members.push(people);
            let message = `${people.name} subscribed to board!`;
            let name = people.name !== '' ? people.name : people.email;
            this.setState({ boardMembers: members, showPeople: false });
            console.log('before', this.state.workspaceMembers)
            let workspacemembers = this.state.workspaceMembers
            workspacemembers.push(people);
            this.setState({ workspaceMembers: this.getFilteredUserLists(workspacemembers) })
            console.log('before1', this.state.workspaceMembers)
            this.updateBoardMembers(members, message, name, this.state.workspaceMembers)
        }
    }

    isBoardAdmin = () => {
   
        let members = this.state.boardMembers;
        let Index = members.findIndex(member => member._id === this.authUser._id);
        if (Index == -1) {
            return false;
        }
        if (members[Index].is_bAdmin) {
            return true;
        } else {
            return false;
        }
    }

    updateBoardAdminStatus = (people) => {

        if(!this.state.isWorkspaceAdmin){
            this.props.fetchError("You don't have permission to add or remove board admins") 
            return;
        }
        let members = this.state.boardMembers;
        if (members.length > 1) {
            let authUser = JSON.parse(localStorage.getItem('authUser'));
            if (authUser._id === people._id) {
                this.props.fetchError('You can not remove your Admin Permissions!');
                return;
            } else {
                let removeIndex = members.findIndex(member => member._id === people._id);
                people.name = people.firstname;
                let message;
                if (members[removeIndex].is_bAdmin) {
                    members[removeIndex].is_bAdmin = false;
                    message = `${people.name} is a member now!`;
                } else {
                    members[removeIndex].is_bAdmin = true;
                    message = `${people.name} is an admin now!`;
                }
                // return;
                // const { removeItem } = members.splice(removeIndex, 1);

                let name = people.name !== '' ? people.name : people.email;
                this.setState({ boardMembers: members })


                console.log('before', this.state.workspaceMembers)
                let workspacemembers = this.state.workspaceMembers
                workspacemembers.pop(people);
                this.setState({ workspaceMembers: this.getFilteredUserLists(workspacemembers) })


                this.updateBoardMembers(members, message, name, this.state.boardMembers)
            }

        } else {
            this.props.fetchError('Can not remove!')
        }
    }

    updateBoardMembers = (members, message, peopleName, newMembersToAdd, removeId) => {
        console.log('members1', members);

        let workspaceID = this.props.boardData.workspaceID
        axios.post('/workspace/updateBoardMembers', {
            members: members,
            workspaceBoardId: this.props.boardData.boardID,
            workspaceId: workspaceID,
            message: message,
            peopleName: peopleName,
            newMembersToAdd: newMembersToAdd,
            removeId:removeId?removeId:''
        }).then(({ data }) => {
            this.props.updateWorkspaceBoard({
                workspaceBoard: data.workspaceBoard,
                workspaceID: workspaceID
            })
        }).catch(err => {
            this.props.fetchError(err)
        })
    }

    inviteByEmailToBoard = () => {
        let email = this.state.inviteInputEmail;
        if (email !== '') {
            let match = validateEmail(email);
            if (match) {
                this.props.closeModal(false);
                axios.post('workspace/workspaceboardMemberInviteByEmail', {
                    workspaceId: this.props.boardData.workspaceID,
                    boardId: this.props.boardData.boardID,
                    email: email,
                    color: getRandomColor(),
                    timezoneOffset: new Date().getTimezoneOffset()
                }).then(({ data }) => {
                    this.setState({ openEdit: false })
                    console.log("data:", data)
                }).catch(err => {
                    this.props.fetchError(err)
                })
            }
        }
    }

    removePeople = (people) => {

        if(this.isBoardAdmin()){
            if (people.is_bAdmin) {
                this.props.fetchError("You don't have permission to remove workspace Admins.");
                return;
            }
        }
        
        let members = this.state.boardMembers;
        if (members.length > 1) {
            let authUser = JSON.parse(localStorage.getItem('authUser'));
            if (authUser._id === people._id) {
                this.props.fetchError('You can not remove your permission!')
            } else {
                let removeIndex = members.findIndex(member => member._id === people._id);
                const { removeItem, } = members.splice(removeIndex, 1);
                people.name = people.firstname;
                let message = `${people.name} unsubscribed from board!`;
                let name = people.name !== '' ? people.name : people.email;
                this.setState({ boardMembers: members })


                console.log('before', this.state.workspaceMembers)
                let workspacemembers = this.state.workspaceMembers
                workspacemembers.pop(people);
                this.setState({ workspaceMembers: this.getFilteredUserLists(workspacemembers) })


                this.updateBoardMembers(members, message, name, this.state.boardMembers, people._id)
            }

        } else {
            this.props.fetchError('Can not remove!')
        }
    }

    searchMember = (event) => {
        let peoples = this.state.allPeople;
        if (this.state.searchText !== '') {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                let users = peoples.filter(user => {
                  
                    let username = user.email;
                    let search = username.toLowerCase().search(this.state.searchText.toLowerCase());
                    if (search !== -1) {
                        return true;
                    } else {
                        return false
                    }
                });
                this.setState({ people: users })
            }, 300);
        } else {
            this.setState({ people: peoples })
        }
    }

    checkIfAlreadyWmember = (memberID) => {

        const Obj = this.state.boardMembers.find(boardMembers => boardMembers._id == memberID)
        if (Obj) {
            return false
        } else {
            return true;
        }
    }


    render() {
      
        return (
            <div>
                <Modal isOpen={this.props.modalOpen} ref={this.modalContainer} className='right modal-dialog-centered modal-lg'>
                    <ModalHeader toggle={() => this.props.closeModal(false)}>
                        Board Members
                    </ModalHeader>
                    <ModalBody>
                        <div className='w-100 p-3' style={{ minHeight: '300px' }}>
                            <div className='w-100' ref={this.dropdownContainer}>
                                {!this.state.openEdit ? (
                                    <div className='w-100'>
                                        {(this.state.workspaceAdmins.includes(this.authUser._id) || this.isBoardAdmin() || this.authUser._id == this.state.workspaceOwner) && <input className="form-control w-100 mb-3"
                                            placeholder="Enter name or email"
                                            autoComplete="off"
                                            onClick={() => this.setState({ showPeople: true })}
                                            value={this.state.searchText}
                                            onChange={(event) => this.setState({ searchText: event.target.value })}
                                            onKeyUp={() => this.searchMember()}
                                        />}
                                        {this.state.showPeople ? (
                                            <div className='w-100 p-2' style={{ display: !this.state.showPeople ? 'none' : '', border: '1px solid #333333', borderTopWidth: '0', borderRadius: '4px', height: '400px', overflow: 'auto' }}>
                                                {/* <div className='w-100 text-center pt-1'>--------------- People ---------------</div> */}
                                                <div className='w-100 dropdown-label-suggest'>
                                                    {this.state.people
                                                        .filter(people => this.checkIfAlreadyWmember(people._id))
                                                        .map((people, index) => (
                                                            <div className="pt-1 pb-1" key={index}>
                                                                <div className="dropdown-label-item" onClick={() => this.addPeople(people)}>
                                                                    {`${people.name && people.name !== '' ? people.name : people.email}`}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    <button type='button' className="btn btn-add w-100 d-flex align-items-center text-left"
                                                        onClick={() => this.setState({ openEdit: true })} >
                                                        <i className='fa fa-envelope-o' style={{ top: '1px' }}></i> &nbsp; Invite a new member by email
                                            </button>
                                                </div>
                                            </div>
                                        ) : ''}
                                    </div>
                                ) : (
                                        <div>
                                            <input className="form-control w-100"
                                                placeholder="Enter email address"
                                                value={this.state.inviteInputEmail}
                                                onChange={(event) => this.setState({ inviteInputEmail: event.target.value })}
                                            />
                                            <div className='w-100 p-2' style={{ display: !this.state.showPeople ? 'none' : '', border: '1px solid #333333', borderTopWidth: '0', borderRadius: '4px' }}>

                                                <div className='w-100 dropdown-label-suggest'>
                                                    <div className='w-100 p-1'>Type in an email address to invite</div>
                                                    <button type='button' className="btn btn-add w-100 d-flex align-items-center text-left"
                                                        onClick={() => this.inviteByEmailToBoard()} >
                                                        <i className='fa fa-envelope-o' style={{ top: '1px' }}></i> &nbsp; Invite
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            {!this.state.showPeople ? (
                                <div className='w-100'>
                                    <div className='w-100 p-1'>
                                        {this.state.boardMembers.map((member, index) => (
                                            <div className='d-flex w-100 py-2 border-bottom align-items-center' key={index} style={{ justifyContent: 'space-between' }}>
                                                {member.name && member.name !== '' ? (
                                                    <img src={createImageFromInitials(28, member.name, member.color ? member.color : '#13a3e3')} style={{ borderRadius: '50%' , 'width' : '25px' , 'height' : '25px' }} />
                                                ) : (
                                                        <img src="/images/users/dark-user.png" style={{ borderRadius: '50%', width: '28px' }} />
                                                    )}

                                                <div className='w-100 pl-2 pr-2 board-member-detail'>{member.name !== '' ? member.name : member.email} - {member.email}</div>

                                                {member._id == this.state.workspaceOwner && <div
                                                    className="data-title"
                                                    data-title={`Owner`}
                                                >
                                                    <i className='fa fa-user-secret fa-lg'></i>
                                                </div>
                                                }

                                                {(member._id != this.state.workspaceOwner) && this.state.workspaceAdmins.includes(member._id) &&
                                                    <div
                                                        data-title='Workspace/Board Admin'
                                                        className='text-success data-title mr-3 cursor-pointer'
                                                    >
                                                        <i className='fa fa-universal-access fa-lg'></i>
                                                    </div>
                                                }

                                                {(member._id != this.state.workspaceOwner && !this.state.workspaceAdmins.includes(member._id)) && (this.state.isWorkspaceAdmin || this.isBoardAdmin()) &&
                                                    <div
                                                        data-title={(this.state.workspaceAdmins.includes(member._id)) ? 'Remove Board Admin' : 'Make Board Admin'}
                                                        className={member.is_bAdmin ? 'text-success data-title mr-3 cursor-pointer' : 'text-black-50 data-title mr-3 cursor-pointer'}
                                                        onClick={() => this.updateBoardAdminStatus(member)}
                                                    >
                                                        <i className='fa fa-address-book-o fa-lg'></i>
                                                    </div>
                                                }

                                                {(member._id != this.state.workspaceOwner) && (this.state.workspaceAdmins.includes(this.authUser._id) || this.isBoardAdmin() || this.authUser._id == this.state.workspaceOwner) && <div
                                                    className="border-member-close data-title"
                                                    data-title={`Remove ${member.name ? member.name : member.email} from this board`}
                                                    onClick={() => this.removePeople(member)}
                                                >
                                                    <i className='fa fa-close'></i>
                                                </div>

                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : ''}

                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { showMessage, fetchError, updateWorkspaceBoard }

export default connect(mapStateToProps, mapDispatchToProps)(BoardMembersPopup);