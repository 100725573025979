import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import axios from '../../../../../util/Api';
import { changeWorkspaceBoard, reloadWorkspace, showMessage, fetchStart, intialize } from '../../../../../actions';
import { Link, useLocation, BrowserRouter as Router } from "react-router-dom";


class WorkspaceBoardAction extends React.Component {
    constructor(props) {
        super(props);
        this._isMounted = false;
        this.state = {
            isRemoveModal: false,
            moveWorkspaceOpen: false,
            workspaces: null,
            subworkspaces: null,
            open: false,
            show: false,
            isWorkspaceOwner: false,
            isWorkspaceAdmin: false
        }

        this.removeModalContainer = React.createRef();
        this.dropdownContainer = React.createRef();
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false });
        }
    }

    componentDidMount() {

        window.addEventListener('click', this.onClickOutsideHandler);
        this._isMounted = true;
        let workspaceBoard = this.props.boardData.workspaceBoard;
        let members = workspaceBoard.members ? JSON.parse(workspaceBoard.members) : [];
        let authUser = JSON.parse(localStorage.getItem('authUser'));
        let findMember = members.find(member => member._id === authUser._id)
        let boardType = workspaceBoard.boardType;
        if (boardType === 'sharable') {
            if (findMember !== undefined) {
                this.setState({ show: true })
            }
        } else if (boardType === 'private') {
            if (findMember !== undefined) {
                this.setState({ show: true })
            }
        } else {
            this.setState({ show: true })
        }

        if (this.authUser._id == this.props.workspace.CreatedBy) {
            this.setState({ isWorkspaceOwner: true })
            this.setState({ isWorkspaceAdmin: true })
        }
        if (this.props.workspace.admins.includes(this.authUser._id)) {
            this.setState({ isWorkspaceAdmin: true })
        }

        //  if (this._isMounted = true) {
        // axios.get('/workspace/getAllWorkspaceAndSubworkspaces').then(({ data }) => {
        //     this.setState({
        //         workspaces: data.workspaces,
        //         subworkspaces: data.subworkspaces
        //     })
        // }).catch(err => {
        //     console.error(err)
        // })
        // }
        let boardId = window.location.pathname?.split('/')[2]
        if (boardId)
            this.switchBoard(boardId)
    }

    componentDidUpdate(prevProps) {
        if (this._isMounted) {
            if (prevProps.boardData.loadWorkspace !== this.props.boardData.loadWorkspace) {
                // setTimeout(() => {
                //     axios.get('/workspace/getAllWorkspaceAndSubworkspaces').then(({ data }) => {
                //         console.log("!!!response data===>", data)
                //         this.setState({
                //             workspaces: data.workspaces,
                //             subworkspaces: data.subworkspaces
                //         })
                //     }).catch(err => {
                //         console.error(err)
                //     })
                // }, 200);

            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    btnAction = () => {
        this.setState({ open: true });
        axios.post('/workspace/getAllWorkspaceAndSubworkspaces', {
            workspaceID: this.props.workspaceID,
            isWorkspaceOwner: this.state.isWorkspaceOwner
        }).then(({ data }) => {
            this.setState({
                workspaces: data.workspaces,
                subworkspaces: data.subworkspaces
            })
            console.log('data.subworkspaces', data.subworkspaces)
        }).catch(err => {
            console.error(err)
        })
        console.log('this.porps', this.props)
    }
    switchBoard = (id) => {
        console.log("board id==>ch101", this.props)
        
        this.props.changeWorkspaceBoard({
            id: id,
            workspaceID: this.props.workspaceID,
            workspace: this.props.workspace,
            isNavigation: true
        })
        
    }

    removeCategory = () => {
        // this.setState({ open: false });
        // console.log("test")
        if (this.props.actionType === 'Subworkspace') {
            let subworkspaceID = this.props.subWorkspaceID;
            console.log("subworkspaceID===>", subworkspaceID);
            this.setState({ isRemoveModal: false });
            this.props.fetchStart();

            axios.post('/workspace/removeBoardForSub', {
                subworkspaceID: subworkspaceID,
                boardID: this.props.board._id
            }).then(({ data }) => {
                if (data.success === 200) {
                    this.props.showMessage(`${data.boardName} deleted successfully!`);
                    this.props.intialize()
                }
            }).catch(err => {
                console.log("err==>", err)
            })
        } else if (this.props.actionType === 'Workspace') {
            let workspaceID = this.props.workspaceID;

            console.log("workspace ID===>", workspaceID);
            this.setState({ isRemoveModal: false });
            this.props.fetchStart();

            axios.post('/workspace/removeBoardForWorkspace', {
                workspaceID: workspaceID,
                boardID: this.props.board._id
            }).then(({ data }) => {
                if (data.success === 200) {
                    console.log("data", data)
                    this.props.showMessage(`${data.boardName} deleted successfully!`);

                    this.props.intialize()
                }
            }).catch(err => {
                console.log("err==>", err)
            })
        }
    }

    moveToSubWorkspace = (subworkspace_id) => {
        this.setState({ open: false });
        axios.post('/workspace/moveWorkspaceboardToSub', {
            boardID: this.props.board._id,
            sourceSubId: this.props.subWorkspaceID,
            destinationId: subworkspace_id,
            workspaceID: this.props.workspaceID
        }).then(({ data }) => {
            this.props.showMessage(`${data.workspaceBoardTitle} moved successfully!`);
            this.props.reloadWorkspace({
                reload: !this.props.boardData.loadWorkspace
            })
        }).catch(error => {
            console.error(error)
        })
    }

    moveToWorkspace = (workspace_id) => {
        this.setState({ open: false });
        axios.post('/workspace/moveBoardToWorkspace', {
            boardID: this.props.board._id,
            destinationWorkspaceId: workspace_id,
            currentWorkspaceId: this.props.workspaceID,
            currentSubworkspaceId: this.props.subWorkspaceID
        }).then(({ data }) => {
            this.props.showMessage(`${data.workspaceBoardTitle} moved successfully!`);
            this.props.reloadWorkspace({
                reload: !this.props.boardData.loadWorkspace
            })
        }).catch(err => {
            console.error(err)
        })
    }

    render() {
        return (
            <>{this.state.show ? (
                <>
                    <div className='d-flex just-content-sb'>
                    <Link class="category-select active" style={{ 'width' : '192px' , marginRight : '10px' }}
                    to ={{
                        pathname: '/board/'+this.props.board._id, 
                        state: { 
                            workspaceID: this.props.workspaceID,
                             workspace: this.props.workspace
                        }
                       }}
                    >


   
                        <button className="nav-link p-1 navbar-text-ellipsis">
                            <i className="fa fa-square" style={{ 'position': 'relative', 'top': '4px' , 'margin-right' : '5px' }}></i> &nbsp;{ReactHtmlParser(this.props.board.title)} </button>
                            </Link>
                        <div className="dropdown" ref={this.dropdownContainer} >
                            {(this.state.isWorkspaceOwner || this.state.isWorkspaceAdmin) && <button className="btn dropdown-toggle btn-nav-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true" style={{ display: 'flex'  }} onClick={() => this.btnAction()}>
                                <i className="bx bx-dots-vertical-rounded dot-icons open" ></i>
                            </button>
                            }
                            {this.state.open ? (
                                <div className={`dropdown-menu multi-level animate slidein board-dropdown ${this.state.open ? 'show' : ''}`} role="menu" aria-labelledby="dropdownMenu1">
                                    {this.state.isWorkspaceOwner && <li className="dropdown-item" style={{ position: 'relative', minWidth: '204px' }}
                                        onMouseLeave={() => { this.setState({ moveWorkspaceOpen: false }) }}>
                                        <button className="btn-sub-nav sub-menu-nav"
                                            onMouseEnter={() => { this.setState({ moveWorkspaceOpen: true }) }}
                                        >
                                            <i className="fal fa-folder" style={{ fontSize: '14px' , 'color' : '#000' }}></i>&nbsp;&nbsp; Move to Workspace
                                    <i className="fal fa-chevron-right" aria-hidden="true" style={{ fontSize: '13px', float: 'right', marginTop: '12px' ,  marginLeft : '10px'  }}></i>
                                        </button>
                                        <ul className={`dropdown-menu multi-level subwork-dropdown ${this.state.moveWorkspaceOpen ? 'show' : ''}`} >
                                            {this.state.workspaces !== null ?
                                                this.state.workspaces
                                                    .filter(workspace => workspace.CreatedBy == this.authUser._id)
                                                    .map((workspace, index) => (
                                                        workspace._id.toString() !== this.props.workspaceID.toString() ?
                                                            (
                                                                <li className='dropdown-item' key={index}>
                                                                    <button className='btn-sub-nav' onClick={() => this.moveToWorkspace(workspace._id)}>
                                                                        <i className="fal fa-chevron-right" style={{ marginRight : '8px' }}></i> {workspace.title}
                                                                    </button>
                                                                </li>
                                                            ) : ''
                                                    )) : ''
                                            }
                                        </ul>
                                    </li>
                                    }
                                    <li className="dropdown-item" style={{ position: 'relative', minWidth: '204px' }}
                                        onMouseLeave={() => { this.setState({ moveSubworkspaceOpen: false }) }}>
                                        <button className="btn-sub-nav sub-menu-nav"
                                            onMouseEnter={() => { this.setState({ moveSubworkspaceOpen: true }) }}
                                        >
                                            <i className="fal fa-folder" style={{ fontSize: '14px' , 'color' : '000' }}></i>&nbsp;&nbsp; Move to Subworkspace
                                    <i className="fal fa-chevron-right" aria-hidden="true" style={{ fontSize: '13px', float: 'right', marginTop: '12px' , marginLeft : '12px' }}></i>
                                        </button>
                                        <ul className={`dropdown-menu multi-level subwork-dropdown ${this.state.moveSubworkspaceOpen ? 'show' : ''}`} >
                                            {this.state.subworkspaces !== null ?
                                                this.state.subworkspaces

                                                    .map((subworkspace, index) => (
                                                        this.props.subWorkspaceID === null ?
                                                            (
                                                                <li className='dropdown-item' key={index}>
                                                                    <button className='btn-sub-nav' onClick={() => this.moveToSubWorkspace(subworkspace._id)}>
                                                                        <i className="fal fa-chevron-right" style={{ marginRight : '13px' }}></i> {subworkspace.title}
                                                                    </button>
                                                                </li>
                                                            ) : (
                                                                subworkspace._id.toString() !== this.props.subWorkspaceID.toString() ?
                                                                    (
                                                                        <li className='dropdown-item' key={index}>
                                                                            <button className='btn-sub-nav' onClick={() => this.moveToSubWorkspace(subworkspace._id)}>
                                                                                <i className="fal fa-chevron-right"></i> {subworkspace.title}
                                                                            </button>
                                                                        </li>
                                                                    ) : ''
                                                            )
                                                    )) : ''
                                            }
                                        </ul>
                                    </li>
                                    <li className="dropdown-item">
                                        <button className="btn-sub-nav"
                                            onClick={() => { this.setState({ isRemoveModal: true, open: false }) }}
                                        >
                                            <i className="fal fa-trash" style={{ fontSize: '13px' , 'color' : '000'  }}></i>&nbsp;&nbsp;Remove</button>
                                    </li>
                                </div>
                            ) : ''}

                        </div>

                    
                    </div>                     
                    

                    <Modal isOpen={this.state.isRemoveModal} ref={this.removeModalContainer}>
                        <ModalHeader toggle={() => this.setState({ isRemoveModal: false })}>
                            Delete {this.props.board.title}?
                    </ModalHeader>
                        <ModalFooter>
                            <button className='btn btn-light' onClick={() => { this.setState({ isRemoveModal: false }) }}>Cancel</button>{' '}
                            <button className='btn btn-primary'
                                onClick={() => this.removeCategory()}
                            >Delete</button>
                        </ModalFooter>
                    </Modal>
                </>
            ) : ''}

            </>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeWorkspaceBoard, reloadWorkspace, showMessage, fetchStart, intialize }

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceBoardAction);