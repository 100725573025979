import React from 'react';
import { DateRange } from 'react-date-range';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { changeBoardItem, fetchError } from '../../../../actions';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
//import '../style/react-datepicker.css';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../util/permission';

const Month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class TimelineItem extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
        };
        this.dropdownContainer = React.createRef();
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) this.setState({ open: false });
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
        // if (this.props.boardData.boardID) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem?.cellItems || [];
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentDidUpdate(prevProps, prevStates) {
        // if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem.cellItems;
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    getDateTimeFormat = (date) => {
        let year = date.getFullYear();
        let month = Month[date.getMonth()];
        let day = ("0" + date.getDate()).slice(-2);

        let formatDate = month + " " + day;

        return formatDate;
    }

    showTimelineRangeDiffDays = (startDate, endDate) => {
        const diffTime = Math.abs(endDate - startDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;
        return diffDays + " days"
    }

    render() {
        const { groupInd, rowInd, colInd, body, _id } = this.props;

        let inputTitle = "";
        let value = "";
        let selectionRange = {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        };
        if (body !== "") {
            const currentDateRangeArr = body.split("-");

            const startDate = new Date(currentDateRangeArr[0]);
            const endDate = new Date(currentDateRangeArr[1]);
            value = this.getDateTimeFormat(startDate) + " - " + this.getDateTimeFormat(endDate);
            inputTitle = this.showTimelineRangeDiffDays(startDate, endDate);
            // console.log(" input title ===>", inputTitle);
            selectionRange = {
                startDate: startDate,
                endDate: endDate,
                key: 'selection',
            }
        }
        return (
            <div className="p-1">
                <div className="dropdown" ref={this.dropdownContainer}>
                    <div>
                        <input type="text"
                            className="timeline-input workspace-board"
                            data-tip={`${inputTitle}`}
                            onChange={(event) => { console.log("timeline input event==>", event) }}
                            onClick={() => {
                                // console.log('llp5',getMyGroupEditPermission(this.props.search.boarddata[this.props.groupInd].members));
         
                                // if(this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.search.boarddata[this.props.groupInd].members) && getMyColumnEditPermission(this.props.search.boarddata[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                                //     this.setState({ open: !this.state.open })
                                // } else {
                                //     this.props.fetchError("You don't have permission. You can only view.")
                                // }
                            } }
                            value={value}
                        />
                        <ReactTooltip />
                    </div>
                    {this.state.open ? (
                        <div className={`dropdown-menu multi-level animate slideIn dropdown-timeline ${this.state.open ? "show" : ""}`} role="menu" aria-labelledby="dropdownMenu">
                            <DateRange
                                ranges={[selectionRange]}
                                onChange={(range) => {
                                    this.setState({ open: false })
                                    let rangeStartDate = range.selection.startDate;
                                    let rangeEndDate = range.selection.endDate;

                                    let changedStartDate = rangeStartDate.getFullYear() + "/" + ("0" + (rangeStartDate.getMonth() + 1)).slice(-2) + "/" + ("0" + rangeStartDate.getDate()).slice(-2);
                                    let changedEndDate = rangeEndDate.getFullYear() + "/" + ("0" + (rangeEndDate.getMonth() + 1)).slice(-2) + "/" + ("0" + rangeEndDate.getDate()).slice(-2);

                                    this.props.changeBoardItem({
                                        groupInd: groupInd,
                                        rowInd: rowInd,
                                        colInd: colInd,
                                        body: changedStartDate + "-" + changedEndDate,
                                        cellItemID: _id,
                                        boardId: this.props.boardData.boardID
                                    })
                                }}
                                moveRangeOnFirstSelection={false}
                            />
                        </div>
                    ) : ''}

                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = { changeBoardItem, fetchError };

export default connect(mapStateToProps, mapDispatchToProps)(TimelineItem);