import React, { Component } from 'react'

const SettingsToolbar = (params) => {
  return (
    <>
      <div className="row dash-blue fixed-top">
       
        <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
          <div>
            <div className="inner-section">
              <h3>Settings</h3>
              <div style={{ marginTop: '-10px' }}>
                <p className="mt-1"> </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
          <table className="align-items-center table-newdashboard-section-scheduler-one">
            <tbody><tr>
              <td rowSpan={2} style={{ width: '50px' }}>
                <ul className="nav navbar-nav bookmark-icons">
                  <li className="nav-item d-none d-lg-block nav-search" data-placement="top">
                    <a className="nav-link nav-link-search cursor-pointer" data-toggle="dropdown" aria-expanded="false" title="Search">
                      <img src="./images/icon/dashboard-icons/scheduler-icons/search copy.png" class="toolbar-icons" />
                    </a>
                    <div className="dropdown-menu text-color w-xl animated fadeInUp" style={{ right: 'auto', width: '240px' }}>
                      <form className="navbar-form form-inline navbar-item m-a-0 p-x v-m ng-pristine ng-valid" role="search">
                        <div className="form-group l-h m-a-0">
                          <div className="input-group">
                            <input type="text" className="form-control" placeholder="Search"

                              id="search-box" />
                            <span className="input-group-btn">
                              <button type="submit" className="btn blue-A200 b-a no-shadow" id="tbSearch">
                                <i className="bx bx-search"></i>
                              </button>
                            </span>
                          </div>
                        </div>
                      </form>
                    </div>
                  </li>
                </ul></td>
              <td rowSpan={2} style={{ width: '50px' }}><a href="#" data-action="expand" ><img src="./images/icon/dashboard-icons/scheduler-icons/refresh copy.png" class="toolbar-icons" style={{ cursor: 'pointer' }} /></a></td>
              <td rowSpan={2} style={{ width: '50px' }}><a href="#" data-action="expand" >
                <img
                  src='/images/icon/dashboard-icons/scheduler-icons/zoomin copy.png'
                  class="toolbar-icons"  style={{ cursor: 'pointer' }} /></a></td>

              <td rowSpan={2} style={{ width: '50px' }}><a ><img src="/images/icon/dashboard-icons/module-icons/white icons-38.png" class="toolbar-icons" style={{ cursor: 'pointer' }} /></a></td>


              <td rowSpan={2} style={{ width: '50px' }}><a href="#" data-toggle="tooltip" data-target="Filter"  ><img src="./images/icon/dashboard-icons/module-icons/share-white.png" class="toolbar-icons" style={{ cursor: 'pointer' }} /></a></td>

              <td>

              </td>


            </tr>
              <tr style={{ fontWeight: 600 }}>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            </tbody></table>

        </div>
      </div>


    </>

  );
}

export default SettingsToolbar;