import {
    FETCH_WORKSPACE_DATA_REQUEST, FETCH_WORKSPACE_DATA_SUCCESS, ADD_FETCH_WORKSPACE_DATA_SUCCESS, UPDATE_FETCH_WORKSPACE_DATA_SUCCESS, WORKSPACE_DATA_ERROR, DELETE_WORKSPACE_DATA_SUCCESS,
    FETCH_SUB_WORKSPACE_DATA_REQUEST, FETCH_SUB_WORKSPACE_DATA_SUCCESS, ADD_FETCH_SUB_WORKSPACE_DATA_SUCCESS, UPDATE_FETCH_SUB_WORKSPACE_DATA_SUCCESS, DELETE_SUB_WORKSPACE_DATA_SUCCESS, SUB_WORKSPACE_DATA_ERROR,
    FETCH_BOARD_DATA_REQUEST, FETCH_BOARD_DATA_SUCCESS, ADD_FETCH_BOARD_DATA_SUCCESS, UPDATE_FETCH_BOARD_DATA_SUCCESS, BOARD_DATA_ERROR,
    FETCH_SUB_BOARD_DATA_REQUEST, FETCH_SUB_BOARD_DATA_SUCCESS, ADD_FETCH_SUB_BOARD_DATA_SUCCESS, UPDATE_FETCH_SUB_BOARD_DATA_SUCCESS,
    FETCH_GROUP_DATA_REQUEST, FETCH_GROUP_DATA_SUCCESS, ADD_FETCH_GROUP_DATA_SUCCESS, UPDATE_FETCH_GROUP_DATA_SUCCESS,
    FETCH_GROUP_ITEM_DATA_REQUEST, FETCH_GROUP_ITEM_DATA_SUCCESS, ADD_FETCH_GROUP_ITEM_DATA_SUCCESS, UPDATE_FETCH_GROUP_ITEM_DATA_SUCCESS,
    FETCH_SUB_WORKSPACE_L2_DATA_REQUEST, FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS, ADD_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS, UPDATE_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS, DELETE_SUB_WORKSPACE_L2_DATA_SUCCESS, SUB_WORKSPACE_L2_DATA_ERROR
} from "../constants/ActionTypes";
import config from '../components/services/config.json';
import http from '../components/services/http-service';
import workspaceDataService from './../data-services/workspace-data-services';
import axios from '../util/Api';
import { useSelector, useDispatch } from "react-redux";
import { initialWorkspaceBoardGroupData, cellStatusInitalData } from '../constants/Dump';
import { initialGroupCellItemBody } from '../util/index';
import store from '../store'

// const workSpaceData=[{_id:1,title:'Automotive',
//         description:'tyres'},

//           {_id:2,title:'Trading',description:'M40'}];

import { workSpaceData } from '../actions/discover-data';
import { DATERANGE_DISABLED_TARGET } from "rsuite/lib/DateRangePicker";

let subWorkSpaceData = [{ _id: 2, title: 'sub Workspace 1', description: 'sub Workspace 1' }, { _id: 2, title: 'sub Workspace 2', description: 'sub Workspace 2' }];

let authUser = JSON.parse(localStorage.getItem('authUser'));

export const getWorkspaces = (data) => {



    // return async (dispatch) => {
    //     dispatch({ type: FETCH_WORKSPACE_DATA_REQUEST });
    //     dispatch({
    //         type: FETCH_WORKSPACE_DATA_SUCCESS,
    //         payload: workSpaceData
    //     });
    // }
    return async (dispatch) => {
        dispatch({ type: FETCH_WORKSPACE_DATA_REQUEST });
        await http.get(config.apiEndPoint + workspaceDataService.getAllWorkSpaces)
            .then(response => {
                dispatch({
                    type: FETCH_WORKSPACE_DATA_SUCCESS,
                    payload: response.data.workspaces
                });

              
            })
            .catch(error => {
                dispatch({
                    type: WORKSPACE_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });
    }
};

export const saveWorkSpace = (data) => {
    return async (dispatch) => {

        // if (data._id === 0) {
        //     data._id = Math.random().toString(16).slice(-8);
        //     dispatch({
        //         type: ADD_FETCH_WORKSPACE_DATA_SUCCESS,
        //         payload: data
        //     });
        // }
        // else {
        //     dispatch({
        //         type: UPDATE_FETCH_WORKSPACE_DATA_SUCCESS,
        //         payload: data
        //     });
        // }
        if (data.actionType === 0) {
            await http.post(config.apiEndPoint + workspaceDataService.createWorkspace, data)
                .then(response => {
                    dispatch({
                        type: ADD_FETCH_WORKSPACE_DATA_SUCCESS,
                        payload: response.data.workspace
                    });
                })
                .catch(error => {
                    console.log(error);
                    dispatch({
                        type: WORKSPACE_DATA_ERROR,
                        payload: 'error in getting data'
                    });
                });
        }
        else if (data.actionType === 1){

            const renameData = {
                id: data._id,
                title: data.title
            }
            await http.post(config.apiEndPoint + workspaceDataService.renameWorkspace, renameData)
                .then(response => {
                    dispatch({
                        type: UPDATE_FETCH_WORKSPACE_DATA_SUCCESS,
                        payload: data
                    });
                })
                .catch(error => {
                    console.log(error);
                    dispatch({
                        type: WORKSPACE_DATA_ERROR,
                        payload: 'error in getting data'
                    });
                });

                
        }
        else if (data.actionType === 2) {

            const deleteData = {
                workspace_id: data._id,
               
            }
            console.log('N-->', deleteData);
            await http.post(config.apiEndPoint + workspaceDataService.deleteWorkSpace, deleteData)
                .then(response => {
                    dispatch({
                        type: DELETE_WORKSPACE_DATA_SUCCESS,
                        payload: data
                    });
                })
                .catch(error => {
                    console.log(error);
                    dispatch({
                        type: WORKSPACE_DATA_ERROR,
                        payload: 'error in getting data'
                    });
                });


        }
    }
};

const findObject = (obj, key, value) => obj.find(v => v[key] === value)
export const getSubWorkspaces = (data) => {

    return async (dispatch, getState) => {

        const discoverWorkspace = findObject(getState().discover.discoverWorkspace.data, '_id', data._id)
        dispatch({
            type: FETCH_SUB_WORKSPACE_DATA_SUCCESS,
            payload: discoverWorkspace.subworkspaces
        });
    }
    // return async (dispatch)  => {
    //    dispatch({ type: FETCH_WORKSPACE_DATA_REQUEST });
    //    await http.get(config.apiEndPoint + workspaceDataService.getAllWorkSpacesNested)
    //    .then(response =>{

    //        dispatch({
    //            type:FETCH_WORKSPACE_DATA_SUCCESS,
    //            payload:response.data.response
    //        });
    //    })
    //    .catch(error => {
    //        dispatch({
    //            type:WORKSPACE_DATA_ERROR,
    //            payload:'error in getting data'
    //        });
    //    });
    // }
};

export const createSubWorkSpace = (data) => {
    return async (dispatch) => { //
        await http.post(config.apiEndPoint + workspaceDataService.createSubworkspace, data)
            .then(response => {
                console.log(response);
                dispatch({
                    type: ADD_FETCH_SUB_WORKSPACE_DATA_SUCCESS,
                    payload: response.data.subworkspace
                });
                dispatch({
                    type: 'ADD_SUB_WORKSPACE_IN_WORKSPACE',
                    payload: { workspace_id: data.workspace_id, subworkspace: response.data.subworkspace }
                })
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: SUB_WORKSPACE_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });
    }
};
export const renameSubWorkSpace = (data) => {
    return async (dispatch) => {
        const renameData = {
            id: data._id,
            title: data.title
        }
        await http.post(config.apiEndPoint + workspaceDataService.renameSubWorkspace, renameData)
            .then(response => {
                dispatch({
                    type: UPDATE_FETCH_SUB_WORKSPACE_DATA_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: SUB_WORKSPACE_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });
    }
};

export const removeSubWorkSpace = (data) => {
    return async (dispatch) => {
        const removeData = {
            workspaceID: data.workspace_id,
            subworkspaceID: data._id
        }
        console.log('NNN-->', removeData);
        await http.post(config.apiEndPoint + workspaceDataService.removeSubworkspace, removeData)
            .then(response => {
                dispatch({
                    type: DELETE_SUB_WORKSPACE_DATA_SUCCESS,
                    payload: data
                });
                dispatch({
                    type: 'DELETE_SUB_WORKSPACE_FROM_WORKSPACE',
                    payload: data
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: SUB_WORKSPACE_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });
    }
};


export const renameBoard = (data) => {
    return async (dispatch) => {
        const renameData = {
            boardID: data._id,
            title: data.title
        }
        await http.post(config.apiEndPoint + workspaceDataService.changeBoardTitle, renameData)
            .then(response => {
                dispatch({
                    type: UPDATE_FETCH_BOARD_DATA_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: BOARD_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });
    }
};


export const getBoards = (data) => {

    return async (dispatch, getState) => {
        let discover;

        if (data.discoverType === 1)
            discover = findObject(getState().discover.discoverWorkspace.data, '_id', data._id)
        else if (data.discoverType === 2)
            discover = findObject(getState().discover.discoverSubWorkspace.data, '_id', data._id)
        else if (data.discoverType === 7)
            discover = findObject(getState().discover.discoverSubWorkspaceL2.data, '_id', data._id)
        dispatch({
            type: FETCH_BOARD_DATA_SUCCESS,
            payload: discover.workspaceBoards
        });
    }
    // return async (dispatch)  => {
    //    dispatch({ type: FETCH_WORKSPACE_DATA_REQUEST });
    //    await http.get(config.apiEndPoint + workspaceDataService.getAllWorkSpacesNested)
    //    .then(response =>{

    //        dispatch({
    //            type:FETCH_WORKSPACE_DATA_SUCCESS,
    //            payload:response.data.response
    //        });
    //    })
    //    .catch(error => {
    //        dispatch({
    //            type:WORKSPACE_DATA_ERROR,
    //            payload:'error in getting data'
    //        });
    //    });
    // }
};

export const createBoard = (data) => {


    return async (dispatch) => { //
        let admins = [];
        if (data.CreatedBy != authUser._id) {
            admins.push(data.CreatedBy);
        }
        if (data.parentType === 1) {
           
            const boardData = {
                title: data.title,
                description: data.description,
                workspaceId: data.parentId,
                group: initialWorkspaceBoardGroupData,
                cellStatusItems: cellStatusInitalData,
                wsAdmins: admins.concat(data.admins)
            }
            console.log('NNN-->', data);

            await http.post(config.apiEndPoint + workspaceDataService.createBoardToWorkspace, boardData)
                .then(response => {
                    console.log('NNN-->',response);
                    dispatch({
                        type: ADD_FETCH_BOARD_DATA_SUCCESS,
                        payload: response.data.workspaceBoard
                    });
                    dispatch({
                        type: 'ADD_BOARD_IN_WORKSPACE',
                        payload: { workspace_id: data.parentId, workspaceBoard: response.data.workspaceBoard }
                    })
                })
                .catch(error => {
                    console.log('NNN-->',error);
                    dispatch({
                        type: BOARD_DATA_ERROR,
                        payload: 'error in getting data'
                    });
                });
        }
        else  {

            const boardData = {
                title: data.title,
                description: data.description,
                subworkspace_id: data.parentId,
                group: initialWorkspaceBoardGroupData,
                cellStatusItems: cellStatusInitalData,
                wsAdmins: admins.concat(data.admins)
            }
            await http.post(config.apiEndPoint + workspaceDataService.createBoard, boardData)
                .then(response => {
                    console.log("DD:>",response);
                    dispatch({
                        type: ADD_FETCH_BOARD_DATA_SUCCESS,
                        payload: response.data.workspaceBoard
                    });
                    dispatch({
                        type: 'ADD_BOARD_IN_SUB_WORKSPACE',
                        payload: { subWorkspace_id: data.parentId, workspaceBoard: response.data.workspaceBoard }
                    })
                })
                .catch(error => {
                    console.log(error);
                    dispatch({
                        type: BOARD_DATA_ERROR,
                        payload: 'error in getting data'
                    });
                });
        }
        //     await http.post(config.apiEndPoint + workspaceDataService.createSubworkspace, data)
        //         .then(response => {
        //             console.log(response);
        //             dispatch({
        //                 type: ADD_FETCH_SUB_WORKSPACE_DATA_SUCCESS,
        //                 payload: response.data.subworkspace
        //             });
        //             dispatch({
        //                 type: 'ADD_SUB_WORKSPACE_IN_WORKSPACE',
        //                 payload: { workspace_id: data.workspace_id, subworkspace: response.data.subworkspace }
        //             })
        //         })
        //         .catch(error => {
        //             console.log(error);
        //             dispatch({
        //                 type: WORKSPACE_DATA_ERROR,
        //                 payload: 'error in getting data'
        //             });
        //         });
    }
};
export const getSubBoards = (data) => {

    return async (dispatch, getState) => {
        let discover;


        discover = findObject(getState().discover.discoverBoard.data, '_id', data._id)
        dispatch({
            type: FETCH_SUB_BOARD_DATA_SUCCESS,
            payload: discover.subBoards
        });
    }
    // return async (dispatch)  => {
    //    dispatch({ type: FETCH_WORKSPACE_DATA_REQUEST });
    //    await http.get(config.apiEndPoint + workspaceDataService.getAllWorkSpacesNested)
    //    .then(response =>{

    //        dispatch({
    //            type:FETCH_WORKSPACE_DATA_SUCCESS,
    //            payload:response.data.response
    //        });
    //    })
    //    .catch(error => {
    //        dispatch({
    //            type:WORKSPACE_DATA_ERROR,
    //            payload:'error in getting data'
    //        });
    //    });
    // }
};
export const getGroups = (data) => {

    return async (dispatch, getState) => {
        if (data.discoverType === 3) {
            http.get(config.apiEndPoint + workspaceDataService.getWorkspaceBoardById + `/${data._id}`)
                .then(response => {
                    dispatch({
                        type: FETCH_GROUP_DATA_SUCCESS,
                        payload: response.data.workspaceBoard.group
                    });

                })
                .catch(error => {

                });
        }
        //let discover;

        // if (data.discoverType === 3)
        //     discover = findObject(getState().discover.discoverBoard.data, '_id', data._id)
        // else if (data.discoverType === 4)
        //     discover = findObject(getState().discover.discoverSubBoard.data, '_id', data._id)

        // dispatch({
        //     type: FETCH_GROUP_DATA_SUCCESS,
        //     payload: discover.groups
        // });
    }
    // return async (dispatch)  => {
    //    dispatch({ type: FETCH_WORKSPACE_DATA_REQUEST });
    //    await http.get(config.apiEndPoint + workspaceDataService.getAllWorkSpacesNested)
    //    .then(response =>{

    //        dispatch({
    //            type:FETCH_WORKSPACE_DATA_SUCCESS,
    //            payload:response.data.response
    //        });
    //    })
    //    .catch(error => {
    //        dispatch({
    //            type:WORKSPACE_DATA_ERROR,
    //            payload:'error in getting data'
    //        });
    //    });
    // }
};

export const createGroup = (data) => {

    return async (dispatch, getState) => {
        let boardData = getState().discover.discoverGroup.data //// if group  is empty , then take data from group template 
        if (boardData) {

        }
        const firstGroup = boardData[0];
        const newGroupItemHeader = firstGroup.groupItems[0];
        const groupItemBodyCellITems = [];

        for (let i = 0; i < newGroupItemHeader.cellItems.length; i++) {
            let body = initialGroupCellItemBody(newGroupItemHeader.cellItems[i].key);
            let newCellItem = { key: newGroupItemHeader.cellItems[i].key, typeInfo: 'data', body: body };
            groupItemBodyCellITems.push(newCellItem);
        }

        const newGroup = {
            label: data.title,
            groupItems: [newGroupItemHeader, {
                title: "New Item",
                category: 'body',
                cellItems: groupItemBodyCellITems
            }]
        }

        const groupData = {
            boardID: data.parentId,
            group: newGroup
        }
        await http.post(config.apiEndPoint + workspaceDataService.addWorkspaceBoardGroup, groupData)
            .then(response => {
                dispatch({
                    type: ADD_FETCH_GROUP_DATA_SUCCESS,
                    payload: response.data.workspaceBoard.group[0]
                });

            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: WORKSPACE_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });
    };
};

export const renameGroup = (data) => {
    return async (dispatch) => {
        const renameData = {
            groupID: data._id,
            title: data.title
        }
        await http.post(config.apiEndPoint + workspaceDataService.updateGroupTitle, renameData)
            .then(response => {
                dispatch({
                    type: UPDATE_FETCH_GROUP_DATA_SUCCESS,
                    payload: renameData
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: WORKSPACE_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });
    }
};

export const getGroupItems = (data) => {

    return async (dispatch, getState) => {
        let discover;


        discover = findObject(getState().discover.discoverGroup.data, '_id', data._id)

        // discover = findObject(discover.groupItems, 'category', 'body')

        dispatch({
            type: FETCH_GROUP_ITEM_DATA_SUCCESS,
            payload: discover.groupItems
        });
    }
    // return async (dispatch)  => {
    //    dispatch({ type: FETCH_WORKSPACE_DATA_REQUEST });
    //    await http.get(config.apiEndPoint + workspaceDataService.getAllWorkSpacesNested)
    //    .then(response =>{

    //        dispatch({
    //            type:FETCH_WORKSPACE_DATA_SUCCESS,
    //            payload:response.data.response
    //        });
    //    })
    //    .catch(error => {
    //        dispatch({
    //            type:WORKSPACE_DATA_ERROR,
    //            payload:'error in getting data'
    //        });
    //    });
    // }
};
export const createGroupItem = (data) => {
    console.log(data);
    return async (dispatch, getState) => {

        let discoverGroupItem = getState().discover.discoverGroupItem.data; // if group item is empty , then take data from item template 

        const currentRowHeaderData = discoverGroupItem[0].cellItems;
        const newCellItems = [];
        for (let i = 0; i < currentRowHeaderData.length; i++) {
            let body = initialGroupCellItemBody(currentRowHeaderData[i].key);
            let cellItem = { key: currentRowHeaderData[i].key, typeInfo: currentRowHeaderData[i].typeInfo, body: body };
            newCellItems.push(cellItem);
        }

        const newRowItem = {
            title: data.title,
            category: 'body',
            cellItems: newCellItems
        }



        const groupItemData = {
            groupID: data.parentId,
            newGroupItem: newRowItem
            //groupInd: groupInd
        }


        await http.post(config.apiEndPoint + workspaceDataService.addWorkspaceBoardGroupItem, groupItemData)
            .then(response => {
                dispatch({
                    type: ADD_FETCH_GROUP_ITEM_DATA_SUCCESS,
                    payload: response.data.newGroupItem
                });
                dispatch({
                    type: 'ADD_GROUP_ITEM_IN_GROUP',
                    payload: { groupId: data.parentId, groupItem: response.data.newGroupItem }
                })
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: WORKSPACE_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });

    }
};
export const renameGroupItem = (data) => {
    return async (dispatch) => {
        const renameData = {
            groupItemID: data._id,
            title: data.title
        }
        await http.post(config.apiEndPoint + workspaceDataService.updateGroupItemTitle, renameData)
            .then(response => {
                dispatch({
                    type: UPDATE_FETCH_GROUP_ITEM_DATA_SUCCESS,
                    payload: renameData
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: WORKSPACE_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });
    }
};

export const getSubWorkspacesL2 = (data) => {

    return async (dispatch, getState) => {

       let  discover = findObject(getState().discover.discoverSubWorkspace.data, '_id', data._id)
        console.log('N-->', discover);
        dispatch({
            type: FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS,
            payload: discover.subworkspaces
        });
        console.log('N-->', discover.subworkspaces);
    }
    // return async (dispatch)  => {
    //    dispatch({ type: FETCH_WORKSPACE_DATA_REQUEST });
    //    await http.get(config.apiEndPoint + workspaceDataService.getAllWorkSpacesNested)
    //    .then(response =>{

    //        dispatch({
    //            type:FETCH_WORKSPACE_DATA_SUCCESS,
    //            payload:response.data.response
    //        });
    //    })
    //    .catch(error => {
    //        dispatch({
    //            type:WORKSPACE_DATA_ERROR,
    //            payload:'error in getting data'
    //        });
    //    });
    // }
};

export const createSubWorkSpaceL2 = (data) => {
    return async (dispatch) => { //
        await http.post(config.apiEndPoint + workspaceDataService.createSubInsideSubworkspace, data)
            .then(response => {
               
                dispatch({
                    type: ADD_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS,
                    payload: response.data.subworkspace
                });
               
                dispatch({
                    type: 'ADD_SUB_WORKSPACE_IN_SUB_WORKSPACE',
                    payload: { subworkspace_id: data.subworkspaceId, subworkspace: response.data.subworkspace }
                })
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: SUB_WORKSPACE_L2_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });
    }
};
export const renameSubWorkSpaceL2 = (data) => {
    return async (dispatch) => {
        const renameData = {
            id: data._id,
            title: data.title
        }
        await http.post(config.apiEndPoint + workspaceDataService.renameSubWorkspace, renameData)
            .then(response => {
                dispatch({
                    type: UPDATE_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS,
                    payload: data
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: SUB_WORKSPACE_L2_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });
    }
};

export const removeSubWorkSpaceL2 = (data) => {
    return async (dispatch) => {
        const removeData = {
            parentID: data.subworkspaceId,
            subworkspaceID: data._id
        }
        console.log('NNN-->',removeData);
        await http.post(config.apiEndPoint + workspaceDataService.removeSubworkspaceFromSub, removeData)
            .then(response => {
                dispatch({
                    type: DELETE_SUB_WORKSPACE_L2_DATA_SUCCESS,
                    payload: data
                });
                dispatch({
                    type: 'DELETE_SUB_L2_FROM_SUB_WORKSPACE',
                    payload: data
                });
            })
            .catch(error => {
                console.log(error);
                dispatch({
                    type: SUB_WORKSPACE_L2_DATA_ERROR,
                    payload: 'error in getting data'
                });
            });
    }
};

