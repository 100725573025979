import React from 'react';
import Select from 'react-select';

function SelectInput({label , error , ...rest }) {
  console.log('options==>', rest.options)
  console.log('value==>', rest.value)
    const options = rest.options.some(  
      value => { return typeof value == "object" } ) ? rest.options : rest.options.map(option => ({ value: option, label: option }))
    const customStyles = {
        control: base => ({
          ...base,
          height: 40,
          minHeight: 40,
          top: '5px',
          backgroundColor: 'white',
          position: 'relative',
        //   color: "#000000", fontSize: '12px', fontWeight: 300, border: '1px solid #CCC'
        })
      };
    return (
        <fieldset className="form-label-group">
            
            { rest.fieldtype !== undefined ? <Select placeholder={''} options={options} onChange={rest.onChange} styles={customStyles} value={rest.value} isDisabled={true}/> : <Select placeholder={''} options={options} onChange={rest.onChange} styles={customStyles} value={rest.value} /> }
            <span class="user-profile-label" style={{
    position: 'absolute',
    top: '-5px',
    color: '#21396c',
    left: '20px',
    backgroundColor: '#fff'}}>{label}<span class="asterik">*</span></span>
            { error && <div className="alert alert-danger">{error.message}</div>}
        </fieldset>
    );
}

export default SelectInput;