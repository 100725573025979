import React from 'react';

import { connect } from 'react-redux';
import { addRow, fetchError } from '../../../../../actions';
import { initialGroupCellItemBody, makeid } from '../../../../../util/index';
import { getMyPermission } from '../../../../../util/permission';

class AddRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showButton: false,
            value: "",
            actionPermission: 'edit'
        }
    }

    componentDidMount() {
       
        if (this.props.boardData.boardID !== '') {
           
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }


    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {

            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    onKeyPress = (event) => {
        if (event.charCode === 13) {
            console.log("this.value", this.state.value)
            this.addNewRow(this.state.value);
        }
    }

    onBlur = () => {
        setTimeout(() => {
            this.setState({ showButton: false })
        }, 300)
    }

    onClickAddRow = () => {
        console.log("test");
        if (this.state.actionPermission === 'edit') {
            this.addNewRow(this.state.value);
        } else {
            this.props.fetchError("You don't have Permission!.")
        }
    }

    addNewRow = (title) => {
        if (title !== "") {
            const { groupInd, id } = this.props;
            const boardId = this.props.boardData.boardID;
            const currentRowHeaderData = this.props.boardData.data[0].groupItems[0].cellItems;
            const groupItems = this.props.boardData.data[groupInd].groupItems;
            const newCellItems = [];
            for (let i = 0; i < currentRowHeaderData.length; i++) {
                let body = initialGroupCellItemBody(currentRowHeaderData[i].key);
                let cellItem = { _id: makeid('col', 7), key: currentRowHeaderData[i].key, body: body, columnId: currentRowHeaderData[i].columnId };
                newCellItems.push(cellItem);
            }

            const newRowItem = {
                _id: makeid('row', 5),
                title: title,
                category: 'body',
                cellItems: newCellItems
            }

            // console.log("newRowItem====>", newRowItem)

            this.props.addRow({
                boardId: boardId,
                groupID: id,
                newGroupItem: newRowItem,
                groupInd: groupInd
            })

            this.setState({ value: "" })
        }
    }

    render() {
        
        const { color } = this.props.boardData.data[this.props.groupInd];
      
        return (
            <div className="list__item"
            style={{marginBottom: '2px', borderLeft: '5px solid', borderColor: color?color:'#bdbdbd' , 'position' : 'sticky' , 'left' : '0' }}
            >
                <div className="card group-row p-1 w-100 mb-1" style={{ 'border' : '1px solid #EAEAEA' , marginTop : '2px' }}>
                    <div className="d-inline-flex " style={{ height: 'auto' }}>
                        <input type="text" className="w-100 workspace-board" style={{ 'position' : 'sticky' , 'left' : '0' }}
                            placeholder={"+Add "+this.props.boardData.workspaceBoard.terminology.charAt(0).toUpperCase() + this.props.boardData.workspaceBoard.terminology.slice(1)}
                            value={this.state.value}
                            style={{ padding: '3px 4px' }}
                            onFocus={() => this.setState({ showButton: true })}
                            onBlur={this.onBlur}
                            onChange={(ev) => { this.setState({ value: ev.target.value }) }}
                            onKeyPress={this.onKeyPress}
                            disabled={(this.state.actionPermission === 'view') ? "disabled" : ""}
                        />
                        <button type="button"
                            className="btn btn-primary"
                            style={{ display: this.state.showButton ? 'block' : 'none', padding: '3px 20px' , 'margin-left' : '20px' }}
                            onClick={this.onClickAddRow}
                        >Add</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { addRow, fetchError }

export default connect(mapStateToProps, mapDispatchToProps)(AddRow);