import React from 'react';
import axios from '../../../util/Api.js';
import Form from '../common/form.jsx';
class Field extends Form {
    constructor(props) {
        super(props);
        this.state = {
          passportIssuedDate: this.props.item.body!== null ? new Date(this.props.item.body) : null,
          firstName: this.props.item.body,
          selectedGenderOption: {'value': this.props.item.body, 'label': this.props.item.body},
        }
    }
  
    componentDidMount() {
      if (this.props.jsonschema) {
        
          let groupItem = this.props.jsonschema[this.props.groupInd].groupItems[this.props.rowInd];
          let cellItems = groupItem?.cellItems || [];
      }
  }
    componentDidUpdate(prevProps, prevState) {
          if (this.props.item.body !== prevProps.item.body) {
              this.setState({ firstName: this.props.item.body });
          }
      }
  
      saveField = (val) => {
      
        axios.post('/users/saveField', {
            group: val
        }).then(({ data }) => {
            console.log("savefielddata===>", data)
  
            // this.props.showMessage(`${this.state.workspaceTitle} created successfully!`)
            
            // this.props.reloadWorkspace({
            //     reload: !this.props.boardData.loadWorkspace
            // })
        })
    
  }
  
    updateCellItem = (value) => {
      const { jsonschema, groupInd, rowInd, colInd } = this.props
      let groups = jsonschema;
          let oldBody = groups[groupInd].groupItems[rowInd].cellItems[colInd].body;
          let newBody = value;
          groups[groupInd].groupItems[rowInd].cellItems[colInd].body = value
          console.log('updatecellitem===>', groups)
          this.saveField(groups)
    }
    onChangepassportIssuedDate = (date) => {
      let value = date;
      this.setState({
        passportIssuedDate: value
      })
      this.updateCellItem(date)
      console.log(value);
    }
  
    onChangeFirstName = (e) => {
      let value = e.target.value;
      this.setState({
        firstName: value
      })
      this.updateCellItem(e.target.value)
      console.log(value);
    }
  
    handleChange_Gender = selectedGenderOption => {
      // this.resetSelectGenderDropDown();
      this.setState(
        { selectedGenderOption },
        () => console.log(`selectedGenderOption selected:`, this.state.selectedGenderOption)
      );
      this.updateCellItem(selectedGenderOption.value)
    }
  
    render() {
        const { groupInd, rowInd, colInd, item } = this.props;
        const { passportIssuedDate, passportIssuedDateError, selectedGenderOption, selectGenderError, firstName, firstNameError } = this.state;
    
        switch (item.key) {
            case 'date':
                return (
                  
                    this.renderUserProfileCalendar("text", passportIssuedDate, "", passportIssuedDateError, this.onChangepassportIssuedDate)
                    // this.renderUserProfileInput("text", lastName, "Passport Issue Date", lastNameError, this.onChangeLastName)
                  
                    // <DateItem
                    // isWorkspaceOwner={this.props.isWorkspaceOwner}
                    // isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    // isBoardAdmin={this.props.isBoardAdmin} 
                    // groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'text':
                return (
                  this.renderUserProfileInput("text", firstName, "", firstNameError, this.onChangeFirstName)
                    // <TextItem
                    // isWorkspaceOwner={this.props.isWorkspaceOwner}
                    // isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    // isBoardAdmin={this.props.isBoardAdmin} 
                    // groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            case 'dropdown':
                return (
                  this.renderUserProfileSelectOptionsControl(selectedGenderOption, this.handleChange_Gender, item.options, "", selectGenderError)
                    // <DropdownItem
                    // isWorkspaceOwner={this.props.isWorkspaceOwner}
                    // isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                    // isBoardAdmin={this.props.isBoardAdmin} 
                    // groupInd={groupInd} rowInd={rowInd} colInd={colInd} {...item} />
                )
            
            default:
                return (
                    <div>{item.body}</div>
                );
        }
  
    }
  }
  export default Field