import React, { useState, useEffect }  from 'react';
import ContentEditable from 'react-contenteditable';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { changeGroupTitle, changeWorkspaceBoard } from '../../../../../actions';
import GroupEvent from './GroupEvent';
import { getMyPermission } from '../../../../../util/permission';
import axios from '../../../../../util/Api';
import config from '../../../../services/config.json';
// const socket = require('socket.io-client')(config.baseUrlEndPoint, {
//     transports: ['websocket'],
//     rejectUnauthorized:   false,
//   })

//   import io from 'socket.io-client';
//   const socket = io.connect(config.baseUrlEndPoint,{origins:"*"});



 
class GroupTitle extends React.Component {
    constructor(props) {
        super(props)
        this.groupTitleEditableRef = React.createRef();
        this.state = {
            groupTitle: this.props.groupTitle,
            actionPermission: 'view',
            isWorkspaceOwner: false,
            isWorkspaceAdmin: false,
            boardMembers: [],
            color: this.props?.boardData?.data[this.props.groupInd]?.color
        }
        this.timeout = 0;
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    
   
    isBoardAdmin = () => {
   
        let members = this.state.boardMembers;
        let Index = members.findIndex(member => member._id === this.authUser._id);
        if (Index == -1) {
            return false;
        }
        if (members[Index].is_bAdmin) {
            return true;
        } else {
            return false;
        }
    }
    onChangeGroupTitle = (event) => {
        this.setState({ groupTitle: event.target.value });
    }
    
    componentDidMount() {

        // if(this.props.boardData.boardID !==''){
        //     socket.emit('connectBoard',this.props.boardData.boardID);
        //     socket.on("socketEvent_boardUpdate", Obj => {
        //           this.props.changeWorkspaceBoard({
        //               id: Obj.boardId?Obj.boardId:this.props.boardData.boardID,
        //               workspaceID: this.props.boardData.workspaceID
        //           })
        //   });
        // }
               
        
        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }

         axios.get('/workspace/getWorkSpaceById/', {
            params: { id: this.props.boardData.workspaceID }
        }).then(({ data }) => {
            let workspace = data.workspaces;           
            this.setState({ workspaceAdmins: workspace.admins })
            this.setState({ workspaceOwner: workspace.CreatedBy })
             if (this.authUser._id == workspace.CreatedBy) {
                this.setState({ isWorkspaceOwner: true })
                this.setState({ isWorkspaceAdmin: true })
            }   
            if (workspace.admins.includes(this.authUser._id)) {
                this.setState({ isWorkspaceAdmin: true })
            }else{
                this.setState({ isWorkspaceAdmin: false }) 
            }         
           
        }).catch(err => {
            console.log("Error: ", err)
        })  
        let boardMembers = this.props.boardData.workspaceBoard.members ? JSON.parse(this.props.boardData.workspaceBoard.members) : [];
        this.setState({ boardMembers: boardMembers })
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);

            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
        if(this.props.boardData.boardID !== prevProps.boardData.boardID){
            // socket.emit('disconnectBoard',prevProps.boardData.boardID);
            // socket.emit('connectBoard',this.props.boardData.boardID);

            axios.get('/workspace/getWorkSpaceById/', {
                params: { id: this.props.boardData.workspaceID }
            }).then(({ data }) => {
                let workspace = data.workspaces;           
                this.setState({ workspaceAdmins: workspace.admins })
                this.setState({ workspaceOwner: workspace.CreatedBy })
               
                if (this.authUser._id == workspace.CreatedBy) {
                    this.setState({ isWorkspaceOwner: true })
                    this.setState({ isWorkspaceAdmin: true })
                }else{
                    this.setState({ isWorkspaceOwner: false })
                    this.setState({ isWorkspaceAdmin: false })
                }
                if (workspace.admins.includes(this.authUser._id)) {
                    this.setState({ isWorkspaceAdmin: true })
                }else{
                    this.setState({ isWorkspaceAdmin: false }) 
                }     
               
            }).catch(err => {
                console.log("Error: ", err)
            })  
            let boardMembers = this.props.boardData.workspaceBoard.members ? JSON.parse(this.props.boardData.workspaceBoard.members) : [];
            this.setState({ boardMembers: boardMembers })
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ groupTitle: nextProps.groupTitle })
        this.setState({ color: nextProps?.boardData?.data[this.props.groupInd]?.color})

    }

    onKeyUp = (event) => {

        const { groupInd, id } = this.props;

            this.props.changeGroupTitle({
                groupInd: groupInd,
                groupTitle: event.target.innerText,
                groupID: id,
                boardId: this.props.boardData.boardID
            });
        
        // return;
        // if (this.timeout) clearTimeout(this.timeout);
        // console.log('event.target.innerText',event.target.innerText);
     
        // this.timeout = setTimeout(() => {            
        // }, 1000);
    }

    renameGroup = () => {
        if (this.props.boardData.boardID !== '') {
         
            this.groupTitleEditableRef.focus();
        }
    }

    render() {
        const { id, groupInd, items, groupTitle } = this.props;
        
        return (
         <>

        <div style={{ 'display' : 'flex' }}>
           <div style= {{ backgroundColor : this.state.color?this.state.color:'#bdbdbd' , 'width' : '6px' , 'left' : '-1px' , 'position' : 'sticky' , 'height': '35px'  , 'z-index' : '8888'  }}></div> 
            
            <div className="d-flex group-head" style={{ 'background-color' : '#ffffff' ,  'padding' : '7px' , 'padding' : '0px 14px 0px',  marginBottom: '-5px' , borderLeftColor: this.state.color?this.state.color:'#bdbdbd', 'width' : '100%'}}
            >
                <GroupEvent
                  isWorkspaceOwner={this.state.isWorkspaceOwner}
                  isWorkspaceAdmin={this.state.isWorkspaceAdmin}
                  isBoardAdmin={this.isBoardAdmin()}
                    groupInd={groupInd} groupTitle={groupTitle} id={id}
                    renameGroup={this.renameGroup}
                />

               {(this.state.isWorkspaceAdmin || this.state.isWorkspaceOwner || this.isBoardAdmin()) || (this.props.boardData.workspaceBoard.permission=='edit-everything' ) && <div {...this.props.groupDragProvided.dragHandleProps} style={{ padding: '10px 1px' }}>
                    <i className='bx bx-grid-vertical' data-target="tooltip" data-title="Drag Group" style={{  'color' : this.state.color?this.state.color:'#ff0100' }}></i>
                </div>}

                <i class="bx bx-grid-vertical group-move" data-toggle="tooltip" data-placement="top" title="" data-original-title="Drag Group"  {...this.props.provided.dragHandleProps}></i>
                              
                <ContentEditable
                    className="holder__title board-name group-title-name"
                    innerRef={r=>this.groupTitleEditableRef=r}
                    data-tip={this.state.groupTitle}
                    html={this.state.groupTitle}
                    disabled={(this.state.actionPermission === 'view' || !(this.state.isWorkspaceAdmin || this.state.isWorkspaceOwner || this.isBoardAdmin())) ? true : false}
      
                    onBlur={event => this.onKeyUp(event)}
                    style={{ zIndex: "20", padding: '5px 8px', marginBottom: '1px' , 'color' : this.state.color?this.state.color:'#000000' , marginLeft : '10px' , fontSize : '15.5px' , fontWeight : '600 !important' , '-webkit-font-smoothing' : 'initial' }}
                    tagName='h4'
                />
            </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeGroupTitle, changeWorkspaceBoard };

export default connect(mapStateToProps, mapDispatchToProps)(GroupTitle);
