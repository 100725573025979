import React from 'react';
import { connect } from 'react-redux';
import { css } from "@emotion/core";
// import ClipLoader from "react-spinners/ClipLoader";
import { MoonLoader } from 'react-spinners';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red!important;
`;

class LoadingComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        console.log("commondata===>", this.props.commonData.loading);
        return (
            <div className={`sweet-loading ${this.props.commonData.loading ? 'active' : ''}`}>

                <div style={{ width: '100%' }}>
                    <MoonLoader
                        css={override}
                        size={70}
                        color={"#123abc"}
                        loading={this.props.commonData.loading}
                    />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ commonData }) => {
    return { commonData }
}

export default connect(mapStateToProps)(LoadingComponent)