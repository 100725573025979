import React, { Component } from 'react';

const AvatarUsers = (props) => {
   return (
      <>

         <div style={{ marginTop: '10px' }}>

            <ul className="list-unstyled users-list m-0 d-flex align-items-center ml-1">
               <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" title="" className="avatar pull-up" data-original-title="Lilian Nenez">
                  <img src="images/portrait/small/avatar-s-24.jpg" alt="Avatar" style={{ width:'30px' , height: '30px' }} />
               </li>

               <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" title="" className="avatar pull-up" data-original-title="Alberto Glotzbach">
                  <img src="images/portrait/small/avatar-s-25.jpg" alt="Avatar" style={{ width:'30px' , height: '30px' }} />
               </li>

               <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" title="" className="avatar pull-up" data-original-title="Alberto Glotzbach">
                  <img src="images/portrait/small/avatar-s-26.jpg" alt="Avatar" style={{ width:'30px' , height: '30px' }} />
               </li>

               <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" title="" className="avatar pull-up" data-original-title="Alberto Glotzbach">
                  <img src="images/portrait/small/avatar-s-25.jpg" alt="Avatar" style={{ width:'30px' , height: '30px' }} />
               </li>

            </ul>
         </div>

      </>

   );


}
export default AvatarUsers;

