import React, { Component } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import "react-tabs/style/react-tabs.css";
import http from "../services/http-service";
import * as savedSearch from "./saved-search/constant";
import * as recentSearch from "./recent-search/constant";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import "./src/css/high-light.css";
import "./card.css";
import "./src/css/style.css";
import "react-tabs/style/react-tabs.css";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import "react-toggle/style.css"; // for ES6 modules
import Toggle from "react-toggle"; // http://aaronshaf.github.io/react-toggle/   //https://github.com/aaronshaf/react-toggle
import Checkbox from "rc-checkbox"; //https://github.com/react-component/checkbox
import * as Message from "../messenger/constant";
import Moment from "moment";
import User from "../auth/auth-services/user-service";

const suggestions = [];
let _currentUser = "";
function renderInput(inputProps) {
  const { classes, ref, ...other } = inputProps;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: ref,
        classes: {
          input: classes.input,
        },
        ...other,
      }}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong
              key={String(index)}
              style={{ fontWeight: "bold", color: "purple", fontSize: "18" }}
            >
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;
  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 &&
          suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: "relative",
    height: 250,
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
});

class MessageSearch extends Component {
  state = {
    value: "",
    suggestions: [],
    isSavedSearchChecked: false,
    isHorezintalDatePickerShow: false,
    selectedDate: "",
    tabIndex: 0,
    inputHighLightKeyWords: [],
    content: "New Group",
    groups: [],
    groupItemsData: [],
    error: false,
    userData: [],
    hintData: [],
    text: "",
    allPeopleData: [],
    allMessages: [],
    allFiltredMessages: [],
    isIWasMentioned: true,
    isIWrote: true,
    isHasAttachments: true,
    isAReply: true,
    isArchived: true,
    isIDidntReplyTo: true,
    cheeseIsReady: false,
    totalRecords: 0,
  };

  async componentDidMount() {
    this._initMessageSearch();
    _currentUser = User.getUserByToken("token");

    try {
      if (
        this.props.searchNameDataIwasMentioned &&
        this.props.searchNameDataIwasMentioned === "QuickSearchIWasMentioned"
      ) {
        this.getMessageByIWasMentioned();
      } else if (
        this.props.searchNameDataIDidntReply &&
        this.props.searchNameDataIDidntReply ===
          "QuickSearchIDidntReplyMessages"
      ) {
        this.getMessageByIDidntReplyTo();
      } else if (
        this.props.quickSearchSavedObj.quickSearchSavedType &&
        this.props.quickSearchSavedObj.quickSearchSavedType === "Message" &&
        this.props.quickSearchSavedObj.searchNameDataSavedSearch
      ) {
        const messageBodyDataByTime = {
          messageBody: this.props.quickSearchSavedObj.searchNameDataSavedSearch,
          isRevelanceByTime: true,
        };
        const messageBodyDatawithoutTime = {
          messageBody: this.props.quickSearchSavedObj.searchNameDataSavedSearch,
          isRevelanceByTime: false,
        };
        await http
          .post(
            Message.getMessageByBodyContent,
            this.state.cheeseIsReady
              ? messageBodyDataByTime
              : messageBodyDatawithoutTime
          )
          .then((messageBody) => {
            this.setState({
              allFiltredMessages: messageBody.data.message,
            });
            this.setState({
              totalRecords: this.state.allFiltredMessages.length,
            });
            localStorage.setItem("totalMessagesRecords", 0);
            localStorage.setItem(
              "totalMessagesRecords",
              this.state.totalRecords
            );
          });
      }
    } catch (ex) {}
  }

  errorHandler = ({ error }) => this.setState({ error });

  onChangeCallback = (selectedInterval) => this.setState({ selectedInterval });

  getSuggestionValue = (suggestion) => {
    this.setState({
      inputHighLightKeyWords: [
        ...this.state.inputHighLightKeyWords,
        suggestion.name,
      ],
    });
    return suggestion.name;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      inputHighLightKeyWords: [],
    });
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
    if (this.state.suggestions === []) {
      this.setState({
        inputHighLightKeyWords: [],
      });
    }
  };

  filterByDate = () => {
    this.setState({
      isHorezintalDatePickerShow: !this.state.isHorezintalDatePickerShow,
    });
  };

  onSelectedDay = (d) => {
    this.setState({ selectedDate: d });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  _initMessageSearch = async () => {
    await http.get(Message.getAllMessages).then((messageData) => {
      let messages = messageData.data.data;
      messages.map((msg) => {
        suggestions.push({ label: msg.message });
      });
    });
  };

  searchResult = async () => {
    //serach by file name
    if (this.state.value === "" || this.state.value === null) return;
    const messageBodyDataByTime = {
      messageBody: this.state.value,
      isRevelanceByTime: true,
    };
    const messageBodyDatawithoutTime = {
      messageBody: this.state.value,
      isRevelanceByTime: false,
    };
    await http
      .post(
        Message.getMessageByBodyContent,
        this.state.cheeseIsReady
          ? messageBodyDataByTime
          : messageBodyDatawithoutTime
      )
      .then((messageBody) => {
        this.setState({
          allFiltredMessages: messageBody.data.message,
        });
        this.setState({
          totalRecords: this.state.allFiltredMessages.length,
        });
        localStorage.setItem("totalMessagesRecords", 0);
        localStorage.setItem("totalMessagesRecords", this.state.totalRecords);
      });

    this.saveRecentSavedSearch();
    try {
      if (this.state.allFiltredMessages.length !== 0) return;
      //search by last name
      const fileTypeDataByTime = {
        fileTypes: this.state.value,
        isRevelanceByTime: true,
      };
      const fileTypeDatawithoutTime = {
        fileTypes: this.state.value,
        isRevelanceByTime: false,
      };
      await http
        .post(
          File.GET_FILES_BY_TYPE,
          this.state.cheeseIsReady
            ? fileTypeDataByTime
            : fileTypeDatawithoutTime
        )
        .then((fileByType) => {
          this.setState({
            allFiltredMessages: fileByType.data.file,
          });
          this.setState({
            totalRecords: this.state.allFiltredMessages.length,
          });
          localStorage.setItem("totalFileRecords", 0);
          localStorage.setItem("totalFileRecords", this.state.totalRecords);
        });
      this.saveRecentSavedSearch();
      if (this.state.allFiltredMessages.length !== 0) return;
    } catch (ex) {
      console.log(ex.Message);
    }
  };

  saveRecentSavedSearch = async () => {
    const savedsearchData = {
      searchName: this.state.value,
      searchUser: _currentUser.id,
    };
    if (this.state.isSavedSearchChecked === true) {
      http
        .post(savedSearch.SAVE_SAVED_SEARCH, savedsearchData)
        .then((result) => {
          console.log(result, "saved search is saved successfully");
        });
    }
    const recentSearchData = {
      searchName: this.state.value,
      searchUser: _currentUser.id,
    };
    http
      .post(recentSearch.SAVE_RECENT_SEARCH, recentSearchData)
      .then((result) => {
        console.log("saved search is saved successfully", result);
      });
  };

  onSavedSerachChange = () => {
    this.setState({ isSavedSearchChecked: !this.state.isSavedSearchChecked });
  };

  getMessageByStatus = (apiMessage) => {
    const messageTypeDataByTime = {
      user: _currentUser.id,
      isRevelanceByTime: true,
    };
    const messageTypeDatawithoutTime = {
      user: _currentUser.id,
      isRevelanceByTime: false,
    };
    http
      .post(
        apiMessage,
        this.state.cheeseIsReady
          ? messageTypeDataByTime
          : messageTypeDatawithoutTime
      )
      .then((result) => {
        let messages = result.data.message;

        this.setState({
          allFiltredMessages: messages,
        });
        this.setState({
          totalRecords: this.state.allFiltredMessages.length,
        });
        localStorage.setItem("totalMessagesRecords", 0);
        localStorage.setItem("totalMessagesRecords", this.state.totalRecords);
      });
  };
  getMessageByType = (apiMessage, msgType) => {
    const messageTypeDataByTime = {
      messageType: msgType,
      isRevelanceByTime: true,
    };
    const messageTypeDatawithoutTime = {
      messageType: msgType,
      isRevelanceByTime: false,
    };
    http
      .post(
        apiMessage,
        this.state.cheeseIsReady
          ? messageTypeDataByTime
          : messageTypeDatawithoutTime
      )
      .then((result) => {
        let messages = result.data.message;
        this.setState({
          allFiltredMessages: messages,
        });
        this.setState({
          totalRecords: this.state.allFiltredMessages.length,
        });
        localStorage.setItem("totalMessagesRecords", 0);
        localStorage.setItem("totalMessagesRecords", this.state.totalRecords);
      });
  };

  getMessageByIWasMentioned = () => {
    this.getMessageByStatus(Message.getMessageByIWasMentioned);
  };

  getMessageByIWrote = () => {
    this.getMessageByStatus(Message.getMessageByIWasWrote);
  };

  getMessageHasAttachments = () => {
    const messageTypeDataByTime = {
      isRevelanceByTime: true,
    };
    const messageTypeDatawithoutTime = {
      isRevelanceByTime: false,
    };
    http
      .post(
        Message.getAllMessagesGroupedbyUserAndFile,
        this.state.cheeseIsReady
          ? messageTypeDataByTime
          : messageTypeDatawithoutTime
      )
      .then((result) => {
        let messages = result.data.message;
        let messagesHasAttachments = messages.filter((x) => x.files.length > 0);

        this.setState({
          allFiltredMessages: messagesHasAttachments,
        });
        this.setState({
          totalRecords: this.state.allFiltredMessages.length,
        });
        localStorage.setItem("totalMessagesRecords", 0);
        localStorage.setItem("totalMessagesRecords", this.state.totalRecords);
      });
  };

  getMessageByIsARcived = async () => {
    let isArcivedType;
    await http.get(Message.getAllMessagesType).then((result) => {
      isArcivedType = result.data.response.filter((x) => x.Name === "Archived");
    });
    this.getMessageByType(Message.getMessageByType, isArcivedType[0]._id);
  };

  getMessageByIsAreply = async () => {
    let isArcivedType;
    await http.get(Message.getAllMessagesType).then((result) => {
      isArcivedType = result.data.response.filter((x) => x.Name === "Is Reply");
    });
    this.getMessageByType(Message.getMessageByType, isArcivedType[0]._id);
  };

  getMessageByIDidntReplyTo = async () => {
    let isArcivedType;
    await http.get(Message.getAllMessagesType).then((result) => {
      isArcivedType = result.data.response.filter(
        (x) => x.Name === "I Did Not Reply To"
      );
    });
    this.getMessageByType(Message.getMessageByType, isArcivedType[0]._id);
  };

  onIWasMentionedChange = () => {
    this.setState({ isIWasMentioned: !this.state.isIWasMentioned });
    if (this.state.isIWasMentioned === true) {
      this.getMessageByIWasMentioned();
    }
  };

  onIWroteChange = () => {
    this.setState({ isWord: !this.state.isIWrote });
    if (this.state.isIWrote === true) {
      this.getMessageByIWrote();
    }
  };

  onHasAttachments = () => {
    this.setState({ isHasAttachments: !this.state.isHasAttachments });
    if (this.state.isHasAttachments === true) {
      this.getMessageHasAttachments();
    }
  };

  onIsAreply = () => {
    this.setState({ isAReply: !this.state.isAReply });
    if (this.state.isAReply === true) {
      this.getMessageByIsAreply();
    }
  };

  onIsArchived = () => {
    this.setState({ isArchived: !this.state.isArchived });
    if (this.state.isArchived === true) {
      this.getMessageByIsARcived();
    }
  };

  onIdidntReplyTo = () => {
    this.setState({ isIDidntReplyTo: !this.state.isIDidntReplyTo });
    if (this.state.isIDidntReplyTo === true) {
      this.getMessageByIDidntReplyTo();
    }
  };

  handleCheeseChange = () => {
    this.setState({
      cheeseIsReady: !this.state.cheeseIsReady,
    });
  };

  render() {
    const { classes } = this.props;
    const {
      allFiltredMessages,
      isSavedSearchChecked,
      cheeseIsReady,
    } = this.state;

    return (
      <>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderInputComponent={renderInput}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestionsContainer}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            classes,
            placeholder: "Type Your Text Search Here ...",
            value: this.state.value,
            onChange: this.handleChange,
          }}
        />

        <div className="row">
          <button
            className="btn btn-primary col-md-2"
            style={{ marginRight: "5px", height: "50px" }}
            onClick={this.searchResult}
          >
            Search
          </button>
          <div style={{ marginTop: "10px" }}>
            <Checkbox
              size="small"
              isChecked={isSavedSearchChecked}
              onChange={this.onSavedSerachChange}
              color="#fc14bb"
            />
            <label style={{ marginTop: "3px" }}>Save search</label>
          </div>
        </div>

        <div
          className="col-md-12"
          style={{ marginTop: "100px", clear: "both" }}
        ></div>

        <div className="row">
          <div className="col-md-8" style={{ float: "left" }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">Message Name</th>
                  {/* <th scope="col">Message Type</th> */}
                  <th scope="col">Created By</th>
                  {/* <th scope="col">Created At</th>  */}
                </tr>
              </thead>
              <tbody>
                {allFiltredMessages.map((msg, index) => (
                  <tr key={index}>
                    <th>{msg.message}</th>
                    {/* <th>{msg.type}</th> */}
                    <th>
                      {msg.createdby.firstname + " " + msg.createdby.lastname}
                    </th>
                    <th>
                      {Moment(msg.createdAt).format("YYYY-MM-DD hh:mm:ss")}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-4" style={{ float: "right" }}>
            <div>
              <span
                style={{ fontSize: "20px", color: "black", fontWeight: "bold" }}
              >
                {this.state.totalRecords}
              </span>
              <label
                style={{ fontSize: "20px", color: "black", fontWeight: "bold" }}
              >
                Results
              </label>
            </div>
            <div>
              <label style={{ marginRight: "10px" }}>Sort By: </label>
              <label>Relevance:</label>
              <Toggle
                id="cheese-status"
                defaultChecked={cheeseIsReady}
                onChange={this.handleCheeseChange}
              />
              <label htmlFor="cheese-status">Time</label>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                <Checkbox onChange={this.getMessageByIWasMentioned} />I Was
                Mentioned
              </li>
              <li>
                <Checkbox onChange={this.onIWroteChange} />I Wrote
              </li>
              <li>
                <Checkbox onChange={this.onHasAttachments} />
                Has Attachments
              </li>
              <li>
                <Checkbox onChange={this.onIsAreply} />
                Is A Reply
              </li>
              <li>
                <Checkbox onChange={this.onIsArchived} />
                Is Archived
              </li>
              <li>
                <Checkbox onChange={this.onIdidntReplyTo} />I Didn't Reply To
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}

MessageSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(MessageSearch);
