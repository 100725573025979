import React from 'react';
import Form from '../common/form.jsx';
import { makeid } from '../../../util/index';
class AddGroupField extends Form {
    constructor(props) {
      super(props);
      this.state = {
        passportIssuedDate: '',
        selectedGenderOption: null,
      }
  }
  
  componentDidMount() {
    const { jsonschema } = this.props;
    console.log('addgroupfieldItems==>', jsonschema)
    this.setState({ jsonschema: jsonschema });
  }
  
  onChangepassportIssuedDate = (date) => {
    let value = date;
    this.setState({
      passportIssuedDate: value
    })
    console.log(value);
  }
  
  onChangeFirstName = (e) => {
    let value = e.target.value;
    this.setState({
      firstName: value
    })
    console.log(value);
  }
  
  onChangeop1 = (e) => {
    let value = e.target.value;
    this.setState({
      optionone: value
    })
    console.log(value);
  }
  
  onChangeop2 = (e) => {
    let value = e.target.value;
    this.setState({
      optiontwo: value
    })
    console.log(value);
  }
  
  handleChange_Gender = selectedGenderOption => {
    // this.resetSelectGenderDropDown();
    this.setState(
      { selectedGenderOption },
      () => console.log(`selectedGenderOption selected:`, this.state.selectedGenderOption)
    );
  }
  
  getHeaderColumn = (key) => {
    switch (key) {
      case 'text':
        return 'Text';
      case 'date':
        return 'Date';
      case 'dropdown':
        return 'Dropdown';
      default:
        return '';
    }
  }
   
  addNewGroup = (label) => {
    if (label !== "") {
        // const { groupInd, id } = this.props;
        // const boardId = this.props.boardData.boardID;
        // const currentRowHeaderData = this.props.boardData.data[0].groupItems[0].cellItems;
        // const groupItems = this.props.boardData.data[groupInd].groupItems;
        const newGroupItems = [];
        // for (let i = 0; i < currentRowHeaderData.length; i++) {
        //     let body = this.initialGroupCellItemBody(currentRowHeaderData[i].key);
            // let cellItem = {};
            // newCellItems.push(cellItem);
        // }
  
        const newGroupItem = {
            _id: makeid('group', 5),
            label: label,
            groupItems: []
        }
  
      return newGroupItem
  
        // console.log("newRowItem====>", newRowItem)
  
        // this.props.addRow({
        //     boardId: boardId,
        //     groupID: id,
        //     newGroupItem: newRowItem,
        //     groupInd: groupInd
        // })
  
        // this.setState({ value: "" })
    }
  }
  
  createGroup(jsonschema) {
    console.log('creategroup==>', group)
    var group = this.addNewGroup(this.state.firstName)
    jsonschema.push(group)
    this.props.handler(jsonschema)
    this.setState({firstName: ''})
  }
    render() {
      const { passportIssuedDate, passportIssuedDateError, selectedGenderOption, selectGenderError, firstName, firstNameError, optionone, optiontwo } = this.state;
      const { jsonschema } = this.props;
       return (
  
          <>
             {/* Vertically Centered modal Modal */}
             <div className="modal fade" id={"groupModalCenter"} tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-centered modal-dialog-scrollable" role="document">
                   <div className="modal-content">
                      <div className="modal-header">
                         <h5 className="modal-title" id="exampleModalCenterTitle">Add Field</h5>
                         <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="bx bx-x" />
                         </button>
                      </div>
                      <div className="modal-body" style={{ padding: '20px 50px', height: 200 }}>
                         <div className="row">
                           
  
                                  {/* <div className="col-md-6">
                                  {this.renderUserProfileSelectOptionsControl(selectedGenderOption, this.handleChange_Gender, Field_options, "Field Type", selectGenderError)}
                                  </div> */}
  
  
                                  <div className="col-md-6">
                                    {this.renderUserProfileInput("text", firstName, "Group Title", firstNameError, this.onChangeFirstName)}
                                </div>
  
                                </div>
  
                                
  
                              <div className="row">
  
                                  {/* <div className="col-md-6">
                                     
                                     
                                  </div> */}
  
  
                                  <div className="col-md-6">
                                  <button type="button" className="btn btn-primary round mr-1 mb-1" style={{ borderRadius: '5px', width: 185 }} onClick={()=>this.createGroup(jsonschema)}>Create Field</button>
                                  </div>
  
                                </div>
  
  
  
  
  
  
  
  
  
  
                              
  
                            
                         </div>
  
  
                      </div>
                   </div>
                </div>
  
  
  
  
          </>
  
       );
    }
  }
  export default AddGroupField