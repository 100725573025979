import React from "react";
import { WidthProvider, Responsive } from "react-grid-layout";
import "./styles.css";
import http from "../services/http-service";
import * as WorkSpaceTasksConst from "./constants";
import * as DashBoardSettingConstant from "../user-settings/dashboard-settings/constants";
import AllTaskWidget from "./setting-components/all-tasks-widget";
import AllTaskTableWidget from "./setting-components/all-tasks-table-widget";
import RecentActiveworkspaceWidget from "./setting-components/recent-active-workspace-widget";
import RecentActiveFolderWidget from "./setting-components/recent-active-folder-widget";
import ShowAttendanceMgmWidget from "./setting-components/show-attendance-mgm-widget";
import TaskDistributionWidget from "./setting-components/task-distribution-widget";
import ReminderMessangerWidget from "./setting-components/reminder-messanger-widget";
import ShowRecentCallHistoryWidget from "./setting-components/recent-call-history-widget";
import DashBoardCharts from "./dashboard-charts";
import ReminderWidget from "./setting-components/reminders-widget";
import MainMenu from "../../shared/components/main-menu";
import NavBar from "../../shared/components/navbar/navbar";
import Notification from "../notification/notification"
import UserService from "../auth/auth-services/user-service";


const ResponsiveReactGridLayout = WidthProvider(Responsive);
const originalLayouts = getFromLS("layouts") || {};
let currentUser = "";

var sortJsonArray = require("sort-json-array");

export default class ResponsiveLocalStorageLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    let user = UserService.getUserByToken("token");
    currentUser = user.id;
    this.state = {
      layouts: JSON.parse(JSON.stringify(originalLayouts)),
      id: "",
      userId: currentUser,
      getAllTasks: [],
      totalTasksCount: 0,
      onGoingTasksCount: 0,
      overdueTasksCount: 0,
      reviewTasksCount: 0,
      unreadTasksCount: 0,
      favoriteTasksCount: 0,
      recentTasksCount: 0,
      //GetAllWorkspace with specific user and tasks
      lastUpdated_10_workspace: [],
      WorkSpaceTasksCount: [],
      //*************************************************************** */
      //Show Tasks Counts Widget
      ShowTasksCountsWidgetValue: false,
      //Show All Tasks Widget
      ShowAllTasksWidgetValue: false,
      //Show Reminder Widget
      ShowReminderWidgetValue: false,
      //Show Messanger Widget
      ShowMessangerWidgetValue: false,
      //Show Recent Active WorkSpace Widget
      ShowRecentActiveWorkSpaceWidgetValue: false,
      //Show Recent Active Folders Widget
      ShowRecentActiveFoldersWidgetValue: false,
      //Show Attendance Management Widget
      ShowAttendanceManagementWidgetValue: false,
      //Show Task Distribution Widget
      ShowTaskDistributionWidgetValue: false,
      //Show Recent Call History Widget
      ShowRecentCallHistoryWidgetValue: false,
      //*************************************************************** */
      //Task Count Widget
      ShowAllTasksValue: false,
      ShowOngoingTaskCountValue: false,
      ShowOverdueTasksValue: false,
      ShowReviewTasksValue: false,
      ShowUnreadTaskValue: false,
      ShowFavoritesTaskValue: false,
      ShowRecentTasksValue: false,
      //*************************************************************** */
      //Task Table widget Group
      ShowABValue: false,
      ShowTaskValue: false,
      ShowProjectValue: false,
      ShowCategoryValue: false,
      ShowPriorityValue: false,
      ShowDueDateValue: false,
      ShowStatusValue: false,
      ShowTotalMessagesValue: false,
      ShowAssignessValue: false,
      //********************************************************************************* */
      boardsOptions: [],
      layoutDirections: [],
    };
  }
  static get defaultProps() {
    return {
      className: "layout",
      cols: { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 },
      rowHeight: 30,
    };
  }

  resetLayout() {
    this.setState({ layouts: {} });
  }

  onLayoutChange(layout, layouts) {
    removeForLS();
    saveToLS("layouts", layouts);
    this.setState({ layouts });
    this.saveDashboardSettingLayoutPositions();
  }

  async componentDidMount() {
    this.loadDashboardSettingsDirections();
    this.saveDashboardSettingLayoutPositions();
    this.loadFirstData();
  }

  loadDashboardSettingsDirections = async () => {
    let usrId = { userId: UserService.getUserByToken("token").id };
    //get setting data
    await http
      .post(DashBoardSettingConstant.getDashBoardSettingsByUserId, usrId)
      .then((response) => {
        removeForLS();
        if (response.data.data.length > 0) {
          saveToLS("layouts", response.data.data[0].DashboardLayout);
          this.setState({ layouts: response.data.data[0].DashboardLayout });
        }
      });
  };

  loadFirstData = async () => {
    this.loadBoardSettingsData();
    this.loadAllWorkSpace();
  };

  loadBoardSettingsData = async () => {
    let usrId = { userId: currentUser };
    //get setting data
    await http
      .post(DashBoardSettingConstant.getDashBoardSettingsByUserId, usrId)
      .then((response) => {
        response.data.data.map((data) => {
          this.setState({
            id: data._id,
            //General Widgets
            //Show Tasks Counts Widget
            ShowTasksCountsWidgetValue: data.ShowTasksCountsWidgetValue,
            ShowAllTasksWidgetValue: data.ShowAllTasksWidgetValue,
            //Show Reminder Widget
            ShowReminderWidgetValue: data.ShowReminderWidgetValue,
            //Show Messanger Widget
            ShowMessangerWidgetValue: data.ShowMessangerWidgetValue,
            //Show Recent Active WorkSpace Widget
            ShowRecentActiveWorkSpaceWidgetValue:
              data.ShowRecentActiveWorkSpaceWidgetValue,
            //Show Recent Active WorkSpace Widget
            ShowRecentActiveFoldersWidgetValue:
              data.ShowRecentActiveFoldersWidgetValue,
            //Show Attendance Management Widget
            ShowAttendanceManagementWidgetValue:
              data.ShowAttendanceManagementWidgetValue,
            //Show Task Distribution Widget
            ShowTaskDistributionWidgetValue:
              data.ShowTaskDistributionWidgetValue,
            //Show Recent Call History Widget
            ShowRecentCallHistoryWidgetValue:
              data.ShowRecentCallHistoryWidgetValue,
            //Getting Default WorkSpace
            DefaultWorkSpace: data.DefaultWorkSpace,
            //Task Count Widget
            ShowAllTasksValue: data.ShowAllTasksValue,
            ShowOngoingTaskCountValue: data.ShowOngoingTaskCountValue,
            ShowOverdueTasksValue: data.ShowOverdueTasksValue,
            ShowReviewTasksValue: data.ShowReviewTasksValue,
            ShowUnreadTaskValue: data.ShowUnreadTaskValue,
            ShowFavoritesTaskValue: data.ShowFavoritesTaskValue,
            ShowRecentTasksValue: data.ShowRecentTasksValue,
            //Task Table widget Group
            ShowABValue: data.ShowABValue,
            ShowTaskValue: data.ShowTaskValue,
            ShowProjectValue: data.ShowProjectValue,
            ShowCategoryValue: data.ShowCategoryValue,
            ShowPriorityValue: data.ShowPriorityValue,
            ShowDueDateValue: data.ShowDueDateValue,
            ShowStatusValue: data.ShowStatusValue,
            ShowTotalMessagesValue: data.ShowTotalMessagesValue,
            ShowAssignessValue: data.ShowAssignessValue,
            //load dashboard settings layout directions
            layoutDirections: data.DashboardLayout,
          });
        });
      });
  };

  loadAllWorkSpace = async () => {
    let usrId = { userId: currentUser };
    try {
      //Get All WorkSpace Tasks
      const data = {
        workspace_id: this.state.id,
        userId: "5fb65016a624bc4f507f452d",
      };
      let loadAllTasks,
        loadItemsData,
        onGoingTasks,
        overdueTasks,
        reviewTasks,
        unreadTasks,
        favoriteTasks = [];
      //Get Default WorkSpace Tasks Per User
      await http
        .post(WorkSpaceTasksConst.get_WorkSpaceTasksPerUser, data)
        .then((data) => {
          this.setState({ allWorkSpaceTasks: data.data.workspace_tasks });
          data.data.workspace_tasks.workspaceBoards.map((boardData) => {
            boardData.group.map((groupData) => {
              groupData.groupItems.map((gpItemData) => {
                loadAllTasks.push(gpItemData);
                this.setState({ getAllTasks: loadAllTasks });
                gpItemData.cellItems.map((cellItemData) => {
                  this.setState({
                    totalTasksCount: this.state.totalTasksCount + 1,
                  });
                  loadItemsData.push(cellItemData);
                });
              });
            });
          });
          this.setState({
            alldataRecords: loadItemsData,
          });
          loadItemsData.map((x) => {
            if (x.key == "status" && x.body == "Ongoing") {
              onGoingTasks.push({ key: "status", body: "Ongoing" });
            }
            if (x.key == "status" && x.body == "overdue") {
              overdueTasks.push({ key: "status", body: "overdue" });
            }
            if (x.key == "status" && x.body == "Review") {
              reviewTasks.push({ key: "status", body: "Review" });
            }
            if (x.key == "status" && x.body == "Unread") {
              unreadTasks.push({ key: "status", body: "Unread" });
            }
            if (x.key == "status" && x.body == "Favorite") {
              favoriteTasks.push({ key: "status", body: "Favorite" });
            }
          });
          this.setState({
            onGoingTasksCount: onGoingTasks.length,
            overdueTasksCount: overdueTasks.length,
            reviewTasksCount: reviewTasks.length,
            unreadTasksCount: unreadTasks.length,
            favoriteTasks: favoriteTasks.length,
          });
          //Get Recent Tasks
          sortJsonArray(loadAllTasks, "createdAt", "des");
          this.setState({ recentTasksCount: loadAllTasks.slice(0, 10).length });
        });
      let groupItemsCount = 0;
      let TasksCount = [];

      await http
        .post(WorkSpaceTasksConst.getAllWorkSpaceTasks, usrId)
        .then((data) => {
          this.setState({
            lastUpdated_10_workspace: data.data.workspace_tasks,
          });
          this.state.lastUpdated_10_workspace.map((workspaceBoards) => {
            workspaceBoards.workspaceBoards.map((groupData, index) => {
              groupData.group.map((groupItems) => {
                groupItemsCount =
                  groupItemsCount + groupItems.groupItems.length;
              });
              TasksCount.push({ index: groupItemsCount });
              this.setState({ WorkSpaceTasksCount: TasksCount });
            });
          });
        });
      sortJsonArray(this.state.lastUpdated_10_workspace, "updatedAt", "des");
      this.setState({
        lastUpdated_10_workspace: this.state.lastUpdated_10_workspace.slice(
          0,
          10
        ),
      });
    } catch (e) {
      // alert('Some Thing Went Wrong In Data Loading..Please Try Again Or Contact With Support..')
      console.log(e.message, "Error Message");
    }

    await http
      .post(WorkSpaceTasksConst.getAllWorkSpaceByUser, currentUser)
      .then((response) => {
        response.data.data.map((data) => {
          this.state.boardsOptions.push({ value: data._id, label: data.title });
        });
      });
  };

  handleworkspaceChange = () => { };
  saveDashboardSettingLayoutPositions = async () => {
    let data = {
      userId: currentUser,
      DashboardLayout: this.state.layouts,
    };
    http
      .post(DashBoardSettingConstant.saveDashboardLayoutSettings, data)
      .then((layoutData) => {
        console.log(layoutData, "====>Dashboard Data Saved Successfully");
      });
  };

  render() {
    const {
      totalTasksCount,
      onGoingTasksCount,
      overdueTasksCount,
      reviewTasksCount,
      unreadTasksCount,
      favoriteTasksCount,
      recentTasksCount,
      lastUpdated_10_workspace,
      WorkSpaceTasksCount,
      getAllTasks,
      ShowTasksCountsWidgetValue,
      ShowAllTasksWidgetValue,
      ShowRecentActiveWorkSpaceWidgetValue,
      ShowRecentActiveFoldersWidgetValue,
      ShowAttendanceManagementWidgetValue,
      ShowTaskDistributionWidgetValue,
      ShowRecentCallHistoryWidgetValue,
      ShowReminderWidgetValue,
      ShowMessangerWidgetValue,
      ShowAllTasksValue,
      ShowOngoingTaskCountValue,
      ShowOverdueTasksValue,
      ShowReviewTasksValue,
      ShowUnreadTaskValue,
      ShowFavoritesTaskValue,
      ShowRecentTasksValue,
      ShowABValue,
      ShowTaskValue,
      ShowProjectValue,
      ShowCategoryValue,
      ShowPriorityValue,
      ShowDueDateValue,
      ShowStatusValue,
      ShowTotalMessagesValue,
      ShowAssignessValue,
      boardsOptions,
    } = this.state;
    return (
      <>
        <MainMenu link={`dashboard`} />
       <div id="whole-layout">
          <NavBar />
          <div class="row dash-blue fixed-top"  id='bluetop'>
        <div className="col-xl-12 col-sm-12 col-md-4 col-lg-4 col-12" id="dashboard-layout">
          <div>
            <div className="inner-section">
              <h3 style={{fontFamily: 'myriad-pro,sans-serif !important', fontWeight: 600, marginTop: '10px' , marginLeft : '5px' }}>Dashboard</h3>
              <div style={{marginTop: '-10px'}}>
                <p className="mt-1"></p> 
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
          <table className="align-items-center table-newdashboard-section-one" style={{marginLeft: '750px'}}>
            <tbody><tr>
                <td rowSpan={2} style={{width: '50px'}}>
                  <ul className="nav navbar-nav bookmark-icons">
                    <li className="nav-item d-none d-lg-block nav-search" data-placement="top">
                      <a className="nav-link nav-link-search cursor-pointer" data-toggle="tooltip" title="Search your Project">
                        <img src="./images/icon/dashboard-icons/white icons-39.png" />
                      </a>
                    </li>
                  </ul>
                </td>
                <td rowSpan={2} style={{width: '50px'}}><a href data-action="expand" ng-click="fullScreen(this)"><img src="./images/icon/dashboard-icons/module-icons/white icons-28.png" height="25px" style={{cursor: 'pointer'}} /></a></td>
                <td rowSpan={2} style={{width: '50px'}}><a href data-action="expand"><img src="./images/icon/dashboard-icons/module-icons/white icons-27.png" height="25px" style={{cursor: 'pointer'}} /></a></td>
                <td rowSpan={2} style={{width: '50px'}}><a href data-toggle="modal" data-target="#default"><img src="./images/icon/dashboard-icons/module-icons/white icons-38.png" height="25px" style={{cursor: 'pointer'}} /></a></td>
                <td rowSpan={2} style={{width: '50px'}}><a href><img src="./images/icon/dashboard-icons/module-icons/white icons-37.png" height="25px" style={{cursor: 'pointer'}} /></a></td>
              </tr>
              <tr style={{fontWeight: 600}}>
                <td id="value" />
                <td id="value" />
                <td id="value" />
                <td id="value" />
                <td id="value" />
              </tr>
            </tbody></table>
        </div>
  </div>
          <div id="appdata" className='app-content content fixed-top content-lay-out' style={{ 'margin-top' : '0px' , 'marginBottom' : '20px' }}>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="row" style={{  'padding' : '30px' , 'marginBottom' : '20px' , 'margin-top' :'-25px' }}>
            <div className="col-md-9 col-lg-9 dashboard-inner-first-en">
              <div className="row small-responsive-inner-first">
                <div className="col-md-12 col-lg-12" style={{padding: 0}}>
                  <div className="board-left-section p-1" style={{backgroundImage: 'url("./images/alltask-bg.png")'}}>
                    <div className="board-left-txt">
                      <span>All Tasks</span><br />
                      <span style={{fontSize: '20px'}}>230</span>
                    </div>
                  </div>
                  <div className="board-left-section p-1" style={{backgroundImage: 'url("./images/ongoing-bg.png")'}}>
                    <div className="board-left-txt">
                      <span>Ongoing</span><br />
                      <span style={{fontSize: '20px'}}>120</span>
                    </div>
                  </div>
                  <div className="board-left-section p-1" style={{backgroundImage: 'url("./images/overdue-bg.png")'}}>
                    <div className="board-left-txt">
                      <span>Overdue</span><br />
                      <span style={{fontSize: '20px'}}>26</span>
                    </div>
                  </div>
                  <div className="board-left-section p-1" style={{backgroundImage: 'url("./images/review-bg.png")'}}>
                    <div className="board-left-txt">
                      <span>Review</span><br />
                      <span style={{fontSize: '20px'}}>230</span>
                    </div>
                  </div>
                  <div className="board-left-section p-1" style={{backgroundImage: 'url("./images/unread-bg.png")'}}>
                    <div className="board-left-txt">
                      <span>Unread</span><br />
                      <span style={{fontSize: '20px'}}>230</span>
                    </div>
                  </div>
                  <div className="board-left-section p-1" style={{backgroundImage: 'url("./images/favourites-bg.png")'}}>
                    <div className="board-left-txt">
                      <span>Favourites</span><br />
                      <span style={{fontSize: '20px'}}>230</span>
                    </div>
                  </div>
                  <div className="board-left-section p-1" style={{backgroundImage: 'url("./images/recent-bg.png")'}}>
                    <div className="board-left-txt">
                      <span>Recent</span><br />
                      <span style={{fontSize: '20px'}}>230</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row border pl-1 pr-1 pt-1 small-responsive-inner-second" style={{borderRadius: '15px', boxShadow: '0px 0px 9px 2px #EAEAEA', marginRight: '10px'}}>
                <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                  <div style={{float: 'left'}}>
                    <h5 className="left-head">All Tasks</h5>
                  </div>
                  <div style={{float: 'right', marginTop: '-2px'}}>
                    <a title="Reply" data-toggle="modal" data-target="#modal-City">
                      <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                    </a>
                  </div>
                </div>
                <div className="row" style={{padding: '0px 26px 0px 26px'}}>
                  <table className="task-table responsive">
                    <tbody><tr style={{padding: '5px'}}>
                        <th style={{textAlign: 'center'}}>AB</th>
                        <th style={{width: '170px', textAlign: 'left'}}>Task</th>
                        <th style={{textAlign: 'left'}}>Project</th>
                        <th style={{width: '100px', textAlign: 'center'}}>Category</th>
                        <th style={{width: '100px', textAlign: 'center'}}>Priority</th>
                        <th style={{width: '100px', textAlign: 'center'}}>Due Date</th>
                        <th style={{textAlign: 'center'}}>Status</th>
                        <th style={{width: '120px', textAlign: 'center'}}>Total Messages</th>
                        <th style={{textAlign: 'center'}}>Assignees</th>
                      </tr>
                    </tbody><tbody>
                      <tr className="first-row">
                        <td style={{fontWeight: 500}}>
                          <img className="round" src="./images/usericon.png" alt="avatar" style={{width: '30px', height: '30px'}} />
                        </td>
                        <td style={{fontWeight: 300, textAlign: 'left', fontSize: '12px', color: '#575756'}}>
                          FHS Status of Interior Design
                        </td>
                        <td style={{textAlign: 'left', fontSize: '13px', fontWeight: 600, color: '#575756'}}>FHS</td>
                        <td style={{textAlign: 'center'}}>
                          <div style={{backgroundColor: '#E8378F', minHeight: '20px', color: '#ffffff', borderRadius: '5px', textAlign: 'center', width: 'fit-content', margin: '0 auto' , fontWeight : '300' ,}}>&nbsp;&nbsp;Category&nbsp;&nbsp;</div>
                        </td>
                        <td>
                          <div style={{backgroundColor: '#f35800d1', minHeight: '20px', color: '#ffffff', borderRadius: '5px', padding: '1px 3px', textAlign: 'center', width: 'fit-content', margin: '0 auto' , fontWeight : '300' ,}}>&nbsp;&nbsp;Medium&nbsp;&nbsp;</div>
                        </td>
                        <td>
                          <span>13 May 2020</span>
                        </td>
                        <td>
                          <span>On Going</span>
                        </td>
                        <td>10</td>
                        <td>
                          <ul className="list-unstyled users-list m-0  d-flex align-items-center">
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Lai Lewandowski" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{'height' : '25px' , 'width' : '25px'  }} />
                            </li>
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Elicia Rieske" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{'height' : '25px' , 'width' : '25px'  }} />
                            </li>
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Darcey Nooner" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{'height' : '25px' , 'width' : '25px'  }} />
                            </li>
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Darcey Nooner" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar"  style={{'height' : '25px' , 'width' : '25px'  }} />
                            </li>
                            <li className="avatar pull-up">
                              <span className="badge badge-pill badge-light-primary badge-round">+7</span>
                            </li>
                          </ul>
                        </td>
                        <td><a className=" ml-75" /></td>
                      </tr>
                      <tr className="first-row">
                        <td style={{fontWeight: 500}}>
                          <img className="round" src="./images/usericon.png" alt="avatar" style={{width: '30px', height: '30px'}} />
                        </td>
                        <td style={{fontWeight: 300, textAlign: 'left', fontSize: '12px', color: '#575756'}}>
                          Social Media Marketing Tool(Feb)
                        </td>
                        <td style={{textAlign: 'left', fontSize: '13px', fontWeight: 600, color: '#575756'}}>Trading</td>
                        <td style={{textAlign: 'center'}}>
                          <div style={{backgroundColor: '#E8378F', minHeight: '20px', color: '#ffffff', borderRadius: '5px', textAlign: 'center', width: 'fit-content', margin: '0 auto' , fontWeight : '300' ,}}>&nbsp;&nbsp;Category&nbsp;&nbsp;</div>
                        </td>
                        <td>
                          <div style={{backgroundColor: '#f35800d1', minHeight: '20px', color: '#ffffff', borderRadius: '5px', padding: '1px 3px', textAlign: 'center', width: 'fit-content', margin: '0 auto' , fontWeight : '300' ,}}>&nbsp;&nbsp;Medium&nbsp;&nbsp;</div>
                        </td>
                        <td>
                          <span>13 May 2020</span>
                        </td>
                        <td>
                          <span>On Going</span>
                        </td>
                        <td>10</td>
                        <td>
                          <ul className="list-unstyled users-list m-0  d-flex align-items-center">
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Lai Lewandowski" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{'height' : '25px' , 'width' : '25px'  }} />
                            </li>
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Elicia Rieske" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{'height' : '25px' , 'width' : '25px'  }}  />
                            </li>
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Darcey Nooner" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{'height' : '25px' , 'width' : '25px'  }}  />
                            </li>
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Darcey Nooner" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{'height' : '25px' , 'width' : '25px'  }} />
                            </li>
                            <li className="avatar pull-up">
                              <span className="badge badge-pill badge-light-primary badge-round">+7</span>
                            </li>
                          </ul>
                        </td>
                        <td><a className=" ml-75" /></td>
                      </tr>
                      <tr className="first-row">
                        <td style={{fontWeight: 500}}>
                          <img className="round" src="./images/usericon.png" alt="avatar" style={{width: '30px', height: '30px'}} />
                        </td>
                        <td style={{fontWeight: 300, textAlign: 'left', fontSize: '12px', color: '#575756'}}>
                          Social Media Marketing Tool(Feb)
                        </td>
                        <td style={{textAlign: 'left', fontSize: '13px', fontWeight: 600, color: '#575756'}}>Automotive</td>
                        <td style={{textAlign: 'center'}}>
                          <div style={{backgroundColor: '#E8378F', minHeight: '20px', color: '#ffffff', borderRadius: '5px', textAlign: 'center', width: 'fit-content', margin: '0 auto' , fontWeight : '300' ,}}>&nbsp;&nbsp;Category&nbsp;&nbsp;</div>
                        </td>
                        <td>
                          <div style={{backgroundColor: '#f35800d1', minHeight: '20px', color: '#ffffff', borderRadius: '5px', padding: '1px 3px', textAlign: 'center', width: 'fit-content', margin: '0 auto' , fontWeight : '300' ,}}>&nbsp;&nbsp;Medium&nbsp;&nbsp;</div>
                        </td>
                        <td>
                          <span>13 May 2020</span>
                        </td>
                        <td>
                          <span>On Going</span>
                        </td>
                        <td>10</td>
                        <td>
                          <ul className="list-unstyled users-list m-0  d-flex align-items-center">
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Lai Lewandowski" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{'height' : '25px' , 'width' : '25px'  }} />
                            </li>
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Elicia Rieske" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{'height' : '25px' , 'width' : '25px'  }}  />
                            </li>
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Darcey Nooner" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{'height' : '25px' , 'width' : '25px'  }} />
                            </li>
                            <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Darcey Nooner" className="avatar pull-up">
                              <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{'height' : '25px' , 'width' : '25px'  }} />
                            </li>
                            <li className="avatar pull-up">
                              <span className="badge badge-pill badge-light-primary badge-round">+7</span>
                            </li>
                          </ul>
                        </td>
                        <td><a className=" ml-75" /></td>
                      </tr>
                    </tbody></table>
                 
                </div>
              </div>
              <div className="row p-1 ">
                <div className="col-md-6 col-lg-6 border small-responsive-inner-third-left" style={{minHeight: '200px', marginRight: '5px', marginLeft: '-15px', borderRadius: '15px', boxShadow: '0px 0px 9px 2px #EAEAEA'}}>
                  <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2" id="top-section">
                      <div style={{float: 'left', marginLeft: '15px'}}>
                        <h5 className="left-head">Recent Active Divisions</h5>
                      </div>
                      <div style={{float: 'right', marginTop: '-2px'}}>
                        <a title="Reply" data-toggle="modal" data-target="#modal-City">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-sm-12 col-md-12 col-lg-12" style={{display: 'contents'}}>
                      <div className="main-third">
                        <div className="third-inner-box p-1 pt-4 text-center" style={{backgroundColor: '#50a9ef'}}>
                          <span className="task-count">10</span><br />
                          <span className="task-name">Tasks</span>
                        </div>
                        <span className="member-name">Alzaabi</span><br />
                        <span className="member-count">20 Members</span>
                      </div>
                      <div className="main-third">
                        <div className="third-inner-box p-1 pt-4 text-center" style={{backgroundColor: '#7771f7'}}>
                          <span className="task-count">25</span><br />
                          <span className="task-name">Tasks</span>
                        </div>
                        <span className="member-name">Automotive</span><br />
                        <span className="member-count">08 Members</span>
                      </div>
                      <div className="main-third">
                        <div className="third-inner-box p-1 pt-4 text-center" style={{backgroundColor: '#50a9ef'}}>
                          <span className="task-count">08</span><br />
                          <span className="task-name">Tasks</span>
                        </div>
                        <span className="member-name">Trading</span><br />
                        <span className="member-count">12 Members</span>
                      </div>
                      <div className="main-third">
                        <div className="third-inner-box p-1 pt-4 text-center" style={{backgroundColor: '#5abc9f'}}>
                          <span className="task-count">11</span><br />
                          <span className="task-name">Tasks</span>
                        </div>
                        <span className="member-name">Royal</span><br />
                        <span className="member-count">10 Members</span>
                      </div>
                      <div className="main-third">
                        <div className="third-inner-box p-1 pt-4 text-center" style={{backgroundColor: '#5486da'}}>
                          <span className="task-count">17</span><br />
                          <span className="task-name">Tasks</span>
                        </div>
                        <span className="member-name">Contracting</span><br />
                        <span className="member-count">28 Members</span>
                      </div>
                      <div className="main-third">
                        <div className="third-inner-box p-1 pt-4 text-center" style={{backgroundColor: '#9f69dc'}}>
                          <span className="task-count">04</span><br />
                          <span className="task-name">Tasks</span>
                        </div>
                        <span className="member-name">Transport</span><br />
                        <span className="member-count">10 Members</span>
                      </div>
                      <div className="main-third">
                        <div className="third-inner-box p-1 pt-4 text-center" style={{backgroundColor: '#d54854'}}>
                          <span className="task-count">22</span><br />
                          <span className="task-name">Tasks</span>
                        </div>
                        <span className="member-name">Health</span><br />
                        <span className="member-count">17 Members</span>
                      </div>
                      <div className="main-third">
                        <div className="third-inner-box p-1 pt-4 text-center" style={{backgroundColor: '#aecd4f'}}>
                          <span className="task-count">11</span><br />
                          <span className="task-name">Tasks</span>
                        </div>
                        <span className="member-name">Pharmacy</span><br />
                        <span className="member-count">09 Members</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 border active-recent" style={{minHeight: '250px', borderRadius: '15px', boxShadow: '0px 0px 9px 2px #EAEAEA'}}>
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-12 mt-2" id="top-section">
                      <div style={{float: 'left', marginLeft: '15px'}}>
                        <h5 className="left-head">Recent Active Folders</h5>
                      </div>
                      <div style={{float: 'right', marginTop: '-2px'}}>
                        <a title="Reply" data-toggle="modal" data-target="#modal-City">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-12" style={{display: 'contents'}}>
                      <div className="folder first-folder">
                        <span className="folder-span">Automotive Finance</span>
                        <div className="progress bar" style={{position: 'relative', top: '45px', left: '5px', right: '5px', width: '90%', height: '4px'}}>
                          <div className="progress-bar" role="progressbar" style={{width: '50%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="folder second-folder">
                        <span className="folder-span">Trading Sales &amp; Marketing</span>
                        <div className="progress" style={{position: 'relative', top: '45px', left: '5px', right: '5px', width: '90%', height: '4px'}}>
                          <div className="progress-bar" role="progressbar" style={{width: '25%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="folder third-folder">
                        <span className="folder-span">Prateek - FHS</span>
                        <div className="progress" style={{position: 'relative', top: '45px', left: '5px', right: '5px', width: '90%', height: '4px'}}>
                          <div className="progress-bar" role="progressbar" style={{width: '45%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="folder fourth-folder">
                        <span className="folder-span">Recruitment</span>
                        <div className="progress" style={{position: 'relative', top: '45px', left: '5px', right: '5px', width: '90%', height: '4px'}}>
                          <div className="progress-bar" role="progressbar" style={{width: '75%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="folder fifth-folder">
                        <span className="folder-span">AEMC 2020 Jun Strategy</span>
                        <div className="progress" style={{position: 'relative', top: '45px', left: '5px', right: '5px', width: '90%', height: '4px'}}>
                          <div className="progress-bar" role="progressbar" style={{width: '60%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                      <div className="folder sixth-folder">
                        <span className="folder-span">Transport Vendor<br /> Payment Approval</span>
                        <div className="progress" style={{position: 'relative', top: '35px', left: '5px', right: '5px', width: '90%', height: '4px'}}>
                          <div className="progress-bar" role="progressbar" style={{width: '20%'}} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pr-1 pl-1">
                <div className="col-md-4 border fourth-first-section">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-12 mt-2" id="top-section">
                      <div style={{float: 'left', marginLeft: '15px'}}>
                        <h5 className="left-head">Attendance Management</h5>
                      </div>
                      <div style={{float: 'right', marginTop: '-2px'}}>
                        <a title="Reply" data-toggle="modal" data-target="#modal-City">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-md-5">
                      <span style={{fontSize: '14px'}}>Punch In</span>
                      <div style={{backgroundColor: '#EAEAEA', height: '100px', width: '110px', borderRadius: '15px'}}>
                      </div>
                    </div>
                    <div className="col-md-7" style={{lineHeight: '12px'}}>
                      <span style={{fontSize: '12.5px', paddingLeft: '5px'}}>08:05 A.M , 10 Jun 2020</span><br /><br />
                      <span style={{fontSize: '12px', fontWeight: 100, marginTop: '10px', paddingLeft: '5px', textAlign: 'justify'}}>
                        Al Maeraj Cafeteria<br />
                        162 , Khalid bin walid road,
                        Dubai
                      </span>
                      <div className="d-flex ml-1 mt-1">
                        <div className="round" style={{backgroundColor: '#336', width: '40px', height: '40px'}}>
                        </div>
                        <div className="round" style={{backgroundColor: '#336', width: '40px', height: '40px', marginLeft: '15px'}}>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              
                <div className="col-md-4 border fourth-second-section">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-12 mt-2" id="top-section">
                      <div style={{float: 'left', marginLeft: '15px'}}>
                        <h5 className="left-head">Task Distribution</h5>
                      </div>
                      <div style={{float: 'right', marginTop: '-2px'}}>
                        <a title="Reply" data-toggle="modal" data-target="#modal-City">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="card widget-state-multi-radial" style={{border: 'none', marginTop: '10px'  }}>
                        <div className="card-content">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="text-center">
                                  <div id="donut-chart" className="d-flex justify-content-center" />
                                </div>
                              </div>
                              <div className="col-md-6 d-flex flex-column justify-content-around">
                                 
                                       <div className="d-flex align-items-center">
                                        <i className="bullet bullet-success mr-50" />
                                        <span className="ml-50" style={{fontSize: '12px', fontWeight: 300, color: '#2f4475'}}>Ongoing</span>
                                      </div>

                                      <div className="d-flex align-items-center">
                                        <i className="bullet bullet-danger mr-50" />
                                        <span className=" ml-50" style={{fontSize: '12px', fontWeight: 300, color: '#2f4475'}}>Ovderdue</span>
                                      </div>

                                   <div className="d-flex align-items-center">
                                        <i className="bullet bullet-primary mr-50" />
                                        <span className=" ml-50" style={{fontSize: '12px', fontWeight: 300, color: '#2f4475'}}>Review</span>
                                      </div>
                                  


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                
                
                </div>
                
                
                
                <div className="col-md-4 border fourth-third-section">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-12 mt-2" id="top-section">
                      <div style={{float: 'left', marginLeft: '15px'}}>
                        <h5 className="left-head">Recent Call History</h5>
                      </div>
                      <div style={{float: 'right', marginTop: '-2px'}}>
                        <a title="Reply" data-toggle="modal" data-target="#modal-City">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="row p-1">
                    <div className="col-md-12 recent-call-section d-flex">
                      <div className="avatar mr-75">
                        <span className="avatar-status-online" />
                        <img src="./images/usericon.png" alt="avtar images" style={{ 'width' : '45px' , 'height' : '45px' }} />
                      </div>
                      <div className="align-items-center" style={{lineHeight: '14px', marginTop: '11px', width: '140px'}}>
                        <span style={{fontSize: '13px', color: '#575756'}}>Marie Jane</span><br />
                        <span style={{fontSize: '10.5px', color: '#b2b2b2'}}>Outgoing 10:12pm</span>
                      </div>
                      <div className="align-items-center" style={{lineHeight: '14px', marginTop: '11px'}}>
                        <img src="./images/call-button.png" style={{width: '20px', verticalAlign: 'baseline'}} />
                        <i className="bx bx-chevron-right" style={{color: '#C5C5C5', fontSize: '28px'}} />
                      </div>
                    </div>
                    <div className="col-md-12 recent-call-section d-flex">
                      <div className="avatar mr-75">
                        <span className="avatar-status-online" />
                        <img src="./images/usericon.png" alt="avtar images" style={{ 'width' : '45px' , 'height' : '45px' }} />
                      </div>
                      <div className="align-items-center" style={{lineHeight: '14px', marginTop: '11px', width: '140px'}}>
                        <span style={{fontSize: '13px', color: '#575756'}}>Benny Varghese</span><br />
                        <span style={{fontSize: '10.5px', color: '#b2b2b2'}}>Missed 08:12pm</span>
                      </div>
                      <div className="align-items-center" style={{lineHeight: '14px', marginTop: '11px'}}>
                        <img src="./images/video-call.png" style={{width: '20px', verticalAlign: 'baseline'}} />
                        <i className="bx bx-chevron-right" style={{color: '#C5C5C5', fontSize: '28px'}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-lg-3 dashboard-reminder">
              <div className="row border right-section-first" style={{minHeight: '265px'}}>
                <p id="inlineDatePicker-container" />
                <input className="inlineDatePicker" type="hidden" />
              </div>
              <div className="row border right-section-second p-1" >
                <div className="col-md-12 col-lg-12 col-xl-12 mt-2" id="top-section">
                  <div style={{float: 'left', marginLeft: '15px'}}>
                    <h5 className="left-head">Reminders</h5>
                  </div>
                  <div style={{float: 'right', marginTop: '-2px'}}>
                    <a title="Reply" data-toggle="modal" data-target="#modal-City">
                      <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                    </a>
                  </div>
                </div>
                <div className="col-md-12 recent-call-section d-flex p-1">
                  <div className="avatar mr-75">
                    <img src="./images/usericon.png" alt="avtar images" style={{ 'width' : '45px' , 'height' : '45px' }} /><br />
                    <span style={{fontSize: '12px', color: '#3c3c3b'}}>Sarah Norfal</span>
                  </div>
                  <div style={{height: '60px', width: '100%'}}>
                    <span style={{fontSize: '12px', color: '#575756'}}>Data Science Marketing</span>
                    <div className="d-flex">
                      <ul className="list-unstyled users-list m-0  d-flex align-items-center">
                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Lai Lewandowski" className="avatar pull-up">
                          <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ 'width' : '25px' , 'height' : '25px' }}/>
                        </li>
                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Elicia Rieske" className="avatar pull-up">
                          <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ 'width' : '25px' , 'height' : '25px' }}/>
                        </li>
                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Darcey Nooner" className="avatar pull-up">
                          <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ 'width' : '25px' , 'height' : '25px' }} />
                        </li>
                      </ul>
                      <ul className="list-unstyled users-list m-0  d-flex align-items-center">
                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Lai Lewandowski" className="avatar pull-up">
                          <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ 'width' : '25px' , 'height' : '25px' }} />
                        </li>
                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Elicia Rieske" className="avatar pull-up">
                          <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ 'width' : '25px' , 'height' : '25px' }}/>
                        </li>
                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Darcey Nooner" className="avatar pull-up">
                          <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ 'width' : '25px' , 'height' : '25px' }} />
                        </li>
                        <li className="avatar pull-up">
                          <span className="badge badge-pill badge-light-primary badge-round">+5</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 recent-call-section d-flex p-1">
                  <div className="avatar mr-75">
                    <img src="./images/usericon.png" alt="avtar images"  style={{ 'width' : '45px' , 'height' : '45px' }}/><br />
                    <span style={{fontSize: '12px', color: '#3c3c3b'}}>Sarah Norfal</span>
                  </div>
                  <div style={{height: '60px', width: '100%'}}>
                    <span style={{fontSize: '12px', color: '#575756'}}>Project Development</span>
                    <div className="d-flex">
                      <ul className="list-unstyled users-list m-0  d-flex align-items-center">
                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Lai Lewandowski" className="avatar pull-up">
                          <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ 'width' : '25px' , 'height' : '25px' }}/>
                        </li>
                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Elicia Rieske" className="avatar pull-up">
                          <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ 'width' : '25px' , 'height' : '25px' }} />
                        </li>
                        <li className="avatar pull-up">
                          <span className="badge badge-pill badge-light-primary badge-round">+5</span>
                        </li>
                      </ul>
                      <ul className="list-unstyled users-list m-0  d-flex align-items-center">
                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Lai Lewandowski" className="avatar pull-up">
                          <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ 'width' : '25px' , 'height' : '25px' }}/>
                        </li>
                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Elicia Rieske" className="avatar pull-up">
                          <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ 'width' : '25px' , 'height' : '25px' }} />
                        </li>
                        <li className="avatar pull-up">
                          <span className="badge badge-pill badge-light-primary badge-round">+5</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row border right-section-third p-1" style={{minHeight: '250px'}}>
                <div className="col-md-12 col-lg-12 col-xl-12 mb-1" id="top-section">
                  <div style={{float: 'left', marginLeft: '15px'}}>
                    <h5 className="left-head">Messenger</h5>
                  </div>
                  <div style={{float: 'right', marginTop: '-2px'}}>
                    <a title="Reply" data-toggle="modal" data-target="#modal-City">
                      <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                    </a>
                  </div>
                </div>
                <div className="col-md-12 recent-call-section d-flex">
                  <div className="avatar mr-75">
                    <span className="avatar-status-online" />
                    <img src="./images/usericon.png" alt="avtar images" style={{ 'width' : '45px' , 'height' : '45px' }} />
                  </div>
                  <div className="align-items-center" style={{lineHeight: '14px', marginTop: '11px', width: '140px'}}>
                    <span style={{fontSize: '13px', color: '#575756'}}>Christina</span><br />
                    <span style={{fontSize: '10.5px', color: '#b2b2b2'}}>Let's do it...</span>
                  </div>
                  <div className="align-items-center" style={{lineHeight: '14px', marginTop: '15px'}}>
                    <span style={{fontSize: '12px', color: '#999'}}>07:00 PM</span>
                  </div>
                </div>
                <div className="col-md-12 recent-call-section d-flex">
                  <div className="avatar mr-75">
                    <span className="avatar-status-online" />
                    <img src="./images/usericon.png" alt="avtar images" style={{ 'width' : '45px' , 'height' : '45px' }} />
                  </div>
                  <div className="align-items-center" style={{lineHeight: '14px', marginTop: '11px', width: '140px'}}>
                    <span style={{fontSize: '13px', color: '#575756'}}>James</span><br />
                    <span style={{fontSize: '10.5px', color: '#b2b2b2'}}>see you</span>
                  </div>
                  <div className="align-items-center" style={{lineHeight: '14px', marginTop: '15px'}}>
                    <span style={{fontSize: '12px', color: '#999'}}>07:00 PM</span>
                  </div>
                </div>
                <div className="col-md-12 recent-call-section d-flex">
                  <div className="avatar mr-75">
                    <span className="avatar-status-online" />
                    <img src="./images/usericon.png" alt="avtar images" style={{ 'width' : '45px' , 'height' : '45px' }} />
                  </div>
                  <div className="align-items-center" style={{lineHeight: '14px', marginTop: '11px', width: '140px'}}>
                    <span style={{fontSize: '13px', color: '#575756'}}>Maria</span><br />
                    <span style={{fontSize: '10.5px', color: '#b2b2b2'}}>me too thanks</span>
                  </div>
                  <div className="align-items-center" style={{lineHeight: '14px', marginTop: '15px'}}>
                    <span style={{fontSize: '12px', color: '#999'}}>07:00 PM</span>
                  </div>
                </div>

                <div className="col-md-12 recent-call-section d-flex">
                  <div className="avatar mr-75">
                    <span className="avatar-status-online" />
                    <img src="./images/usericon.png" alt="avtar images" style={{ 'width' : '45px' , 'height' : '45px' }} />
                  </div>
                  <div className="align-items-center" style={{lineHeight: '14px', marginTop: '11px', width: '140px'}}>
                    <span style={{fontSize: '13px', color: '#575756'}}>Jane</span><br />
                    <span style={{fontSize: '10.5px', color: '#b2b2b2'}}>me too thanks</span>
                  </div>
                  <div className="align-items-center" style={{lineHeight: '14px', marginTop: '15px'}}>
                    <span style={{fontSize: '12px', color: '#999'}}>07:00 PM</span>
                  </div>
                </div>
              </div>
            </div>
          </div>




             {/* <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <button
                  style={{ margin: "10px" }}
                  className="btn btn-primary"
                  onClick={() => this.resetLayout()}
                >
                  Reset Layout
                </button>

                <div className="row dash-blue fixed-top" id="bluetop">
                  <div className="col-md-1"></div>
                  <div className="col-md-3 col-lg-3 col-12" id="dashboard-layout">
                    <div>
                      <div className="inner-section">
                        <h3
                          style={{
                            fontFamily: "myriad-pro,sans-serif !important",
                            fontWeight: 600,
                            marginTop: "10px",
                          }}
                        >
                          Dashboard
                        </h3>
                        <div style={{ marginTop: "-10px" }}>
                          <p className="mt-1"></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-3 col-lg-3">
                    <div className="d-flex mt-4">
                      <div style={{ marginLeft: "20px" }}>
                        <a href="#" data-action="expand" onClick={this.onRefreshClick}>
                          <img
                            src="./images/icon/dashboard-icons/module-icons/white icons-28.png"
                            
                            style={{ cursor: "pointer" , height:'25px' , width:'25px' }}
                            data-toggle="tooltip"
                            data-title="Refresh Window"
                            data-placement="top"
                          />
                        </a>
                      </div>

                      <div style={{ marginLeft: "20px" }}>
                        <a href="#" data-toggle="modal" data-target="#default">
                          <img
                            src="./images/icon/dashboard-icons/module-icons/white icons-38.png"
                            style={{ cursor: "pointer" , height:'25px' , width:'25px'  }}
                            data-toggle="tooltip"
                            data-title="Download"
                            data-placement="top"
                          />
                        </a>
                      </div>

                      <div style={{ marginLeft: "20px" }}>
                        <a href="#">
                          <img
                            src="./images/icon/dashboard-icons/module-icons/white icons-37.png"
                            style={{ cursor: "pointer" , height:'25px' , width:'25px'  }}
                            data-toggle="tooltip"
                            data-title="Share Screen"
                            data-placement="top"
                          />
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-5 col-lg-5 col-12">
                    <div className="searchBox">
                      <input
                        className="searchInput"
                        type="text"
                        name=""
                        placeholder="Search Here . . ."
                      />
                      <button className="searchButton" href="#">
                        <img
                          src="./images/icon/dashboard-icons/white icons-39.png"
                          style={{ height: "25px" }}
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <ul className="nav nav-pills">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="pill"
                      href="#home"
                      aria-expanded="true"
                    >
                      Primary Dashboard
            </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      id="profile-tab"
                      data-toggle="pill"
                      href="#profile"
                      aria-expanded="false"
                    >
                      Charts Dashboard
            </a>
                  </li>
                </ul>

                <div className="tab-content">
                  <div
                    role="tabpanel"
                    className="tab-pane active"
                    id="home"
                    aria-labelledby="home-tab"
                    aria-expanded="true"
                  >
                    <ResponsiveReactGridLayout
                      className="layout"
                      cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                      rowHeight={30}
                      layouts={this.state.layouts}
                      onLayoutChange={(layout, layouts) =>
                        this.onLayoutChange(layout, layouts)
                      }
                    >
                      <div
                        key="1"
                        data-grid={{
                          w: 12,
                          h: 3,
                          x: 0,
                          y: 0,
                          minW: 12,
                          minH: 4,
                          maxH: 4,
                        }}
                      >
                        <AllTaskWidget
                          ShowTasksCountsWidgetValue={ShowTasksCountsWidgetValue}
                          ShowAllTasksValue={ShowAllTasksValue}
                          totalTasksCount={totalTasksCount}
                          ShowOngoingTaskCountValue={ShowOngoingTaskCountValue}
                          onGoingTasksCount={onGoingTasksCount}
                          ShowOverdueTasksValue={ShowOverdueTasksValue}
                          overdueTasksCount={overdueTasksCount}
                          ShowReviewTasksValue={ShowReviewTasksValue}
                          reviewTasksCount={reviewTasksCount}
                          ShowUnreadTaskValue={ShowUnreadTaskValue}
                          unreadTasksCount={unreadTasksCount}
                          ShowFavoritesTaskValue={ShowFavoritesTaskValue}
                          favoriteTasksCount={favoriteTasksCount}
                          ShowRecentTasksValue={ShowRecentTasksValue}
                          recentTasksCount={recentTasksCount}
                        />
                      </div>
                      <div
                        key="2"
                        data-grid={{
                          w: 12,
                          h: 5,
                          x: 2,
                          y: 0,
                          minW: 12,
                          minH: 5,
                          maxH: 10,
                        }}
                      >
                        <AllTaskTableWidget
                          ShowAllTasksWidgetValue={ShowAllTasksWidgetValue}
                          ShowABValue={ShowABValue}
                          ShowTaskValue={ShowTaskValue}
                          ShowProjectValue={ShowProjectValue}
                          ShowCategoryValue={ShowCategoryValue}
                          ShowPriorityValue={ShowPriorityValue}
                          ShowDueDateValue={ShowDueDateValue}
                          ShowStatusValue={ShowStatusValue}
                          ShowTotalMessagesValue={ShowTotalMessagesValue}
                          ShowAssignessValue={ShowAssignessValue}
                          getAllTasks={getAllTasks}
                        />
                      </div>
                      <div
                        key="3"
                        data-grid={{
                          w: 4,
                          h: 8,
                          x: 0,
                          y: 0,
                          minW: 4,
                          minH: 4,
                          maxW: 4,
                          maxH: 8,
                        }}
                      >
                        <RecentActiveworkspaceWidget
                          ShowRecentActiveWorkSpaceWidgetValue={
                            ShowRecentActiveWorkSpaceWidgetValue
                          }
                          lastUpdated_10_workspace={lastUpdated_10_workspace}
                          WorkSpaceTasksCount={WorkSpaceTasksCount}
                        />
                      </div>
                      <div
                        key="4"
                        data-grid={{
                          w: 8,
                          h: 8,
                          x: 0,
                          y: 10,
                          minW: 8,
                          minH: 8,
                          maxW: 8,
                          maxH: 8,
                        }}
                      >
                        <RecentActiveFolderWidget
                          ShowRecentActiveFoldersWidgetValue={
                            ShowRecentActiveFoldersWidgetValue
                          }
                        />
                      </div>
                      <div
                        key="5"
                        data-grid={{
                          w: 4,
                          h: 8,
                          x: 0,
                          y: 4,
                          minW: 4,
                          minH: 4,
                          maxW: 4,
                          maxH: 8,
                        }}
                      >
                        <ShowAttendanceMgmWidget
                          ShowAttendanceManagementWidgetValue={
                            ShowAttendanceManagementWidgetValue
                          }
                        />
                      </div>
                      <div
                        key="6"
                        data-grid={{
                          w: 4,
                          h: 8,
                          x: 4,
                          y: 4,
                          minW: 4,
                          minH: 4,
                          maxW: 4,
                          maxH: 8,
                        }}
                      >
                        <TaskDistributionWidget
                          ShowTaskDistributionWidgetValue={
                            ShowTaskDistributionWidgetValue
                          }
                        />
                      </div>
                      <div
                        key="7"
                        data-grid={{
                          w: 4,
                          h: 8,
                          x: 8,
                          y: 2,
                          minW: 4,
                          minH: 4,
                          maxW: 4,
                          maxH: 8,
                        }}
                      >
                        <ShowRecentCallHistoryWidget
                          ShowRecentCallHistoryWidgetValue={
                            ShowRecentCallHistoryWidgetValue
                          }
                        />
                      </div>
                      <div
                        key="8"
                        data-grid={{
                          w: 4,
                          h: 8,
                          x: 10,
                          y: 2,
                          minW: 4,
                          minH: 4,
                          maxW: 4,
                          maxH: 8,
                        }}
                      >
                        <ReminderMessangerWidget
                          ShowMessangerWidgetValue={ShowMessangerWidgetValue}
                        />
                      </div>
                      <div
                        key="9"
                        data-grid={{
                          w: 4,
                          h: 8,
                          x: 4,
                          y: 4,
                          minW: 4,
                          minH: 4,
                          maxW: 4,
                          maxH: 8,
                        }}
                      >
                        <ReminderWidget
                          ShowReminderWidgetValue={ShowReminderWidgetValue}
                        />
                      </div>
                    </ResponsiveReactGridLayout>
                  </div>

                  <div
                    className="tab-pane"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                    aria-expanded="false"
                  >
                    <DashBoardCharts></DashBoardCharts>
                  </div>
                </div>
              </div>

            </div> */}
          </div>
        </div> 

      </>
    );
  }
}

function getFromLS(key) {
  let ls = {};
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem("rgl-8")) || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function removeForLS(key, value) {
  if (global.localStorage) {
    global.localStorage.removeItem("rgl-8");
  }
}

function saveToLS(key, value) {
  if (global.localStorage) {
    global.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}
