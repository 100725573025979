import React from 'react';
import { connect } from 'react-redux';
import { changeBoardItem, fetchError } from '../../../../../actions'
import StarRatings from 'react-star-ratings';

import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../../util/permission';


class RatingItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rating: 0,
            hover: false
        }
    }

    componentDidMount() {
        this.setState({ rating: Number(this.props.body) });
        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem.cellItems;
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }
    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem.cellItems;
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    saveItem = (body) => {
        this.props.changeBoardItem({
            groupInd: this.props.groupInd,
            rowInd: this.props.rowInd,
            colInd: this.props.colInd,
            body: body,
            cellItemID: this.props.columnId,
            boardId: this.props.boardData.boardID
        })
    }

    changeRating = (newRating, name) => {
        if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
            this.setState({
                rating: Number(newRating)
            });
            this.saveItem(Number(newRating));
        } else {
            this.props.fetchError("You don't have permission. You can only view")
        }
    }

    removeCurrentRating = () => {
        if (this.state.rating !== 0) {
            this.setState({ rating: 0 })
            this.saveItem(Number(0));
        }
    }

    render() {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{ height: '35px', position: 'relative' , 'border' : '1px solid #EAEAEA' ,    ' border-top' : "none" ,
            'border-bottom' : 'none'  }}
                onMouseEnter={() => this.setState({ hover: true })} onMouseLeave={() => this.setState({ hover: false })}>
                <div>
                    <StarRatings
                        rating={this.state.rating}
                        starRatedColor="#fdab3d"
                        starHoverColor='#fdab3d'
                        changeRating={this.changeRating}
                        numberOfStars={5}
                        name='rating'
                        starDimension='18px'
                        starSpacing='2px'
                    />
                </div>
                {this.state.hover && (
                    <div style={{ position: 'absolute', top: '50%', right: '4px', transform: 'translateY(-50%)' }}>
                        <img src="./images/icon/common/close.svg" style={{ width: '16px', cursor: 'pointer' }}
                            onClick={() => {
                                if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                                    this.removeCurrentRating()
                                } else {
                                    this.props.fetchError("You don't have permission. You can only view")
                                }
                            }}></img>
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeBoardItem, fetchError }

export default connect(mapStateToProps, mapDispatchToProps)(RatingItem)