// import http from "../http-common";

// class SchedulerDataService {
//   getEvents(data) {
//     return http.post("events/getEvents",data);
//   }

//   updateEvent(data) {
//     return http.put("events/updateEvent",data);
//   }

//   getEventCategories() {
//     return http.get("events/getEventCategories");
//   }

//   getReminderList(data) {
//     return http.post("events/getReminderList",data);
//   }
//   getCategoryWiseCount(data) {
//     return http.post("events/getCategoryWiseCount",data);
//   }
//   getCategoryWiseCount(data) {
//     return http.post("events/getCategoryWiseCount",data);
//   }

//   getDownloadData(data){
//     return http.post("events/getDownloadData",data);
//   }
//   getDownloadFile(data){
//     return http.post("events/getDownloadFile",data);
//   }

// }

// export default new SchedulerDataService();

export const  getEvents = "events/getEvents"; 
export const  updateEvent = "events/updateEvent"; 
export const  getEventCategories = "events/getEventCategories"; 
export const  getReminderList = "events/getReminderList"; 
export const  getCategoryWiseCount = "events/getCategoryWiseCount" ; 
export const  getDownloadData = "events/getDownloadData" ; 
export const  getDownloadFile = "events/getDownloadFile" ;

export default {
  getEvents : getEvents , 
  updateEvent : updateEvent , 
  getEventCategories : getEventCategories , 
  getReminderList : getReminderList , 
  getCategoryWiseCount : getCategoryWiseCount , 
  getDownloadData : getDownloadData , 
  getDownloadFile : getDownloadFile 
}