import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import React from 'react';
import { connect } from 'react-redux'
import axios from '../../../../../util/Api';
import { createImageFromInitials, getRandomColor, validateEmail } from '../../../../../util/People';
import { showMessage, fetchError, updateWorkspaceBoard, updateWorkspaceBoardMembers,changeWorkspaceBoard } from '../../../../../actions';
import { ToastContainer, toast } from 'react-toastify';
class WorkspaceMembersPopup extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            people: [],
            allPeople: [],
            showPeople: false,
            openEdit: false,
            boardMembers: [],
            searchText: '',
            inviteInputEmail: '',
            workspaceMembers: [],
            workspaceAdmins: [],
            isWorkspaceOwner: false,
            isWorkspaceAdmin: false,
            allWorkspaceBoards: this.props.workspaceData.workspaceBoards
        }

        this.timeout = 0
        this.modalContainer = React.createRef();
        this.dropdownContainer = React.createRef();
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.showPeople && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ showPeople: false });
        }

        if (this.state.openEdit && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ showPeople: true, openEdit: false });
        }
    }
    componentWillMount() {
        axios.get('/workspace/getWorkSpaceById/', {
            params: { id: this.props.workspaceData._id }
        }).then(({ data }) => {
         
         
            let workspace = data.workspaces;
            // let members = workspace.members;
            //  let members = this.getFilteredUserLists(workspace.members);
            let filteredmembers = this.getFilteredUserLists(workspace.members);
            this.setState({ workspaceMembers: filteredmembers });
            this.setState({ workspaceAdmins: this.props.workspaceData.admins })
      
        }).catch(err => {
            console.log("Error: ", err)
        })


        axios.get('/users/getAllUsers').then(({ data }) => {

            this.setState({ people: data, allPeople: data })
        }).catch(err => {
            console.log("Error: ", err)
        })
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.onClickOutsideHandler);

        let boardMembers = this.props.workspaceData.members ? this.props.workspaceData.members : [];
        this.setState({ boardMembers: boardMembers })

        if (this.authUser._id == this.props.workspaceData.CreatedBy) {
            this.setState({ isWorkspaceOwner: true })
            this.setState({ isWorkspaceAdmin: true })
        }
        if (this.props.workspaceData.admins.includes(this.authUser._id)) {
            this.setState({ isWorkspaceAdmin: true })
        }

        // this.getAllNestedWorkspaceBoards();

    }
 

    componentWillReceiveProps(nextProps) {
       
        if (JSON.stringify(this.props.workspaceData.workspaceBoards) !== JSON.stringify(nextProps.workspaceData.workspaceBoards)) {
            this.setState({ allWorkspaceBoards: nextProps.workspaceData.workspaceBoards })
 
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickOutsideHandler);
    }
    getUserLists = (members) => {
        let newMembers = [];
        for (let i = 0; i < members.length; i++) {
            let member = {
                _id: members[i]._id,
                name: members[i].firstname ? `${members[i].firstname} ${members[i].lastname}` : '',
                email: members[i].email,
                color: members[i].color ? members[i].color : getRandomColor()
            }

            newMembers.push(member)
        }
        return newMembers;
    }

    getFilteredUserLists = (members) => {
        let newMembers = [];
        for (let i = 0; i < members.length; i++) {
            let member = {
                _id: members[i]._id,
                name: members[i].firstname ? `${members[i].firstname} ${members[i].lastname}` : '',
                email: members[i].email,
                color: members[i].color ? members[i].color : getRandomColor()
            }

            newMembers.push(member)
        }
        return newMembers;
    }

    addPeople = (people) => {
        let members = this.state.boardMembers;

        let checkMember = members.find(member => member._id === people._id);
        if (checkMember === undefined) {
            let authUser = JSON.parse(localStorage.getItem('authUser'));
            people.permission = authUser._id === people._id ? 'full' : 'view';
            people.name = people.firstname + ' ' + people.lastname;
            members.push(people);
            let message = `${people.name} subscribed to board!`;
            let name = people.name !== '' ? people.name : people.email;
            this.setState({ boardMembers: members, showPeople: false });

            console.log('before', this.state.workspaceMembers)
            let workspacemembers = this.state.workspaceMembers
            workspacemembers.push(people);
            this.setState({ workspaceMembers: this.getFilteredUserLists(workspacemembers) })
            console.log('before1', this.state.workspaceMembers)
            this.updateBoardMembers(members, message, name, this.state.workspaceMembers)
        }
    }

    updateBoardMembers = (members, message, peopleName, newMembersToAdd,isRemovePeople,removeid) => {
        console.log('members1', members);

        let workspaceID = this.props.workspaceData._id
        axios.post('/workspace/updateBoardMembers', {
            members: members,
           // workspaceBoardId: this.props.boardData.boardID,
            workspaceId: workspaceID,
            message: message,
            peopleName: peopleName,
            newMembersToAdd: newMembersToAdd,
            isUpdateWorkspaceMembers: true,
            isRemovePeople:isRemovePeople,
            removeid:removeid
        }).then(({ data }) => {
        
            // this.props.changeBoardMembers({
            //     workspaceBoard: data.workspaceBoard
            // })

            this.props.changeWorkspaceBoard({
                id: this.props.boardData.boardID,
                workspaceID: workspaceID,
                workspace: this.props.workspace
            })
        }).catch(err => {
            this.props.fetchError(err)
        })
    }

    getAllNestedWorkspaceBoards = () => {

        for (let k = 0; k < this.state.allWorkspaceBoards.length; k++) {
            var x = []
            x.push(this.state.allWorkspaceBoards[k])
        }
      
        for (let i = 0; i < this.props.workspaceData.subworkspaces.length; i++) {

             x = [...x, ...this.props.workspaceData.subworkspaces[i].workspaceBoards];

            for (let j = 0; j < this.props.workspaceData.subworkspaces[i].subworkspaces.length; j++) {

                x = [...x, ...this.props.workspaceData.subworkspaces[i].subworkspaces[j].workspaceBoards];

            }
        }
        console.log('getAllNestedWorkspaceBoards', x)
        return x;
    }
    pushAdminToBoards = (member) => {
       
        let allBoards = this.getAllNestedWorkspaceBoards()
        for (let i = 0; i < allBoards.length; i++) {
            this.updateBoardAdminStatus(allBoards[i], member)
        }
    }
    updateBoardAdminStatus = (board, people) => {

        // if(!this.state.isWorkspaceAdmin){
        //     this.props.fetchError("You don't have permission to add or remove board admins") 
        //     return;
        // }
  
        let members = JSON.parse(board.members);

        let Index = members.findIndex(member => member._id === people._id);

        if (Index == -1) {
            members.push(people);
        } else {
          //   members.push(people);
          //   members.pop(people);
          //  members[Index].push(people);
        }
        this.updateNestedBoardAdminMembers(members, people.name + ' is now a workspace and board admin', people.name, board)
    }
    updateNestedBoardAdminMembers = (members, message, peopleName, board) => {
        console.log('members1', members);

        this.props.updateWorkspaceBoardMembers({
            members: JSON.stringify(members),
            workspaceBoardId: board._id,
            workspaceId: this.props.workspaceData._id,
            message: message
        });
        return;
        let workspaceID = this.props.workspaceData._id
        axios.post('/workspace/updateBoardMembers', {
            members: members,
            workspaceBoardId: board._id,
            workspaceId: workspaceID,
            message: message,
            peopleName: peopleName,
            
        }).then(({ data }) => {
            // this.props.updateWorkspaceBoard({
            //     workspaceBoard: data.workspaceBoard,
            //     workspaceID: workspaceID
            // })
        }).catch(err => {
            this.props.fetchError(err)
        })
    }


    inviteByEmailToBoard = () => {
        let email = this.state.inviteInputEmail;
        if (email !== '') {
            let match = validateEmail(email);
            if (match) {
                this.props.closeModal(false);
                axios.post('workspace/workspaceboardMemberInviteByEmail', {
                    workspaceId: this.props.workspaceData._id,
                    boardId: this.props.boardData.boardID,
                    email: email,
                    color: getRandomColor(),
                    timezoneOffset: new Date().getTimezoneOffset()
                }).then(({ data }) => {
                    this.setState({ openEdit: false })
                    console.log("data:", data)
                }).catch(err => {
                    this.props.fetchError(err)
                })
            }
        }
    }

    removePeople = (people) => {
        if (!this.state.isWorkspaceAdmin) {

            toast.error("You don't have permission to add or remove workspace members.");
            return;
        }

        if (this.props.workspaceData.admins.includes(people._id) && !this.state.isWorkspaceOwner) {
            toast.error("You don't have permission to remove workspace Admins.");
            return;
        }

        let members = this.state.boardMembers;
        if (members.length > 1) {
            let authUser = JSON.parse(localStorage.getItem('authUser'));
            if (authUser._id === people._id) {
                this.props.fetchError('You can not remove your permission!')
            } else {
                
                let removeIndex = members.findIndex(member => member._id === people._id);
                const { removeItem, } = members.splice(removeIndex, 1);
                people.name = people.firstname;
                let message = `${people.name} unsubscribed from board!`;
                let name = people.name !== '' ? people.name : people.email;
                this.setState({ boardMembers: members })
                console.log('before', this.state.workspaceMembers)
                let workspacemembers = this.state.workspaceMembers
                //workspacemembers.pop(people._id);
                
                for (let i = 0; i < workspacemembers.length; i++) {
                    if (workspacemembers[i]._id == people._id) workspacemembers.splice(i, 1);
                  }

                // for (var i = workspacemembers.length - 1; i >= 0; i--) {
                //     if (workspacemembers[i] == people._id) workspacemembers.splice(i, 1);
                // }

                this.setState({ workspaceMembers: workspacemembers })
                if(this.state.workspaceAdmins.includes(people._id)){
                    this.updateAdminStatus(people._id, people,true)
                }
                this.updateBoardMembers(members, message, name, this.state.boardMembers, true, people._id);
                
            }

        } else {
            this.props.fetchError('Can not remove!')
        }
    }

    searchMember = (event) => {
        let peoples = this.state.allPeople;
        if (this.state.searchText !== '') {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                let users = peoples.filter(user => {
                 
                    let username = user.email;
                    let search = username.toLowerCase().search(this.state.searchText.toLowerCase());
                    if (search !== -1) {
                        return true;
                    } else {
                        return false
                    }
                });
                this.setState({ people: users })
            }, 300);
        } else {
            this.setState({ people: peoples })
        }
    }

    updateAdminStatus = (memberID, member,isPop) => {

        if (!this.state.isWorkspaceOwner) {
            toast.error("You don't have permission to add or remove Workspace admins.");
            return;
        }

        let adminList = this.state.workspaceAdmins ? this.state.workspaceAdmins : [];

        if (adminList.includes(memberID)) {
         //adminList.pop(memberID)
         
         for (let i = 0; i < adminList.length; i++) {
            if (adminList[i] == memberID) adminList.splice(i, 1);
          }
        } else {
            adminList.push(memberID)
        }

        if(isPop){
            //adminList.pop(memberID)
            for (let i = 0; i < adminList.length; i++) {
                if (adminList[i] == memberID) adminList.splice(i, 1);
              }
            // for (var i = adminList.length - 1; i >= 0; i--) {
            //     if (adminList[i] == memberID) adminList.splice(i, 1);
            // }
        }

        axios.post('/workspace/updateBoardMembers', {
            admins: adminList,
            workspaceId: this.props.workspaceData._id,
            isUpdateadmins: true
        }).then(({ data }) => {
            console.log('update', data);

            this.setState({ workspaceAdmins: data.admins.admins });
            if(data.admins.admins.includes(memberID)){
                this.pushAdminToBoards(member);
            }
           
        }).catch(err => {
            this.props.fetchError(err)
        })

    }



    checkIfAlreadyWmember = (memberID) => {

        const Obj = this.state.workspaceMembers.find(workspaceMembers => workspaceMembers._id == memberID)
        if (Obj) {
            return false
        } else {
            return true;
        }
    }

    render() {
     
        return (
            <div>
                <Modal isOpen={this.props.modalOpen} ref={this.modalContainer} className='right modal-dialog-centered modal-lg' data-backdrop="true">
                    <ModalHeader toggle={() => this.props.closeModal(false)}>
                        Workspace Members
                    </ModalHeader>
                    <ModalBody>
                        <div className='w-100 p-3' style={{ minHeight: '300px' }}>
                            <div className='w-100' ref={this.dropdownContainer}>
                                {!this.state.openEdit ? (
                                    <div className='w-100'>
                                        {this.state.isWorkspaceAdmin && <input className="form-control w-100"
                                        autoComplete="off"
                                            placeholder="Enter name or email"
                                            onClick={() => this.setState({ showPeople: true })}
                                            value={this.state.searchText}
                                            onChange={(event) => this.setState({ searchText: event.target.value })}
                                            onKeyUp={() => this.searchMember()}
                                        />}
                                        {this.state.showPeople ? (
                                            <div className='w-100 p-2' style={{ display: !this.state.showPeople ? 'none' : '', border: '1px solid #333333', borderTopWidth: '0', borderRadius: '4px', height: '400px', overflow: 'auto' }}>
                                                {/* <div className='w-100 text-center pt-1'>--------------- People ---------------</div> */}
                                                <div className='w-100 dropdown-label-suggest'>
                                                    {this.state.people
                                                        .filter(people => this.checkIfAlreadyWmember(people._id))
                                                        .map((people, index) => (
                                                            <div className="pt-1 pb-1" key={index}>
                                                                <div className="dropdown-label-item" onClick={() => this.addPeople(people)}>
                                                                    {`${people.name && people.name !== '' ? people.name : people.email}`}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    <button type='button' className="btn btn-add w-100 d-flex align-items-center text-left"
                                                        onClick={() => this.setState({ openEdit: true })} >
                                                        <i className='fa fa-envelope-o' style={{ top: '1px' }}></i> &nbsp; Invite a new member by email
                                            </button>
                                                </div>
                                            </div>
                                        ) : ''}
                                    </div>
                                ) : (
                                        <div>
                                            <input className="form-control w-100"
                                                placeholder="Enter email address"
                                                value={this.state.inviteInputEmail}
                                                onChange={(event) => this.setState({ inviteInputEmail: event.target.value })}
                                            />
                                            <div className='w-100 p-2' style={{ display: !this.state.showPeople ? 'none' : '', border: '1px solid #333333', borderTopWidth: '0', borderRadius: '4px' }}>

                                                <div className='w-100 dropdown-label-suggest'>
                                                    <div className='w-100 p-1'>Type in an email address to invite</div>
                                                    <button type='button' className="btn btn-add w-100 d-flex align-items-center text-left"
                                                        onClick={() => this.inviteByEmailToBoard()} >
                                                        <i className='fa fa-envelope-o' style={{ top: '1px' }}></i> &nbsp; Invite
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            </div>
                            {!this.state.showPeople ? (
                                <div className='w-100'>
                                    <div className='w-100 p-1'>
                                        {this.state.workspaceMembers.map((member, index) => (
                                            <div className='d-flex w-100 py-2 border-bottom align-items-center' key={index} style={{ justifyContent: 'space-between' , textTranform : 'lowercase !important' }}>
                                                {member.name && member.name !== '' ? (
                                                    <img src={createImageFromInitials(28, member.name, member.color ? member.color : '#13a3e3')} style={{ borderRadius: '50%' , 'width' : '25px' , 'height' : '25px' }} />
                                                ) : (
                                                        <img src="/images/users/dark-user.png" style={{ borderRadius: '50%', width: '28px' }} />
                                                    )}

                                                <div className='w-100 pl-2 pr-2'>{member.name !== '' ? member.name : member.email} - {member.email}</div>

                                                {member._id == this.props.workspaceData.CreatedBy &&
                                                    <div
                                                        className="data-title"
                                                        data-title={`Owner`}
                                                    >
                                                        <i className='fal fa-user fa-lg'></i>
                                                    </div>
                                                }
                                                
                                                {member._id != this.props.workspaceData.CreatedBy &&
                                                    <div data-title={(this.state.workspaceAdmins.includes(member._id)) ? 'Remove Admin' : 'Make Admin'}
                                                        className={(this.state.workspaceAdmins.includes(member._id)) ? 'text-success data-title mr-3 cursor-pointer' : 'text-black-50 data-title mr-3 cursor-pointer'}
                                                        onClick={() => this.updateAdminStatus(member._id, member)}
                                                    >
                                                        <i className='fa fa-universal-access fa-lg'></i>
                                                    </div>

                                                }
                                                {member._id != this.props.workspaceData.CreatedBy && <div
                                                    className="border-member-close data-title"
                                                    data-title={`Remove ${member.name ? member.name : member.email} from this workspace`}
                                                    onClick={() => this.removePeople(member)}
                                                >
                                                    <i className='fa fa-close'></i>
                                                </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : ''}

                        </div>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { showMessage, fetchError, updateWorkspaceBoard, updateWorkspaceBoardMembers,changeWorkspaceBoard }

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceMembersPopup);