import React from 'react';
import Form from '../common/Form.jsx'

class Modal extends Form {
  render() {
    const { selectedOption, options, handleChange, Name, onChangeStateName, SaveOrUpdate, errorStateName, errorStateCountryId, isUpdate } = this.props;
    return (
      <div className="modal fade" id="modal-Country">
        <div className="modal-dialog modal-lg modal-plus">
          <div className="modal-content">
            {this.renderModalHeaderTitle("Add State Details")}
            <div className="modal-body" style={{ marginLeft: '20px', marginRight: '20px', 'min-height': '200px' }}>
              <form className="form-horizontal" name={this.returnFormName("countryForm")}>
                <div className="form-group row">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput("text", Name, "Name", errorStateName, onChangeStateName)}
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderSelectOptionsControl(selectedOption, handleChange, options, "Country Name", errorStateCountryId)}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      {this.renderSubmitButton("submit", (errorStateName != null || errorStateCountryId != null) && isUpdate == false, SaveOrUpdate, "Save")}
                      {this.renderCancelButton()}
                    </div>

                    <div className="col-md-6 col-12">
                    </div>
                    <div className="col-md-12 col-lg-12 col-12">
                      <div style={{ float: 'left', marginLeft: '-15px', verticalAlign: 'baseline' }}>
                      </div>
                      <div style={{ float: 'right', marginRight: '-2px' }}>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;



