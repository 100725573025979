import React from 'react';
import { connect } from 'react-redux';
import { getRemainderObjectArray, getSortedObjectArray } from '../../../../../util/index';
import { searchFilterBoardData } from '../../../../../actions';
import axios from '../../../../../util/Api';

class SortDropdwon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            sortLists: [],
            activeSortLists: [],
            groups: []
        }

        this.dropdownContainer = React.createRef();
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {

            if (ev.target.className === "fa fa-close sort" || ev.target.className === "remove-sort") {
                // console.log("!!!!!!!!!!!!!!")            
            } else {
                this.setState({ open: false })
            }
        }
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
        if (this.props.boardData.boardID !== '') {
            let columns = this.props.boardData.cellSuggestions;
            let sortLists = this.getSortLists(columns);
            this.setState({ sortLists: sortLists });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.boardData.boardID !== '' && prevProps.boardData.boardID !== this.props.boardData.boardID) {
            let columns = this.props.boardData.cellSuggestions;

            let sortLists = this.getSortLists(columns);
            this.setState({ sortLists: sortLists, groups: this.props.boardData.storeData });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.boardData.boardID !== "") {
            // axios.get(`/workspace/getWorkspaceBoardById/${nextProps.boardData.boardID}`).then(({ data }) => {
            //     let sortLists = this.getSortLists(data.workspaceBoard.columnHeaders);
            //     this.setState({ groups: JSON.parse(data.workspaceBoard.group), sortLists: sortLists });
            // }).catch(err => {
            //     console.log("Error:", err)
            // })

            // console.log("next props===>", nextProps)

            let sortLists = this.getSortLists(nextProps.columns);
            this.setState({ groups: nextProps.groups, sortLists: sortLists });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    getSortLists = (columns) => {
        let sortLists = [];
        let nameSort = {
            columnId: "name",
            title: 'name'
        }
        sortLists.push(nameSort);
        for (let i = 0; i < columns.length; i++) {
            let newColumn = {
                columnId: columns[i]._id,
                title: columns[i].title
            }
            sortLists.push(newColumn)
        }

        return sortLists;
    }

    addSort = () => {
        let activeSorts = this.state.activeSortLists;
        let sortLists = this.state.sortLists;
        let activeLists = this.state.activeSortLists;
        if (this.state.activeSortLists.length === 0) {
            let nameSort = {
                columnId: "name",
                title: 'Name',
                direction: 'asc'
            }

            activeSorts.push(nameSort);
        } else {
            let reminderLists = getRemainderObjectArray(sortLists, activeLists);
            if (reminderLists.length > 0) {
                let list = reminderLists[0];
                list.direction = 'asc'
                activeSorts.push(list)
            }
        }

        this.setState({ activeSortLists: activeSorts });
        this.updateBoardGroup();
    }

    getSuggestSorts = (activeItem) => {
        let suggests = [];
        let sortLists = this.state.sortLists;
        let activeLists = this.state.activeSortLists;
        let remainderLists = getRemainderObjectArray(sortLists, activeLists);
        suggests = [activeItem, ...remainderLists];
        return suggests;
    }

    onChangeSort = (targetColumnId, sourceColumnId) => {
        let activeLists = this.state.activeSortLists;
        let sortLists = this.state.sortLists;
        // console.log("targetColumnId", targetColumnId);
        // console.log("sourceColumnId", sourceColumnId);
        let targetListIndex = activeLists.findIndex(x => x.columnId === sourceColumnId);
        let destinationList = sortLists.find(x => x.columnId === targetColumnId);
        // console.log("destinationList", destinationList)
        activeLists[targetListIndex] = destinationList;
        this.setState({ activeSortLists: activeLists });
        this.updateBoardGroup();
    }

    removeActiveSort = (list) => {
        let activeLists = this.state.activeSortLists;
        let targetIndex = activeLists.findIndex(x => x.columnId === list.columnId);
        activeLists.splice(targetIndex, 1);
        this.setState({ activeSortLists: activeLists });
        this.updateBoardGroup();
    }

    changeSortDirection = (direction, list) => {
        let activeLists = this.state.activeSortLists;
        let listIndex = activeLists.findIndex(x => x.columnId === list.columnId);
        activeLists[listIndex].direction = direction;
        this.setState({ activeSortLists: activeLists });
        this.updateBoardGroup();
    }

    updateBoardGroup = () => {
        if (this.state.activeSortLists.length === 0) {
            this.props.searchFilterBoardData({
                boardData: this.state.groups
            })
        } else {
            console.log("this.state.activeSortLists", this.state.activeSortLists)
            let sortedData = getSortedObjectArray(this.state.groups, this.state.activeSortLists);
            this.props.searchFilterBoardData({
                boardData: sortedData
            })
        }

    }

    render() {
        // console.log("this.state.activeSortLists", this.state.activeSortLists)
        return (
            <div style={{ cursor: 'pointer', fontFamily: 'myriad-pro,sans-serif !important', fontWeight: '300' }} ref={this.dropdownContainer} >
                <div className="dropdown" id="sort-dropdown">
                    <button className="btn dropdown-toggle" type="button" aria-expanded="false" onClick={() => {
                        if (this.props.boardData.boardID !== "") {
                            this.setState({ open: !this.state.open })
                        }
                    }}>
                        <i className="fal fa-sort-alt mr-2"></i>
                        <span style={{ verticalAlign:' middle' }}>Sort</span>
                    </button>
                    <div style={{ position: 'absolute', width: '250px' }}>
                        <ul className={`dropdown-menu multi-level animate slideIn sort-drp ${this.state.open ? 'show' : ''}`} role="menu" style={{ width: '400px', marginLeft: '-170px' }} >
                            <div className="p-2" style={{ display: this.state.activeSortLists.length > 0 ? 'none' : '' }}>
                                <h5 class="sort-drp-title" >Sort By</h5>
                                <p style={{ 'marginLeft': '7px' }}>Sort your items by priority, creation date, price or any columns you have on your board.</p>
                                <li className="dropdown-divider"></li>
                            </div>
                            <div className="p-2" style={{ display: this.state.activeSortLists.length === 0 ? 'none' : '' }}>
                                <h5 class="sort-drp-title">Sort By</h5>
                                {this.state.activeSortLists.map((list, index) => (
                                    <div className="p-1" key={index}>
                                        <div className="sort-option">
                                            <div className='p-1 w-100'>
                                                <select className='form-control select' value={list.columnId} onChange={(ev) => this.onChangeSort(ev.target.value, list.columnId)}>
                                                    {this.getSuggestSorts(list).map((item, ind) => (
                                                        <option value={item.columnId} key={ind}>{item.title}</option>
                                                    ))}
                                                </select>
                                            </div>
                                            <div className='p-1 w-100'>
                                                <select value={list.direction} className='form-control select' onChange={(ev) => this.changeSortDirection(ev.target.value, list)}>
                                                    <option value="asc">Ascending</option>
                                                    <option value="desc">Descending</option>
                                                </select>
                                            </div>
                                            <div>
                                                <div className='remove-sort' onClick={() => this.removeActiveSort(list)} >
                                                    <i className='fa fa-close sort' ></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                            <button className="btn add-sort" onClick={() => this.addSort()}>
                                <i className="bx bx-plus" style={{ 'color' : '#fff' }}></i>
                                Add new Sort
                            </button>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { searchFilterBoardData };

export default connect(mapStateToProps, mapDispatchToProps)(SortDropdwon);