export const getAllFiles = "files/getAllFiles";
export const getAllFilesByUser = "files/getAllFilesByUser";
export const getFilesById = "files/getFilesById";
export const saveFile = "files/saveFile";
export const updateFile = "files/updatefiles";
export const deleteFile = "files/deletefiles";
export const fileSearch = "files/fileSearch";
export const getFilesByTypes = "files/getFilesByTypes";
export const getFileByName = "files/getFileByName";
export const getFileByType = "files/getFileByType";

export default {
    getAllFiles: getAllFiles,
    getAllFilesByUser: getAllFilesByUser,
    getFilesById: getFilesById,
    saveFile: saveFile,
    updateFile: updateFile,
    deleteFile: deleteFile,
    fileSearch: fileSearch,
    getFilesByTypes: getFilesByTypes,
    getFileByName: getFileByName,
    getFileByType: getFileByType

}


