import React from 'react';
import ContentEditable from 'react-contenteditable';

class StatusItemEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: this.props.item.name
        }
        this.timeout = 0;
        this.contentRef = React.createRef();
    }

    onChangeTextItem = (event) => {
        this.setState({content:  event.target.value})
    }

    onKeyUp = (ev) => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.changeLabelItemTitle(this.state.content)
        }, 800);
    }

    render() {
        return (
            <div>
                <ContentEditable 
                    className="board-name status-names"
                    innerRef={this.contentRef}
                    html={this.state.content}
                    onChange={this.onChangeTextItem}
                    onKeyUp={this.onKeyUp}
                    style={{ width: '100%', fontSize: '16px' }}
                    tagName='div'
                />
            </div>
        )
    }
}

export default StatusItemEdit;