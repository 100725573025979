import React from 'react';
import * as Validator from 'validatorjs';
import { Link } from 'react-router-dom';
import Form from '../common/form.jsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as UserService from '../auth-services/user-service.jsx';
import axios from "./../../../util/Api";
//import validator from 'validator';

var Gender_options = ["Male", "Female", "Others"];
  

  var validator = require('validator');
 
class RegisterForm extends Form {
  constructor(props) {
    super(props);
    this.state = {
      selectedGenderOption: null,
      gender: "",
      firstName: "",
      lastName: "",
      department: "",
      teamName: '',
      lastNameError: "",
      phoneNumber: "",
      emailAdress: "",
      password: "",
      password2: "",
      isAgreeTermsSelected: false,
      errors: [],
      rememberToken: ""
      // password2Error: "" , 
      // passwordError: "",
      // emailAdressError: "",
      // phoneNumberError: "",
      // firstNameError: "",
      // selectGenderError: "",
    }
  }



  

  componentDidMount() {
    const { rememberToken, email } = this.props;
    console.log("rememberToken: ", rememberToken);
    console.log("email: ", email)
    this.setState({ rememberToken: rememberToken, emailAdress: email })
  }

  componentDidUpdate(prevProps, prevStates) {
    if (this.props.rememberToken !== prevProps.rememberToken) {
      const { rememberToken, email } = this.props;
      this.setState({ rememberToken: rememberToken, emailAdress: email })
    }
  }


  onChangeFirstName = (e) => {
    let value = e.target.value;
    if (validator.matches(value, "^[a-zA-Z]*$")) {
          this.setState({firstName: value})
         }
    else{
      toast("Invalid Firstname");
    }

      // console.log(value);
}

  onChangeLastName = (e) => {
    let value = e.target.value;
    if (validator.matches(value, "^[a-zA-Z]*$")){
    this.setState({lastName: value})
      }
      else{
        toast("Invalid Lastname");
      }
    // console.log(value);
  }

  onChangeDepartment = (e) => {
    let value = e.target.value;
    this.setState({ department: value })

    
  }

  onChangeTeamName = (e) => {
    
    this.setState({ teamName: e.target.value })
  }

  onChangePhoneNumber = (e) => {
    let value = e.target.value;
    this.setState({
      phoneNumber: value
    })
    // console.log(value);
  }

  onChangeEmailAdress = (e) => {
    let value = e.target.value;
   
    this.setState({
       emailAdress: value
       })
    
  }

  onChangePassword = (e) => {
    let value = e.target.value;
    this.setState({
      password: value
    })
    // console.log(value);
  }

  onChangePassword2 = (e) => {
    let value = e.target.value;
    this.setState({
      password2: value
    })
    console.log(value);
  }

  handleChange_Gender = selectedGenderOption => {
   
    this.resetSelectGenderDropDown();
    this.setState(
      { selectedGenderOption },
      () => 
      console.log(`selectedGenderOption selected:`, this.state.selectedGenderOption)
      );
  
  }
  

  async resetSelectGenderDropDown() {
    this.setState({ gender: "" })
    
    this.setState({
      selectedGenderOption: null
    })
   //
  }

  registerSubmit = async (e) => {
    e.preventDefault();
    
    if ( this.state.isAgreeTermsSelected == false) {
     toast("Please agree the terms and conditions")
     return;
    }
    if(this.state.selectedGenderOption == null){
      toast("Please select your Gender")
      return;
    }
    if(this.state.department == ""){
      toast("Please Fill Department")
      return;
    }
    if(this.state.teamName == ""){
      toast("Please Fill Team Name")
      return;
    }
    // if(this.state.phoneNumber<10 )&& this.state.phoneNumber>10){
    //   toast("Please Enter a valid Mobile Number ")
    //   return;
    // }
    
    const data =
    {
      firstname: this.state.firstName,
      lastname: this.state.lastName,
      department: this.state.department,
      teamName: this.state.teamName,
      mobileno: this.state.phoneNumber,
      email: this.state.emailAdress,
      password: this.state.password,
      password2: this.state.password2,
      gender: this.state.selectedGenderOption.value,
      token: this.state.rememberToken
    }

    axios.post('/users/register', data)
    .then(function (response) {
      toast("You Registered Successfully!")
      window.location = "/login";
      console.log('repos register',response)    
    })
    .catch(function (error) {   
      debugger;
      console.log('response: ', error.response.data.message);

      toast.error('Oops! '+error.response.data.message);
    });

return;
    const registerResult = UserService.register(data);
    if (registerResult) {
    //  toast("You Registered Successfully!")

      
     // this.props.history.push('/login')

    } else {
      alert("Some Thing Went Wrong ... Please Try Again Or Contact With Support!")
    }
    // if (ex.response && ex.response.status === 400) {
    //  toast("Some Thing Went Wrong...Please Try Again Or Contact With Support....!")
    // const errors = {...this.state.errors}
    // errors.errors = ex.response.data; 
    // this.setState({
    //   errors
    // })
    // console.log(errors , ">>>>>errors"); 
  }

  onChangeAgreeTerms = (e) => {
    if (this.state.isAgreeTermsSelected === false) {
      this.state.isAgreeTermsSelected = true
      this.setState({ isAgreeTermsSelected: true })
       //alert(this.state.isAgreeTermsSelected); 
      return
    }
    else if (this.state.isAgreeTermsSelected === true)
      this.state.isAgreeTermsSelected = false
    this.setState({ isAgreeTermsSelected: false })
    // alert(this.state.isAgreeTermsSelected); 
    return
  }

  render() {
    const { selectedGenderOption, selectGenderError, firstName, lastName, phoneNumber, emailAdress, password, password2, teamName, department,
      firstNameError, lastNameError, phoneNumberError,teamNameError,departmentNameError, emailAdressError, passwordError, password2Error, isAgreeTermsSelected, errors, isChecked,isRequired
    } = this.state;
    return (
      <>
        <ToastContainer />

        <form name="userForm">
          <div className="form-group mb-50" style={{ color: "#000000" }}>
            {
              this.renderRegisterSelectOptionsControl(selectedGenderOption, this.handleChange_Gender, Gender_options, "Gender", selectGenderError)
            }
          </div>

          <div className="form-row" style={{ marginTop: '30px' }}>
            {
              this.renderRegisterInput("text", firstName, "First Name", firstNameError, this.onChangeFirstName,isRequired)
            }
           

            {
              this.renderRegisterInput("text", lastName, "Last Name", lastNameError, this.onChangeLastName)
            }
          </div>

          <div className="form-row" style={{ marginTop: '10px' }}>
            {
              this.renderRegisterInput("text", teamName, "Team Name", teamNameError, this.onChangeTeamName)
            }

            {
              this.renderRegisterInput("text", department, "Department", departmentNameError, this.onChangeDepartment)
            }
          </div>

          <div className="form-row" style={{ marginTop: '10px' }}>
            {
              this.renderRegisterInput("number", phoneNumber, "Phone Number", phoneNumberError, this.onChangePhoneNumber)
            }

            {
              this.renderRegisterInput("email", emailAdress.toLowerCase(), "Email Address", emailAdressError, this.onChangeEmailAdress)
            }
          </div>

          <div className="form-row" style={{ marginTop: '10px' }}>
            {
              this.renderRegisterInput("password", password, "Password", passwordError, this.onChangePassword)
            }

            {
              this.renderRegisterInput("password", password2, "Confirm Password", password2Error, this.onChangePassword2)
            }

          </div>

          {/* previous validation */}
          {
            this.renderRegisterCheckBox("I agree the terms and conditions", this.state.isAgreeTermsSelected, this.onChangeAgreeTerms)
              
          }
          <div className="form-row" style={{ marginTop: '15px' }}>
            <div className="form-group col-md-6 mb-50" >
              <button type="submit" onClick={this.registerSubmit} 
                className="btn btn-danger glow position-relative w-100 disabled"
                style={{ backgroundColor: '#e8378c !important', border: 'none' }}
              
                > SIGN UP </button>
            </div>
            <div>
              {
                // errors.map((error)=>{
                //   <div> {error.email} </div>
                // })
              }
            </div>
            <div className="form-group col-md-6 mb-50">
              <div className="text-center mt-50">
                <small className="mr-25" style={{ 'color' : '#707070' , 'font-weight' : '300' }}>Already have an account?</small>
                <Link to="/login" style={{ 'color' : '#504f4f' }}>Sign in</Link>
              </div>
            </div>

          </div>
        </form>
      </>
    );
  }
}
export default RegisterForm;