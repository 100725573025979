import * as UserConstant from '../constant/users-contant';
import jwtDecode from 'jwt-decode';
import http from '../../services/http-service';
import { createBrowserHistory } from "history";

const history = createBrowserHistory()

export function addToken(tokenKey, tokenValue) {
    localStorage.setItem(tokenKey, JSON.stringify(tokenValue))
}

export function getUserByToken(tokenKey) {
    try {
        const jwt = localStorage.getItem(tokenKey);
        const user = jwtDecode(jwt);
        if (user)
            return user
        return null
    } catch (ex) {

    }
}

export function removeToken(tokenKey) {
    localStorage.removeItem(tokenKey);
}

export async function login(data) {
    await http.post(UserConstant.LOGIN, data).then((response) => {
        console.log("response===>", response)
        addToken(UserConstant.TOKEN_NAME, JSON.stringify(response.data.token));
        history.push('/')
        // window.location = "/";
    }).catch(err => {
        console.error(err)
    })
}

export async function register(data) {
    try {
        await http.post(UserConstant.REGISTER, data)
            .then((response) => {
                console.log(response);
                debugger;
                return true;
            })
    } catch (ex) {
        debugger;
        console.log(ex, ">>Register Error");
        return false;
    }
}

export default {
    getUserByToken, addToken, login, register
}