import React from 'react';
import Checkbox from 'react-checkbox-component'

//https://github.com/Hugand/react-checkbox-component#readme
function UserProfileTextInput({ label, error, ...rest }) {
    return (
        <fieldset className="form-label-group">
       { rest.fieldtype !== undefined ? <input {...rest} class="form-control outline user-profile-outline" style={{backgroundColor: '#fff'}} readOnly /> : <input {...rest} class="form-control outline user-profile-outline" />}
        <label class="user-profile-label">{label}<span class="asterik">*</span></label>
            { error && <div className="alert alert-danger">{error.message}</div>}

    </fieldset>
    );
}

export default UserProfileTextInput;