/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import http from '../../services/http-service';
import * as DashBoardSettingConstant from './constants';
import MainMenu from '../../../shared/components/main-menu';
import NavBar from '../../../shared/components/main-menu';
import Notification from '../../notification/notification';

class DashBoardSetting extends Component {
  //General Group
  // ShowTasksCountsWidgetRef = React.createRef(); 
  ShowAllTasksWidgetRef = React.createRef();
  ShowReminderWidgetRef = React.createRef();
  ShowMessangerWidgetRef = React.createRef();
  ShowRecentActiveWorkSpaceWidgetRef = React.createRef();
  ShowRecentActiveFoldersWidgetRef = React.createRef();
  ShowAttendanceManagementWidgetRef = React.createRef();
  ShowTaskDistributionWidgetRef = React.createRef();
  ShowRecentCallHistoryWidgetRef = React.createRef();

  //Task Count Group
  ShowAllTasksRef = React.createRef();
  ShowOngoingTaskCountRef = React.createRef();
  ShowOverdueTasksRef = React.createRef();
  ShowReviewTasksRef = React.createRef();
  ShowUnreadTaskRef = React.createRef();
  ShowFavoritesTaskRef = React.createRef();
  ShowRecentTasksRef = React.createRef();

  //Task Table widget Group
  ShowABRef = React.createRef();
  ShowTaskRef = React.createRef();
  ShowProjectRef = React.createRef();
  ShowCategoryRef = React.createRef();
  ShowPriorityRef = React.createRef();
  ShowDueDateRef = React.createRef();
  ShowStatusRef = React.createRef();
  ShowTotalMessagesRef = React.createRef();
  ShowAssignessRef = React.createRef();

  constructor(props) {
    super(props)
    this.state = {
      id: "",
      userId: "5fb65016a624bc4f507f452d",
      CreatedBy: "5fb65016a624bc4f507f452d",
      ModifiedBy: "5fb65016a624bc4f507f452d",
      IsDeleted: false,
      IsActive: true,

      AllUserWorkSpaces: [],
      DefaultWorkSpace: "",

      //*************************************************************** */   
      General_GroupName: "General",
      //Show Tasks Counts Widget
      ShowTasksCountsWidgetName: "Show Tasks Counts Widget",
      ShowTasksCountsWidgetValue: false,
      //Show All Tasks Widget
      ShowAllTasksWidgetName: "Show All Tasks Widget",
      ShowAllTasksWidgetValue: false,
      //Show Reminder Widget
      ShowReminderWidgetName: "Show Reminder Widget",
      ShowReminderWidgetValue: false,
      //Show Messanger Widget
      ShowMessangerWidgetName: "Show Messanger Widget",
      ShowMessangerWidgetValue: false,
      //Show Recent Active WorkSpace Widget
      ShowRecentActiveWorkSpaceWidgetName: "Show Recent Active WorkSpace Widget",
      ShowRecentActiveWorkSpaceWidgetValue: false,
      //Show Recent Active Folders Widget
      ShowRecentActiveFoldersWidgetName: "Show Recent Active Folders Widget",
      ShowRecentActiveFoldersWidgetValue: false,
      //Show Attendance Management Widget
      ShowAttendanceManagementWidgetName: "Show Attendance Management Widget",
      ShowAttendanceManagementWidgetValue: false,
      //Show Task Distribution Widget
      ShowTaskDistributionWidgetName: "Show Task Distribution Widget",
      ShowTaskDistributionWidgetValue: false,
      //Show Recent Call History Widget
      ShowRecentCallHistoryWidgetName: "Show Recent Call History Widget",
      ShowRecentCallHistoryWidgetValue: false,

      //*************************************************************** */   
      //Task Count Widget 
      TaskWidgetGroupName: "Task Widget Group Name",

      ShowAllTasksName: "show all tasks",
      ShowAllTasksValue: false,

      ShowOngoingTaskCountName: "Show On going Tasks",
      ShowOngoingTaskCountValue: false,

      ShowOverdueTasksName: "Show Overdue tasks",
      ShowOverdueTasksValue: false,

      ShowReviewTasksName: "Show Review Tasks",
      ShowReviewTasksValue: false,

      ShowUnreadTaskName: "Show Unread Tasks",
      ShowUnreadTaskValue: false,

      ShowFavoritesTaskName: "Show Favorites Tasks",
      ShowFavoritesTaskValue: false,

      ShowRecentTasksName: "Show Recent Tasks Name",
      ShowRecentTasksValue: false,

      //*************************************************************** */   
      //Task Table widget Group
      TaskTableWidgetGroupName: "Task Table Widget Group Name",

      ShowABName: "Show AB Name",
      ShowABValue: false,

      ShowTaskName: "Show Task Name",
      ShowTaskValue: false,

      ShowProjectName: "Show Project Name",
      ShowProjectValue: false,

      ShowCategoryName: "Show Category Name",
      ShowCategoryValue: false,

      ShowPriorityName: "Show Priority Name",
      ShowPriorityValue: false,

      ShowDueDateName: "Show Due Date Name",
      ShowDueDateValue: false,

      ShowStatusName: "Show Status Name",
      ShowStatusValue: false,

      ShowTotalMessagesName: "Show Total Messages Name",
      ShowTotalMessagesValue: false,

      ShowAssignessName: "Show Assigness Name",
      ShowAssignessValue: false

      //*************************************************************** */   
    }
  }

  async componentDidMount() {
    //get setting data 
    let usrId = { userId: this.state.userId }
    await http.post(DashBoardSettingConstant.getDashBoardSettingsByUserId, usrId)
      .then((response) => {
        response.data.data.map((data) => {
          //General Widgets
          this.setState({ id: data._id })
          //Show General Group
          this.setState({ General_GroupName: data.General_GroupName })
          //Show Tasks Counts Widget
          this.setState({ ShowTasksCountsWidgetName: data.ShowTasksCountsWidgetName })
          this.setState({ ShowTasksCountsWidgetValue: data.ShowTasksCountsWidgetValue })
          //Show All Tasks Widget
          this.setState({ ShowAllTasksWidgetName: data.ShowAllTasksWidgetName })
          this.setState({ ShowAllTasksWidgetValue: data.ShowAllTasksWidgetValue })
          //Show Reminder Widget
          this.setState({ ShowReminderWidgetName: data.ShowReminderWidgetName })
          this.setState({ ShowReminderWidgetValue: data.ShowReminderWidgetValue })
          //Show Messanger Widget
          this.setState({ ShowMessangerWidgetName: data.ShowMessangerWidgetName })
          this.setState({ ShowMessangerWidgetValue: data.ShowMessangerWidgetValue })
          //Show Recent Active WorkSpace Widget
          this.setState({ ShowRecentActiveWorkSpaceWidgetName: data.ShowRecentActiveWorkSpaceWidgetName })
          this.setState({ ShowRecentActiveWorkSpaceWidgetValue: data.ShowRecentActiveWorkSpaceWidgetValue })
          //Show Recent Active WorkSpace Widget
          this.setState({ ShowRecentActiveFoldersWidgetName: data.ShowRecentActiveFoldersWidgetName })
          this.setState({ ShowRecentActiveFoldersWidgetValue: data.ShowRecentActiveFoldersWidgetValue })
          //Show Attendance Management Widget
          this.setState({ ShowAttendanceManagementWidgetName: data.ShowAttendanceManagementWidgetName })
          this.setState({ ShowAttendanceManagementWidgetValue: data.ShowAttendanceManagementWidgetValue })
          //Show Task Distribution Widget
          this.setState({ ShowTaskDistributionWidgetName: data.ShowTaskDistributionWidgetName })
          this.setState({ ShowTaskDistributionWidgetValue: data.ShowTaskDistributionWidgetValue })
          //Show Recent Call History Widget
          this.setState({ ShowRecentCallHistoryWidgetName: data.ShowRecentCallHistoryWidgetName })
          this.setState({ ShowRecentCallHistoryWidgetValue: data.ShowRecentCallHistoryWidgetValue })
          //Getting Default WorkSpace 
          this.setState({ DefaultWorkSpace: data.DefaultWorkSpace })

          //Task Count Widget 
          this.setState({ TaskWidgetGroupName: data.TaskWidgetGroupName })

          this.setState({ ShowAllTasksName: data.ShowAllTasksName })
          this.setState({ ShowAllTasksValue: data.ShowAllTasksValue })

          this.setState({ ShowOngoingTaskCountName: data.ShowOngoingTaskCountName })
          this.setState({ ShowOngoingTaskCountValue: data.ShowOngoingTaskCountValue })

          this.setState({ ShowOverdueTasksName: data.ShowOverdueTasksName })
          this.setState({ ShowOverdueTasksValue: data.ShowOverdueTasksValue })

          this.setState({ ShowReviewTasksName: data.ShowReviewTasksName })
          this.setState({ ShowReviewTasksValue: data.ShowReviewTasksValue })

          this.setState({ ShowUnreadTaskName: data.ShowUnreadTaskName })
          this.setState({ ShowUnreadTaskValue: data.ShowUnreadTaskValue })

          this.setState({ ShowFavoritesTaskName: data.ShowFavoritesTaskName })
          this.setState({ ShowFavoritesTaskValue: data.ShowFavoritesTaskValue })

          this.setState({ ShowRecentTasksName: data.ShowRecentTasksName })
          this.setState({ ShowRecentTasksValue: data.ShowRecentTasksValue })


          //Task Table widget Group
          this.setState({ TaskTableWidgetGroupName: data.TaskTableWidgetGroupName })

          this.setState({ ShowABName: data.ShowABName })
          this.setState({ ShowABValue: data.ShowABValue })

          this.setState({ ShowTaskName: data.ShowTaskName })
          this.setState({ ShowTaskValue: data.ShowTaskValue })

          this.setState({ ShowProjectName: data.ShowProjectName })
          this.setState({ ShowProjectValue: data.ShowProjectValue })

          this.setState({ ShowCategoryName: data.ShowCategoryName })
          this.setState({ ShowCategoryValue: data.ShowCategoryValue })

          this.setState({ ShowPriorityName: data.ShowPriorityName })
          this.setState({ ShowPriorityValue: data.ShowPriorityValue })

          this.setState({ ShowDueDateName: data.ShowDueDateName })
          this.setState({ ShowDueDateValue: data.ShowDueDateValue })

          this.setState({ ShowStatusName: data.ShowStatusName })
          this.setState({ ShowStatusValue: data.ShowStatusValue })

          this.setState({ ShowTotalMessagesName: data.ShowTotalMessagesName })
          this.setState({ ShowTotalMessagesValue: data.ShowTotalMessagesValue })


          this.setState({ ShowAssignessName: data.ShowAssignessName })
          this.setState({ ShowAssignessValue: data.ShowAssignessValue })
        })

      })

    //General Widgets Ref
    //ShowTasksCountsWidgetRef
    //  this.ShowTasksCountsWidgetRef.current.checked = this.state.ShowTasksCountsWidgetValue;
    //  this.setState({ShowTasksCountsWidgetValue : this.ShowTasksCountsWidgetRef.current.checked})

    //ShowAllTasksWidgetNameRef
    //  this.ShowAllTasksWidgetRef.current.checked = this.state.ShowAllTasksWidgetValue;
    //  this.setState({ShowAllTasksWidgetValue : this.ShowAllTasksWidgetRef.current.checked})

    //ShowReminderWidgeRef
    // this.ShowReminderWidgetRef.current.checked = this.state.ShowReminderWidgetValue;
    // this.setState({ShowReminderWidgetValue : this.ShowReminderWidgetRef.current.checked})

    //Show Messanger WidgetRef
    // this.ShowMessangerWidgetRef.current.checked = this.state.ShowMessangerWidgetValue;
    // this.setState({ShowMessangerWidgetValue : this.ShowMessangerWidgetRef.current.checked})

    //Show Recent Active WorkSpace Widget Ref
    // this.ShowRecentActiveWorkSpaceWidgetRef.current.checked = this.state.ShowRecentActiveWorkSpaceWidgetValue;
    // this.setState({ ShowRecentActiveWorkSpaceWidgetValue : this.ShowRecentActiveWorkSpaceWidgetRef.current.checked})

    //Show ShowRecentActiveFoldersWidgetRef
    // this.ShowRecentActiveFoldersWidgetRef.current.checked = this.state.ShowRecentActiveFoldersWidgetValue;
    // this.setState({ ShowRecentActiveFoldersWidgetValue : this.ShowRecentActiveFoldersWidgetRef.current.checked})

    //Show Attendance Management Widget
    // this.ShowAttendanceManagementWidgetRef.current.checked = this.state.ShowAttendanceManagementWidgetValue;
    // this.setState({ ShowAttendanceManagementWidgetValue : this.ShowAttendanceManagementWidgetRef.current.checked})

    //Show TaskDistribution Widget
    // this.ShowTaskDistributionWidgetRef.current.checked = this.state.ShowTaskDistributionWidgetValue;
    // this.setState({ ShowTaskDistributionWidgetValue : this.ShowTaskDistributionWidgetRef.current.checked})

    //Show Recent Call History Widget
    // this.ShowRecentCallHistoryWidgetRef.current.checked = this.state.ShowRecentCallHistoryWidgetValue;
    // this.setState({ ShowRecentCallHistoryWidgetValue : this.ShowRecentCallHistoryWidgetRef.current.checked})

    //Task Count Widget Ref
    // this.ShowAllTasksRef.current.checked = this.state.ShowAllTasksValue;
    // this.setState({ ShowAllTasksValue : this.ShowAllTasksRef.current.checked})

    // this.ShowOngoingTaskCountRef.current.checked = this.state.ShowOngoingTaskCountValue;
    // this.setState({ ShowOngoingTaskCountValue : this.ShowOngoingTaskCountRef.current.checked})

    // this.ShowOverdueTasksRef.current.checked = this.state.ShowOverdueTasksValue;
    // this.setState({ ShowOverdueTasksValue : this.ShowOverdueTasksRef.current.checked})

    // this.ShowReviewTasksRef.current.checked = this.state.ShowReviewTasksValue;
    // this.setState({ ShowReviewTasksValue : this.ShowReviewTasksRef.current.checked})

    // this.ShowUnreadTaskRef.current.checked = this.state.ShowUnreadTaskValue;
    // this.setState({ ShowUnreadTaskValue : this.ShowUnreadTaskRef.current.checked})

    // this.ShowFavoritesTaskRef.current.checked = this.state.ShowFavoritesTaskValue;
    // this.setState({ ShowFavoritesTaskValue : this.ShowFavoritesTaskRef.current.checked})

    // this.ShowRecentTasksRef.current.checked = this.state.ShowRecentTasksValue;
    // this.setState({ ShowRecentTasksValue : this.ShowRecentTasksRef.current.checked})


    //Task Table widget Ref
    // this.ShowABRef.current.checked = this.state.ShowABValue;
    // this.setState({ ShowABValue : this.ShowABRef.current.checked})

    // this.ShowTaskRef.current.checked = this.state.ShowTaskValue;
    // this.setState({ ShowTaskValue : this.ShowTaskRef.current.checked})

    // this.ShowProjectRef.current.checked = this.state.ShowProjectValue;
    // this.setState({ ShowProjectValue : this.ShowProjectRef.current.checked})

    // this.ShowCategoryRef.current.checked = this.state.ShowCategoryValue;
    // this.setState({ ShowCategoryValue : this.ShowCategoryRef.current.checked})

    // this.ShowPriorityRef.current.checked = this.state.ShowPriorityValue;
    // this.setState({ ShowPriorityValue : this.ShowPriorityRef.current.checked})

    // this.ShowDueDateRef.current.checked = this.state.ShowDueDateValue;
    // this.setState({ ShowDueDateValue : this.ShowDueDateRef.current.checked})

    // this.ShowStatusRef.current.checked = this.state.ShowStatusValue;
    // this.setState({ ShowStatusValue : this.ShowStatusRef.current.checked})

    // this.ShowTotalMessagesRef.current.checked = this.state.ShowTotalMessagesValue;
    // this.setState({ ShowTotalMessagesValue : this.ShowTotalMessagesRef.current.checked})


    // this.ShowAssignessRef.current.checked = this.state.ShowAssignessValue;
    // this.setState({ ShowAssignessValue : this.ShowAssignessRef.current.checked})

    //Load All User Workspaces 
    // await http.post(, usrId)
    // .then((data) => {

    //   })
    // })

  }

  updateRecord = async () => {
    const data = {
      //General Group Widget Attribute
      id: this.state.id,
      userId: this.state.userId,
      CreatedBy: this.state.CreatedBy,
      ModifiedBy: this.state.ModifiedBy,
      IsDeleted: this.state.IsDeleted,
      IsActive: this.state.IsActive,
      //update General Group
      General_GroupName: this.state.General_GroupName,
      //Update Show Tasks Counts Widget
      ShowTasksCountsWidgetName: this.state.ShowTasksCountsWidgetName,
      ShowTasksCountsWidgetValue: this.state.ShowTasksCountsWidgetValue,
      //Update All Tasks Widget
      ShowAllTasksWidgetName: this.state.ShowAllTasksWidgetName,
      ShowAllTasksWidgetValue: this.state.ShowAllTasksWidgetValue,
      //Update Reminder Widget
      ShowReminderWidgetName: this.state.ShowReminderWidgetName,
      ShowReminderWidgetValue: this.state.ShowReminderWidgetValue,
      //Update All Tasks Widget
      ShowMessangerWidgetName: this.state.ShowMessangerWidgetName,
      ShowMessangerWidgetValue: this.state.ShowMessangerWidgetValue,
      //Show Recent Active WorkSpace Widget
      ShowRecentActiveWorkSpaceWidgetName: this.state.ShowRecentActiveWorkSpaceWidgetName,
      ShowRecentActiveWorkSpaceWidgetValue: this.state.ShowRecentActiveWorkSpaceWidgetValue,
      //ShowRecentActiveFoldersWidget
      ShowRecentActiveFoldersWidgetName: this.state.ShowRecentActiveFoldersWidgetName,
      ShowRecentActiveFoldersWidgetValue: this.state.ShowRecentActiveFoldersWidgetValue,
      //ShowAttendanceManagementWidget
      ShowAttendanceManagementWidgetName: this.state.ShowAttendanceManagementWidgetName,
      ShowAttendanceManagementWidgetValue: this.state.ShowAttendanceManagementWidgetValue,
      //Show Task Distribution Widget
      ShowTaskDistributionWidgetName: this.state.ShowTaskDistributionWidgetName,
      ShowTaskDistributionWidgetValue: this.state.ShowTaskDistributionWidgetValue,
      //Show Recent Call History Widget
      ShowRecentCallHistoryWidgetName: this.state.ShowRecentCallHistoryWidgetName,
      ShowRecentCallHistoryWidgetValue: this.state.ShowRecentCallHistoryWidgetValue,

      //Set Default Workspace
      DefaultWorkSpace: this.state.DefaultWorkSpace,

      //Task Count Widget Attribute
      ShowAllTasksName: this.state.ShowAllTasksName,
      ShowAllTasksValue: this.state.ShowAllTasksValue,

      ShowOngoingTaskCountName: this.state.ShowOngoingTaskCountName,
      ShowOngoingTaskCountValue: this.state.ShowOngoingTaskCountValue,

      ShowOverdueTasksName: this.state.ShowOverdueTasksName,
      ShowOverdueTasksValue: this.state.ShowOverdueTasksValue,

      ShowReviewTasksName: this.state.ShowReviewTasksName,
      ShowReviewTasksValue: this.state.ShowReviewTasksValue,

      ShowUnreadTaskName: this.state.ShowUnreadTaskName,
      ShowUnreadTaskValue: this.state.ShowUnreadTaskValue,

      ShowFavoritesTaskName: this.state.ShowFavoritesTaskName,
      ShowFavoritesTaskValue: this.state.ShowFavoritesTaskValue,

      ShowRecentTasksName: this.state.ShowRecentTasksName,
      ShowRecentTasksValue: this.state.ShowRecentTasksValue,

      //Task Table widget 
      ShowABName: this.state.ShowABName,
      ShowABValue: this.state.ShowABValue,

      ShowTaskName: this.state.ShowTaskName,
      ShowTaskValue: this.state.ShowTaskValue,

      ShowProjectName: this.state.ShowProjectName,
      ShowProjectValue: this.state.ShowProjectValue,

      ShowCategoryName: this.state.ShowCategoryName,
      ShowCategoryValue: this.state.ShowCategoryValue,

      ShowPriorityName: this.state.ShowPriorityName,
      ShowPriorityValue: this.state.ShowPriorityValue,

      ShowDueDateName: this.state.ShowDueDateName,
      ShowDueDateValue: this.state.ShowDueDateValue,

      ShowStatusName: this.state.ShowStatusName,
      ShowStatusValue: this.state.ShowStatusValue,

      ShowTotalMessagesName: this.state.ShowTotalMessagesName,
      ShowTotalMessagesValue: this.state.ShowTotalMessagesValue,

      ShowAssignessName: this.state.ShowAssignessName,
      ShowAssignessValue: this.state.ShowAssignessValue,

    };


    await http.put(DashBoardSettingConstant.updateDashBoardSettings, data)
      .then((response) => {
        console.log(">>>Data Updated", response)
      })
  }

  saveDefaultWorksapce = (defWs) => {
    try {
      this.state.DefaultWorkSpace = defWs;
      this.setState({ DefaultWorkSpace: defWs })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }


  onChangeShowTasksCountsWidgetSelect = async () => {
    try {
      const checkValue = this.state.ShowTasksCountsWidgetValue;
      this.state.ShowTasksCountsWidgetValue = !checkValue;
      this.setState({ ShowTasksCountsWidgetValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeShowAllTasksWidgetValue = async () => {
    try {
      const checkValue = this.state.ShowAllTasksWidgetValue;
      this.state.ShowAllTasksWidgetValue = !checkValue;
      this.setState({ ShowAllTasksWidgetValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeShowReminderWidget = async () => {
    try {
      const checkValue = this.state.ShowReminderWidgetValue;
      this.state.ShowReminderWidgetValue = !checkValue;
      this.setState({ ShowReminderWidgetValue: !checkValue })
      this.updateRecord();

      console.log(this.state.ShowReminderWidgetValue, ">>>>>>>ShowReminderWidgetValue")
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeMessangerWidget = async () => {
    try {
      const checkValue = this.state.ShowMessangerWidgetValue;
      this.state.ShowMessangerWidgetValue = !checkValue;
      this.setState({ ShowMessangerWidgetValue: !checkValue })
      this.updateRecord();

      console.log(this.state.ShowMessangerWidgetValue, ">>>>>>>ShowReminderWidgetValue")
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeRecentActiveWorkSpaceWidget = async () => {
    try {
      const checkValue = this.state.ShowRecentActiveWorkSpaceWidgetValue;
      this.state.ShowRecentActiveWorkSpaceWidgetValue = !checkValue;
      this.setState({ ShowRecentActiveWorkSpaceWidgetValue: !checkValue })
      this.updateRecord();

    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeRecentActiveFoldersWidget = async () => {
    try {
      const checkValue = this.state.ShowRecentActiveFoldersWidgetValue;
      this.state.ShowRecentActiveFoldersWidgetValue = !checkValue;
      this.setState({ ShowRecentActiveFoldersWidgetValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeAttendanceManagementWidget = async () => {
    try {
      const checkValue = this.state.ShowAttendanceManagementWidgetValue;
      this.state.ShowAttendanceManagementWidgetValue = !checkValue;
      this.setState({ ShowAttendanceManagementWidgetValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeTaskDistributionWidget = async () => {
    try {
      const checkValue = this.state.ShowTaskDistributionWidgetValue;
      this.state.ShowTaskDistributionWidgetValue = !checkValue;
      this.setState({ ShowTaskDistributionWidgetValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeRecentCallHistoryWidget = async () => {
    try {
      const checkValue = this.state.ShowRecentCallHistoryWidgetValue;
      this.state.ShowRecentCallHistoryWidgetValue = !checkValue;
      this.setState({ ShowRecentCallHistoryWidgetValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }


  onChangeAllTasks = async () => {
    try {
      const checkValue = this.state.ShowAllTasksValue;
      this.state.ShowAllTasksValue = !checkValue;
      this.setState({ ShowAllTasksValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeOngoingTaskCount = async () => {
    try {
      const checkValue = this.state.ShowOngoingTaskCountValue;
      this.state.ShowOngoingTaskCountValue = !checkValue;
      this.setState({ ShowOngoingTaskCountValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }


  onChangeOverdueTaskCount = async () => {
    try {
      const checkValue = this.state.ShowOverdueTasksValue;
      this.state.ShowOverdueTasksValue = !checkValue;
      this.setState({ ShowOverdueTasksValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeReviewTasksName = async () => {
    try {
      const checkValue = this.state.ShowReviewTasksValue;
      this.state.ShowReviewTasksValue = !checkValue;
      this.setState({ ShowReviewTasksValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeUnreadTask = async () => {
    try {
      const checkValue = this.state.ShowUnreadTaskValue;
      this.state.ShowUnreadTaskValue = !checkValue;
      this.setState({ ShowUnreadTaskValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeFavoritesTask = async () => {
    try {
      const checkValue = this.state.ShowFavoritesTaskValue;
      this.state.ShowFavoritesTaskValue = !checkValue;
      this.setState({ ShowFavoritesTaskValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeRecentTasks = async () => {
    try {
      const checkValue = this.state.ShowRecentTasksValue;
      this.state.ShowRecentTasksValue = !checkValue;
      this.setState({ ShowRecentTasksValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }

  }

  //Task Table widget onChange 
  onChangeABName = async () => {
    try {
      const checkValue = this.state.ShowABValue;
      this.state.ShowABValue = !checkValue;
      this.setState({ ShowABValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeTaskName = async () => {
    try {
      const checkValue = this.state.ShowTaskValue;
      this.state.ShowTaskValue = !checkValue;
      this.setState({ ShowTaskValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeProjectName = async () => {
    try {
      const checkValue = this.state.ShowProjectValue;
      this.state.ShowProjectValue = !checkValue;
      this.setState({ ShowProjectValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeCategoryName = async () => {
    try {
      const checkValue = this.state.ShowCategoryValue;
      this.state.ShowCategoryValue = !checkValue;
      this.setState({ ShowCategoryValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangePriorityName = async () => {
    try {
      const checkValue = this.state.ShowPriorityValue;
      this.state.ShowPriorityValue = !checkValue;
      this.setState({ ShowPriorityValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeDueDateName = async () => {
    try {
      const checkValue = this.state.ShowDueDateValue;
      this.state.ShowDueDateValue = !checkValue;
      this.setState({ ShowDueDateValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeStatusName = async () => {
    try {
      const checkValue = this.state.ShowStatusValue;
      this.state.ShowStatusValue = !checkValue;
      this.setState({ ShowStatusValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeTotalMessagesName = async () => {
    try {
      const checkValue = this.state.ShowTotalMessagesValue;
      this.state.ShowTotalMessagesValue = !checkValue;
      this.setState({ ShowTotalMessagesValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  onChangeAssignessName = async () => {
    try {
      const checkValue = this.state.ShowAssignessValue;
      this.state.ShowAssignessValue = !checkValue;
      this.setState({ ShowAssignessValue: !checkValue })
      this.updateRecord();
    } catch (e) {
      alert("There Is Some Thing Went Wrong , Please Try Again Or Contact With Technical Support!")
      console.log(">>>Show the Error", e)
    }
  }

  render() {
    return (
      <>
        <MainMenu link={`dashboard-setting`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top messenger-layout'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <div className="content-wrapper" >
                  <div className="company-table-one" style={{ minHeight: 1000, padding: '50px' }}>
                    <div className="row">
                      <div className="col-md-12" style={{ justifyContent: 'space-between', display: 'flex' }}>
                        <button className="btn btn-primary">Global Settings</button>
                        <h4 className="left-head">Customize Dashboard Settings</h4>
                        <button className="btn btn-Danger">Go to Dashboard</button>
                      </div>
                    </div>

                    <div className="row p-2" style={{ margin: '20px 0px' }}>
                      <div className="col-md-5 p-2" style={{
                        boxShadow: 'rgb(234, 234, 234) -2px 0px 10px 4px', borderRadius: '20px', marginTop: '30px',
                        padding: '30px !important', lineHeight: '40px'
                      }}>
                        <p style={{ textAlign: 'center' }} className="mt-2">General</p>
                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" ref = {this.ShowTasksCountsWidgetRef} defaultChecked = {this.state.ShowTasksCountsWidgetValue}  
                      className="custom-control-input" onChange = {this.onChangeShowTasksCountsWidgetSelect} id="onChangeShowTasksCountsWidgetSelect" /> */}
                          <label htmlFor="onChangeShowTasksCountsWidgetSelect">Show Tasks Counts Widget</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch30" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch30" ></label>
                          </div>
                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" ref = {this.ShowAllTasksWidgetRef} defaultChecked = {this.state.ShowAllTasksWidgetValue} 
                    onChange = {this.onChangeShowAllTasksWidgetValue}  className="custom-control-input" id="ShowAllTasksWidget"/> */}
                          <label htmlFor="ShowAllTasksWidget">Show All Tasks Widget</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch32" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch32" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" ref = {this.ShowReminderWidgetRef} defaultChecked = {this.state.ShowReminderWidgetValue} 
                    onChange = {this.onChangeShowReminderWidget}  className="custom-control-input" id="ShowReminderWidget"/> */}
                          <label htmlFor="ShowReminderWidget">Show Reminder Widget</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch23" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch23" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" ref ={this.ShowMessangerWidgetRef} defaultChecked = {this.state.ShowMessangerWidgetValue} 
                           onChange = {this.onChangeMessangerWidget} className="custom-control-input" id="ShowMessangerWidget"/> */}
                          <label htmlFor="ShowMessangerWidget">Show Messanger Widget</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch33" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch33" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="RecentActiveWorkSpaceWidget"
                      ref ={this.ShowRecentActiveWorkSpaceWidgetRef} defaultChecked = {this.state.ShowRecentActiveFoldersWidgetValue} 
                      onChange = {this.onChangeRecentActiveWorkSpaceWidget}
                    /> */}
                          <label htmlFor="RecentActiveWorkSpaceWidget" >Show Recent Active WorkSpace Widget</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch34" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch34" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowRecentActiveFoldersWidget" ref ={this.ShowRecentActiveFoldersWidgetRef}
                           defaultChecked = {this.state.ShowRecentActiveFoldersWidgetName} 
                           onChange = {this.onChangeRecentActiveFoldersWidget}/> */}
                          <label htmlFor="ShowRecentActiveFoldersWidget">Show Recent Active Folders Widget</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch35" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch35" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="AttendanceManagementWidget" ref ={this.ShowAttendanceManagementWidgetRef}
                           defaultChecked = {this.state.ShowAttendanceManagementWidgetValue} 
                           onChange = {this.onChangeAttendanceManagementWidget}/> */}
                          <label htmlFor="AttendanceManagementWidget">Show  Attendance Management Widget</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch36" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch36" ></label>
                          </div>


                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="TaskDistributionWidget" onClick = {this.onChangeTaskDistributionWidget}
                          ref ={this.ShowTaskDistributionWidgetRef} defaultChecked = {this.state.ShowTaskDistributionWidgetValue} />   */}
                          <label htmlFor="TaskDistributionWidget">Show  Task Distribution Widget</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch37" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch37" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="RecentCallHistoryWidget" onClick = {this.onChangeRecentCallHistoryWidget}
                          ref ={this.ShowRecentCallHistoryWidgetRef} defaultChecked = {this.state.ShowRecentCallHistoryWidgetValue} /> */}
                          <label htmlFor="RecentCallHistoryWidget">Show Recent Call History Widget</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch38" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch38" ></label>
                          </div>

                        </div>
                      </div>

                      <div className="col-md-5 p-2 ml-5" style={{
                        boxShadow: 'rgb(234, 234, 234) -2px 0px 10px 4px', borderRadius: '20px', marginTop: '30px',
                        padding: '30px !important', lineHeight: '40px'
                      }}>
                        <p style={{ textAlign: 'center' }} className="mt-2">Tasks Widget</p>
                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowAllTasks" onChange = {this.onChangeAllTasks}
                          ref ={this.ShowAllTasksRef} defaultChecked = {this.state.ShowAllTasksValue} /> */}
                          <label htmlFor="ShowAllTasks">Show All Tasks</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch39" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch39" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="OngoingTaskCount" onChange = {this.onChangeOngoingTaskCount}
                          ref ={this.ShowOngoingTaskCountRef} defaultChecked = {this.state.ShowOngoingTaskCountValue} /> */}
                          <label htmlFor="OngoingTaskCount">Show On going Tasks</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch40" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch40" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>

                          {/* <input type="checkbox" className="custom-control-input" id="OverdueTaskCount" onChange = {this.onChangeOverdueTaskCount}
                          ref ={this.ShowOverdueTasksRef} defaultChecked = {this.state.ShowOverdueTasksValue} /> */}

                          <label htmlFor="OverdueTaskCount">Show Overdue tasks</label>
                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch41" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch41" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowReviewTasks" onChange = {this.onChangeReviewTasksName}
                          ref ={this.ShowReviewTasksRef} defaultChecked = {this.state.ShowReviewTasksValue} /> */}
                          <label htmlFor="ShowReviewTasks">Show Review Tasks</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch42" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch42" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowUnreadTask" onChange = {this.onChangeUnreadTask}
                          ref ={this.ShowUnreadTaskRef} defaultChecked = {this.state.ShowUnreadTaskValue} /> */}
                          <label htmlFor="ShowUnreadTask">Show Unread Tasks</label>
                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch43" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch43" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowFavoritesTask" onChange = {this.onChangeFavoritesTask}
                          ref ={this.ShowFavoritesTaskRef} defaultChecked = {this.state.ShowFavoritesTaskValue} /> */}
                          <label htmlFor="ShowFavoritesTask">Show Favorites Tasks</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch44" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch44" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowRecentTasks" onChange = {this.onChangeRecentTasks}
                          ref ={this.ShowRecentTasksRef} defaultChecked = {this.state.ShowRecentTasksValue} /> */}
                          <label htmlFor="ShowRecentTasks">Show Recent Tasks</label>

                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch45" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch45" ></label>
                          </div>

                        </div>
                      </div>

                      <div className="col-md-5" style={{
                        boxShadow: 'rgb(234, 234, 234) -2px 0px 10px 4px', borderRadius: '20px', marginTop: '30px',
                        padding: '30px !important', lineHeight: '40px'
                      }}>
                        <p style={{ textAlign: 'center' }} className="mt-2">Task Table Widget</p>
                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowABName" onChange = {this.onChangeABName}
                          ref ={this.ShowABRef} defaultChecked = {this.state.ShowABValue} /> */}
                          <label htmlFor="ShowABName">Show AB</label>
                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch46" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch46" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowTaskName" onChange = {this.onChangeTaskName}
                          ref ={this.ShowTaskRef} defaultChecked = {this.state.ShowTaskValue} /> */}
                          <label htmlFor="ShowTaskName">Show Task Name</label>
                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch47" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch47" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowProjectName" onChange = {this.onChangeProjectName}
                          ref ={this.ShowProjectRef} defaultChecked = {this.state.ShowProjectValue} /> */}
                          <label htmlFor="ShowProjectName">Show Project Name</label>
                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch48" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch48" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowCategoryName" onChange = {this.onChangeCategoryName}
                          ref ={this.ShowCategoryRef} defaultChecked = {this.state.ShowCategoryValue} /> */}
                          <label htmlFor="ShowCategoryName">Show Category Name</label>
                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch49" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch49" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowPriorityName" onChange = {this.onChangePriorityName}
                          ref ={this.ShowPriorityRef} defaultChecked = {this.state.ShowPriorityValue} /> */}
                          <label htmlFor="ShowPriorityName">Show Priority</label>
                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch50" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch50" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowDueDateName" onChange = {this.onChangeDueDateName}
                          ref ={this.ShowDueDateRef} defaultChecked = {this.state.ShowDueDateValue} /> */}
                          <label htmlFor="ShowDueDateName">Show Due Date</label>
                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch51" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch51" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowStatusName" onChange = {this.onChangeStatusName}
                          ref ={this.ShowStatusRef} defaultChecked = {this.state.ShowStatusValue} /> */}
                          <label htmlFor="ShowStatusName">Show Status</label>
                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch52" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch52" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowTotalMessagesName" onChange = {this.onChangeTotalMessagesName}
                          ref ={this.ShowTotalMessagesRef} defaultChecked = {this.state.ShowTotalMessagesValue} /> */}
                          <label htmlFor="ShowTotalMessagesName">Show Total Messages</label>
                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch53" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch53" ></label>
                          </div>

                        </div>

                        <div className="custom-control custom-checkbox" style={{ display: 'flex', justifyContent: ' space-between', alignItems: 'center' }}>
                          {/* <input type="checkbox" className="custom-control-input" id="ShowAssignessName" onChange = {this.onChangeAssignessName}
                          ref ={this.ShowAssignessRef} defaultChecked = {this.state.ShowAssignessValue} />                     */}
                          <label htmlFor="ShowAssignessName">Show Assigness</label>
                          <div className="custom-control custom-switch custom-control-inline mb-1">
                            <input type="checkbox" className="custom-control-input" id="customSwitch54" />
                            <label className="custom-control-label mr-1" htmlFor="customSwitch54" ></label>
                          </div>

                        </div>
                      </div>



                      <div className="col-md-5 ml-5" style={{
                        boxShadow: 'rgb(234, 234, 234) -2px 0px 10px 4px', borderRadius: '20px', marginTop: '30px',
                        padding: '30px !important', lineHeight: '40px'
                      }}>


                        <p style={{ textAlign: 'center' }} className="mt-2">Default Work Space</p>
                        <ul className="list-group">
                          {/* {console.log(this.state.DefaultWorkSpace  === "Ws_3" , "html>>>")} */}
                          <li onClick={() => this.saveDefaultWorksapce("Ws_1")} className={this.state.DefaultWorkSpace === "Ws_1" ? "list-group-item active" : "list-group-item"}>Ws_1</li>
                          <li onClick={() => this.saveDefaultWorksapce("Ws_2")} className={this.state.DefaultWorkSpace === "Ws_2" ? "list-group-item active" : "list-group-item"}>Ws_2</li>
                          <li onClick={() => this.saveDefaultWorksapce("Ws_3")} className={this.state.DefaultWorkSpace === "Ws_3" ? "list-group-item active" : "list-group-item"}>Ws_3</li>
                          <li onClick={() => this.saveDefaultWorksapce("Ws_4")} className={this.state.DefaultWorkSpace === "Ws_4" ? "list-group-item active" : "list-group-item"}>Ws_4</li>
                          <li onClick={() => this.saveDefaultWorksapce("Ws_5")} className={this.state.DefaultWorkSpace === "Ws_5" ? "list-group-item active" : "list-group-item"}>Ws_5</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </>
    );
  }
}
export default DashBoardSetting;




