import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
//import GroupTitle from '../titles/GroupTitle';
//import AddRow from './AddRow';
//import BoardGroupBottom from './BoardGroupBottom';
import DroppableRow from './dropable-row';

class SearchDroppableList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupTitle: this.props.label,
            isWorkspaceOwner: this.props.isWorkspaceOwner,
            isWorkspaceAdmin: this.props.isWorkspaceAdmin,
            isBoardAdmin: this.props.isBoardAdmin,
            editRowRestrictedMembers: this.props.editRowRestrictedMembers,
            permission: this.props.permission

        }
        this.authUser = JSON.parse(localStorage.getItem("authUser"));
    }

    componentDidMount() {
       // console.log('llk1', this.props)
        this.setState({ isWorkspaceOwner: this.props.isWorkspaceOwner })
        this.setState({ isWorkspaceAdmin: this.props.isWorkspaceAdmin })
        this.setState({ isWorkspaceAdmin: this.props.isBoardAdmin })
        this.setState({ editRowRestrictedMembers: this.props.editRowRestrictedMembers })
        this.setState({ permission: this.props.permission })
        console.log("All Data",this.props)
    }


    componentWillReceiveProps(nextProps) {
        this.setState({ isWorkspaceOwner: nextProps.isWorkspaceOwner })
        this.setState({ isWorkspaceAdmin: nextProps.isWorkspaceAdmin })
        this.setState({ isBoardAdmin: nextProps.isBoardAdmin })
        this.setState({ editRowRestrictedMembers: nextProps.editRowRestrictedMembers });
        this.setState({ permission: this.props.permission })
    }

    filterRowForRestrictedMembers = (cellItems, cat) => {
        if (this.state.isWorkspaceOwner || this.state.isWorkspaceAdmin || this.state.isBoardAdmin) {
            return true;
        }
        if (cat === 'header') {
            return true;
        }
        if (this.state.editRowRestrictedMembers ? this.state.editRowRestrictedMembers.includes(this.authUser._id) : false) {
            let Index = cellItems.findIndex(cell => cell.key === 'people');
            if (cellItems[Index].body ? cellItems[Index].body.includes(this.authUser._id) : false) {
                return true;
            } else {
                return false;
            }
        } else {
            return true
        }

    }


    filterRowForRestrictedViews = (cellItems, cat) => {
        if (this.state.isWorkspaceOwner || this.state.isWorkspaceAdmin || this.state.isBoardAdmin || this.state.permission != 'edit-personal') {
            return false;
        }

        let Index = cellItems.findIndex(cell => cell.key === 'people');
        if (cellItems[Index].body ? cellItems[Index].body.includes(this.authUser._id) : false) {
            return true;
        } else {
            return false;
        }
    }
    render() {

        const { _id, groupItems, label, tint, members } = this.props;
        console.log("888888",this.props)
        //console.log("groupItems",this.props)
        // let regex = new RegExp(this.props.searchItem, 'i');
        // let dataitem=groupItems.findIndex(product => product.cellItems.some(item =>regex.test(item.body)) || regex.test(product.title));
        
        // let dataArrayItem=[]
        // if(groupItems[dataitem])
        // {
        //     dataArrayItem.splice(0,0,groupItems[0])
        //     dataArrayItem.splice(1,0,groupItems[dataitem])
        //     groupItems.length=0
        //     groupItems.push({...dataArrayItem})
        // }
        
        // console.log("searchItemgroupOldItems",groupItems)
        // console.log("searchItemIndex",groupItems[dataitem])
        // let groupNewItems=groupItems.slice(dataitem,1)
        // console.log("searchItemgroupItems",groupNewItems)
        
        return (
            <Droppable droppableId={_id}>
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                    >
                        <div className={`holder card p-2 holder--tint-${tint} ${(members ? members.includes('ViewOnly_' + this.authUser._id) : false) ? "disablediv" : ""}`}
                            style={{ display: 'inline-flex', minWidth: '100%', 'border': 'none' }}>
                            {/* <GroupTitle id={_id} groupInd={this.props.groupInd} groupTitle={label} {...groupItems} groupDragProvided={this.props.groupDragProvided} /> */}
                            <div style={{ backgroundColor: '#EAEAEA' , 'padding': '18px 20px 0px' , 'text-align' : 'left' , 'display' : 'grid' , 'line-height' : '11px'  }}>
                            <label style={{fontSize: '15px',fontWeight: '600','float':'left'}} dangerouslySetInnerHTML={{__html:this.props.title.replace(/\<br\>/g," ")}}></label> 
                            <label style={{textAlign:'left',fontSize: '13.3px','float':'left'}}>{label}</label>
                            </div>
                            
                            <div className='holder__content' style={{ 'overflow-y' : 'scroll' , 'overflow-x' : 'scroll' ,  width: '1500px'  }}>
                                <div className='list'>
                                    {groupItems
                                        .filter(items => this.state.permission == 'edit-personal' ? this.filterRowForRestrictedMembers(items.cellItems, items.category) : true)
                                        .map((item, index) => {
                                            console.log("itemitemitemitem",item)
                                            //item.cellItems.filter(x => x.body === this.props.searchItem);
                                            //item.cellItems = item.cellItems.filter(x => x.body === this.props.searchItem);
                                            // console.log("searchItemIndex",searchItemIndex)
                                            //item.cellItems=item.cellItems.slice(searchItemIndex,1)
                                            if (item.category === 'header') {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div
                                                            className='list__item'
                                                        >
                                                            <div className=''>
                                                                <DroppableRow
                                                                    {...item}
                                                                    groupId={_id}
                                                                    groupInd={this.props.groupInd}
                                                                    rowInd={index}
                                                                    targetAreaIndex={this.props.targetAreaIndex}
                                                                    category={item.category}
                                                                    groupItemProvided={null}
                                                                    key={this.props.index}
                                                                    index={this.props.index}
                                                                    columnHeader={this.props.columnHeader}
                                                                    members={this.props.members}
                                                                //   isWorkspaceOwner={this.state.isWorkspaceOwner}
                                                                // isWorkspaceAdmin={this.state.isWorkspaceAdmin}
                                                                // isBoardAdmin={this.state.isBoardAdmin}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div
                                                            className='list__item d-none'
                                                        // key={item.id}
                                                        >
                                                            <Draggable
                                                                draggableId={item._id}
                                                                index={index}
                                                            >
                                                                {(provided) => {
                                                                    return (
                                                                        <div
                                                                            className='group-row'
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            ref={provided.innerRef}
                                                                        >

                                                                        </div>
                                                                    );
                                                                }}
                                                            </Draggable>
                                                        </div>
                                                    </React.Fragment>
                                                );
                                            } else {
                                                //item.cellItems = item.cellItems.filter(x => x.body === this.props.searchItem);
                                            //      var searchItemIndex = item.cellItems.filter(x => x.body === this.props.searchItem);
                                            // console.log("searchItemOld",item.cellItems)
                                            //   console.log("searchItemIndex",item.cellItems.filter(x => x.body === this.props.searchItem))
                                                return (
                                                    <div
                                                        className='list__item'
                                                        //  className={this.filterRowForRestrictedViews(item.cellItems)?'list__item':'list__item disablediv'}
                                                        key={item._id}
                                                    >
                                                        <Draggable
                                                            draggableId={item._id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => {
                                                                // console.log(" Provided: ", provided);
                                                                return (
                                                                    <div
                                                                        className='group-row'
                                                                        {...provided.draggableProps}
                                                                        ref={provided.innerRef}
                                                                        style={{
                                                                            height: snapshot.isDragging ? '0px !important' : 'auto',
                                                                            ...provided.draggableProps.style,
                                                                        }}
                                                                    // {...provided.dragHandleProps}
                                                                    >
                                                                        {/* {item.id} */}
                                                                        <div>
                                                                            <DroppableRow
                                                                                {...item}
                                                                                groupId={_id}
                                                                                groupInd={this.props.groupInd}
                                                                                rowInd={index}
                                                                                targetAreaIndex={this.props.targetAreaIndex}
                                                                                category={item.category}
                                                                                groupItemProvided={provided}
                                                                                key={this.props.index}
                                                                                index={this.props.index}
                                                                                columnHeader={this.props.columnHeader}
                                                                                searchItem={this.props.searchItem}
                                                                                mainBoardId={this.props.mainBoardId}
                                                                                members={this.props.members}
                                                                               // isWorkspaceOwner={this.state.isWorkspaceOwner}
                                                                               // isWorkspaceAdmin={this.state.isWorkspaceAdmin}
                                                                              //  isBoardAdmin={this.state.isBoardAdmin}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    </div>
                                                )
                                            }
                                            
                                        })}
                                    {provided.placeholder}
                                    {/* <AddRow groupInd={this.props.groupInd} id={_id} {...groupItems} /> */}
                                    {/* <BoardGroupBottom groupInd={this.props.groupInd} groupId={_id} groupItems={groupItems} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Droppable >
        );
    }
}

// const mapDispatchToProps = { changeGroupTitle };

// export default connect(() => ({}), mapDispatchToProps)(DroppableList);

export default SearchDroppableList;