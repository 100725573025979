import React from 'react';

const DiscoverToolbar = (params) => {
  return (
    <div className="row dash-blue fixed-top">
     
      <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
        <div>
          <div className="inner-section">
            <h3>Project Management</h3>
            <div style={{ marginTop: '-10px' }}>
              <p className="mt-1">Discover</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
        <table className="align-items-center table-dashboard-section-one">
          <tbody><tr>
            <td rowSpan={2} width="50px">
              <ul className="nav navbar-nav bookmark-icons">
                <li className="nav-item d-none d-lg-block nav-search" data-placement="top">
                  <a className="nav-link nav-link-search cursor-pointer" data-toggle="tooltip" title="Search your Project">
                    <img src="images/icon/dashboard-icons/white icons-39.png" />
                  </a>
                </li>
              </ul>
            </td>
            <td width="100px">All Projects</td>
            <td width="10px"> InProgress</td>
            <td width="80px">Review</td>
            <td width="100px">Completed</td>
            <td width="80px">Overdue</td>
          </tr>
            <tr style={{ fontWeight: 600 }}>
              <td id="value">530</td>
              <td id="value">400</td>
              <td id="value">100</td>
              <td id="value">30</td>
              <td id="value">400</td>
            </tr>
          </tbody></table>
        <table className="table-dashboard-middle-section" id="res-div-one">
          <tbody><tr>
            <td rowSpan={2} width="100px">Priority</td>
            <td width="50px">High</td>
            <td width="80px">Medium</td>
            <td width="50px">Low</td>
          </tr>
            <tr style={{ fontWeight: 500 }}>
              <td>18</td>
              <td>25</td>
              <td>13</td>
            </tr>
            <tr>
            </tr></tbody></table>
        {/* <table className="align-items-center table-dashboard-section-two">
          <tbody><tr>
            <td rowSpan={2} style={{ fontSize: '16px', fontWeight: 100, width: '40px' }}>Sort</td>
          </tr>
            <tr>
              <td>&nbsp;&nbsp;<img src="images/icon/dashboard-icons/Panel icons-32.png" /></td>
            </tr>
            <tr>
            </tr></tbody></table> */}
      </div>
    </div>
  );
}
export default DiscoverToolbar;
