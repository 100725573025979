import React, { Component, useState, useEffect, useRef } from 'react';
import { useFullScreen } from 'react-browser-hooks'
import { Modal } from "react-responsive-modal";
import SchedulerFilter from './scheduler-filter.model'
import SchedulerExport from './scheduler-export.model'

const SchedulerToolBar = (props) => {
  const { toggle, fullScreen } = useFullScreen()
  const [isOpenFilter, setOpenFilter] = useState(false);
  const [isOpenExport, setOpenExport] = useState(false);
  return (
    <div className="row dash-blue fixed-top" id='bluetop'>
      
      <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
        <div>
          <div className="inner-section">
            <h3 style={{ fontFamily: 'myriad-pro,sans-serif !important', fontWeight: 600 }}>Scheduler</h3>
            <div style={{ marginTop: '-10px' }}>
              <p className="mt-1">Calender Planner</p>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
        <table className="align-items-center table-newdashboard-section-scheduler-one">
          <tbody><tr>
            <td rowSpan={2} style={{ width: '50px' }}>
              <ul className="nav navbar-nav bookmark-icons">
                <li className="nav-item d-none d-lg-block nav-search" data-placement="top">
                  <a className="nav-link nav-link-search cursor-pointer" data-toggle="dropdown" aria-expanded="false" title="Search">
                    <img src="./images/icon/dashboard-icons/scheduler-icons/search copy.png" style={{ width: '25px' ,  height : '25px' }} />
                  </a>
                  <div className="dropdown-menu text-color w-xl animated fadeInUp" style={{ right: 'auto', width: '240px' }}>
                    <form className="navbar-form form-inline navbar-item m-a-0 p-x v-m ng-pristine ng-valid" role="search">
                      <div className="form-group l-h m-a-0">
                        <div className="input-group">
                          <input type="text" className="form-control" placeholder="Search"
                            value={props.searchText} onChange={props.onSearchChange}
                            id="search-box" />
                          <span className="input-group-btn">
                            <button type="submit" className="btn blue-A200 b-a no-shadow" onClick={props.onSearchClick} id="tbSearch">
                              <i className="bx bx-search"></i>
                            </button>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                </li>
              </ul></td>
            <td rowSpan={2} style={{ width: '50px' }}><a href='#' data-action="expand" onClick={props.onRefreshClick}><img src="./images/icon/dashboard-icons/scheduler-icons/refresh copy.png" style={{ cursor: 'pointer' , width: '25px' ,  height : '25px' }} /></a></td>
            <td rowSpan={2} style={{ width: '50px' }}><a href="#" data-action="expand" onClick={toggle}>
              <img
                src={fullScreen ? './images/icon/dashboard-icons/scheduler-icons/zoomin copy.png' : './images/icon/dashboard-icons/scheduler-icons/zoomin copy.png'}
                 style={{ cursor: 'pointer' , width: '25px' ,  height : '25px' }} /></a></td>

            <td rowSpan={2} style={{ width: '50px' }}><a ><img src="./images/icon/dashboard-icons/module-icons/white icons-38.png" style={{ cursor: 'pointer' , width: '25px' ,  height : '25px' }} onClick={() => setOpenExport(true)} /></a></td>


            <td rowSpan={2} style={{ width: '50px' }}><a href="#" data-toggle="tooltip" data-target="Filter" onClick={() => setOpenFilter(true)}><img src="./images/icon/dashboard-icons/module-icons/share-white.png" style={{ cursor: 'pointer' , width: '25px' ,  height : '25px' }} /></a></td>

            <td>
              <ul id="category" style={{ display: 'flex', listStyleType: 'none' }}>
                {props.categoriesCount.map((c, index) => (
                  <li key={index} className="scheduler-list-count">{c.category[0].name} {c.count}</li>
                ))}

              </ul>
            </td>


          </tr>
            <tr style={{ fontWeight: 600 }}>
              <td />
              <td />
              <td />
              <td />
              <td />
            </tr>
          </tbody></table>
     
      </div>
      <SchedulerFilter open={isOpenFilter} onClose={() => setOpenFilter(false)} closeOnOverlayClick={false} onFilterClick={props.onFilterClick} />
      <SchedulerExport open={isOpenExport} onClose={() => setOpenExport(false)} closeOnOverlayClick={false} onExportClick={props.onExportClick} />
    </div>


  )
}

export default SchedulerToolBar;