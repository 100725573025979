import React from 'react';
import Form from '../common/form.jsx';
import { makeid } from '../../../util/index';
var Field_options = [{ value: 'text', label: 'Text Field' },
  { value: 'dropdown', label: 'Dropdown' },
  { value: 'date', label: 'Calendar Field' },];
class AddField extends Form {
    constructor(props) {
      super(props);
      this.state = {
        passportIssuedDate: '',
        option: [],
        selectedGenderOption: null,
      }
  }
  
  componentDidMount() {
    const { groupItems, index, jsonschema } = this.props;
    console.log('addfieldgroupItems==>', groupItems)
    this.setState({ groupItems: groupItems });
  }
  
  onChangepassportIssuedDate = (date) => {
    let value = date;
    this.setState({
      passportIssuedDate: value
    })
    console.log(value);
  }
  
  onChangeFirstName = (e) => {
    let value = e.target.value;
    this.setState({
      firstName: value
    })
    console.log(value);
  }
  
  onChangeop1 = (e) => {
    let value = e.target.value;
    this.setState({
      optionone: value
    })
    console.log(value);
  }
  
  onChangeop2 = (e) => {
    let value = e.target.value;
    this.setState({
      optiontwo: value
    })
    console.log(value);
  }
  
  handleChange_Gender = selectedGenderOption => {
    // this.resetSelectGenderDropDown();
    this.setState(
      { selectedGenderOption },
      () => console.log(`selectedGenderOption selected:`, this.state.selectedGenderOption)
    );
  }
  
  getHeaderColumn = (key) => {
    switch (key) {
      case 'text':
        return 'Text';
      case 'date':
        return 'Date';
      case 'dropdown':
        return 'Dropdown';
      default:
        return '';
    }
  }
  
  initialGroupCellItemBody = (key) => {
    let date = new Date();
    // let formatDate = date.getFullYear() + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + ("0" + date.getDate()).slice(-2);
    switch (key) {
       case 'tags':
          return JSON.stringify([]);
       case 'dropdown':
          return JSON.stringify([]);
       case 'people':
          return JSON.stringify([]);
       default:
          return '';
    }
  }
  
  addNewRow = (title) => {
    if (title !== "") {
        // const { groupInd, id } = this.props;
        // const boardId = this.props.boardData.boardID;
        // const currentRowHeaderData = this.props.boardData.data[0].groupItems[0].cellItems;
        // const groupItems = this.props.boardData.data[groupInd].groupItems;
        const newCellItems = [];
        // for (let i = 0; i < currentRowHeaderData.length; i++) {
        //     let body = this.initialGroupCellItemBody(currentRowHeaderData[i].key);
            // let cellItem = {};
            // newCellItems.push(cellItem);
        // }
  
        const newRowItem = [{
            _id: makeid('row', 5),
            title: '',
            category: 'body',
            cellItems: []
        },
        {
          _id: makeid('row', 5),
          title: 'task',
          category: 'header',
          cellItems: []
      }
      ]
  
      return newRowItem
  
        // console.log("newRowItem====>", newRowItem)
  
        // this.props.addRow({
        //     boardId: boardId,
        //     groupID: id,
        //     newGroupItem: newRowItem,
        //     groupInd: groupInd
        // })
  
        // this.setState({ value: "" })
    }
  }
  
  addGroupColumn = (groupItems, req) => {
        console.log('groupItems==>', groupItems)
        // console.log('checkrowitem==>', groupItems[groupItems.length-1].cellItems.length)
        if(groupItems.length === 0 || groupItems[groupItems.length-1].cellItems.length === 3)
        {
        let rowitem = this.addNewRow('hello')
        console.log('rowitem==>', rowitem)
        console.log('concatrowitem==>', groupItems.concat(rowitem))
        var groupItems = groupItems.concat(rowitem);
        var group = [{'groupItems': groupItems}]
        }
        else
        {
          var group = [{'groupItems': groupItems}]
        }
        
        // let group = [{'groupItems': groupItems}]
        let groups = group;
       console.log('addGroupColumngroups==>', groups)
        // const samekeyColumns = columnHeaders.filter(column => column.key === req.body.key);
        // let headerBody = '';
        // if (samekeyColumns.length === 0) {
        //     headerBody = this.getHeaderColumn(req.body.key); // pass field title in column
        // } else {
        //     headerBody = this.getHeaderColumn(req.body.key) + " " + (samekeyColumns.length)
        // }
  
        let suggestions = [];
  
        // if (req.body.key === 'status') {
        //     suggestions = [
        //         { _id: 'status_1', name: 'Waiting for work', type: 'Waiting for work', color: '#c4c4c4' },
        //         { _id: 'status_2', name: 'Working on it', type: 'Working on it', color: '#fdab3d' },
        //         { _id: 'status_3', name: 'Waiting for review', type: 'Waiting for review', color: '#579bfc' },
        //         { _id: 'status_4', name: 'Approved', type: 'Approved', color: '#225091' },
        //         { _id: 'status_5', name: 'Done', type: 'Done', color: '#00c875' },
        //         { _id: 'status_6', name: 'Stuck', type: 'Stuck', color: '#e2445c' },
        //     ];
        // } else if (req.body.key === "dropdown") {
            
        //     suggestions = [];
        //     //columnHeaders.filter(header => header._id === req.body.columnId)[0].suggestions;
        // }
        let newColumn = {
            _id: makeid('column', 4),
            title: req.body.body,
            suggestions: suggestions,
            key: req.body.key
        }
  
       // workspaceBoard.columnHeaders = [...columnHeaders, newColumn];
        // for (let i = 0; i < groups.length; i++) {
        //     for (let j = 0; j < groups[i].groupItems.length; j++) {
                if (groups[0].groupItems[groupItems.length-1].category === 'header') {
                    let cellItem = {
                        _id: makeid('col', 7),
                        key: req.body.key,
                        body: Array.isArray(req.body.body) ? req.body.name : req.body.body,
                        columnId: newColumn._id
                    }
                    if (req.body.leftIndex === 'none') {
                        groups[0].groupItems[groupItems.length-1].cellItems.push(cellItem);
                    } else {
                        groups[0].groupItems[groupItems.length-1].cellItems.splice(parseInt(req.body.leftIndex + 1), 0, cellItem);
                    }
                } 
                
                if (groups[0].groupItems[groupItems.length-2].category === 'body') {
                    let body = Array.isArray(req.body.body) ? req.body.body : null;
                    // if (req.body.addType === "add") {
                    //     body = req.body.body;
                    // } else if (req.body.addType === "duplicate") {
                    //     body = groups[i].groupItems[j].cellItems[req.body.leftIndex].body;
                    // }
                    let cellItem = Array.isArray(req.body.body) ? {
                        _id: makeid('col', 7),
                        key: req.body.key,
                        body: '',
                        options: body,
                        columnId: newColumn._id
                    } :
                    {
                      _id: makeid('col', 7),
                      key: req.body.key,
                      body: body,
                      columnId: newColumn._id
                    }
                    // if (req.body.key === "formula" && req.body.leftIndex != 'none') {
                     
                    //     cellItem.activeItems = groups[i].groupItems[j].cellItems[req.body.leftIndex].activeItems;
                    //     cellItem.equation = groups[i].groupItems[j].cellItems[req.body.leftIndex].equation
                    // }
  
                    console.log('cellItem ===> ', cellItem);
  
                    if (req.body.leftIndex === 'none') {
                        groups[0].groupItems[groupItems.length-2].cellItems.push(cellItem);
                    } else {
                        groups[0].groupItems[groupItems.length-2].cellItems.splice(parseInt(req.body.leftIndex + 1), 0, cellItem);
                    }
  
                }
        //     }
        // }
  
        group = groups;
        console.log('group==>', group)
        return group
    
        
  }
  createField(groupItems) {
    const {jsonschema} = this.props
    console.log('creategroupItems==>', groupItems)
    var req = this.state.selectedGenderOption.label !== 'Dropdown' ? {body:{key: this.state.selectedGenderOption.value, body: this.state.firstName, leftIndex : 'none'}} : {body:{key: this.state.selectedGenderOption.value, body: this.state.option, name: this.state.firstName, leftIndex : 'none'}}
    var group = this.addGroupColumn(groupItems, req)
    jsonschema[this.props.index].groupItems = group[0].groupItems
    this.props.handler(jsonschema)
    this.setState({firstName: '', selectedGenderOption: null, option: []})
  }
  addField(newitem) {
    if(newitem!=='') {
    var item = this.state.option
    item.push(newitem)
    this.setState({option: item, optionone: ''})
    }
  }
    render() {
      const { passportIssuedDate, passportIssuedDateError, selectedGenderOption, selectGenderError, firstName, firstNameError, option, optionone, optiontwo } = this.state;
      const { index, groupItems } = this.props;
       return (
  
          <>
             {/* Vertically Centered modal Modal */}
             <div className="modal fade" id={"exampleModalCenter"+index} tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-dialog-centered modal-dialog-scrollable" role="document">
                   <div className="modal-content">
                      <div className="modal-header">
                         <h5 className="modal-title" id="exampleModalCenterTitle">Add Field</h5>
                         <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="bx bx-x" />
                         </button>
                      </div>
                      <div className="modal-body" style={{ padding: '20px 50px', height: 200 }}>
                         <div className="row">
                           
                                  <div className="col-md-6">
                                  {this.renderUserProfileSelectOptionsControl(selectedGenderOption, this.handleChange_Gender, Field_options, "Field Type", selectGenderError)}
                                  </div>
  
  
                                  <div className="col-md-6">
                                    {this.renderUserProfileInput("text", firstName, "Field Title", firstNameError, this.onChangeFirstName)}
                                </div>
  
                                </div>
  
                                {this.state.selectedGenderOption?.label === 'Dropdown' &&
                                  <div className="row">
                           
  
                                  <div className="col-md-6">
                                  {this.renderUserProfileInput("text", optionone, "Field", firstNameError, this.onChangeop1)}
                                  {option.map((option, index)=><div key={index}>{option}</div>)}
                                  </div>
  
  
                                  <div className="col-md-6">
                                  <button type="button" className="btn btn-primary round mr-1 mb-1" style={{ borderRadius: '5px', width: 185 }} onClick={()=>this.addField(optionone)}>Add Field</button>
                                    
                                </div>
  
                                </div>
                                }
  
                              <div className="row">
  
                                  <div className="col-md-6">
                                     
                                     
                                  </div>
  
  
                                  <div className="col-md-6">
                                  <button type="button" className="btn btn-primary round mr-1 mb-1" style={{ borderRadius: '5px', width: 185 }} onClick={()=>this.createField(groupItems)}>Create Field</button>
                                  </div>
  
                                </div>
  
  

                         </div>
  
  
                      </div>
                   </div>
                </div>
  
  
  
  
          </>
  
       );
    }
  }
  export default AddField