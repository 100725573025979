import React, { Component } from 'react';
import MainMenu from "../../shared/components/main-menu";
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';
class AddActivity extends Component {
   state = {}
   render() {
      return (

         <>
            <MainMenu link={`addactivity`} />
            <div id="whole-layout">
               <NavBar />
               <div id="appdata" className='app-content content fixed-top messenger-layout'>
                  <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
                  <div className="content-wrapper">
                     <div id="mySidenav" className="sidenav">
                        <a href="#" className="closebtn" onClick={() => {
                           document.getElementById("mySidenav").style.width = "0%";
                        }}>&times;</a>
                     </div>
                     <div>
                        <div className="modal fade" id="modal-activity" data-backdrop="static" style={{ position: 'fixed', top: '50px', left: '-50px' }}>
                           <div className="modal-dialog modal-lg">
                              <div className="modal-content" style={{ padding: 0, width: '130%', margin: '0 auto', border: 'none', marginTop: '30px', borderRadius: '15px 15px 15px 15px', minHeight: '650px', overflow: 'auto' }}>
                                 <div className="modal-header _600" style={{ backgroundColor: '#22386B', color: '#fff', borderRadius: '15px 15px 0px 0px', height: '70px' }}>
                                    <div style={{ display: 'inline-flex', margin: '10px auto' }}>
                                       <span>Add Project</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label className="switch">
                                          <input type="checkbox" defaultChecked id="ckmainadd" />
                                          <span className="slider round" />
                                       </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{}}>Add Task</span>
                                    </div>
                                    <button type="button" className="close-pink" ng-click="clearTask()" aria-label="Close" data-dismiss="modal">
                                       <div style={{ color: '#ffffff', marginTop: '-2px' }}><span aria-hidden="true">×</span></div>
                                    </button>
                                 </div>
                                 <div id="divTask">
                                    <form className="form-horizontal" ng-submit="createTask(taskForm.$valid)" noValidate name="taskForm">
                                       <div className="modal-body" style={{ padding: '10px 20px 0px 0px' }}>
                                          <div className="form-group row">
                                             <div className="col-md-12 col-lg-12 d-flex">
                                                <div className="col-md-2 col-lg-2">
                                                   <label>Task Title:<span className="required" style={{ color: 'red' }}> *</span></label>
                                                </div>
                                                <div className="col-md-10 col-lg-10">
                                                   <input type="text" ng-model="task.name" className="text-line" autoComplete="off" ng-required="true" ng-maxlength={100} style={{ width: '100%', borderBottom: '1px solid #e5e2e2' }} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row">
                                             <div className="col-md-12 col-lg-12 d-flex">
                                                <div className="col-md-2 col-lg-2">
                                                   <label>Task Details:</label>
                                                </div>
                                                <div className="col-md-10 col-lg-10">
                                                   <textarea className="form-control ng-pristine ng-untouched ng-valid ng-empty ng-valid-maxlength" ng-model="task.description" ng-maxlength={200} style={{ height: '70px', backgroundColor: '#e5e2e2', fontSize: '12.5px', outline: 'none', border: 'none' }} defaultValue={""} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row">
                                             <div className="col-md-12 col-lg-12 d-flex">
                                                <div className="col-md-2 col-lg-2">
                                                   <label className="form-control-label">Visiblity:</label>
                                                </div>
                                                <div className="col-md-4 col-lg-4">
                                                   <div style={{ marginLeft: '-15px' }}>
                                                      <label ng-repeat="item in visibility" ng-model="task.visibilitytypeid">
                                                         <input type="radio" name="visibility" defaultChecked="checked" defaultValue="{{item.id}}" style={{ verticalAlign: 'text-bottom' }} />  {'{'}{'{'}item.visibleName{'}'}{'}'} &nbsp; &nbsp;
                            </label>
                                                   </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                   <label className="form-control-label">Project Cost:</label>
                                                   <input type="number" ng-model="task.projectcost" className="text-line" style={{ width: '120px' }} />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <select id="ddlcurrency" className="form-control">
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row">
                                             <div className="col-md-12 col-lg-12 col-12 d-flex">
                                                <div className="col-md-2 col-lg-2 d-flex">
                                                   <label>Client Name:</label>
                                                </div>
                                                <div className="col-md-10 col-md-10 col-12">
                                                   <input type="text" className="text-line-two" readOnly="readonly" id="txtcustomername" ng-model="task.customername" autoComplete="off" />
                          &nbsp;&nbsp;
                          <a style={{ width: '5%' }} className="icon" ng-click="openclientPopup()">
                                                      <span className="hidden-sm-down right-head"><i className="bx bxs-plus-circle" /></span>
                                                   </a>
                          &nbsp;
                          <span style={{ width: '10%', verticalAlign: 'bottom', fontWeight: 300 }}>(OR)</span>
                                                   <select id="ddlcustomer" className="form-control">
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row">
                                             <div className="col-md-12 col-lg-12 d-flex">
                                                <div className="col-md-2 col-lg-2 col-12 d-flex">
                                                   <label>Project Name:</label>
                                                </div>
                                                <div className="col-md-10 col-lg-10 col-12">
                                                   <input type="text" className="text-line-two" readOnly="readonly" id="txtprojectname" ng-model="task.projectname" autoComplete="off" />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style={{ width: '10%', verticalAlign: 'bottom', fontWeight: 300 }}>(OR)</span>
                                                   <select id="ddproject" className="form-control">
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ margin: '-10px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12">
                                                <label>Category Name</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12">
                                                <div className="form-label-group">
                                                   <select id="ddlcategories" style={{ height: '25px', fontSize: '12.5px' }}>
                                                      <option />
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ margin: '-10px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12">
                                                <label>Assignees</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12">
                                                <div className="form-label-group">
                                                   <select className="form-control" id="ddlassignees" style={{ height: '25px', fontSize: '12.5px' }}>
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ margin: '-10px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12">
                                                <label>Task Managers</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12">
                                                <div className="form-label-group">
                                                   <select className="form-control" id="ddltaskmanagers" style={{ height: '25px', fontSize: '12.5px' }} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ margin: '-10px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12">
                                                <label>Collaborators</label>
                                             </div>
                                             <div className="col-md-10 col-12">
                                                <div className="form-label-group">
                                                   <select className="form-control" id="ddlcollaborators" />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ margin: '-10px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12">
                                                <label>Followers</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12">
                                                <div className="form-label-group">
                                                   <select className="form-control" id="ddlfollowers" />
                                                </div>
                                             </div>
                                          </div>
                                          <br />
                                          <div className="form-group row" style={{ marginTop: '20px auto' }}>
                                             <div className="col-md-12 col-12">
                                                <div className="form-label-group d-flex">
                                                   <div className="col-md-2 col-lg-2" style={{ display: 'inline-block', marginTop: '10px', paddingLeft: '30px' }}>
                                                      <img src="Content/images/Mobile icons-37.png" style={{ width: '20px', height: '20px' }} /><label>Forecast</label>
                                                   </div>
                                                   <div className="col-md-10 col-lg-10 d-flex" style={{ backgroundColor: '#e5e5e5', padding: '15px', margin: '0 auto', borderRadius: '5px' }}>
                                                      <div className="col-md-5 col-lg-5 col-12">
                                                         <span style={{ fontSize: '12.5px', fontWeight: 300, verticalAlign: 'baseline' }}>Start</span> &nbsp;&nbsp;&nbsp;&nbsp;
                              <input type="date" ng-model="task.forecaststart" id="txtforecaststart" autoComplete="off" className="text-line" style={{ fontSize: '12px', fontWeight: 300 }} />
                                                      </div>
                                                      <div className="col-md-2 col-lg-2 col-12">
                                                      </div>
                                                      <div className="col-md-5 col-lg-5 col-12">
                                                         <div>
                                                            <span style={{ fontSize: '12.5px', fontWeight: 300, verticalAlign: 'baseline' }}>End</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="date" ng-model="task.forecastend" id="txtforecastend" autoComplete="off" className="text-line" style={{ fontSize: '12px', fontWeight: 300 }} />
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ marginTop: '-20px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12" style={{ paddingLeft: '30px' }}>
                                                <label>Priority</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12">
                                                <div style={{ marginLeft: '-15px' }}>
                                                   <label htmlFor="high" style={{ verticalAlign: 'text-bottom' }}>High</label>
                                                   <input type="radio" id="high" name="priority" defaultValue={3} />
                                                   <label htmlFor="medium" style={{ verticalAlign: 'text-bottom' }}>Medium</label>
                                                   <input type="radio" id="medium" name="priority" defaultValue={2} />
                                                   <label htmlFor="low" style={{ verticalAlign: 'text-bottom' }}>Low</label>
                                                   <input type="radio" id="low" name="priority" defaultValue={1} defaultChecked />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row">
                                             <div className="col-lg-2">
                                             </div>
                                             <div className="col-md-10 col-lg-10">
                                                <div style={{ marginLeft: '-15px' }}>
                                                   <label>Focus</label>
                                                   <input type="text" ng-model="task.focus" className="text-line-three" ng-maxlength={50} />
                                                   <label>Stage</label>
                                                   <input type="text" ng-model="task.stage" className="text-line-three" ng-maxlength={50} />
                                                   <label>Effort</label>
                                                   <input type="text" ng-model="task.effort" className="text-line-three" ng-maxlength={50} />
                                                   <label>Type</label>
                                                   <input type="text" ng-model="task.type" className="text-line-three" ng-maxlength={50} />
                                                   <label>Hours</label>
                                                   <input type="text" ng-model="task.hours" className="text-line-three" ng-maxlength={50} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ marginTop: '0 auto', display: 'none' }}>
                                             <div className="col-md-2 col-lg-2 col-12" style={{ paddingLeft: '30px' }}>
                                                <label htmlFor="ckextras">Extras</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12" style={{ marginTop: '10px' }}>
                                                <input type="checkbox" id="ckextras" onchange="checkExtras()" />
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ marginTop: '0 auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12" style={{ display: 'inline-block', paddingLeft: '45px' }}>
                                                <img src="Content/images/Mobile icons-37.png" style={{ width: '20px', height: '20px' }} /><label>Reminders</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 d-flex" style={{ backgroundColor: '#e5e5e5', padding: '15px', margin: '0 auto', borderRadius: '5px' }}>
                                                <div className="col-md-4 col-lg-4 col-12">
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-12">
                                                </div>
                                                <div className="col-md-2 col-lg-2 col-12">
                                                   {/* <button type="button" className="btn btn-light-danger mr-1 mb-1" ng-click="addReminder()" style="width:100%">
                    Add Reminder&nbsp;&nbsp;&nbsp;<i className="bx bxs-plus-circle" style="vertical-align:middle"></i>
                </button>*/}
                                                   <label style={{ background: 'none' }}>Add Reminder</label>&nbsp;<i className="bx bxs-plus-circle" ng-click="addReminder()" style={{ verticalAlign: 'middle', color: '#e8378f' }} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ float: 'right' }}>
                                             <div className="col-md-12 col-lg-12 col-12">
                                                {/*<input type="submit" className="btn btn-primary btn-sm p-x-md" value="Submit" />*/}
                                                <button type="submit" className="btn-custom-1" value="Submit">Submit</button> &nbsp;&nbsp;&nbsp;
                      </div>
                                          </div>
                                          {/*  <div className="form-group row" style="width:70%;float:left">
        <label className="col-lg-3 form-control-label">Forecast:</label>
        <div className="col-lg-7">
            <label className="col-lg-2 form-control-label" style="    margin-left: -13px;">Start</label>
            <div className="col-lg-10" style="margin-left: 46px;margin-top: -25px;">
                <input type="date" ng-model="task.forecaststart" id="txtforecaststart" autocomplete="off" className="text-line" />
            </div>
        </div>
    </div>

                          <div className="form-group row" id="" style="width:30%;float:right">
                              <label className="col-lg-2 form-control-label">End</label>
                              <div className="col-lg-10">
                                  <input type="date" ng-model="task.forecastend" id="txtforecastend" autocomplete="off" className="text-line" />

                              </div>
                          </div>*/}
                                          <div id="divextras" style={{ display: 'none' }}>
                                             <div className="form-group row">
                                                <div className="col-lg-2">
                                                </div>
                                                <label className="col-lg-2 form-control-label">Outstanding</label>
                                                <div className="col-lg-3">
                                                   <input type="number" id="txtoutsanding" className="text-line" />
                                                </div>
                                                <label className="col-lg-2 form-control-label">Overdue</label>
                                                <div className="col-lg-3">
                                                   <input type="number" id="txtoverdue" className="text-line" />
                                                </div>
                                             </div>
                                             <div className="form-group row">
                                                <div className="col-lg-2">
                                                </div>
                                                <label className="col-lg-2 form-control-label">Expected</label>
                                                <div className="col-lg-3">
                                                   <input type="number" id="txtexpected" className="text-line" />
                                                </div>
                                                <label className="col-lg-2 form-control-label">Collected</label>
                                                <div className="col-lg-3">
                                                   <input type="number" id="txtcollected" className="text-line" />
                                                </div>
                                             </div>
                                             <div className="form-group row">
                                                <div className="col-lg-2">
                                                </div>
                                                <label className="col-lg-2 form-control-label">Sales Contact</label>
                                                <div className="col-lg-3">
                                                   <input type="text" id="txtsalescontact" className="text-line" />
                                                </div>
                                                <label className="col-lg-2 form-control-label">Coll. Contact</label>
                                                <div className="col-lg-3">
                                                   <input type="text" id="txtcollcontact" className="text-line" />
                                                </div>
                                             </div>
                                             <div className="form-group row">
                                                <div className="col-lg-2">
                                                </div>
                                                <label className="col-lg-2 form-control-label">Sales Contact No.</label>
                                                <div className="col-lg-3">
                                                   <input type="number" id="txtsalecontactno" className="text-line" />
                                                </div>
                                                <label className="col-lg-2 form-control-label">Coll. Contact No.</label>
                                                <div className="col-lg-3">
                                                   <input type="number" id="txtcollcontactno" className="text-line" />
                                                </div>
                                             </div>
                                             <div className="form-group row">
                                                <div className="col-lg-2">
                                                </div>
                                                <label className="col-lg-2 form-control-label">Email Id.</label>
                                                <div className="col-lg-3">
                                                   <input type="email" id="txtsalesemail" className="text-line" />
                                                </div>
                                                <label className="col-lg-2 form-control-label">Email Id.</label>
                                                <div className="col-lg-3">
                                                   <input type="email" id="txtcollemail" className="text-line" />
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </form>
                                 </div>
                                 <div id="divProject" style={{ display: 'none' }}>
                                    <form className="form-horizontal" ng-submit="createProject(projectForm.$valid)" noValidate name="projectForm">
                                       <div className="modal-body" style={{ padding: '10px 20px 0px 0px' }}>
                                          <div className="form-group row">
                                             <div className="col-md-12 col-lg-12 d-flex">
                                                <div className="col-md-2 col-lg-2">
                                                   <label>Project Title:<span className="required" style={{ color: 'red' }}> *</span></label>
                                                </div>
                                                <div className="col-md-10 col-lg-10">
                                                   <input type="text" ng-model="project.name" className="text-line" autoComplete="off" ng-required="true" ng-maxlength={100} style={{ width: '100%', borderBottom: '1px solid #e5e2e2' }} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row">
                                             <div className="col-md-12 col-lg-12 d-flex">
                                                <div className="col-md-2 col-lg-2">
                                                   <label>Project Details:</label>
                                                </div>
                                                <div className="col-md-10 col-lg-10">
                                                   <textarea className="form-control" ng-model="project.description" ng-maxlength={200} style={{ height: '70px', backgroundColor: '#e5e2e2', fontSize: '12.5px', outline: 'none', border: 'none' }} defaultValue={""} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row">
                                             <div className="col-md-12 col-lg-12 d-flex">
                                                <div className="col-md-2 col-lg-2">
                                                   <label className="form-control-label">Visiblity:</label>
                                                </div>
                                                <div className="col-md-4 col-lg-4">
                                                   <div style={{ marginLeft: '-15px' }}>
                                                      <label ng-repeat="item in visibility" ng-model="project.visibilitytypeid">
                                                         <input type="radio" name="visibility1" defaultChecked="checked" defaultValue="{{item.id}}" style={{ verticalAlign: 'text-bottom' }} />  {'{'}{'{'}item.visibleName{'}'}{'}'} &nbsp; &nbsp;
                            </label>
                                                   </div>
                                                </div>
                                                <div className="col-md-6 col-lg-6">
                                                   <label className="form-control-label">Project Cost:</label>
                                                   <input type="number" ng-model="project.projectcost" className="text-line" style={{ width: '120px' }} />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <select id="ddlcurrency1" className="form-control">
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row">
                                             <div className="col-md-12 col-lg-12 col-12 d-flex">
                                                <div className="col-md-2 col-lg-2 d-flex">
                                                   <label>Client Name:</label>
                                                </div>
                                                <div className="col-md-10 col-md-10 col-12">
                                                   <input type="text" className="text-line-two" readOnly="readonly" id="txtcustomername1" ng-model="project.customername" autoComplete="off" />
                          &nbsp;&nbsp;
                          <a style={{ width: '5%' }} className="icon" ng-click="openclientPopup()">
                                                      <span className="hidden-sm-down right-head"><i className="bx bxs-plus-circle" /></span>
                                                   </a>
                          &nbsp;
                          <span style={{ width: '10%', verticalAlign: 'bottom', fontWeight: 300 }}>(OR)</span>
                                                   <select id="ddlcustomer1" className="form-control">
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ margin: '-10px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12">
                                                <label>Category Name</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12">
                                                <div className="form-label-group">
                                                   <select id="ddlcategories1" style={{ height: '25px', fontSize: '12.5px' }}>
                                                      <option />
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ margin: '-10px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12">
                                                <label>Assignees</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12">
                                                <div className="form-label-group">
                                                   <select className="form-control" id="ddlassignees1" style={{ height: '25px', fontSize: '12.5px' }}>
                                                   </select>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ margin: '-10px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12">
                                                <label>Task Managers</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12">
                                                <div className="form-label-group">
                                                   <select className="form-control" id="ddltaskmanagers1" style={{ height: '25px', fontSize: '12.5px' }} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ margin: '-10px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12">
                                                <label>Collaborators</label>
                                             </div>
                                             <div className="col-md-10 col-12">
                                                <div className="form-label-group">
                                                   <select className="form-control" id="ddlcollaborators1" />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ margin: '-10px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12">
                                                <label>Followers</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12">
                                                <div className="form-label-group">
                                                   <select className="form-control" id="ddlfollowers1" />
                                                </div>
                                             </div>
                                          </div>
                                          <br />
                                          <div className="form-group row" style={{ marginTop: '20px auto' }}>
                                             <div className="col-md-12 col-12">
                                                <div className="form-label-group d-flex">
                                                   <div className="col-md-2 col-lg-2" style={{ display: 'inline-block', marginTop: '10px', paddingLeft: '30px' }}>
                                                      <img src="Content/images/Mobile icons-37.png" style={{ width: '20px', height: '20px' }} /><label>Forecast</label>
                                                   </div>
                                                   <div className="col-md-10 col-lg-10 d-flex" style={{ backgroundColor: '#e5e5e5', padding: '15px', margin: '0 auto', borderRadius: '5px' }}>
                                                      <div className="col-md-5 col-lg-5 col-12">
                                                         <span style={{ fontSize: '12.5px', fontWeight: 300, verticalAlign: 'baseline' }}>Start</span> &nbsp;&nbsp;&nbsp;&nbsp;
                              <input type="date" ng-model="project.forecastfrom" id="txtforecaststart" autoComplete="off" className="text-line" style={{ fontSize: '12px', fontWeight: 300 }} />
                                                      </div>
                                                      <div className="col-md-2 col-lg-2 col-12">
                                                      </div>
                                                      <div className="col-md-5 col-lg-5 col-12">
                                                         <div>
                                                            <span style={{ fontSize: '12.5px', fontWeight: 300, verticalAlign: 'baseline' }}>End</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="date" ng-model="project.forecastend" id="txtforecastend" autoComplete="off" className="text-line" style={{ fontSize: '12px', fontWeight: 300 }} />
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ marginTop: '-20px auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12" style={{ paddingLeft: '30px' }}>
                                                <label>Priority</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12">
                                                <div style={{ marginLeft: '-15px' }}>
                                                   <label htmlFor="high1" style={{ verticalAlign: 'text-bottom' }}>High</label>
                                                   <input type="radio" id="high" name="priority1" defaultValue={3} />
                                                   <label htmlFor="medium1" style={{ verticalAlign: 'text-bottom' }}>Medium</label>
                                                   <input type="radio" id="medium" name="priority1" defaultValue={2} />
                                                   <label htmlFor="low1" style={{ verticalAlign: 'text-bottom' }}>Low</label>
                                                   <input type="radio" id="low" name="priority1" defaultValue={1} defaultChecked />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row">
                                             <div className="col-lg-2">
                                             </div>
                                             <div className="col-md-10 col-lg-10">
                                                <div style={{ marginLeft: '-15px' }}>
                                                   <label>Focus</label>
                                                   <input type="text" ng-model="project.focus" className="text-line-three" ng-maxlength={50} />
                                                   <label>Stage</label>
                                                   <input type="text" ng-model="project.stage" className="text-line-three" ng-maxlength={50} />
                                                   <label>Effort</label>
                                                   <input type="text" ng-model="project.effort" className="text-line-three" ng-maxlength={50} />
                                                   <label>Type</label>
                                                   <input type="text" ng-model="project.type" className="text-line-three" ng-maxlength={50} />
                                                   <label>Hours</label>
                                                   <input type="text" ng-model="project.hours" className="text-line-three" ng-maxlength={50} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ marginTop: '0 auto', display: 'none' }}>
                                             <div className="col-md-2 col-lg-2 col-12" style={{ paddingLeft: '30px' }}>
                                                <label htmlFor="ckextras">Extras</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 col-12" style={{ marginTop: '10px' }}>
                                                <input type="checkbox" id="ckextras" onchange="checkExtras()" />
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ marginTop: '0 auto' }}>
                                             <div className="col-md-2 col-lg-2 col-12" style={{ display: 'inline-block', paddingLeft: '45px' }}>
                                                <img src="Content/images/Mobile icons-37.png" style={{ width: '20px', height: '20px' }} /><label>Reminders</label>
                                             </div>
                                             <div className="col-md-10 col-lg-10 d-flex" style={{ backgroundColor: '#e5e5e5', padding: '15px', margin: '0 auto', borderRadius: '5px' }}>
                                                <div className="col-md-4 col-lg-4 col-12">
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-12">
                                                </div>
                                                <div className="col-md-2 col-lg-2 col-12">
                                                   {/* <button type="button" className="btn btn-light-danger mr-1 mb-1" ng-click="addReminder()" style="width:100%">
                    Add Reminder&nbsp;&nbsp;&nbsp;<i className="bx bxs-plus-circle" style="vertical-align:middle"></i>
                </button>*/}
                                                   <label style={{ background: 'none' }}>Add Reminder</label>&nbsp;<i className="bx bxs-plus-circle" ng-click="addReminder()" style={{ verticalAlign: 'middle', color: '#e8378f' }} />
                                                </div>
                                             </div>
                                          </div>
                                          <div className="form-group row" style={{ float: 'right' }}>
                                             <div className="col-md-12 col-lg-12 col-12">
                                                {/*<input type="submit" className="btn btn-primary btn-sm p-x-md" value="Submit" />*/}
                                                <button type="submit" className="btn-custom-1" value="Submit">Submit</button> &nbsp;&nbsp;&nbsp;
                      </div>
                                          </div>
                                       </div>
                                    </form>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="modal fade" id="modal-client" data-backdrop="static">
                           <div className="modal-dialog modal-lg">
                              <div className="modal-content" style={{ padding: 0, width: '100%', margin: '0 auto', border: 'none' }}>
                                 <div className="modal-header _600" style={{ backgroundColor: '#22386B', color: '#fff', borderRadius: '8px 8px 0px 0px', height: '60px' }}>
                                    Add Client
                <button type="button" className="close-pink" ng-click="closeclientPopup()" aria-label="Close">
                                       <div style={{ color: '#ffffff', marginTop: '-2px' }}><span aria-hidden="true">×</span></div>
                                    </button>
                                 </div>
                                 <form className="form-horizontal" ng-submit="createClient(clientForm.$valid)" noValidate name="clientForm">
                                    <div className="modal-body" style={{ padding: '40px' }}>
                                       <h6>Company Information</h6>
                                       <br />
                                       <div className="form-group row">
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="text" ng-model="client.name" className="form-control outline" ng-maxlength={100} ng-required="true" />
                                                <label>&nbsp;&nbsp;Company&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="text" ng-model="client.weburl" ng-maxlength={100} className="form-control" />
                                                <label>&nbsp;&nbsp;Website&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="text" ng-model="client.phoneno" ng-maxlength={50} className="form-control" />
                                                <label>&nbsp;&nbsp;Company Tel&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="email" ng-model="client.email" ng-maxlength={50} className="form-control" />
                                                <label>&nbsp;&nbsp;Company Email&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="text" ng-model="client.contactpersonname" ng-maxlength={200} className="form-control" />
                                                <label>&nbsp;&nbsp;Contact Person&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="email" ng-model="client.contactpersonemail" ng-maxlength={100} className="form-control" />
                                                <label>&nbsp;&nbsp;Email ID&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="text" ng-model="client.contactpersonmobile" ng-maxlength={20} className="form-control" />
                                                <label>&nbsp;&nbsp;Mobile&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <textarea className="form-control" ng-model="client.address" ng-maxlength={500} defaultValue={""} />
                                                <label>&nbsp;&nbsp;Address&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                       </div>
                                       <h6>Purchase Department</h6>
                                       <br />
                                       <div className="form-group row">
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="text" ng-model="client.deptdtl[0].name" ng-maxlength={200} className="form-control" />
                                                <label>&nbsp;&nbsp;Name&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="email" ng-model="client.deptdtl[0].email" ng-maxlength={50} className="form-control" />
                                                <label>&nbsp;&nbsp;Email&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="text" ng-model="client.deptdtl[0].mobile" ng-maxlength={20} className="form-control" />
                                                <label>&nbsp;&nbsp;Mobile&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                       </div>
                                       <h6>Finance Department</h6>
                                       <br />
                                       <div className="form-group row">
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="text" ng-model="client.deptdtl[1].name" ng-maxlength={200} className="form-control" />
                                                <label>&nbsp;&nbsp;Name&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="email" ng-model="client.deptdtl[1].email" ng-maxlength={50} className="form-control" />
                                                <label>&nbsp;&nbsp;Email&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                          <div className="col-md-4 col-lg-4 col-12">
                                             <div className="form-label-group">
                                                <input type="text" ng-model="client.deptdtl[1].mobile" ng-maxlength={20} className="form-control" />
                                                <label>&nbsp;&nbsp;Mobile&nbsp;&nbsp;</label>
                                             </div>
                                          </div>
                                       </div>
                                       <div className="form-group row">
                                          <div className="col-md-12 col-lg-12">
                                             <button type="submit" className="btn-custom-1">Save</button> &nbsp;&nbsp;&nbsp;
                      <button type="button" className="btn-custom-2" ng-click="closeclientPopup()">Cancel</button>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                        <div className="modal fade" id="modal-event" data-backdrop="static">
                           <div className="modal-dialog modal-lg">
                              <div className="modal-content" style={{ padding: 0, width: '50%', margin: '0 auto', border: 'none', marginTop: '50px' }}>
                                 <div className="modal-header _600" style={{ backgroundColor: '#22386B', color: '#fff', borderRadius: '8px 8px 0px 0px', height: '60px' }}>
                                    Event
                <button type="button" className="close-pink" ng-click="closeeventPopup()" aria-label="Close">
                                       <div style={{ color: '#ffffff', marginTop: '-2px' }}><span aria-hidden="true">×</span></div>
                                    </button>
                                 </div>
                                 <form className="form-horizontal" noValidate name="eventForm">
                                    <div className="modal-body" style={{ padding: '40px' }}>
                                       <div className="form-group row">
                                          <label className="col-lg-2 form-control-label">Start:</label>
                                          <div className="col-lg-8">
                                             <input type="date" ng-model="event.start" style={{ display: 'none' }} id="txtstart" className="form-control" />
                                             <input type="datetime-local" ng-model="event.start" id="txtstart1" className="form-control" />
                                          </div>
                                       </div>
                                       <div className="form-group row">
                                          <label className="col-lg-2 form-control-label">End:</label>
                                          <div className="col-lg-8">
                                             <input type="date" ng-model="event.end" style={{ display: 'none' }} id="txtend" className="form-control" />
                                             <input type="datetime-local" ng-model="event.end" id="txtend1" className="form-control" />
                                          </div>
                                       </div>
                                       <div className="form-group row">
                                          <label className="col-lg-2 form-control-label">All day event:</label>
                                          <div className="col-lg-8">
                                             <input type="checkbox" ng-model="event.isallday" ng-change="changeIsallday()" defaultChecked="checked" />
                                          </div>
                                       </div>
                                       <div className="form-group row">
                                          <label className="col-lg-2 form-control-label">Repeat:</label>
                                          <div className="col-lg-8" ng-model="event.repeat">
                                             <select className="form-control" ng-model="event.repeat" ng-change="changeRepeat()" style={{ height: '30px', fontSize: '12px' }}>
                                                <option value={0}>Never</option>
                                                <option value={1}>Hourly</option>
                                                <option value={2}>Daily</option>
                                                <option value={3}>Weekly</option>
                                                <option value={4}>Monthly</option>
                                                <option value={5}>Yearly</option>
                                             </select>
                                          </div>
                                       </div>
                                       <div id="divhourly" style={{ display: 'none' }}>
                                          <div className="form-group row">
                                             <label className="col-lg-2 form-control-label">Repeat every:</label>
                                             <div className="col-lg-8">
                                                <input type="number" ng-model="event.repeateveryhourly" min={1} className="form-control" /> hours
                      </div>
                                          </div>
                                       </div>
                                       <div id="divdaily" style={{ display: 'none' }}>
                                          <div className="form-group row">
                                             <label className="col-lg-2 form-control-label">Repeat every:</label>
                                             <div className="col-lg-8">
                                                <input type="number" ng-model="event.repeateverydaily" min={1} className="form-control" /> days
                      </div>
                                          </div>
                                       </div>
                                       <div id="divweekly" style={{ display: 'none' }}>
                                          <div className="form-group row">
                                             <label className="col-lg-2 form-control-label">Repeat every:</label>
                                             <div className="col-lg-8">
                                                <input type="number" ng-model="event.repeateveryweekly" min={1} className="form-control" /> weeks
                      </div>
                                          </div>
                                          <div className="form-group row">
                                             <label className="col-lg-2 form-control-label">Repeat on:</label>
                                             <div className="col-lg-8">
                                                <input type="checkbox" id="weeklysun" ng-model="event.repeatonweeklysun" />
                                                <label htmlFor="weeklysun">Su</label>
                                                <input type="checkbox" id="weeklymon" ng-model="event.repeatonweeklymon" />
                                                <label htmlFor="weeklymon">Mo</label>
                                                <input type="checkbox" id="weeklytue" ng-model="event.repeatonweeklytue" />
                                                <label htmlFor="weeklytue">Tu</label>
                                                <input type="checkbox" id="weeklywed" ng-model="event.repeatonweeklywed" />
                                                <label htmlFor="weeklywed">We</label>
                                                <input type="checkbox" id="weeklythu" ng-model="event.repeatonweeklythu" />
                                                <label htmlFor="weeklythu">Th</label>
                                                <input type="checkbox" id="weeklyfri" ng-model="event.repeatonweeklyfri" />
                                                <label htmlFor="weeklyfri">Fr</label>
                                                <input type="checkbox" id="weeklysat" ng-model="event.repeatonweeklysat" />
                                                <label htmlFor="weeklysat">Sa</label>
                                             </div>
                                          </div>
                                       </div>
                                       <div id="divmonthly" style={{ display: 'none' }}>
                                          <div className="form-group row">
                                             <label className="col-lg-2 form-control-label">Repeat every:</label>
                                             <div className="col-lg-8">
                                                <input type="number" ng-model="event.repeateverymonthly" min={1} className="form-control" /> months
                      </div>
                                          </div>
                                          <div className="form-group row">
                                             <label className="col-lg-2 form-control-label">Repeat on:</label>
                                             <div className="col-lg-8">
                                                <input type="radio" name="repeatonmonthly" defaultValue={1} ng-click="changeRepeatOnWeekly()" /> Date : <input type="number" min={1} max={31} className="form-control" style={{ width: '50%' }} ng-model="event.repeatonmonthlydate" ng-disabled="event.repeatonmonthlydatedisabled" />
                                                <br />
                                                <input type="radio" name="repeatonmonthly" defaultValue={2} ng-click="changeRepeatOnWeekly()" />
                                                <select ng-disabled="event.repeatonmonthlydisabled" ng-model="event.repeatonmonthlyweek" className="form-control" style={{ width: '50%' }}>
                                                   <option value={1}>first</option>
                                                   <option value={2}>second</option>
                                                   <option value={3}>third</option>
                                                   <option value={4}>fourth</option>
                                                   <option value={-1}>last</option>
                                                </select>
                                                <select ng-disabled="event.repeatonmonthlydisabled" ng-model="event.repeatonmonthlyday" className="form-control" style={{ width: '50%' }}>
                                                   <option value="SU,MO,TU,WE,TH,FR,SA">day</option>
                                                   <option value="MO,TU,WE,TH,FR">weekday</option>
                                                   <option value="SU,SA">weekend day</option>
                                                   <option value="SU">Sunday</option>
                                                   <option value="MO">Monday</option>
                                                   <option value="TU">Tuesday</option>
                                                   <option value="WE">Wednesday</option>
                                                   <option value="TH">Thursday</option>
                                                   <option value="FR">Friday</option>
                                                   <option value="SA">Saturday</option>
                                                </select>
                                             </div>
                                          </div>
                                       </div>
                                       <div id="divyearly" style={{ display: 'none' }}>
                                          <div className="form-group row">
                                             <label className="col-lg-2 form-control-label">Repeat every:</label>
                                             <div className="col-lg-8">
                                                <input type="number" ng-model="event.repeateveryyearly" min={1} className="form-control" /> years
                      </div>
                                          </div>
                                          <div className="form-group row">
                                             <label className="col-lg-2 form-control-label">Repeat on:</label>
                                             <div className="col-lg-8">
                                                <input type="radio" name="repeatonyearly" defaultValue={1} ng-click="changeRepeatOnYearly()" />
                        Date :
                        <select className="form-control" style={{ width: '50%' }} ng-disabled="event.repeatonyearly1disabled" ng-model="event.repeatonyearly1month">
                                                   <option value={1}>January</option>
                                                   <option value={2}>February</option>
                                                   <option value={3}>March</option>
                                                   <option value={4}>April</option>
                                                   <option value={5}>May</option>
                                                   <option value={6}>June</option>
                                                   <option value={7}>July</option>
                                                   <option value={8}>August</option>
                                                   <option value={9}>September</option>
                                                   <option value={10}>October</option>
                                                   <option value={11}>November</option>
                                                   <option value={12}>December</option>
                                                </select>
                                                <input type="number" className="form-control" min={1} max={31} style={{ width: '50%' }} ng-disabled="event.repeatonyearly1disabled" ng-model="event.repeatonyearly1date" />
                                                <br />
                                                <input type="radio" name="repeatonyearly" defaultValue={2} ng-click="changeRepeatOnYearly()" />
                                                <select className="form-control" style={{ width: '50%' }} ng-disabled="event.repeatonyearly2disabled" ng-model="event.repeatonyearly2week">
                                                   <option value={1}>first</option>
                                                   <option value={2}>second</option>
                                                   <option value={3}>third</option>
                                                   <option value={4}>fourth</option>
                                                   <option value={-1}>last</option>
                                                </select>
                                                <select className="form-control" style={{ width: '50%' }} ng-disabled="event.repeatonyearly2disabled" ng-model="event.repeatonyearly2day">
                                                   <option value="SU,MO,TU,WE,TH,FR,SA">day</option>
                                                   <option value="MO,TU,WE,TH,FR">weekday</option>
                                                   <option value="SU,SA">weekend day</option>
                                                   <option value="SU">Sunday</option>
                                                   <option value="MO">Monday</option>
                                                   <option value="TU">Tuesday</option>
                                                   <option value="WE">Wednesday</option>
                                                   <option value="TH">Thursday</option>
                                                   <option value="FR">Friday</option>
                                                   <option value="SA">Saturday</option>
                                                </select> of
                                                <select className="form-control" style={{ width: '50%' }} ng-disabled="event.repeatonyearly2disabled" ng-model="event.repeatonyearly2month">
                                                   <option value={1}>January</option>
                                                   <option value={2}>February</option>
                                                   <option value={3}>March</option>
                                                   <option value={4}>April</option>
                                                   <option value={5}>May</option>
                                                   <option value={6}>June</option>
                                                   <option value={7}>July</option>
                                                   <option value={8}>August</option>
                                                   <option value={9}>September</option>
                                                   <option value={10}>October</option>
                                                   <option value={11}>November</option>
                                                   <option value={12}>December</option>
                                                </select>
                                             </div>
                                          </div>
                                       </div>
                                       <div id="divrepeatend" style={{ display: 'none' }}>
                                          <div className="form-group row">
                                             <label className="col-lg-2 form-control-label">End:</label>
                                             <div className="col-lg-8">
                                                <input type="radio" id="never" name="repeatend" defaultValue={1} defaultChecked="checked" ng-click="changeRepeatEnd()" />
                                                <label htmlFor="never">Never</label>
                                                <br />
                                                <input type="radio" id="after" name="repeatend" defaultValue={2} ng-click="changeRepeatEnd()" />
                                                <label htmlFor="after">After</label>
                                                <input type="number" min={1} className="form-control" style={{ width: '50%' }} ng-model="event.repeatendoccurrence" ng-disabled="event.repeatendoccurrencedisabled" /> occurrence(s)
                                                <br />
                                                <input type="radio" id="on" name="repeatend" defaultValue={3} ng-click="changeRepeatEnd()" />
                                                <label htmlFor="on">On</label>
                                                <input type="date" ng-model="event.repeatendon" ng-disabled="event.repeatendondisabled" id="txtrepeatendon" className="form-control" style={{ width: '50%' }} />
                                             </div>
                                          </div>
                                       </div>
                                       <br />
                                       <div className="form-group row">
                                          <div className="col-md-12 col-lg-12">
                                             <button type="button" ng-click="createEvent()" className="btn-custom-1">Save</button> &nbsp;&nbsp;&nbsp;
                                             <button type="button" className="btn-custom-2" ng-click="closeeventPopup()">Cancel</button>
                                          </div>
                                       </div>
                                    </div>
                                 </form>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </>

      );
   }
}

export default AddActivity;