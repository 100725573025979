import config from '../../services/config.json';
import StateDataService from '../../../data-services/state-data-service';

export const State_GetALL_URL = config.apiEndPoint + StateDataService.getAllState; 
export const State_GetByID_URL = config.apiEndPoint + StateDataService.getStateById; 
export const State_GetBYCOUNTR_URL = config.apiEndPoint + StateDataService.getStateByCountryId; 
export const State_ADD_URL = config.apiEndPoint + StateDataService.saveState; 
export const State_Update_URL = config.apiEndPoint + StateDataService.updateState; 
export const State_DELETE_URL = config.apiEndPoint + StateDataService.deleteState; 

