import config from '../../services/config.json';
import DashBoardSettingsDataService from '../../../data-services/dashboard-settings-data-services';

export const getAllDashBoardSettings = config.apiEndPoint + DashBoardSettingsDataService.getAllDashBoardSettings;
export const getDashBoardSettingsById = config.apiEndPoint + DashBoardSettingsDataService.getDashBoardSettingsById;
export const saveDashBoardSettings = config.apiEndPoint + DashBoardSettingsDataService.saveDashBoardSettings;
export const updateDashBoardSettings = config.apiEndPoint + DashBoardSettingsDataService.updateDashBoardSettings;
export const deleteDashBoardSettings = config.apiEndPoint + DashBoardSettingsDataService.deleteDashBoardSettings;
export const getDashBoardSettingsByUserId = config.apiEndPoint + DashBoardSettingsDataService.getDashBoardSettingsByUserId;
export const saveDashboardLayoutSettings = config.apiEndPoint + DashBoardSettingsDataService.saveDashboardLayoutSettings;


