import React  , {useState} from 'react';
import {Line} from 'react-chartjs-2';
import Moment from 'moment'; 

const state = {
    labels: ['January', 'February', 'March',
             'April', 'May'],
    datasets: [
      {
        label: 'Monthly Tasks',
        fill: false,
        lineTension: 0.5,
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: [65, 59, 80, 81, 56]
      }
    ]
  }

// const [data, setData] = useState([])
// useEffect(() => {
//   this.state.datasets.data = []
//   return () => {
    
//   }
// }, [])

function LineGraph(props) {
    return (
        <div>
          <Line
            data={state}
            options={{
              title:{
                display:true,
                text:'Average Tasks per month',
                fontSize:20,
              },
              legend:{
                display:true,
                position:'right'
              }
            }}/>
        </div>
      );
  
}

export default LineGraph;




