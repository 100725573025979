export const initialWorkspaceBoardGroupData = [
   {
      _id: "group_1",
      label: "Group 1",
      groupItems: [{
         _id: "row_1_1",
         title: "",
         category: "header",
         chatroom:"",
         cellItems: [
            { _id: "col_1_1_1", key: "people", body: "Assignee", columnId: "column_1" },
            { _id: "col_1_1_2", key: "people", body: "Followers", columnId: "column_2" },
            { _id: "col_1_1_3", key: "status", body: "Status", columnId: "column_3" },
            { _id: "col_1_1_4", key: "text", body: "Text", columnId: "column_4" },
            { _id: "col_1_1_5", key: "timeline", body: "Timeline", columnId: "column_5" },
            { _id: "col_1_1_6", key: "date", body: "Date", columnId: "column_6" },
            
         ]

            
      }, {
         _id: "row_1_2",
         title: "Item 1",
         category: "body",
         chatroom:"",
         cellItems: [
            { _id: "col_1_2_1", key: "people", body: JSON.stringify([]), columnId: "column_1" },
            { _id: "col_1_2_2", key: "people", body: JSON.stringify([]), columnId: "column_2" },
            { _id: "col_1_2_3", key: "status", body: "", columnId: "column_3" },
            { _id: "col_1_2_4", key: "text", body: "", columnId: "column_4" },
            { _id: "col_1_2_5", key: "timeline", body: "", columnId: "column_5" },
            { _id: "col_1_2_6", key: "date", body: "", columnId: "column_6" }
           
         ]
      }]
   },
   {
      _id: "group_2",
      label: "Group 2",
      groupItems: [{
         _id: "row_2_1",
         title: "",
         category: "header",
         chatroom:"",
         cellItems: [
            { _id: "col_2_1_1", key: "people", body: "Assignee", columnId: "column_1" },
            { _id: "col_2_1_2", key: "people", body: "Followers", columnId: "column_2" },
            { _id: "col_2_1_3", key: "status", body: "Status", columnId: "column_3" },
            { _id: "col_2_1_4", key: "text", body: "Text", columnId: "column_4" },
            { _id: "col_2_1_5", key: "timeline", body: "Timeline", columnId: "column_5" },
            { _id: "col_2_1_6", key: "date", body: "Date", columnId: "column_6" }
            
         ]
      }, {
         _id: "row_2_2",
         title: "Item 2",
         category: "body",
         chatroom:"",
         cellItems: [
            { _id: "col_2_2_1", key: "people", body: JSON.stringify([]), columnId: "column_1" },
            { _id: "col_2_2_2", key: "people", body: JSON.stringify([]), columnId: "column_2" },
            { _id: "col_2_2_3", key: "status", body: "", columnId: "column_3" },
            { _id: "col_2_2_4", key: "text", body: "", columnId: "column_4" },
            { _id: "col_2_2_5", key: "timeline", body: "", columnId: "column_5" },
            { _id: "col_2_2_6", key: "date", body: "", columnId: "column_6" },
            
         ]
      }]
   }
]

export const initialWorkspaceBoardDataArr = [
   {
      groupItems: [
         {
            cellItems: [
               {
                  typeInfo: "data",
                  _id: "5f9f8a250df2994034b7b59e",
                  key: "status",
                  body: "Status",
                  createdAt: "2020-11-02T04:25:09.633Z",
                  updatedAt: "2020-11-02T04:25:09.633Z",
                  __v: 0
               },
               {
                  typeInfo: "data",
                  _id: "5f9f8a260df2994034b7b59f",
                  key: "text",
                  body: "Text",
                  createdAt: "2020-11-02T04:25:10.206Z",
                  updatedAt: "2020-11-02T04:25:10.206Z",
                  __v: 0
               },
               {
                  typeInfo: "data",
                  _id: "5f9f8a260df2994034b7b5a0",
                  key: "timeline",
                  body: "Timeline",
                  createdAt: "2020-11-02T04:25:10.837Z",
                  updatedAt: "2020-11-02T04:25:10.837Z",
                  __v: 0
               },
               {
                  typeInfo: "data",
                  _id: "5f9f8a270df2994034b7b5a1",
                  key: "date",
                  body: "Date",
                  createdAt: "2020-11-02T04:25:11.367Z",
                  updatedAt: "2020-11-02T04:25:11.367Z",
                  __v: 0
               }
            ],
            _id: "5f9f8a240df2994034b7b59d",
            title: "",
            category: "header",
            createdAt: "2020-11-02T04:25:08.968Z",
            updatedAt: "2020-11-02T04:25:11.632Z",
            __v: 4
         },
         {
            cellItems: [
               {
                  typeInfo: "data",
                  _id: "5f9f8a280df2994034b7b5a3",
                  key: "status",
                  body: "Waiting for work",
                  createdAt: "2020-11-02T04:25:12.563Z",
                  updatedAt: "2020-11-02T04:25:12.563Z",
                  __v: 0
               },
               {
                  typeInfo: "data",
                  _id: "5f9f8a290df2994034b7b5a4",
                  key: "text",
                  body: "Text",
                  createdAt: "2020-11-02T04:25:13.104Z",
                  updatedAt: "2020-11-02T04:25:13.104Z",
                  __v: 0
               },
               {
                  typeInfo: "data",
                  _id: "5f9f8a290df2994034b7b5a5",
                  key: "timeline",
                  body: "2020/10/8-2020/10/26",
                  createdAt: "2020-11-02T04:25:13.705Z",
                  updatedAt: "2020-11-02T04:25:13.705Z",
                  __v: 0
               },
               {
                  typeInfo: "data",
                  _id: "5f9f8a2a0df2994034b7b5a6",
                  key: "date",
                  body: "2020/10/25",
                  createdAt: "2020-11-02T04:25:14.276Z",
                  updatedAt: "2020-11-02T04:25:14.276Z",
                  __v: 0
               }
            ],
            _id: "5f9f8a270df2994034b7b5a2",
            title: "Item 1",
            category: "body",
            createdAt: "2020-11-02T04:25:11.898Z",
            updatedAt: "2020-11-02T04:25:14.571Z",
            __v: 4
         }
      ],
      _id: "5f9f8a240df2994034b7b59c",
      label: "Group 1",
      createdAt: "2020-11-02T04:25:08.208Z",
      updatedAt: "2020-11-02T04:25:12.225Z",
      __v: 2
   }
];

export const cellStatusInitalData = [
   {
      _id: 'status_1',
      name: '',
      type: 'None',
      color: '#c4c4c4'
   },
   {
      _id: 'status_2',
      name: 'Waiting for work',
      type: 'Waiting for work',
      color: '#9aadbd'
   },
   {
      _id: 'status_3',
      name: 'Working on it',
      type: 'Working on it',
      color: '#fdab3d'
   },
   {
      _id: 'status_4',
      name: 'Waiting for review',
      type: 'Waiting for review',
      color: '#579bfc'
   },
   {
      _id: 'status_5',
      name: 'Approved',
      type: 'Approved',
      color: '#225091'
   },
   {
      _id: 'status_6',
      name: 'Done',
      type: 'Done',
      color: '#00c875'
   },
   {
      _id: 'status_7',
      name: 'Stuck',
      type: 'Stuck',
      color: '#e2445c'
   },
]
