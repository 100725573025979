import React from 'react';
import { connect } from 'react-redux'
import axios from '../../../../../util/Api';
import { getRandomColor, validateEmail } from '../../../../../util/People';
import { showMessage } from '../../../../../actions';
import { format } from 'date-fns';
import { getLocationTime } from '../../../../../util';

class PeopleItemAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openEdit: false,
            inviteEmail: '',
            suggestUsers: [],
            allSuggestions: [],
            searchText: ''
        }
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
        this.timeout = 0
    }

    componentDidMount() {
        // axios.get('/workspace/getWorkSpaceById/', {
        //     params: { id: this.props.boardData.workspaceID }
        // }).then(({ data }) => {
        //     let workspace = data.workspaces;
        //     let members = workspace.members;
        //     console.log("workspace===>", data);
        //     let users = this.getUserLists(members);
        //      users = this.removeDuplicates(users, 'email')

        //     console.log('peopleusers===>',users);

        //     this.setState({ suggestUsers: users, allSuggestions: users })
        // }).catch(err => {
        //     console.log("Error: ", err)
        // })
console.log('peopleusers===>',this.props);
        this.setState({ suggestUsers: this.getUserLists(JSON.parse(this.props.boardData.workspaceBoard.members)), allSuggestions: this.getUserLists(JSON.parse(this.props.boardData.workspaceBoard.members)) })
    }

     removeDuplicates = (originalArray, prop) =>{
        var newArray = [];
        var lookupObject  = {};
   
        for(var i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }
   
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
         return newArray;
    }
    getUserLists = (members) => {
        let userLists = [];
        let peoples = this.props.people;
        for (let i = 0; i < members.length; i++) {
            let member = members[i];
            let authUser = JSON.parse(localStorage.getItem('authUser'));
            let color = member._id === authUser._id ? '#13a3e3' : getRandomColor();
            let timezoneOffset = member.timezoneOffset ? parseFloat(member.timezoneOffset) : new Date().getTimezoneOffset();

            let findPeople = peoples.find(people => people._id === member._id);
            if (findPeople === undefined) {
                let user = {
                    _id: member._id,
                    name: member.name?member.name:(`${member.firstname ? member.firstname : ''} ${member.lastname ? member.lastname : ''}`),
                    email: member.email,
                    color: color,
                    timezoneOffset: timezoneOffset
                }
                userLists.push(user);
            }
        }

        return userLists;
    }

    invitePeople = () => {
        let email = this.state.inviteEmail;
        if (email !== '') {
            let match = validateEmail(email);
            if (match) {
                console.log("email", email);
                let authUser = JSON.parse(localStorage.getItem('authUser'));
                let timezoneOffset = new Date().getTimezoneOffset()
                axios.post('/workspace/inviteMemberByEmail', {
                    email: email,
                    authUserId: authUser._id,
                    workspaceID: this.props.boardData.workspaceID,
                    groupItemTitle: this.props.groupItem.title,
                    timezoneOffset: timezoneOffset
                }).then(({ data }) => {
                    let user = data.newUser;
                    let newuser = {
                        _id: user._id,
                        name: '',
                        email: user.email,
                        color: getRandomColor(),
                        timezoneOffset: new Date().getTimezoneOffset()
                    };
                    let userLists = this.state.suggestUsers;
                    userLists.push(newuser);
                    this.setState({ suggestUsers: userLists, allSuggestions: userLists })
                });
            } else {
                this.props.showMessage('Invalid Email Address!')
            }
        }
    }

    addPeople = (people) => {
        let suggestionUsers = this.state.suggestUsers;
        let removeIndex = suggestionUsers.findIndex(user => user._id === people._id);
        const { removeuser, } = suggestionUsers.splice(removeIndex, 1);
        this.props.addPeople(people);
        this.setState({ suggestUsers: suggestionUsers, allSuggestions: suggestionUsers })
    }

    removePeople = (people) => {
        let suggestusers = this.state.suggestUsers;
        suggestusers.push(people);
        this.setState({ suggestUsers: suggestusers, allSuggestions: suggestusers });
        this.props.removePeople(people)
    }

    searchUsers = (event) => {
        if (this.state.searchText !== '') {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                let suggestusers = this.state.allSuggestions;
                let users = suggestusers.filter(user => {
                    let username = user.name;
                    let search = username.toLowerCase().search(this.state.searchText.toLowerCase());
                    if (search !== -1) {
                        return true;
                    } else {
                        return false
                    }
                });
                this.setState({ suggestUsers: users })
            }, 300);
        } else {
            this.setState({ suggestUsers: this.state.allSuggestions })
        }
    }

    render() {
        return (
            <div className="p-2">
                <div className="multiple-items-box-component dropdown-chosen-options-wrapper" style={{ flexWrap: 'inherit' }}>
                    {this.props.people.map((people, index) => (
                        <div className="item-tokens-line" key={index}>
                            <div className="single-box-item-component item-token can-change-content" >
                                <div className="item-wrapper">
                                    <div className="dropdown-item-name">{people.name !== '' ? people.name : people.email}</div>
                                </div>
                                <div className="clear-button" onClick={() => this.removePeople(people)} >
                                    <i className="fa fa-close"></i>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div style={{ display: `${this.state.openEdit ? 'none' : ''}` }}>
                    <input type='text'
                        className="add-dropdown-item"
                        placeholder="Enter another name"
                        onChange={(event) => this.setState({ searchText: event.target.value })}
                        onKeyUp={(event) => this.searchUsers(event)}
                    />
                </div>
                <div className="dropdown-label-list">
                    <div className="dropdown-label-suggest" style={{ display: `${this.state.openEdit ? 'none' : ''}` }}>
                        {this.state.suggestUsers
                       // .filter(member => (member._id != this.authUser._id) && (member._id != this.props.boardData?.workspace?.CreatedBy) && !member.is_bAdmin && !this.props.boardData?.workspace?.admins.includes(member._id))
                        .map((people, index) => (
                            <div className="pt-1 pb-1" key={index}>
                                <div className="dropdown-label-item" onClick={() => this.addPeople(people)}>{people.name? people.name : people.firstname}</div>
                            </div>
                        ))}
                    </div>
                    <div className="dropdown-label-suggest" style={{ display: `${!this.state.openEdit ? 'none' : ''}` }}>
                        <input type='text'
                            className="add-dropdown-item"
                            placeholder="Email Address"
                            value={this.state.inviteEmail}
                            onChange={(event) => this.setState({ inviteEmail: event.target.value })}
                        />
                    </div>
                </div>
                <div className="mt-1">
                    <div style={{ display: `${this.state.openEdit ? 'none' : ''}` }}>
                        <button type='button' className="btn btn-add w-100 d-flex align-items-center text-left"
                            onClick={() => this.setState({ openEdit: true })} >
                            <i className='fa fa-envelope-o' style={{ top: '1px' }}></i> &nbsp; Invite a new member by email
                                    </button>
                    </div>
                    <div style={{ display: `${!this.state.openEdit ? 'none' : ''}` }}>
                        <button type='button' className="btn btn-add w-100 d-flex align-items-center text-left"
                            onClick={() => this.invitePeople()} >
                            <i className='fa fa-envelope-o' style={{ top: '1px' }}></i> &nbsp; Invite
                                    </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { showMessage }

export default connect(mapStateToProps, mapDispatchToProps)(PeopleItemAdd);