// Excel Header DataSet generator
export const exportExcelHeader = (boardTitle, boardDescription) => {
    return [
        // Board Title
        {
            columns: [
                {title: boardTitle, style: {font: {sz: "18", bold: true}}},
            ],
            data: []
        },
        // Board description
        {
            columns: [
                {title: boardDescription, style: {font: {sz: "14", bold: true}}},
            ],
            data: [[]]
        },
    ]
}

// Excel specified Group DataSet generator
export const exportGroup = (thisGroup) => {
    if (!thisGroup) return [];
    const groupDataSet = [
        // Group Label
        {
            columns: [
                {title: thisGroup?.label, style: {font: {sz: "16", bold: true, color: { rgb: "FF00CA72" }}, }},
            ],
            data: []
        },
        // Group Data
        {
            // Group Header
            columns: [
                {title: "Name", width: {wpx: 240}, style: {font: {sz: "14", bold: true}, fill: {patternType: "solid", fgColor: {rgb: "FFAAAAAA"}} }},
                ...thisGroup.groupItems[0]?.cellItems?.map(cellItem => {
                    let width = 140;
                    if (cellItem?.key === "longText") width = 300;
                    return {
                        title: cellItem?.body,
                        width: { wpx: width },
                        style: {
                            font: {sz: "14", bold: true},
                            fill: { patternType: "solid", fgColor: {rgb: "FFAAAAAA"}},
                            alignment: {horizontal: "center"}
                        },
                    };
                })
            ],
            // Group Items
            data: [
                ...thisGroup.groupItems.filter(groupItem => groupItem.category === "body").map((groupItem,index) => {
                    return [
                        // Group Item Title
                        {
                            value: groupItem?.title,
                            style: {font: {sz: "12", bold: false} }
                        },
                        // Group Cell Items
                        ...groupItem?.cellItems?.map(cellItem=> {
                            // Select Cell Style according to CellItem key
                            switch(cellItem?.key) {
                                case "rating":
                                    return {
                                        value: Number(cellItem?.body),
                                        style: {
                                            font: {sz: "12", bold: false},
                                            alignment: {horizontal: "center"}
                                        }
                                    };
                                    case "autoNumber":
                                        debugger;
                                        return {
                                            value: index+1,
                                            style: {
                                                font: {sz: "12", bold: false},
                                                alignment: {horizontal: "center"}
                                            }
                                        };
                                case "progressTracking":
                                    return {
                                        value: cellItem?.body + "%",
                                        style: {
                                            font: {sz: "12", bold: false},
                                            alignment: {horizontal: "center"}
                                        }
                                    };
                                case "file":
                                    let value = "";
                                    if (Array.isArray(cellItem?.body)) {
                                        value = cellItem?.body?.map(file => {
                                            return file.path
                                        }).join(", ");
                                    }
                                    return {
                                        value: value,
                                        style: {
                                            font: {sz: "12", bold: false},
                                            alignment: {horizontal: "center"}
                                        }
                                    };
                                case "status":
                                    let bgColor = "FFc4c4c4";
                                    // Select Background Color according to Status
                                    switch (cellItem?.body) {
                                        case "Done":
                                            bgColor = "FF00CA72";
                                            break;
                                        case "Stuck":
                                            bgColor = "FFe2445c";
                                            break;
                                        case "Approved":
                                            bgColor = "FF235191";
                                            break;
                                        case "Waiting for review":
                                            bgColor = "FF579bfc";
                                            break;
                                        case "Working on it":
                                            bgColor = "FFfdab3e";
                                            break;
                                        case "Waiting for work":
                                            bgColor = "FF9aadbd";
                                            break;
                                        default: bgColor = "FFc4c4c4";
                                            break;
                                    }
                                    const borderColor = "FF079658"
                                    return {
                                        value: cellItem?.body,
                                        style: {
                                            font: {sz: "12", bold: false, color: { rgb: "FFFFFFFF" }},
                                            fill: {patternType: "solid", fgColor: {rgb: bgColor} },
                                            // border: {
                                            //     top: { style: "medium", color: borderColor },
                                            //     bottom: { style: "medium", color: borderColor },
                                            //     left: { style: "medium", color: borderColor },
                                            //     right: { style: "medium", color: borderColor },
                                            // },
                                            alignment: {horizontal: "center"}
                                        }
                                    };
                                case "people":
                                    const peoples = JSON.parse(cellItem?.body).map(people => {
                                        return people?.name;
                                    });
                                    return {
                                        value: peoples.join(", "),
                                        style: {
                                            font: {sz: "12", bold: false},
                                            alignment: {horizontal: "center"}
                                        }
                                    };
                                case "tags":
                                    const tags = JSON.parse(cellItem?.body).map(tag => {
                                        return '#'+tag?.name;
                                    });
                                    return {
                                        value: tags.join(", "),
                                        style: {
                                            font: {sz: "12", bold: false},
                                            alignment: {horizontal: "center"}
                                        }
                                    }
                                case "dropdown":
                                    const dropdowns = JSON.parse(cellItem?.body).map(dropdown => {
                                        return dropdown?.name;
                                    });
                                    return {
                                        value: dropdowns.join(", "),
                                        style: {
                                            font: {sz: "12", bold: false},
                                            alignment: {horizontal: "center"}
                                        }
                                    }
                                case "lastUpdated":
                                    let lastUpdated = "";
                                    if (groupItem?.lastUpdated) {
                                        lastUpdated = groupItem?.lastUpdated?.name + " " + groupItem?.lastUpdated?.updated;
                                    }
                                    return {
                                        value: lastUpdated,
                                        style: {
                                            font: {sz: "12", bold: false},
                                            alignment: {horizontal: "center"}
                                        }
                                    };
                                default:
                                    return {
                                        value: cellItem?.body,
                                        style: {
                                            font: {sz: "12", bold: false},
                                            alignment: {horizontal: "center"}
                                        }
                                    };
                            }
                        })
                    ];
                }),
                []
            ]
        }
    ];

    return groupDataSet;
}