import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fetchError, updateWorkspaceBoard, showMessage } from '../../../../../actions';
import axios from '../../../../../util/Api';

class BoardType extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boardType: ''
        }
        this.modalContainer = React.createRef();
    }

    componentDidMount() {
        let workspaceBoard = this.props.boardData.workspaceBoard;
        let boardType = workspaceBoard.boardType ? workspaceBoard.boardType : 'main';
        this.setState({ boardType: boardType })
    }

    changeBoardType = () => {
        this.props.closeModal(false)
        axios.post('/workspace/updateBoardType', {
            boardId: this.props.boardData.boardID,
            boardType: this.props.boardType.to
        }).then(({ data }) => {
            this.props.showMessage(`Board type changed from ${this.state.boardType} to ${this.props.boardType.to}`)
            this.props.updateWorkspaceBoard({
                workspaceBoard: data.board,
                workspaceID: this.props.boardData.workspaceID
            });
        }).catch(err => {
            this.props.fetchError(err)
        })
    }

    getColor = (type) => {
        switch (type) {
            case 'sharable':
                return '#a358df';
            case 'private':
                return '#e44258';
            default:
                return '#0085ff';
        }
    }

    render() {
        let color = this.getColor(this.props.boardType.to)
        return (
            <div>
                <Modal isOpen={this.props.boardType.open} ref={this.modalContainer} size="lg">
                    <ModalHeader toggle={() => this.props.closeModal(false)}>
                        Change board from {this.state.boardType} to {(this.props.boardType.to==='sharable')?'shareable':this.props.boardType.to}?
                    </ModalHeader>
                    <ModalBody>
                        <div className='p-2 board-type-content' style={{ borderColor: color }}>
                            <div className='row'>
                                <div className='col-2'>
                                    <div className='board-type-icon'>
                                        {this.props.boardType.to === 'sharable' ? (
                                            <i className='bx bxs-share-alt' style={{ color: color }}></i>
                                        ) : this.props.boardType.to === 'private' ? (
                                            <i className='bx bx-lock-alt' style={{ color: color }}></i>
                                        ) : (
                                                    <i className='fa fa-bars' style={{ color: color }}></i>
                                                )}

                                    </div>
                                </div>
                                <div className='col-10'>
                                    {this.props.boardType.to === 'sharable' ? (
                                        <ul>
                                            <li>Shareable boards are visible only to subscribers.</li>
                                            <li>People who accessed this board without subscribing will no longer have access.</li>
                                        </ul>
                                    ) : this.props.boardType.to === 'private' ? (
                                        <ul>
                                            <li>Private boards are visible only to subscribers.</li>
                                            <li>People who accessed this board without subscribing will no longer have access.</li>
                                        </ul>
                                    ) : (
                                                <ul>
                                                    <li>This board's content will become publicly available to all team members.</li>
                                                </ul>
                                            )}


                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className='btn btn-light' onClick={() => this.props.closeModal()}>Cancel</button>
                        <button className='btn btn-primary text-capitalize' onClick={() => this.changeBoardType()}>Change Board to {this.props.boardType.to}</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { fetchError, updateWorkspaceBoard, showMessage }

export default connect(mapStateToProps, mapDispatchToProps)(BoardType)