import React, { Component } from 'react';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

class Currency extends Component {
  state = {}
  render() {
    return (
      <>
        <MainMenu link={`currency`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <div className="row dash-blue fixed-top">
                  <div className="col-md-1 col-md-1">
                  </div>
                  <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
                    <div>
                      <div className="inner-section">
                        <h3>Master Settings</h3>
                        <div style={{ marginTop: '-10px' }}>
                          <p className="mt-1">Currency</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
                    <table className="align-items-center table-dashboard-section-one">
                      <tbody><tr>
                        <td rowSpan={2} style={{ width: '300px', textAlign: 'left' }}>
                          <input className="search-dashboard" type="text" name="search" placeholder="Search Here.." />
                        </td>
                      </tr>
                        <tr style={{ fontWeight: 600 }}>
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                        </tr>
                      </tbody></table>
                    <table className="align-items-center table-dashboard-section-last d-flex">
                      <tbody><tr>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-27.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-38.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-37.png" /></a></td>
                        <td rowSpan={2} style={{ fontWeight: 100, display: 'inline-flex' }}>&nbsp;&nbsp;Sort <img src="/images/icon/dashboard-icons/Panel icons-32.png" style={{ marginLeft: '10px' }} /></td>
                      </tr>
                        <tr>
                          <td>&nbsp;&nbsp;</td>
                        </tr>
                        <tr>
                        </tr></tbody></table>
                  </div>
                </div>
                <div className="content-wrapper" ng-app="myApp">
                  <div className="company-table">
                    <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                      <div style={{ float: 'left' }}>
                        <h5 className="left-head">Currency</h5>
                      </div>
                      <div style={{ float: 'right', marginTop: '-2px', marginRight: '-15px' }}>
                        <a title="Reply" data-toggle="modal" data-target="#modal-Currency">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;New Currency <i className="bx bxs-plus-circle" /></span>
                        </a>
                      </div>
                    </div>
                    {/* Start Table Content*/}
                    <table className="table-striped module-table" aria-describedby="example_info">
                      <thead>
                        <tr className="rounded">
                          <th style={{ paddingLeft: '15px', width: '80px' }}>Sl No.</th>
                          <th style={{ paddingLeft: '100px', width: '300px' }}>Currency Name</th>
                          <th style={{ textAlign: 'center', width: '150px' }}>Currency Code</th>
                          <th style={{ textAlign: 'center', width: '150px' }}>Created By</th>
                          <th style={{ textAlign: 'center', width: '150px' }}>Created On</th>
                          <th style={{ textAlign: 'center', width: '150px' }}>Modified By</th>
                          <th style={{ textAlign: 'center', width: '150px' }}>Modified On</th>
                          <th style={{ textAlign: 'center', width: '250px' }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr ng-repeat="item in list track by $index">
                          <td style={{ paddingLeft: '20px' }}></td>
                          <td style={{ paddingLeft: '100px' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}>
                            <a ng-click="Edit(item)"><i className="bx bxs-pencil text-dark" /></a>{/*ng-if="rolePermission.canedit"*/}
                            <a ng-click="Delete(item.id)"><i className="bx bxs-trash-alt text-dark" /></a> {/*ng-if="rolePermission.candelete"*/}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* End Table Content*/}
                    <br />
                    <div className="row">
                      <div className="col-md-12 justify-content-center">
                        <div data-pagination data-num-pages="numOfPages()" data-current-page="curPage" data-max-size="maxSize" data-boundary-links="true" style={{ width: '80%', margin: '0 auto', justifyContent: 'center' }} className="mb-1">
                        </div>
                      </div>
                    </div>
                  </div> {/* End Top Section*/}
                </div> {/* ENd Company Table*/}
                <div className="modal fade" id="modal-Currency" data-backdrop="true" data-keyboard="false">
                  <div className="modal-dialog modal-lg modal-plus">
                    <div className="modal-content" style={{ overflow: 'hidden' }}>
                      <div className="modal-header modal-bg">
                        <span>New Currency</span>
                        <div>
                          <button type="button" className="close-pink" data-dismiss="modal" aria-label="Close">
                            <div style={{ marginTop: '-4px', marginLeft: '1px' }}><span className="cross">×</span></div>
                          </button>
                        </div>
                      </div> {/* End Model-header*/}
                      <div className="modal-body" style={{ overflowY: 'auto', padding: '50px' }}>
                        <form className="form-horizontal" ng-submit="Create(currencyForm.$valid)" noValidate name="currencyForm">
                          <div className="form-group row">
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="hidden" ng-model="Currency.id" />
                                  <input type="text" ng-model="Currency.currencyName" ng-blur="CheckAvailability(Currency.currencyName)" className="form-control outline" />
                                  <label>&nbsp;&nbsp;Currency<span>*</span>&nbsp;&nbsp;</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="text" ng-model="Currency.currencyCode" ng-required="true" className="form-control outline" />
                                  <label>&nbsp;&nbsp;Currency Code<span>*</span>&nbsp;&nbsp;</label>
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-12 col-12">
                                <div>
                                  <button type="submit" className="btn-custom-1">Save</button> &nbsp;&nbsp;&nbsp;
                          <button type="button" className="btn-custom-2" data-dismiss="modal">Cancel</button>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix" />
                          </div>
                        </form>
                      </div> {/* End Model-body*/}
                    </div> {/* End Model-Content*/}
                  </div> {/* End Model-dialog*/}
                </div> {/* End Modal Fade*/}
                {/*


<div className="content-wrapper">
    <div className="content-body">
        <div className="row" ng-app="myApp">
            <div className="col-xl-12 col-md-12 col-12">
                <div className="card">
                    <div className="card-header">


                        <div className="navbar no-radius">

                            <ul className="nav navbar-nav" style="margin-left:66px">
                                <li className="nav-item">
                                    <span className="navbar-item text-md">{{header}}</span>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link text-muted" title="Reply" data-toggle="modal" data-target="#modal-Currency">
                                        <span className="">
                                            <i className="fa fa-fw fa-plus"></i>
                                            <span className="hidden-sm-down">New Currency</span>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className="card-content">
                        <table className="table" aria-describedby="example_info" role="grid">
                            <thead>
                                <tr>
                                    <th>Currency Name</th>
                                    <th>Currency Code</th>
                                    <th>Action</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr ng-repeat="item in list">
                                    <td>{{ item.currencyName }}</td>
                                    <td>{{ item.currencyCode }}</td>
                                    <td>
                                        <a ng-click="Edit(item)"><span className="ficon bx bx-pencil"></span></a>
                                        <a ng-click="Delete(item.id)"><span className="ficon bx bx-trash"></span></a> 
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="modal-Currency" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header _600">
                            <button type="button" className="close" ng-click="closePopup()" aria-label="Close"><span aria-hidden="true">&times;</span></button> New Currency
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal" ng-submit="Create(currencyForm.$valid)" novalidate name="currencyForm">
                                <div className="form-group row">

                                    <div className="form-group col-lg-6 col-md-6">
                                       
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6">
                                      
                                    </div>

                                    <div className="clearfix"></div>


                                </div>

                                <div className="form-group row">
                                    <div className="col-lg-6 offset-lg-2" style="float:right">
                                        <input type="submit" className="btn primary btn-sm p-x-md" value="Save" />

                                        <input type="button" className="btn danger btn-sm p-x-md" ng-click="closePopup()" value="Cancel" />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

*/}
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Currency;