import React, { useState,useEffect ,useContext} from 'react'
import { SettingsContext } from "../../contexts/settings-context";

const GlobalGeneralForm = () => {
  
   const { general , saveSettings} = useContext(SettingsContext);
  const [generalData,setGeneralData] = useState({});
  
  
  useEffect(() => {
    
    setGeneralData({...general});
    return () => {
      
    }
  
}, [general])

const updateState = (event) =>{
  const path = event.target.name.split('.');
  const depth = path.length;
  const state = { ...generalData };
  let ref = state;
  for (let i = 0; i < depth; i += 1) {
    if (i === depth - 1) {
      ref[path[i]] = event.target.value;
    } else {
      ref = ref[path[i]];
    }
  }
  setGeneralData(state);
}

const saveGeneralSettings = e => {
  e.preventDefault();
  generalData.type = 'general';
  
  saveSettings(generalData);
  
};
  return ( 
    
    <>
   
                        <div role="tabpanel" className="tab-pane active" id="settings-vertical-general" aria-labelledby="settings-pill-general" aria-expanded="true">
                          <form name="generalForm" noValidate onSubmit={saveGeneralSettings}>
                            <div className="row mb-1" style={{ backgroundColor: '#4189f6', margin: '10px 1px 1px 1px', borderRadius: '8px' , paddingTop : '10px' , height : '40px' }}>
                              <div className="col-12">
                                <h6 style={{color: '#ffffff', fontWeight: 300, fontSize: '14.5px'}}>General Settings</h6>
                              </div>
                            </div>
                            <div className="row" style={{ marginTop : '20px' }}>
                              <div className="col-12">
                                <div className="form-group">
                                  <label>Base File Path</label>
                                  <div className="controls">
                                    <input type="text" name="baseFilePath" autoComplete="off" className="form-control" 
                                        defaultValue={general.baseFilePath} onChange={updateState} 
                                    />
                                     </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
    
                              <div className="col-md-4 col-lg-4">
                                <div className="form-group">
                                  <label>Default Page Size (General Lists) </label>
                                  <div className="controls">
                                   
                                    <input type="text" name="pageSize.generalList" autoComplete="off" className="form-control" placeholder="Default page size for general lists" 
                                       value={general.pageSize.generalList} 
                                      onChange={updateState}
                                    />
                                   
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-lg-4">
                                <div className="form-group">
                                  <label>Default Page Size (Task List)</label>
                                  <div className="controls">
                                    <input type="text" name="pageSize.taskList" autoComplete="off" className="form-control" placeholder="Default page size for task list" 
                                      value={general.pageSize.taskList}  onChange={updateState}
                                    />
                                   
                                  </div>
                                </div>
                              </div>
                           
    
                            <div className="col-md-4 col-lg-4">
                                <div className="form-group">
                                  <label>Default Page Size (User List)</label>
                                  <div className="controls">
                                    <input type="text" name="pageSize.userList" autoComplete="off" className="form-control" placeholder="Default page size for user selection list"
                                      value={general.pageSize.userList} onChange={updateState}
                                     />
                                    
                                  </div>
                                </div>
                            </div>
                            </div>
                       
                            <div className="row">
    
                              <div className="col-md-4 col-lg-4">
                                <div className="form-group">
                                  <label>Default Page Size (Dashboard)</label>
                                  <div className="controls">
                                    <input type="text" name="pageSize.dashboard" autoComplete="off" className="form-control" 
                                      value={general.pageSize.dashboard}  onChange={updateState}
                                    />
                                   
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-lg-4">
                                <div className="form-group">
                                  <label>Default Page Size (Discover - Horizontal)</label>
                                  <div className="controls">
                                    <input type="text" name="pageSize.discoverHorizondal" autoComplete="off" className="form-control" placeholder="Default page size for discover horizontal list" 
                                       value={general.pageSize.discoverHorizondal}  onChange={updateState}
                                    />
                                   
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 col-lg-4">
                                <div className="form-group">
                                  <label>Default Page Size (Discover - Vertical)</label>
                                  <div className="controls">
                                    <input type="text" name="pageSize.discoverVertical" autoComplete="off" className="form-control" placeholder="Default page size for discover vertical list"
                                      value={general.pageSize.discoverVertical}  onChange={updateState}
                                    />
                                    
                                  </div>
                                </div>
                              </div>
                            </div>
    
                            <fieldset>
                              <legend> ERP Integration</legend>
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>ERP Database Name</label>
                                    <div className="controls">
                                      <input type="text" name="erpIntegration.dbName" autoComplete="off"  className="form-control"  
                                       value={general.erpIntegration.dbName} onChange={updateState}
                                      />
                                      
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-12">
                                  <div className="form-group">
                                    <label>Connection String</label>
                                    <div className="controls">
                                      <textarea className="form-control" name="erpIntegration.conString"  
                                       value={general.erpIntegration.conString} onChange={updateState}
                                      />
                                     
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="row">
                              <div className="col-12">
                                <button type="submit" className="btn-custom-1" >Save</button> &nbsp;&nbsp;
                                <button type="reset" className="btn-custom-2">Cancel</button>
                              </div>
                            </div>
                          </form>
                        </div>
              
    
    </>
    
             );
}

export default GlobalGeneralForm;