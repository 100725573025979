import React, { Component } from 'react';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

class PlanApproval extends Component {
  state = {}
  render() {
    return (

      <>
        <MainMenu link={`planapproval`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <div className="row dash-blue fixed-top">
                  <div className="col-md-1 col-md-1">
                  </div>
                  <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
                    <div>
                      <div className="inner-section">
                        <h3>Master Settings</h3>
                        <div style={{ marginTop: '-10px' }}>
                          <p className="mt-1">Plan Approval</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
                    <table className="align-items-center table-dashboard-section-one">
                      <tbody><tr>
                        <td rowSpan={2} style={{ width: '300px', textAlign: 'left' }}>
                          <input className="search-dashboard" type="text" name="search" placeholder="Search Here.." />
                        </td>
                      </tr>
                        <tr style={{ fontWeight: 600 }}>
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                        </tr>
                      </tbody></table>
                    <table className="align-items-center table-dashboard-section-last d-flex">
                      <tbody><tr>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-27.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-38.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-37.png" /></a></td>
                        <td rowSpan={2} style={{ fontWeight: 100, display: 'inline-flex' }}>&nbsp;&nbsp;Sort <img src="/images/icon/dashboard-icons/Panel icons-32.png" style={{ marginLeft: '10px' }} /></td>
                      </tr>
                        <tr>
                          <td>&nbsp;&nbsp;</td>
                        </tr>
                        <tr>
                        </tr></tbody></table>
                  </div>
                </div>
                <div className="content-wrapper" ng-app="myApp">
                  <div className="company-table">
                    <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                      <div style={{ float: 'left' }}>
                        <h5 className="left-head">Plan Approval</h5>
                      </div>
                      {/*<div style="float:right;margin-top:-2px;margin-right:-15px;">
            <a title="Reply" data-toggle="modal" data-target="#modal-Country">
                <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;New Country <i className="bx bxs-plus-circle"></i></span>
            </a>
        </div>*/}
                    </div>
                    {/* Start Table Content*/}
                    <table className="table-striped module-table" aria-describedby="example_info">
                      <thead>
                        <tr>
                          <th style={{ paddingLeft: '15px', width: '80px' }}>Sl No.</th>
                          <th style={{ paddingLeft: '100px', width: '300px' }}>Request Date</th>
                          <th style={{ textAlign: 'center', width: '150px' }}>Customer Name</th>
                          <th style={{ textAlign: 'center', width: '250px' }}>Plan Name</th>
                          <th style={{ textAlign: 'center', width: '250px' }}>End Date</th>
                          <th style={{ textAlign: 'center', width: '250px' }}>Payment Status</th>
                          <th style={{ textAlign: 'center', width: '250px' }}>Approval</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr ng-repeat="item in list track by $index">
                          <td style={{ paddingLeft: '20px' }}></td>
                          <td style={{ paddingLeft: '100px' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center', width: '350px' }}></td>
                          <td style={{ textAlign: 'center', paddingLeft: '50px' }}>
                            <select type="text" ng-model="item.paymentStatus" ng-change="changePaymentstatus(item)" className="form-control outline" style={{ fontSize: '12px', height: '33px', width: '100px' }}>
                              <option value={0}>Pending</option>
                              <option value={1}>Done</option>
                            </select>
                          </td>
                          <td style={{ textAlign: 'center' }}>
                            <a ng-click="approve(item)" className="btn btn-success">Approve</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* End Table Content*/}
                    <br />
                    <div className="row">
                      <div className="col-md-12 justify-content-center">
                        <div data-pagination data-num-pages="numOfPages()" data-current-page="curPage" data-max-size="maxSize" data-boundary-links="true" style={{ width: '80%', margin: '0 auto', justifyContent: 'center' }} className="mb-1">
                        </div>
                      </div>
                    </div>
                  </div>{/* ENd Company Table*/}
                </div> {/* End Content- Wrapper*/}
              </div>

            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PlanApproval;