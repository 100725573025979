import React, { Component } from 'react';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

class RoleMaster extends Component {
  state = {}
  render() {
    return (
      <>
        <MainMenu link={`rolemaster`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <div className="row dash-blue fixed-top">
                  <div className="col-md-1 col-md-1">
                  </div>
                  <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
                    <div>
                      <div className="inner-section">
                        <h3>Master Settings</h3>
                        <div style={{ marginTop: '-10px' }}>
                          <p className="mt-1">Role Master</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
                    <table className="align-items-center table-dashboard-section-one">
                      <tbody><tr>
                        <td rowSpan={2} style={{ width: '300px', textAlign: 'left' }}>
                          <input className="search-dashboard" type="text" name="search" placeholder="Search Here.." />
                        </td>
                      </tr>
                        <tr style={{ fontWeight: 600 }}>
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                        </tr>
                      </tbody></table>
                    <table className="align-items-center table-dashboard-section-last d-flex">
                      <tbody><tr>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-27.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-38.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-37.png" /></a></td>
                        <td rowSpan={2} style={{ fontWeight: 100, display: 'inline-flex' }}>&nbsp;&nbsp;Sort <img src="/images/icon/dashboard-icons/Panel icons-32.png" style={{ marginLeft: '10px' }} /></td>
                      </tr>
                        <tr>
                          <td>&nbsp;&nbsp;</td>
                        </tr>
                        <tr>
                        </tr></tbody></table>
                  </div>
                </div>
                <div className="content-wrapper" ng-app="myApp">
                  <div className="company-table">
                    <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                      <div style={{ float: 'left' }}>
                        <h5 className="left-head">Role Master</h5>
                      </div>
                      <div style={{ float: 'right', marginTop: '-2px', marginRight: '-15px' }}>
                        <a title="Reply" data-toggle="modal" data-target="#modal-Rolemaster">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;New Role Master <i className="bx bxs-plus-circle" /></span>
                        </a>
                      </div>
                    </div>
                    {/* Start Table Content*/}
                    <table className="table-striped module-table" aria-describedby="example_info">
                      <thead>
                        <tr>
                          <th style={{ paddingLeft: '15px', width: '80px' }}>Sl No.</th>
                          <th style={{ paddingLeft: '100px', width: '300px' }}>RoleMaster Name</th>
                          <th style={{ textAlign: 'center', width: '150px' }}>RoleMaster Code</th>
                          <th style={{ textAlign: 'center', width: '150px' }}>Set Permission</th>
                          <th style={{ textAlign: 'center', width: '250px' }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr ng-repeat="item in list track by $index">
                          <td style={{ paddingLeft: '20px' }}></td>
                          <td style={{ paddingLeft: '100px' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}><a ng-click="rolePermission(item)" data-toggle="modal" data-target="#modal-rolePermission"><span className="bx bx bxs-user-plus" style={{ fontSize: '22px' }} /></a></td>
                          <td style={{ textAlign: 'center' }}>
                            <a ng-click="Edit(item)"><i className="bx bxs-pencil text-dark" /></a>{/*ng-if="rolePermission.canedit"*/}
                            <a ng-click="Delete(item.id)"><i className="bx bxs-trash-alt text-dark" /></a> {/*ng-if="rolePermission.candelete"*/}
                            {/*ng-if="rolePermission.candelete"*/}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* End Table Content*/}
                    <br />
                    <div className="row">
                      <div className="col-md-12 justify-content-center">
                        <div data-pagination data-num-pages="numOfPages()" data-current-page="curPage" data-max-size="maxSize" data-boundary-links="true" style={{ width: '80%', margin: '0 auto', justifyContent: 'center' }} className="mb-1">
                        </div>
                      </div>
                    </div>
                  </div> {/* End Top Section*/}
                </div> {/* ENd Company Table*/}
                {/*  Modal Box of Role Master Begins*/}
                <div className="modal fade" id="modal-Rolemaster" data-backdrop="true" data-keyboard="false">
                  <div className="modal-dialog modal-lg modal-plus">
                    <div className="modal-content" style={{ overflow: 'hidden' }}>
                      <div className="modal-header modal-bg">
                        <span>New Role Master</span>
                        <div>
                          <button type="button" className="close-pink" data-dismiss="modal" aria-label="Close">
                            <div style={{ marginTop: '-4px', marginLeft: '1px' }}><span className="cross">×</span></div>
                          </button>
                        </div>
                      </div> {/* End Model-header*/}
                      <div className="modal-body" style={{ overflowY: 'auto', padding: '50px' }}>
                        <form className="form-horizontal" ng-submit="Create(rolemasterForm.$valid)" noValidate name="rolemasterForm">
                          <div className="form-group row">
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="hidden" ng-model="Rolemaster.id" />
                                  <input type="text" ng-model="Rolemaster.roleName" ng-blur="CheckAvailability(Rolemaster.roleName)" ng-required="true" className="form-control outline" />
                                  <label>&nbsp;&nbsp;Role Name<span>*</span>&nbsp;&nbsp;</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="number" ng-model="Rolemaster.roleOrder" ng-required="true" className="form-control outline" />
                                  <label>&nbsp;&nbsp;Role Order<span>*</span>&nbsp;&nbsp;</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <select type="text" ng-model="Rolemaster.userMenuId" className="form-control outline">
                                  <option value={0}>--Select User Menu--</option>
                                  <option ng-repeat="item in usermenus" ng-selected="item.userMenuId == Rolemaster.userMenuId" value="{{item.userMenuId}}">{'{'}{'{'}item.userMenuName{'}'}{'}'}</option>
                                </select>
                              </div>
                              <div className="col-md-6 col-12">
                                <label>Active<span>*</span></label>
                                <input type="checkbox" ng-model="Rolemaster.isActive" defaultChecked="checked" />
                              </div>
                              <div className="col-md-12 col-lg-12 col-12">
                                <div style={{ float: 'left', marginLeft: '-15px', verticalAlign: 'baseline' }}>
                                </div>
                                <div style={{ float: 'right', marginRight: '-2px' }}>
                                  <button type="submit" className="btn-custom-1">Save</button> &nbsp;&nbsp;&nbsp;
                          <button type="button" className="btn-custom-2" data-dismiss="modal" >Cancel</button>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix" />
                          </div>
                        </form>
                      </div> {/* End Model-body*/}
                    </div> {/* End Model-Content*/}
                  </div> {/* End Model-dialog*/}
                </div> {/* End Modal Box Role Master*/}
                {/* Begin Modal Box of Role Permission*/}
                <div className="modal fade" id="modal-rolePermission" data-backdrop="true" data-keyboard="false" style={{ position: 'fixed', top: '20px', left: '60px' }}>
                  <div className="modal-dialog modal-xl">
                    <div className="modal-content" style={{ overflow: 'hidden' }}>
                      <div className="modal-header modal-bg">
                        <span>Set Permission Roles</span>
                        <div>
                          <button type="button" className="close-pink" ng-click="closePopupRolepermission()" aria-label="Close">
                            <div style={{ marginTop: '-4px', marginLeft: '1px' }}><span className="cross">×</span></div>
                          </button>
                        </div>
                      </div> {/* End Model-header*/}
                      <div className="modal-body" style={{ overflowY: 'auto', padding: '50px' }}>
                        <form className="form-horizontal" ng-submit="AssignPermission(rolepermissionForm.$valid)" noValidate name="rolepermissionForm">
                          <div className="form-group row">
                            <table className="table-striped module-table">
                              <thead>
                                <tr>
                                  <th style={{ paddingLeft: '20px' }}><i className="bx bxs-select-multiple" /></th>
                                  <th>Module Name</th>
                                  <th>Submodule Name</th>
                                  <th>can view</th>
                                  <th>can save</th>
                                  <th>can edit</th>
                                  <th>can delete</th>
                                  <th>can print</th>
                                </tr>
                              </thead>
                              <tbody>
                                {/*<tr ng-repeat="item in rolePermissions">*/}
                                <tr ng-repeat="item in rolePermissions" style={{ height: '20px' }}>
                                  <td style={{ paddingLeft: '20px' }}><input type="checkbox" ng-change="checkall(this)" ng-model="checked" /></td>
                                  <td ng-dblclick="dblclickmodule(item.moduleId)">{'{'}{'{'} item.moduleName {'}'}{'}'}</td>
                                  <td ng-dblclick="dblclicksubmodule(item.subModuleId)">{'{'}{'{'} item.submoduleName {'}'}{'}'}</td>
                                  <td><input type="checkbox" ng-model="item.canview" /></td>
                                  <td><input type="checkbox" ng-model="item.cansave" /></td>
                                  <td><input type="checkbox" ng-model="item.canedit" /></td>
                                  <td><input type="checkbox" ng-model="item.candelete" /></td>
                                  <td><input type="checkbox" ng-model="item.canprint" /></td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="clearfix" />
                          </div>
                          <div className="form-group row">
                            <div className="col-lg-12 col-md-12" style={{ textAlign: 'right' }}>
                              <button type="submit" className="btn-custom-1">Save</button> &nbsp;&nbsp;&nbsp;
                              <button type="button" data-dismiss="modal" className="btn-custom-2">Cancel</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* End Modal Box of Role Permission*/}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RoleMaster;