import React from 'react';

class DropdownBottom extends React.Component {
    constructor(props) {
        super(props);
    }

    getAllDropdownItems = (groupItems) => {
        let items = [];
        let groupHeader = groupItems[0];
        let cellItemIndex = groupHeader.cellItems.findIndex(cell => cell._id === this.props.id);
        for (let i = 1; i < groupItems.length; i++) {
            let cellItem = groupItems[i].cellItems[cellItemIndex];
            let body = JSON.parse(cellItem.body);
            if (body.length > 0) {
                if (items.length === 0) {
                    items = [...body]
                } else {
                    for (let j = 0; j < body.length; j++) {
                        let checkItems = items.filter(item => item._id === body[j]._id);
                        // console.log("checkTags===>", checkTags)
                        if (checkItems.length === 0) {
                            items.push(body[j])
                        }
                    }
                }
            }
        }

        return items;
    }

    render() {
        let dropdownItems = this.getAllDropdownItems(this.props.groupItems)
        return (
            <div className='dropdown-items pl-1 pr-1 w-100'>
                <div className="dropdown-cell-item" style={{ backgroundColor: '#e6e9ef' }} >
                    <div className="dropdown-option-wrapper">
                        {
                            dropdownItems.length > 2 ? (
                                <>
                                    <div className="option-names with-counter-options">
                                        {dropdownItems.map((item, index) => (
                                            index < 2 ? (
                                                <div className="option-name cell_focused" key={index}>
                                                    <div className="ds-text-component">{item.name}</div>
                                                </div>
                                            ) : ''
                                        ))}
                                    </div>
                                    <div className="dropdown-counter-component">
                                        <div className="dropdown-counter-text">+{dropdownItems.length - 2}</div>
                                    </div>
                                </>
                            ) : (
                                    <>
                                        <div className="option-names with-counter-options">
                                            {dropdownItems.map((item, index) => (
                                                <div className="option-name cell_focused" key={index}>
                                                    <div className="ds-text-component">{item.name}</div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default DropdownBottom;