import Axios from 'axios';
import config from '../components/services/config.json';

let base_url = process.env.NODE_ENV === "development" ? `http://localhost:5000/api` : config.apiEndPoint;
console.log("______ process.env.NODE_ENV ____", process.env.NODE_ENV)

let axios = Axios.create({
   baseURL: `${base_url}`,
   headers: {
      'Content-Type': 'application/json',
   }
});

axios.interceptors.request.use(
   (config) => {
      // console.log('axios request interceptor: ', config);
      const token = JSON.parse(localStorage.getItem('token')); 
      if (token) {
         config.headers.common['authorization'] = `Bearer ${token}`
      }

      return config;
   },
   (err) => Promise.reject(err)
);

export default axios;