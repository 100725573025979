import React from 'react'
import { connect } from 'react-redux'
import ResizePanel from "react-resize-panel";
import { Table, TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col } from 'reactstrap';
import axios from '../../../../../util/Api';
import { fetchError } from '../../../../../actions'
import { format } from 'date-fns';
import { getLocationTimeFromDate, timeSince } from '../../../../../util';
import { createImageFromInitials, getRandomColor } from '../../../../../util/People';

class BoardActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: '1',
            activities: [],
            lastViewed: null
        }

        this.modalContainer = React.createRef();
    }

    componentDidMount() {
        let workspaceBoard = this.props.boardData.workspaceBoard;
        let lastViewed = workspaceBoard.lastViewed;
       
        if(lastViewed){
            this.setState({ lastViewed: JSON.parse(lastViewed) })
        }
        
        axios.post('workspace/getBoardActivities', {
            workspaceBoardId: this.props.boardData.boardID
        }).then(({ data }) => {
            // console.log("data.activities: ", data.activities)
            this.setState({ activities: data.activities })
        }).catch(err => {
            this.props.fetchError(err)
        })
    }

    toggle = (tab) => {
        if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
    }

    render() {
        return (
            <div className='right-side'>
                <div className='right-side-content'>
                    <ResizePanel direction="w" handleclassName="customHandle">
                        <div className='w-100 p-3'>
                            <div className='right-side-header'>
                                <h4 className='right-side-header-title'>{this.props.boardTitle}</h4>
                                <div className='right-side-close'
                                    onClick={() => this.props.closeModal()}
                                >
                                    <i className='fa fa-close'></i>
                                </div>
                            </div>
                            <div className='right-side-body'>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            className={`right-side-tab ${this.state.activeTab === '1' ? 'active' : ''}`}
                                            onClick={() => { this.toggle('1'); }}
                                        >
                                            Activity
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`right-side-tab ${this.state.activeTab === '2' ? 'active' : ''}`}
                                            onClick={() => { this.toggle('2'); }}
                                        >
                                            Last Viewed
                                        </NavLink>
                                    </NavItem>
                                    {/* <NavItem>
                                        <NavLink
                                            className={`right-side-tab ${this.state.activeTab === '3' ? 'active' : ''}`}
                                            onClick={() => { this.toggle('3'); }}
                                        >
                                            Updated
                                        </NavLink>
                                    </NavItem> */}
                                </Nav>
                                <TabContent activeTab={this.state.activeTab}>
                                    <TabPane tabId="1" style={{ maxHeight: '100vh', overflow: 'auto' }}>
                                        <br></br>
                                        {this.state.activities.map((activity, index) => (
                                            <div className='activity-logs' key={index}>
                                                <div className='row w-100'>
                                                    <div className='col-2'>
                                                        <div className='activity-time'>
                                                            <span className='data-title' data-title={`${format(new Date(activity.createdAt), 'MMM d, hh:mm a')}`}>
                                                                <i className='fa fa-clock-o'></i>&nbsp;
                                                                {timeSince(new Date(activity.createdAt))}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className='col-1'>
                                                        <img src={createImageFromInitials(28, activity.user.firstname?activity.user.firstname:'A' + ' ' + activity.user.lastname?activity.user.lastname:'B', activity.user.color && activity.user.color !== '' ? activity.user.color : getRandomColor())} style={{ borderRadius: '50%' }} />
                                                    </div>
                                                    <div className='col-2'>
                                                        <div className='activity-type'>
                                                            {activity.activityType}
                                                        </div>
                                                    </div>
                                                    <div className='col-3 data-title activity-col-title' data-title={activity.activityName}>
                                                        <div className='activity-name'>
                                                            {activity.activityName}
                                                        </div>
                                                    </div>
                                                    <div className='col-3 data-title  activity-col-title' data-title={`${activity.activityContent.preview !== '' ? activity.activityContent.preview + ' >' : ''} ${activity.activityContent.next}`}>
                                                        <div className='activity-content'>
                                                            {activity.activityType === 'board' ? (
                                                                <>
                                                                    {activity.activityContent.type === 'create' ? (
                                                                        <span>{activity.activityContent.next}</span>
                                                                    ) : activity.activityContent.type === 'update' ? (
                                                                        <span className='activity-status'>
                                                                            <span style={{ paddingRight: '3px' }}>{activity.activityContent.preview}</span>
                                                                            <span className='activity-update'>{'>'}</span>
                                                                            <span style={{ paddingLeft: '3px' }}>
                                                                                {activity.activityContent.next}
                                                                            </span>
                                                                        </span>
                                                                    ) : (
                                                                                <span>{activity.activityContent.preview}</span>
                                                                            )}
                                                                </>
                                                            ) : activity.activityType === 'member' ? (
                                                                <span>
                                                                    {activity.activityContent.next}
                                                                </span>
                                                            ) : ''}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row>
                                            <Col sm="12">
                                                <Card body>
                                                    {this.state.lastViewed !== null ? (
                                                        <div className='board-acitivty-last-viewed'>
                                                            {this.state.lastViewed.name === '' ? (
                                                                <div className='d-flex align-items-center'>
                                                                    <img src="/images/users/dark-user.png" style={{ borderRadius: '50%', width: '28px' }} />
                                                                    <div className='pl-2'>{this.state.lastViewed.email}</div>
                                                                </div>
                                                            ) : (
                                                                    <div className='d-flex align-items-center'>
                                                                        <img src={createImageFromInitials(28, this.state.lastViewed.name, this.state.lastViewed.color)} style={{ borderRadius: '50%', width: '28px' }} />
                                                                        <div className='pl-2'>{this.state.lastViewed.name}</div>
                                                                    </div>
                                                                )}
                                                            <div className='last-viewed-times-ago'>
                                                                {timeSince(getLocationTimeFromDate(new Date(this.state.lastViewed.viewed), new Date().getTimezoneOffset()))} ago
                                                            </div>
                                                        </div>
                                                    ) : ''}
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane>
                                    {/* <TabPane tabId="3">
                                        <Row>
                                            <Col sm="6">
                                                <Card body>
                                                    <CardTitle>Special Title Treatment</CardTitle>
                                                    <CardText>With supporting text below as a natural lead-in to additional content.</CardText>
                                                    <Button>Go somewhere</Button>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </TabPane> */}
                                </TabContent>
                            </div>
                        </div>
                    </ResizePanel>
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { fetchError }

export default connect(mapStateToProps, mapDispatchToProps)(BoardActivity);