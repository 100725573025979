import React from 'react';
import { connect } from 'react-redux'
import BoardGroupCellBottom from '../bottom/BoardGroupCellBottom';
import NumbersBottom from '../bottom/NumbersBottom';

class BoardGroupBottom extends React.Component {
    constructor(props) {
        super(props);
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    getColumnWidth = (columnId) => {

        let columnIndex = this.props.cellSuggestions.findIndex(ele => ele._id === columnId);
       // console.log('cccc', this.props.cellSuggestions[columnIndex])
        return parseInt(this.props.cellSuggestions[columnIndex].width)
    }

    getcolumnIndex = (columnId) => {

        let columnIndex = this.props.cellSuggestions.findIndex(ele => ele._id === columnId);
       // console.log('cccc', this.props.cellSuggestions[columnIndex])
        return parseInt(columnIndex)
    }

    render() {
        // console.log("group bottom props===>", this.props);å
        const { groupItems, groupId } = this.props
        const groupItem = groupItems[0];
        // console.log("groupItem===>", groupItem)
        return (

         
            <div className='d-flex flex-item w-100 p-1 ' style={{ 'marginTop' : '-12px' , height: '33px' }}>
                <div class="title-column" style={{ minWidth: '400px' , backgroundColor : '#efefef' , height : '40px' }}>
                    <div className='empty-item'>

                    </div>
                </div>
                
                {groupItem.cellItems
                .filter(cellItems => cellItems.restrictedMembers?!cellItems.restrictedMembers.includes(this.authUser._id):true)
                .map((cellItem, index) => (
                    <div
                    style={{ width: this.getColumnWidth(cellItem.columnId) }}
                    key={index} className={`d-flex flex-item board-bottom ${(cellItem.restrictedMembers?cellItem.restrictedMembers.includes('ViewOnly_'+this.authUser._id):false) ? "disablediv" : ""}`}>
                        <BoardGroupCellBottom itemKey={cellItem.key} columnId={cellItem.columnId} id={cellItem._id} groupId={groupId} groupItems={groupItems} colInd={this.getcolumnIndex(cellItem.columnId)}/>
                    </div>
                ))}
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(BoardGroupBottom);