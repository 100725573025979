export const  getAllCity = "city/getAllCity"; 
export const  getCityById = "city/getCityById"; 
export const  getCityByStateId = "city/getCityByStateId"; 
export const  saveCity = "city/saveCity" ; 
export const  updateCity = "city/updateCity" ; 
export const  deleteCity = "city/deleteCity" ;

export default {
    getAllCity : getAllCity , 
    getCityById : getCityById , 
    getCityByStateId : getCityByStateId , 
    saveCity : saveCity ,  
    updateCity : updateCity , 
    deleteCity : deleteCity 
}