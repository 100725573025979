import config from '../services/config.json';
import GsWorkSpaceBoardDataService from '../../data-services/global-search-services';
import SavedSearch from '../../data-services/saved-search-data-service';
import RecentSearch from '../../data-services/recent-search-data-service';
import PeopleSearch from '../../data-services/users-data-services';
//for hot tags
export const GET_ALL_WORKSPACE = config.apiEndPoint + GsWorkSpaceBoardDataService.getAllWorkSpaceBoard;
export const GET_ALL_SEARCH_WORKSPACE = config.apiEndPoint + GsWorkSpaceBoardDataService.getAllSearchWorkSpaceBoard;
export const GET_ALL_SEARCH_UPDATES = config.apiEndPoint + GsWorkSpaceBoardDataService.getSearchUpdates;
//saved search

export const GET_ALL_SAVED_SEARCH = config.apiEndPoint + SavedSearch.getAllSavedSearch;

export const GET_SAVED_SEARCH_BY_ID = config.apiEndPoint + SavedSearch.getSavedSearchById;
export const GET_SAVED_SEARCH_BY_USER_ID = config.apiEndPoint + SavedSearch.getSavedSearchByUserId;

export const GET_MAX_SAVED_SEARCH = config.apiEndPoint + SavedSearch.getMAxSavedSearch;

export const SAVE_SAVED_SEARCH = config.apiEndPoint + SavedSearch.saveSavedSearch;

export const UPDATE_SAVED_SEARCH = config.apiEndPoint + SavedSearch.updateSavedSearch;

export const DELETE_SAVED_SEARCH = config.apiEndPoint + SavedSearch.deleteSavedSearch;

export const SAVED_SEARCH_SEARCH = config.apiEndPoint + SavedSearch.SavedSearch_Search;

//recent search


export const GET_ALL_RECENT_SEARCH = config.apiEndPoint + RecentSearch.getAllRecentSearch;
export const GET_MAX_RECENT_SEARCH = config.apiEndPoint + RecentSearch.getMaxRecentSearch;
export const GET_WORKSPACE_BY_ID = config.apiEndPoint + RecentSearch.getWorkspaceBoardyById;
export const GET_RECENT_SEARCH_BY_USER_ID = config.apiEndPoint + RecentSearch.getRecentSearchByUserId;
export const SAVE_RECENT_SEARCH = config.apiEndPoint + RecentSearch.saveRecentSearch;
export const UPDATE_RECENT_SEARCH = config.apiEndPoint + RecentSearch.updateRecentSearch;
export const DELETE_RECENT_SEARCH = config.apiEndPoint + RecentSearch.deleteRecentSearch;
export const SEARCH_RECENT_SEARCH = config.apiEndPoint + RecentSearch.recentSearch_Search

//people search

export const GET_ALL_USERS = config.apiEndPoint + PeopleSearch.getallUser

export const GET_WORKSPACE_BY_BOARDID = config.apiEndPoint + GsWorkSpaceBoardDataService.getWorkspaceByBoardId;

export const view = [1000, 150]; // ViewBox: Width, Height
export const trbl = [20, 50, 20, 50]; // Margins: Top, Right, Bottom, Left

export const dims = [
  view[0] - trbl[1] - trbl[3], // Adjusted dimensions width
  view[1] - trbl[0] - trbl[2] // Adjusted dimensions height
];
