import React, { Component, useState, useEffect } from "react";
import config from "../services/config.json";
import http from "../services/http-service";
import schedulerDataService from "../../data-services/scheduler-data-services";
import AvatarUsers from "../avatar/avatarusers";

const moment = require("moment");
const ReminderList = (props) => {
  const [isViewAll, setViewAll] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    getReminderList();
  }, [isViewAll]);
  const getReminderList = async () => {
    var params = {
      userId: props.userId,
      isViewAll: isViewAll,
    };

    await http
      .post(config.apiEndPoint + schedulerDataService.getReminderList, params)
      .then((response) => {
        setData(response.data.reminderList);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleOnClick = async (e) => {
    await setViewAll(1);
  };

  return (
    <div
      className="card"
      style={{
        border: "none",
        boxShadow: "0px 1px 7px 1px #dcd7d7",
        height: "auto",
        marginTop: "50px",
        marginRight: "20px"
      }}
    >
      <div className="card-header-schedule">
        <div className="row match-height">
          <div
            className="col-md-12 col-12 d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <h6 style={{ marginTop: "20px", marginLeft: "20px" }}>Reminders</h6>
            <a
              className="nav-link"
              href="#"
              onClick={handleOnClick}
              style={{
                float: "right",
                marginTop: "10px",
                verticalAlign: "bottom",
                fontSize: "12px",
                color: "#333",
              }}
            >
              View All
            </a>
          </div>
        </div>
      </div>
      <div
        className="card-content"
        style={{ maxHeight: "600px", overflow: "auto" }}
      >
        <div
          className="card-body"
          style={{ padding: "5px", overflow: "hidden" }}
        >
          {data.map((r, index) => (
            <div
              className="p-1 mb-2 bg-white"
              style={{
                borderRadius: "8px",
                border : "1px solid #efefef" ,
              }}
              key={index}
            >
              <div>
                <div className="row">
                  <div className="col-md-12 text-right">
                    <small className="text-muted">
                      {moment.utc(r.date).local().format("DD MMM YYYY | h:mmA")}
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-4" style={{ textAlign: "center" }}>
                    <div className="avatar mr-1 avatar-xl">
                      <img
                        className="round"
                        src="images/usericon.png"
                        height="100"
                        width="100"
                      ></img>
                    </div>
                    <h6
                      className="text-bold-600 text-center"
                      style={{
                        lineheight: "12px",
                        fontfamily: "myriad-pro,sans-serif",
                      }}
                    >
                      <small style={{ color: "#21386C" }}>Sarah Norfal</small>
                    </h6>
                  </div>
                  <div className="col-md-8" style={{ overflow: "hidden" }}>
                    <div className="row" style={{ marginTop: "10px" }}>
                      <span
                        className="card-text text-bold-400"
                        style={{
                          fontSize: "13px",
                          color: "#336",
                          width: "100%",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {r.title}
                      </span>
                      <span style={{ fontSize: "11px", fontWeight: "100" }}>
                        {r.description}
                      </span>
                    </div>
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          textAlign: "right",
                          fontWeight: "300",
                          fontSize: "12.5px",
                          marginTop: "20px",
                        }}
                      >
                        Team
                      </span>
                      <AvatarUsers></AvatarUsers>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default ReminderList;
