import React from 'react';
import Moment from 'moment';
import ReactFlexyTable from 'react-flexy-table'
import 'react-flexy-table/dist/index.css'
import './table-style.css'; 
import deleteIcon from './icons/delete.png'
import editIcon from './icons/edit.png'
import 'react-flexy-table/dist/index.css'

const BusinessCategoryTable = (props) => {
  const columns = [
     {
       header: 'SN.',
       key: 'h_0',
     },
     {
       header: 'BC Name',
       key: 'h_1'
     },
     {
       header: 'BC Code	',
       key: 'h_2'
     },
     {
       header: 'Created By	',
       key: 'h_3'
     },
     {
       header: 'Created On	',
       key: 'h_4'
     },
     {
       header: 'Modified By',
       key: 'h_5'
     },
     {
       header: 'Modified On	',
       key: 'h_6'
     },
     {
       header: 'Action',
       td: (data) => {
         return (
             <div>
                 <a onClick={() => loadBusinessCategory(data.itemId)} data-toggle="modal" data-target="#modal-Country"><img width={30} height = {30} src={editIcon} alt="edit"></img></a>
                 <img src={deleteIcon} alt="delete" width={30} height = {30} onClick={() => deleteRecord(data.itemId)} />                  
             </div>
         )
     }
     },
   ]
   const updatedData = []; 
 
     const {TableData  , loadBusinessCategory , deleteRecord} = props; 
 
     return (  
       <div style={{marginTop:"50px"}}>
       {
         TableData.map((item)=>{
           item.h_4 = Moment(item.h_4).format("YYYY-MM-DD hh:mm:ss"); 
           item.h_6 = Moment(item.h_6).format("YYYY-MM-DD hh:mm:ss"); 
           updatedData.push(item)
         })
       }
           <ReactFlexyTable data={updatedData} columns  = {columns} className='my-table' filterable />
       </div>
    
    );
 }
  
 export default BusinessCategoryTable;



