import React from "react";
import Faq from "react-faq-component";
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';
import "./faq.css";
// https://github.com/binodswain/react-faq-component#readme
const data = {
    title: "FAQ (How it works)",
    rows: [
        {
            title: "How Can I Register New Account",
            content: "You Can Go To This Link http://localhost:3000/register  and create new account",
        },

        {
            title: "Can I use html as content",
            content: `Yes, here is an example of a <a href="https://binodswain.github.io/react-faq-component/">link</a>`,
        },
        {
            title: "What is the package version",
            content: "v1.0.0",
        },
    ],
};

export default function FAQ() {
    return (
        <>
            <MainMenu link={`faq`} />
            <div id="whole-layout">
                <NavBar />
                <div id="appdata" className='app-content content fixed-top content-lay-out'>
                    <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
                    <div className="content-wrapper">
                        <div id="mySidenav" className="sidenav">
                            <a href="#" className="closebtn" onClick={() => {
                                document.getElementById("mySidenav").style.width = "0%";
                            }}>&times;</a>
                        </div>
                        <div>
                            <div className="row" style={{ padding: "50px" }}>
                                <h2 className="section-title">Engage FAQ Questions</h2>
                                <div className="faq-style-wrapper col-md-12">
                                    <Faq data={data} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}
