import React from 'react';
import { format } from 'date-fns';
import { getToday } from '../../../../../util';

class DateBottom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dateShowOptions: 'option1',
            startDate: null,
            endDate: null,
            range: null,
            pastPercent: 0,
            featurePercent: 0,
            hover: false,
            open: false
        }
        this.dropdownContainer = React.createRef();
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false });
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
        this.getDateRangeMaxMin(this.props.groupItems);
    }

    componentWillReceiveProps(nextProps) {
        this.getDateRangeMaxMin(nextProps.groupItems);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }


    getDateRangeMaxMin = (groupItems) => {
        let groupHeader = groupItems[0];
        let cellItemIndex = groupHeader.cellItems.findIndex(cell => cell._id === this.props.id);
        let cellItemDates = [];
        for (let i = 1; i < groupItems.length; i++) {
            let cellItem = groupItems[i].cellItems[cellItemIndex];
            if (cellItem && cellItem.body !== "") {
                cellItemDates.push(new Date(cellItem.body));
            }
        }

        if (cellItemDates.length > 0) {
            let endDate = new Date(Math.max.apply(null, cellItemDates));
            let startDate = new Date(Math.min.apply(null, cellItemDates));
            let range, diffDays = this.getDateRange(startDate, endDate);
            if (diffDays === 0) {
                range = 1;
            } else {
                range = diffDays;
            }

            let now = new Date(getToday());
            let pastPercent, featurePercent;
            if (now < startDate) {
                pastPercent = 0;
                featurePercent = 0;
            } else if (now > endDate) {
                pastPercent = 100;
                featurePercent = 0;
            } else {
                let pastDays = this.getDateRange(startDate, now);
                // console.log("pastDays", pastDays);
                // console.log("statedate", startDate)
                let featureDays = this.getDateRange(now, endDate);
                pastPercent = parseFloat((pastDays / range) * 100).toFixed(2);
                if (pastDays === 0) {
                    featurePercent = 0;
                } else {
                    featurePercent = parseFloat((featureDays / range) * 100).toFixed(2);
                }

            }


            this.setState({ startDate: startDate, endDate: endDate, range: range, pastPercent: pastPercent, featurePercent: featurePercent })
        }

    }

    getDateRange = (startDate, endDate) => {
        const diffTime = Math.abs(endDate - startDate);
        const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    changeOptions = (ev) => {
        // console.log("value===>", ev.target.value);
        this.setState({ dateShowOptions: ev.target.value })
    }

    render() {
        // this.getDateRangeMaxMin(this.props.groupItems)
        return (
            <div className="date-bottom p-1 w-100" ref={this.dropdownContainer}>
                <div className="date-bottom-content"
                    onMouseEnter={() => this.setState({ hover: true })}
                    onMouseLeave={() => this.setState({ hover: false })}
                    onClick={() => this.setState({ open: !this.state.open })}
                >
                    {this.state.range === null ? (
                        <div className='date-bottom-range' style={{ backgroundColor: '#c4c4c4' }}>
                            <span> - </span>
                        </div>
                    ) : (
                            <>
                                {this.state.dateShowOptions === 'option1' ? (
                                    <div className="date-bottom-range"
                                        style={{ background: `linear-gradient(to right, #a25ddc ${this.state.pastPercent}%, #1c1f3b ${this.state.featurePercent}%)` }}>
                                        <div style={{ display: this.state.hover ? 'none' : '' }}>
                                            {this.state.range === 1 ? (
                                                <span>
                                                    {format(this.state.startDate, 'MMM d, y')}
                                                </span>
                                            ) : (
                                                    <span>
                                                        {format(this.state.startDate, 'MMM d, y')} - {format(this.state.endDate, 'MMM d, y')}
                                                    </span>
                                                )}
                                        </div>
                                        <div style={{ display: !this.state.hover ? 'none' : '' }}>
                                            <span>{this.state.range}d</span>
                                        </div>
                                    </div>
                                ) : this.state.dateShowOptions === 'option2' ? (
                                    <div className="date-bottom-item">
                                        <div className="text-center">
                                            <div className="date-bottom-earliest">{format(this.state.startDate, 'MMM d, y')}</div>
                                            <div className="date-bottom-type">earliest</div>
                                        </div>
                                    </div>
                                ) : (
                                            <div className="date-bottom-item">
                                                <div className="text-center">
                                                    <div className="date-bottom-earliest">{format(this.state.endDate, 'MMM d, y')}</div>
                                                    <div className="date-bottom-type">latest</div>
                                                </div>
                                            </div>
                                        )}
                            </>
                        )}
                </div>
                {this.state.open ? (
                    <div className=" status-bottom-dropdown">
                        <ul className={`p-2 dropdown-menu multi-level animate slideIn ${this.state.open ? 'show' : ''}`} role="menu" style={{ width: '168px', marginLeft: '-165px' }}  >
                            <li>
                                <div className="radio-field">
                                    <input type="radio" value="option1"
                                        id="date-option1"
                                        checked={this.state.dateShowOptions === 'option1'}
                                        onChange={this.changeOptions} />
                                    <label htmlFor="date-option1">Earliest to Latest</label>
                                </div>
                            </li>
                            <li>
                                <div className="radio-field">
                                    <input type="radio" value="option2"
                                        id="date-option2"
                                        checked={this.state.dateShowOptions === 'option2'}
                                        onChange={this.changeOptions} />
                                    <label htmlFor="date-option2">Earliest</label>
                                </div>
                            </li>
                            <li>
                                <div className="radio-field">
                                    <input type="radio" value="option3"
                                        id="date-option3"
                                        checked={this.state.dateShowOptions === 'option3'}
                                        onChange={this.changeOptions} />
                                    <label htmlFor="date-option3"> Latest</label>
                                </div>
                            </li>
                        </ul>
                    </div>
                ) : ''}

            </div>
        )
    }
}

export default DateBottom;
