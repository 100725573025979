import React from 'react';

function ModalClient(props) {
    return ( 
        <div className="modal fade" id="modal-client" data-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content" style={{ padding: 0, width: '100%', margin: '0 auto', border: 'none' }}>
              <div className="modal-header _600" style={{ backgroundColor: '#22386B', color: '#fff', borderRadius: '8px 8px 0px 0px', height: '60px' }}>
                Add Client
                <button type="button" className="close-pink" ng-click="closeclientPopup()" aria-label="Close">
                  <div style={{ color: '#ffffff', marginTop: '-2px' }}><span aria-hidden="true">×</span></div>
                </button>
              </div>
              <form className="form-horizontal" ng-submit="createClient(clientForm.$valid)" noValidate name="clientForm">
                <div className="modal-body" style={{ padding: '40px' }}>
                  <h6>Company Information</h6>
                  <br />
                  <div className="form-group row">
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="text" ng-model="client.name" className="form-control outline" ng-maxlength={100} ng-required="true" />
                        <label>&nbsp;&nbsp;Company&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="text" ng-model="client.weburl" ng-maxlength={100} className="form-control" />
                        <label>&nbsp;&nbsp;Website&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="text" ng-model="client.phoneno" ng-maxlength={50} className="form-control" />
                        <label>&nbsp;&nbsp;Company Tel&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="email" ng-model="client.email" ng-maxlength={50} className="form-control" />
                        <label>&nbsp;&nbsp;Company Email&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="text" ng-model="client.contactpersonname" ng-maxlength={200} className="form-control" />
                        <label>&nbsp;&nbsp;Contact Person&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="email" ng-model="client.contactpersonemail" ng-maxlength={100} className="form-control" />
                        <label>&nbsp;&nbsp;Email ID&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="text" ng-model="client.contactpersonmobile" ng-maxlength={20} className="form-control" />
                        <label>&nbsp;&nbsp;Mobile&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <textarea className="form-control" ng-model="client.address" ng-maxlength={500} defaultValue={""} />
                        <label>&nbsp;&nbsp;Address&nbsp;&nbsp;</label>
                      </div>
                    </div>
                  </div>
                  <h6>Purchase Department</h6>
                  <br />
                  <div className="form-group row">
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="text" ng-model="client.deptdtl[0].name" ng-maxlength={200} className="form-control" />
                        <label>&nbsp;&nbsp;Name&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="email" ng-model="client.deptdtl[0].email" ng-maxlength={50} className="form-control" />
                        <label>&nbsp;&nbsp;Email&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="text" ng-model="client.deptdtl[0].mobile" ng-maxlength={20} className="form-control" />
                        <label>&nbsp;&nbsp;Mobile&nbsp;&nbsp;</label>
                      </div>
                    </div>
                  </div>
                  <h6>Finance Department</h6>
                  <br />
                  <div className="form-group row">
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="text" ng-model="client.deptdtl[1].name" ng-maxlength={200} className="form-control" />
                        <label>&nbsp;&nbsp;Name&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="email" ng-model="client.deptdtl[1].email" ng-maxlength={50} className="form-control" />
                        <label>&nbsp;&nbsp;Email&nbsp;&nbsp;</label>
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-4 col-12">
                      <div className="form-label-group">
                        <input type="text" ng-model="client.deptdtl[1].mobile" ng-maxlength={20} className="form-control" />
                        <label>&nbsp;&nbsp;Mobile&nbsp;&nbsp;</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-12 col-lg-12">
                      <button type="submit" className="btn-custom-1">Save</button> &nbsp;&nbsp;&nbsp;
                      <button type="button" className="btn-custom-2" ng-click="closeclientPopup()">Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
    
    
    );
}

export default ModalClient;