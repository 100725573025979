import React, { useState, useEffect, Component, useContext } from 'react';
import SettingsToolbar from './settingtoolbar';
import GlobalSettingsNavTab from './global-settings-tab';
import GlobalContentForm from './global-content-form';
import SettingsProvider from "../../contexts/settings-context";
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';
const GlobalSettings = () => {


  return (

    <>
      <MainMenu link={`globalsettings`} />
      <div id="whole-layout">
        <NavBar />
        <div id="appdata" className='app-content content fixed-top content-lay-out'>
          <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
          <div className="content-wrapper">
            <div id="mySidenav" className="sidenav">
              <a href="#" className="closebtn" onClick={() => {
                document.getElementById("mySidenav").style.width = "0%";
              }}>&times;</a>
            </div>
            <SettingsProvider >
              <SettingsToolbar></SettingsToolbar>
              <div className="content-wrapper">
                <div className="company-table" style={{ 'boxShadow': 'none', border: 'none' }}>
                  <section id="page-account-settings">
                    <div className="row">
                      <div className="col-12">
                        <div className="row">
                          {/* <input type="text" value={settingsData.general.baseFilePath}/> */}
                          {/* left menu section */}

                          <GlobalSettingsNavTab></GlobalSettingsNavTab>

                          {/* right content section */}
                          <GlobalContentForm ></GlobalContentForm>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </SettingsProvider>
          </div>
        </div>
      </div>

    </>

  );
}



export default GlobalSettings;