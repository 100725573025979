import React from 'react';
import Select from 'react-select';

function SelectInput({ labels, error, ...rest }) {

    const options = rest.options.map(option => ({ value: option, label: option }))
    return (
        <>
        {/* <label>{labels}<span class="asterik">*</span></label> */}
        <label htmlFor="inputfirstname5" style={{textTransform: 'none', fontSize: '14px',
         position: 'absolute', zIndex: 8888, padding: '0px 10px', /* top: '-8px', *//* left: '15px', */
         backgroundColor: 'rgb(255, 255, 255)', fontWeight: 300, marginLeft: '15px', marginTop: '-10px' , 'color' : '#707070' }}>{labels}<span className="asterik">*</span></label>


        <fieldset className="form-label-group form-group position-relative">
            <Select className="register" options={options} onChange={rest.onChange} style={{ color: "#000000", fontSize: '12px', fontWeight: 300, border: '1px solid #CCC' }} />
            
            { error && <div className="alert alert-danger">{error.message}</div>}
        </fieldset>
        </>
    );
}
export default SelectInput;
