import React from 'react';
import { connect } from 'react-redux';
import { changeBoardItem } from '../../../../actions'


class ProgressTrackingItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            progress: 0
        }
    }

    componentDidMount() {
       // let groups = this.props.item;
       // let groupItem = groups[this.props.groupInd].groupItems[this.props.rowInd];
        this.setState({ progress: this.props.body });
        // this.setState({ progress: this.getProgressPercent(groupItem?.cellItems) });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.search.boardID !== '') {
            let groups = this.props.item;
            let groupItem = groups[nextProps.groupInd].groupItems[nextProps.rowInd];
            const progress = this.getProgressPercent(groupItem?.cellItems);
            if (progress !== this.state.progress) {
                this.setState({ progress: progress });
                this.saveProgressPercent(progress);
            }
        }
    }

    getProgressPercent(cellItems) {
        const statusItems = cellItems.filter(cellItem => cellItem.key === "status");
        let doneCount = 0;
        statusItems.map(statusItem => {
            if (statusItem.body === "Done") doneCount++;
        });
        if (statusItems.length === 0) {
            return 0;
        }
        const progress = Math.round(doneCount * 100 / statusItems.length);
        return progress;
    }

    saveProgressPercent = (progress) => {
        const { groupInd, rowInd, colInd, _id } = this.props;
        this.props.changeBoardItem({
            groupInd: groupInd,
            rowInd: rowInd,
            colInd: colInd,
            body: progress,
            cellItemID: _id,
            boardId: this.props.search.boardID
        });
    }

    render() {
        return (
            <div className="d-flex align-items-center justify-content-between" style={{ width: '160px', height: '37px', padding: '8px' }}>
                <progress id="file" min="0" max="100" value={this.state.progress} className="h-100"
                    style={{ borderRadius: 0, border: '1px solid #00ca72', width: 'calc(100% - 50px)' }}>
                </progress>
                <span className="mx-1"> {this.state.progress}% </span>
            </div>
        )
    }
}

const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = { changeBoardItem }

export default connect(mapStateToProps, mapDispatchToProps)(ProgressTrackingItem)