import React from 'react';
import { Link } from 'react-router-dom';
import AvatarEditor from '../common/avatar-editor'

//https://github.com/kirill3333/react-avatar
function HeaderPart(props) {
  return (
    <div className="card-header" style={{ backgroundColor: '#ffffff', border: 'none' }}>

      <div className="card-title">
        <div className="row">
          <div className="col-md-10" style={{ marginLeft: '-20px' }}>
            <h4 className="mb-2">
              <Link to='/home'>
                <img src="/images/engagefinal.png" alt="users avatar" className="users-avatar-shadow" style={{ width: "220px", height: 'auto' }} />
              </Link>

            </h4>
          </div>
          <div className="col-md-2">
            <h4 className="text-center mb-2">
              {/* <AvatarEditor/> */}
              <img src="/images/usericon.png" alt="users avatar" className="users-avatar-shadow rounded-circle" height={100} width={100} style={{ border: '4px solid #EAEAEA', padding: '2.5px' }} />
              <i className="bx bxs-pencil" id="user-pencil" style={{ borderRadius: '50px', backgroundColor: '#ffffff', border: '1px solid #CCC', padding: '4px', color: '#CCC', position: 'relative', top: '-40px', left: '60px', cursor: 'pointer' }} />
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderPart;