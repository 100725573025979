// Sync const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

// Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const USER_DATA_SET = 'user_data_set';

//Workspace


// Workspace Board

export const ADD_COLUMN = 'add_column';
export const DRAG_DROP = 'drag_drop';
export const CHANGE_ITEM = 'change_item';
export const CHNAGE_GROUP_TITLE = 'change_group_title';
export const CHANGE_GROUP_COLOR = 'change_group_color';
export const CHANGE_GROUP_MEMBERS = 'change_group_members';
export const CHANGE_GROUP_LIST_ROW_TITLE = 'change_group_list_row_title';
export const CHANGE_GROUP_COLUMN_TITLE = 'change_group_column_title';
export const CHANGE_GROUP_COLUMN_MEMBERS = 'change_group_column_members';
export const CHANGE_GROUP_COLUMN_STATUS_PROGRESS = 'change_group_column_status_progress';
export const ADD_ROW = 'add_row';
export const DUPLICATE_ROW = 'duplicate_row';
export const ADD_GROUP = 'add_group';
export const REMOVE_GROUP = 'remove_group';
export const REMOVE_ROW = 'remove_row';
export const REMOVE_COLUMN = 'remove_column';
export const CHANGE_BOARD_TITLE = 'change_board_title';
export const CHANGE_BOARD_DESC = 'change_board_desc';
export const RELOAD_WORKSPACE = 'reload_workspace';

export const CHANGE_WORKSPACE_BOARD = 'change_workspace_board';
export const NAVIGATE_TO_BOARD_FROM_NOTIFICATION = 'navigate_to_board_from_notification';
export const INITIALIZE_BOARD = 'initialize_board';
export const CHANGE_STATUS_LABELS = 'change_status_labels';
export const UPDATE_BORD_GROUP_DATA = 'update_board_group_data';
export const UPDATE_WORKSPACE_BOARD_MEMBERS = 'update_workspace_board_members'
export const CHANGE_BOARD_NUMBER_UNIT = 'change_board_number_unit';
export const CHANGE_BOARD_NUMBER_DIRECTION = 'change_board_number_direction';
export const CHANGE_BOARD_NUMBER_ACTION = 'change_board_number_action';
export const UPDATE_BOARD_TAGS_SUGGESTION = 'update_board_tags_suggestion';
export const UPDATE_BOARD_DROPDOWN_SUGGESTION = 'update_board_dropdown_suggestion';
export const UPDATE_COLUMN_WIDTH = 'update_column_width';
export const SEARCH_FILTER_BOARD_DATA = 'search_filter_board_data';
export const SEND_BOARD_CHAT_MESSAGE = "send_board_chat_message";
export const UPDATE_MESSAGE_LIKES = "update_message_likes";
export const UPDATE_MESSAGE_REMINDER = "update_message_reminder";
export const UPDATE_MESSAGE_SEEN_BY = "update_message_seen_by";
export const UPDATE_NESTED_MESSAGE_LIKES = "update_nested_message_likes";
export const UPDATE_CHATROOM_COLUMNS = "update_chatroom_columns";
export const GET_CHATROOM_BY_ID = "get_chatroom_by_id";
export const UPDATE_UNREAD_MESSAGE_COUNT = "update_unread_message_count";
export const SEND_MESSAGE_REPLY = "send_message_reply"
export const FETCH_POSTS_STARTED = "post_started";
export const FETCH_POSTS_FAILURE = "post_failed";
export const FETCH_POSTS_SUCCESS = "post_success";

// Discover

export const FETCH_WORKSPACE_DATA_REQUEST = 'GET_WORKSPACE_DATA_REQUEST'
export const FETCH_WORKSPACE_DATA_SUCCESS = 'FETCH_WORKSPACE_DATA_SUCCESS'
export const ADD_FETCH_WORKSPACE_DATA_SUCCESS = 'ADD_FETCH_WORKSPACE_DATA_SUCCESS'
export const UPDATE_FETCH_WORKSPACE_DATA_SUCCESS = 'UPDATE_FETCH_WORKSPACE_DATA_SUCCESS'
export const WORKSPACE_DATA_ERROR = 'WORKSPACE_DATA_ERROR'
export const DELETE_WORKSPACE_DATA_SUCCESS = 'DELETE_WORKSPACE_DATA_SUCCESS'

export const FETCH_SUB_WORKSPACE_DATA_REQUEST = 'FETCH_SUB_WORKSPACE_DATA_REQUEST'
export const FETCH_SUB_WORKSPACE_DATA_SUCCESS = 'FETCH_SUB_WORKSPACE_DATA_SUCCESS'
export const ADD_FETCH_SUB_WORKSPACE_DATA_SUCCESS = 'ADD_FETCH_SUB_WORKSPACE_DATA_SUCCESS'
export const UPDATE_FETCH_SUB_WORKSPACE_DATA_SUCCESS = 'UPDATE_FETCH_SUB_WORKSPACE_DATA_SUCCESS'
export const SUB_WORKSPACE_DATA_ERROR = 'SUB_WORKSPACE_DATA_ERROR'
export const DELETE_SUB_WORKSPACE_DATA_SUCCESS = 'DELETE_SUB_WORKSPACE_DATA_SUCCESS'

export const FETCH_BOARD_DATA_REQUEST = 'FETCH_BOARD_DATA_REQUEST'
export const FETCH_BOARD_DATA_SUCCESS = 'FETCH_BOARD_DATA_SUCCESS'
export const ADD_FETCH_BOARD_DATA_SUCCESS = 'ADD_FETCH_BOARD_DATA_SUCCESS'
export const UPDATE_FETCH_BOARD_DATA_SUCCESS = 'UPDATE_FETCH_BOARD_DATA_SUCCESS'
export const BOARD_DATA_ERROR = 'BOARD_DATA_ERROR'

export const FETCH_SUB_BOARD_DATA_REQUEST = 'FETCH_SUB_BOARD_DATA_REQUEST'
export const FETCH_SUB_BOARD_DATA_SUCCESS = 'FETCH_SUB_BOARD_DATA_SUCCESS'
export const ADD_FETCH_SUB_BOARD_DATA_SUCCESS = 'ADD_FETCH_SUB_BOARD_DATA_SUCCESS'
export const UPDATE_FETCH_SUB_BOARD_DATA_SUCCESS = 'UPDATE_FETCH_SUB_BOARD_DATA_SUCCESS'
export const SUB_BOARD_DATA_ERROR = 'SUB_BOARD_DATA_ERROR'

export const FETCH_GROUP_DATA_REQUEST = 'FETCH_GROUP_DATA_REQUEST'
export const FETCH_GROUP_DATA_SUCCESS = 'FETCH_GROUP_DATA_SUCCESS'
export const ADD_FETCH_GROUP_DATA_SUCCESS = 'ADD_FETCH_GROUP_DATA_SUCCESS'
export const UPDATE_FETCH_GROUP_DATA_SUCCESS = 'UPDATE_FETCH_GROUP_DATA_SUCCESS'
export const GROUP_DATA_ERROR = 'GROUP_DATA_ERROR'

export const FETCH_GROUP_ITEM_DATA_REQUEST = 'FETCH_GROUP_ITEM_DATA_REQUEST'
export const FETCH_GROUP_ITEM_DATA_SUCCESS = 'FETCH_GROUP_ITEM_DATA_SUCCESS'
export const ADD_FETCH_GROUP_ITEM_DATA_SUCCESS = 'ADD_FETCH_GROUP_ITEM_DATA_SUCCESS'
export const UPDATE_FETCH_GROUP_ITEM_DATA_SUCCESS = 'UPDATE_FETCH_GROUP_ITEM_DATA_SUCCESS'
export const GROUP_ITEM_DATA_ERROR = 'GROUP_ITEM_DATA_ERROR'

export const FETCH_SUB_WORKSPACE_L2_DATA_REQUEST = 'FETCH_SUB_WORKSPACE_L2_DATA_REQUEST'
export const FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS = 'FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS'
export const ADD_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS = 'ADD_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS'
export const UPDATE_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS = 'UPDATE_FETCH_SUB_WORKSPACE_L2_DATA_SUCCESS'
export const DELETE_SUB_WORKSPACE_L2_DATA_SUCCESS = 'DELETE_SUB_WORKSPACE_L2_DATA_SUCCESS'
export const SUB_WORKSPACE_L2_DATA_ERROR = 'SUB_WORKSPACE_L2_DATA_ERROR'

export const FETCH_GLOBAL_SEARCh_HOT_TAGS_PENDING = 'FETCH_GLOBAL_SEARCh_HOT_TAGS_PENDING';
export const FETCH_GLOBAL_SEARCh_HOT_TAGS_SUCCESS = 'FETCH_GLOBAL_SEARCh_HOT_TAGS_SUCCESS';
export const FETCH_GLOBAL_SEARCh_HOT_TAGS_ERROR = 'FETCH_GLOBAL_SEARCh_HOT_TAGS_ERROR';

