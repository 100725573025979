import React from 'react';
import { connect } from 'react-redux';
import FilterDropdownComponent from './FilterDropdownComponent';

class FilterDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }

        this.dropdownContainer = React.createRef();
    }

    onClickOutsideHandler = (ev) => {
        // console.error("ASDFASDF", ev.target.name)
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            // console.error("_____________asdfasdf ______________");
            if (ev.target.name !== "removableItem") this.setState({ open: false });
        }
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    render() {
        return (
            <div style={{ cursor: 'pointer', fontFamily: 'myriad-pro,sans-serif !important', fontWeight: '300' }} ref={this.dropdownContainer}>
                <div className="dropdown" id="sort-dropdown">
                    <button className="btn dropdown-toggle" type="button" onClick={() => this.setState({ open: !this.state.open })}>
                        <i className="fal fa-filter" ></i>&nbsp;&nbsp;<span style={{ verticalAlign:' middle' }}>Filter</span>
                    </button>
                    <div class="filter-dropdown-en" style={{ position: 'absolute', minWidth: '800px', 'top': '4px', 'right': '-400px' }}>
                        {/* {this.state.open ? (
                            <ul className={`dropdown-menu multi-level animate slideIn ${this.state.open ? 'show' : ''}`} role="menu" aria-labelledby="dropdownMenu" style={{ width: '350px !important' }}>
                                <FilterDropdownComponent />
                            </ul>
                        ) : ''} */}

                        <ul className={`dropdown-menu multi-level animate slideIn fltr-drp ${this.state.open && this.props.boardData.boardID !== '' ? 'show' : ''}`} role="menu" aria-labelledby="dropdownMenu" style={{ width: '350px !important' , 'top' : '0 !important' , 'left' : '0 !important'}}>
                            <FilterDropdownComponent groups={this.props.groups} columns={this.props.columns} />
                        </ul>
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

export default connect(mapStateToProps)(FilterDropdown);