import React ,{ useState } from 'react'
import DiscoverAddEditPopup from './discover-addedit.model';
const DiscoverAddCard = (props) => {
    const [showModal, setShowModal] = useState(false);
    const tooltips='Add ' +props.discoverName;
    const params = {
               _id:0,
               showModal:showModal,               
            }
    return(
        <>
        <div>
        <a className="row text-center d-flex align-items-center justify-content-center mr-3 shadow cursor-pointer" style={{
                    borderRadius: '17px', width: '120px', height: '120px', marginLeft: '16px', backgroundColor: props.discoverType === 1 ? '#e8378f' : props.discoverType === 2 ? '#7a66df' : props.discoverType === 3 ? '#04b1c3' : props.discoverType === 7 ? '#F2668B' : '#e8378f'}}
         title={tooltips} onClick={() => setShowModal(true) }>
          <h3 className="title" style={{ fontSize: '3.5rem!important', color: '#ffffff', fontWeight: 100 }}>+</h3>
        </a>
       </div>

        <DiscoverAddEditPopup {...props} {...params} onHide={() => setShowModal(false)}></DiscoverAddEditPopup>

        </>
    );
}
export default DiscoverAddCard;
