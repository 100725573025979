import React, { Component, useState, useEffect, useRef } from 'react';
import '@progress/kendo-theme-default/dist/all.css';
// import config from '../services/config.json';
// import http from '../services/http-service';
import axios from '../../util/Api';
import schedulerDataService from "../../data-services/scheduler-data-services";
import exportPDF from "./scheduler-generatepdf";
import exportExcel from "./scheduler-generateexcel";
import FileSaver from 'file-saver';
import {
  Scheduler,
  AgendaView,
  TimelineView,
  DayView,
  WeekView,
  MonthView,
  SchedulerItem,
} from '@progress/kendo-react-scheduler';
import ReminderList from "./reminder-list"
import SchedulerToolBar from "./scheduler-toolbar"
import moment from 'moment';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';
import { getRandomColor } from '../../util/People';

const EngageScheduler = () => {

  const [displayDate, setDisplayDate] = useState(new Date());
  const [value, setValue] = useState();
  const [serverData, setServerData] = useState([]);
  const [eventsCategories, setEventsCategories] = useState([]);
  const [categoriesCount, setCategoriesCount] = useState([]);

  const [search, setSearch] = useState('');
  const [exportType, setExportType] = useState('');

  let child = useRef('');
  useEffect(() => {
    getServerData();
    getEventCategories();
    getCategoryWiseCount();
    setValue(new Date())
  }, []);

  const getServerData = async () => {
    const params = {
      id: 1,
      userId: 10,
      search: search
    }

    await axios.post(schedulerDataService.getEvents, params)
      .then((response) => {

        setServerData(response.data.events);
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getEventCategories = async () => {
    await axios.get(schedulerDataService.getEventCategories)
      .then((response) => {
        setEventsCategories(response.data.response);
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCategoryWiseCount = async () => {
    const params = {
      userId: 10,
    }

    await axios.post(schedulerDataService.getCategoryWiseCount, params)
      .then((response) => {
        setCategoriesCount(response.data.response);

      })
      .catch((error) => {
        console.log(error)
      })
  }
  const handleDataChange = React.useCallback(

    ({ updated }) => {

      const params = {
        id: updated[0].id,
        type: updated[0].type,
        start: new Date(updated[0].start),
        end: new Date(updated[0].end),
        recurrenceExceptions: updated[0].recurrenceExceptions
      }
      axios.put(schedulerDataService.updateEvent, params)
        .then((response) => {

          getServerData();

        })
        .catch((error) => {
          console.log(error);
        });


    }


  )

  const resources = [{
    name: 'Category',
    data: eventsCategories,
    field: 'categoryId',
    valueField: '_id',
    textField: 'name',
    colorField: getRandomColor()
  }]
  const serverDataModel = serverData.map(dataItem => (
    {
      id: dataItem._id,
      start: new Date(dataItem.startDate),
      startTimezone: null,
      end: new Date(dataItem.endDate),
      endTimezone: null,
      isAllDay: dataItem.isAllDay,
      title: dataItem.title,
      description: dataItem.description,
      recurrenceRule: dataItem.recurrenceRule,
      recurrenceId: dataItem.recurrenceId == "" ? null : dataItem.recurrenceId,
      //recurrenceExceptions:dataItem.recurrenceExceptions.length == 0 ? null : dataItem.recurrenceExceptions,
      categoryId: dataItem.categoryId,
      pseudoColor: dataItem.categoryId === 1 ? '#5527c4' : dataItem.categoryId === 2 ? '#abce43' : '#d83022',
      type: dataItem.type
      //image: 'https://d585tldpucybw.cloudfront.net/sfimages/default-source/default-album/release_webinar_kui_1920x440_lp.png?sfvrsn=23989f80_0'

    }


  ));
  const onSearchClick = (e) => {
    e.preventDefault();
    getServerData();
  }
  const onSearchChange = (e) => {
    setSearch(e.target.value)

  }
  const refreshOnClick = (e) => { e.preventDefault(); getServerData(); }

  const exportOnClick = async (e) => {
    const startOfMonth = moment(displayDate).startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment(displayDate).endOf('month').format('YYYY-MM-DD');
    const params = {
      startDate: startOfMonth,
      endDate: endOfMonth,
      eType: e
    }
    await axios.post(schedulerDataService.getDownloadData, params)
      .then((response) => {

        if (e === 'exporttoexcel') {
          exportExcel(response.data.downloadList);
        }
        else {
          exportPDF(response.data.downloadList);
        }

      })
      .catch((error) => {
        console.log(error)
      })


  }
  const filterOnClick = (e) => {

    console.log(e.workspace ? e.workspace : 0);
    console.log(e.subworkspace ? e.subworkspace : 0);
    console.log(e.board ? e.board : 0);
    console.log(e.subboard ? e.subboard : 0);

  }



  const handelViewChange = (e) => { console.log(e) }

  const handleDateChange = (e) => {
    if (e.isTrusted) {
      setDisplayDate(e.value)
    }
    else {
      setDisplayDate(e.value._localDate)
    }

  }
  const CustomItem = (props) => (
    <SchedulerItem
      {...props}
      style={{ ...props.style, '--schedule-category-color': props.dataItem.pseudoColor }}
    //background: `url(${props.dataItem.image})`
    />)

  return (
    <React.Fragment>
      <MainMenu link={`scheduler`} />
      <div id="whole-layout">
        <NavBar />
        <div id="appdata" className='app-content content fixed-top content-lay-out'>
          <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
          <div className="content-wrapper">
            <div id="mySidenav" className="sidenav">
              <a href="#" className="closebtn" onClick={() => {
                document.getElementById("mySidenav").style.width = "0%";
              }}>&times;</a>
            </div>
            <div>
              <SchedulerToolBar
                onSearchClick={onSearchClick}
                onSearchChange={onSearchChange}
                searchText={search}
                onRefreshClick={refreshOnClick}
                categoriesCount={categoriesCount}
                onExportClick={exportOnClick}
                onFilterClick={filterOnClick}
              />
              <div className="row match-height">

                <div className="col-md-9">
                  <div>
                    <div className="scheduler-top-head" style={{ textAlign: 'right' }}>
                      <ul className="calender-right-head" style={{ listStyleType: 'none', display: 'inlineFlex', fontSize: '11.5px', color: '#999999', fontWeight: '100' }}>
                        {eventsCategories.map((e, index) => (
                          <li style={{ display: 'flex', marginLeft: '20px' }} key={index}>
                            <div style={{ width: '8px', height: '8px', borderRadius: '50px', backgroundColor: e.color, marginTop: '4px' }}>
                              &nbsp; </div>&nbsp;{e.name} </li>
                        ))}

                      </ul>
                    </div>
                  </div>

                  <Scheduler
                    data={serverDataModel}

                    defaultDate={displayDate}
                    editable={{
                      add: false,
                      remove: false,
                      drag: true,
                      resize: true,
                      edit: false
                    }}

                    resources={resources}
                    onDataChange={handleDataChange}
                    onDateChange={handleDateChange}
                    onViewChange={handelViewChange}
                    item={CustomItem}
                    defaultView="week"
                    footer={false}             >
                    <DayView />
                    <WeekView />
                    <MonthView />
                    <AgendaView />
                    <TimelineView />
                  </Scheduler>
                </div>


                <div className="col-md-3">
                  <ReminderList userId={1} />
                </div>


              </div>

            </div>

          </div>
        </div>
      </div>

    </React.Fragment>


  );
}
export default EngageScheduler;
