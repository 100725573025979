import React, { Component } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import "react-tabs/style/react-tabs.css";
import Checkbox from "react-checkbox-component";
import http from "../services/http-service";
import * as savedSearch from "./saved-search/constant";
import * as recentSearch from "./recent-search/constant";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import "./src/css/high-light.css";
import "./card.css";
import "./src/css/style.css";
import * as User from "../users/constant";

const suggestions = [];

function renderInput(inputProps) {
  const { classes, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: ref,
        classes: {
          input: classes.input,
        },
        ...other,
      }}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong
              key={String(index)}
              style={{ fontWeight: "bold", color: "purple", fontSize: "18" }}
            >
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 &&
          suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: "relative",
    height: 250,
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
});

class PeopleSearch extends Component {
  state = {
    value: "",
    suggestions: [],
    isSavedSearchChecked: false,
    isHorezintalDatePickerShow: false,
    selectedDate: "",
    tabIndex: 0,
    inputHighLightKeyWords: [],
    content: "New Group",
    groups: [],
    groupItemsData: [],
    error: false,
    userData: [],
    hintData: [],
    text: "",
    allPeopleData: [],
  };

  componentDidMount() {
    this._initPeopleSearch();
    try {
      if (
        this.props.quickSearchSavedObj.quickSearchSavedType &&
        this.props.quickSearchSavedObj.quickSearchSavedType === "People" &&
        this.props.quickSearchSavedObj.searchNameDataSavedSearch
      ) {
        this.searchResult(
          this.props.quickSearchSavedObj.searchNameDataSavedSearch
        );
      }
    } catch (ex) {}
  }

  _initPeopleSearch = async () => {
    await http.get(User.getAllUsers).then((userData) => {
      this.setState({
        allPeopleData: userData.data,
      });
      console.log(this.state.allPeopleData, ">>>>>all the data");
      this.state.allPeopleData.map((user) => {
        suggestions.push({ label: user.firstname });
        suggestions.push({ label: user.email });
        suggestions.push({ label: user.lastname });
        suggestions.push({ label: user.mobileno });
        suggestions.push({ label: user.position });
      });
    });
  };
  // this.state.value
  searchResult = async (searchValue) => {
    //serach by first name
    const firstNameData = {
      firstName: searchValue,
    };
    await http
      .post(User.getUserByFirstName, firstNameData)
      .then((userByName) => {
        this.setState({
          userData: userByName.data.user,
        });
        localStorage.setItem("totalPeopleRecords", 0);
        localStorage.setItem("totalPeopleRecords", this.state.userData.length);
      });

    this.saveRecentSavedSearch();
    if (this.state.userData.length !== 0) return;
    //search by last name
    const lastNameData = {
      lastName: searchValue,
    };
    await http
      .post(User.getUserByLastName, lastNameData)
      .then((userByLastName) => {
        this.setState({
          userData: userByLastName.data.user,
        });
        localStorage.setItem("totalPeopleRecords", 0);
        localStorage.setItem("totalPeopleRecords", this.state.userData.length);
      });
    this.saveRecentSavedSearch();
    if (this.state.userData.length !== 0) return;

    // //search byemail
    const emailData = {
      userEmail: searchValue,
    };
    await http.post(User.getUserByEmail, emailData).then((userByName) => {
      this.setState({
        userData: userByName.data.user,
      });
      localStorage.setItem("totalPeopleRecords", 0);
      localStorage.setItem("totalPeopleRecords", this.state.userData.length);
    });
    this.saveRecentSavedSearch();
    if (this.state.userData.length !== 0) return;

    // //search by mobile no
    const mobileData = {
      mobileNo: searchValue,
    };
    await http.post(User.getUserByMobileNo, mobileData).then((userByName) => {
      this.setState({
        userData: userByName.data.user,
      });
      localStorage.setItem("totalPeopleRecords", 0);
      localStorage.setItem("totalPeopleRecords", this.state.userData.length);
    });
    this.saveRecentSavedSearch();
    if (this.state.userData.length !== 0) return;
    //search by position
    const positionData = {
      userPosition: searchValue,
    };
    await http.post(User.getUserByPosition, positionData).then((userByName) => {
      this.setState({
        userData: userByName.data.user,
      });
      localStorage.setItem("totalPeopleRecords", 0);
      localStorage.setItem("totalPeopleRecords", this.state.userData.length);
    });
    this.saveRecentSavedSearch();
    if (this.state.userData.length !== 0) return;
  };

  saveRecentSavedSearch = async () => {
    const savedsearchData = {
      searchName: this.state.value,
      searchUser: "5fe43e2d91056e9f478549c7",
    };
    if (this.state.isSavedSearchChecked === true) {
      http
        .post(savedSearch.SAVE_SAVED_SEARCH, savedsearchData)
        .then((result) => {
          console.log(result, "saved search is saved successfully");
        });
    }
    const recentSearchData = {
      searchName: this.state.value,
      searchUser: "5fe43e2d91056e9f478549c7",
    };
    http
      .post(recentSearch.SAVE_RECENT_SEARCH, recentSearchData)
      .then((result) => {
        console.log("saved search is saved successfully", result);
      });
  };

  onSavedSerachChange = () => {
    this.setState({ isSavedSearchChecked: !this.state.isSavedSearchChecked });
  };

  filterByDate = () => {
    this.setState({
      isHorezintalDatePickerShow: !this.state.isHorezintalDatePickerShow,
    });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  render() {
    const { classes } = this.props;
    const { userData } = this.state;

    return (
      <>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderInputComponent={renderInput}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestionsContainer}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            classes,
            placeholder: "Type Your Text Search Here ...",
            value: this.state.value,
            onChange: this.handleChange,
          }}
        />

        <button
          className="btn btn-primary col-md-2"
          style={{ marginRight: "5px", height: "50px" }}
          onClick={() => this.searchResult(this.state.value)}
        >
          Search
        </button>
        <div style={{ marginTop: "10px" }}>
          <Checkbox
            size="small"
            isChecked={this.state.isSavedSearchChecked}
            onChange={this.onSavedSerachChange}
            color="#fc14bb"
          />
          <label style={{ marginTop: "3px" }}>Save search</label>
        </div>

        <div
          className="col-md-12"
          style={{ marginTop: "100px", clear: "both" }}
        ></div>

        <div className="row">
          <div
            className="col-md-12"
            style={{ height: "500px", margin: "20px", overflow: "scroll" }}
          >
            <div className="col-md-4">
              {userData.map((person, index) => (
                <div className="card_search" key={index}>
                  <img
                    src={require("./src/imgs/avatar.png")}
                    alt="John"
                    style={{ width: "100%", height: "150px" }}
                  />
                  <h1>{person.firstname}</h1>
                  <p className="title_search">{person.position}</p>
                  <div style={{ margin: "24px 0" }}>
                    <a className="a__search" href="#">
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </div>
                  <p>
                    <button className="button_search">{person.mobileno}</button>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

PeopleSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PeopleSearch);
