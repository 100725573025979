import React, { useEffect, useState } from 'react'
import { Bar } from '@reactchartjs/react-chart.js'
import Moment from 'moment'; 
// https://github.com/reactchartjs/react-chartjs-2/blob/react16/example/src/charts/Crazy.js
const rand = () => Math.floor(Math.random() * 255)

const genData = () => ({
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
  datasets: [
    {
      type: 'line',
      label: 'Dataset 1',
      borderColor: `rgb(${rand()}, ${rand()}, ${rand()})`,
      borderWidth: 2,
      fill: false,
      data: [500, 800, 500, 300, 300, 500],
    },
    {
      type: 'bar',
      label: 'Dataset 2',
      backgroundColor: `rgb(${rand()}, ${rand()}, ${rand()})`,
      data: [2000, 7500, 5000, 1000, 2000, 7000, 9000],
      borderColor: 'white',
      borderWidth: 2,
    },
    {
      type: 'bar',
      label: 'Dataset 3',
      backgroundColor: `rgb(${rand()}, ${rand()}, ${rand()})`,
      data: [50000, 20000, 30000, 60000, 40000, 30000, 70000],
    },
  ],
})

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

// const [data, setData] = useState([])
// useEffect(() => {
//   this.state.datasets.data = []
//   return () => {
    
//   }
// }, [])

const LineBarCrazy = () => {
  const [data, setData] = useState(genData())

  useEffect(() => {
    const interval = setInterval(() => setData(genData()), 5000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <Bar data={data} options={options} />
    </>
  )
}

export default LineBarCrazy

