import React, { Component } from 'react'
import { connect } from 'react-redux';
import http from '../../services/http-service';
import * as workspaceApi from '../../global-search/constants'
import moment from 'moment'
import { Link } from "react-router-dom";
import {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs,
    onClickTag, onSavedSearchDelete, getHotTags
} from '../../../actions/GlobalSearch'
class HotTags extends Component {
    constructor(props) {
        super(props)
        this.onTagClick = this.onTagClick.bind(this)
        this.state = {
            data: ''
        }
    }
    onTagClick = (i, value) => {

        http.get(workspaceApi.GET_WORKSPACE_BY_BOARDID + "?id=" + value)
            .then((result) => {
                //window.location = "/board/" + result.data.workSpaceBoard[0]._id
            }).catch(error => {
            })
    }
    componentDidMount() {
        this.setState({
            data: this.props.item
        })
    }
    componentWillReceiveProps(nextProps) {
        this.setState({
            data: nextProps.item
        })
    }
    render() {
        console.log("this.state.datathis.state.data", this.state.data)
        return <div>

            <div className="card" style={{ textAlign: 'left' }}>
                <div className="card-body"
                    //onClick={() => this.onTagClick(1, this.state.data.chatRoom)} 
                    dangerouslySetInnerHTML={{ __html: this.state.data.body }}
                >
                </div>
                <Link className="btn btn-danger text-white mr-2"
                    //to={'/board/'+data.boardId} 
                    to={{
                        pathname: '/board/' + this.state.data.boardId,
                        state: {
                            groupIndex: ""+this.state.data.groupIndex,
                            rowIndex: ""+this.state.data.rowIndex,
                            messageID: this.state.data._id,
                            isChatNotification: true
                        }
                    }}
                >
                    Go To Message
                </Link>
                <span>{moment(this.state.data.createdAt).fromNow()}</span> <span>{this.state.data.groupTitle}</span><span><b> {this.state.data.boardTitle}</b></span>
            </div>
        </div>
    }
}

const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs, onClickTag,
    onSavedSearchDelete, getHotTags
};

export default connect(mapStateToProps, mapDispatchToProps)(HotTags);