import React from 'react';
import AddColumn from './AddColumn';
import BoardGroup from './BoardGroup';
import 'react-toastify/dist/ReactToastify.css';

class BoardTable extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                <div className='layout__wrapper' style={{ overflowX: 'auto', 'height': 'calc(100vh - 271px)' }}>
                    <div className="board-wrapper">
                    </div>
                    <BoardGroup />
                </div>
            </div>
        )
    }
}

export default BoardTable;