import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userSignOut } from '../../../actions';
import * as UserConstant from '../constant/users-contant';
import * as UserService from '../auth-services/user-service.jsx';

class Logout extends Component {

    componentDidMount() {
        // UserService.removeToken(UserConstant.TOKEN_NAME)
        console.log("test")
        if (this.props.auth.token) {
            this.props.userSignOut()
        } else {
            this.props.history.push('/login')
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.token === null) {
            nextProps.history.push('/login')
        }
    }
    render() {
        return (
            null
        );
    }
}

const mapStateToProps = ({ auth }) => {
    return { auth }
}

const mapDispatchToProps = { userSignOut }

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
