import React, { Component } from 'react'
import { connect } from 'react-redux';
import { format } from 'date-fns';
import {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs,
    onClickTag, onSavedSearchDelete, getHotTags, onBoardDataChange
} from '../../../actions/GlobalSearch'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import SearchDroppableList from './dropable-list';
import { dragDropChangedGroup, dragDropChangedGroupItem, dragDropChangedGroupColumnOrder } from '../../../actions';

const Mousetrap = require("mousetrap");
class SearchBoardData extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            groups: {},
            targetAreaIndex: null,
            isWorkspaceOwner: false,
            isWorkspaceAdmin: false,
            boardMembers: [],
            workspaceAdmins: [],
            workspaceOwner: '',
            editRowRestrictedMembers: [],
            boardID: ''
        }
        this.authUser = JSON.parse(localStorage.getItem("authUser"));
    }
    getSearchBoardGroupData = (boardDatas) => {

        let newBoardGroups = [];
        let boardData = boardDatas;
        console.log("boardData===>", boardDatas)
        let text1 = this.props.searchTerm
        let firstLtr = text1.charAt(0)
        if (firstLtr == "#") {
            text1 = text1.substring(1)
        }
        let text = text1 //this.props.searchTerm

        for (let i = 0; i < boardData.length; i++) {
            let grouptitle = boardData[i].label.toLowerCase().search(text.toLowerCase());
            if (grouptitle !== -1) {
                let newGroupItems = [];
                let groupItems = boardData[i].groupItems;
                let flag = 0;
                for (let j = 0; j < groupItems.length; j++) {
                    let cellItems = groupItems[j].cellItems;
                    if (groupItems[j].category === 'header') {

                        newGroupItems.push(groupItems[j]);
                    } else {


                        for (let k = 0; k < cellItems.length; k++) {
                            if (firstLtr == "#") {
                            if (cellItems[k].key === 'tags') {
                                let labels = JSON.parse(cellItems[k].body);
                                for (let h = 0; h < labels.length; h++) {
                                    let n = labels[h].name.toLowerCase().search(text.toLowerCase());
                                    if (n !== -1) {
                                        flag++
                                        newGroupItems.push(groupItems[j]);
                                    }
                                }

                            }
                        }

                        }
                        // if (flag) {


                        // }
                    }

                }

                if (firstLtr == "#" && flag > 0) {
                    console.log("groupItems[j]", newGroupItems)
                    let newGroup = boardData[i];
                    newGroup.groupItems = newGroupItems;
                    newBoardGroups.push(newGroup);
                } else
                    if (firstLtr != "#" && flag == 0) {
                        newBoardGroups.push(boardData[i]);
                    }

            } else {
                let newGroupItems = [];
                let groupItems = boardData[i].groupItems;
                for (let j = 0; j < groupItems.length; j++) {
                    if (groupItems[j].category === 'header') {

                        newGroupItems.push(groupItems[j]);
                    } else {
                        let groupItemIitle = groupItems[j].title.toLowerCase().search(text.toLowerCase());
                        if (groupItemIitle !== -1) {
                            let flag = 0;
                            //for (let j = 0; j < groupItems.length; j++) {
                            let cellItems = groupItems[j].cellItems;

                            for (let k = 0; k < cellItems.length; k++) {
                                if (cellItems[k].key === 'tags') {
                                    let labels = JSON.parse(cellItems[k].body);
                                    for (let h = 0; h < labels.length; h++) {
                                        let n = labels[h].name.toLowerCase().search(text.toLowerCase());
                                        if (n !== -1) {
                                            flag++;
                                        }
                                    }

                                }
                            }

                            //}
                            //    console.log("firstLtr",firstLtr)
                            if (firstLtr == "#" && flag > 0) {
                                newGroupItems.push(groupItems[j]);
                            } else if (firstLtr != "#" && flag == 0) {
                                newGroupItems.push(groupItems[j]);
                            }

                        } else {
                            let cellItems = groupItems[j].cellItems;
                            let flag = false;
                            for (let k = 0; k < cellItems.length; k++) {

                                // let firstLtr=text.charAt(0)
                                // console.log("firstLtr",firstLtr)
                                if (firstLtr == "#") {
                                    if (cellItems[k].key === 'tags') {
                                        let labels = JSON.parse(cellItems[k].body);
                                        for (let h = 0; h < labels.length; h++) {
                                            let n = labels[h].name.toLowerCase().search(text.toLowerCase());
                                            if (n !== -1) {
                                                flag = true;
                                            }
                                        }

                                    }
                                } else {


                                    if (cellItems[k].key === 'tags' || cellItems[k].key === 'dropdown') {
                                        let labels = JSON.parse(cellItems[k].body);
                                        for (let h = 0; h < labels.length; h++) {
                                            let n = labels[h].name.toLowerCase().search(text.toLowerCase());
                                            if (n !== -1) {
                                                flag = true;
                                            }
                                        }

                                    } else if (cellItems[k].key === 'people') {
                                        let labels = JSON.parse(cellItems[k].body);
                                        console.log("labels", labels)
                                        for (let h = 0; h < labels.length; h++) {
                                            console.log("labels[h].name:", labels[h].name)
                                            let n = labels[h].name.toLowerCase().search(text.toLowerCase());
                                            console.log("n:", n)
                                            if (n !== -1) {
                                                flag = true;
                                                break;
                                            }
                                        }
                                    } else if (cellItems[k].key === 'numbers') {
                                        if (cellItems[k].body !== "" && parseInt(cellItems[k].body) === parseInt(text)) {
                                            flag = true;
                                        }
                                    } else if (cellItems[k].key === 'date') {
                                        if (cellItems[k].body !== "") {
                                            let body = format(new Date(cellItems[k].body), 'MMM d, y').toLowerCase().search(text.toLowerCase());
                                            if (body !== -1) {
                                                flag = true;
                                            }
                                        }
                                    } else if (cellItems[k].key === 'timeline') {
                                        if (cellItems[k].body !== '') {
                                            let timelineArr = cellItems[k].body.split("-");
                                            let startDate = timelineArr[0];
                                            let endDate = timelineArr[1];
                                            let timeline = format(new Date(startDate), 'MMM dd') + ' - ' + format(new Date(endDate), 'MMM dd');
                                            let body = timeline.toLowerCase().search(text.toLowerCase());
                                            if (body !== -1) {
                                                flag = true;
                                            }
                                        }
                                    } else if (cellItems[k].key === 'file' || cellItems[k].key === 'autoNumber' || cellItems[k].key === 'lastUpdated') {
                                        flag = false
                                    } else {

                                        let body = cellItems[k].body.toString().toLowerCase().search(text.toLowerCase());

                                        if (body !== -1) {
                                            flag = true;
                                        }
                                    }
                                }
                                if (flag) {
                                    break;
                                }
                            }

                            if (flag) {
                                newGroupItems.push(groupItems[j]);
                            }
                        }
                    }
                }

                if (newGroupItems.length > 1) {
                    let newGroup = boardData[i];
                    newGroup.groupItems = newGroupItems;
                    newBoardGroups.push(newGroup);
                }
            }
        }
        console.log("newBoardGroups===>", newBoardGroups)
        return newBoardGroups;
    }
    componentDidMount() {

        this.buildAndSave(this.getSearchBoardGroupData(JSON.parse(this.props.item.group)));
    }
    componentWillReceiveProps(nextProps) {
        this.buildAndSave(this.getSearchBoardGroupData(JSON.parse(nextProps.item.group)));
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.item.group !== this.props.item.group) {

        }
    }

    buildAndSave = (items) => {
        const groups = {};
        for (let i = 0; i < Object.keys(items).length; ++i) {
            const currentGroup = items[i];
            groups[currentGroup._id] = i;
        }
        this.setState({ items: items });
        this.setState({ groups: groups });

    }

    render() {
        const { items, groups } = this.state;
        console.log("this.statethis.props.item", this.props.item)

        return (
            <DragDropContext >
                <Droppable droppableId='ROOT' type='group'>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >

                            {items.length > 0 ? items
                                .filter(items => items.members ? !items.members.includes(this.authUser._id) : true)
                                .map((item, index) => (
                                    <Draggable
                                        key={item._id + this.props.index}
                                        draggableId={item._id}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                {...provided.draggableProps}
                                                // {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >

                                                <SearchDroppableList
                                                    mainBoardId={this.props.boardId}
                                                    boardID={item._id}
                                                    key={item._id + this.props.index}
                                                    groupInd={index}
                                                    targetAreaIndex={this.state.targetAreaIndex}
                                                    {...item}
                                                    groupDragProvided={provided}
                                                    index={item._id + this.props.index}
                                                    columnHeader={this.props.columnHeader}
                                                    searchItem={this.props.search.search}
                                                    title={this.props.item.title}
                                                />

                                            </div>
                                        )}
                                    </Draggable>
                                )) : ''}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }
}
const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs, onClickTag, onBoardDataChange,
    onSavedSearchDelete, getHotTags, dragDropChangedGroup, dragDropChangedGroupItem,
    dragDropChangedGroupColumnOrder
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBoardData);