import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { saveWorkSpace, createSubWorkSpace, renameSubWorkSpace, createBoard, renameBoard, createGroup, renameGroup, createGroupItem, renameGroupItem, createSubWorkSpaceL2, renameSubWorkSpaceL2, removeSubWorkSpace, removeSubWorkSpaceL2 } from "../../actions";
import Modal from "@material-ui/core/Modal";
const DiscoverAddEditPopup = (props) => {
   const [state, setState] = useState([]);
   const dispatch = useDispatch();
   const caption = (props.actionType === 1 ? 'Rename ' : props.actionType === 2 ? 'Delete ' : 'Add ') + props.discoverName;
   let authUser = JSON.parse(localStorage.getItem('authUser'))
   var data = useSelector(state => state.discover);

   let discoverList = [{}];
   useEffect(() => {
      console.log('nith',props);
      getDiscoverList();
   }, [props])
   const findObject = (obj, key, value) => obj.find(v => v[key] === value)
   const getDiscoverList = async () => {
      let discover;

      if (props._id === 0) {
         await setState({});
      }
      else {
         if (props.discoverType === 1)
            discover = await findObject(data.discoverWorkspace.data, '_id', props._id)
         else if (props.discoverType === 2)
            discover = await findObject(data.discoverSubWorkspace.data, '_id', props._id)
         else if (props.discoverType === 3)
            discover = await findObject(data.discoverBoard.data, '_id', props._id)
         // else if (props.discoverType === 6)
         //   discover = findObject(data.discoverSubBoard.data, '_id', props._id)
         else if (props.discoverType === 4)
            discover = await findObject(data.discoverGroup.data, '_id', props._id)
         else if (props.discoverType === 5)
            discover = await findObject(data.discoverGroupItem.data, '_id', props._id)
         else if (props.discoverType === 7)
            discover = await findObject(data.discoverSubWorkspaceL2.data, '_id', props._id)


         state.title = discover.title ? discover.title : discover.label;
         state.description = discover.description;

      }

   }

   function handleChange(e) {
      const value = e.target.value;
      setState({
         ...state,
         [e.target.name]: value
      });
   }

   const handleOnSave = e => {
      // workspace : 1 , sub work space : 2 , board : 3 , group : 4 , groupitem : 5 , sub work space L2 : 7
      e.preventDefault();
      let data = {};
      if (props.discoverType === 1) // workspace
      {
         data = {
            _id: props._id,
            title: state.title,
            description: state.description,
            actionType: props.actionType
         };
         saveWorkspaceData(data);
      }
      else if (props.discoverType === 2) // sub workspace
      {
         data = {
            _id: props._id,
            title: state.title,
            description: state.description,
            workspace_id: props.parentId,
            actionType: props.actionType
         };
         saveSubWorkspaceData(data);
      }
      else if (props.discoverType === 3) // board
      {
        
         data = {
            _id: props._id,
            title: state.title,
            description: state.description,
            parentId: props.parentId,
            parentType: props.parentType,
            CreatedBy: props.location.state.params.CreatedBy,
            admins: props.location.state.params.admins
         };
         
          saveBoardData(data);
      }

      else if (props.discoverType === 4) // group
      {

         data = {
            _id: props._id,
            title: state.title,
            description: state.description,
            parentId: props.parentId,
            parentType: props.parentType
         };
         saveGroupData(data);
      }
      else if (props.discoverType === 5) // group Item
      {
         data = {
            _id: props._id,
            title: state.title,
            description: state.description,
            parentId: props.parentId,
            parentType: props.parentType
         };
         saveGroupItemData(data);
      }
      else if (props.discoverType === 7) // sub workspace l2
      {
         data = {
            _id: props._id,
            title: state.title,
            description: state.description,
            subworkspaceId: props.parentId
         };
         saveSubWorkspaceL2Data(data);
      }

   };
   const saveWorkspaceData = async (data) => {
      await dispatch(saveWorkSpace(data));
      props.onHide();

   }

   const saveSubWorkspaceData = async (data) => {

      if (props.actionType === 0) {
         await dispatch(createSubWorkSpace(data));
      }
      else if (props.actionType === 1) {
         await dispatch(renameSubWorkSpace(data));
      }
      else {
         await dispatch(removeSubWorkSpace(data));
      }

      props.onHide();

   }
   const saveBoardData = async (data) => {
      console.log("brd-->",data);
      if (props._id === 0) {
         await dispatch(createBoard(data));
      }
      else {
         await dispatch(renameBoard(data));
      }

      props.onHide();

   }
   const saveGroupData = async (data) => {
      if (props._id === 0) {
         await dispatch(createGroup(data));
      }
      else {
         await dispatch(renameGroup(data));
      }
      props.onHide();

   }
   const saveGroupItemData = async (data) => {

      if (props._id === 0) {
         await dispatch(createGroupItem(data));
      }
      else {
         await dispatch(renameGroupItem(data));
      }
      props.onHide();

   }
   const saveSubWorkspaceL2Data = async (data) => {
    
      if (props.actionType === 0) {
         await dispatch(createSubWorkSpaceL2(data));
      }
      else if (props.actionType === 1) {
         await dispatch(renameSubWorkSpaceL2(data));
      }
      else {
         await dispatch(removeSubWorkSpaceL2(data));
      }
      props.onHide();

   }
   return (
      <>
         <div >

            <Modal open={props.showModal} onClose={props.onHide}>
               <div className="modal-dialog modal-dialog-scrollable" style={{ maxWidth: '750px!important' }} role="document">
                  <div className="modal-content" style={{ width: '70%', position: 'relative', top: '100px', margin: '0 auto' }}>
                     <div className="modal-header modal-bg">
                        <h3 className="modal-title  text-white" id="myModalLabel1" style={{ fontSize: '13.5px' }}>{caption}</h3>
                        <div style={{ marginRight: '-15px' }}>
                           <button type="button" className="close-pink" onClick={props.onHide}>
                              <div style={{ marginTop: '-4px', marginLeft: '-15px' }}><span className="cross">×</span></div>
                           </button>
                        </div>
                     </div>
                     <div className="modal-body">

                        <form name="masterForm" noValidate onSubmit={handleOnSave} >

                           { props.actionType !== 2 &&
                           <div className="form-group row">
                              <label className="col-lg-3 form-control-label" style={{ textTransform: 'none', fontSize: '13px' }}> Name:<span className="required" style={{ color: 'red' }}> *</span> </label>
                              <div className="col-lg-8">
                                 <div className="b-a">
                                    <input type="text" className="form-control" style={{ border: '1px solid #C5C5C5' }} name="title" autoComplete="false" defaultValue={state.title} required
                                       onChange={handleChange} />
                                 </div>
                              </div>
                           </div>
                           }
                           {props.actionType === 0 || props.actionType === 1 &&  props.discoverType === 3 && props._id === 0 &&
                              <div className="form-group row">
                                 <label className="col-lg-3 form-control-label" style={{ textTransform: 'none', fontSize: '13px' }}>Description:</label>
                                 <div className="col-lg-8">
                                    <div className="b-a">
                                       <textarea className="form-control" name="description" style={{ border: '1px solid #C5C5C5' }} autoComplete="false" value={state.description}
                                          onChange={handleChange} />
                                    </div>
                                 </div>
                              </div>
                           }

                           
                           <div className="row">
                              <div style={{ padding : '8px' }}>
                                 {props.actionType === 2 && <span style={{ width: '100%' }}>Are you sure you want to delete ?</span>}
                              </div>

                              <div className="col-12 d-flex flex-sm-row flex-column">
                                   <button type="submit" className="btn-custom-1" >
                                    {props.actionType !==2 && <span>Save changes </span>}
                                    {props.actionType === 2 && <span>Delete </span>}
                                  </button>
                                  &nbsp;&nbsp;&nbsp;<button type="reset" className="btn-custom-2" onClick={props.onHide}> Cancel</button>
                              </div>
                           </div>
                        </form>


                     </div>
                  </div>
               </div>
            </Modal>




         </div>

      </>

   );
}

export default DiscoverAddEditPopup;