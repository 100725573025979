import React, { useState, useEffect } from 'react';
import ContentEditable from 'react-contenteditable';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { changeGroupListRowTitle } from '../../../../../actions';
import { getMyPermission } from '../../../../../util/permission';
import GroupRowEvent from './GroupRowEvent';
import ReactTooltip from 'react-tooltip';

class GroupListRowTitle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rowTitle: this.props.title,
            actionPermission: 'view',
            terminology:this.props.boardData.workspaceBoard.terminology
        }
        this.timeout = 0;

        this.rowContentEditableRef = React.createRef();
    }

    onChangeListTitle = (event) => {
        this.setState({ rowTitle: event.target.value })
    }

    componentDidMount() {
        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }

        if(this.state.rowTitle !== prevStates.rowTitle){
            ReactTooltip.rebuild();
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ terminology: nextProps.boardData.workspaceBoard.terminology })
        this.setState({ rowTitle: nextProps.title })
    }

    onKeyPress = (event) => {
        const { title, groupInd, rowInd, id } = this.props;
            this.props.changeGroupListRowTitle({
                groupInd: groupInd,
                rowInd: rowInd,
                rowTitle: event.target.innerText,
                groupItemID: id,
                boardId: this.props.boardData.boardID
            })
            ReactTooltip.rebuild();
    }

  
    render() {
 
        return (
            
            <div className="d-inline-flex" style={{ justifyContent : 'space-between' }}>

               {!this.props.isHeader && 
                <div class="checkbox-div-table">
                  <label className="container-checkbox" style={{ textTransform: 'none'}}>
                         <input type="checkbox" className="form-check-input"/>              
                            <span class="checkmark"></span>
                  </label>
                </div>

    }
               {!this.props.isHeader &&  <GroupRowEvent groupInd={this.props.groupInd} groupId={this.props.groupId} rowInd={this.props.rowInd} id={this.props.id} />}
                {this.props.groupItemProvided !== null ? (
                    <div {...this.props.groupItemProvided.dragHandleProps} style={{ padding: '7px 1px', color: '#c4c4c4' , 'marginTop' : '3px' }}>
                        <i className='bx bx-grid-vertical board-table-move' ></i>
                    </div>
                ) : ''}
                
                {!this.props.isHeader &&  <ContentEditable
                    className="holder__title board-name list-item-head" style={{ 'width':'220px' }}
                    innerRef={r=>this.rowContentEditableRef=r}
                    data-tip={this.state.rowTitle}
                    html={this.state.rowTitle}
                    disabled={this.state.actionPermission === 'view' ? true : false}
                  //  onChange={this.onChangeListTitle}
                    onBlur={event => this.onKeyPress(event)}
                    tagName='div'
                />}
                {this.props.isHeader &&  <div className="mt-3 ml-3" style={{ 'font-size' : '14px' ,  'position' : 'relative'  ,
    'top': '-8px' ,  'text-transform': 'capitalize' }}>
                {this.state.terminology}
                    </div>}
               
            </div>
            
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeGroupListRowTitle };

export default connect(mapStateToProps, mapDispatchToProps)(GroupListRowTitle);