import React from 'react';

function CheckEmailConfirmModal(props) {
    return (
        <div className="modal popups" data-backdrop="true" data-keyboard="false" id="confirmpass" role="dialog" aria-labelledby="confirmPass" aria-hidden="true" style={{ margin: '200px auto' }}>
          <div className="modal-content" style={{ minHeight: '265px', margin: '0 auto', padding: '23px 33px 20px 25px', maxWidth: '570px', borderRadius: '25px', overflow: 'hidden' }}>
            <div className="modal-header" style={{ height: '23px', backgroundColor: '#ffffff' }}>
              <h3 className="modal-title" id="confirmPass" />
              <button type="button" className="close rounded-pill" data-dismiss="modal" aria-label="Close">
                <i className="bx bx-x" />
              </button>
            </div>
            <div className="modal-body" style={{ padding: '10px 10px 10px 10px' }}>
              <div className="row">
                <div className="col-md-6">
                  <img src="/images/icon/auth/Email-Check.png" style={{ width: '86%' }} />
                </div>
                <div className="col-md-6">
                  <div className="row mt-50">
                    <h4 style={{ color: '#2c4d74' }}>Check Your Email</h4>
                  </div>
                  <div className="row mt-50">
                    <span style={{ fontWeight: 300 }}>We have sent you a reset password instructions on your email address</span>
                  </div>
                  <div className="row mt-50">
                    <input type="email" className="form-control h-50" disabled placeholder="Verify Code" style={{ fontSize: '13px', fontWeight: 300 }} />
                  </div>
                  <div className="row mt-2">
                    <span style={{ fontSize: '12px', fontWeight: 300 }}>Didn't recieve the link?</span> <a className="card-link" ng-click="resendNext()" style={{ color: '#09F', cursor: 'pointer' }}><small style={{ fontWeight: 300, verticalAlign: 'super' }}>&nbsp;&nbsp;Resend</small></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}

export default CheckEmailConfirmModal;