import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { CirclePicker } from 'react-color';
import { changeBoardItem, changeStatusItems, fetchError } from '../../../../actions';
//import StatusItemEdit from './StatusItemEdit';
//import StatusLabelRemove from './StatusLabelRemove';

import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../util/permission';

const colors = [
    "#ff5ac4", "#ff158a", "#7f5347", "#ff642e", "#cab641", "#9cd326",
    "#037f4c", "#0086c0", "#66ccff", "#784bd1", "#808080", "#333333",
    "#ff7575", "#faa1f1", "#ffadad", "#7e3b8a", "#9aadbd", "#68a1bd",
    "#225091", "#4eccc6", "#5559df", "#401694", "#00c875", "#579bfc",
    "#e2445c"
]

class StatusItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openEdit: false,
            cellItemStatus: [],
            items: [],
            labels: {},
            targetAreaIndex: null,
            newLabelName: '',
            colorId: -1,
            newColor: '#808080',
            activeColorItemIndex: null,
            actionPermission: 'edit'
        };
        this.dropdownContainer = React.createRef();
        this.editLabelContainer = React.createRef();
    }
    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false, openEdit: false });
        }
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
        let cellSuggestion =  this.props.columnHeader;
        // let columnHeaderId = "col_1_1_" + (this.props.colInd + 1);
        let statusSuggestion = cellSuggestion.find(ele => ele._id === this.props.columnId);
        this.setState({ cellItemStatus: statusSuggestion.suggestions })
        this.buildAndSaveDragDrop(statusSuggestion.suggestions);
        // if (this.props.boardData.boardID) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem?.cellItems || [];
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }
    componentDidUpdate(prevProps, prevStates) {
        // if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem.cellItems;
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    editStatusLabels = () => {
        this.setState({
            openEdit: true
        })
    }

    applyChangedStatus = () => {
        this.setState({
            open: false,
            openEdit: false
        })

        this.props.changeStatusItems({
            boardId: this.props.search.boardID,
            labels: this.state.items,
            columnId: this.props.columnId
        })
    }

    changeColorId = (itemId, itemInd) => {
        this.setState({ colorId: itemId, activeColorItemIndex: itemInd })
    }

    handleColorChangeComplete = (color) => {
        if (this.state.colorId === -1) {
            this.setState({ newColor: color.hex })
        } else {
            let items = this.state.items;
            items[this.state.activeColorItemIndex].color = color.hex;

            this.setState({ items: items })
        }

    }

    buildAndSaveDragDrop = (items) => {
        const labels = {};

        for (let i = 0; i < Object.keys(items).length; ++i) {
            // console.log("items length==>", i)
            const currentLabel = items[i];
            labels[currentLabel._id] = i;
        }

        // console.log("labels==>", labels)

        // Set the data.
        this.setState({ items: items });

        // Makes the groups searchable via their id.
        this.setState({ labels: labels });
    }

    addNewLabel = () => {
        if (this.state.newLabelName !== '') {
            let items = this.state.items;
            let newLabel = {
                _id: 'status_' + (items.length + 1),
                name: this.state.newLabelName,
                type: this.state.newLabelName,
                color: this.state.newColor
            }


            items.push(newLabel);
            this.setState({ items: items, newLabelName: "" });
        }
    }

    changeLableTitle = (label, itemInd) => {
        console.log("label===>", label)
        if (label !== '') {
            let items = this.state.items;
            items[itemInd].name = label;
            this.setState({ items: items })
        }
    }

    removeLabel = (itemInd) => {

        let items = this.state.items;
        const workValue = items.slice();
        const [deletedItem,] = workValue.splice(itemInd, 1);

        setTimeout(() => {
            this.setState({ items: workValue });

        }, 100)
        // this.setState({ items: workValue });

    }
    render() {
        const { _id, body } = this.props;
        const { items, labels } = this.state;
        let cellSuggestion =  this.props.columnHeader;
        let statusSuggestion = cellSuggestion.find(ele => ele._id === this.props.columnId);
        let cellStatusItems = statusSuggestion.suggestions;

        let backgroundColor = '';
        let statusName = '';
        for (let i = 0; i < cellStatusItems.length; i++) {
            if (body.toString() === cellStatusItems[i].type.toString()) {
                backgroundColor = cellStatusItems[i].color;
                statusName = cellStatusItems[i].name
                break;
            } else if (body === "") {
                backgroundColor = "#c4c4c4";
            }
        }

        return (
            <div className="dropdown p-2" ref={this.dropdownContainer} style={{ backgroundColor: backgroundColor, minHeight: '38px' }} id="dropdown-container">
                <button type="button"
                    className="btn dropdown-toggle"
                    style={{ fontSize: '14px', width: '98%', padding: '0 4px', color: '#fff', minHeight: "22px" }}
                    onClick={() => {

                        // console.log('llp7',this.props);
                        // if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.search.boarddata[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.search.boarddata[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                        //     this.setState({ open: !this.state.open })                            
                        // } else {
                        //     this.props.fetchError("You don't have permission. You can only view")
                        // }
                    }} dangerouslySetInnerHTML={{__html:statusName}}>
                   
                </button>
                {this.state.open ? (
                    <ul
                        className={`dropdown-menu multi-level animate slideIn status-dropdown p-0  ${this.state.open ? "show" : ""} `}
                        role="menu"
                        aria-labelledby="dropdownMenu"
                    >
                        <div style={{ display: this.state.openEdit ? 'none' : '' }}>
                            <div className='p-2 border-bottom'>
                                {cellStatusItems.map((item, index) => {
                                    return (
                                        <button className="dropdown-item change-item change-status" key={index}
                                            onClick={() => {
                                                this.props.changeBoardItem({
                                                    groupInd: this.props.groupInd,
                                                    rowInd: this.props.rowInd,
                                                    colInd: this.props.colInd,
                                                    body: item.type,
                                                    cellItemID: _id,
                                                    boardId: this.props.search.boardID
                                                });
                                                this.setState({ open: false });
                                            }} style={{ backgroundColor: `${item.color}` }} dangerouslySetInnerHTML={{__html:item.name}}></button>
                                    )
                                })}
                            </div>
                            <div>
                                <button className="btn btn-status w-100" onClick={() => this.editStatusLabels()}>
                                    <i className="fa fa-pencil" style={{ color: 'inherit' }}></i> Add/Edit Label
                        </button>
                            </div>
                        </div>
                        <div style={{ display: !this.state.openEdit ? 'none' : '', position: 'relative' }} >
                            <div className='border-bottom p-2' style={{ position: 'relative' }}>
                                <DragDropContext
                                    onDragEnd={(result) => {
                                        this.setState({ targetAreaIndex: null });
                                        const { destination, draggableId, source, type } = result;
                                        if (!destination) {
                                            return;
                                        }

                                        if (type === 'label') {
                                            const sourceIndex = source.index;
                                            const targetIndex = destination.index;
                                            const workValue = items.slice();
                                            const [deletedItem,] = workValue.splice(sourceIndex, 1);
                                            workValue.splice(targetIndex, 0, deletedItem);

                                            this.buildAndSaveDragDrop(workValue);
                                            // this.props.changeStatusItems({
                                            //     boardId: this.props.boardData.boardID,
                                            //     labels: workValue
                                            // })

                                            return;
                                        }
                                    }}
                                >
                                    <Droppable droppableId='label_root' type='label'>
                                        {(provided, snapshot) => (
                                            <div
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                className="p-1"
                                                style={{ backgroundColor: snapshot.isDraggingOver ? '#f1f1f3' : 'white', position: 'relative' }}
                                            >
                                                {items.length > 0 ? items.map((item, index) => (
                                                    <Draggable
                                                        key={item._id}
                                                        draggableId={item._id}
                                                        index={index}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <div
                                                                {...provided.draggableProps}
                                                                ref={provided.innerRef}
                                                                style={{ ...provided.draggableProps.style, position: 'relative !important', height: snapshot.isDragging ? '0px' : '' }}
                                                            >
                                                                <div className='d-inline-flex w-100 mb-1' style={{ backgroundColor: '#f5f6f8', boxShadow: this.state.colorId === item._id ? '1px 1px 8px 2px rgba(69, 96, 95, 0.8)' : '' }}>
                                                                    <div {...provided.dragHandleProps} style={{ padding: '11px 2px' }}>
                                                                        <i className='bx bx-grid-vertical'></i>
                                                                    </div>
                                                                    <div>
                                                                        <button
                                                                            type='button'
                                                                            className='btn h-100'
                                                                            style={{ backgroundColor: `${item.color}` }}
                                                                            onClick={() => this.changeColorId(item._id, index)}
                                                                            disabled={item.type === 'None' ? true : false}
                                                                        ></button>
                                                                    </div>
                                                                   
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )) : ''}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                    <div className='p-2'>
                                        <div className='d-flex w-100' style={{ backgroundColor: '#f5f6f8' }}>
                                            <div>
                                                <button className='btn h-100'
                                                    style={{ backgroundColor: `${this.state.newColor}` }}
                                                    onClick={() => { this.setState({ colorId: -1 }) }}
                                                ></button>
                                            </div>
                                            <div className='p-1'>
                                                <input type='text'
                                                    placeholder="Add Label"
                                                    value={this.state.newLabelName}
                                                    onChange={(event) => this.setState({ newLabelName: event.target.value })}
                                                    style={{ borderWidth: '0px', padding: '3px 4px' }}
                                                />
                                            </div>
                                            <div>
                                                <button type='button' className='btn btn-primary' onClick={() => this.addNewLabel()}>Add</button>
                                            </div>
                                        </div>
                                    </div>
                                </DragDropContext>
                                <div className='p-2'>
                                    <CirclePicker width={270} circleSize={16} colors={colors} onChangeComplete={this.handleColorChangeComplete} />
                                </div>
                            </div>
                            <div>
                                <button className="btn btn-status w-100" onClick={() => this.applyChangedStatus()}>
                                    Apply
                            </button>
                            </div>
                        </div>

                    </ul>
                ) : ''}


            </div>
        )
    }
}

const mapStatusToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = { changeBoardItem, changeStatusItems, fetchError };
export default connect(mapStatusToProps, mapDispatchToProps)(StatusItem);