import React from 'react';
import Checkbox from 'react-checkbox-component'

//https://github.com/Hugand/react-checkbox-component#readme
function LoginInputInput({ label, error, ...rest }) {
    return (
        <fieldset className="form-label-group form-group position-relative has-icon-right">
            <input {...rest} type="email" className="form-control login bg-transparent" required style={{
                fontSize: '16px', fontWeight: 300,
                border: '1px solid #CCC', backgroundColor: 'transparent', outline: 'none', height: '35px !important'
            }}
                autoComplete="off" />
            <div className="form-control-position" style={{ lineHeight: '1.8', top: '5px' }}>
                <img src="images/icon/auth/Mobileicons-203w.png" style={{ width: '55%', marginTop: '3px' }} />
            </div>
            <span htmlFor="iconLabelLeft"
                style={{
                    textTransform: 'none', position: 'absolute', padding: '0px 10px 0px 10px', top: '-8px', left: '15px',
                    backgroundColor: '#4d2878', color: '#ffffff', borderRadius: '5px', fontSize: '14px', fontWeight: '300'
                }}>{label}</span>
                
            { error && <div className="alert alert-danger">{error.message}</div>}
        </fieldset>
    );
}

export default LoginInputInput;