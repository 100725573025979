/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import http from '../../services/http-service';
import CountriesTable from './countryTable.jsx';
import Modal from './modal.jsx';
import StaticHTML from '../common/static-html.jsx';
import * as CountryConstant from './constant.jsx';
import Joi from 'joi-browser';
import Form from '../common/Form.jsx';
import MainMenu from '../../../shared/components/main-menu';
import NavBar from '../../../shared/components/navbar/navbar';
import Notification from '../../notification/notification';
import UserService from "../../auth/auth-services/user-service";

class Country extends Form {
  constructor(props) {
    super(props);
    let _currentUser = UserService.getUserByToken("token").id;

    this.state = {
      CountryCode: "",
      CreatedBy: _currentUser,
      ModifiedBy: _currentUser,
      IsDeleted: false,
      IsActive: true,
      //load data
      countyData: [],
      TableData: [],

      //validation
      countryToValidate: { Name: "", CountryCode: "" },
      errorCountryName: "",
      errorCountryCode: "",
      isUpdate: false,
    };
  }

  schema = {
    Name: Joi.string().required().label("Name"),
    CountryCode: Joi.string().required().label("Country Code"),
  };

  async componentDidMount() {
    this.loadFirstData();
  }

  loadFirstData = async () => {
    try {
      await http.get(CountryConstant.Country_GetALL_URL).then((data) => {
        this.state.countyData = data.data.response;
      });
      let countryList = this.state.countyData.filter(
        (x) =>
          x.IsActive === true &&
          x.IsDeleted === false &&
          (x.CreatedBy != null) & (x.ModifiedBy != null)
      );
      countryList.map((data, index) => {
        this.state.TableData.push({
          itemId: data._id,
          h_0: index + 1,
          h_1: data.Name,
          h_2: data.CountryCode,
          h_3: data.CreatedBy.firstname,
          h_4: data.createdAt,
          h_5: data.ModifiedBy.firstname,
          h_6: data.updatedAt,
        });
      });
      this.setState({
        TableData: this.state.TableData,
      });
    } catch (e) {
      alert(
        "Some Thing Went Wrong In Load Data , Please Reload Page Again Or Contact With Support..."
      );
      console.log(e.message, "Error Message");
    }
  };

  SaveOrUpdate = async () => {
    this.handleSaveSubmit();
    const data = {
      id: this.state.Id,
      Name: this.state.countryToValidate.Name,
      CountryCode: this.state.countryToValidate.CountryCode,
      CreatedBy: this.state.CreatedBy,
      ModifiedBy: this.state.ModifiedBy,
      IsDeleted: this.state.IsDeleted,
      IsActive: this.state.IsActive,
    };
    this.doSaveOrUpdate(
      data,
      CountryConstant.Country_ADD_URL,
      CountryConstant.Country_Update_URL
    );
  };

  onloadCountry = async (id) => {
    this.setState({
      isUpdate: true,
    });
    await http
      .get(CountryConstant.Country_GetByID_URL + `?id=${id}`)
      .then((data) => {
        this.setState({
          countryToValidate: {
            Name: data.data.response.Name,
            CountryCode: data.data.response.CountryCode,
          },
        });
        this.setState({
          Id: data.data.response._id,
        });
      });
  };
  //Soft Delete??
  deleteRecord = async (id) => {
    this.doDeleteRecord(id, CountryConstant.Country_DELETE_URL);
  };

  onChangeCountryName = (e) => {
    if (
      e.target.value != "" &&
      this.state.countryToValidate.CountryCode != ""
    ) {
      this.setState({
        isUpdate: true,
      });
    }
    this.setState({
      countryToValidate: {
        ...this.state.countryToValidate,
        Name: e.target.value,
      },
    });
    const error = this.validateProperty(e.target.value, this.schema.Name);
    if (error) {
      this.setState({
        isUpdate: false,
      });
    }
    this.setState({ errorCountryName: error });
  };

  onChangeCountryCode = (e) => {
    if (e.target.value != "" && this.state.countryToValidate.Name != "") {
      this.setState({
        isUpdate: true,
      });
    }
    this.setState({
      countryToValidate: {
        ...this.state.countryToValidate,
        CountryCode: e.target.value,
      },
    });
    const error = this.validateProperty(
      e.target.value,
      this.schema.CountryCode
    );
    if (error) {
      this.setState({
        isUpdate: false,
      });
    }
    this.setState({ errorCountryCode: error });
  };

  resetModal = () => {
    this.setState({
      isUpdate: false,
    });
    this.setState({ countryToValidate: { Name: "", CountryCode: "" } });
    this.setState({
      Id: "",
    });
  };

  render() {
    const {
      TableData,
      errorCountryName,
      errorCountryCode,
      isUpdate,
    } = this.state;
    return (
      <>
        <MainMenu link={`country`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <StaticHTML />
                <div className="content-wrapper" >

                  <div className="company-table">
                    <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                      <div style={{ float: 'left' }}>
                        <h5 className="left-head">Country</h5>
                      </div>
                      <div style={{ float: 'right', marginTop: '-2px', marginRight: '-15px' }}>
                        <a onClick={this.resetModal} title="Reply" data-toggle="modal" data-target="#modal-Country">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;New Country <i className="bx bxs-plus-circle" /></span>
                        </a>
                      </div>
                    </div>
                    {/* Countries Table */}
                    <CountriesTable onSort={this.onSort} TableData={TableData} onloadCountry={this.onloadCountry} deleteRecord={this.deleteRecord} />

                    <Modal ChangeCountryName={this.onChangeCountryName} ChangeCountryCode={this.onChangeCountryCode}
                      saveOrUpdate={this.SaveOrUpdate} countryName={this.state.countryToValidate.Name} countryCode={this.state.countryToValidate.CountryCode}
                      errorCountryName={errorCountryName} errorCountryCode={errorCountryCode} isUpdate={isUpdate}
                    />
                  </div> {/* End Top Section*/}
                </div> {/* ENd Company Table*/}
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}
export default Country;
