import React from 'react';

function RecentActiveFolderWidget(props) {
    const { ShowRecentActiveFoldersWidgetValue } = props;

    return (
        <>
            {
                ShowRecentActiveFoldersWidgetValue &&
                <div className="col-md-5 col-lg-5 border active-recent" id="recent-folder" style={{ minHeight: '250px', borderRadius: '15px', boxShadow: '0px 0px 9px 2px #EAEAEA' }}>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-12 mt-2" id="top-section">
                            <div style={{ float: 'left', marginLeft: '15px' }}>
                                <h5 className="left-head">Recent Active Folders</h5>
                            </div>
                            <div style={{ float: 'right', marginTop: '-2px' }}>
                                <a title="Reply" data-toggle="modal" data-target="#modal-City">
                                    <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12" style={{ display: 'contents' }}>
                            <div className="folder first-folder">
                                <span className="folder-span">Automotive Finance</span>
                                <div className="progress bar" style={{ position: 'relative', top: '45px', left: '5px', right: '5px', width: '90%', height: '4px' }}>
                                    <div className="progress-bar" role="progressbar" style={{ width: '50%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                                </div>
                            </div>
                            <div className="folder second-folder">
                                <span className="folder-span">Trading Sales &amp; Marketing</span>
                                <div className="progress" style={{ position: 'relative', top: '45px', left: '5px', right: '5px', width: '90%', height: '4px' }}>
                                    <div className="progress-bar" role="progressbar" style={{ width: '25%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                                </div>
                            </div>
                            <div className="folder third-folder">
                                <span className="folder-span">Prateek - FHS</span>
                                <div className="progress" style={{ position: 'relative', top: '45px', left: '5px', right: '5px', width: '90%', height: '4px' }}>
                                    <div className="progress-bar" role="progressbar" style={{ width: '45%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                                </div>
                            </div>
                            <div className="folder fourth-folder">
                                <span className="folder-span">Recruitment</span>
                                <div className="progress" style={{ position: 'relative', top: '45px', left: '5px', right: '5px', width: '90%', height: '4px' }}>
                                    <div className="progress-bar" role="progressbar" style={{ width: '75%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                                </div>
                            </div>
                            <div className="folder fifth-folder">
                                <span className="folder-span">AEMC 2020 Jun Strategy</span>
                                <div className="progress" style={{ position: 'relative', top: '45px', left: '5px', right: '5px', width: '90%', height: '4px' }}>
                                    <div className="progress-bar" role="progressbar" style={{ width: '60%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                                </div>
                            </div>
                            <div className="folder sixth-folder">
                                <span className="folder-span">Transport Vendor<br /> Payment Approval</span>
                                <div className="progress" style={{ position: 'relative', top: '35px', left: '5px', right: '5px', width: '90%', height: '4px' }}>
                                    <div className="progress-bar" role="progressbar" style={{ width: '20%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }


        </>

    );
}

export default RecentActiveFolderWidget;