import React from 'react'
import ReactDOM from 'react-dom'
import Avatar from 'react-avatar-edit'
import * as Img from '../register/avatar.png'

//https://github.com/kirill3333/react-avatar
class AvatarEditor extends React.Component {

  constructor(props) {
    super(props)
    const src = {Img}
    this.state = {
      preview: {Img},
      src
    }
    this.onCrop = this.onCrop.bind(this)
    this.onClose = this.onClose.bind(this)
  }
  
  onClose() {
    // this.setState({preview: null})
  }
  
  onCrop(preview) {
    this.setState({preview})
  }
  
  render () {
    return (
      <div>
        <Avatar
          width={390}
          height={295}
          onCrop={this.onCrop}
          onClose={this.onClose}
          src={this.state.src}
        />
        <img src={this.state.preview} alt="Preview" />
        {/* <img src={Img} alt="Preview" /> */}
      </div>
    )
  }
}

export default AvatarEditor;
