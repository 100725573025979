export const getAllRecentSearch = "recentSearch/getAllRecentSearch";
export const getRecentSearchById = "recentSearch/getRecentSearchById "
export const getWorkspaceBoardyById = "recentSearch/getWorkspaceBoardyById"
export const getRecentSearchByUserId = "recentSearch/getRecentSearchByUserId"
export const saveRecentSearch = "recentSearch/saveRecentSearch"
export const updateRecentSearch = "recentSearch/updateRecentSearch"
export const deleteRecentSearch = "recentSearch/deleteRecentSearch"
export const recentSearch_Search = "recentSearch/RecentSearch_Search "
export const getMaxRecentSearch = "recentSearch/getMaxRecentSearch"
export default {
    getAllRecentSearch: getAllRecentSearch,
    getMaxRecentSearch: getMaxRecentSearch,
    getWorkspaceBoardyById: getWorkspaceBoardyById,
    getRecentSearchByUserId:getRecentSearchByUserId,
    saveRecentSearch: saveRecentSearch,
    updateRecentSearch: updateRecentSearch,
    deleteRecentSearch: deleteRecentSearch,
    recentSearch_Search: recentSearch_Search,
}

