import React from 'react';
import { useState,useEffect } from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { userSignOut } from '../../../actions';
function NavBody(props) {
   const { user } = props;
   const [authUser,setAuthuser]=useState(JSON.parse(localStorage.getItem('authUser')))
   const[greeting,setGreetings]=useState('Good Morning')

   useEffect(()=>{

      var myDate = new Date();
      var hrs = myDate.getHours();
  
      var greet;
  
      if (hrs < 12)
          greet = 'Good Morning';
      else if (hrs >= 12 && hrs <= 17)
          greet = 'Good Afternoon';
      else if (hrs >= 17 && hrs <= 24)
          greet = 'Good Evening';
          setGreetings(greet)
   },[])

   return (
      

      <nav id="navmenu" className="header-navbar main-header-navbar navbar-expand-lg navbar navbar-with-menufixed-top fixed-top" data-bgcolor="bg-white" style={{ padding: '0.5rem 0rem' }}>
         <div style={{ backgroundColor: '#21386C', padding: '10px 0px 0px 0px', width: '100%' }} className="navbar-wrapper">
            <div className="navbar-wrapper" style={{borderTopRightRadius: '49px', borderTopLeftRadius: '49px' }}>
               <div className="navbar-container content" style={{ borderTopRightRadius: '18px', borderTopLeftRadius: '18px', borderBottomLeftRadius: '0' , borderBottomRightRadius: '0' }}>
                  <div className="navbar-collapse" id="navbar-mobile">
                     <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                        <ul className="nav navbar-nav">
                           <li className="nav-item mobile-menu d-xl-none mr-auto"><a className="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i className="ficon bx bx-menu" /></a></li>
                        </ul>
                        <ul className="nav navbar-nav bookmark-icons">
                           <li className="nav-item d-none d-lg-block nav-search" data-placement="top">
                              <a className="nav-link nav-link-search cursor-pointer" data-toggle="tooltip" title="Search Here">
                                 <img src="./images/icon/header-icons/search.png" style={{ width: '25px', height: '25px' }} alt="" />
                              </a>

                              <div className="search-input">
                                 <div className="search-input-icon"><i className="bx bx-search primary" /></div>
                                 <div className="wrapper" ng-controller="autocompletemainController">
                                    
                                    <div className="search-input-close" ng-click="closeSearch()"><i className="bx bx-x" /></div>
                                 </div>
                              </div>
                           </li>

                           <li className="dropdown dropdown-notification nav-item">
                              <a className="nav-link nav-link-label" href="#" data-toggle="dropdown" title="Notification"><img src="./images/icon/header-icons/bell.png" style={{ width: '25px', height: '25px' }} /> <span className="badge badge-pill badge-danger badge-up">5</span></a>
                              <ul className="dropdown-menu">
                                 <li className="dropdown-menu-header">
                                    <div className="dropdown-header px-1 py-75 d-flex justify-content-between"><span className="notification-title">7 new Notification</span><span className="text-bold-400 cursor-pointer">Mark all as read</span></div>
                                 </li>
                                 <li className="scrollable-container media-list">
                                    <a className="d-flex justify-content-between" href="#">
                                       <div className="media d-flex align-items-center">
                                          <div className="media-left pr-0">
                                             <div className="avatar mr-1 m-0"><img src="./images/portrait/small/avatar-s-11.jpg" alt="avatar" height={39} width={39} /></div>
                                          </div>
                                          <div className="media-body">
                                             <h6 className="media-heading"><span className="text-bold-500">Congratulate Socrates Itumay</span> for work anniversaries</h6><small className="notification-text">Mar 15 12:32pm</small>
                                          </div>
                                       </div>
                                    </a>
                                    <div className="d-flex justify-content-between read-notification cursor-pointer">
                                       <div className="media d-flex align-items-center">
                                          <div className="media-left pr-0">
                                             <div className="avatar mr-1 m-0"><img src="Content/images/portrait/small/avatar-s-16.jpg" alt="avatar" height={39} width={39} /></div>
                                          </div>
                                          <div className="media-body">
                                             <h6 className="media-heading"><span className="text-bold-500">New Message</span> received</h6><small className="notification-text">You have 18 unread messages</small>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-between cursor-pointer">
                                       <div className="media d-flex align-items-center py-0">
                                          <div className="media-left pr-0"><img className="mr-1" src="Content/images/icon/sketch-mac-icon.png" alt="avatar" height={39} width={39} /></div>
                                          <div className="media-body">
                                             <h6 className="media-heading"><span className="text-bold-500">Updates Available</span></h6><small className="notification-text">Sketch 50.2 is currently newly added</small>
                                          </div>
                                          <div className="media-right pl-0">
                                             <div className="row border-left text-center">
                                                <div className="col-12 px-50 py-75 border-bottom">
                                                   <h6 className="media-heading text-bold-500 mb-0">Update</h6>
                                                </div>
                                                <div className="col-12 px-50 py-75">
                                                   <h6 className="media-heading mb-0">Close</h6>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-between cursor-pointer">
                                       <div className="media d-flex align-items-center">
                                          <div className="media-left pr-0">
                                             <div className="avatar bg-primary bg-lighten-5 mr-1 m-0 p-25"><span className="avatar-content text-primary font-medium-2">LD</span></div>
                                          </div>
                                          <div className="media-body">
                                             <h6 className="media-heading"><span className="text-bold-500">New customer</span> is registered</h6><small className="notification-text">1 hrs ago</small>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="cursor-pointer">
                                       <div className="media d-flex align-items-center justify-content-between">
                                          <div className="media-left pr-0">
                                             <div className="media-body">
                                                <h6 className="media-heading">New Offers</h6>
                                             </div>
                                          </div>
                                          <div className="media-right">
                                             <div className="custom-control custom-switch">
                                                <input className="custom-control-input" type="checkbox" defaultChecked id="notificationSwtich" />
                                                <label className="custom-control-label" htmlFor="notificationSwtich" />
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-between cursor-pointer">
                                       <div className="media d-flex align-items-center">
                                          <div className="media-left pr-0">
                                             <div className="avatar bg-danger bg-lighten-5 mr-1 m-0 p-25"><span className="avatar-content"><i className="bx bxs-heart text-danger" /></span></div>
                                          </div>
                                          <div className="media-body">
                                             <h6 className="media-heading"><span className="text-bold-500">Application</span> has been approved</h6><small className="notification-text">6 hrs ago</small>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-between read-notification cursor-pointer">
                                       <div className="media d-flex align-items-center">
                                          <div className="media-left pr-0">
                                             <div className="avatar mr-1 m-0"><img src="Content/images/portrait/small/avatar-s-4.jpg" alt="avatar" height={39} width={39} /></div>
                                          </div>
                                          <div className="media-body">
                                             <h6 className="media-heading"><span className="text-bold-500">New file</span> has been uploaded</h6><small className="notification-text">4 hrs ago</small>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-between cursor-pointer">
                                       <div className="media d-flex align-items-center">
                                          <div className="media-left pr-0">
                                             <div className="avatar bg-rgba-danger m-0 mr-1 p-25">
                                                <div className="avatar-content"><i className="bx bx-detail text-danger" /></div>
                                             </div>
                                          </div>
                                          <div className="media-body">
                                             <h6 className="media-heading"><span className="text-bold-500">Finance report</span> has been generated</h6><small className="notification-text">25 hrs ago</small>
                                          </div>
                                       </div>
                                    </div>
                                    <div className="d-flex justify-content-between cursor-pointer">
                                       <div className="media d-flex align-items-center border-0">
                                          <div className="media-left pr-0">
                                             <div className="avatar mr-1 m-0"><img src="Content/images/portrait/small/avatar-s-16.jpg" alt="avatar" height={39} width={39} /></div>
                                          </div>
                                          <div className="media-body">
                                             <h6 className="media-heading"><span className="text-bold-500">New customer</span> comment recieved</h6><small className="notification-text">2 days ago</small>
                                          </div>
                                       </div>
                                    </div>
                                 </li>
                                 <li className="dropdown-menu-footer"><a className="dropdown-item p-50 text-primary justify-content-center" href="#">Read all notifications</a></li>
                              </ul>
                           </li>
                           <li className="dropdown dropdown-language nav-item" style={{ marginLeft: '30px' }}>
                              <button type="button" className="btn btn-light-danger mb-1" id="light-buttons" data-toggle="modal" data-target="#modal-activity" style={{ backgroundColor: '#e8378f', borderRadius: '12px', fontFamily: 'myriad-pro' , 
                             ' width': '132px' , 'height': '37px' , 'display': 'flex' , 'color' : '#fff' }}>Add activity <img src="./images/icon/header-icons/Add-button.png" 
                              style={{ width: '25px' , 'margin-left' : '10px' }} /></button>
                           </li>
                        </ul>
                        <ul className="nav navbar-nav greeting-div" style={{ marginLeft: '60px' }}>
                           <div className="greetings-text">
                              <span style={{ fontSize: '14px', fontWeight: 500, color: '#333333', fontFamily: 'myriad-pro' }}>

                                 {user ? "Good Morning  " + user.context.user.gender + "  " + user.context.user.name : ""}{greeting} {authUser.firstname+' '+authUser.lastname} <br />

                                 <span style={{ fontWeight: 100, fontSize: '12.5px', color: '#666666', fontFamily: 'myriad-pro' }}>10:30 a.m - 03 sep 2020</span>
                              </span>
                           </div>
                        </ul>
                        <ul className="nav navbar-nav nav-weather" style={{ marginLeft: '150px', fontFamily: 'myriad-pro,sans-serif !important' }}>
                           <table className="weather-report">
                              <tbody ><tr>
                                 <td style={{ width: '60px' }}><img src="./images/icon/header-icons/cloudy.png" style={{ width: '50px' }} alt="" /></td>
                                 <td class="cloud-detail" style={{ lineHeight: '13px', fontFamily: 'myriad-pro' }}>
                                    <span className="menu-title menu-txt-weather" style={{ fontSize: '11px', fontWeight: 100 }}>Abudhabi</span> <br />
                                    <span className="menu-title menu-txt-weather" style={{ fontSize: '11px', fontWeight: 100 }}>Cloudy</span><br />
                                    <span className="menu-title menu-txt-weather" style={{ fontSize: '11px', fontWeight: 100 }}>Chance of Rain: 5%</span> <br />
                                 </td>
                              </tr>
                              </tbody></table>
                        </ul>
                        <ul className="nav navbar-nav" style={{ marginLeft: '50px', lineHeight: '12px' }}>
                           <span className="menu-title txt-whtr" style={{ fontSize: '23px', fontWeight: 100, textAlign: 'center', fontFamily: 'myriad-pro' , lineHeight: '17px' }}>
                              26 °  <br />
                              <span className="menu-title txt-whtr" style={{ fontSize: '12.5px', fontWeight: 100, letterSpacing: '-1px', fontFamily: 'myriad-pro' }}>26 ° / 22 °</span>
                           </span>
                           <br />
                        </ul>

                        <ul className="nav navbar-nav logout-dashboard" style={{ marginLeft: '50px', lineHeight: '12px' }}>
                           <div style={{cursor: 'pointer' , 'display' : 'grid' }} onClick={() => { props.userSignOut()}}>
                              <i class="fad fa-sign-out logout-icon"></i>
                              <span class="log-txt">Logout</span>
                              </div>
                        </ul>
                     </div>
                     <ul className="nav navbar-nav float-left">
                        <div id="div-engage"> <img className="logo-engage" src="./images/logo/engage-logo.png" style={{ width: '200px', height: 'auto' }} alt="" /></div>
                     </ul>
                  </div>
               </div>
            </div>
         </div>
      </nav>

      

   );
}

const mapDispatchToProps = { userSignOut }


export default connect(() => ({}), mapDispatchToProps)(NavBody);