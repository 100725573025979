import React, { Component } from 'react';

const GlobalSettingsNavTab = (params) => {
  return (
    <>
             <div className="col-md-12 mb-2 mb-md-0 pills-stacked" style={{ padding: '0px 5px' }}>
                <ul className="nav nav-pills" style={{ flexWrap: 'inherit' }} >
                  <li className="nav-item">
                    <a className="nav-link d-flex align-items-center active" id="settings-pill-general" data-toggle="pill" href="#settings-vertical-general" target="_self" aria-expanded="true">
                      <i className="bx bxs-cog" />
                      <span style={{fontSize: '12.5px', fontWeight: 100}}>General Settings</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link d-flex align-items-center" id="settings-pill-email" data-toggle="pill" href="#settings-vertical-email" target="_self" aria-expanded="false">
                      <i className="bx bx-mail-send" />
                      <span style={{fontSize: '12.5px', fontWeight: 100}}>Email Settings</span>
                    </a>
                  </li>
                </ul>
              </div>
       </>
              );
}

export default GlobalSettingsNavTab ;