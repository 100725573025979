import React, { Component } from 'react';
import { connect } from 'react-redux'
import ModalSuccessResetPassword from './modal-password-reset-success';
import ModalForgetPassword from './modal-forget-password';
import LoginForm from './login-form';
import RightSideLinks from './right-side-links';
import ModalEmailRequestFailed from './email-request-failed-modal';
import ResetPasswordModal from './reset-password-modal';
import CheckEmailConfirmModal from './check-email-confirm-modal';
import Form from '../common/form.jsx';
import Notification from '../../notification/notification';
import { Link } from 'react-router-dom';

class LoginPage extends Form {
  constructor(props) {
    super(props);
    this.state = {
      forgotpasswordOpen: false
    }
  }

  componentDidMount() {
    if (this.props.token !== null) {
      this.props.history.push('/');
      window.location.reload();
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.token && nextProps.authUser) {
  //     this.props.history.push('/dashboard');
  //   }
  // }

  componentDidUpdate() {
    const { authUser, token, history } = this.props;
    if (authUser && token) {
      history.push('/');
      window.location.reload();
    };
  }

  render() {
    return (
      <React.Fragment>
        <div id="whole-layout">
          <div id="appdata" className='app-content content fixed-top messenger-layout'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div className="container-fluid">
                <div className="row" style={{ height: '100vh', overflow: 'hidden' }}>
                  <div className="col-md-5 col-12 px-0" >
                    <div className="card disable-rounded-left mb-0 p-2 h-100 d-flex justify-content-center" style={{ background: 'linear-gradient(to top left,#e8378c,#4E287A,#24214C)', borderRadius: '0px !important', border: 'none' }}>
                      <div className="card-header pb-1" style={{ borderBottom: '0px' }}>
                        <div className="card-title">
                          <h4 className="text-center mb-2">
                            <Link to='/home'>
                              <img src="images/engagefinal.png" alt="users avatar" className="users-avatar-shadow" style={{ width: '220px', height: 'auto' , margin : '0 auto' }} />
                            </Link>
                          </h4>
                          <br />
                          <h4 className="text-center mb-2">
                            <img src="images/usericon.png" alt="users avatar" className="users-avatar-shadow rounded-circle" style={{ border: '4px solid #CCC', padding: '3px' ,  margin : '0 auto' }} />
                          </h4>
                        </div>
                      </div>
                      <div className="card-content" style={{ marginLeft: '100px', marginRight: '100px' }}>
                        <div className="card-body">
                          <div className="text-left">
                            <div className="divider-text text-muted">
                              <label className="text-white font-medium-2" style={{ textTransform: 'none', fontWeight: '400' }}>Sign In</label>
                            </div>
                          </div>
                          <br />
                          <LoginForm openModal={() => {
                            this.setState({ forgotpasswordOpen: true })
                          }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <RightSideLinks />
                </div>
              </div>

              <ModalForgetPassword open={this.state.forgotpasswordOpen} closeModal={() => this.setState({ forgotpasswordOpen: false })} />

              <ModalEmailRequestFailed />

              <CheckEmailConfirmModal />

              <ResetPasswordModal />

              <ModalSuccessResetPassword />

            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser, token } = auth;

  return { authUser, token }
}

export default connect(mapStateToProps)(LoginPage);



