import React, { Component } from 'react';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';
import SettingsToolbar from '../../components/globalsettings/settingtoolbar';
class UserProfile extends Component {
  constructor(props){
    super(props);
    this.state = {
      title: '',
      nationality: '',
      firstname: ''
    }
  }
  render() {
    return (
      <>
        <MainMenu link={`userprofile`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div className="row dash-blue fixed-top">
                <div className="col-md-1 col-lg-1">
                </div>
                <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
                  <div>
                    <div className="inner-section">
                      <h3>User Profile Management</h3>
                      <div style={{ marginTop: '-10px' }}>
                        <p className="mt-1"> </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
                  <table className="align-items-center table-newdashboard-section-scheduler-one">
                    <tbody><tr>
                      <td rowSpan={2} style={{ width: '50px' }}>
                        <ul className="nav navbar-nav bookmark-icons">
                          <li className="nav-item d-none d-lg-block nav-search" data-placement="top">
                            <a className="nav-link nav-link-search cursor-pointer" data-toggle="dropdown" aria-expanded="false" title="Search">
                              <img src="./images/icon/dashboard-icons/scheduler-icons/search copy.png" height="25px" />
                            </a>
                            <div className="dropdown-menu text-color w-xl animated fadeInUp" style={{ right: 'auto', width: '240px' }}>
                              <form className="navbar-form form-inline navbar-item m-a-0 p-x v-m ng-pristine ng-valid" role="search">
                                <div className="form-group l-h m-a-0">
                                  <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search"

                                      id="search-box" />
                                    <span className="input-group-btn">
                                      <button type="submit" className="btn blue-A200 b-a no-shadow" id="tbSearch">
                                        <i className="bx bx-search"></i>
                                      </button>
                                    </span>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </li>
                        </ul></td>
                      <td rowSpan={2} style={{ width: '50px' }}><a href="#" data-action="expand" ><img src="./images/icon/dashboard-icons/scheduler-icons/refresh copy.png" height="25px" style={{ cursor: 'pointer' }} /></a></td>
                      <td rowSpan={2} style={{ width: '50px' }}><a href="#" data-action="expand" >
                        <img
                          src='/images/icon/dashboard-icons/scheduler-icons/zoomin copy.png'
                          height="25px" style={{ cursor: 'pointer' }} /></a></td>

                      <td rowSpan={2} style={{ width: '50px' }}><a ><img src="/images/icon/dashboard-icons/module-icons/white icons-38.png" height="25px" style={{ cursor: 'pointer' }} /></a></td>


                      <td rowSpan={2} style={{ width: '50px' }}><a href="#" data-toggle="tooltip" data-target="Filter"  ><img src="./images/icon/dashboard-icons/module-icons/share-white.png" height="25px" style={{ cursor: 'pointer' }} /></a></td>

                      <td>

                      </td>


                    </tr>
                      <tr style={{ fontWeight: 600 }}>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tbody></table>

                </div>
              </div>


              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 user-profile-banner">
                    <div className="user-profile-layout">

                      <div className="user-profile-avatar">
                        {/* <img className="profile-av" src="../images/Profile.png" alt="Name"  />  */}


                        {/* <div className="user-profile-edit">
                        <div id="user-profile-click">
                          <i className="bx bx-user-plus" style={{ fontSize: '40px' ,  color:' #ffffff' }}></i><br/>
                          <span style={{ color: '#ffffff' }}>Change Profile Picture</span>
                        </div>

                      </div>

                      <div id="user-dropdown" className="user-dropdown" style={{ padding : '10px' }}>
                           <a className="dropdown-item" href="#" style={{ backgroundColor : '#EAEAEA' }}><i className="bx bx-pencil" style={{ verticalAlign : 'sub' }}></i>Change Picture</a>
                           <a className="dropdown-item" href="#" style={{ backgroundColor : '#EAEAEA' , marginTop : '2px' }}><i className="bx bx-pencil" style={{ verticalAlign : 'sub' }}></i>Remove Picture</a>
                      </div>   */}

                        <div className="c-dropdown dropdown">
                          <div className="c-avatar has-dropdown dropdown-toggle" id="dropdownMenuAvatar" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img className="profile-av" src="../images/Profile.png" alt="Name" />
                            <div className="user-profile-edit">
                              <div id="user-profile-click">
                                <i className="bx bx-user-plus" style={{ fontSize: '40px', color: ' #ffffff' }}></i><br />
                                <span style={{ color: '#ffffff' }}>Change Profile Picture</span>
                              </div>

                            </div>
                          </div>
                          <div className="c-dropdown__menu dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuAvatar">
                            <a className="c-dropdown__item dropdown-item" href="#" style={{ textTransform: 'Capitalize' }} data-toggle="modal"
                              data-target="#avatarmodal"> <i className="bx bxs-image" style={{ color: '#e53c8d', marginRight: '10px' }}></i> Change Picture</a>
                            <a className="c-dropdown__item dropdown-item" href="#" style={{ textTransform: 'Capitalize' }}> <i className="bx bxs-trash" style={{ color: '#e53c8d', marginRight: '10px' }}></i> Remove Picture</a>
                          </div>
                        </div>

                      </div>
                      <div className="user-profile-head">
                        <h4>Carline John</h4>
                        <span>Admin</span>
                      </div>

                      <div className="user-profile-progress">
                        <span>90 % Completed</span>
                        <div className="progress bar user-progress" style={{ height: '7px', width: '200px' }}><div className="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '90%', height: '7px', backgroundColor: '#e53c8d' }}></div></div>
                      </div>

                    </div>
                  </div>
                </div>



                <div className="row" style={{ padding: '80px 50px' }}>

                  <section id="userprofile-with-icon">
                    <div className="collapsible collapse-icon accordion-icon-rotate in">
                      <div className="card collapse-header">
                        <div id="headingCollapse5" className="card-header" data-toggle="collapse" role="button" data-target="#collapse5"
                          aria-expanded="false" aria-controls="collapse5">
                          <span className="collapse-title">
                            {/* <i className='bx bxs-pencil'></i> */}
                            <span className="align-middle">Personal Info</span>
                          </span>
                        </div>
                        <div id="collapse5" role="tabpanel" aria-labelledby="headingCollapse5" className="collapse show">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value={this.state.title} onChange={(event) => this.setState({title: event.target.value})} />
                                    <label className="user-profile-label">Title<span className="asterik">*</span></label>
                                  </div>

                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value={this.state.firstname} onChange={(event) => this.setState({firstname: event.target.value})} />
                                    <label className="user-profile-label">First Name<span className="asterik">*</span></label>
                                  </div>

                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Middle Name<span className="asterik">*</span></label>
                                  </div>


                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Last Name<span className="asterik">*</span></label>
                                  </div>


                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">DOB<span className="asterik">*</span></label>
                                  </div>
                                </div>


                                <div className="col-md-4">
                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Nationality<span className="asterik">*</span></label>
                                  </div>

                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Gender<span className="asterik">*</span></label>
                                  </div>

                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Mobile<span className="asterik">*</span></label>
                                  </div>

                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Email<span className="asterik">*</span></label>
                                  </div>

                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Company<span className="asterik">*</span></label>
                                  </div>
                                </div>


                                <div className="col-md-4">
                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Division<span className="asterik">*</span></label>
                                  </div>

                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Department<span className="asterik">*</span></label>
                                  </div>


                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Designation<span className="asterik">*</span></label>
                                  </div>

                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label" >Birth Date<span className="asterik">*</span></label>
                                  </div>

                                  <div className="form-label-group">
                                    <input type="password" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Work Anniversary<span className="asterik">*</span></label>
                                  </div>


                                </div>

                              </div>

                              {/* <div className="row">
                   <div className="col-md-4">
                        <div style={{ display : 'flex' , justifyContent : 'space-between' }}>
                            <span style={{ color: '#21376c' }}>Add Text Fields</span>
                            <i className="bx bxs-plus-circle" style={{ color : '#e53c8d' ,  fontSize:' 25px' ,
    verticalAlign: 'middle' , cursor  : 'pointer' }}></i>
                        </div>
                   </div>
                </div> */}

                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="card collapse-header">
                        <div id="headingCollapse7" className="card-header" data-toggle="collapse" role="button" data-target="#collapse9"
                          aria-expanded="false" aria-controls="collapse7">
                          <span className="collapse-title">
                            {/* <i className="bx bx-bell align-middle"></i> */}
                            <span className="align-middle">Password</span>
                          </span>
                        </div>
                        <div id="collapse9" role="tabpanel" aria-labelledby="headingCollapse7" className="collapse show">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-4">
                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Current Password<span className="asterik">*</span></label>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">New Password<span className="asterik">*</span></label>
                                  </div>
                                </div>

                                <div className="col-md-4">
                                  <div className="form-label-group">
                                    <input type="text" className="form-control outline user-profile-outline" value="" />
                                    <label className="user-profile-label">Confirm New Password<span className="asterik">*</span></label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>





                      <div className="card collapse-header">
                        <div id="headingCollapse6" className="card-header" data-toggle="collapse" role="button" data-target="#collapse6"
                          aria-expanded="false" aria-controls="collapse6">
                          <span className="collapse-title">
                            {/* <i className="bx bx-heart align-middle"></i> */}
                            <span className="align-middle">Work Status</span>
                          </span>
                        </div>
                        <div id="collapse6" role="tabpanel" aria-labelledby="headingCollapse2" className="collapse show">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12 work-status">
                                  <span className="head-one">Update your working status</span><br />
                                  <span className="head-two">Let everyone know your status</span>
                                </div>

                                <div className="col-md-12 mt-2">



                                  <ul className="list-unstyled mb-0">
                                    <li className="d-inline-block mr-4 mb-1">
                                      <fieldset>
                                        <div className="radio radio-primary radio-glow">
                                          <input type="radio" id="radioGlow1" name="radioGlow" />
                                          <label htmlFor="radioGlow1">In the Office</label>
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li className="d-inline-block mr-4 mb-1">
                                      <fieldset>
                                        <div className="radio radio-secondary radio-glow">
                                          <input type="radio" id="radioGlow2" name="radioGlow" />
                                          <label htmlFor="radioGlow2">Working from Home</label>
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li className="d-inline-block mr-4 mb-1">
                                      <fieldset>
                                        <div className="radio radio-success radio-glow">
                                          <input type="radio" id="radioGlow3" name="radioGlow" />
                                          <label htmlFor="radioGlow3">Out Sick</label>
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li className="d-inline-block mr-4 mb-1">
                                      <fieldset>
                                        <div className="radio radio-danger radio-glow">
                                          <input type="radio" id="radioGlow4" name="radioGlow" />
                                          <label htmlFor="radioGlow4">On Break</label>
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li className="d-inline-block mr-4 mb-1">
                                      <fieldset>
                                        <div className="radio radio-info radio-glow">
                                          <input type="radio" id="radioGlow5" name="radioGlow" />
                                          <label htmlFor="radioGlow5">Out of the Office</label>
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li className="d-inline-block mr-4 mb-1">
                                      <fieldset>
                                        <div className="radio radio-warning radio-glow">
                                          <input type="radio" id="radioGlow6" name="radioGlow" />
                                          <label htmlFor="radioGlow6">Working Outside</label>
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li className="d-inline-block mr-4 mb-1">
                                      <fieldset>
                                        <div className="radio radio-danger radio-glow">
                                          <input type="radio" id="radioGlow7" name="radioGlow" />
                                          <label htmlFor="radioGlow7">Family Time</label>
                                        </div>
                                      </fieldset>
                                    </li>
                                    <li className="d-inline-block mr-4 mb-1">
                                      <fieldset>
                                        <div className="radio radio-danger radio-glow">
                                          <input type="radio" id="radioGlow8" name="radioGlow" />
                                          <label htmlFor="radioGlow8">Do Not Disturb</label>
                                        </div>
                                      </fieldset>
                                    </li>
                                  </ul>
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="card collapse-header">
                        <div id="headingCollapse7" className="card-header" data-toggle="collapse" role="button" data-target="#collapse7"
                          aria-expanded="false" aria-controls="collapse7">
                          <span className="collapse-title">
                            {/* <i className="bx bx-bell align-middle"></i> */}
                            <span className="align-middle">Notifications</span>
                          </span>
                        </div>
                        <div id="collapse7" role="tabpanel" aria-labelledby="headingCollapse7" className="collapse show">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="row mb-2">
                                <span style={{ fontSize: '15px', marginLeft: '16px' }}>Send me notifications when someone</span>
                              </div>

                              <div className="row">
                                <div className="col-md-4">
                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch2" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch2" >
                                    </label>
                                    <span className="text-user-toggle" style={{ fontWeight: '300' }}>Likes my update</span>
                                  </div>


                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch6" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch6" >
                                    </label>
                                    <span className="text-user-toggle" style={{ fontWeight: '300' }}>Writes an update on an item I'm subscirbed to</span>
                                  </div>


                                </div>

                                <div className="col-md-4">
                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch4" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch4" >
                                    </label>
                                    <span className="text-user-toggle" style={{ fontWeight: '300' }}>Replies or likes a conversation I'm part of</span>
                                  </div>


                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch5" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch5" >
                                    </label>
                                    <span className="text-user-toggle" style={{ fontWeight: '300' }}>Subscribe me to a Board/ Item/ Team</span>
                                  </div>

                                </div>

                                <div className="col-md-4">
                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch3" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch3" >
                                    </label>
                                    <span className="text-user-toggle" style={{ fontWeight: '300' }}>Replies to my update</span>
                                  </div>

                                </div>


                              </div>


                              <div className="row mb-2 mt-3">
                                <span style={{ fontSize: '15px', marginLeft: '16px' }}>Send me notifications via Email when someone</span>
                              </div>

                              <div className="row">
                                <div className="col-md-4">
                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch10" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch10" >
                                    </label>
                                    <span className="text-user-toggle">Assigns me to an item</span>
                                  </div>


                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch15" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch15" >
                                    </label>
                                    <span className="text-user-toggle" >Does not sign up after I have invited them</span>
                                  </div>



                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch11" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch11" >
                                    </label>
                                    <span className="text-user-toggle">Replies to my update</span>
                                  </div>


                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch18" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch18" >
                                    </label>
                                    <span className="text-user-toggle">Signs up with an email address from my account domain</span>
                                  </div>



                                </div>


                                <div className="col-md-4">

                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch12" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch12" >
                                    </label>
                                    <span className="text-user-toggle">Writes an update on an item I'm the owner of</span>
                                  </div>


                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch13" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch13" >
                                    </label>
                                    <span className="text-user-toggle">Writes an update on an item I'm subscribed to</span>
                                  </div>

                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch20" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch20" >
                                    </label>
                                    <span className="text-user-toggle">Mentions me in a post or reply</span>
                                  </div>


                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch16" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch16" >
                                    </label>
                                    <span className="text-user-toggle">Subscribes me to a Board / Item / Team</span>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch22" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch22" >
                                    </label>
                                    <span className="text-user-toggle">Replies or likes a conversation I'm a part of </span>
                                  </div>

                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch23" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch23" >
                                    </label>
                                    <span className="text-user-toggle">Signs up after I have invited them</span>
                                  </div>


                                  <div className="custom-control custom-switch custom-control-inline mb-1">
                                    <input type="checkbox" className="custom-control-input" id="customSwitch17" />
                                    <label className="custom-control-label mr-1" htmlFor="customSwitch17" >
                                    </label>
                                    <span className="text-user-toggle">Notifies me via automations</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card collapse-header">
                        <div id="headingCollapse8" className="card-header" data-toggle="collapse" role="button" data-target="#collapse8"
                          aria-expanded="false" aria-controls="collapse4">
                          <span className="collapse-title">
                            {/* <i className="bx bx-chalkboard align-middle"></i> */}
                            <span className="align-middle">Email Integration</span>
                          </span>
                        </div>
                        <div id="collapse8" role="tabpanel" aria-labelledby="headingCollapse8" className="collapse show">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <h6 style={{ color: '#000' }}>Update any item via email</h6>
                                  <span style={{ fontSize: '14px', fontWeight: '300' }}>Each board and item has an ID number, which can be used to create a unique email address.
                                  You can use this email address to send updates to an item or add a new item to a board
               from any email account, and it will magically update as if you were directly logged in.</span>
                                </div>

                                <div className="email_integration_addr">
                                  <div>
                                    <span className="color_purple pulse_id">pulse-(#ID)-</span>
                                    <span className="bold">187f0176a5312ae5bf7e__17881025</span>
                                    <span className="slug">@team-a277235.engage.com</span>
                                  </div>
                                </div>

                                <div className="email_integration_addr">
                                  <div>
                                    <span className="color_blue  pulse_id">board-(#ID)-</span>
                                    <span className="bold">187f0176a5312ae5bf7e__17881025</span>
                                    <span className="slug">@team-a277235.engage.com</span>
                                  </div>
                                </div>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div className="card collapse-header">
                        <div id="headingCollapse6" className="card-header" data-toggle="collapse" role="button" data-target="#collapse6"
                          aria-expanded="false" aria-controls="collapse6">
                          <span className="collapse-title">
                            {/* <i className="bx bx-heart align-middle"></i> */}
                            <span className="align-middle">Sessions</span>
                          </span>
                        </div>
                        <div id="collapse6" role="tabpanel" aria-labelledby="headingCollapse2" className="collapse show">
                          <div className="card-content">
                            <div className="card-body">
                              <div className="row">
                                <div className="user-summary-details">
                                  <div className="session-details">
                                    <span>2 Sessions</span>
                                  </div>

                                  <div className="recent-session">
                                    <span><i className="bx bx-time" style={{ verticalAlign: 'sub' }}></i> Recent session Jan 7, 2021 11:44 AM</span>
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <table className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>Device</th>
                                      <th>Location</th>
                                      <th>Last Usage</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>

                                    <tr>
                                      <td>Mac Chrome</td>
                                      <td>Abu Dhabi, Abu Dhabi, AE <br />86.98.151.195</td>
                                      <td>Jan 7, 2021 11:44 AM<br />7 minutes ago</td>

                                      <td><button type="button" className="btn btn-primary round mr-1 mb-1" style={{ borderRadius: '20px' }} data-toggle="tooltip" data-title="You will be logged out of this session & will need to login again" data-placement="left" >Signout</button></td>

                                    </tr>

                                    <tr>
                                      <td>Mac Chrome</td>
                                      <td>Abu Dhabi, Abu Dhabi, AE<br />86.98.151.195</td>
                                      <td>Jan 7, 2021 11:44 AM<br />7 minutes ago</td>

                                      <td><button type="button" className="btn btn-primary round mr-1 mb-1" style={{ borderRadius: '20px' }} data-toggle="tooltip" data-title="You will be logged out of this session & will need to login again" data-placement="left"  >Signout</button></td>


                                    </tr>


                                    <tr>
                                      <td>Mac Chrome</td>
                                      <td>Abu Dhabi, Abu Dhabi, AE<br />86.98.151.195</td>
                                      <td>Jan 7, 2021 11:44 AM<br />7 minutes ago</td>

                                      <td><button type="button" className="btn btn-primary round mr-1 mb-1" style={{ borderRadius: '20px' }} data-toggle="tooltip" data-title="You will be logged out of this session & will need to login again" data-placement="left"  >Signout</button></td>
                                    </tr>




                                  </tbody>
                                </table>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>


                  <div className="modal fade" id="avatarmodal" tabIndex="-1" role="dialog"
                    aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-centered modal-dialog-scrollable"
                      role="document">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title" id="exampleModalCenterTitle">Change Profile Picture</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="bx bx-x"></i>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div style={{ display: 'flex', justifyContent: 'space-between', padding: '20px' }}>
                            <img className="profile-av" src="../images/Profile.png" alt="Name" />

                            <div className="file btn btn-lg btn-primary file-div">
                              <i className="bx bx-upload" style={{ verticalAlign: 'text-top', marginRight: '5px' }}></i>Upload your Profile Pic
						              	<input type="file" name="file" className="avatar-file-upload" style={{ cursor: 'pointer' }} />
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>

                  </div>


                </div>




              </div>

            </div>
          </div>
        </div>
      </>

    );
  }
}

export default UserProfile;