import React from 'react';
import { connect } from 'react-redux'
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { addGroup, fetchError, showMessage, intialize } from '../../../../../actions';
import { initialWorkspaceBoardGroupData } from '../../../../../constants/Dump';
import { makeid, initialGroupCellItemBody } from '../../../../../util';
import { exportExcelHeader, exportGroup } from '../../../../../util/ExportToExcel';
import axios from '../../../../../util/Api';
import ReactExport from 'react-data-export';

import { getMyPermission } from '../../../../../util/permission';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class BoardFirstEllipsis extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boardType: '',
            openModal: false,
            boardTitle: '',
            actionPermission: 'edit'
        }

        this.removeModalContainer = React.createRef();
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    componentDidMount() {
        if (this.props.boardData.boardID !== '') {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardType = workspaceBoard.boardType ? workspaceBoard.boardType : 'main'
            this.setState({ boardType: boardType });
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            console.log("permission: ", permission)
            if (permission === 'super') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
          //  console.log('isWorkspaceOwner',this.props.isWorkspaceOwner)
        }
       
    }

    componentDidUpdate(prevProps, prevStates) {
        if (prevProps.boardData.workspaceBoard.boardType && prevProps.boardData.workspaceBoard.boardType !== this.props.boardData.workspaceBoard.boardType) {
            this.setState({ boardType: this.props.boardData.workspaceBoard.boardType })
        }

        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {

            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
           
        }
       
    }

    addGroup = () => {
        const boardID = this.props.boardData.boardID;
        if (boardID !== '') {
            const group = this.props.boardData.data;
            let firstGroup;
            if (group.length > 0) {
                firstGroup = group[0];
            } else {
                firstGroup = initialWorkspaceBoardGroupData[0];
            }

            const newGroupItemHeader = firstGroup.groupItems[0];

            const groupItemHeaderCellItems = [];
            const groupItemBodyCellITems = [];
            for (let i = 0; i < newGroupItemHeader.cellItems.length; i++) {
                let body = initialGroupCellItemBody(newGroupItemHeader.cellItems[i].key);
                // console.log("body===>", body);
                let newCellHeader = { _id: makeid('col', 7), key: newGroupItemHeader.cellItems[i].key, body: newGroupItemHeader.cellItems[i].body, columnId: newGroupItemHeader.cellItems[i].columnId }
                let newCellItem = { _id: makeid('col', 7), key: newGroupItemHeader.cellItems[i].key, body: body, columnId: newGroupItemHeader.cellItems[i].columnId };
                groupItemHeaderCellItems.push(newCellHeader);
                groupItemBodyCellITems.push(newCellItem);
            }

            const newGroup = {
                _id: makeid('group', 3),
                label: 'New Group',
                groupItems: [{
                    _id: makeid('row', 5),
                    title: "New Item",
                    category: "header",
                    cellItems: groupItemHeaderCellItems
                }, {
                    _id: makeid('row', 5),
                    title: "New Item",
                    category: 'body',
                    cellItems: groupItemBodyCellITems
                }]
            }

            // console.log("newGroup==>", newGroup);
            // console.log("boardID==>", boardID)
            this.props.addGroup({
                boardID: boardID,
                newGroup: newGroup
            })
        }
    }

    removeBoard = () => {
        this.setState({ openModal: false })
        axios.post('workspace/removeWorkspaceBoard', {
            boardId: this.props.boardData.boardID
        }).then(({ data }) => {
            this.props.showMessage(`${data.boardName} deleted successfully!`);
            this.props.intialize()
        }).catch(err => {
            this.props.fetchError(err)
        })
    }

    exportBoardToExcel = () => {
        debugger;
        const groups = this.props.boardData?.data;
        const multiDataSet = [
            ...exportExcelHeader(this.props.boardData?.boardTitle, this.props.boardData?.boardDescription),
        ];

        for (var groupInd in groups) {
            multiDataSet.push(...exportGroup(groups[groupInd]));
        }

        return multiDataSet;
    }

    render() {
        return (
            <div className="dropdown" >
                <button className="btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i id="dot-icon-workspace-one" className="bx bx-dots-horizontal-rounded dot-icons" ></i>
                </button>
                <ul className="dropdown-menu multi-level animate slideIn board-first-ellipsis-dropdown" role="menu" aria-labelledby="dropdownMenu" style={{ fontFamily: 'myriad-pro', fontWeight: '300 !important' }}>
                    <li className="dropdown-item">
                        <div onClick={() => {
                            if (this.state.actionPermission === 'edit') {
                                this.addGroup();
                            } else {
                                this.props.fetchError("You don't have permission. Only you can view!")
                            }
                        }} style={{ cursor: 'pointer' }}>
                            <i className="fal fa-plus" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Add Group</div></li>
                    <li className="dropdown-item"  >
                        <div onClick={(event) => {
                            if (this.state.actionPermission === 'edit') {
                                this.props.boardMember(event)
                            } else {
                                this.props.fetchError("You don't have permission. Only you can view!")
                            }
                        }} style={{ cursor: 'pointer' }}>
                            <i className="fal fa-user-plus" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Board Members</div>
                    </li>
                    <li className="dropdown-item">
                        <div onClick={() => this.props.boardActivity()} style={{ cursor: 'pointer' }}>
                            <i className="fal fa-random" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Board Activity</div>
                    </li>
                   {(this.props.isWorkspaceAdmin || this.props.isWorkspaceOwner || this.props.isBoardAdmin) && <li className="dropdown-item">
                        <div onClick={() => {
                            if (this.state.actionPermission === 'edit') {
                                this.props.boardPermission()
                            } else {
                                this.props.fetchError("You don't have permission. Only you can view!")
                            }
                        }} style={{ cursor: 'pointer' }}>
                            <i className="fal fa-lock-alt" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Board Permission</div>
                    </li>}
                   
                    {(this.props.isWorkspaceAdmin || this.props.isWorkspaceOwner || this.props.isBoardAdmin) &&<li className="dropdown-submenu">
                        <a className="dropdown-item" tabIndex="-1" style={{ textTransform: 'none' }}>
                            <i className="fal fa-cog" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Board Settings</a>
                        <ul className="dropdown-menu" style={{ 'margin-left' : '-390px' , 'margin-top' : '-45px' }}>
                            <li className="dropdown-item" >
                                <div tabIndex="-1" style={{ cursor: 'pointer', width: '100%' }}
                                    onClick={() => {
                                        if (this.state.actionPermission === 'edit') {
                                            this.props.renameBoard()
                                        } else {
                                            this.props.fetchError("You don't have permission. Only you can view!")
                                        }
                                    }}>
                                    <i className="fal fa-pencil-alt" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Rename Board</div>
                            </li>
                            <li className="dropdown-submenu">
                                <a className="dropdown-item" style={{ textTransform: 'none' }}><i className="fal fa-exchange-alt" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;<span style={{ 'color' : '#000' }}> Change Board Type </span> &nbsp;&nbsp;</a>
                                <ul className="dropdown-menu" style={{  'margin-left' : '-412px' ,  'margin-top' : '-45px' }}>
                                    {this.state.boardType === 'sharable' ? (
                                        <li className="dropdown-item">
                                            <div onClick={() => {
                                                if (this.state.actionPermission === 'edit') {
                                                    this.props.changeBoardType('main')
                                                } else {
                                                    this.props.fetchError("You don't have permission. Only you can view!")
                                                }
                                            }} style={{ cursor: 'pointer', width: '100%' }} >
                                                <i className="fa fa-bars " style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Change to Main</div>
                                        </li>
                                    ) : (
                                            <li className="dropdown-item">
                                                <div onClick={() => {
                                                    if (this.state.actionPermission === 'edit') {
                                                        this.props.changeBoardType('sharable')
                                                    } else {
                                                        this.props.fetchError("You don't have permission. Only you can view!")
                                                    }
                                                }} style={{ cursor: 'pointer', width: '100%' }}>
                                                    <i className="fal fa-share-alt" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Change to Shareable</div>
                                            </li>
                                        )}

                                    {/* <li className="dropdown-submenu"><a className="dropdown-item" href="#">another level</a>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item"><a href="#">Change to Shareable</a></li>
                                <li className="dropdown-item"><a href="#">Change to Private</a></li>
                            </ul>
                          </li> */}
                                    {this.state.boardType === 'private' ? (
                                        <li className="dropdown-item">
                                            <div onClick={() => {
                                                if (this.state.actionPermission === 'edit') {
                                                    this.props.changeBoardType('main')
                                                } else {
                                                    this.props.fetchError("You don't have permission. Only you can view!")
                                                }
                                            }} style={{ cursor: 'pointer', width: '100%' }}><i className="fa fa-bars" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Change to Main</div>
                                        </li>
                                    ) : (
                                            <li className="dropdown-item">
                                                <div onClick={() => {
                                                    if (this.state.actionPermission === 'edit') {
                                                        this.props.changeBoardType('private')
                                                    } else {
                                                        this.props.fetchError("You don't have permission. Only you can view!")
                                                    }
                                                }} style={{ cursor: 'pointer', width: '100%' }} ><i class="fal fa-lock" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Change to Private</div>
                                            </li>
                                        )}

                                </ul>
                            </li>
                            {/* <li className="dropdown-item" data-toggle="modal" data-target="#email-preference"><a href="#"><i className="bx bx-mail-send" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Email Preference</a></li> */}
                            <li className="dropdown-item">
                                <div onClick={() => {
                                    if (this.state.actionPermission === 'edit') {
                                        this.props.boardItemTerminology()
                                    } else {
                                        this.props.fetchError("You don't have permission. Only you can view!")
                                    }
                                }} style={{ cursor: 'pointer', width: '100%' }}><i class="fal fa-address-card" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Change Item Terminology</div>
                            </li>
                        </ul>
                    </li>
    }
                    <li className="dropdown-submenu">
                        <a className="dropdown-item" tabIndex="-1" style={{ textTransform: 'none' }}><i class="fal fa-ellipsis-h-alt" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;More Actions&nbsp;&nbsp;</a>
                        <ul className="dropdown-menu" style={{ 'margin-left' : '-378px' , 'margin-top' : '-40px'  }}>
                            {/* <li className="dropdown-item">
                                <a tabIndex="-1" href="#">
                                    <i className="bx bxs-save" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Save as a Template</a>
                            </li> */}
                            <li className="dropdown-submenu">
                                <a className="dropdown-item" style={{ textTransform: 'none' }}><i className="fal fa-clipboard" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;<span style={{ 'color' : '#000' }}>Export Board to Excel</span> &nbsp;&nbsp;&nbsp;&nbsp;</a>
                                <ul className="dropdown-menu" style={{ 'margin-left' : '-355px' , 'margin-top' : '-40px' }}>
                                    {/* <li className="dropdown-item" onClick={() => {
                                        this.exportBoardToExcel()}
                                    }>
                                        <a><i className="bx bx-table" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Export Table</a>
                                    </li> */}
                                    <ExcelFile element={
                                        <div className="dropdown-item btn-group-action" style={{ cursor: 'pointer' }}>
                                            <a ><i className="fal fa-table" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Export Table</a>
                                            {/* <i className="fa fa-file-excel-o" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>
                                            Export to Excel */}
                                        </div>
                                    }>
                                        <ExcelSheet dataSet={this.exportBoardToExcel()} name="Organization" />
                                    </ExcelFile>
                                    {/* <li className="dropdown-submenu"><a className="dropdown-item" href="#">another level</a>
                            <ul className="dropdown-menu">
                                <li className="dropdown-item"><a href="#">Change to Shareable</a></li>
                                <li className="dropdown-item"><a href="#">Change to Private</a></li>
                            </ul>
                          </li> */}
                                    {/* <li className="dropdown-item"><a href="#"><i className="bx bx-columns" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Export Table & Updates</a></li> */}
                                </ul>
                            </li>
                            <li className="dropdown-item">
                                <div onClick={() => {
                                    if (this.state.actionPermission === 'edit') {
                                        this.props.boardDuplicate()
                                    } else {
                                        this.props.fetchError("You don't have permission. Only you can view!")
                                    }
                                }} style={{ cursor: 'pointer', width: '100%' }}>
                                    <i className="fal fa-copy"  style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Duplicate Board</div></li>
                            <li className="dropdown-item">
                                <div onClick={() => {
                                    if (this.state.actionPermission === 'edit') {
                                        this.props.print()
                                    } else {
                                        this.props.fetchError("You don't have permission. Only you can view!")
                                    }
                                }} style={{ cursor: 'pointer', width: '100%' }}>
                                    <i className="fal fa-print" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Print Board</div></li>
                            <li className="dropdown-item">
                                <div onClick={() => this.props.fullScrean()} style={{ cursor: 'pointer', width: '100%' }}>
                                    <i className="fal fa-expand-arrows-alt" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Full Screen</div>
                            </li>
                        </ul>
                    </li>
               
                    {(this.props.isWorkspaceAdmin || this.props.isWorkspaceOwner || this.props.isBoardAdmin) &&  <li className="dropdown-item">
                        <div onClick={() => {
                            if (this.props.boardData.boardID !== '') {
                                if (this.state.actionPermission === 'edit') {
                                    this.setState({ openModal: true })
                                } else {
                                    this.props.fetchError("You don't have permission. Only you can view!")
                                }
                            }
                        }} style={{ cursor: 'pointer', width: '100%' }}>
                            <i className="fal fa-trash-alt" style={{ verticalAlign: 'middle', color: '#000000' }}></i>&nbsp;&nbsp;Delete Board</div>
                    </li> }
                </ul>
                {this.state.openModal ? (
                    <Modal isOpen={this.state.openModal} ref={this.removeModalContainer}>
                        <ModalHeader toggle={() => this.setState({ openModal: false })}>
                            Delete {this.props.boardData.boardTitle}?
                    </ModalHeader>
                        <ModalFooter>
                            <button className='btn btn-light' onClick={() => { this.setState({ openModal: false }) }}>Cancel</button>{' '}
                            <button className='btn btn-primary'
                                onClick={() => this.removeBoard()}
                            >Delete</button>
                        </ModalFooter>
                    </Modal>
                ) : ''}

            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData };
};

const mapDispatchToProps = { addGroup, fetchError, showMessage, intialize };

export default connect(mapStateToProps, mapDispatchToProps)(BoardFirstEllipsis);