import React from 'react';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { changeBoardNumbersUnit, changeBoardNumberDirection, changeBoardNumberActionType } from '../../../../../actions';
import {
    sumArrayItems,
    averageArrayItems,
    medianArrayItems,
    minArrayItems,
    maxArrayItems,
    countArrayItems,
} from '../../../../../util'
import { getBoardGroupFormulaCellItemsArray, getBoardFormulaCellItemsArray } from '../../../../../util/bottom';

class FormulaBottom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: '-',
            overral: '',
            openEdit: false,
            ownUnit: '',
            numbers: null,
            unit: 'none',
            direction: 'left',
            action: 'none'
        }
        this.container = React.createRef();
        this.timeout = 0;
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.openEdit && !this.container.current.contains(ev.target)) this.setState({ openEdit: false });
    }
    componentDidMount() {
        let numbersProperties = this.props.boardData.numbersProperties;
        if (numbersProperties.length > 0) {
            let property = numbersProperties.find(x => x.columnId === this.props.columnId);
            if (property !== undefined) {
                this.setState({
                    unit: property.unit,
                    direction: property.direction,
                    action: property.action
                })
            }
        }
        window.addEventListener('click', this.onClickOutsideHandler);
        this.changeNumberButtomContent()
    }

    componentWillReceiveProps(nextProps) {
        let numbersProperties = nextProps.boardData.numbersProperties;
        if (numbersProperties.length > 0) {
            let property = numbersProperties.find(x => x.columnId === this.props.columnId);
            // console.log("property", property)
            if (property !== undefined) {
                // console.log("property.action===>", property.action)
                this.setState({
                    unit: property.unit,
                    direction: property.direction,
                    action: property.action
                })
            }
        }
        setTimeout(() => {
            this.changeNumberButtomContent()
        }, 10)

    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    changeUnitNumbers = (unit) => {
        this.props.changeBoardNumbersUnit({
            unit: unit,
            columnId: this.props.columnId
        })
    }

    changeNumberUnitByInput = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.changeUnitNumbers(this.state.ownUnit)
        }, 800);
    }

    changeNumberDirection = (direction) => {
        this.props.changeBoardNumberDirection({ direction: direction, columnId: this.props.columnId })
    }

    changeNumberActionType = (actionType) => {
        this.setState({ action: actionType })
        this.props.changeBoardNumberActionType({ actionType: actionType, columnId: this.props.columnId });
    }

    changeNumberButtomContent = () => {
        let numbers = getBoardGroupFormulaCellItemsArray(this.props.groupItems, this.props.columnId);
        // console.log("numbers", numbers)
        let overallNumbers = getBoardFormulaCellItemsArray(this.props.boardData.data, this.props.columnId);
        // console.log("overallNumbers", overallNumbers)
        let checkNumbers = overallNumbers.filter(number => isNaN(parseFloat(number)));
        if (checkNumbers.length === 0) {
            // console.log("this.state.action", this.state.action)
            let bottomNumberContent = this.getValueByFunctions(numbers, this.state.action);
            let overallNumberContent = this.getValueByFunctions(overallNumbers, this.state.action);
            this.setState({ numbers: numbers, content: bottomNumberContent, overral: overallNumberContent })
        } else {
            this.setState({ numbers: [], content: 'Error', overral: [] })
        }


    }

    getValueByFunctions = (numbers, key) => {
        switch (key) {
            case 'sum':
                return sumArrayItems(numbers);
            case 'average':
                return averageArrayItems(numbers);
            case 'median':
                return medianArrayItems(numbers);
            case 'min':
                return minArrayItems(numbers);
            case 'max':
                return maxArrayItems(numbers);
            case 'count':
                return countArrayItems(numbers);
            default:
                return '-';
        }
    }

    render() {
        // console.log("number bottom===>", this.props)
        return (
            <div className='number-bottom w-100' ref={this.container}>
                <div className='border text-center'
                    style={{ fontSize: '12px', backgroundColor: '#e6e9ef', cursor: 'pointer' }}
                    onClick={() => this.setState({ openEdit: !this.state.openEdit })}
                >
                    {this.state.action === 'none' ? (<span>-</span>) : this.state.content === 'Error' ? (
                        (<span style={{ color: '#ff0000' }}>N/A</span>)
                    ) :
                        this.state.action === 'count' ? (
                            <span>{this.state.content}</span>
                        ) :
                            this.state.direction === 'left' ? (
                                <span>
                                    {this.state.action === 'none' ? '' :
                                        this.state.unit !== 'none' ?
                                            `${ReactHtmlParser(this.state.unit)} ` :
                                            ''} {Math.round(parseFloat(this.state.content) * 1000) / 1000}
                                </span>
                            ) :
                                (
                                    <span>
                                        {Math.round(parseFloat(this.state.content) * 1000) / 1000} {this.state.action === 'none' ? '' :
                                            this.state.unit !== 'none' ? ` ${ReactHtmlParser(this.state.unit)}` : ''}
                                    </span>
                                )
                    }
                    <br />
                    <span>{this.state.action}</span>
                </div>
                <div className={`number-bottom-change ${this.state.openEdit ? 'show' : ''}`}>
                    <div className='p-2'>
                        <div className='p-2'>
                            <div className='change-title'>
                                Unit
                            </div>
                            <div className='mt-2'>
                                <div className='change-action'>
                                    <div className={`number-unit-item border w-100 p-1 ${this.state.unit === 'none' ? 'active' : ''}`} style={{ borderRadius: '5px 0 0 5px' }} onClick={() => this.changeUnitNumbers('none')}>
                                        <span>None</span>
                                    </div>
                                    <div className={`number-unit-item border w-100 p-1 ${this.state.unit === '$' ? 'active' : ''}`} onClick={() => this.changeUnitNumbers('$')}>
                                        <span>$</span>
                                    </div>
                                    <div className={`number-unit-item border w-100 p-1 ${this.state.unit === '&euro;' ? 'active' : ''}`} onClick={() => this.changeUnitNumbers('&euro;')}>
                                        <span>&euro;</span>
                                    </div>
                                    <div className={`number-unit-item border w-100 p-1 ${this.state.unit === '&#8356;' ? 'active' : ''}`} onClick={() => this.changeUnitNumbers('&#8356;')}>
                                        <span>&#8356;</span>
                                    </div>
                                    <div className={`number-unit-item border w-100 p-1 ${this.state.unit === '%' ? 'active' : ''}`} onClick={() => this.changeUnitNumbers('%')}>
                                        <span>%</span>
                                    </div>
                                    <div className={`number-unit-item border w-100 p-1`} style={{ marginRight: '20px', borderRadius: '0 5px 5px 0' }}>
                                        <input type='text'
                                            className='number-unit'
                                            placeholder='Type your own'
                                            style={{ maxWidth: '110px' }}
                                            value={this.state.ownUnit}
                                            onChange={(event) => this.setState({ ownUnit: event.target.value })}
                                            onKeyUp={() => this.changeNumberUnitByInput()}
                                        />
                                    </div>
                                    <div className={`number-unit-item border w-100 p-1 ${this.state.direction === 'left' ? 'active' : ''}`}
                                        style={{ borderRadius: '5px 0 0 5px' }}
                                        onClick={() => this.changeNumberDirection('left')}
                                    >
                                        <span>L</span>
                                    </div>
                                    <div className={`number-unit-item border w-100 p-1 ${this.state.direction === 'right' ? 'active' : ''}`}
                                        style={{ borderRadius: '0 5px 5px 0' }}
                                        onClick={() => this.changeNumberDirection('right')}
                                    >
                                        <span>R</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='p-2'>
                            <div className='change-title'>
                                Function
                            </div>
                            <div className='mt-2'>
                                <div className='change-action'>
                                    <div className={`number-unit-item border p-1 w-100 ${this.state.action === 'none' ? 'active' : ''}`}
                                        style={{ borderRadius: '5px 0 0 5px' }}
                                        onClick={() => this.changeNumberActionType('none')}
                                    >
                                        <span>None</span>
                                    </div>
                                    <div className={`number-unit-item border p-1 w-100 ${this.state.action === 'sum' ? 'active' : ''}`}
                                        onClick={() => this.changeNumberActionType('sum')}
                                    >
                                        <span>Sum</span>
                                    </div>
                                    <div className={`number-unit-item border p-1 w-100 ${this.state.action === 'average' ? 'active' : ''}`}
                                        onClick={() => this.changeNumberActionType('average')}
                                    >
                                        <span>Average</span>
                                    </div>
                                    <div className={`number-unit-item border p-1 w-100 ${this.state.action === 'median' ? 'active' : ''}`}
                                        onClick={() => this.changeNumberActionType('median')}
                                    >
                                        <span>Median</span>
                                    </div>
                                    <div className={`number-unit-item border p-1 w-100 ${this.state.action === 'min' ? 'active' : ''}`}
                                        onClick={() => this.changeNumberActionType('min')}
                                    >
                                        <span>Min</span>
                                    </div>
                                    <div className={`number-unit-item border p-1 w-100 ${this.state.action === 'max' ? 'active' : ''}`}
                                        onClick={() => this.changeNumberActionType('max')}
                                    >
                                        <span>Max</span>
                                    </div>
                                    <div className={`number-unit-item border p-1 w-100 ${this.state.action === 'count' ? 'active' : ''}`}
                                        style={{ borderRadius: '0 5px 5px 0' }}
                                        onClick={() => this.changeNumberActionType('count')}
                                    >
                                        <span>Count</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='p-2'>
                        <div className='p-2 border-top'>
                            {this.state.action === 'none' ?
                                (
                                    <div>Overall summary of column: -</div>
                                ) :
                                (
                                    <div>Overall { this.state.action} of column: &nbsp;
                                        {this.state.action === 'count' ?
                                            (<span>
                                                {this.state.overral}
                                            </span>) :
                                            this.state.direction === 'left' ? (
                                                <span>
                                                    {this.state.action === 'none' ? '' :
                                                        this.state.unit !== 'none' ?
                                                            `${ReactHtmlParser(this.state.unit)} ` :
                                                            ''} {Math.round(parseFloat(this.state.overral) * 1000) / 1000}
                                                </span>
                                            ) :
                                                (
                                                    <span>
                                                        {Math.round(parseFloat(this.state.overral) * 1000) / 1000} {this.state.action === 'none' ? '' :
                                                            this.state.unit !== 'none' ? ` ${ReactHtmlParser(this.state.unit)}` : ''}
                                                    </span>
                                                )
                                        }

                                    </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeBoardNumbersUnit, changeBoardNumberDirection, changeBoardNumberActionType };

export default connect(mapStateToProps, mapDispatchToProps)(FormulaBottom);
