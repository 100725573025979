
import React from 'react';
import { connect } from 'react-redux';
import { changeBoardItem, updateCellTagsSuggestion, fetchError, updateMessageReminder } from '../../../actions';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../util/permission';
import DatePicker from "react-datepicker";
import './style.css'
import moment from 'moment';
import axios from '../../../util/Api';
import { toast } from 'react-toastify';
class MessageReminder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            
            open: false,
            suggestions: [],
            actionPermission: 'edit',
            dateTime: new Date(),
            frequency: '',
            users: [],
            isEmailNotification: false
        }

        this.dropdownContainer = React.createRef();
        this.changeFrequncy = this.changeFrequncy.bind(this);
        this.changeUsers = this.changeUsers.bind(this);
        this.chnageEmailnotifyStatus = this.chnageEmailnotifyStatus.bind(this);
    }

    onClickOutsideHandler = (ev) => {
       
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false });
        }
    }
    componentDidMount() {
        
        // if (this.props.boardData.boardID) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);

        //     let permission = getMyPermission(boardPermission, members);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
        if(this.props.reminderID){
          
            axios.post('/workspace/getReminderById', {
                reminderID: this.props.reminderID
              }).then(({ data }) => {
                  console.log('dddd11',data)
                  this.setState({ dateTime: data.reminder.reminderDate })
                  this.setState({ frequency: data.reminder.frequency })
                  this.setState({ isEmailNotification: data.reminder.isEmailNotification })
                  this.setState({ users: data.reminder.users })
              })
        }
       
    }

    componentDidUpdate(prevProps, prevStates) {
        // if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
   
        //     let permission = getMyPermission(boardPermission, members);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    componentWillReceiveProps(nextProps) {

    }


    changeFrequncy = (event) => {
        this.setState({ frequency: event.target.value });
    }

    chnageEmailnotifyStatus = (event) => {
        this.setState({ isEmailNotification: !this.state.isEmailNotification });
    }

    changeUsers = (event) => {
        
        const member = event.target
        const memberID = member.value
        let reminderUsers = this.state.users
        if (this.state.users ? this.state.users.includes(memberID) : false) {

            for (var i = reminderUsers.length - 1; i >= 0; i--) {
                if (reminderUsers[i] == memberID) reminderUsers.splice(i, 1);
            }

            event.target.checked = false;
        } else {
            reminderUsers.push(memberID);
            event.target.checked = true;
        }

        this.setState({ users: reminderUsers });


//JSON.parse(this.props.body.users)
        // this.setState({ users: event.target.value });

    }


    saveReminder = (e) => {

        let reminderObj ={
            reminderDate:  moment(this.state.dateTime).format(),
            frequency: this.state.frequency,
            groupInd: this.props.groupInd,
            rowInd: this.props.rowInd,
            boardId: this.props.boardID,
            users: this.state.users,
            messageID:this.props.messageID,
            isEmailNotification: this.state.isEmailNotification
            
        }
        this.props.updateMessageReminder(reminderObj)
        toast.success("Reminder updated.");
        this.setState({ open: false });
        e.preventDefault();

    }

    render() {
console.log("this.props.boardMembers",this.props.boardMembers)
        return (
          
                    <div className="row">
                        <div className="col-xl-6">
                            <label>Time</label>
                            <DatePicker
                                selected={this.state.dateTime ? new Date(this.state.dateTime) : new Date()}
                                showTimeSelect
                                dateFormat="Pp"
                                onChange={date => {
                                    console.log(moment(Date.parse(date)).utc())
                                    this.setState({ dateTime: new Date(date) })
                                }}
                                minDate={new Date()} 
                            />
                        </div>
                        <div className="col-xl-6">
                            <div class="form-group">
                                <label>Frequency</label>
                                <select class="form-control" value={this.state.frequency} onChange={this.changeFrequncy}>
                                    <option value="today">Does Not Repeat</option>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div class="form-group">
                                {/*  <label>Users</label>
                              
                               <select class="form-control" value={this.state.users} onChange={this.changeUsers}>
                                    <option value="assignee">Assignee</option>
                                    <option value="followers">Followers</option>
                                    <hr></hr>

                                    {
                                        JSON.parse(this.props.boardData.workspaceBoard.members)
                                            .map((member, index) => (
                                                <option key={index} value={member._id}>{member.name}</option>
                                            ))
                                    }
                                </select> */}
                                 <form>
                                <div class="dropup dropdownh">
                                    <button type="button" class="btn btn-outline-secondary btn-sm btn-block dropdown-toggle" data-toggle="dropdown">
                                        Choose Users
                            </button>
                                   
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item">
                                            <label class="form-check-label">
                                                                <input
                                                                    className="form-check-input cursor-pointer"
                                                                    type="checkbox"
                                                                    checked={(this.state?.users ? this.state?.users?.includes('assignee') : false)}
                                                                    onChange={this.changeUsers}
                                                                    value="assignee"
                                                                />
                                                                Assignee
                                                            </label>
                                                </a>
                                            <a class="dropdown-item">
                                            <label class="form-check-label">
                                                                <input
                                                                    className="form-check-input cursor-pointer"
                                                                    type="checkbox"
                                                                    checked={(this.state?.users ? this.state?.users?.includes('followers') : false)}
                                                                    onChange={this.changeUsers}
                                                                    value="followers"
                                                                />
                                                                Followers
                                                            </label>
                                                            </a>

                                            <div class="dropdown-divider"></div>
                                            {
                                                JSON.parse(this.props.boardMembers)
                                                    .map((member, index) => (
                                                        <a class="dropdown-item" key={index}>
                                                            <label class="form-check-label">
                                                                <input
                                                                    className="form-check-input cursor-pointer"
                                                                    type="checkbox"
                                                                    checked={(this.state?.users ? this.state?.users?.includes(member._id) : false)}
                                                                    onChange={this.changeUsers}
                                                                    value={member._id}
                                                                />
                                                                {member.name}
                                                            </label>
                                                        </a>
                                                    ))
                                            }

                                        </div>
                                   
                                </div>

                                </form>
                            </div>
                        </div>
                        <div className="col-xl-6">
                        <div class="form-check">
                        <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" value="" 
                             className="form-check-input cursor-pointer"
                             type="checkbox"
                             checked={(this.state.isEmailNotification ? this.state.isEmailNotification : false)}
                             onChange={this.chnageEmailnotifyStatus}
                           
                              
                            /> Email Notification
                        </label>
                        </div>
                        </div>
                        <div className="col-xl-12 mb-2">
                            <button className="btn btn-danger btn-sm float-right d-none"
                                onClick={(e) => { this.setState({ open: false })
                                e.preventDefault(e);

                            }}
                            >Close</button>
                            <button className="btn btn-primary btn-sm mr-2 float-right"
                                onClick={(e) => { this.saveReminder(e) }}

                            >Save</button>

                        </div>
                    </div>

        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeBoardItem, updateCellTagsSuggestion, fetchError, updateMessageReminder }

export default connect(mapStateToProps, mapDispatchToProps)(MessageReminder);