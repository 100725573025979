import React, { Component } from 'react';
class AddChatUser extends Component {
   state = {}
   render() {
      return (

         <>
            {/* Vertically Centered modal Modal */}
            <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
               <div className="modal-dialog modal-dialog-centered modal-dialog-centered modal-dialog-scrollable" role="document">
                  <div className="modal-content">
                     <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalCenterTitle">Add Users</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                           <i className="bx bx-x" />
                        </button>
                     </div>
                     <div className="modal-body" style={{ padding: '20px 50px' }}>
                        <div className="row">
                           <div className="col-md-12" style={{ justifyContent: 'space-between', padding: '0' }}>
                              <input type="text" className="search-dashboard" placeholder="Search name" style={{ width: '100%' }} />

                              <div className="row" style={{ paddingTop: '15px' }}>

                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Jimmy Changa</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>Hi Iam Here</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>04:28 PM</span>
                                    </div>
                                 </div>


                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #6dcd47' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Otto Matic</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>Fine</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>04:10 PM</span>
                                    </div>
                                 </div>


                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Anna Mull</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>Let's do it...</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>03:15 PM</span>
                                    </div>
                                 </div>



                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#575756' }}>Barb Ackue</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>Busy</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>03:07 PM</span>
                                    </div>
                                 </div>


                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Maya Didas</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>Sent</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>02:58 PM</span>
                                    </div>
                                 </div>

                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Peter Pants</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>I will check</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>Yesterday</span>
                                    </div>
                                 </div>

                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Peter Pants</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>I will check</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>Yesterday</span>
                                    </div>
                                 </div>

                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Peter Pants</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>I will check</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>Yesterday</span>
                                    </div>
                                 </div>


                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Peter Pants</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>I will check</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>Yesterday</span>
                                    </div>
                                 </div>



                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Peter Pants</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>I will check</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>Yesterday</span>
                                    </div>
                                 </div>



                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Peter Pants</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>I will check</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>Yesterday</span>
                                    </div>
                                 </div>



                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Peter Pants</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>I will check</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>Yesterday</span>
                                    </div>
                                 </div>



                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Peter Pants</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>I will check</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>Yesterday</span>
                                    </div>
                                 </div>



                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Peter Pants</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>I will check</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>Yesterday</span>
                                    </div>
                                 </div>


                                 <div className="col-md-12 recent-call-section d-flex" style={{ backgroundColor: 'white', borderRadius: '15px', boxShadow: '1px 1px 6px 1px #C5C5C5' }}>
                                    <div className="avatar mr-75">
                                       <span className="avatar-status-online" style={{ width: '10px', height: '10px', position: 'absolute', top: '39px', left: '36px' }} />
                                       <img src="./images/usericon.png" alt="avtar images" style={{ width: '50px', height: '50px', border: '1px solid #F30' }} />
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '18px', marginTop: '11px', width: '140px' }}>
                                       <span style={{ fontSize: '13px', color: '#3c3c3c' }}>Peter Pants</span><br />
                                       <span style={{ fontSize: '11.5px', color: '#3c3c3c', fontWeight: '300' }}>I will check</span>
                                    </div>
                                    <div className="align-items-center" style={{ lineHeight: '14px', marginTop: '15px' }}>
                                       <span style={{ fontSize: '12px', color: '#999' }}>Yesterday</span>
                                    </div>
                                 </div>









                              </div>

                           </div>
                        </div>


                     </div>
                  </div>
               </div>
            </div>




         </>

      );
   }
}

export default AddChatUser;