import React from 'react';

class WorkspaceSlideIcon extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
<>

            <div className="custom-menu">
                <button type="button" id="sidebarCollapse" className="btn btn-primary" style={{ backgroundColor: '#EAEAEA' }}>
                    <i className="fal fa-angle-left ml-1" id="arrow-icon" />
                    <i className="fal fa-angle-right ml-1 right-arrow-icon"  />
                    <span className="sr-only">Toggle Menu</span>
                </button>
            </div>

            <div className="custom-menu1">
            <button type="button" id="navbarCollapse" className="btn arrow-workspace-one" style={{ backgroundColor: '#EAEAEA' , 'borderRadius' : '100px' ,
    'width' : '25px' , 'height' : '25px' , 'cursor' : 'pointer' }}>
                <i className="fal fa-angle-left ml-1 navbar-slide" id="arrow-icon" style={{ transform: 'rotate(90deg)' , 'cursor' : 'pointer' }}/>
                <span className="sr-only">Toggle Menu</span>
            </button>
           </div>

           <div className="custom-menu2" style={{ 'display': 'none' }}>
            <button type="button" id="navbarCollapse1" className="btn arrow-workspace-two" style={{ backgroundColor: '#EAEAEA' , 'borderRadius' : '100px' ,
    'width' : '25px' ,  'height' : '25px' , 'cursor' : 'pointer' }}>
                <i className="fal fa-angle-down ml-1 navbar-slide1" id="arrow-icon" style={{ 'cursor' : 'pointer' }}/>
                <span className="sr-only">Toggle Menu</span>
            </button>
           </div>


            </>
        )
    }
}

export default WorkspaceSlideIcon;