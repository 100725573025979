import React, { useEffect, useState } from 'react'
import DiscoverToolbar from './discover-toolbar';
import DiscoverAddCard from './discover-addcard'
import DiscoverCard from './discover-card';
import { useSelector, useDispatch } from "react-redux";
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

const DiscoverBoard = (props) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const discoverBoard = useSelector(state => state.discover.discoverBoard);
    const discoverSubBoard = useSelector(state => state.discover.discoverSubBoard);
    const discoverGroup = useSelector(state => state.discover.discoverGroup);

    const handleActive = (id) => {
        setActiveIndex(id);
    }

    return (
        <>
            <MainMenu link={`discover-board`} />
            <div id="whole-layout">
                <NavBar />
                <div id="appdata" className='app-content content fixed-top content-lay-out'>
                    <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
                    <div className="content-wrapper">
                        <div id="mySidenav" className="sidenav">
                            <a href="#" className="closebtn" onClick={() => {
                                document.getElementById("mySidenav").style.width = "0%";
                            }}>&times;</a>
                        </div>
                        <div>

                            <DiscoverToolbar></DiscoverToolbar>

                            <div className="content-wrapper">
                                <div className="content-header row" style={{ marginTop: '30px' }}>
                                    <div className="contenBody" style={{ width: '100%' }}>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="card border-0">
                                                    <nav className="nav" style={{ marginLeft: '20px' }}>
                                                        <a className="discover-link" href="/discover" style={{ cursor: 'pointer', color: 'rgb(93 90 90)' }}>Discover </a>
                                                        <a className="discover-link" href="/discover-workspace" style={{ cursor: 'pointer' }} style={{ cursor: 'pointer', color: 'rgb(93 90 90)' }}>Workspaces</a>
                                                        <a className="discover-link" href="/discover-subworkspace" style={{ cursor: 'pointer' }} target="_self" style={{ cursor: 'pointer', color: 'rgb(93 90 90)' }}>Sub Workspace</a>
                                                        <a className="discover-link" href="/discover-board" style={{ cursor: 'pointer' }} style={{ borderTop: '1px solid #000', color: 'rgb(93 90 90)' }}>Board</a>
                                                    </nav>
                                                    <div className="row" style={{ marginTop: '20px' }}>
                                                        <div className="col-2" style={{ paddingLeft: '50px' }}>
                                                            <h6 style={{ color: '#21376c' }}><i className="bx bx-folder" style={{ verticalAlign: 'text-top', color: '#21376c' }}></i>&nbsp;&nbsp;&nbsp;Board</h6>
                                                            <hr />
                                                            {discoverBoard != '' && discoverBoard.data != '' && discoverBoard.data != undefined && discoverBoard.data.map((item, index) => (

                                                                <DiscoverCard handleActive={handleActive} active={item._id === activeIndex ? true : false} {...item} discoverName={'Board'} discoverType={3}  ></DiscoverCard>

                                                            ))}
                                                        </div>
                                                        <div className="col-10" style={{ borderLeft: '1px solid #EAEAEA' }}>
                                                            <div className="collapsible collapse-icon accordion-icon-rotate" style={{ border: 'none', padding: '0px 50px 0px 20px' }}>
                                                                {/* <div className="card collapse-header" style={{ border: 'none' }}>
                                        <div id="headingCollapse6" style={{ border: 'none' }} className="card-header collapsed" data-toggle="collapse" role="button" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                                        <span className="collapse-title">
                                                <span className="align-middle" style={{ fontSize: '14.5px' , color : '#21376c' }}><i className="bx bx-chalkboard" style={{ verticalAlign : 'text-top'  , color : '#21376c'  }}></i>Sub Board</span>
                                            </span>
                                        </div>
                                        <div id="collapse6" role="tabpanel" aria-labelledby="headingCollapse2" className="collapse show" style={{}}>
                                            <div className="card-content">
                                                <div className="card-body" style={{ display: 'inline-flex' }}>
                                                    <DiscoverAddCard parentId={0} parentType={3} discoverName={'Sub Board'} discoverType={4}></DiscoverAddCard>
                                                    {discoverSubBoard != '' && discoverSubBoard.data != '' && discoverSubBoard.data != undefined && discoverSubBoard.data.map((item, index) => (
                                                        <DiscoverCard {...item} discoverName={'Sub Board'} discoverType={4} isRedirect={true}></DiscoverCard>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                                                <div className="card collapse-header" style={{ border: 'none' }}>
                                                                    <div id="headingCollapse6" style={{ border: 'none' }} className="card-header collapsed" data-toggle="collapse" role="button" data-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
                                                                        <span className="collapse-title">
                                                                            <span className="align-middle" style={{ fontSize: '14.5px', color: '#21376c' }}><i className="bx bxs-briefcase" style={{ verticalAlign: 'text-top', color: '#21376c' }}></i>Group</span>
                                                                        </span>
                                                                    </div>
                                                                    <div id="collapse6" role="tabpanel" aria-labelledby="headingCollapse2" className="collapse show" style={{}}>
                                                                        <div className="card-content">
                                                                            <div className="card-body" style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                                                                                {activeIndex !== 0 &&
                                                                                    <DiscoverAddCard parentId={activeIndex} parentType={3} discoverName={'Group'} discoverType={4}></DiscoverAddCard>
                                                                                }
                                                                                {discoverGroup != '' && discoverGroup.data != '' && discoverGroup.data != undefined && discoverGroup.data.map((item, index) => (
                                                                                    <DiscoverCard key={index} parentId={activeIndex} parentType={3} _id={item._id} title={item.label} discoverName={'Group'} discoverType={4} isRedirect={true}></DiscoverCard>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/*<style dangerouslySetInnerHTML={{ __html: "\n    .divactive {\n        border: 5px solid ;\n    }\n" }} /> */}
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default DiscoverBoard;