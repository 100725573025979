import { string } from "joi";

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

export const getInitials = (name) => {
    let initials;
    // if(name == 2){
    //     name = '+';
    // }
    let nameSplit;
    if(name.constructor === String){
     nameSplit = name.split(" "); 
    }else{
    nameSplit = '+'+name.toString();
    }
    
    const nameLength = nameSplit.length;
    if (nameLength > 1) {
        initials =
            nameSplit[0].substring(0, 1) +
            nameSplit[nameLength - 1].substring(0, 1);
    } else if (nameLength === 1) {
        initials = nameSplit[0].substring(0, 1);
    } else return;

    return initials.toUpperCase();
}

export const createImageFromInitials = (size, name, color) => {
    if (name === null) return;
    name = getInitials(name)

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size

    context.fillStyle = "#ececec"
    context.fillRect(0, 0, size, size)

    context.fillStyle = `${color}`
    context.fillRect(0, 0, size, size)

    context.fillStyle = '#ececec';
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.font = `${size / 2}px Roboto`
    context.fillText(name, (size / 2), (size / 2))

    return canvas.toDataURL()
};


export const createFileTypeImages = (size, name, color) => {
    if (name === null) return;
   // name = getInitials(name)
 

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.width = canvas.height = size

    context.fillStyle = "#ececec"
    context.fillRect(0, 0, size, size)

    context.fillStyle = `${color || getRandomColor()}`  
    context.fillRect(0, 0, size, size)

    context.fillStyle = '#ececec';
    context.textBaseline = 'middle'
    context.textAlign = 'center'
    context.font = `18px Roboto`
    context.fillText(name.toUpperCase(), (size / 2), (size / 2))

    return canvas.toDataURL()
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}