
import config from '../../services/config.json';
import CountryDataService from '../../../data-services/country-data-service';

export const Country_GetALL_URL = config.apiEndPoint + CountryDataService.getAllCountry; 
export const Country_GetByID_URL = config.apiEndPoint + CountryDataService.getCountryById; 
export const Country_ADD_URL = config.apiEndPoint + CountryDataService.saveCountry; 
export const Country_Update_URL = config.apiEndPoint + CountryDataService.updateCountry; 
export const Country_DELETE_URL = config.apiEndPoint + CountryDataService.deleteCountry; 

