import React from 'react';

function TaskDistributionWidget(props) {
    const { ShowTaskDistributionWidgetValue } = props
    return (
        <>
            {
                ShowTaskDistributionWidgetValue &&
                <div className="col-md-12 border fourth-second-section" style={{ minHeight: "400px" }}>
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xl-12 mt-2" id="top-section">
                            <div style={{ float: 'left', marginLeft: '15px' }}>
                                <h5 className="left-head">Task Distribution</h5>
                            </div>
                            <div style={{ float: 'right', marginTop: '-2px' }}>
                                <a title="Reply" data-toggle="modal" data-target="#modal-City">
                                    <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <div className="card widget-state-multi-radial" style={{ border: 'none', marginTop: '10px' }}>
                                <div className="card-content">
                                    <div className="card-body" style={{ padding: 0 }}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="text-center">
                                                    <div id="donut-chart" className="d-flex justify-content-center" />
                                                </div>
                                            </div>
                                            <div className="col-md-6 d-flex flex-column justify-content-around">
                                                <div className="tab-content p-1">
                                                    <div className="tab-pane active" id="category" aria-labelledby="category-tab" role="tabpanel">
                                                        <div className="chart-info d-flex justify-content-between mb-75">
                                                            <div className="d-flex align-items-center">
                                                                <i className="bullet bullet-success mr-50" />
                                                                <span className="ml-50" style={{ fontSize: '12px', fontWeight: 300, color: '#2f4475' }}>Ongoing</span>
                                                            </div>
                                                        </div>
                                                        <div className="chart-info d-flex justify-content-between mb-75">
                                                            <div className="d-flex align-items-center">
                                                                <i className="bullet bullet-danger mr-50" />
                                                                <span className=" ml-50" style={{ fontSize: '12px', fontWeight: 300, color: '#2f4475' }}>Ovderdue</span>
                                                            </div>
                                                        </div>
                                                        <div className="chart-info d-flex justify-content-between mb-75">
                                                            <div className="d-flex align-items-center">
                                                                <i className="bullet bullet-primary mr-50" />
                                                                <span className=" ml-50" style={{ fontSize: '12px', fontWeight: 300, color: '#2f4475' }}>Review</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default TaskDistributionWidget;