import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs,
    onClickTag, onSavedSearchDelete, getHotTags
} from '../../../actions/GlobalSearch'
import moment from 'moment'


class PeopleSearch extends Component {
    constructor(props) {
        super(props)
        this.onTagClick = this.onTagClick.bind(this)
        // this.onFileTypeClick = this.onFileTypeClick.bind(this)
        this.state = {
            chkImage: false,
            chkWord: false,
            chkPpt: false,
            chkExcel: false,
            chkPdf: false,
            chkZip: false,
            chkMusic: false,
            chkVideo: false,
            chkOther: false,
            item: [],
            filtervalue: []
        }
    }
    onTagClick = (i, value) => {
        this.props.onClickTag(i, value)
    }
    componentDidMount() {
        this.setState({
            item: this.props.item
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.item !== this.props.item) {

            this.setState({
                item: this.props.item
            })
        }
    }
    render() {

        return <div class="row widget-radial-charts">
            {this.state.item.map((item) => {
                const randomColor = Math.floor(Math.random() * 16777215).toString(16);
                return (
                    <div class="col-sm-3">
                        <div class="card search-card" style={{ 'padding' : '5px' , 'border'  : 'none' }}>
                            <div class="card-body p-0" style={{  boxShadow : 'rgb(234 234 234) 0px 1px 7px 1px'  }}>

                            <div id="radial-chart-primary" style={{ 'height' : '40px' ,
                        'width': '100%' , 
                        justifyContent: 'center' ,
                        'display' : 'flex' }}>
                                        <div id="apexchartsz2tqid4fl" class="apexcharts-canvas apexchartsz2tqid4fl apexcharts-theme-light" style={{  "height": "auto" }}>
                                            <div class="resize-triggers">
                                                <div class="expand-trigger">
                                                    <div>
                                                        <div style={{
                                                            backgroundColor: "#" + randomColor, 'height': '50px', 'width' : '50px' ,
                                                            'margin': '10px', borderRadius: '80px', 
                                                            'color': 'white', fontWeight: 'bold', fontSize: '15px' ,
                                                            'padding' : '14px' 
                                                        }}>
                                                            {item.firstname && item.lastname && item.firstname?.charAt(0).toUpperCase() + item.lastname?.charAt(0).toUpperCase()}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="contract-trigger">
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                <div class="d-lg-flex justify-content-between" style={{ "position": "relative" , textAlign : 'center' }}>
                                    <div class="widget-card-details d-flex" style={{ justifyContent : 'center' , 'padding' : '29px 29px 10px' , 'width' : '100%' }}>
                                        <div>
                                            <h5 class="font-medium-2 font-weight-normal">{item.firstname + ' ' + item.lastname}</h5>
                                            <p class="text-muted mb-0"> <i class="bx bxs-calendar" style={{ 'position' : 'relative' , top : '4px' , marginRight : '5px' }}></i>{moment(item.date).format('ll')}</p>
                                            <p class="text-muted mb-0"> <i class="bx bx-mobile" style={{ 'position' : 'relative' , top : '4px' , marginRight : '5px' }}></i>{item.mobileno}</p>
                                            <p class="text-muted mb-0"> <i class="bx bxs-envelope" style={{ 'position' : 'relative' , top : '4px' , marginRight : '5px' }}></i>  {item.email}</p>
                                        </div>
                                        
                                    </div>
                                  
                                </div >
                            </div >
                        </div >
                    </div>
                )

            })
            }
        </div>
    }
}

const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs, onClickTag,
    onSavedSearchDelete, getHotTags
};

export default connect(mapStateToProps, mapDispatchToProps)(PeopleSearch);