import React from 'react';
import { connect } from 'react-redux';
import ContentEditable from 'react-contenteditable';
import { addColumn, changeBoardColumnStatusProgress } from '../../../../../actions/BoardData';
import { initialGroupCellItemBody } from '../../../../../util/index';
import { getMyPermission } from '../../../../../util/permission';
import _, {debounce} from 'lodash';


class ProgressTrackerCoumnSettings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myPermission: 'super'
        };
        this.updateStatusColumnProgress = this.updateStatusColumnProgress.bind(this);
        this.updateStatusColumnProgressPercentage = this.updateStatusColumnProgressPercentage.bind(this);
        this.percentageEditableRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.boardData.boardID !== '') {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            this.setState({ myPermission: permission })
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {

            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            console.log("permission: ", permission)
            this.setState({ myPermission: permission })
        }
    }

    updateStatusColumnProgress = (event,cellItemID) => {
        
        let statusColInd = this.props.cellItems.findIndex(item => item._id === cellItemID);

        let progressConfig =this.props.cellItems[statusColInd]?.progressConfig?this.props.cellItems[statusColInd]?.progressConfig:{}
       
       
        progressConfig.percentage=progressConfig.percentage?progressConfig.percentage:""

        if (progressConfig?.isEnabled) {

            progressConfig.isEnabled = false
          
            event.target.checked = false;
        } else {
            progressConfig.isEnabled = true
            event.target.checked = true;
        }


        this.props.changeBoardColumnStatusProgress({
            groupInd: this.props.groupInd,
            boardId: this.props.boardData.boardID,
            colInd: statusColInd,
            progressConfig: progressConfig,
            cellItemID: cellItemID
        })
       


    }

    distributePercentageEqually = (event) => {

        const statusItems = this.props.cellItems.filter(cellItem => cellItem.key === "status" && cellItem?.progressConfig?.isEnabled);
        let progressConfig = {}
        let statusColInd
        let equalPercentage = 100/statusItems;

        for (let i = 0; i < statusItems.length; i++) {
            debugger;
            progressConfig =statusItems[i].progressConfig;
            progressConfig.percentage = equalPercentage.toString();
            statusColInd = this.props.cellItems.findIndex(item => item._id === statusItems[i]._id);

            this.props.changeBoardColumnStatusProgress({
                groupInd: this.props.groupInd,
                boardId: this.props.boardData.boardID,
                colInd: statusColInd,
                progressConfig: progressConfig,
                cellItemID: statusItems[i].cellItemID
            })

        }

    }


    updateStatusColumnProgressPercentage = (event,cellItemID) => {
        
        let statusColInd = this.props.cellItems.findIndex(item => item._id === cellItemID);

        let progressConfig =this.props.cellItems[statusColInd]?.progressConfig?this.props.cellItems[statusColInd]?.progressConfig:{}
       
       
        progressConfig.isEnabled=progressConfig.isEnabled?progressConfig.isEnabled:false
        progressConfig.percentage = event.target.innerText

        this.props.changeBoardColumnStatusProgress({
            groupInd: this.props.groupInd,
            boardId: this.props.boardData.boardID,
            colInd: statusColInd,
            progressConfig: progressConfig,
            cellItemID: cellItemID
        })



    }

  
    addColumn = (key) => {
        const boardID = this.props.boardData.boardID;
        const body = initialGroupCellItemBody(key);
        if (this.props.leftIndex !== 'none') {
            this.props.addColumnEvent()
        }
        if (boardID !== "") {
            this.props.addColumn({
                boardID: boardID,
                key: key,
                body: body,
                leftIndex: this.props.leftIndex,
                addType: "add"
            })
        }
    }

    render() {
        return (
            <>
                <p className="mx-2">Select which status columns will affect the progress </p>
                <ul className="list-group list-group-flush">
                    {this.props.cellItems.filter(cellItem => cellItem.key === "status").map((item, index) =>
                        <li class="list-group-item w-100" key={item._id}>
                           
                            <input type="checkbox" className="mr-2" 
                               checked={item?.progressConfig?.isEnabled?true:false}
                              // onChange={this.updateStatusColumnProgress}
                               onChange={(event) => { this.updateStatusColumnProgress(event,item._id) }}
                               value={item?.progressConfig?.isEnabled}
                            />  {item.body}                              
                           
                            <span className="float-right ml-2 text-bold">%</span>
                            <ContentEditable
                 className="float-right ml-4"
                    innerRef={r=>this.percentageEditableRef=r}
                
                    html={item?.progressConfig?.percentage}
      
                    onBlur={event => this.updateStatusColumnProgressPercentage(event,item._id) }
                    style={{ padding: '3px 10px', 'color' : '#000000' , background: "#eeeeee" }}
                />
                            {/* <input type="text" className="float-right ml-4" style={{ width: 50 }} 
                             onChange={
                                (event) => { 
                                    _.debounce(function() {
                                    this.updateStatusColumnProgressPercentage(event,item._id) 
                                }, 1000)
                                }

                                }
                             value={item?.progressConfig?.percentage}
                            /> */}

                        </li>
                    )
                    }

                </ul>

                <div class="form-check ml-3">
                    <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" 
                               onChange={(event) => { this.distributePercentageEqually(event) }}
                               value="" /> Distribute Equally
                    </label>
                </div>

                <button className="btn btn-primary mt-2 ml-3">Save</button>
            </>
        )
    }
}

const mapStateToProps = ({ auth, commonData, boardData }) => {
    return { auth, commonData, boardData }
}

const mapDispatchToProps = { addColumn, changeBoardColumnStatusProgress };

export default connect(mapStateToProps, mapDispatchToProps)(ProgressTrackerCoumnSettings)

