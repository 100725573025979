import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './allrightslide.css'
// import moment from 'moment'
// import $ from 'jquery'
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import FilterDropdown from './borad-data/header/FilterDropdown';
import BoardData from './borad-data/index'
import axios from '../../util/Api';
import {
    getUnreadTaskManagerData
} from '../../actions/TaskManager'

function UnreadTask() {
    // const [resultData, setData] = useState([])
    //const [resultData, setData] = useState([])
    //const [chatRoom, setChatRoom] = useState([])
    const dispatch = useDispatch();
    const [seacrhvalue, setSearchValue] = useState('')
    const taskmanager = useSelector(state => state.taskmanager);
    const { resultData, chatRoom, filterData } = taskmanager
    const [filtervalue, setFilterValue] = useState({})

    useEffect(() => {
        // axios.get(`/search/getUnreadTask/?userid=${JSON.parse(localStorage.getItem('authUser'))._id}`).then(({ data }) => {
        //     console.log("board data==>", data.workSpaceBoard)
        //     setData(data.workSpaceBoard)
        //     setChatRoom(data.chatrooms)
        // }).catch(err => {
        //     console.log("Error:", err)
        // })
        dispatch(getUnreadTaskManagerData(JSON.parse(localStorage.getItem('authUser'))._id));
    }, [dispatch])

    const setSearchData = (value) => {
        setFilterValue(value)
    }

    const getSearchBoardGroupData = (boardDatas) => {
        let newBoardGroups = [];
        let boardData = boardDatas;
        for (let i = 0; i < boardData.length; i++) {

            console.log("roomroomroom", boardDatas)
            let newGroupItems = [];
            let groupItems = boardData[i].groupItems;
            for (let j = 0; j < groupItems.length; j++) {
                console.log('chatRoom', chatRoom)
                console.log('groupItems[j].chatroom ', chatRoom.includes(groupItems[j].chatroom))
                if (groupItems[j].category === 'header') {
                    newGroupItems.push(groupItems[j]);
                } else if (chatRoom.includes(groupItems[j].chatroom)) {
                    newGroupItems.push(groupItems[j]);

                }
            }
            if (newGroupItems.length > 1) {
                let newGroup = boardData[i];
                newGroup.groupItems = newGroupItems;
                newBoardGroups.push(newGroup);
                console.log('newGroupItems', newBoardGroups)
            }

        }
        return newBoardGroups;
    }


    return (
        <>
            <MainMenu link={`tasks`} />
            <div id="whole-layout">
                <NavBar />
                <div id="appdata" className='app-content content fixed-top content-lay-out'>
                    <div class="row">
                        <div class="col-md-4">
                            <fieldset class="form-label-group mb-0 mt-3 ml-3">
                                <input type="text" className="form-control" value={seacrhvalue} onChange={(e) => {
                                    setSearchValue(e.target.value)
                                }} />
                                <label class="user-profile-label">Search in task</label>
                            </fieldset>
                        </div>
                    </div>
                    <div className="content-wrapper" style={{ 'padding': '20px' }}>
                        {chatRoom && chatRoom.length && resultData && resultData.length && filterData && filterData.length && resultData.map((item, index) => {
                            let group = JSON.parse(item.group)
                            item.group = JSON.stringify(getSearchBoardGroupData(group))

                            let filterGroup = filterData[index].group
                            let groups = JSON.parse(filterGroup)

                            filterData[index].group = JSON.stringify(getSearchBoardGroupData(groups))
                            let newfilterGroup = filterData[index].group
                            let newgroups = JSON.parse(newfilterGroup)
                            console.log("item.groupapi", filterData)
                            return (
                                <>
                                    <div class="layout__wrapper">{
                                        <FilterDropdown groups={newgroups} columns={item.columnHeaders}
                                            boardId={item._id} resultData={resultData} filterData={filterData} filterfunc={setSearchData} />
                                    }
                                        <BoardData item={item} index={index} key={item._id} boardId={item._id} filtervalue={filtervalue}
                                            columnHeader={item.columnHeaders} searchTerm={seacrhvalue} />
                                    </div>
                                </>
                            )
                        }
                        )
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default UnreadTask
