import React, { Component } from 'react'
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Avatar } from '@material-ui/core';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import moment from 'moment'
import SunEditor, { buttonList } from 'suneditor-react';
import axios from '../../util/Api';
import http from '../services/http-service';
import config from '../services/config.json';
import { getInitials } from '../../util/People';
import { sendBoardChatMessage, updateMessageLikes } from '../../actions';
import * as workspaceApi from '../global-search/constants'
import InboxReminder from './inbox-reminder';
import { Link } from "react-router-dom";
import ReactTooltip from 'react-tooltip';
class InboxList extends Component {
    
    constructor(props) {
        super(props)
        this.loadBoard = this.loadBoard.bind(this)
        this.state = {
            data: '',
            sender: [],
            sendercolor: '#bdbdbd',
            showToolbar: false,
            isRemoveModal: false,
            messageReceivers: [],
            profilepic: null,
            boardID : '',
            groupInd : '',
            rowInd : '',
            messageId: ''
        }
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    // replyWithQuote = (message) => {
    //     this.setState({ showQuotedMessage: true })
    //     let fromName = this.getUserdetails(message.from).name
    //     this.setState({ quotedFrom: fromName })
    //     this.setState({ quotedMsg: message.body.replace(/<(?:.|\n)*?>/gm, '').replace(fromName, "") })
    //     this.setState({ quotedId: message._id })
    //     this.scrollToBottom()
    // }

    // SendReplyWithQuote = () => {
    //     let QuotedMessage = "<div class='quotedmessage'><a href='" + this.state.quotedId + "'><div class='quoteduser border-bottom'>" + this.state.quotedFrom + "</div><div class='quotedmessagebody'><i class='fa fa-quote-left small text-black-50'></i> " + this.state.quotedMsg + " <i class='fa fa-quote-right small text-black-50'></i></div></a></div>"
    //     //let messageBody = this.state.editorText;
    //     let reply = {
    //         chatRoom: this.state.chatRoomID,
    //         to: this.state.messageReceivers,
    //         from: this.authUser._id,
    //         likes: [],
    //         seenBy: [],
    //         body: QuotedMessage.concat(this.state.editorText)

    //     }
    //     this.props.sendBoardChatMessage(reply)

    //     this.setState({ editorText: '' })
    //     this.setState({ quotedFrom: '' })
    //     this.setState({ quotedMsg: '' })
    //     this.setState({ showQuotedMessage: false })
    //     this.setState({ messageReceivers: [] })
    //     this.setState({ messages: [...this.state.messages, reply] })
    // }

    // clearQuotedMsg = () => {
    //     this.setState({ quotedFrom: '' })
    //     this.setState({ quotedMsg: '' })
    //     this.setState({ showQuotedMessage: false })
    // }









    loadBoard = (value) => {

        http.get(workspaceApi.GET_WORKSPACE_BY_BOARDID + "?id=" + value)
            .then((result) => {
                window.location = "/board/" + value

            }).catch(error => {

            })
    }
    componentDidMount() {
        console.log("this.props.item",this.props.item)
        var sendername = '', sendercolor = ''
        JSON.parse(this.props.item.members).map((item) => {
            if (this.props.item.from === item._id) {
                sendername = item.name;
                sendercolor = item.color
            }
        })
        this.setState({
            data: this.props.item,
            sender: sendername,
            sendercolor: sendercolor,
           // boardData:this.props.item.boardData,
            boardID : this.props.item.boardData.boardID,
            groupInd : ""+ this.props.item.boardData.groupInd,
            rowInd : ""+this.props.item.boardData.rowInd,
            messageId: this.props.item.boardData.messageId,
            chatroomID : this.props.item.boardData.chatroomID
        })
        this.getProfilepic(this.props.item.from)

       
       
       
       
    //    new
       
    axios.post('/workspace/getRepliesByMessageId', {
        messageID: this.props.item.messageID,
    }).then(({ data }) => {
        this.setState({ replies: this.props.item.body })
        ReactTooltip.rebuild();
    }).catch(error => {
        console.log("Error****:", error.message);
    })

        // new
    
    
    
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.item !== this.props.item) {
            var sendername = '', sendercolor = ''
            JSON.parse(nextProps.item.members).map((item) => {
                if (nextProps.item.from === item._id) {
                    sendername = item.name;
                    sendercolor = item.color
                }
            })
            this.setState({
                data: nextProps.item,
                sender: sendername,
                sendercolor: sendercolor
            })
            this.getProfilepic(nextProps.item.from)
        }
    }

    updateLike = () => {

        let obj;
        if (this.state.data.from === this.authUser._id) {
            toast.error("You can't like or dislike your messages");
            return;
        }
        let seenByarray = this.state.data.likes

        let index = this.state.data.likes.findIndex(member => member._id === this.authUser._id);
        if (index == -1) {
            obj = {
                _id: this.authUser._id,
                time: new Date().valueOf()
            }

            seenByarray.push(obj)

            this.props.refresh({
                ...this.state.data,
                likes: seenByarray
            })

        } else {
            debugger;
            seenByarray = seenByarray.splice(index, -1);

            this.props.refresh({
                ...this.state.data,
                likes: seenByarray
            })
        }
        this.props.updateMessageLikes({
            messageID: this.state.data.messageId,
            likes: seenByarray
        });
    }

    getUserdetails = (userID) => {
        let members = JSON.parse(this.state.data.members)
        let Index = members.findIndex(member => member._id === userID);
        return members[Index];
    }

    deleteMessage = (messageID) => {
        axios.post('/workspace/removeBoardChatMessage', {
            messageID: messageID,

        }).then(({ data }) => {
            this.props.deleteInbox(data.deletedMessage._id)
            //alert("Deleted Sucessfully")
            toast.success("Deleted Sucessfully");
            this.setState({ isRemoveModal: false })
            
        }).catch(error => {
            //  dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        })
    }

    handleChange = (contents) => {
        this.setState({ editorText: contents })
        let content = contents.replace(/(<([^>]+)>)/ig, '')
        if (content.includes('@') && !content.includes('@ ')) {
            this.setState({ userFilterString: content.split('@').pop() })
            this.setState({ showUserSuggestions: true })
        } else {
            this.setState({ showUserSuggestions: false })
        }
    }

    onKeyDown = (e) => {

        let messageReceivers = []
        if (e.key == "Backspace") {

            var selection = ReactHtmlParser(this.state.editorText);

            for (let i = 0; i < selection.length; i++) {
                if (selection[i]?.props?.uid) {
                    messageReceivers.push(selection[i]?.props?.uid)
                }
            }
            this.setState({ messageReceivers: messageReceivers })
            console.log('messageReceivers', messageReceivers)

        }
        console.log('messageReceivers1', this.state.messageReceivers)
    }

    showInline = (toolbar, context) => {
        console.log(toolbar, context)
    }

    clearUserSuggestions = () => {
        this.setState({ showUserSuggestions: false })
    }

    addUsersToMessage = (member) => {
        let msg = this.state.editorText
        msg = msg.substring(0, msg.lastIndexOf("@"));
        this.setState({ editorText: msg.concat("<u class='badge badge-primary' uid='" + member._id + "' contenteditable='false'><b>" + member.name + "</b></u>&nbsp;") })
        let receivers = this.state.messageReceivers
        receivers.push(member._id)
        this.setState({ messageReceivers: receivers })
        this.setState({ userFilterString: '' })
        this.setState({ showUserSuggestions: false })
    }

    SendReplyWithQuote = (value) => {
        let brdmembers=JSON.parse(value.members)
        let Index = brdmembers.findIndex(member => member._id === value.from);

        let tempDiv;
        if(value.body.includes('quotedmessage')){
             tempDiv = document.createElement('div');
            tempDiv.innerHTML = value.body;
            tempDiv.childNodes[0].remove();
            tempDiv = tempDiv.outerHTML
        }else{
            tempDiv = value.body;
        }

        let QuotedMessage = "<div class='quotedmessage'><a href='" + value._id + "'><div class='quoteduser border-bottom'>" + brdmembers[Index].name + "</div><div class='quotedmessagebody'><i class='fa fa-quote-left small text-black-50'></i> " + tempDiv.replace(/<(?:.|\n)*?>/gm, '').replace(brdmembers[Index].name, "") + " <i class='fa fa-quote-right small text-black-50'></i></div></a></div>"
        //let messageBody = this.state.editorText;
        // let reply = {
        //     chatRoom: this.state.chatRoomID,
        //     to: this.state.messageReceivers,
        //     from: this.authUser._id,
        //     likes: [],
        //     seenBy: [],
        //     body: QuotedMessage.concat(this.state.editorText)

        // }
        let members=JSON.parse(this.props.item.members)
            let result=members.filter(x=>x._id==this.props.item.from)
                      let clrname="<div style ='color:#e8368f'>" +result[0].name+"</div>"
            
            let message = {
                chatRoom: this.state.data.chatroomId,
                to: this.state.messageReceivers,
                likes: [],
                seenBy: [],
                from: this.authUser._id,
                body: QuotedMessage.concat(this.state.editorText)
            }
            axios.post('/workspace/sendBoardChatMessage', message).then(({ data }) => {
                console.log("add row saved data===>", data);

                if (data.message) {
                    let message = {
                        "_id": this.state.data._id,
                        "title": this.state.data.title,
                        "description": this.state.data.description,
                        "to": data.message.to,
                        "from": data.message.from,
                        "body": data.message.body,
                        "likes": data.message.likes,
                        "seenBy": data.message.seenBy,
                        "messageId": data.message._id,
                        "chatroomId": this.state.data.chatroomId,
                        "members": this.state.data.members,
                        "grouplabel": this.state.data.grouplabel,
                        "groupitemtitle": this.state.data.groupitemtitle,
                        "createdAt": data.message.createdAt,
                        "updatedAt": this.state.data.updatedAt,
                        "lastViewed": this.state.data.lastViewed,
                        "boardData":this.state.data.boardData
                    }
                    this.props.replyInbox(message, this.state.data.messageId)

                } else {
                    // dispatch({ type: FETCH_ERROR, payload: "No Data" });
                }
            }).catch(error => {
                // dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            })
            this.setState({ editorText: '' })

        this.setState({ editorText: '' })
        this.setState({ quotedFrom: '' })
        this.setState({ quotedMsg: '' })
        this.setState({ showQuotedMessage: false })
        this.setState({ messageReceivers: [] })
        // this.setState({ messages: [...this.state.messages, reply] })
    }



    highlightmsg = (mgsid) => {
     
        var elmnt = document.getElementById(mgsid);

        elmnt.classList.add("blink_chatmsg");

        elmnt.scrollIntoView();
        setTimeout(function () {
            elmnt.classList.remove("blink_chatmsg");
        }, 5000);

    }







    onSendMessage = (value) => {
        console.log("this.state.editorText",this.state.editorText)
        if (!this.state.enableDropzone) {
           
            if (this.state.editorText==undefined||this.state.editorText.replace(/(<([^>]+)>)/ig, '') === '') {
                toast.error("Type something to send");
                return;
            }
        } else {
            if (this.state.editorText.replace(/(<([^>]+)>)/ig, '') === '' && this.state.editorAttachements.replace(/(<([^>]+)>)/ig, '') === '') {
                toast.error("Type something or attach atleast one file to send");
                return;
            }
        }
        // if (this.state.showQuotedMessage) {
            this.SendReplyWithQuote(value);
        // } else {
        //     let members=JSON.parse(this.props.item.members)
        //     let result=members.filter(x=>x._id==this.props.item.from)
        //               let clrname="<div style ='color:#e8368f'>" +result[0].name+"</div>"
            
        //     let message = {
        //         chatRoom: this.state.data.chatroomId,
        //         to: this.state.messageReceivers,
        //         likes: [],
        //         seenBy: [],
        //         from: this.authUser._id,
        //         body: this.state.enableDropzone ? "<div class='txt-inb' >"+ clrname+"<br/>"+this.props.item.body+this.state.editorAttachements+"</div>" + this.state.editorText :"<div class='txt-inb-one'>"+clrname+"<br/>"+  this.props.item.body+"</div>"+this.state.editorText
        //     }
        //     axios.post('/workspace/sendBoardChatMessage', message).then(({ data }) => {
        //         console.log("add row saved data===>", data);

        //         if (data.message) {
        //             let message = {
        //                 "_id": this.state.data._id,
        //                 "title": this.state.data.title,
        //                 "description": this.state.data.description,
        //                 "to": data.message.to,
        //                 "from": data.message.from,
        //                 "body": data.message.body,
        //                 "likes": data.message.likes,
        //                 "seenBy": data.message.seenBy,
        //                 "messageId": data.message._id,
        //                 "chatroomId": this.state.data.chatroomId,
        //                 "members": this.state.data.members,
        //                 "grouplabel": this.state.data.grouplabel,
        //                 "groupitemtitle": this.state.data.groupitemtitle,
        //                 "createdAt": data.message.createdAt,
        //                 "updatedAt": this.state.data.updatedAt,
        //                 "lastViewed": this.state.data.lastViewed
        //             }
        //             this.props.replyInbox(message, this.state.data.messageId)

        //         } else {
        //             // dispatch({ type: FETCH_ERROR, payload: "No Data" });
        //         }
        //     }).catch(error => {
        //         // dispatch({ type: FETCH_ERROR, payload: error.message });
        //         console.log("Error****:", error.message);
        //     })
        //     this.setState({ editorText: '' })

        // }
        this.setState({ enableDropzone: false })
        this.setState({ enableEmojis: false })
    }

    getProfilepic = (userId) => {
        axios.get(`/users/getUserProfileById?id=${userId}`).then(({ data }) => {
            console.log("inbox data==>", data)
            if (data.userProfile[0].profilepic !== null) {
                this.setState({ profilepic: config.baseUrlEndPoint + data.userProfile[0].profilepic })
            }
        }).catch(err => {
            console.log("Error:", err)
        })
    }


// getOnBoard =()=>{
//     this.setState({
      
//      })
// }



    render() {
        console.log('boardData==>', this.state.data.boardData?.workspaceBoard.members)
        //console.log("==>board",this.props.item.boardData.boardID)
        return <div>
            <ReadMore
                initialHeight={450}
                readMore={props => (
                    <div style={{ textAlign: 'center', marginTop: '65px' }}>
                        <a style={{ color: "blue", cursor: "pointer", width: '100%' }} onClick={props.onClick}>
                            {props.open ? 'Read Less' : 'Read More'}
                            
                        </a>
                    </div>
                )}
            >
                <div className="card border-bottom-0 rounded-0" style={{ textAlign: 'left' }}>
                    <div className="card-body" >
                        {/* <span onClick={() => this.loadBoard(this.state.data._id)}> */}
                        <span>
                            <div className="row card-title">
                                {/* <div className="col-sm-1 col-xl-1"> */}
                                {this.state.profilepic !== null ? <Avatar alt={this.state.sender} src={this.state.profilepic} style={{ height: '36px', width: '36px', marginLeft: '10px', marginRight: '10px' }} /> : <Avatar style={{ height: '36px', width: '36px', fontSize: '16px', marginLeft: '10px', marginRight: '10px', backgroundColor: this.state.sendercolor }}>{getInitials(this.state.sender)}</Avatar>}
                                {/* </div> */}
                                <div className="col inbox-head-detail" style={{ lineHeight: '0.9em' }}>
                                   
                                   <div style={{ 'display' : 'grid' , 'line-height' : '18px' }}>
                                    <span className="row" style={{ fontWeight: 400 }}>{this.state.sender}</span> 
                                   
                                    {/* <span className="row text-muted" style={{ fontSize: 10 }}>{this.state.data.title} > {this.state.data.grouplabel}> {this.state.data.groupitemtitle} </span> */}
                                    <span className="row text-muted" style={{ fontSize: 12, fontWeight: "bold" }}>  
                    <Link
                        to={{
                        pathname: '/board/' + this.state.boardID,
                        state: {
                            groupInd: ""+ this.state.groupInd,
                            rowInd: ""+this.state.rowInd,
                            messageId: this.state.messageId,
                            
                        }
                    }}
                    >
                    {this.state.data.title}
                    </Link> 
                > 
                <Link
                        to={{
                        pathname: '/board/' + this.state.boardID,
                        state: {
                            groupInd: ""+ this.state.groupInd,
                            rowInd: ""+this.state.rowInd,
                            messageId: this.state.messageId,
                            
                        }
                    }}
                    >
                    {this.state.data.grouplabel}
                    </Link> 
                > 
                <Link
                        to={{
                        pathname: '/board/' + this.state.boardID,
                        state: {
                            groupInd: ""+ this.state.groupInd,
                            rowInd: ""+this.state.rowInd,
                            messageId: this.state.messageId,
                            chatroomId: this.state.chatroomId
                        }
                    }}
                    >
                    {this.state.data.groupitemtitle}
                    </Link> 
                 </span>
                 </div>

                 <span className="row text-muted mr-2" style={{ fontSize: 10 }}><i class="fa fa-clock-o fa-lg inbox-icons" ></i> <span class="ml-1" style={{ verticalAlign : 'middle' }}>{moment(this.state.data.createdAt).startOf('min').fromNow()}</span></span>
                       

                                </div>
                            </div>


                            <div class="inbox-detailed-txt">
                                    {ReactHtmlParser(this.state.data.body)}
                            </div>



                        </span>


                    </div>
                    
                </div>
            </ReadMore>










            
            <div className="card border-top-0 rounded-0" style={{ textAlign: 'left', marginTop: '-15px' ,  }}>
                <div className="card-body" style={{ marginBottom : '10px' }}>
                    <div className="row" style={{ 'padding-left' : '15px' }}>
                        <div className="d-flex">
                            <span className="cursor-pointer"
                                onClick={() => this.updateLike()}

                            >
                                <i class="fal fa-thumbs-up inbox-icons" aria-hidden="true"></i>
                            </span>
                            {this.state.data.likes?.length > 0 && <span class="dropdown dropleft">
                                <span class="badge likecounter" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {this.state.data.likes.length}
                                </span>
                                <div class="dropdown-menu" style={{ minWidth: '235px' }}>
                                    {this.state.data.likes
                                        .map((like, index) =>
                                        (
                                            <a class="mb-2" key={index}>{this.getUserdetails(like?._id)?.name}  <sub className="ml-1 text-black-50">@{moment(like.time).format('LLL')}</sub></a>)
                                        )}
                                </div>
                            </span>}
                        </div>
                        <div >
                            {this.authUser._id === this.props.item.from && <div className="ml-3 float-right"><i className="fal fa-trash-alt cursor-pointer inbox-icons"
                                onClick={event => this.setState({ isRemoveModal: true })}
                            ></i></div>}
                            <div className="ml-3 float-right">
                                {this.state.data.seenBy?.length > 0 && <span class="dropdown dropleft">
                                    {/* <span class="badge likecounter" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                 {message.seenBy.length}
                                                                            </span> */}
                                    <i class="fa fa-eye fa-lg inbox-icons" data-toggle="dropdown"></i>

                                    <div class="dropdown-menu" style={{ minWidth: '235px' }}>
                                        {
                                            this.state.data.seenBy
                                                .filter(seenobj => seenobj._id !== this.authUser._id).length > 0 ?
                                                this.state.data.seenBy
                                                    .filter(seenobj => seenobj._id !== this.authUser._id)
                                                    .map((obj, index) =>
                                                    (
                                                        <a class="mb-2" key={index}>{this.getUserdetails(obj._id)?.name} <sub className="ml-1 text-black-50">@ {moment(obj.time).format('LLL')}</sub></a>)
                                                    ) :
                                                <div className="mt-n4">
                                                    No other users
                                                </div>

                                        }
                                    </div>
                                </span>}
                            </div>
                            <div className="float-right">

                                <span class="dropdown">

                                    <i class="fal fa-bell fa-lg inbox-icons" data-toggle="dropdown" style={{ fontSize : '18px' , 'position' : 'relative' , 'top' : '2px' }}></i>

                                    <div class="dropdown-menu multi-level animate slidein" style={{ minWidth: '435px' }}>
                                        <form className="m-0">
                                            <InboxReminder
                                                chatroomID={this.props.item.chatroomId}
                                                messageID={this.props.item.messageId}
                                                boardMembers={this.props.item.members}
                                                boardData={this.props.item.boardData}
                                                rowInd={this.props.item.rowInd}
                                                groupInd={this.props.item.groupInd}
                                                reminderID={this.props.item.reminderId ? this.props.item.reminderId : ''}

                                            />
                                        </form>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>


                    {/* <div className="card-body msgbody p-2 "> 
                            {ReactHtmlParser(message.body, { transform: this.htmlParserTransform })}
                            <i class="fa fa-reply-all float-right ml-3 border-left cursor-pointer mt-n3 text-secondary small"
                             onClick={event => this.replyWithQuote(message)}
                            ></i>
                    </div> */}


                    <div className="row">
                        <div className="col-xl-11 col-11">
                            {this.state.showUserSuggestions &&
                                <div className="usersuggestions shadow py-2 px-3 mb-3 border w-100">
                                    <div className="border-bottom pb-1">Select User
                                        <i className="fa fa-times float-right cursor-pointer  inbox-icons"
                                            onClick={event => this.clearUserSuggestions(event)}
                                        ></i>
                                    </div>
                                    {JSON.parse(this.state.data.members)
                                        .filter(member => !this.state.messageReceivers.includes(member._id))
                                        .filter(member => member.name.toLowerCase().includes(this.state.userFilterString.toLowerCase()))
                                        .map((member, index) =>
                                        (
                                            <div className="border-bottom py-2 cursor-pointer"
                                                onClick={event => this.addUsersToMessage(member)}
                                                key={index}
                                            >
                                                {member.name}
                                            </div>
                                        )
                                        )}
                                </div>
                            }
                           
                            <SunEditor 
                             contentEditable={true}
                                placeholder="Please type here..."
                                autoFocus={true}
                                showToolbar={this.state.showToolbar}
                                onClick={()=>{
                                    this.setState({
                                        showToolbar:true,
                                       
                                        

                                    })
                                }}
                                onBlur={()=>{
                                    this.setState({
                                        showToolbar:false
                                    })
                                }}
                                onChange={this.handleChange}
                                onKeyUp={this.onKeyDown}
                                setContents={this.state.editorText}
                                setOptions={{
                                    height: 'auto',
                                    minHeight: 45,
                                    maxHeight: 150,
                                    marginTop : 100 ,
                                   //buttonList: [['font', 'align'], ['image']]
                                    buttonList: [['bold', 'italic','underline', 'fontColor','image', 'video', 'link']]
                                    // Or Array of button list, eg. [['font', 'align'], ['image']]
                                }}
                                showInline={this.showInline()}
                            />
                            
                                
                          
                        </div>
                        <div style={{ 'align-items' : 'center' ,  'display' : 'flex' }}>
                            <a onClick={event => this.onSendMessage(this.props.item)} class="round-button mt-1">
                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>




                   



                </div>
            </div>
            <Modal isOpen={this.state.isRemoveModal} ref={this.removeModalContainer} className='modal-dialog-centered'>
                <ModalHeader toggle={() => this.setState({ isRemoveModal: false })}>
                    Are you sure to delete this message ?
                </ModalHeader>
                <ModalFooter>
                    <button className='btn btn-light' onClick={() => { this.setState({ isRemoveModal: false }) }}>Cancel</button>{' '}
                    <button className='btn btn-primary'
                        onClick={() => this.deleteMessage(this.props.item.messageId)}
                    >Delete</button>
                </ModalFooter>
            </Modal>
        </div>
    }
}

class ReadMore extends React.Component {
    constructor({ initialHeight }) {
        super();
        this.state = { initialHeight, maxHeight: initialHeight };
    }

    toggle() {
        let { maxHeight, initialHeight } = this.state;

        //if expanded, close it
        if (maxHeight !== initialHeight)
            return this.setState({ maxHeight: initialHeight });

        let height = this.calculateHeight();

        //set the full height
        this.setState({ maxHeight: height });
    }

    calculateHeight() {
        //calculate height of all the children
        let children = [...this.container.children];
        let height = 0;
        children.forEach(child => (height += child.offsetHeight));

        return height;
    }

    componentDidMount() {
        if (this.calculateHeight() <= this.props.initialHeight)
            this.setState({ hideReadMore: true });
    }

    render() {
        let { children, readMore, blurStyle, overhangSize } = this.props;
        let { maxHeight, initialHeight, hideReadMore } = this.state;
        let open = maxHeight !== initialHeight;

        return (
            <React.Fragment>
                <div
                    className="readmore"
                    style={{
                        maxHeight: open ? maxHeight : maxHeight - overhangSize,
                        transition: 'max-height .5s ease',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                    ref={el => (this.container = el)}
                >
                    {children}
                    {hideReadMore ? null : (
                        <div
                            className="readmore overhang"
                            style={{
                                transition: 'opacity 0.25s',
                                opacity: open ? 0 : 1,
                                backgroundImage:
                                    'linear-gradient(to bottom, rgba(255, 255, 255, 0.44), #ffffff )',
                                content: '',
                                height: `${overhangSize}px`,
                                width: '100%',
                                position: 'absolute',
                                bottom: '0',
                                left: '0',
                                ...blurStyle,
                            }}
                        >
                            {readMore({
                                open,
                                onClick: () => this.toggle(),
                            })}
                        </div>
                    )}
                </div>

                {/* {hideReadMore
            ? null
            : readMore({
                open,
                onClick: () => this.toggle(),
              })} */}
            </React.Fragment>
        );
    }
}

ReadMore.defaultProps = {
    overhangSize: 160,
}

const mapDispatchToProps = { 
    sendBoardChatMessage,
     updateMessageLikes 
    };

export default connect(null, mapDispatchToProps)(InboxList);
//export default InboxList;