import React from 'react';

function MainWidget(props) {
   const { ShowTasksCountsWidgetValue, ShowAllTasksValue, totalTasksCount, ShowOngoingTaskCountValue, onGoingTasksCount, ShowOverdueTasksValue, overdueTasksCount,
      ShowReviewTasksValue, reviewTasksCount, ShowUnreadTaskValue, unreadTasksCount, ShowFavoritesTaskValue, favoriteTasksCount, ShowRecentTasksValue,
      recentTasksCount
   } = props;

   return (
      <>
         {
            ShowTasksCountsWidgetValue &&
            <div className="row small-responsive-inner-first">
               <div className="col-md-12 col-lg-12" >
                  {
                     ShowAllTasksValue &&
                     <a href="/tasks">
                        <div className="board-left-section p-1" style={{ backgroundImage: 'url("./images/alltask-bg.png")' }}>
                           <div className="board-left-txt">
                              <span>All Tasks</span><br />
                              <span style={{ fontSize: '20px' }}>{totalTasksCount}</span>
                           </div>
                        </div>
                     </a>
                  }
                  {ShowOngoingTaskCountValue &&
                     <a href="/tasks">
                        <div className="board-left-section p-1" style={{ backgroundImage: 'url("./images/ongoing-bg.png")' }}>
                           <div className="board-left-txt">
                              <span>Ongoing</span><br />
                              <span style={{ fontSize: '20px' }}>{onGoingTasksCount}</span>
                           </div>
                        </div>
                     </a>
                  }
                  {ShowOverdueTasksValue &&
                     <a href="/tasks">
                        <div className="board-left-section p-1" style={{ backgroundImage: 'url("./images/overdue-bg.png")' }}>
                           <div className="board-left-txt">
                              <span>Overdue</span><br />
                              <span style={{ fontSize: '20px' }}>{overdueTasksCount}</span>
                           </div>
                        </div>
                     </a>
                  }
                  {ShowReviewTasksValue &&
                     <a href="/tasks">
                        <div className="board-left-section p-1" style={{ backgroundImage: 'url("./images/review-bg.png")' }}>
                           <div className="board-left-txt">
                              <span>Review</span><br />
                              <span style={{ fontSize: '20px' }}>{reviewTasksCount}</span>
                           </div>
                        </div>
                     </a>
                  }
                  {ShowUnreadTaskValue &&
                     <a href="/tasks">
                        <div className="board-left-section p-1" style={{ backgroundImage: 'url("./images/unread-bg.png")' }}>
                           <div className="board-left-txt">
                              <span>Unread</span><br />
                              <span style={{ fontSize: '20px' }}>{unreadTasksCount}</span>
                           </div>
                        </div>
                     </a>
                  }
                  {ShowFavoritesTaskValue &&
                     <a href="/tasks">
                        <div className="board-left-section p-1" style={{ backgroundImage: 'url("./images/favourites-bg.png")' }}>
                           <div className="board-left-txt">
                              <span>Favourites</span><br />
                              <span style={{ fontSize: '20px' }}>{favoriteTasksCount}</span>
                           </div>
                        </div>
                     </a>
                  }
                  {ShowRecentTasksValue &&
                     <a href="/tasks">
                        <div className="board-left-section p-1" style={{ backgroundImage: 'url("./images/recent-bg.png")' }}>
                           <div className="board-left-txt">
                              <span>Recent</span><br />
                              <span style={{ fontSize: '20px' }}>{recentTasksCount}</span>
                           </div>
                        </div>
                     </a>
                  }

               </div>
            </div>
         }


      </>

   );
}

export default MainWidget;