export const getAllMessagesType = "messagesType/getAllMessagesType";
export const getMessageTypeById = "messagesType/getMessageTypeById";
export const saveMessageType = "messagesType/saveMessageType";
export const updateMessageType = "messagesType/updateMessageType";
export const deleteState = "messagesType/deleteState";

export default {
  getAllMessagesType: getAllMessagesType,
  getMessageTypeById: getMessageTypeById,
  saveMessageType: saveMessageType,
  updateMessageType: updateMessageType,
  deleteState: deleteState,
};
