import config from '../../services/config.json';
import file from '../../../data-services/file-data-service';

export const GET_ALL_FILES = config.apiEndPoint + file.getAllFiles;
export const GET_ALL_FILES_BY_USER = config.apiEndPoint + file.getAllFilesByUser;

export const FET_FILE_BY_ID = config.apiEndPoint + file.getFilesById;

export const SAVE_FILE = config.apiEndPoint + file.saveFile;

export const UPDATE_FILE = config.apiEndPoint + file.updateFile;

export const DELETE_FILE = config.apiEndPoint + file.deleteFile;

export const SEARCH_FILE = config.apiEndPoint + file.saveFile;

export const GET_FILES_BY_TYPE = config.apiEndPoint + file.getFilesByTypes;


export const GET_FILE_BY_NAME = config.apiEndPoint + file.getFileByName;

export const GET_FILE_BY_TYPE = config.apiEndPoint + file.getFileByType;



