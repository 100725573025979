import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import DroppableList from './DroppableList';

import { dragDropChangedGroup, dragDropChangedGroupItem, dragDropChangedGroupColumnOrder, changeWorkspaceBoard } from '../../../../../actions';
import config from '../../../../services/config.json';
const socket = require('socket.io-client')(config.baseUrlEndPoint, {
    transports: ['websocket'],
    rejectUnauthorized:   false,
  })
class BoardGroup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            items: [],
            groups: {},
            targetAreaIndex: null,
            isWorkspaceOwner: false,
            isWorkspaceAdmin: false,
            boardMembers: [],
            workspaceAdmins: [],
            workspaceOwner: '',
            editRowRestrictedMembers: [],
            boardID: '',
            removedCurrentBoard: false,
        }
        this.authUser = JSON.parse(localStorage.getItem("authUser"));
    }

    componentDidMount() {
       
        this.buildAndSave(this.props.boardData.data);

     
            socket.emit('connectBoard',this.props.boardData.boardID);
            socket.on("socketEvent_boardUpdate", Obj => {
                if(Obj.removeId ===this.authUser._id ){
                    if(this.state.boardID === Obj.boardId)
                    this.setState({ removedCurrentBoard: true })
                    return
                 }
               // if(this.props.boardData.boardID === Obj.boardId){
                  this.props.changeWorkspaceBoard({
                      id: Obj.boardId?Obj.boardId:this.props.boardData.boardID,
                      workspaceID: this.props.boardData.workspaceID
                  })
               // }                     
  
          });
        

    }
    componentDidUpdate(prevProps, prevStates) {

        if (this.props.boardData.boardID !== prevProps.boardData.boardID) {
            this.setState({ removedCurrentBoard: false })
            this.setState({ editRowRestrictedMembers: this.props.boardData.workspaceBoard.editRowRestrictedMembers })
            let boardMembers = this.props.boardData.workspaceBoard.members ? JSON.parse(this.props.boardData.workspaceBoard.members) : [];
            this.setState({ boardMembers: boardMembers })
            this.setState({ boardID: this.props.boardData.boardID })

            let workspace = this.props.boardData?.workspace;
            this.setState({ workspaceAdmins: workspace?.admins })
            this.setState({ workspaceOwner: workspace?.CreatedBy })
            if (this.authUser._id == workspace?.CreatedBy) {
                this.setState({ isWorkspaceOwner: true })
                this.setState({ isWorkspaceAdmin: true })
            } else {
                this.setState({ isWorkspaceOwner: false })
                this.setState({ isWorkspaceAdmin: false })
            }
            if (workspace?.admins.includes(this.authUser._id)) {
                this.setState({ isWorkspaceAdmin: true })
            } else {
                this.setState({ isWorkspaceAdmin: false })
            }
        }
        socket.emit('disconnectBoard',prevProps.boardData.boardID);
        socket.emit('connectBoard',this.props.boardData.boardID);
      

    }
    isBoardAdmin = () => {

        let members = this.state.boardMembers;
        let Index = members.findIndex(member => member._id === this.authUser._id);
        if (Index == -1) {
            return false;
        }
        if (members[Index].is_bAdmin) {
            return true;
        } else {
            return false;
        }
    }

    componentWillReceiveProps(nextProps) {
        // console.log("NEXTTT PPPROPS ==>", nextProps)
        this.buildAndSave(nextProps.boardData.data);
        this.setState({ editRowRestrictedMembers: nextProps.boardData.workspaceBoard.editRowRestrictedMembers  });
    }

    buildAndSave = (items) => {
        // console.log("items==>", items)
        const groups = {};

        for (let i = 0; i < Object.keys(items).length; ++i) {
            // console.log("items length==>", i)
            const currentGroup = items[i];
            groups[currentGroup._id] = i;
        }

     
        // Set the data.
        this.setState({ items: items });

        // Makes the groups searchable via their id.
        this.setState({ groups: groups });
    }

    render() {
        const { items, groups, removedCurrentBoard } = this.state;
        // console.log("this.state==>", this.state)
        return removedCurrentBoard?
        <div className="removedCurrentBoard position-absolute w-100 h-100 bg-white" style={{top: '0'}}>
            <div className="float-left ml-5 mt-5">
                <i class="fa fa-5x fa-exclamation-triangle text-secondary mt-5" aria-hidden="true"></i>
                <h2>Oops! Your access for the current Board has been revoked.</h2>
            </div>
        </div>
        :(
         <DragDropContext
                onDragEnd={(result) => {
                    this.setState({ targetAreaIndex: null });
                    const { destination, draggableId, source, type } = result;
                    let boardID = this.props.boardData.boardID;
                    if (!destination) {
                        return;
                    }

                    if (destination.droppableId === source.droppableId && destination.index === source.index) {
                        return;
                    }
                    if (type === 'group') {
                        const sourceIndex = source.index;
                        const targetIndex = destination.index;

                        const workValue = items.slice();
                        const [deletedItem,] = workValue.splice(sourceIndex, 1);
                        workValue.splice(targetIndex, 0, deletedItem);

                        let groupIds = [];

                        for (let i = 0; i < Object.keys(workValue).length; i++) {
                            groupIds.push(workValue[i]._id);
                        }

                        this.props.dragDropChangedGroup({
                            boardID: boardID,
                            sourceIndex: sourceIndex,
                            targetIndex: targetIndex,
                            group: workValue
                        })

                        this.buildAndSave(workValue);

                        return;
                    }
                    if (type === 'data') {
                        const sourceIndex = source.index;
                        const targetIndex = destination.index;
                        // console.log("items ==> ", items);
                        // console.log("groups ==> ", groups);
                        console.log("sourceIndex ==> ", sourceIndex);
                        console.log("targetIndex ==> ", targetIndex);

                        let groupValue = [...items];
                        for (let i = 0; i < groupValue.length; i++) {
                            for (let j = 0; j < groupValue[i].groupItems.length; j++) {
                                const [deletedItem,] = groupValue[i].groupItems[j].cellItems.splice(sourceIndex, 1);
                                groupValue[i].groupItems[j].cellItems.splice(targetIndex, 0, deletedItem);
                            }
                        }

                        // console.log("groupValue == >", groupValue)

                        this.props.dragDropChangedGroupColumnOrder({
                            sourceIndex: sourceIndex,
                            destinationIndex: targetIndex,
                            boardID: this.props.boardData.boardID,
                            group: groupValue
                        })
                        this.buildAndSave(groupValue);

                        return;
                    }
                    const sourceDroppableIndex = groups[source.droppableId];
                    const targetDroppableIndex = groups[destination.droppableId];
                    // console.log("destination id==>", destination.droppableId);
                    // console.log("source Index====>", sourceDroppableIndex)
                    // console.log("targetDroppableIndex", targetDroppableIndex)
                    const sourceItems = items[sourceDroppableIndex].groupItems.slice();
                    const targetItems = source.droppableId !== destination.droppableId ? items[targetDroppableIndex].groupItems.slice() : sourceItems;

                    // Pull the item from the source.
                    const [deletedItem,] = sourceItems.splice(source.index, 1);
                    targetItems.splice(destination.index, 0, deletedItem);

                    const workValue = items.slice();
                    workValue[sourceDroppableIndex] = {
                        ...items[sourceDroppableIndex],
                        groupItems: sourceItems,
                    };

                    workValue[targetDroppableIndex] = {
                        ...items[targetDroppableIndex],
                        groupItems: targetItems,
                    };

                    // console.log("workValue==>", workValue)

                    this.props.dragDropChangedGroupItem({
                        sourceGroupID: source.droppableId,
                        destinationGroupID: destination.droppableId,
                        sourceGroupItemIndex: source.index,
                        destinationGroupItemIndex: destination.index,
                        boardId: boardID,
                        group: workValue
                    })

                    this.setState({ items: workValue });

                }}
                onDragUpdate={(result) => {
                    // console.log(" onDragUpdate result => ", result);
                    const { destination, draggableId, source, type, } = result;
                    if (type === 'data') {
                        this.setState({ targetAreaIndex: destination ? destination.index : null });
                        // console.log("targetAreaIndex =>", this.state.targetAreaIndex)
                    }
                }}
            >
                <Droppable droppableId='ROOT' type='group'>
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}

                            style={{ backgroundColor: snapshot.isDraggingOver ? '#f1f1f3' : '#efefef' }}
                        >
                            {items.length > 0 ? items
                                .filter(items => items.members ? !items.members.includes(this.authUser._id) : true)
                                .map((item, index) => (
                                    <Draggable
                                        key={item._id}
                                        draggableId={item._id}
                                        index={index}
                                    >
                                        {(provided) => (
                                            <div
                                                {...provided.draggableProps}
                                            //    {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                            >
                                                <DroppableList
                                                    isWorkspaceOwner={this.state.isWorkspaceOwner}
                                                    isWorkspaceAdmin={this.state.isWorkspaceAdmin}
                                                    isBoardAdmin={this.isBoardAdmin()}
                                                    editRowRestrictedMembers={this.state.editRowRestrictedMembers}
                                                    permission={this.props.boardData.workspaceBoard.permission}
                                                    boardID={this.props.boardData.boardID}
                                                    key={item._id}
                                                    groupInd={index}
                                                    targetAreaIndex={this.state.targetAreaIndex}
                                                    {...item}
                                                    groupDragProvided={provided}
                                                    cellSuggestions={this.props.boardData.cellSuggestions}
                                                    boardID={this.props.boardData.boardID}
                                                    provided = {provided}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                )) : ''}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData };
};

const mapDispatchToProps = { dragDropChangedGroup, dragDropChangedGroupItem, dragDropChangedGroupColumnOrder, changeWorkspaceBoard };

export default connect(mapStateToProps, mapDispatchToProps)(BoardGroup);
