import React, { Component } from 'react';
class ChatProfileSection extends Component {
  state = {}
  render() {
    return (

      <>


        <div className="col-md-3 chat-profile-layout">
          <div className="row">
            <div className="chat-profile-avatar">
              <div className="avatar chat-user-online">
                <img src="images/portrait/small/avatar-s-2.jpg" alt="user image" width="100" height="100" />
                <span className="avatar-status-online-messenger"></span>
              </div>
            </div>
            <div className="chat-profile-title">
              <span className="chat-profile-name">Sarah Lauren</span><br />
              <span className="chat-profile-day">Yesterday</span>
            </div>
          </div>

          <div className="row" style={{ marginTop: '10px' }}>
            <div className="d-flex chat-details-icon">
              <i className="bx bxs-mobile">
              </i> &nbsp;&nbsp;&nbsp;
                  <span>+971569181578</span>
            </div>

            <div className="d-flex chat-details-icon">
              <i className="bx bxs-envelope">
              </i> &nbsp;&nbsp;&nbsp;
                  <span>mefawas015@gmail.com</span>
            </div>


            <div className="d-flex chat-details-icon">
              <i className="bx bxs-user">
              </i> &nbsp;&nbsp;&nbsp;
                  <span>Team</span>
            </div>


            <div className="d-flex chat-details-icon">
              <i className="bx bxs-video">
              </i> &nbsp;&nbsp;&nbsp;
                  <span>Video Call</span>
            </div>


            <div className="d-flex chat-details-icon">
              <i className="bx bxs-phone">
              </i> &nbsp;&nbsp;&nbsp;
                  <span>Voice Call</span>
            </div>


          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="panel-group" id="accordion" style={{ width: '95%' }}>
                <div className="panel panel-default message-tab">
                  <div className="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapseOne">
                    <h4 className="panel-title chat-panel-title"><i className="bx bx-star"></i> &nbsp;&nbsp;Starred Messages</h4>

                  </div>
                  <div id="collapseOne" className="panel-collapse collapse">
                    <div className="panel-body">
                      <p>Sample text</p>
                    </div>
                  </div>
                </div>


                <div className="panel panel-default file-tab">
                  <div className="panel-heading accordion-toggle collapsed" data-toggle="collapse" data-parent="#accordion" data-target="#collapsetwo">
                    <h4 className="panel-title chat-panel-title"><i className="bx bx-file"></i> &nbsp;&nbsp; Files & Documents</h4>

                  </div>
                  <div id="collapsetwo" className="panel-collapse collapse">
                    <div className="panel-body">
                      <p>Sample Text</p>
                    </div>
                  </div>
                </div>



              </div>
            </div>

            <div className="col-md-12" id="chat-shared-docs">
              <div className="row" id="chat-shared-layout">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2" id="top-section">
                  <div style={{ float: 'left', marginLeft: '15px' }}>
                    <h5 className="left-head">Recent Shared</h5>
                  </div>
                  <div style={{ float: 'right', marginTop: '-2px' }}>
                    <a title="Reply" data-toggle="modal" data-target="#modal-City">
                      <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                    </a>
                  </div>
                </div>

                <div className="col-sm-12 col-md-12 col-lg-12" style={{ display: 'contents' }}>
                  <div className="main-third">
                    <div className="chat-inner-box p-1 pt-4 text-center" style={{ backgroundColor: 'red', opacity: '0.8' }}>

                    </div>
                  </div>

                  <div className="main-third">
                    <div className="chat-inner-box p-1 pt-4 text-center" style={{ backgroundColor: 'orange', opacity: '0.8' }}>

                    </div>
                  </div>


                  <div className="main-third">
                    <div className="chat-inner-box p-1 pt-4 text-center" style={{ backgroundColor: '#1dbd47', opacity: '0.8' }}>
                    </div>
                  </div>

                  <div className="main-third">
                    <div className="chat-inner-box p-1 pt-4 text-center" style={{ backgroundColor: '#e861b9', opacity: '0.8' }}>

                    </div>
                  </div>


                  <div className="main-third">
                    <div className="chat-inner-box p-1 pt-4 text-center" style={{ backgroundColor: '#e861b9', opacity: '0.8' }}>

                    </div>
                  </div>


                  <div className="main-third">
                    <div className="chat-inner-box p-1 pt-4 text-center" style={{ backgroundColor: '#1dbd47', opacity: '0.8' }}>

                    </div>
                  </div>


                  <div className="main-third">
                    <div className="chat-inner-box p-1 pt-4 text-center" style={{ backgroundColor: 'orange', opacity: '0.8' }}>

                    </div>
                  </div>


                  <div className="main-third">
                    <div className="chat-inner-box p-1 pt-4 text-center" style={{ backgroundColor: 'red', opacity: '0.8' }}>

                    </div>
                  </div>


                </div>

              </div>
            </div>


          </div>



        </div>





      </>

    );
  }
}

export default ChatProfileSection;