import React from 'react';
import { connect } from 'react-redux'
import { showMessage, fetchError, changeGroupColor } from '../../../../../actions';

class GroupColorAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openEdit: false,
            isChecked: false,
           
            boardData: [],
            groupItem: [],
      
            colorCodes:['#00c875','#9cd326', '#cab641', '#ffcb00', '#784bd1', '#0086c0', '#579bfc', '#66ccff', '#bb3354', '#e2445c', '#ff158a', '#ff5ac4', '#ff642e', '#fdab3d', '#7f5347', '#c4c4c4', '#808080', '#037f4c'],
            currentColor:''
        }
    
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    componentDidMount() {
        if (this.props.boardData.boardID) {          
           this.setState({ currentColor: this.props.groupItem.color })
           this.setState({ boardData: this.props.boardData })
           this.setState({ groupItem: this.props.boardData })
           
        }   
        
    }
    componentDidUpdate(prevProps){
        if (this.props.groupItem.color!== prevProps.groupItem.color) {
            this.setState({ currentColor: this.props.groupItem.color})
        }
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.groupItem.color!== nextProps.groupItem.color) {
            this.setState({ currentColor: nextProps.groupItem.color})
        }
    }

    updateColor = (color) => {
        this.props.changeGroupColor({
            groupInd: this.props.groupInd,
            groupID: this.props.groupItem._id,
            boardId: this.props.boardData.boardID,
            color: color
        });
        this.setState({ currentColor: color})
    }
    
    render() {
        return (
            <div className="p-2 mb-n4" style={{width: '215px' }}>
               
                <div className="pt-2" style={{ flexWrap: 'inherit' }}>
                    <div className="row">
                    {this.state.colorCodes.map((color, index) => (
                        <div className="col-xl-1  mb-3 text-center" key={index}>
                            <i 
                            className={`fa cursor-pointer ${this.state.currentColor==color ? `fa-check-square` : `fa-circle`}`} 
                            style={{ color: color , fontSize : '20px' }}
                            onClick={() => this.updateColor(color)}                        
                            ></i>
                        </div>
                    ))}
                        
                    </div>
                    
                </div>
               
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { showMessage, fetchError, changeGroupColor };

export default connect(mapStateToProps, mapDispatchToProps)(GroupColorAdd);