import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs,
    onClickTag, onSavedSearchDelete, getHotTags
} from '../../../actions/GlobalSearch'
class HotTags extends Component {
    constructor(props) {
        super(props)
        this.onTagClick = this.onTagClick.bind(this)
    }
    onTagClick = (i, value) => {
        this.props.onClickTag(i, value)
        this.props.onChangeSearch(this.props.search.savedsearch, value,
            this.props.search.userdetails._id, 1,this.props.search.files)
            this.props.fnc(value)
      }
    componentDidMount() {
    }
    render() {
        const group = this.props.item
            .sort((a, b) => a.name.localeCompare(b.name))
            .reduce((r, e) => {
                const key = e.name[0];
                if (!r[key]) r[key] = []
                r[key].push(e);
                return r;
            }, {});

        return <div>
            {Object.entries(group)
                .map(([key, value], i) => {
                    return <div key={i} className="card" style={{ textAlign: 'left' }}>
                        <div class="card-header"><strong>{key}</strong></div>
                        {value.map((item, j) => <div key={j} className="card-body" onClick={() =>this.onTagClick(1,"#"+item.name)}>#{item.name}</div>)}
                    </div>
                })}
        </div>
    }
}

const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs, onClickTag,
    onSavedSearchDelete, getHotTags
};

export default connect(mapStateToProps, mapDispatchToProps)(HotTags);