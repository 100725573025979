import React from 'react';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';

class StatusLabelRemove extends React.Component {
    constructor(props) {
        super(props);
    }

    removeStatusLabel = () => {
        this.props.removeLabel();
    }

    render() {
        let itemType = this.props.item.type;
        let boardData = this.props.boardData.data;
        // console.log("itemType===>", itemType)        
        // console.log("boardData===>", boardData);

        let active = false;

        for (let i = 0; i < boardData.length; i++) {
            let groupItems = boardData[i].groupItems.filter(groupItem => { return groupItem.category === "body" });
            // console.log("groupItems", groupItems);
            for (let j = 0; j < groupItems.length; j++) {
                let cellItems = groupItems[j].cellItems.filter(cellItem => {
                    return cellItem.body === itemType
                });

                if (cellItems.length > 0) {
                    active = true;
                    break;
                }
            }
        }

        // console.log("active", active)
        return (
            <div style={{ position: 'relative' }}>
                <button
                    type='button'
                    className={`btn h-100 ${active ? "" : "d-none"}`}
                    data-tip="You can't delete a label while in use"
                >
                    <i className='fa fa-trash' style={{ color: '#e2445c' }}></i>
                </button>
                <button
                    type='button'
                    className={`btn h-100 ${!active ? "" : "d-none"}`}
                    data-tip="Delete a label"
                    onClick={() => this.removeStatusLabel()}
                >
                    <i className='fa fa-trash' style={{ color: '#e2445c' }}></i>
                </button>
                <ReactTooltip />
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

export default connect(mapStateToProps)(StatusLabelRemove);