import React from 'react';
import FieldRow from './FieldRow';
class FieldList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groupTitle: this.props.label,
        }
        this.authUser = JSON.parse(localStorage.getItem("authUser"));
    }
  
    componentDidMount() {
      const { groupItems } = this.props;
      console.log('groupitemsfieldlist==>', groupItems)
      this.setState({ groupItems: groupItems });
  }
  
    handler = (val) => {
      console.log('valfieldlist==>', val)
      this.setState({
        groupItems: val
      })
    }
  
    render() {
        // const {groupItems} = this.state;
        const { _id, label, groupItems } = this.props;
  
        return (
            
                        <div className={''}>
                            <div style={{paddingBottom: 15}}>{label}</div>
                            <div className=''>
                               
                                    {groupItems && groupItems
                                    .map((item, index) => {
                                          return <div className=''>
  
                                       
                                           { item.category === 'header' ?
                                                <React.Fragment key={index}>
                                                    <div
                                                        className=''
                                                    >
                                                        <div className=''>
                                                            <FieldRow
                                                                {...item}
                                                                groupId={_id}
                                                                groupInd={this.props.groupInd}
                                                                rowInd={index}
                                                                category={item.category}
                                                            />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            
                                        
                                            :
                                                <div
                                                    className=''
                                                  //  className={this.filterRowForRestrictedMembers(item.cellItems)?'list__item':'d-none'}
                                                    key={item._id}
                                                >
                                                   
                                                        
                                                               
                                                                    <div>
                                                                        <FieldRow
                                                                            {...item}
                                                                            groupId={_id}
                                                                            groupInd={this.props.groupInd}
                                                                            rowInd={index}
                                                                            jsonschema={this.props.jsonschema}
                                                                            groupItems={groupItems}
                                                                            category={item.category}
                                                                        />
                                                                    </div>
                                                                
                                                </div>
                                    }
                                            
                                           
                                           </div>
                                        
                                        
                                    
                                    })}
                                    
                            </div>
                        </div>
                   
        );
    }
  }
  export default FieldList