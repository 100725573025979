import {
   FETCH_ERROR,
   FETCH_START,
   FETCH_SUCCESS,
   INIT_URL,
   SIGNOUT_USER_SUCCESS,
   USER_DATA,
   USER_DATA_SET,
   USER_TOKEN_SET
} from "../constants/ActionTypes";

import axios from '../util/Api'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export const setInitUrl = (url) => {
   return {
      type: INIT_URL,
      payload: url
   };
};

export const getUser = () => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.get('/users/user',
      ).then(({ data }) => {
         console.log("get User res: ", data);
         if (data.user) {
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: USER_TOKEN_SET, payload: data.token });
            let user = {
               firstname: data.firstname,
               lastname: data.lastname,
               email: data.emailid,
               mobileno: data.mobileno,
               token: data.token
            }
            dispatch({ type: USER_DATA, payload: user });
         } else {
            dispatch({ type: FETCH_ERROR, payload: data.error });
         }
      }).catch(function (error) {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      });
   }
};

export const userSignIn = ({ email, password }) => {
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      dispatch({ type: USER_TOKEN_SET, payload: null });
      dispatch({ type: USER_DATA, payload: null });
      axios.post('/users/login', {
         email: email,
         password: password,
      }).then(({ data }) => {
         localStorage.setItem("token", JSON.stringify(data.token));
         dispatch({ type: FETCH_SUCCESS });
         let user = {
            _id: data.userid,
            firstname: data.firstname,
            lastname: data.lastname,
            email: data.emailid,
            mobileno: data.mobileno
         }
         console.log("user===>", user);
         localStorage.setItem("authUser", JSON.stringify(user));
         dispatch({ type: USER_TOKEN_SET, payload: data.token });
         dispatch({ type: USER_DATA, payload: user });
      }).catch(function (error) {
         toast.error('Oops! '+error.response.data.message);
         console.log("error===>", error)
        // dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      });
   }
};

export const userSignUp = ({ firstName, lastName, email, password, companyName, location }) => {
   console.log(firstName, lastName, email, password, location);
   return (dispatch) => {
      dispatch({ type: FETCH_START });
      axios.post('/user', {
         firstName,
         lastName,
         email,
         password,
         companyName,
         location
      }
      ).then(({ data }) => {
         console.log("data:", data);
         if (data.user) {
            localStorage.setItem("token", JSON.stringify(data.token));
            axios.defaults.headers.common['authorization'] = "Bearer " + data.token;
            dispatch({ type: FETCH_SUCCESS });
            dispatch({ type: USER_TOKEN_SET, payload: data.token });
            dispatch({ type: USER_DATA, payload: data.user });
         } else {
            console.log("payload: data.error", data.error);
            dispatch({ type: FETCH_ERROR, payload: "Network Error" });
         }
      }).catch(function (error) {
         dispatch({ type: FETCH_ERROR, payload: error.message });
         console.log("Error****:", error.message);
      });
   }
};

export const userSignOut = () => {
   return (dispatch) => {
      localStorage.removeItem("token");
      localStorage.removeItem("authUser")
      dispatch({ type: FETCH_SUCCESS });
      dispatch({ type: SIGNOUT_USER_SUCCESS });
   }
};
