import React from 'react';
const StaticHTML = () => {
    return ( 
        <div className="row dash-blue fixed-top">
        <div className="col-md-1 col-md-1">
        </div>
        <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
          <div>
            <div className="inner-section">
              <h3>Master Settings</h3>
              <div style={{ marginTop: '-10px' }}>
                <p className="mt-1">Country</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
          <table className="align-items-center table-dashboard-section-one">
            <tbody><tr>
              <td rowSpan={2} style={{ width: '300px', textAlign: 'left' }}>
                <input className="search-dashboard" type="text" name="search" placeholder="Search Here.." />
              </td>
            </tr>
              <tr style={{ fontWeight: 600 }}>
                <td id="value" />
                <td id="value" />
                <td id="value" />
                <td id="value" />
                <td id="value" />
              </tr>
            </tbody></table>
          <table className="align-items-center table-dashboard-section-last d-flex">
            <tbody><tr>
              <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-27.png" alt="" /></a></td>
              <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-38.png" alt="" /></a></td>
              <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-37.png" alt="" /></a></td>
              <td rowSpan={2} style={{ fontWeight: 100, display: 'inline-flex' }}>&nbsp;&nbsp;Sort <img src="/images/icon/dashboard-icons/Panel icons-32.png" alt="" style={{ marginLeft: '10px' }} /></td>
            </tr>
              <tr>
                <td>&nbsp;&nbsp;</td>
              </tr>
              <tr>
              </tr></tbody></table>
        </div>
      </div>
     );
}
 
export default StaticHTML;