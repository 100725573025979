/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import http from "../../services/http-service";
import Modal from "./modal.jsx";
import StaticHtml from "../common/static-html.jsx";
import StateTable from "./stateTable.jsx";
import * as StateConstant from "./constant.jsx";
import * as CountryConstant from "../country/constant.jsx";
import Joi from "joi-browser";
import Form from "../common/Form.jsx";
import MainMenu from '../../../shared/components/main-menu';
import NavBar from '../../../shared/components/navbar/navbar';
import Notification from '../../notification/notification';

import UserService from "../../auth/auth-services/user-service";
const options = [];

class State extends Form {
  constructor(props) {
    super(props);
    let _currentUSer = UserService.getUserByToken("token").id;
    this.state = {
      selectedOption: null,
      Id: "",
      CreatedBy: _currentUSer,
      ModifiedBy: _currentUSer,
      IsDeleted: false,
      IsActive: true,
      stateData: [],
      TableData: [],
      pageSize: 5,
      currentPage: 1,
      //validation
      countryToValidate: { Name: "", CountryId: "" },
      errorStateName: "",
      errorStateCountryId: "",
      isUpdate: false,
    };
  }

  schema = {
    Name: Joi.string().required().label("Name"),
    CountryId: Joi.string().required().label("Country"),
  };

  async componentDidMount() {
    this.loadFirstData();
  }

  loadFirstData = async () => {
    try {
      //Get Country Data
      await http.get(CountryConstant.Country_GetALL_URL).then((data) => {
        data.data.response.map((data) => {
          options.push({ value: data._id, label: data.Name });
        });
      });
      //Get State Data
      await http.get(StateConstant.State_GetALL_URL).then((data) => {
        this.state.stateData = data.data.response;
      });
      let stateList = this.state.stateData.filter(
        (x) =>
          x.IsActive === true &&
          x.IsDeleted === false &&
          x.CreatedBy != null &&
          x.ModifiedBy != null &&
          x.CountryId != null
      );

      stateList.map((data, index) => {
        this.state.TableData.push({
          itemId: data._id,
          h_0: index + 1,
          h_1: data.Name,
          h_2: data.CountryId.Name,
          h_3: data.CreatedBy.firstname,
          h_4: data.createdAt,
          h_5: data.ModifiedBy.firstname,
          h_6: data.updatedAt,
        });
      });
      this.setState({
        TableData: this.state.TableData,
      });
    } catch (e) {
      alert(
        "Some Thing Went Wrong In Load Data , Please Reload Page Again Or Contact With Support..."
      );
      console.log(e.message, "Error Message");
    }
  };

  SaveOrUpdate = async () => {
    if (this.state.countryToValidate.CountryId == "") {
      const error = this.validateProperty(
        this.state.countryToValidate.CountryId,
        this.schema.CountryId
      );
      this.setState({ errorStateCountryId: error });
      this.setState({ isUpdate: false });
      return;
    }

    this.handleSaveSubmit();
    const data = {
      id: this.state.Id,
      Name: this.state.countryToValidate.Name,
      CountryId: this.state.countryToValidate.CountryId,
      CreatedBy: this.state.CreatedBy,
      ModifiedBy: this.state.ModifiedBy,
      IsDeleted: this.state.IsDeleted,
      IsActive: this.state.IsActive,
    };

    this.doSaveOrUpdate(
      data,
      StateConstant.State_ADD_URL,
      StateConstant.State_Update_URL
    );
  };

  loadState = async (id) => {
    this.setState({
      isUpdate: true,
    });
    await http
      .get(StateConstant.State_GetByID_URL + `?id=${id}`)
      .then((data) => {
        this.setState({
          countryToValidate: {
            Name: data.data.response.Name,
            CountryId: data.data.response.CountryId,
          },
        });
        this.setState({
          Id: data.data.response._id,
        });
      });
    //Get Dependent Country
    await http
      .get(
        CountryConstant.Country_GetByID_URL +
        `?id=${this.state.countryToValidate.CountryId}`
      )
      .then((data) => {
        this.setState({
          selectedOption: {
            value: data.data.response._id,
            label: data.data.response.Name,
          },
        });
      });
  };

  //Soft Delete????????
  deleteRecord = async (id) => {
    this.doDeleteRecord(id, StateConstant.State_DELETE_URL);
  };

  onChangeStateName = (e) => {
    if (e.target.value != "") {
      this.setState({
        isUpdate: true,
      });
    }
    this.setState({
      countryToValidate: {
        ...this.state.countryToValidate,
        Name: e.target.value,
      },
    });
    const error = this.validateProperty(e.target.value, this.schema.Name);
    if (error) {
      this.setState({
        isUpdate: false,
      });
    }
    this.setState({ errorStateName: error });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
    this.setState({
      countryToValidate: {
        ...this.state.countryToValidate,
        CountryId: selectedOption.value,
      },
    });

    const error = this.validateProperty(
      selectedOption.label,
      this.schema.CountryId
    );

    if (error) {
      this.setState({
        isUpdate: false,
      });
    }
    this.setState({ errorStateCountryId: error });
  };

  resetModal = () => {
    this.setState({
      isUpdate: false,
    });
    this.setState({
      countryToValidate: { Name: "", CountryId: "" },
    });
    this.setState({
      Id: "",
    });
    this.setState({
      selectedOption: "",
    });
  };

  render() {
    const {
      TableData,
      selectedOption,
      countryToValidate,
      errorStateName,
      errorStateCountryId,
      isUpdate,
    } = this.state;
    console.log(">>>>data", TableData);

    return (
      <>
        <MainMenu link={`state`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <StaticHtml />
                <div className="content-wrapper">
                  <div className="company-table">
                    <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                      <div style={{ float: "left" }}>
                        <h5 className="left-head">State</h5>
                      </div>
                      <div
                        style={{
                          float: "right",
                          marginTop: "-2px",
                          marginRight: "-15px",
                        }}
                      >
                        <a
                          onClick={this.resetModal}
                          title="Reply"
                          data-toggle="modal"
                          data-target="#modal-Country"
                        >
                          <span className="hidden-sm-down right-head">
                            &nbsp;&nbsp;&nbsp;New State{" "}
                            <i className="bx bxs-plus-circle" />
                          </span>
                        </a>
                      </div>
                    </div>
                    <StateTable
                      TableData={TableData}
                      loadState={this.loadState}
                      deleteRecord={this.deleteRecord}
                    />
                  </div>{" "}
                  {/* End Top Section*/}
                </div>{" "}
                {/* ENd Company Table*/}
                <Modal
                  selectedOption={selectedOption}
                  options={options}
                  handleChange={this.handleChange}
                  Name={countryToValidate.Name}
                  onChangeStateName={this.onChangeStateName}
                  SaveOrUpdate={this.SaveOrUpdate}
                  errorStateName={errorStateName}
                  errorStateCountryId={errorStateCountryId}
                  isUpdate={isUpdate}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default State;
