import React from 'react';
import { connect } from 'react-redux';
import FilterDropdownComponent from './FilterDropdownComponent';

class FilterDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }

        this.dropdownContainer = React.createRef();
    }

    onClickOutsideHandler = (ev) => {
        // console.error("ASDFASDF", ev.target.name)
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            // console.error("_____________asdfasdf ______________");
            if (ev.target.name !== "removableItem") this.setState({ open: false });
        }
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    render() {
        return (
            <div style={{ cursor: 'pointer', fontFamily: 'myriad-pro,sans-serif !important', 
            fontWeight: '300' ,  'float': 'right' ,
            'position': 'absolute' ,
            'display': 'flex' ,
            'z-index': '8888' ,
            'cursor': 'pointer',
            'right': '50px' ,
             marginTop: '1px' ,
            'top'  : '10px' }} ref={this.dropdownContainer}>
                <div className="dropdown" id="sort-dropdown" style= {{ 'border': '1px  solid #d4cdcd' , borderRadius: '20px' , backgroundColor : '#fff' }} >
                    <button className="btn dropdown-toggle" type="button" onClick={() => this.setState({ open: !this.state.open })}>
                        <i className="bx bx-filter" ></i>&nbsp;&nbsp;Filter
                    </button>
                    <div class="filter-dropdown-en search-filter-drop-en" style={{ position: 'absolute', minWidth: '800px', 'top': '4px', 'right': '-400px' }}>
                        {/* {this.state.open ? (
                            <ul className={`dropdown-menu multi-level animate slideIn ${this.state.open ? 'show' : ''}`} role="menu" aria-labelledby="dropdownMenu" style={{ width: '350px !important' }}>
                                <FilterDropdownComponent />
                            </ul>
                        ) : ''} */}

                        <ul className={`dropdown-menu multi-level animate slideIn ${this.state.open ? 'show' : ''}`} role="menu" aria-labelledby="dropdownMenu" style={{ width: '350px !important' }}>
                            <FilterDropdownComponent groups={this.props.groups} columns={this.props.columns} 
                            boardId={this.props.boardId} resultData={this.props.resultData} filterfunc={this.props.filterfunc}/>
                        </ul>
                    </div>

                </div>
            </div>
        )
    }
}


export default FilterDropdown;