import './formula';
import './calculator';
import './bottom';
import './People';
import './permission';
import { calculatorEquation } from './bottom';

export const initialGroupCellItemBody = (key) => {
   let date = new Date();
   // let formatDate = date.getFullYear() + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + ("0" + date.getDate()).slice(-2);
   switch (key) {
      case 'tags':
         return JSON.stringify([]);
      case 'dropdown':
         return JSON.stringify([]);
      case 'people':
         return JSON.stringify([]);
      case 'reminder':
         return JSON.stringify({
            dateTime: date,
            frequency: '',
            users: ''
        });
      default:
         return '';
   }
}

export const timeSince = (date) => {

   var seconds = Math.floor((new Date() - date) / 1000);

   var interval = seconds / 31536000;

   if (interval > 1) {
      return Math.floor(interval) + "y";
   }
   interval = seconds / 2592000;
   if (interval > 1) {
      return Math.floor(interval) + "m";
   }
   interval = seconds / 86400;
   if (interval > 1) {
      return Math.floor(interval) + "d";
   }
   interval = seconds / 3600;
   if (interval > 1) {
      return Math.floor(interval) + "h";
   }
   interval = seconds / 60;
   if (interval > 1) {
      return Math.floor(interval) + "m";
   }
   return Math.floor(seconds) + "s";
}

export const appendLeadingZeroes = (n) => {
   if (n <= 9) {
      return "0" + n.toString();
   }
   return n
}

export const getLocationTime = (timezoneOffset) => {
   let date = new Date(getUTCDateTime());
   date.setMinutes(date.getUTCMinutes() - timezoneOffset);
   return date;
}

export const getUTCDateTime = () => {
   let now = new Date();
   let date = now.getUTCFullYear() + '/' + (now.getUTCMonth() + 1) + '/' + now.getUTCDate() + ' ' + now.getUTCHours() + ':' + now.getUTCMinutes() + ':' + now.getUTCSeconds();
   return date;
}

export const getLocationTimeFromDate = (date, timezoneOffset) => {
   date.setMinutes(date.getUTCMinutes() - timezoneOffset);
   return date;
}

export const convertDateToString = (date) => {
   let year = date.getFullYear();
   let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
   let month = date.getMonth();
   let day = date.getDate();
   let newDate = months[month] + ' ' + day + ', ' + year;
   return newDate;
}

export const getNumberFormat = (number) => {
   if (isInt(number)) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   } else {
      return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
   }
}

export const isInt = (n) => {
   return Number(n) === n && n % 1 === 0;
}

export const getToday = () => {
   var today = new Date();
   var dd = String(today.getDate()).padStart(2, '0');
   var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
   var yyyy = today.getFullYear();

   today = mm + '/' + dd + '/' + yyyy;
   return today;
}

export const makeid = (prefix, length) => {
   var result = prefix + '_';
   var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

export const getBoardGroupCellItemsArray = (groupItems, columnId) => {
   let cellItemsArray = [];
   for (let i = 0; i < groupItems.length; i++) {
      if (groupItems[i].category === 'body') {
         let cellItems = groupItems[i].cellItems;
         for (let j = 0; j < cellItems.length; j++) {
            if (cellItems[j].columnId === columnId) {
               cellItemsArray.push(cellItems[j].body);
            }
         }
      }
   }

   return cellItemsArray;
}

export const getBoardCellItemsArray = (groups, columnId) => {
   let cellItemsArray = [];
   for (let i = 0; i < groups.length; i++) {
      let groupItems = groups[i].groupItems;
      for (let j = 0; j < groupItems.length; j++) {
         if (groupItems[j].category === 'body') {
            for (let k = 0; k < groupItems[j].cellItems.length; k++) {
               if (groupItems[j].cellItems[k].columnId === columnId) {
                  cellItemsArray.push(groupItems[j].cellItems[k].body)
               }

            }
         }
      }
   }

   // console.log("cellItemsArray", cellItemsArray)

   return cellItemsArray;
}

export const sumArrayItems = (items) => {
   let floatArray = getNumberArray(items);
   let sum = 0;
   for (let i = 0; i < floatArray.length; i++) {
      // console.log("array===>", floatArray[i])
      if (floatArray[i] !== "") {
         let item = parseFloat(floatArray[i]);
         // console.log("float===>", floatArray[i])
         // console.log("item===>", item)
         sum = sum + item;
      }
   }

   return sum;
}

export const averageArrayItems = (items) => {
   let numbersArray = getNumberArray(items);
   let sum = sumArrayItems(numbersArray);
   if (numbersArray.length === 0) {
      let average = 0;
      return average;
   }
   let average = sum / (numbersArray.length);

   return average;
}

export const medianArrayItems = (items) => {
   let numbersArray = getNumberArray(items);
   if (numbersArray.length === 0) {
      let median = 0;
      return median;
   }

   numbersArray.sort(function (a, b) { return a - b });

   if (numbersArray.length % 2 === 0) {
      return parseFloat((numbersArray[numbersArray.length / 2] + numbersArray[numbersArray.length / 2 - 1]) / 2);
   } else {
      return parseFloat(numbersArray[parseInt(numbersArray.length / 2)])
   }
}

export const minArrayItems = (items) => {
   let numbersArray = getNumberArray(items);
   if (numbersArray.length === 0) {
      let min = 0;
      return min;
   }
   let minItem = Math.min.apply(null, numbersArray);
   return minItem;
}

export const maxArrayItems = (items) => {
   let numbersArray = getNumberArray(items);
   if (numbersArray.length === 0) {
      let max = 0;
      return max;
   }
   let maxItem = Math.max.apply(null, numbersArray);
   return maxItem;
}

export const countArrayItems = (items) => {
   let numbersArray = getNumberArray(items);
   let count = 0;
   for (let i = 0; i < numbersArray.length; i++) {
      if (numbersArray[i] > 0) {
         count++;
      }
   }
   return count;
}

export const getNumberArray = (items) => {
   let numbersArray = [];
   for (let i = 0; i < items.length; i++) {
      if (items[i] !== '') {
         numbersArray.push(parseFloat(items[i]));
      }
   }
   return numbersArray;
}

export const checkTags = (items, tagName) => {
   if (items.length > 0) {
      for (let i = 0; i < items.length; i++) {
         if (items[i].name === tagName) {
            return true;
         }
      }
   }

   return false;
}

export const activeTagsSuggestion = (tags, suggestions) => {
   let newSuggestion = [];
   for (let i = 0; i < suggestions.length; i++) {
      let suggestionTagName = suggestions[i].name;
      let flag = false;
      for (let j = 0; j < tags.length; j++) {
         if (suggestionTagName === tags[j].name) {
            flag = true;
         }
      }

      if (flag === false) {
         newSuggestion.push(suggestions[i])
      }
   }

   return newSuggestion;
}


export const searchDropdownLabels = (labels, text) => {
   let newLabels = [];
   for (let i = 0; i < labels.length; i++) {
      let n = labels[i].name.toLowerCase().search(text.toLowerCase());
      if (n !== -1) {
         newLabels.push(labels[i])
      }
   }

   return newLabels;
}

export const getColumnArray = (groups) => {
   let columnObjects = {};
   for (let i = 0; i < groups.length; i++) {
      for (let j = 0; j < groups[i].groupItems.length; j++) {
         if (groups[i].groupItems[j]?.category === 'body') {
            for (let k = 0; k < groups[i].groupItems[j].cellItems.length; k++) {
               let cellItem = groups[i].groupItems[j].cellItems[k];
               if (cellItem.key === 'formula') {
                  columnObjects[cellItem.columnId] = [...(columnObjects[cellItem.columnId] || []), {
                     columnId: cellItem.columnId,
                     key: cellItem.key,
                     body: cellItem.body,
                     _id: cellItem._id,
                     equation: cellItem.equation,
                     activeItems: cellItem.activeItems,
                     columns: groups[i].groupItems[j].cellItems,
                     groupItem: groups[i].groupItems[j]
                  }]
               } else {
                  columnObjects[cellItem.columnId] = [...(columnObjects[cellItem.columnId] || []), {
                     columnId: cellItem.columnId,
                     key: cellItem.key,
                     body: cellItem.body,
                     _id: cellItem._id
                  }]
               }

            }
         }
      }
   }

   return columnObjects;
}

export const getFilterColumns = (cellSuggestions, cellItems) => {
   let newCellSuggestions = [];
   for (let i = 0; i < cellSuggestions.length; i++) {
      let cellItem = cellItems.find(cell => cell.columnId === cellSuggestions[i]._id);
      // console.log("cellItem===>", cellItem);
      if (cellItem) {
         if (cellItem.key !== 'file' && cellItem.key !== 'autoNumber' && cellItem.key !== 'lastUpdated') {
            let newCellSuggestion = {
               _id: cellSuggestions[i]._id,
               title: cellItem.body,
               suggestions: cellSuggestions[i].suggestions
            };
            newCellSuggestions.push(newCellSuggestion);
         }
      }

   }

   return newCellSuggestions;
}

export const filterBoardData = (groups, filterOptions) => {
   if (filterOptions.length === 0) {
      return groups;
   }
   let newGroups = [];
   let groupFilters = filterOptions.filter(filter => filter.option === 'group');
   let recordNameFilters = filterOptions.filter(filter => filter.option === 'name');
   let itemFilters = filterOptions.filter(filter => filter.option === 'item');

   if (groupFilters.length > 0) {
      newGroups = groups.filter(group => {
         for (let i = 0; i < groupFilters.length; i++) {
            if (groupFilters[i] !== undefined && groupFilters[i].title === group.label)
               return true;
         }
         return false;
      })
   }

   if (recordNameFilters.length > 0) {
      let filterGroups, filteredGroup = [];
      if (newGroups.length > 0) {
         filterGroups = newGroups;
      } else {
         filterGroups = groups;
      }

      for (let i = 0; i < filterGroups.length; i++) {
         let groupItems = filterGroups[i].groupItems;
         let newGroupItems = []
         for (let j = 0; j < groupItems.length; j++) {
            if (groupItems[j].category === 'header') {
               newGroupItems.push(groupItems[j]);
            } else {
               let flag = false;
               for (let k = 0; k < recordNameFilters.length; k++) {
                  if (groupItems[j].title === recordNameFilters[k].title) {
                     flag = true;
                     break;
                  }
               }

               if (flag) {
                  newGroupItems.push(groupItems[j]);
               }
            }
         }

         if (newGroupItems.length > 1) {
            let newGroup = {
               _id: filterGroups[i]._id,
               label: filterGroups[i].label,
               groupItems: newGroupItems
            }

            filteredGroup.push(newGroup)
         }
      }

      newGroups = filteredGroup;
   }

   if (itemFilters.length > 0) {
      let filterGroups; let filteredGroup = [];
      if (newGroups.length > 0) {
         filterGroups = newGroups;
      } else {
         filterGroups = groups;
      }
      for (let i = 0; i < filterGroups.length; i++) {
         let newItems = [];
         for (let j = 0; j < filterGroups[i].groupItems.length; j++) {
            let groupItem = filterGroups[i].groupItems[j];
            if (groupItem.category === 'header') {
               newItems.push(groupItem);
            } else {
               let flag = false;
               for (let k = 0; k < itemFilters.length; k++) {
                  let filterOption = itemFilters[k];
                  let cellItems = groupItem.cellItems;
                  let cellItem = cellItems.find(cell => cell.columnId === filterOption.columnId);
                  // console.log("cellItem", cellItem)
                  if (cellItem.key === 'tags' || cellItem.key === 'dropdown') {
                     let bodies = JSON.parse(cellItem.body);
                     if (bodies.length === 0) {
                        if (filterOption.title === 'blank') {
                           flag = true;
                           break;
                        }
                     } else {
                        let body = bodies.find(x => x.name === filterOption.title);
                        if (body !== undefined) {
                           flag = true;
                           break;
                        }
                     }
                  } else if (cellItem.key === 'date') {
                     if (cellItem.body === "") {
                        if (filterOption.title === 'blank') {
                           flag = true;
                           break;
                        }
                     } else {
                        let date = new Date(cellItem.body);
                        let now = new Date();
                        if (filterOption.title === 'Past Dates') {
                           if (date.getFullYear() < now.getFullYear()) {
                              flag = true;
                              break;
                           } else if (date.getFullYear() === now.getFullYear()) {
                              if (date.getMonth() < now.getMonth()) {
                                 flag = true;
                                 break;
                              }
                           }
                        } else if (filterOption.title === 'This Month') {
                           if (date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth()) {
                              flag = true;
                              break;
                           }
                        } else {
                           if (date.getFullYear() > now.getFullYear()) {
                              flag = true;
                              break;
                           } else if (date.getFullYear() === now.getFullYear()) {
                              if (date.getMonth() > now.getMonth()) {
                                 flag = true;
                                 break;
                              }
                           }
                        }
                     }
                  } else if (cellItem.key === 'numbers') {
                     if (cellItem.body === "") {
                        if (filterOption.title === 'blank') {
                           flag = true;
                           break;
                        }
                     } else {
                        if (parseFloat(filterOption.title) === parseFloat(cellItem.body)) {
                           flag = true;
                           break;
                        }
                     }
                  } else if (cellItem.key === 'formula') {
                     if (cellItem.body === "" || cellItem.equation === undefined || cellItem.equation === "") {
                        if (filterOption.title === 'blank') {
                           flag = true;
                           break;
                        }
                     } else {
                        let value = calculatorEquation(cellItem.equation, cellItem.activeItems, groupItem, cellItems);
                        if (value === filterOption.title) {
                           flag = true;
                           break;
                        }
                     }
                  } else if (cellItem.key === 'people') {
                     
                     if (cellItem.body.includes(filterOption.title)) {
                        flag = true;
                        break;
                     }
                  }
                  else if (cellItem.key === 'reminder') {
               
                     if (filterOption.title===JSON.stringify(cellItem.body)) {
                        flag = true;
                        break;
                     }
                  }
                  else {
                     if (cellItem.body === "") {
                        if (filterOption.title === 'blank') {
                           flag = true;
                           break;
                        }
                     } else {
                        if (filterOption.title === cellItem.body) {
                           flag = true;
                           break;
                        }
                     }
                  }
               }

               if (flag) {
                  newItems.push(groupItem);
               }
            }
         }

         if (newItems.length > 1) {
            let newGroup = {
               _id: filterGroups[i]._id,
               label: filterGroups[i].label,
               groupItems: newItems
            }

            filteredGroup.push(newGroup)
         }
      }

      newGroups = filteredGroup;
   }

   return newGroups;
}

export const getRemainderObjectArray = (lists, activeLists) => {
   let reminderLists = [];
   for (let i = 0; i < lists.length; i++) {
      let list = lists[i];
      let checkLists = activeLists.filter(filter => filter.columnId === list.columnId);
      if (checkLists.length === 0) {
         reminderLists.push(list);
      }
   }

   return reminderLists;
}

export const getSortedObjectArray = (groups, sortLists) => {
   for (let i = 0; i < groups.length; i++) {
      let groupItems = groups[i].groupItems;
      const itemHeader = groupItems[0];
      groupItems.splice(0, 1);

      groupItems.sort((a, b) => {
         let compares = [];
         for (let i = 0; i < sortLists.length; i++) {
            let sortOption = sortLists[i];
            if (sortOption.columnId === "name") {
               let compare = a.title.localeCompare(b.title);
               compares[i] = (sortOption.direction === 'desc') ? (compare * (-1)) : compare;
            } else {
               let column_A = a.cellItems.find(item => item.columnId === sortOption.columnId);
               let column_B = b.cellItems.find(item => item.columnId === sortOption.columnId);
               if (column_A.key === 'tags' || column_A.key === 'dropdown') {
                  let body_A = JSON.parse(column_A.body);
                  let body_B = JSON.parse(column_B.body);
                  let compare = 0;
                  if (body_A.length === 0) {
                     if (body_B.length === 0) {
                        compare = 0;
                     } else {
                        compare = 1;
                     }
                  } else {
                     if (body_B.length === 0) {
                        compare = -1;
                     } else {
                        compare = body_A[0].name.localeCompare(body_B[0].name);
                     }
                  }

                  compares[i] = (sortOption.direction === 'desc') ? (compare * (-1)) : compare;
               } else if (column_A.key === 'date') {
                  let compare = 0;
                  if (column_A.body === "") {
                     compare = (column_B.body === "") ? 0 : (1)
                  } else {
                     if (column_B.body === "") {
                        compare = -1;
                     } else {
                        let body_A = new Date(column_A.body);
                        let body_B = new Date(column_B.body);
                        if (body_A.getTime() > body_B.getTime()) {
                           compare = 1;
                        } else if (body_A.getTime() === body_B.getTime()) {
                           compare = 0;
                        } else {
                           compare = -1;
                        }
                     }
                  }
                  compares[i] = (sortOption.direction === 'desc') ? (compare * (-1)) : compare;
               } else if (column_A.key === 'numbers') {
                  let compare = 0;
                  if (column_A.body === "") {
                     if (column_B.body !== "") {
                        compare = -1;
                     }
                  } else {
                     if (column_B.body === "") {
                        compare = 1
                     } else {
                        if (parseFloat(column_A.body) > parseFloat(column_B.body)) {
                           compare = 1;
                        } else if (parseFloat(column_A.body) === parseFloat(column_B.body)) {
                           compare = 0;
                        } else {
                           compare = -1;
                        }
                     }
                  }

                  compares[i] = (sortOption.direction === 'desc') ? (compare * (-1)) : compare;
               } else {
                  let compare = 0;
                  if (column_A.body === "") {
                     if (column_B.body !== "") {
                        compare = 1;
                     }
                  } else {
                     if (column_B.body === "") {
                        compare = -1;
                     } else {
                        compare = column_A.body.localeCompare(column_B.body);
                     }
                  }

                  compares[i] = (sortOption.direction === 'desc') ? (compare * (-1)) : compare;
               }
            }
         }

         if (compares.length) {
            let compareResult = compares[0];

            for (let j = 1; j < compares.length; j++) {
               compareResult = compareResult || compares[j];
            }

            return compareResult;
         } else {
            return 0;
         }
      });


      let newGroupItems = [itemHeader, ...groupItems];

      groups[i].groupItems = newGroupItems;
   }

   return groups;
}

export const getNewGroupsChangedFormula = (groups, columnId, equation, activeItems, groupItem) => {
   let newGroups = groups;
   for (let i = 0; i < groups.length; i++) {
      for (let j = 1; j < groups[i].groupItems.length; j++) {
         let cellItems = groups[i].groupItems[j].cellItems;
         let cellItem = cellItems.find(cell => cell.columnId === columnId);
         let cellItemIndex = cellItems.findIndex(cell => cell.columnId === columnId);
         let body = calculatorEquation(equation, activeItems, groupItem, cellItems);
         console.log("body", body);
         cellItem.body = body;
         cellItem.equation = equation;
         cellItem.activeItems = activeItems;
         newGroups[i].groupItems[j].cellItems[cellItemIndex] = cellItem;
      }
   }
   return newGroups;
}