export const getAllMessages = "message/getmessages";
export const getMessageByBodyContent = "message/getMessageByBodyContent";
export const getMessageByIWasMentioned = "message/getMessageByIWasMentioned"
export const getMessageByIWasWrote = "message/getMessageByIWasWrote";
export const getAllMessagesGroupedbyUserAndFile = "message/getAllMessagesGroupedbyUserAndFile";
export const getMessageByType = "message/getMessageByType";

export const createmessage = "message/createmessage";
export const updatemessage = "message/updatemessage";
export const download = "message/download";

export default {
  getAllMessages: getAllMessages,
  getMessageByBodyContent: getMessageByBodyContent,
  getMessageByIWasMentioned: getMessageByIWasMentioned,
  getMessageByIWasWrote: getMessageByIWasWrote,
  getAllMessagesGroupedbyUserAndFile: getAllMessagesGroupedbyUserAndFile,
  getMessageByType: getMessageByType,
  createmessage: createmessage,
  updatemessage: updatemessage,
  download: download
}



