import React, { Component } from 'react'
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
import { SliderRail, Handle, Track, Tick } from "./components"; // example render components - source below
import { view, trbl, dims } from "./constants";
import HotTags from './hot-tags'
import Files from './files'
import People from './people'
import MainHotTags from './hot-tags/maintag'
import RelatedMe from './related-me'
import SavedSearch from './saved-search'
import RecentSearch from './recent-search'
import BoardData from './borad-data'
import Updates from './updates'
import Surface from "./Surface";
import './style.css'
import { connect } from 'react-redux';
import {
  onChangeSearch, onTabChange, onFocusSearch,
  onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs,
  onClickTag, onSavedSearchDelete, getHotTags, getFiles, getAllPeople
} from '../../actions/GlobalSearch'
import axios from '../../util/Api';
import ReactCanvasConfetti from 'react-canvas-confetti';

const sliderProps = {
  width: dims[0],
  height: dims[1],
  fill: "none",
  opacity: 0.5,
  stroke: "red"
};
const domain = [100, 500];
const defaultValues = [150, 300];
var viewArray = []
const Mousetrap = require("mousetrap");

function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}

const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0
}

class Drawer extends Component {
  constructor(props) {
    super(props)
    this.onTabClick = this.onTabClick.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onSaveSearch = this.onSaveSearch.bind(this)
    this.onSearchFocus = this.onSearchFocus.bind(this)
    this.onSearchBlur = this.onSearchBlur.bind(this)

    this.setSearchTermOnTagClick = this.setSearchTermOnTagClick.bind(this)
    this.mounted = false;
    this.state = {
      searchTerm: '',
      ishotkeymodalvisible: 'none',
      ishotkeymodalshow: 'modal fade text-left',
      categories: "",
      filteredCats: [],
      filteredSubCats: [],
      filteredNestedCats: []
    }

    this.authUser = JSON.parse(localStorage.getItem('authUser'));
    this.allowedCategories = {};

    this.isAnimationEnabled = false;
    this.animationInstance = null;
    this.intervalId = null;
  }
  onSearchChange = (e) => {

    let tabsearch = this.props.search.files
    if (this.props.search.tabposition == 5) {
      tabsearch = this.props.search.tags
    } else if (this.props.search.tabposition == 4) {
      tabsearch = this.props.search.people
    }
    this.props.onChangeSearch(this.props.search.savedsearch, e.target.value,
      this.props.search.userdetails._id, this.props.search.tabposition, tabsearch)

    setTimeout(() => {
      if (this.props.search.tabposition == 0) {
        this.props.onTabChange(1)
      }
    }, 100);
    this.setState({
      searchTerm: e.target.value
    })
  }
  setSearchTermOnTagClick = (value) => {
    this.setState({
      searchTerm: value
    })
    setTimeout(() => {
      this.onSearchFocus()
    }, 100)
    
  }
  onSearchFocus = () => {
    this.props.onFocusSearch(this.props.search.savedsearch, this.props.search.search)
  }
  onSearchBlur = () => {
    if (this.props.search.userdetails) {
      this.props.onBlurSearch(this.props.search.search, this.props.search.userdetails._id)
    }
  }
  onSaveSearch = () => {
    if (this.props.search.userdetails) {
      this.props.onSaveSearch(this.props.search.search, this.props.search.userdetails._id)
    }
  }

  onTabClick = (i) => {
    this.props.onTabChange(i)
    if (i == 3) {
      this.props.getFiles(this.props.search.userdetails._id)
    } else if (i == 4) {
      this.props.getAllPeople()
    }
  }
  componentDidMount() {
    if (this.props.search.userdetails) {
      this.onTabClick(0)
    }
    this.isAnimationEnabled = false;
    this.intervalId && clearInterval(this.intervalId);
    Mousetrap.bind("ctrl+b", () => {
      this.setState({ ishotkeymodalvisible: 'block' })
      this.setState({ ishotkeymodalshow: 'modal fade text-left show' })
      this.mounted = true;
      axios.get('/workspace/getAllWorkSpaces').then(({ data }) => {
        if (this.mounted) this.setState({ categories: data.workspaces })
      }).catch(err => {
        console.log("error==>", err)
      })

      return false;
    });


  }
  getInstance = (instance) => {
    this.animationInstance = instance
  }
  getAnimationSettings(originXA, originXB) {
    return {
      startVelocity: 30,
      spread: 360,
      ticks: 60,
      zIndex: 0,
      particleCount: 150,
      origin: {
        x: randomInRange(originXA, originXB),
        y: Math.random() - 0.2
      }
    }
  }
  nextTickAnimation = () => {
    this.animationInstance && this.animationInstance(this.getAnimationSettings(0.1, 0.3));
    this.animationInstance && this.animationInstance(this.getAnimationSettings(0.7, 0.9));
  }
  componentWillUnmount() {
    this.mounted = false;
  }
 
  
  renderBoards = () => {
    const steps = this.props.search.data && this.props.search.data.workspaceBoard &&
      this.props.search.data.workspaceBoard.map((item, index) =>
        <BoardData item={item} index={index} key={item._id} boardId={item._id}
          columnHeader={item.columnHeaders} searchTerm={this.state.searchTerm} />
      );
    console.log("this.props.search.data", this.props.search.data)
    return steps;
  }
  renderUpdates = () => {
    const steps = this.props.search.data && this.props.search.data.message &&
      this.props.search.data.message.map((item, index) =>
        <Updates item={item} />
      );
    return steps;
  }
  renderTags = () => {
    let steps = <></>
    if (this.props.search.data && this.props.search.data.tags) {
      steps = <HotTags item={this.props.search.data.tags} fnc={this.setSearchTermOnTagClick} />
    }

    if (!this.props.search.data.tags) {
      steps = <HotTags item={this.props.search.tags} fnc={this.setSearchTermOnTagClick} />
    }

    return steps

  }
  renderFiles = () => {

    let steps = <></>
    if (this.props.search.data && this.props.search.data.files) {
      steps = <Files item={this.props.search.data.files} />
    }
    if (!this.props.search.data.files) {
      steps = <Files item={this.props.search.files} />
    }

    return steps

  }
  renderPeople = () => {

    let steps = <></>
    if (this.props.search.data && this.props.search.data.people) {
      steps = <People item={this.props.search.data.people} />
    }
    if (!this.props.search.data.people) {
      steps = <People item={this.props.search.people} />
    }
    return steps

  }
  getAllNestedWorkspaceBoards = (index) => {
    console.log('level_1', this.state.categories[index].workspaceBoards.length)
    let y = this.state.categories[index].workspaceBoards;
    for (let i = 0; i < this.state.categories[index].subworkspaces.length; i++) {

      var x = [...y, ...this.state.categories[index].subworkspaces[i].workspaceBoards];
      console.log('level_2', x.length)

      for (let j = 0; j < this.state.categories[index].subworkspaces[i].subworkspaces.length; j++) {

        x = [...x, ...this.state.categories[index].subworkspaces[i].subworkspaces[j].workspaceBoards];

        console.log('level_3', x.length)
      }
    }
    console.log('getAllNestedWorkspaceBoards', x)
    return x;
  }

  filterWorkspaces = (workspace) => {
    //console.log('workspaceee',workspace)
    this.allowedCategories[workspace._id] = [];

    let isLevel_1;
    let isLevel_2;
    let isLevel_3;

    for (let x = 0; x < workspace.workspaceBoards.length; x++) {
      if (workspace.workspaceBoards[x].members.includes(this.authUser._id)
      ) {
        isLevel_1 = true;
        this.allowedCategories[workspace._id].push(1);

      }
    }

    for (let i = 0; i < workspace.subworkspaces.length; i++) {

      for (let z = 0; z < workspace.subworkspaces[i].workspaceBoards.length; z++) {

        if (workspace.subworkspaces[i].workspaceBoards[z].members.includes(this.authUser._id)) {
          isLevel_2 = true;

          this.allowedCategories[workspace._id].push(workspace.subworkspaces[i]._id);
          // this.subscribeToWorkspaceSocketRoom(workspace.subworkspaces[i]._id)
        }
      }

      for (let w = 0; w < workspace.subworkspaces[i].subworkspaces.length; w++) {

        for (let v = 0; v < workspace.subworkspaces[i].subworkspaces[w].workspaceBoards.length; v++) {

          if (workspace.subworkspaces[i].subworkspaces[w].workspaceBoards[v].members.includes(this.authUser._id)) {
            isLevel_3 = true;
            this.allowedCategories[workspace._id].push(workspace.subworkspaces[i]._id);
            this.allowedCategories[workspace._id].push(workspace.subworkspaces[i].subworkspaces[w]._id);
            // this.subscribeToWorkspaceSocketRoom(workspace.subworkspaces[i]._id)
            //this.subscribeToWorkspaceSocketRoom(workspace.subworkspaces[i].subworkspaces[w]._id)
          }
        }

      }
    }

    if ((workspace.CreatedBy === this.authUser._id) || workspace.admins.includes(this.authUser._id)) {
      this.allowedCategories[workspace._id].push('admin');
      //this.subscribeToWorkspaceSocketRoom(workspace._id)
      return true;
    }
    if (isLevel_1 || isLevel_2 || isLevel_3) {
      // this.subscribeToWorkspaceSocketRoom(workspace._id)
    }
    return isLevel_1 || isLevel_2 || isLevel_3;


  }

  getFilteredCats = (cats, subcats, nestedcats) => {
    console.log('cats,subcats,nestedcats', cats, subcats, nestedcats)
    this.setState({ filteredCats: cats })
    this.setState({ filteredSubCats: subcats })
    this.setState({ filteredNestedCats: nestedcats })
  }

  // subscribeToWorkspaceSocketRoom = (workspaceID) => {
  //     socket.emit('userConnectedWorkspaces',workspaceID);
  // }

  render() {

    return (
      <div id="globalSearchNav" class="overlay" style={{ 'width' : '0%' }}>

        <a href="javascript:void(0)" class="closebtn" onClick={() => {
          document.getElementById("globalSearchNav").style.width = "0%"
        }}>&times;</a>
        <div class="overlay-content">

          <div class="form-group has-search">
            <div class="badge badge-pill badge-light-warning savesearchbtn"
              style={{ 'display': this.props.search.savedfound == false ? 'block' : 'none' }}
              onClick={() => this.onSaveSearch()} >
              <i class="bx bxs-star" style={{ fontSize: '0.7rem' }}></i>Save
            </div>
            <div class="badge badge-pill badge-light-success savesearchbtn"
              style={{ 'display': this.props.search.savedfound == true ? 'block' : 'none' }} >
              <i class="bx bx-check" style={{ fontSize: '0.8rem' }}></i>Saved
            </div>
            <span class="fa fa-search form-control-feedback"></span>
            <input type="text" class="globalsearch" placeholder="Search Everything..."
              onFocus={() => this.onSearchFocus()}
              value={this.props.search.search} onChange={(e) => this.onSearchChange(e)} onBlur={() => this.onSearchBlur()} />
          </div>
          <section id="basic-tabs-components">
            <div class="card g-search-card">
              <div class="card-body g-search">
                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a className={this.props.search.tabposition == 0 ? this.props.search.classname : 'nav-link'} onClick={() => this.onTabClick(0)}
                      data-toggle="tab" role="tab" aria-selected="true" href="#">
                      <i class="bx bx-search align-middle"></i>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a className={this.props.search.tabposition == 1 ? this.props.search.classname : 'nav-link'} onClick={() => this.onTabClick(1)} data-toggle="tab" href="#" aria-controls="board" role="tab" aria-selected="false">
                      Cross Boards </a>
                  </li>
                  <li class="nav-item">
                    <a className={this.props.search.tabposition == 2 ? this.props.search.classname : 'nav-link'} onClick={() => this.onTabClick(2)} data-toggle="tab" href="#" aria-controls="update" role="tab" aria-selected="false">
                      Updates </a>
                  </li>
                  <li class="nav-item">
                    <a className={this.props.search.tabposition == 3 ? this.props.search.classname : 'nav-link'} onClick={() => this.onTabClick(3)} data-toggle="tab" href="#" aria-controls="file" role="tab" aria-selected="false">
                      Files </a>
                  </li>
                  {/* <li class="nav-item">
                    <a className={this.props.search.tabposition == 4 ? this.props.search.classname : 'nav-link'} onClick={() => this.onTabClick(4)} data-toggle="tab" href="#" aria-controls="people" role="tab" aria-selected="false">
                      People </a>
                  </li> */}
                  <li class="nav-item">
                    <a className={this.props.search.tabposition == 5 ? this.props.search.classname : 'nav-link'} onClick={() => this.onTabClick(5)} data-toggle="tab" href="#" aria-controls="tag" role="tab" aria-selected="false">
                      Tags </a>
                  </li>
                  {/* <li class="nav-item ">
                    <a className={this.props.search.tabposition == 6 ? this.props.search.classname : 'nav-link'} onClick={() => this.onTabClick(6)} data-toggle="tab" href="#" aria-controls="tag" role="tab" aria-selected="false">
                      Filter by date</a>
                  </li> */}
                </ul>
                <div class="tab-content">
                  <div class={this.props.search.tabposition == 0 ? this.props.search.tabcontentclass : 'tab-pane'} role="tabpanel">

                    {/* start */}
                    <div class="row">
                      <div class="col-xl-2 col-md-6 col-12 activity-card">
                        <div class="card tab-list-card">
                          <div class="card-header search-header">
                            <h4 class="card-title"><i class="bx bxs-hot" ></i> Hot Tags</h4>
                          </div>
                          {this.props.search.tabposition == 0 ? <MainHotTags fnc={this.setSearchTermOnTagClick} /> : ''}
                        </div>
                      </div>
                      <div class="col-xl-2 col-md-6 col-12 activity-card">
                        <div class="card tab-list-card">
                          <div class="card-header search-header">
                            <h4 class="card-title"><i class="bx bxs-user" ></i> Related to me</h4>
                          </div>
                          {this.props.search.tabposition == 0 ? <RelatedMe fnc={this.setSearchTermOnTagClick} /> : ''}
                        </div>
                      </div>
                      <div class="col-xl-2 col-md-6 col-12 activity-card">
                        <div class="card tab-list-card">
                          <div class="card-header search-header">
                            <h4 class="card-title"><i class="bx bxs-star" ></i> Saved searches</h4>
                          </div>
                          {this.props.search.tabposition == 0 ? <SavedSearch fnc={this.setSearchTermOnTagClick} /> : ''}
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6 col-12 activity-card">
                        <div class="card tab-list-card">
                          <div class="card-header search-header">
                            <h4 class="card-title"><i class="bx bx-time" ></i> Recent searches</h4>
                          </div>
                          {this.props.search.tabposition == 0 ? <RecentSearch fnc={this.setSearchTermOnTagClick}/> : ''}
                        </div>
                      </div>
                      <div class="col-xl-3 col-md-6 col-12 activity-card">
                        <div class="card tab-list-card">
                          <div class="card-header search-header">
                            <h4 class="card-title"><i class="bx bxs-zap" ></i> Quick search tip</h4>
                          </div>
                          <div class="card-body pt-4" style={{ 'display': 'flex' }}>
                            <h1 class="text-primary font-large-2 text-bold-500" style={{ 'padding': '10px', 'border': '2px solid', borderRadius: '10px', 'margin-left': '30px' }}>Ctrl</h1>
                            <h1 class="text-primary font-large-2 text-bold-500" style={{ 'padding': '10px' }}>+</h1>
                            <h1 class="text-primary font-large-2 text-bold-500" style={{ 'padding': '10px', 'border': '2px solid', borderRadius: '10px' }}>B</h1>

                          </div>
                          <label style={{ 'color': 'black' }}>Use this keyboard shortcut to find boards, dashboards and workplaces faster!</label>
                        </div>
                      </div>
                    </div>
                    {/* <div class="todo-item-action d-flex align-items-center people-search">
                      <div class="todo-badge-wrapper d-flex">
                        <span style={{ "color": "white", fontSize: "20px" }}>People search</span>
                      </div>
                      <div class="avatar mr-1 bg-secondary bg-lighten-3">
                        <span class="avatar-content">SP</span>
                      </div>
                      <div class="avatar mr-1 bg-secondary bg-lighten-3">
                        <span class="avatar-content">NV</span>
                      </div>
                      <div class="avatar mr-1 bg-secondary bg-lighten-3">
                        <span class="avatar-content">VV</span>
                      </div>
                    </div> */}
                    {/* end */}
                  </div>
                  <div class={this.props.search.tabposition == 1 ? this.props.search.tabcontentclass : 'tab-pane'} role="tabpanel">
                    {!this.props.search.data && !this.props.search.data.workspaceBoard &&
                      <div class="col-xl-3 col-md-12 col-12 growth-card">
                        <div class="card">
                          <div class="card-body text-center" style={{ "position": "relative", "max-height": "300px" }} >

                            <img src="images/magnifying_glass.png" height="120px" />
                            <h3>Boards search</h3>
                            <p>Search for whatever you want. Seriously, go crazy! Find anything like items,owners,dates,key words.</p>
                          </div>
                        </div>
                      </div>
                    }
                    {this.props.search.tabposition == 1 && this.renderBoards()}

                  </div>
                  <div class={this.props.search.tabposition == 2 ? this.props.search.tabcontentclass : 'tab-pane'} role="tabpanel">
                    <p>{this.props.search.tabposition == 2 && this.renderUpdates()}</p>
                  </div>
                  <div class={this.props.search.tabposition == 3 ? this.props.search.tabcontentclass : 'tab-pane'} role="tabpanel">
                    {this.props.search.tabposition == 3 && this.renderFiles()}
                  </div>
                  <div class={this.props.search.tabposition == 4 ? this.props.search.tabcontentclass : 'tab-pane'} role="tabpanel">
                    <p>{this.props.search.tabposition == 4 && this.renderPeople()}</p>
                  </div>
                  <div class={this.props.search.tabposition == 5 ? this.props.search.tabcontentclass : 'tab-pane'} role="tabpanel">
                    {this.props.search.tabposition == 5 && this.renderTags()}
                  </div>
                  <div class={this.props.search.tabposition == 6 ? this.props.search.tabcontentclass : 'tab-pane'} role="tabpanel">
                    {/* <p>
                      <Surface view={view} trbl={trbl}>
                        <Slider
                          mode={1}
                          step={1}
                          flatten
                          domain={domain}
                          component="rect"
                          onUpdate={this.onUpdate}
                          rootProps={sliderProps}
                          values={defaultValues}
                        >
                          <Rail>
                            {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
                          </Rail>
                          <Ticks>
                            {({ ticks, getHandleProps }) => (
                              <g transform={`translate(0,${dims[1]})`}>
                                {ticks.map(tick => (
                                  <Tick key={tick.id} tick={tick} />
                                ))}
                              </g>
                            )}
                          </Ticks>
                          <Tracks left={false} right={false}>
                            {({ tracks, getTrackProps }) => (
                              <g transform={`translate(0,${dims[1] / 2})`}>
                                {tracks.map(({ id, source, target }) => (
                                  <Track
                                    key={id}
                                    source={source}
                                    target={target}
                                    getTrackProps={getTrackProps}
                                  />
                                ))}
                              </g>
                            )}
                          </Tracks>
                          <Handles>
                            {({ handles, getHandleProps }) => (
                              <g transform={`translate(0,${dims[1] / 2})`}>
                                {handles.map(handle => (
                                  <Handle
                                    key={handle.id}
                                    handle={handle}
                                    domain={domain}
                                    getHandleProps={getHandleProps}
                                  />
                                ))}
                              </g>
                            )}
                          </Handles>
                        </Slider>
                      </Surface>
                    </p> */}
                  </div>
                  {!this.props.search.data &&
                    <img src="images/giphy.gif" style={{
                      "display":
                        this.props.search.tabposition == 1 || this.props.search.tabposition == 2 ||
                          this.props.search.tabposition == 3 || this.props.search.tabposition == 4 ||
                          this.props.search.tabposition == 5 || this.props.search.tabposition == 6 ?
                          'block' : "none", 'margin': '-200px auto', 'width': '100px', zIndex: '8888'
                      , 'position': 'relative', 'display': 'none'
                    }} />
                  }
                </div>
              </div>
            </div>
          </section>
        </div>

        <div class={this.state.ishotkeymodalshow} id="backdrop" style={{ 'display': this.state.ishotkeymodalvisible }} tabIndex="-1" aria-labelledby="myModalLabel4" aria-hidden="true" >
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" data-backdrop="true">
            <div class="modal-content" style={{ 'max-height': '400px' }}>
              <div class="modal-header">
                <div class="email-fixed-search flex-grow-1">
                  <div class="sidebar-toggle d-block d-lg-none">
                    <i class="bx bx-menu"></i>
                  </div>
                  <fieldset class="form-group position-relative has-icon-left m-0">
                    <input type="text" class="form-control" id="email-search" placeholder="Search email" />
                    <div class="form-control-position">
                      <i class="bx bx-search" style={{ lineHeight: '32px' }}></i>
                    </div>
                  </fieldset>
                </div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={() => {
                  this.setState({
                    ishotkeymodalshow: 'modal fade text-left',
                    ishotkeymodalvisible: 'none'
                  })
                }}>
                  <i class="bx bx-x"></i>
                </button>
              </div>
              <div class="modal-body">
                <div class="email-app-area">
                  <div class="email-app-list-wrapper">
                    <div class="email-app-list">
                      <div class="email-user-list list-group ps ps--active-y">
                        <ul class="users-list-wrapper media-list" style={{ 'padding': '0px', marginBottom: '0px' }}>
                          {this.state.categories.length > 0 ?
                            this.state.categories
                              .filter(category => this.state.filteredCats.length > 0 ? this.state.filteredCats.includes(category._id) : true)
                              .map((category, index) => (
                                <>
                                  {this.filterWorkspaces(category) && category.members.includes(this.authUser._id) &&
                                    <li class="media mail-read" style={{ "animation": "0s ease 0s 1 normal none running none" }}>
                                      <div class=" bg-rgba-warning m-0 mr-75 mr-xl-2"
                                        style={{ 'padding': '8px', borderRadius: '50px' }}>
                                        <div class="avatar-content">
                                          <i class="bx bx-area text-warning font-medium-2"></i>
                                        </div>
                                      </div>
                                      <div class="media-body">
                                        <div class="user-details">
                                          <div class="mail-items">
                                            <span class="list-group-item-text text-truncate d-flex" style={{
                                              'font-family': 'myriad-pro',
                                              'font-weight': '400',
                                              'line-height': '35px',
                                              fontSize: '1.2rem'
                                            }} dangerouslySetInnerHTML={{ __html: category.title }}></span>
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                  }
                                  {this.filterWorkspaces(category) &&
                                    <>
                                      {category.workspaceBoards.length > 0 ?
                                        category.workspaceBoards
                                          .filter(workspaceBoards => workspaceBoards.members.includes(this.authUser._id))
                                          .map((board, index) => {
                                            return (
                                              <li class="media mail-read" style={{ 'padding': '0rem 3rem' }} onClick={() => {
                                                window.location = "/board/" + board._id
                                              }}>
                                                <div class=" bg-rgba-success m-0 mr-75 mr-xl-2"
                                                  style={{ 'padding': '8px', borderRadius: '50px' }}>
                                                  <div class="avatar-content">
                                                    <i class="bx bx-window"></i>
                                                  </div>
                                                </div>
                                                <div class="media-body">
                                                  <div class="user-details">
                                                    <div class="mail-items">
                                                      <span class="list-group-item-text text-truncate d-flex" style={{
                                                        'font-family': 'myriad-pro',
                                                        'font-weight': '400',
                                                        'line-height': '35px',
                                                        fontSize: '1.2rem'
                                                      }} dangerouslySetInnerHTML={{ __html: board.title }}></span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            )
                                          }) : ''}

                                      {category.subworkspaces
                                        .filter(subworkspace => this.state.filteredSubCats.length > 0 ? this.state.filteredSubCats.includes(subworkspace._id) : true)
                                        .map((subCategory, index_1) => (
                                          <>
                                            {(this.allowedCategories[category._id].includes('admin') || this.allowedCategories[category._id].includes(subCategory._id)) &&
                                              <li class="media mail-read" style={{ 'padding': '0rem 2rem' }}>
                                                <div class=" bg-rgba-primary m-0 mr-75 mr-xl-2"
                                                  style={{ 'padding': '8px', borderRadius: '50px' }}>
                                                  <div class="avatar-content">
                                                    <i class="bx bx-folder"></i>
                                                  </div>
                                                </div>
                                                <div class="media-body">
                                                  <div class="user-details">
                                                    <div class="mail-items">
                                                      <span class="list-group-item-text text-truncate d-flex" style={{
                                                        'font-family': 'myriad-pro',
                                                        'font-weight': '400',
                                                        'line-height': '35px',
                                                        fontSize: '1.2rem'
                                                      }} dangerouslySetInnerHTML={{ __html: subCategory.title }}></span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </li>
                                            }
                                            {(this.allowedCategories[category._id].includes('admin') || this.allowedCategories[category._id].includes(subCategory._id)) &&
                                              <>
                                                {subCategory.workspaceBoards
                                                  .filter(workspaceBoards => workspaceBoards.members.includes(this.authUser._id))
                                                  .map((board, index_2) => (
                                                    <li class="media mail-read" style={{ "padding": "1rem 3rem" }} 
                                                    onClick={() => {
                                                      // http.get(workspaceApi.GET_WORKSPACE_BY_BOARDID + "?id=" + this.props.mainBoardId)
                                                      //     .then((result) => {
                                                      //         window.location = "/board/"+ this.props.mainBoardId
                                                      //     }).catch(error => {
              
                                                      //     })
                                                      window.location = "/board/"+ board._id
                                                  }}
                                                    >
                                                      <div class=" bg-rgba-success m-0 mr-75 mr-xl-2"
                                                        style={{ 'padding': '8px', borderRadius: '50px' }}>
                                                        <div class="avatar-content">
                                                          <i class="bx bx-window"></i>
                                                        </div>
                                                      </div>
                                                      <div class="media-body">
                                                        <div class="user-details">
                                                          <div class="mail-items">
                                                            <span class="list-group-item-text text-truncate d-flex" style={{
                                                              'font-family': 'myriad-pro',
                                                              'font-weight': '400',
                                                              'line-height': '35px',
                                                              fontSize: '1.2rem'
                                                            }} dangerouslySetInnerHTML={{ __html: board.title }}></span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  ))}
                                                {subCategory.subworkspaces
                                                  .filter(subworkspace => this.state.filteredNestedCats.length > 0 ? this.state.filteredNestedCats.includes(subworkspace._id) : true)
                                                  .map((subworkspace, index_3) => (
                                                    <>
                                                      {(this.allowedCategories[category._id].includes('admin') || this.allowedCategories[category._id].includes(subworkspace._id)) &&
                                                        <li class="media mail-read" style={{ 'padding': '0rem 2rem' }}>
                                                          <div class=" bg-rgba-primary m-0 mr-75 mr-xl-2"
                                                            style={{ 'padding': '8px', borderRadius: '50px' }}>
                                                            <div class="avatar-content">
                                                              <i class="bx bx-folder"></i>
                                                            </div>
                                                          </div>
                                                          <div class="media-body">
                                                            <div class="user-details">
                                                              <div class="mail-items">
                                                                <span class="list-group-item-text text-truncate d-flex" style={{
                                                                  'font-family': 'myriad-pro',
                                                                  'font-weight': '400',
                                                                  'line-height': '35px',
                                                                  fontSize: '1.2rem'
                                                                }} dangerouslySetInnerHTML={{ __html: subworkspace.title }}></span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </li>
                                                      }
                                                      {(this.allowedCategories[category._id].includes('admin') || this.allowedCategories[category._id].includes(subworkspace._id)) &&
                                                        <>
                                                          {subworkspace.workspaceBoards
                                                            .filter(workspaceBoards => workspaceBoards.members.includes(this.authUser._id))
                                                            .map((board, index_2) => (
                                                              <li class="media mail-read" style={{ "padding": "1rem 3rem" }}>
                                                                <i class="bx bx-window"></i>
                                                                <div class="media-body">
                                                                  <div class="user-details">
                                                                    <div class="mail-items">
                                                                      <span class="list-group-item-text text-truncate d-flex" style={{
                                                                        'font-family': 'myriad-pro',
                                                                        'font-weight': '400',
                                                                        'line-height': '35px',
                                                                        fontSize: '1.2rem'
                                                                      }} dangerouslySetInnerHTML={{ __html: board.title }}></span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </li>
                                                            ))}
                                                        </>
                                                      }
                                                    </>
                                                  ))}
                                              </>
                                            }
                                          </>
                                        ))}
                                    </>
                                  }
                                </>
                              )
                              ) : ""
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <ReactCanvasConfetti refConfetti={this.getInstance} style={canvasStyles} />
      </div>
    )
  }
}

const mapStateToProps = ({ search }) => {
  return { search }
}

const mapDispatchToProps = {
  onChangeSearch, onTabChange, onFocusSearch,
  onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs, onClickTag,
  onSavedSearchDelete, getHotTags, getFiles, getAllPeople
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);