import React, { Component, useState, useEffect } from 'react';
import { Modal } from "react-responsive-modal";
import PropTypes from 'prop-types'
import config from '../services/config.json';
import http from '../services/http-service';
import workspaceDataService from './../../data-services/workspace-data-services';
import boardDataService from './../../data-services/board-data-services';
const SchedulerFilter = (props) => {

    const [mainWorkspace, setMainWorkspace] = useState([]);
    const [subWorkSpace, setSubWorkSpace] = useState([]);
    const [board, setBoard] = useState([]);
    const [subBoard, setSubBoard] = useState([]);
    const [filterValues, setFilterValues] = useState([]);

    useEffect(() => {

        getWorkSpaceAll();
        getSubWorkSpaceAll();
        getBoardAll();
        getSubBoardAll();
    }, []);


    const handleOnFilter = () => {
        props.onFilterClick(filterValues);
        props.onClose();
    }
    const handleOnReset = () => {
        setFilterValues([{}]);
        props.onClose();
        props.onFilterClick(filterValues);

    }


    const getWorkSpaceAll = async () => {
        await http.get(config.apiEndPoint + workspaceDataService.getAllWorkSpacesNested)
            .then(response => {
                setMainWorkspace(response.data.response);

            });
    }
    const getSubWorkSpaceAll = async () => {
        await http.get(config.apiEndPoint + workspaceDataService.getAllSubWorkSpacesNested)
            .then(response => {
                setSubWorkSpace(response.data.response);

            });
    }

    const getBoardAll = async () => {
        await http.get(config.apiEndPoint + boardDataService.getAllBoardsNested)
            .then(response => {
                setBoard(response.data.response);


            });
    }

    const getSubBoardAll = async () => {
        await http.get(config.apiEndPoint + boardDataService.getAllSubBoardsNested)
            .then(response => {
                setSubBoard(response.data.response);


            });
    }


    const onChange = (key, value) => {
        setFilterValues(prev => ({
            ...prev,
            [key]: value,
        }));
    }
    return (
        <div>
            <Modal open={props.open} onClose={props.onClose} closeOnOverlayClick={props.closeOnOverlayClick} showCloseIcon={false}>
                <div style={{ width: '450px' }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ backgroundColor: '#22386B', color: '#fff', height: '45px', fontSize: '10px' }}>
                            <h4 className="modal-title  text-white" >Filter By</h4>
                            <button type="button" className="close-pink" data-dismiss="modal" aria-label="Close" onClick={props.onClose}>
                                <i className="bx bx-x" style={{ color: '#ffffff', marginTop: '2px' }}></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            <section id="floating-label-input">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-control-label" style={{ fontSize: '13px', fontWeight: '100' }}>Workspace</label>
                                                <select className="form-control outline" style={{ fontSize: '11px', height: '30px' }}
                                                    value={filterValues['workspace']}
                                                    onChange={e => onChange('workspace', e.target.value)}
                                                >
                                                    <option value="0">[ALL]</option>
                                                    {mainWorkspace.map(ws => (
                                                        <option
                                                            key={ws._id}
                                                            value={ws._id}
                                                        >
                                                            {ws.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <br />
                                            </div>

                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-control-label" style={{ fontSize: '13px', fontWeight: '100' }}>Sub Workspace</label>
                                                <select type="text" className="form-control outline" style={{ fontSize: '11px', height: '30px' }}
                                                    value={filterValues['subworkspace']}
                                                    onChange={e => onChange('subworkspace', e.target.value)}
                                                >
                                                    <option value="0">[ALL]</option>
                                                    {subWorkSpace.map(sw => (
                                                        <option
                                                            key={sw._id}
                                                            value={sw._id}
                                                        >
                                                            {sw.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <br />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-control-label" style={{ fontSize: '13px', fontWeight: '100' }}>Board</label>
                                                <select type="text" className="form-control outline"
                                                    style={{ fontSize: '11px', height: '30px' }}
                                                    value={filterValues['board']}
                                                    onChange={e => onChange('board', e.target.value)}
                                                >
                                                    <option value="0">[ALL]</option>
                                                    {board.map(b => (
                                                        <option
                                                            key={b._id}
                                                            value={b._id}
                                                        >
                                                            {b.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <br />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <label className="form-control-label" style={{ fontSize: '13px', fontWeight: '100' }}>Sub Board</label>
                                                <select type="text" className="form-control outline"
                                                    style={{ fontSize: '11px', height: '30px' }}
                                                    value={filterValues['subboard']}
                                                    onChange={e => onChange('subboard', e.target.value)}
                                                >
                                                    <option value="0">[ALL]</option>
                                                    {subBoard.map(sb => (
                                                        <option
                                                            key={sb._id}
                                                            value={sb._id}
                                                        >
                                                            {sb.Name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <br />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        <div className="modal-footer">
                            <div className="col-lg-12 text-right">
                                <button type="button" className="btn-custom-1 " id="filter" onClick={handleOnFilter}
                                >
                                    <i className="bx bx-filter" style={{ color: '#ffffff', marginTop: '2px' }}></i>
                    Filter</button> &nbsp;&nbsp;&nbsp;

                    <button type="button" className="btn-custom-1 " id="reset" onClick={handleOnReset}
                                >
                                    <i className="bx bx-reset" style={{ color: '#ffffff', marginTop: '2px' }}></i>
                    Reset</button> &nbsp;&nbsp;&nbsp;
                </div>


                        </div>
                    </div>

                </div>
            </Modal>
        </div>
    );
}
SchedulerFilter.defaultProps = {
    closeOnOverlayClick: true
}

export default SchedulerFilter;