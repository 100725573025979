import React, { Component } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import "react-tabs/style/react-tabs.css";
import Checkbox from "react-checkbox-component";
import http from "../../services/http-service";
import * as savedSearch from "../saved-search/constant";
import * as recentSearch from "../recent-search/constant";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import "../src/css/high-light.css";
import "../card.css";
import "../src/css/style.css";
import * as Tag from "./constant";
import Moment from "moment";

const suggestions = [];

function renderInput(inputProps) {
  const { classes, ref, ...other } = inputProps;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: ref,
        classes: {
          input: classes.input,
        },
        ...other,
      }}
    />
  );
}
function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong
              key={String(index)}
              style={{ fontWeight: "bold", color: "purple", fontSize: "18" }}
            >
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}
function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}
function getSuggestionValue(suggestion) {
  return suggestion.label;
}
function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 &&
          suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}
const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: "relative",
    height: 250,
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
});

class TagsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      suggestions: [],
      isSavedSearchChecked: false,
      isHorezintalDatePickerShow: false,
      selectedDate: "",
      tabIndex: 0,
      inputHighLightKeyWords: [],
      content: "New Group",
      groups: [],
      groupItemsData: [],
      error: false,
      hintData: [],
      text: "",
      hotTagData: [],
      allHotTagData: [],
    };
  }

  componentDidMount() {
    this._initHotTagSearch();
    let searchName = this.props.searchName;
    if (searchName !== "") {
      this.searchResult(searchName);
    }
    try {
      let searchTag = this.props.quickSearchSavedObj.searchNameDataSavedSearch;
      if (searchTag !== "") {
        this.searchResult(searchTag);
      }
    } catch (ex) {}
  }

  _initHotTagSearch = async () => {
    await http.get(Tag.GET_ALL_TAGS).then((hotTagData) => {
      this.setState({
        allHotTagData: hotTagData.data.response,
      });
      this.state.allHotTagData.map((tag) => {
        suggestions.push({ label: tag.tagName });
      });
    });
  };

  searchResult = async (searchValue) => {
    //serach by first name
    const tagNameData = {
      tagName: searchValue,
    };
    await http.post(Tag.GET_TAGS_BY_NAME, tagNameData).then((hotTagName) => {
      this.setState({
        hotTagData: hotTagName.data.tagName,
      });
      localStorage.setItem("totalHotTagRecords", 0);
      localStorage.setItem("totalHotTagRecords", this.state.hotTagData.length);
    });
    this.saveRecentSavedSearch();
    if (this.state.hotTagData.length !== 0) return;
  };

  saveRecentSavedSearch = async () => {
    const savedsearchData = {
      searchName: this.state.value,
      searchUser: "5fe43e2d91056e9f478549c7",
    };
    if (this.state.isSavedSearchChecked === true) {
      http
        .post(savedSearch.SAVE_SAVED_SEARCH, savedsearchData)
        .then((result) => {
          console.log(result, "saved search is saved successfully");
        });
    }
    const recentSearchData = {
      searchName: this.state.value,
      searchUser: "5fe43e2d91056e9f478549c7",
    };
    http
      .post(recentSearch.SAVE_RECENT_SEARCH, recentSearchData)
      .then((result) => {
        console.log("saved search is saved successfully", result);
      });
  };

  onSavedSerachChange = () => {
    this.setState({ isSavedSearchChecked: !this.state.isSavedSearchChecked });
  };

  filterByDate = () => {
    this.setState({
      isHorezintalDatePickerShow: !this.state.isHorezintalDatePickerShow,
    });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  render() {
    const { classes } = this.props;
    const { hotTagData } = this.state;
    return (
      <>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderInputComponent={renderInput}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestionsContainer}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            classes,
            placeholder: "Type Your Text Search Here ...",
            value: this.state.value,
            onChange: this.handleChange,
          }}
        />

        <button
          className="btn btn-primary col-md-2"
          style={{ marginRight: "5px", height: "50px" }}
          onClick={() => this.searchResult(this.state.value)}
        >
          Search
        </button>
        <div style={{ marginTop: "10px" }}>
          <Checkbox
            size="small"
            isChecked={this.state.isSavedSearchChecked}
            onChange={this.onSavedSerachChange}
            color="#fc14bb"
          />
          <label style={{ marginTop: "3px" }}>Save search</label>
        </div>

        <div
          className="col-md-12"
          style={{ marginTop: "100px", clear: "both" }}
        ></div>

        <div className="row">
          <div
            className="col-md-12"
            style={{ height: "500px", margin: "20px", overflow: "scroll" }}
          >
            <div className="col-md-8" style={{ float: "left" }}>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Tag Name</th>
                    <th scope="col">Update At</th>
                  </tr>
                </thead>
                <tbody>
                  {hotTagData.map((tag, index) => (
                    <tr key={index}>
                      <th>{tag.tagName}</th>
                      <th>
                        {Moment(tag.updatedAt).format("YYYY-MM-DD hh:mm:ss")}
                      </th>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}
TagsSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(TagsSearch);
