import React from 'react';
import { connect } from 'react-redux'
import Form from '../common/form.jsx';
import * as UserService from '../auth-services/user-service.jsx';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { userSignIn } from '../../../actions'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class LoginForm extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                email: "",
                password: "",
            },
            isRememberSelected: false,
            
        }
    }

    onChangeUserEmail = (e) => { this.setState({ data: { ...this.state.data, email: e.target.value } }) }
    onChangeUserpassword = (e) => { this.setState({ data: { ...this.state.data, password: e.target.value } }) }

    onChangeRemeberMe = (e) => {
        if (this.state.isRememberSelected === false) {
            this.state.isRememberSelected = true
            this.setState({ isRememberSelected: true })
            // alert(this.state.isRememberSelected); 
            return
        }
        else if (this.state.isRememberSelected === true)
            this.state.isRememberSelected = false
        this.setState({ isRememberSelected: false })
        // alert(this.state.isRememberSelected); 
        return
    }



    loginSubmit = async (e) => {
        e.preventDefault();
        const { data } = this.state;
        console.log("data_____", data)
        try {
            // UserService.login(data);
            //Redirect To Home Page
            // window.location = "/";
            this.props.userSignIn({
                email: data.email,
                password: data.password
            })

        } catch (ex) {
            debugger;
            if (ex.response && ex.response.status === 400) {

                toast.error('Oops! '+ex.response.data.message);

               // alert('Some Thing Went Wrong...Please Try Again Or Contact With Support....');
                // const errors = {...this.state.errors}
                // errors.errors = ex.response.data; 
                // this.setState({
                // errors
                // })
                // console.log(errors , ">>>>>errors"); 
            }
        }
    }

    openModal = () => {
        this.props.openModal()
    }
    render() {
        const { userEmail, password } = this.state;
        return (
            <>
                <form name="loginForm" >

                    <div className="form-group mb-50">
                        {
                            this.renderLoginInput_1("text", userEmail, "Email Id", this.onChangeUserEmail)
                        }
                    </div>
                    <div className="form-group">
                        {
                            this.renderLoginInput_2("password", password, "Password", this.onChangeUserpassword)
                        }
                    </div>
                    {
                        this.renderLoginCheckBox("Remember Me", "Forgot Password", this.state.isRememberSelected, this.onChangeRemeberMe, this.openModal)
                    }
                    <button type="submit" className="btn btn-danger w-100 position-relative mt-2"
                        onClick={this.loginSubmit}
                        style={{ backgroundColor: '#ff459e !important', border: 'none' }}>
                        Sign In<i id="icon-arrow" className="bx bx-right-arrow-alt" />
                    </button>
                </form>
            </>
        );
    }
}

const mapStateToProps = ({ auth }) => {
    const { authUser, token } = auth;
    return { authUser, token }
}

const mapDispatchToProps = { userSignIn };
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
