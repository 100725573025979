import React from 'react';

function show() {
    var p = document.getElementById('pwd');
    p.setAttribute('type', 'text');
}

function hide() {
    var p = document.getElementById('pwd');
    p.setAttribute('type', 'password');
}
var pwShown = 0;
   

function LoginInputInput({ label, error, ...rest }) {
    
    return (
        <fieldset className="form-label-group form-group position-relative has-icon-right">
            <input type="password" {...rest}
                className="form-control login bg-transparent" required
                style={{
                    fontSize: '16px', fontWeight: 300, border: '1px solid #CCC', outline: 'none', height: '35px !important',
                    color: '#ffffff !important'
                }} id='pwd'/>
            <div className="form-control-position" style={{ lineHeight: '1.8' }}>
            
                <img src="images/icon/auth/Mobileicons-205w.png" style={{ width: '55%', marginTop: '5px' }} 
               onClick={()=>{
                if (pwShown == 0) {
                    pwShown = 1;
                    show();
                  } else {
                    pwShown = 0;
                    hide();
                  }
               }} id="eye"
                />
           
            </div>
            <span htmlFor="iconLabelLeft" style={{
                textTransform: 'none', position: 'absolute', padding: '0px 10px 0px 10px',
                top: '-8px', left: '15px', backgroundColor: '#53297a', color: '#ffffff', borderRadius: '5px',
                fontSize: '14px', fontWeight: '300'
            }}>{label}</span>

            { error && <div className="alert alert-danger">{error.message}</div>}

        </fieldset>
    );
 }

export default LoginInputInput;