import React , { useState } from 'react';
import DatePicker from "react-datepicker";
import '../profile-user/style.css'

//https://github.com/Hugand/react-checkbox-component#readme
function UserProfileCalendarInput({ label, error, ...rest }) {
    const [startDate, setStartDate] = useState(new Date());
    return (
    <div class="form-label-group">
    <DatePicker selected={rest.value} onChange={rest.onChange} className="form-control outline user-profile-outline" showMonthDropdown showYearDropdown/>
    <span class="user-profile-label" style={{
    position: 'absolute',
    top: '-5px',
    color: '#21396c',
    left: '20px',
    backgroundColor: '#fff'}}>{label}<span class="asterik">*</span></span>
    { error && <div className="alert alert-danger">{error.message}</div>}
    </div>
    );
}

export default UserProfileCalendarInput;