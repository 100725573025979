import React from 'react';
import { connect } from 'react-redux'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import axios from '../../../../../util/Api';
import { fetchError, updateWorkspaceBoard } from '../../../../../actions';

class BoardPermission extends React.Component {

    
    constructor(props) {
        super(props);
        this.state = {
            permission: '',
            activePermissionImg: '',
            activePermissionContent: '',
            img: '',
            content: ''
        }
        this.editRowRestrictedMembers = this.props.boardData.workspaceBoard.editRowRestrictedMembers?this.props.boardData.workspaceBoard.editRowRestrictedMembers:[]
        this.modalContainer = React.createRef()
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    componentDidMount() {
        let workspaceBoard = this.props.boardData.workspaceBoard;
        let permission = workspaceBoard.permission ? workspaceBoard.permission : 'edit-everything';
        this.setState({ permission: permission })
        this.setInitialPermission(permission)
        console.log('edit-everything', this.props)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.boardData.workspaceBoard.permission !== nextProps.boardData.workspaceBoard.permission) {
            this.setState({ permission: nextProps.boardData.workspaceBoard.permission })
            this.setInitialPermission(nextProps.boardData.workspaceBoard.permission)
        }
    }

    setInitialPermission = (permission) => {
        switch (permission) {
            case 'edit-everything':
                this.setState({
                    img: 'permissions-everyone.png', content: 'Add/Move groups, columns and rows. \n Edit content in all rows. \n Write updates.',
                    activePermissionImg: 'permissions-everyone.png', activePermissionContent: 'Add/Move groups, columns and rows. \n Edit content in all rows. \n Write updates.'
                })
                break;
            case 'edit-content':
                this.setState({
                    img: 'permissions-collaborators.png', content: 'Add/Move rows.\n Edit content in all rows. \n Write updates. ',
                    activePermissionImg: 'permissions-collaborators.png', activePermissionContent: 'Add/Move rows.\n Edit content in all rows. \n Write updates. '
                })
                break;
            case 'edit-personal':
                this.setState({
                    img: 'permissions-assignee.png', content: 'Team members can only edit rows assigned to them in a selected person column.',
                    activePermissionImg: 'permissions-assignee.png', activePermissionContent: 'Team members can only edit rows assigned to them in a selected person column.'
                })
                break;
            default:
                this.setState({
                    img: 'permissions-owners.png', content: 'View board content.\n Write updates.',
                    activePermissionImg: 'permissions-owners.png', activePermissionContent: 'View board content.\n Write updates.'
                })
                break;
        }
    }

    setBoardPermission = (permission) => {
        this.setState({ permission: permission })
        axios.post('/workspace/updateUserPermission', {
            workspaceBoardId: this.props.boardData.boardID,
            permission: permission,
            editRowRestrictedMembers: this.editRowRestrictedMembers
        }).then(({ data }) => {
            this.props.updateWorkspaceBoard({
                workspaceBoard: data.board,
                workspaceID: this.props.boardData.workspaceID,
                editRowRestrictedMembers: this.editRowRestrictedMembers
            })
        }).catch(err => {
            this.props.fetchError(err)
        })
    }

    showBoardPermisstionContent = (permission) => {
        switch (permission) {
            case 'edit-everything':
                this.setState({ img: 'permissions-everyone.png', content: 'Add/Move groups, columns and rows. \n Edit content in all rows. \n Write updates.' })
                break;
            case 'edit-content':
                this.setState({ img: 'permissions-collaborators.png', content: 'Add/Move rows.\n Edit content in all rows. \n Write updates. ' })
                break;
            case 'edit-personal':
                this.setState({ img: 'permissions-assignee.png', content: 'Team members can only edit rows assigned to them in a selected person column.' })
                break;
            default:
                this.setState({ img: 'permissions-owners.png', content: 'View board content.\n Write updates.' })
                break;
        }
    }

    showActivePermissionContent = () => {
        this.setState({ img: this.state.activePermissionImg, content: this.state.activePermissionContent })
    }

    handleInputChange = (event) => {

        const cat = event.target
        const memberID = cat.value

        if (this.editRowRestrictedMembers ? this.editRowRestrictedMembers.includes(memberID) : false) {
            this.editRowRestrictedMembers.pop(memberID);
            event.target.checked = false;
               
        } else {
            this.editRowRestrictedMembers.push(memberID);
            event.target.checked = true;
       }
       console.log('this.editRowRestrictedMembers',this.editRowRestrictedMembers)
       this.setBoardPermission('edit-personal');
        
    }


    
    render() {
        return (
            <>
                <Modal isOpen={this.props.modalOpen} ref={this.modalContainer} size='xl' className='modal-dialog-centered modal-lg'>
                    <ModalHeader toggle={() => this.props.closeModal(false)}>
                        Board Permissions
                    </ModalHeader>
                    <ModalBody>
                        <div className='p-2'>
                            <div className='board-permission-header'>Choose what everyone can do on this board:</div>
                            <div className='row'>
                                <div className={this.state.permission === 'edit-personal'?'col-lg-6':'col-lg-7'}>
                                    <div className={`card card-body ${this.state.permission === 'edit-everything' ? 'active' : ''}`} style={{ cursor: 'pointer' }}
                                        onClick={() => this.setBoardPermission('edit-everything')}
                                        onMouseEnter={() => this.showBoardPermisstionContent('edit-everything')}
                                        onMouseLeave={() => this.showActivePermissionContent()}
                                    >
                                        <div className='permission-content'>
                                            <div className='w-100' >
                                                Edit everything
                                                </div>
                                            {this.state.permission === 'edit-everything' ? (
                                                <div className='permission-check'>
                                                    <i className='fa fa-check'></i>
                                                </div>
                                            ) : ''}
                                        </div>
                                    </div>
                                    <div>
                                        <div className={`card card-body ${this.state.permission === 'edit-content' ? 'active' : ''}`} style={{ cursor: 'pointer' }}
                                            onClick={() => this.setBoardPermission('edit-content')}
                                            onMouseEnter={() => this.showBoardPermisstionContent('edit-content')}
                                            onMouseLeave={() => this.showActivePermissionContent()}
                                        >
                                            <div className='permission-content'>
                                                <div className='w-100' >
                                                    Edit content
                                                </div>
                                                {this.state.permission === 'edit-content' ? (
                                                    <div className='permission-check'>
                                                        <i className='fa fa-check'></i>
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={`card card-body ${this.state.permission === 'edit-personal' ? 'active' : ''}`} style={{ cursor: 'pointer' }}
                                            onClick={() => this.setBoardPermission('edit-personal')}
                                            onMouseEnter={() => this.showBoardPermisstionContent('edit-personal')}
                                            onMouseLeave={() => this.showActivePermissionContent()}
                                        >
                                            <div className='permission-content'>
                                                <div className='w-100' >
                                                    Edit rows assigned to them In the Person column
                                                </div>
                                                {this.state.permission === 'edit-personal' ? (
                                                    <div className='permission-check'>
                                                        <i className='fa fa-check'></i>
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={`card card-body ${this.state.permission === 'view-only' ? 'active' : ''}`} style={{ cursor: 'pointer' }}
                                            onClick={() => this.setBoardPermission('view-only')}
                                            onMouseEnter={() => this.showBoardPermisstionContent('view-only')}
                                            onMouseLeave={() => this.showActivePermissionContent()}
                                        >
                                            <div className='permission-content'>
                                                <div className='w-100' >
                                                    View only
                                                </div>
                                                {this.state.permission === 'view-only' ? (
                                                    <div className='permission-check'>
                                                        <i className='fa fa-check'></i>
                                                    </div>
                                                ) : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.permission === 'edit-personal' &&
                                <div className='col-lg-2'>
                                <p>Restrict members</p>
                                <div className="list-group">
                                {JSON.parse(this.props.boardData.workspaceBoard.members)
                                 .filter(member => (member._id != this.authUser._id) && (member._id != this.props.boardData?.workspace?.CreatedBy) && !member.is_bAdmin && !this.props.boardData?.workspace?.admins.includes(member._id))
                                .map((member, index) =>    
                                <a className="list-group-item list-group-item-action p-0 mb-2 border" key={index}>
                                    
                                            <label className="form-check-label">
                                                <input type="checkbox" className="form-check-input"
                                                    type="checkbox"
                                                      checked={this.props.boardData.workspaceBoard.editRowRestrictedMembers ? this.props.boardData.workspaceBoard.editRowRestrictedMembers.includes(member._id) : false}
                                                    className="subCat"
                                                    onChange={this.handleInputChange}
                                                    value={member._id}
                                                    name={member._id} /> {member.name}
                                            </label>

                                        
                                </a>
                                )}
                                </div>
                                </div>
}

                                <div className={this.state.permission === 'edit-personal'?'col-lg-4':'col-lg-5'}>
                                    <div className='board-permission-detail'>
                                        <div className='w-100 text-center'>
                                            <img src={`/images/permission/${this.state.img}`} style={{ width: '96%' }} />
                                        </div>
                                        <div className='board-permission-detail-content'>
                                            {this.state.content}
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='board-permission-footer'>Board owners can do everything on this board</div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { fetchError, updateWorkspaceBoard }

export default connect(mapStateToProps, mapDispatchToProps)(BoardPermission)