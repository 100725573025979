import React from 'react';
import WorkspaceSlideIcon from './WorkspaceSlideIcon';
import WorkspaceEllipsis from './WorkspaceEllipsis';
import WorkspaceSearch from './WorkspaceSearch';
import WorkspaceSideCategories from './WorkspaceSideCategories';


class WorkspaceNavBar extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div>
                <nav id="sidebar">
                    
                    <WorkspaceSlideIcon></WorkspaceSlideIcon>

                    <WorkspaceEllipsis></WorkspaceEllipsis>
                   
                    {/* <WorkspaceSearch></WorkspaceSearch> */}

                    <WorkspaceSideCategories></WorkspaceSideCategories>

                    {/* <WorkspacePanelCollapse></WorkspacePanelCollapse> */}
                </nav>
            </div>
        )
    }
}

export default WorkspaceNavBar;