import React from 'react';
import Form from '../common/form.jsx';

function TextInput({label , error , ...rest}) {
    return (
        <div className="form-group col-md-6 mb-50">
            <fieldset className="form-label-group form-group position-relative">
                <input {...rest} className="form-control" required style={{ fontSize: '16px', fontWeight: 300, border: '1px solid #CCC' }} />
                <label htmlFor="inputfirstname4" 
                style={{ textTransform: 'none', fontSize: '14px', position: 'absolute', padding: '0px 10px 0px 10px', top: '-8px', left: '15px', backgroundColor: '#ffffff', color: '#999999 !important', fontWeight: 300 }}>
                    {label}<span class="asterik">*</span></label>
                    
                    {/* <label>{label}<span>*</span></label> */}
                    { error && <div className="alert alert-danger">{error.message}</div>}

            </fieldset>
        </div>
    );
}

export default TextInput;