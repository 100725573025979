/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import http from '../../services/http-service';
import Modal from './modal'
import StaticHTML from '../common/static-html.jsx';
import BusinessCategoryTable from './business-categoryTable';
import * as BusinessCategoryConstant from './constant';
import Joi from 'joi-browser';
import Form from '../common/Form.jsx';
import MainMenu from '../../../shared/components/main-menu';
import NavBar from '../../../shared/components/main-menu';
import Notification from '../../notification/notification';
import UserService from "../../auth/auth-services/user-service";

class BusinessCategory extends Form {
  constructor(props) {
    super(props);
    let _currentUser = UserService.getUserByToken("token").id;
    this.state = {
      Id: "",
      CreatedBy: _currentUser,
      ModifiedBy: _currentUser,
      IsDeleted: false,
      IsActive: true,
      pageSize: 5,
      currentPage: 1,
      countyData: [],
      TableData: [],
      //validation
      businessCategoryToValidate: { Name: "", BusinessCategoryCode: "" },
      errorBusinessCategoryName: "",
      errorBusinessCategoryCode: "",
      isUpdate: false,
      businessCategoryData: [],
    };
  }
  schema = {
    Name: Joi.string().required().label("Name"),
    BusinessCategoryCode: Joi.string()
      .required()
      .label("Business Category Code"),
  };

  async componentDidMount() {
    this.loadFirstData();
  }

  loadFirstData = async () => {
    try {
      await http
        .get(BusinessCategoryConstant.BUSNIESS_CATEGORY_GetALL_URL)
        .then((data) => {
          this.setState({ businessCategoryData: data.data.response });
        });
      let businessCategoryList = this.state.businessCategoryData.filter(
        (x) =>
          x.IsActive === true &&
          x.IsDeleted === false &&
          (x.CreatedBy != null) & (x.ModifiedBy != null)
      );
      businessCategoryList.map((data, index) => {
        this.state.TableData.push({
          itemId: data._id,
          h_0: index + 1,
          h_1: data.Name,
          h_2: data.BusinessCategoryCode,
          h_3: data.CreatedBy.firstname,
          h_4: data.createdAt,
          h_5: data.ModifiedBy.firstname,
          h_6: data.updatedAt,
        });
      });
      this.setState({
        TableData: this.state.TableData,
      });
    } catch (e) {
      alert(
        "Some Thing Went Wrong In Load Data , Please Reload Page Again Or Contact With Support..."
      );
      console.log(e.message, "Error Message");
    }
  };

  SaveOrUpdate = async () => {
    this.handleSaveSubmit();
    const data = {
      id: this.state.Id,
      Name: this.state.businessCategoryToValidate.Name,
      BusinessCategoryCode: this.state.businessCategoryToValidate
        .BusinessCategoryCode,
      CreatedBy: this.state.CreatedBy,
      ModifiedBy: this.state.ModifiedBy,
      IsDeleted: this.state.IsDeleted,
      IsActive: this.state.IsActive,
    };
    this.doSaveOrUpdate(data, BusinessCategoryConstant.BUSNIESS_CATEGORY_ADD_URL, BusinessCategoryConstant.BUSNIESS_CATEGORY_Update_URL);
  }

  loadBusinessCategory = async (id) => {
    this.setState({
      isUpdate: true,
    });
    await http
      .get(BusinessCategoryConstant.BUSNIESS_CATEGORY_GetByID_URL + `?id=${id}`)
      .then((data) => {
        this.setState({
          businessCategoryToValidate: {
            Name: data.data.response.Name,
            BusinessCategoryCode: data.data.response.BusinessCategoryCode,
          },
        });
        this.setState({
          Id: data.data.response._id,
        });
      });
  };

  //Soft Delete???
  deleteRecord = async (id) => {
    this.doDeleteRecord(id, BusinessCategoryConstant.BUSNIESS_CATEGORY_DELETE_URL);
  }

  onChangeBusinessCategoryName = (e) => {
    if (
      e.target.value != "" &&
      this.state.businessCategoryToValidate.BusinessCategoryCode != ""
    ) {
      this.setState({
        isUpdate: true,
      });
    }
    this.setState({
      businessCategoryToValidate: {
        ...this.state.businessCategoryToValidate,
        Name: e.target.value,
      },
    });
    const error = this.validateProperty(e.target.value, this.schema.Name);
    if (error) {
      this.setState({
        isUpdate: false,
      });
    }
    this.setState({ errorBusinessCategoryName: error });
  };

  onChangeBusinessCategoryCode = (e) => {
    if (
      e.target.value != "" &&
      this.state.businessCategoryToValidate.BusinessCategoryCode != ""
    ) {
      this.setState({
        isUpdate: true,
      });
    }
    this.setState({
      businessCategoryToValidate: {
        ...this.state.businessCategoryToValidate,
        BusinessCategoryCode: e.target.value,
      },
    });
    const error = this.validateProperty(
      e.target.value,
      this.schema.BusinessCategoryCode
    );
    if (error) {
      this.setState({
        isUpdate: false,
      });
    }
    this.setState({ errorBusinessCategoryCode: error });
  };

  resetModal = () => {
    this.setState({
      isUpdate: false,
    });
    this.setState({
      businessCategoryToValidate: { Name: "", BusinessCategoryCode: "" },
    });
    this.setState({
      Id: "",
    });
  };

  render() {
    const {
      TableData,
      errorBusinessCategoryName,
      errorBusinessCategoryCode,
      businessCategoryToValidate,
      isUpdate,
    } = this.state;
    return (
      <>
        <MainMenu link={`BusinessCategory`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <StaticHTML />
                <div className="content-wrapper" >
                  <div className="company-table">
                    <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                      <div style={{ float: 'left' }}>
                        <h5 className="left-head">Country</h5>
                      </div>
                      <div style={{ float: 'right', marginTop: '-2px', marginRight: '-15px' }}>
                        <a onClick={this.resetModal} title="Reply" data-toggle="modal" data-target="#modal-Country">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;New Business Category <i className="bx bxs-plus-circle" /></span>
                        </a>
                      </div>
                    </div>
                    <BusinessCategoryTable TableData={TableData} loadBusinessCategory={this.loadBusinessCategory} deleteRecord={this.deleteRecord} />
                  </div>
                </div>
                <Modal Name={businessCategoryToValidate.Name} BusinessCategoryCode={businessCategoryToValidate.BusinessCategoryCode}
                  onChangeBusinessCategoryName={this.onChangeBusinessCategoryName}
                  onChangeBusinessCategoryCode={this.onChangeBusinessCategoryCode}
                  SaveOrUpdate={this.SaveOrUpdate} errorBusinessCategoryName={errorBusinessCategoryName}
                  errorBusinessCategoryCode={errorBusinessCategoryCode} isUpdate={isUpdate} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default BusinessCategory;
