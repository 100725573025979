import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import '../../../style/BoardMessages.css'
import axios from '../../../../../util/Api';
import { createImageFromInitials, getRandomColor, validateEmail, createFileTypeImages } from '../../../../../util/People';
import ReactTooltip from 'react-tooltip';
import config from '../../../../services/config.json';
import { toast } from 'react-toastify';

var FormData = require('form-data')

axios.interceptors.request.use(
  (config) => {
    // console.log('axios request interceptor: ', config);
    const token = JSON.parse(localStorage.getItem('token'));
    if (token) {
      config.headers.common['authorization'] = `Bearer ${token}`
    }

    return config;
  },
  (err) => Promise.reject(err)
);
// axios.defaults.headers.post['Content-Type'] ='multipart/form-data';
axios.defaults.headers.post['crossDomain'] = true;

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  // display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


function Previews(props) {
  const [files, setFiles] = useState([]);
  const [filesPreview, setFilesPreview] = useState([]);
  const [isUploading, setisUploading] = useState(false);
  const { getRootProps, getInputProps } = useDropzone({
    // accept: 'image/*,video/*,audio/*,.doc,.csv,.xls,.webm,.mp4',
    maxFiles: 5,
    onDrop: async acceptedFiles => {
      setisUploading(true)
      let array = files;
      let previewArray = [];
      array.push(acceptedFiles[0]);

      setFiles(array)
      // setFiles(acceptedFiles.map(file => Object.assign(file, {
      //   preview: URL.createObjectURL(file)
      // })));
      // setFiles(array.map(file => Object.assign(file, {
      //   preview: URL.createObjectURL(file)
      // })));

      let file;
      for (let i = 0; i < acceptedFiles.length; i++) {
        file = new FormData();
        file.append('sampleFile', acceptedFiles[i]);
        let response = await axios.post('/workspace/uploadFiles', file, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
    
        ReactTooltip.rebuild();
        let html;
        let mediaUrl = config.baseUrlEndPoint + response.data.name
        if (response.data.mimetype.includes('image')) {
          html = '<img src="' + mediaUrl + '" alt="" data-rotate="" data-proportion="true" data-rotatex="" data-rotatey="" data-size="100" data-align="left" data-percentage="100,100" data-index="1" data-file-name="' + response.data.name + '" data-file-size="0" data-origin=",">'
        } else {
          if (response.data.mimetype.includes('video')) {
            html = '<video src="' + mediaUrl + '"  data-file-name="' + response.data.name + '"  controls></video>'
          } else {
            if (response.data.mimetype.includes('audio')) {
              html = '<audio src="' + mediaUrl + '" data-file-name="' + response.data.name + '"  controls></audio>'
            } else {
              html = '<div class="nonmedia" alt="" data-rotate="" data-proportion="true" data-rotatex="" data-rotatey="" data-size="100" data-align="left" data-percentage="100,100" data-index="1" data-file-name="' + response.data.name + '" data-file-size="0" data-origin=","><span class="badge badge-pill badge-warning font-medium-4 font-weight-light mb-4">' + response.data.name.split('.').pop() + '</span><a download="' + response.data.name + '" target="_blank" alt="" data-rotate="" data-proportion="true" data-rotatex="" data-rotatey="" data-size="100" data-align="left" data-percentage="100,100" data-index="1" data-file-name="' + response.data.name + '" data-file-size="0" data-origin="," class="on blockquote-footer" href="' + mediaUrl + '">' + response.data.name + '</a></div>'

            }
          }

        }

        let uploadedFile = {
          url: mediaUrl,
          filename: response.data.name,
          mimetype: response.data.mimetype
        }
        previewArray = filesPreview;
        previewArray.push(uploadedFile)
        setFilesPreview(previewArray)
        props.mediaAttachementhandler(html)


        
      }
      toast.success("File/s Uploaded.");
      setisUploading(false);
    
      // axios.post('/workspace/uploadFiles', file, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //   }
      // }).then(({ data }) => {

      //   ReactTooltip.rebuild();
      //   let html;
      //   let mediaUrl = config.baseUrlEndPoint + data.name
      //   if (data.mimetype.includes('image')) {
      //     html = '<img src="' + mediaUrl + '" alt="" data-rotate="" data-proportion="true" data-rotatex="" data-rotatey="" data-size="100" data-align="left" data-percentage="100,100" data-index="1" data-file-name="' + data.name + '" data-file-size="0" data-origin=",">'
      //   } else {
      //     if (data.mimetype.includes('video')) {
      //       html = '<video src="' + mediaUrl + '" alt="" data-rotate="" data-proportion="true" data-rotatex="" data-rotatey="" data-size="100" data-align="left" data-percentage="100,100" data-index="1" data-file-name="' + data.name + '" data-file-size="0" data-origin=",">'
      //     } else {
      //       if (data.mimetype.includes('audio')) {
      //         html = '<audio src="' + mediaUrl + '" alt="" data-rotate="" data-proportion="true" data-rotatex="" data-rotatey="" data-size="100" data-align="left" data-percentage="100,100" data-index="1" data-file-name="' + data.name + '" data-file-size="0" data-origin=",">'
      //       } else {
      //         html = '<div class="nonmedia" alt="" data-rotate="" data-proportion="true" data-rotatex="" data-rotatey="" data-size="100" data-align="left" data-percentage="100,100" data-index="1" data-file-name="' + data.name + '" data-file-size="0" data-origin=","><span class="badge badge-pill badge-warning font-medium-4 font-weight-light mb-4">' + data.name.split('.').pop() + '</span><a download="' + data.name + '" target="_blank" alt="" data-rotate="" data-proportion="true" data-rotatex="" data-rotatey="" data-size="100" data-align="left" data-percentage="100,100" data-index="1" data-file-name="' + data.name + '" data-file-size="0" data-origin="," class="on blockquote-footer" href="' + mediaUrl + '">' + data.name + '</a></div>'

      //       }
      //     }

      //   }

      //   let uploadedFile = {
      //     url: mediaUrl,
      // filename: data.name,
      //     mimetype: data.mimetype
      //   }
      //   previewArray = filesPreview;
      //   previewArray.push(uploadedFile)
      //   setFilesPreview(previewArray)
      //   props.mediaAttachementhandler(html)
 
      //   toast.success("File Uploaded.");
      //   setisUploading(false);
      // }).catch(error => {

      //   console.log("Error****:", error.message);
      // })

    }
  });


  const thumbs = filesPreview?.map(file => (
    <div className="col-xl-2" key={file.filename}>
      <badge className="badge badge-warning font-weight-light preview-badge">{file.filename.split('.').pop()}</badge>
      <div style={thumb}>
        {file.mimetype.includes('video') ?
          <video
            className="w-100 h-100"
            src={file.url}>
          </video> :
          <img
            src={file.mimetype.includes('image') ? file.url : createFileTypeImages(90, file.filename.split('.').pop())}
            style={img}
            alt={file.filename}
          />
        }

      </div>

      <div className="small text-center" title={file.filename}>

        {file.filename.substr(0, 13)}
      </div>

    </div>
  ));

  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    // files.forEach(file => URL.revokeObjectURL(file.preview));
    setFiles(files)

  }, [files]);

  return (
    <section className="customDropzone">
      <div class="card bg-rgba-light h-100">
        <div class="card-header bg-pink">Upload Files
        {/* <i class="fa fa-times text-white float-right" aria-hidden="true"></i> */}

        </div>
        <div class="card-body">
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />

            <div className="text-center mt-1" ><div className="nomsg mt-0">
              {isUploading && <div class="spinner-border text-primary mr-2" role="status">
                <span class="sr-only">Loading...</span>
              </div>}{isUploading ? 'Please wait, File is uploading...' : 'Drag & drop files or click to upload '}
            </div></div>
          </div>
       
          <div className="row previewpanel" style={thumbsContainer}>

            {thumbs}

          </div>

        </div>
      </div>



    </section>
  );
}

export default Previews