
import React from 'react';
import { connect } from 'react-redux';
import { changeBoardItem, updateCellTagsSuggestion, fetchError, saveRowReminder } from '../../../../../actions';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../../util/permission';
import DatePicker from "react-datepicker";
import '../../../style/react-datepicker.css'
import Select from 'react-select'
import moment from 'moment';
import { date } from 'joi';
import {setHours, setMinutes} from 'date-fns';


class ReminderItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            suggestions: [],
            actionPermission: 'edit',
            dateTime: this.props.body.dateTime,
            frequency: '',
            users: this.props.body.users?this.props.body.users:[],
            isEmailNotification: this.props.body.isEmailNotification?this.props.body.isEmailNotification:false
        }

        this.dropdownContainer = React.createRef();
        this.changeFrequncy = this.changeFrequncy.bind(this);
        this.changeUsers = this.changeUsers.bind(this);
        this.chnageEmailnotifyStatus = this.chnageEmailnotifyStatus.bind(this);
    }

    onClickOutsideHandler = (ev) => {
        return;
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false });
        }
    }
    componentDidMount() {
        //  window.addEventListener('click', this.onClickOutsideHandler);
        console.log("this.props.body", this.props);
        let cellSuggestion = this.props.boardData.cellSuggestions;

        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem.cellItems;
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem.cellItems;
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    componentWillReceiveProps(nextProps) {
        let cellSuggestion = nextProps.boardData.cellSuggestions;

        this.setState({ dateTime: nextProps.body.dateTime ? nextProps.body.dateTime : '' })
        this.setState({ frequency: nextProps.body.frequency ? nextProps.body.frequency : '' })
    }


    changeFrequncy = (event) => {
        this.setState({ frequency: event.target.value });
    }

    chnageEmailnotifyStatus = (event) => {
        this.setState({ isEmailNotification: !this.state.isEmailNotification });
    }

    changeUsers = (event) => {
        const member = event.target
        const memberID = member.value
        let reminderUsers = this.state.users
        if (this.state.users ? this.state.users.includes(memberID) : false) {

            for (var i = reminderUsers.length - 1; i >= 0; i--) {
                if (reminderUsers[i] == memberID) reminderUsers.splice(i, 1);
            }

            event.target.checked = false;
        } else {
            reminderUsers.push(memberID);
            event.target.checked = true;
        }

        this.setState({ users: reminderUsers });


//JSON.parse(this.props.body.users)
        // this.setState({ users: event.target.value });

    }


    saveReminder = () => {

        let reminderBody = {
            dateTime: moment(this.state.dateTime).format(),
            frequency: this.state.frequency,
            users: this.state.users,
            isEmailNotification : this.state.isEmailNotification
        }

        if (this.props.body.reminderId) {
            this.props.changeBoardItem({
                groupInd: this.props.groupInd,
                rowInd: this.props.rowInd,
                colInd: this.props.colInd,
                body: reminderBody,
                cellItemID: this.props._id,
                boardId: this.props.boardData.boardID
            })
        } else {
            this.props.saveRowReminder({
                reminderDate: this.state.dateTime,
                frequency: this.state.frequency,
                groupInd: this.props.groupInd,
                rowInd: this.props.rowInd,
                colInd: this.props.colInd,
                body: reminderBody,
                cellItemID: this.props._id,
                boardId: this.props.boardData.boardID
            })
        }

        this.setState({ open: false });
    }

    render() {

        const { groupInd, rowInd, colInd, body, _id } = this.props;

        return (
            <div  ref={this.dropdownContainer} >
                <div className='p-1 small' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '35px' , 'border' : '1px solid #EAEAEA'  , 'border-top' :' none' ,
            'border-bottom' : 'none' ,
            'border-right' : 'none'  }}
                    onClick={() => {
                        if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members) && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                            this.setState({ open: true })
                        } else {
                            this.props.fetchError("You don't have permission. You can only view")
                        }
                    }}
                >
                    <span className="badge badge-primary">{body.frequency ? body.frequency : ''}</span> &nbsp;
                    {body.dateTime ? moment(body.dateTime).format('LLL') : 'ADD'}

                </div>
                {this.state.open && (
                    <div className="row bg-white pt-2 border shadow position-absolute reminder-dropdown" style={{ width: '240', zIndex:'99999'  }}>
                        <div className="col-xl-12">
                            <label>Time</label>
                            <DatePicker
                                selected={this.state.dateTime ? new Date(this.state.dateTime) : new Date()}
                                excludeOutOfBoundsTimes

                                showTimeSelect
                                dateFormat="Pp"
                                onChange={date => {
                                    console.log(moment(Date.parse(date)).utc())
                                    this.setState({ dateTime: new Date(date) })
                                }}
                                minDate={new Date()}
                                // minTime={new Date(new Date().setHours(new Date().getHours(), new Date().getMinutes(), 0, 0))}
                                // maxTime={new Date(new Date().setHours(23, 59, 0, 0))}
                              
                            />
                        </div>
                        <div className="col-xl-12 mt-2">
                            <div class="form-group">
                                <label>Frequency</label>
                                <select class="form-control" value={this.state.frequency} onChange={this.changeFrequncy}>
                                    <option value="today">Does Not Repeat</option>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                    <option value="yearly">Yearly</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-xl-12">
                            <div class="form-group">
                                {/*  <label>Users</label>
                              
                               <select class="form-control" value={this.state.users} onChange={this.changeUsers}>
                                    <option value="assignee">Assignee</option>
                                    <option value="followers">Followers</option>
                                    <hr></hr>

                                    {
                                        JSON.parse(this.props.boardData.workspaceBoard.members)
                                            .map((member, index) => (
                                                <option key={index} value={member._id}>{member.name}</option>
                                            ))
                                    }
                                </select> */}
                                <div class="dropup">
                                    <button type="button" class="btn btn-outline-secondary btn-sm btn-block dropdown-toggle" data-toggle="dropdown">
                                        Choose Users
                            </button>
                                    <form>
                                        <div class="dropdown-menu">
                                            <a class="dropdown-item">
                                            <label class="form-check-label">
                                                                <input
                                                                    className="form-check-input cursor-pointer"
                                                                    type="checkbox"
                                                                    checked={(this.state?.users ? this.state?.users?.includes('assignee') : false)}
                                                                    onChange={this.changeUsers}
                                                                    value="assignee"
                                                                />
                                                                Assignee
                                                            </label>
                                                </a>
                                            <a class="dropdown-item">
                                            <label class="form-check-label">
                                                                <input
                                                                    className="form-check-input cursor-pointer"
                                                                    type="checkbox"
                                                                    checked={(this.state?.users ? this.state?.users?.includes('followers') : false)}
                                                                    onChange={this.changeUsers}
                                                                    value="followers"
                                                                />
                                                                Followers
                                                            </label>
                                                            </a>

                                            <div class="dropdown-divider"></div>
                                            {
                                                JSON.parse(this.props.boardData.workspaceBoard.members)
                                                    .map((member, index) => (
                                                        <a class="dropdown-item" key={index}>
                                                            <label class="form-check-label">
                                                                <input
                                                                    className="form-check-input cursor-pointer"
                                                                    type="checkbox"
                                                                    checked={(this.state?.users ? this.state?.users?.includes(member._id) : false)}
                                                                    onChange={this.changeUsers}
                                                                    value={member._id}
                                                                />
                                                                {member.name}
                                                            </label>
                                                        </a>
                                                    ))
                                            }

                                        </div>
                                    </form>
                                </div>

                               
                            </div>
                        </div>
                        <div className="col-xl-12">
                        <div class="form-check">
                        <label class="form-check-label label-inp">
                            <input type="checkbox" class="form-check-input " value="" 
                             className="form-check-input cursor-pointer"
                             type="checkbox"
                             checked={(this.state.isEmailNotification ? true : false)}
                             onChange={this.chnageEmailnotifyStatus}
                             
                            /> Email Notification
                        </label>
                        </div>
                        </div>
                        <div className="col-xl-12 mb-2 mt-2">
                            <button className="btn btn-danger btn-sm float-left"
                                onClick={() => { this.setState({ open: false }) }}
                            >Close</button>
                            <button className="btn btn-primary btn-sm mr-2 float-right"
                                onClick={() => { this.saveReminder() }}

                            >Save</button>

                        </div>
                    </div>

                )}

            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeBoardItem, updateCellTagsSuggestion, fetchError, saveRowReminder }

export default connect(mapStateToProps, mapDispatchToProps)(ReminderItem);