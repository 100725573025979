import React from 'react';
    const Input = ({ label ,  error , ...rest }) => {
    return (
        <>
            <input {...rest} className="form-control outline" />
                <label>{label}<span>*</span></label>
            { error && <div className="alert alert-danger">{error.message}</div>}
        </>
    )
}
export default Input; 

