import React, { useState, useEffect } from 'react';
import ContentEditable from 'react-contenteditable';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import { connect } from 'react-redux';
import { sendBoardChatMessage, updateChatRoomColumns, updateMessageLikes, getChatRoomById, updateMessageSeenBy } from '../../../../../actions';
import { getMyPermission } from '../../../../../util/permission';
import '../../../style/BoardMessages.css'
import SunEditor, { buttonList } from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import ReactTooltip from 'react-tooltip';
import axios from '../../../../../util/Api';
import CellComponent from '../body/CellComponent';
import { createImageFromInitials, getRandomColor, validateEmail, createFileTypeImages } from '../../../../../util/People';
import Moment from 'react-moment';
import { Button, Accordion, Card } from 'react-bootstrap';
import NestedMessageContainer from './NestedMessageContainer';
import MessageReminder from './messageReminder';
import moment from 'moment';
import Previews from './dropzone';
import Picker from 'emoji-picker-react';
import VisibilitySensor from "react-visibility-sensor";
import { toast } from 'react-toastify';
import config from '../../../../services/config.json';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
const socket = require('socket.io-client')(config.baseUrlEndPoint, {
    transports: ['websocket'],
    rejectUnauthorized: false,
})

class BoardMessageContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rowTitle: this.props.title,
            actionPermission: 'view',
            editorText: '',
            editorAttachements: '',
            chatRoom: [],
            chatRoomID: this.props.chatRoomID,
            messages: [],
            boardMembers: [],
            checkingMessages: true,
            showUserSuggestions: false,
            userFilterString: '',
            messageReceivers: [],
            socketReply: '',
            cellItems: this.props.boardData.data[0].groupItems[0].cellItems,
            enableDropzone: false,
            disableNesteChat: false,
            showToolbar: false,
            enableEmojis: false,
            showQuotedMessage: false,
            quotedFrom: '',
            quotedMsg: '',
            quotedId: '',
            getElement: null,
            isModalOpen: false,
            msgidToDelete: ''
        }
        this.timeout = 0;
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
        this.rowContentEditableRef = React.createRef();
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    onChangeListTitle = (event) => {
        this.setState({ rowTitle: event.target.value })
    }

    handleInputChange = (event) => {

        // groupMembers= this.props.groupItem.members;
        const column = event.target
        const columnID = column.value
        let chatroomColumns = this.state.chatRoom.columns
        if (this.state.chatRoom.columns ? this.state.chatRoom.columns.includes(columnID) : false) {
            //chatroomColumns.pop(columnID);

            for (var i = chatroomColumns.length - 1; i >= 0; i--) {
                if (chatroomColumns[i] == columnID) chatroomColumns.splice(i, 1);
            }
            //  this.props.groupItem.members.pop(memberID);
            event.target.checked = false;
        } else {
            chatroomColumns.push(columnID);
            //   this.props.groupItem.members.push(memberID);
            event.target.checked = true;
        }

        this.setState({ chatRoom: { ...this.state.chatRoom, columns: chatroomColumns } });


        this.props.updateChatRoomColumns({
            chatroomID: this.state.chatRoomID,
            columns: chatroomColumns
        });


    }

    componentDidMount() {
        socket.emit('connectChatRoom', this.state.chatRoomID);

        socket.on("socketEvent_messageReceived", Obj => {

            if (Obj.message.from !== this.authUser._id) {
                this.setState({ messages: [...this.state.messages, Obj.message] })
            }
        });

        socket.on("socketEvent_messageUpdates", Obj => {

            //if(Obj.message.from !== this.authUser._id) {
            axios.post('/workspace/getMessagesByChatroomId', {
                chatRoom: this.state.chatRoomID,

            }).then(({ data }) => {

                this.setState({ messages: data.messages })
                ReactTooltip.rebuild();
            })
            //}
        });

        socket.on("socketEvent_nestedMessageReceived", Obj => {
            axios.post('/workspace/getMessagesByChatroomId', {
                chatRoom: this.state.chatRoomID,

            }).then(({ data }) => {
                this.setState({ checkingMessages: false })
                this.setState({ messages: data.messages })
                this.scrollToBottom();
                ReactTooltip.rebuild();
            }).catch(error => {
                //  dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            })
            // if (Obj.message.from !== this.authUser._id) {               
            //     this.setState({ socketReply: Obj.message })
            // }
        });

        this.setState(() => {
            return {
                getElement: document.getElementById("chatScroller")
            };
        });


        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            this.setState({ boardMembers: members })
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }


        axios.post('/workspace/getMessagesByChatroomId', {
            chatRoom: this.state.chatRoomID,

        }).then(({ data }) => {
            this.setState({ checkingMessages: false })
            this.setState({ messages: data.messages })
            this.scrollToBottom();
            ReactTooltip.rebuild();

            if (this.props.boardData.isChatNotification) {
                this.highlightmsg(this.props.boardData.messageID)
            }
        }).catch(error => {
            //  dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        })


        this.props.getChatRoomById({
            chatroomID: this.state.chatRoomID
        });
    }

    componentWillUnmount() {
        socket.emit('disconnectChatRoom', this.state.chatRoomID);

    }


    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            this.setState({ boardMembers: members })
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
        if (this.state.messages.length !== prevStates.messages.length) {
            this.scrollToBottom();
        }

        if (this.props.chatRoomID !== prevProps.chatRoomID) {

            this.props.getChatRoomById({
                chatroomID: this.state.chatRoomID
            });
        }

    }

    componentWillReceiveProps(nextProps) {

        this.setState(() => {
            return {
                getElement: document.getElementById("chatScroller")
            };
        });
        this.setState({ cellItems: nextProps.boardData.data[0].groupItems[0].cellItems })

        this.setState({ rowTitle: nextProps.title })
        this.setState({ chatroomID: nextProps.chatroomID })
        this.setState({ chatRoom: nextProps.boardData.chatroom })
        console.log('nextProps.boardData.chatroom', nextProps.boardData.chatroom)
        if (nextProps.message._id) {
            axios.post('/workspace/getMessagesByChatroomId', {
                chatRoom: this.state.chatRoomID,

            }).then(({ data }) => {
                this.setState({ checkingMessages: false })
                this.setState({ messages: data.messages })
                this.scrollToBottom();
                ReactTooltip.rebuild();
            }).catch(error => {
                //  dispatch({ type: FETCH_ERROR, payload: error.message });
                console.log("Error****:", error.message);
            })
            return;
            this.setState({ messages: [...this.state.messages, nextProps.message] })
        }

    }

    getUserdetails = (userID) => {
        let Index = this.state.boardMembers.findIndex(member => member._id === userID);
        return this.state.boardMembers[Index];
    }


    deleteMessage = (messageID) => {

        axios.post('/workspace/removeBoardChatMessage', {
            messageID: messageID,

        }).then(({ data }) => {
            this.setState({ messages: this.state.messages.filter(i => i._id !== data.deletedMessage._id) })
        }).catch(error => {
            //  dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        })
    }

    onSendMessage = (event) => {
        if (!this.state.enableDropzone) {
            if (this.state.editorText.replace(/(<([^>]+)>)/ig, '') === '') {
                toast.error("Type something to send");
                return;
            }
        } else {
            // if (this.state.editorText.replace(/(<([^>]+)>)/ig, '') === '' && this.state.editorAttachements.replace(/(<([^>]+)>)/ig, '') === '') {
            //     toast.error("Type something or attach atleast one file to send");
            //     return;
            // }
        }
        if (this.state.showQuotedMessage) {
            this.SendReplyWithQuote();
        } else {
            let message = {
                chatRoom: this.state.chatRoomID,
                to: this.state.messageReceivers,
                likes: [],
                seenBy: [],
                from: this.authUser._id,
                body: this.state.enableDropzone ? this.state.editorAttachements + this.state.editorText : this.state.editorText
            }
            this.props.sendBoardChatMessage(message)
            this.setState({ editorText: '' })
            this.setState({ messages: [...this.state.messages, message] })
        }
        this.setState({ enableDropzone: false })
        this.setState({ enableEmojis: false })
    }

    handleChange = (contents) => {
        this.setState({ editorText: contents })
        let content = contents.replace(/(<([^>]+)>)/ig, '')
        if (content.includes('@') && !content.includes('@ ')) {
            this.setState({ userFilterString: content.split('@').pop() })
            this.setState({ showUserSuggestions: true })
        } else {
            this.setState({ showUserSuggestions: false })
        }
    }


    onKeyDown = (e) => {

        let messageReceivers = []
        if (e.key == "Backspace") {

            var selection = ReactHtmlParser(this.state.editorText);

            for (let i = 0; i < selection.length; i++) {
                if (selection[i]?.props?.uid) {
                    messageReceivers.push(selection[i]?.props?.uid)
                }
            }
            this.setState({ messageReceivers: messageReceivers })
            console.log('messageReceivers', messageReceivers)

        }
        console.log('messageReceivers1', this.state.messageReceivers)
    }

    clearUserSuggestions = () => {
        this.setState({ showUserSuggestions: false })
    }

    updateLikestatue = (message, likedUserID) => {

        let obj;
        if (message.from === this.authUser._id) {
            toast.error("You can't like or dislike your messages");
            return;
        }
        let seenByarray = message.likes

        let index = message.likes.findIndex(member => member._id === this.authUser._id);
        if (index == -1) {
            obj = {
                _id: this.authUser._id,
                time: new Date().valueOf()
            }

            seenByarray.push(obj)

        } else {

            seenByarray = seenByarray.splice(index, -1);
        }
        this.props.updateMessageLikes({
            messageID: message._id,
            likes: seenByarray
        });
    }


    scrollToBottom = () => {
        this.el.scrollIntoView({ behavior: 'smooth' });
    }
    closeButtonClickHandler = () => {
        this.props.handleClose();
    }

    showInline = (toolbar, context) => {
        console.log(toolbar, context)
    }

    addUsersToMessage = (member) => {
        let msg = this.state.editorText
        msg = msg.substring(0, msg.lastIndexOf("@"));
        this.setState({ editorText: msg.concat("<u class='badge badge-primary' uid='" + member._id + "' contenteditable='false'><b>" + member.name + "</b></u>&nbsp;") })
        let receivers = this.state.messageReceivers
        receivers.push(member._id)
        this.setState({ messageReceivers: receivers })
        this.setState({ userFilterString: '' })
        this.setState({ showUserSuggestions: false })
    }

    getCellNameFromSuggestionsArray = (_id) => {

        let Index = this.state.cellItems.findIndex(item => item.columnId === _id);
        return this.state.cellItems[Index].body;
    }

    mediaAttachementhandler = (html) => {

        this.setState({ editorAttachements: this.state.editorAttachements.concat(html) })

    }

    replyWithQuote = (message) => {
        let tempDiv;
        if(message.body.includes('quotedmessage')){
             tempDiv = document.createElement('div');
            tempDiv.innerHTML = message.body;
            tempDiv.childNodes[0].remove();
            tempDiv = tempDiv.outerHTML
        }else{
            tempDiv = message.body;
        }
      
        debugger;
        
        this.setState({ showQuotedMessage: true })
        let fromName = this.getUserdetails(message.from).name
        this.setState({ quotedFrom: fromName })
        this.setState({ quotedMsg: tempDiv.replace(/<(?:.|\n)*?>/gm, '').replace(fromName, "") })
        this.setState({ quotedId: message._id })
        this.scrollToBottom()
    }

    SendReplyWithQuote = () => {
        let QuotedMessage = "<div class='quotedmessage'><a href='" + this.state.quotedId + "'><div class='quoteduser border-bottom'>" + this.state.quotedFrom + "</div><div class='quotedmessagebody'><i class='fa fa-quote-left small text-black-50'></i> " + this.state.quotedMsg + " <i class='fa fa-quote-right small text-black-50'></i></div></a></div>"
        //let messageBody = this.state.editorText;
        let reply = {
            chatRoom: this.state.chatRoomID,
            to: this.state.messageReceivers,
            from: this.authUser._id,
            likes: [],
            seenBy: [],
            body: QuotedMessage.concat(this.state.editorText)

        }
        this.props.sendBoardChatMessage(reply)

        this.setState({ editorText: '' })
        this.setState({ quotedFrom: '' })
        this.setState({ quotedMsg: '' })
        this.setState({ showQuotedMessage: false })
        this.setState({ messageReceivers: [] })
        this.setState({ messages: [...this.state.messages, reply] })
    }

    clearQuotedMsg = () => {
        this.setState({ quotedFrom: '' })
        this.setState({ quotedMsg: '' })
        this.setState({ showQuotedMessage: false })
    }

    htmlParserTransform = (node, index) => {

        if (node.type == "tag" && node.name == "a") {
            const { href } = node.attribs
            return (
                <a
                    onClick={(event) => {
                        event.preventDefault()
                        var elmnt = document.getElementById(href);

                        elmnt.classList.add("blink_chatmsg");

                        elmnt.scrollIntoView();
                        setTimeout(function () {
                            elmnt.classList.remove("blink_chatmsg");
                        }, 5000);

                    }}
                >
                    <span pointerEvents="none">
                        {convertNodeToElement(node, index, this.htmlParserTransform)}
                    </span>
                </a>
            )
        }

    }


    highlightmsg = (mgsid) => {

        var elmnt = document.getElementById(mgsid);

        elmnt.classList.add("blink_chatmsg");

        elmnt.scrollIntoView();
        setTimeout(function () {
            elmnt.classList.remove("blink_chatmsg");
        }, 5000);

    }

    onEmojiClick = (event, emojiObject) => {

        this.setState({ editorText: this.state.editorText.replace(/(<p[^>]+?>|<p>|<\/p>)/img, "").replace(/\<br\>/g, " ").concat(emojiObject.emoji) })
    };

    onChange = (message) => {
        let obj;
        if (message.from === this.authUser._id) {
            return '#000';
        }
        let seenByarray = []

        let index = message.seenBy.findIndex(member => member._id === this.authUser._id);
        if (index == -1) {
            obj = {
                _id: this.authUser._id,
                time: new Date().valueOf()
            }

            seenByarray.push(obj)

        } else {
            return '#000';
        }
        this.props.updateMessageSeenBy({
            messageID: message._id,
            seenBy: seenByarray,
            chatroom: this.state.chatRoomID
        });
        return '#000'
    }

    render() {
        console.log('chat11', this.props)
        var containmentDOMRect = this.state.getElement
            ? this.state.getElement
            : null;

        return (

<>



            
            <div className="chatMainWrapper shadow">
                <div className="position-fixed">
                </div>
                <div className="chatHeader row">


                    <div className="col-xl-12 col-12 chat-header-section">

                    <i className="fal fa-times-circle chat-close-icon"
                            onClick={() => this.closeButtonClickHandler()}
                        ></i>



<div className="header-title"  data-tip={this.state.rowTitle}
                    html={this.state.rowTitle}>{this.state.rowTitle}</div>



                    </div>

<div className="container" style={{ 'padding' : '0' , 'height' : 'calc(100vh - 100px)' , 'margin' : "20px 5px"  }}>
      <ul className="nav nav-tabs">
        <li className="active"><a data-toggle="tab" href="#home" class="active">Chat Updates</a></li>
        <li><a data-toggle="tab" href="#menu1">Add Fields</a></li>
      </ul>
      <div className="tab-content">
        <div id="home" className="tab-pane fade in active tabcnct show">
        <div className="chatBody py-2">
                    <div class="panel-body msg_container_base" id="chatScroller">
                        {this.state.messages.length > 0 ?
                            this.state.messages
                                .map((message, index) => {
                                    return containmentDOMRect ? (
                                        <VisibilitySensor key={index} containment={containmentDOMRect}>
                                            {({ isVisible }) => {
                                                return (
                                                    <div className="row msg_container base_receive" ref={el => { this.el = el; }}
                                                        style={{

                                                            color: isVisible ? this.onChange(message) : "#000",
                                                            margin: 5
                                                        }}
                                                    >

                                                        <div className="col-xl-1 col-1 px-2 chat-avatar">
                                                            <img className="img-responsive" src={createImageFromInitials(40, this.getUserdetails(message.from).name, this.getUserdetails(message.from).color)} />

                                                        </div>
                                                        <div className="col-xl-11 col-11" style={{ 'padding' : '0px' }}>
                                                            <div className="card messages msg_receive msgheader" id={message._id}>
                                                                <div className="card-header p-2" style={{ backgroundColor : 'none' }}>
                                                                    <div className="row">
                                                                        <div className="col-xl-6 col-6 chat-head-text">
                                                                        <i class="fal fa-user"></i> &nbsp; <span> {this.getUserdetails(message.from).name} </span>
                                                                        </div>
                                                                        <div className="col-xl-6 col-6 small">
                                                                            {this.authUser._id === message.from && <i className="fal fa-trash-alt float-right mx-3 mt-1 cursor-pointer" style={{ fontSize : '16px' }}
                                                                                onClick={event => {
                                                                                    this.setState({ msgidToDelete: message._id })
                                                                                    this.setState({ isModalOpen: true })
                                                                                }
                                                                                }
                                                                            ></i>}

                                                                            <div className="ml-3 float-right">
                                                                                {message.likes.includes(this.authUser._id) ?
                                                                                    (<span className="cursor-pointer text-primary"
                                                                                        onClick={() => this.updateLikestatue(message, this.authUser._id)}


                                                                                    >
                                                                                        <i class="fal fa-thumbs-up mt-1" aria-hidden="true" style={{  fontSize : '19px' }}></i>
                                                                                    </span>


                                                                                    ) :
                                                                                    (<span className="cursor-pointer"
                                                                                        onClick={() => this.updateLikestatue(message, this.authUser._id)}

                                                                                    >
                                                                                        <i class="fal fa-thumbs-up  mt-1" aria-hidden="true" style={{ fontSize : '17px' }}></i>
                                                                                    </span>)
                                                                                }

                                                                                {message.likes.length > 0 && <span class="dropdown dropleft">
                                                                                    <span class="badge likecounter" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                        {message.likes.length}
                                                                                    </span>
                                                                                    <div class="dropdown-menu" style={{ minWidth: '235px' }}>
                                                                                        {message.likes
                                                                                            .map((like, index) =>
                                                                                            (
                                                                                                <a class="mb-2" key={index}>{this.getUserdetails(like?._id)?.name}  <sub className="ml-1 text-black-50">@{moment(like.time).format('LLL')}</sub></a>)
                                                                                            )}
                                                                                    </div>
                                                                                </span>}
                                                                            </div>

                                                                            <div className="ml-3 float-right">

                                                                                {message?.seenBy?.length > 0 && <span class="dropdown dropleft">
                                                                                    {/* <span class="badge likecounter" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                 {message.seenBy.length}
                                                                            </span> */}
                                                                                    <i class="fa fal-eye fa-lg mt-2" data-toggle="dropdown"></i>

                                                                                    <div class="dropdown-menu" style={{ minWidth: '235px' }}>
                                                                                        {
                                                                                            message.seenBy
                                                                                                .filter(seenobj => seenobj._id !== this.authUser._id).length > 0 ?
                                                                                                message.seenBy
                                                                                                    .filter(seenobj => seenobj._id !== this.authUser._id)
                                                                                                    .map((obj, index) =>
                                                                                                    (
                                                                                                        <a class="mb-2" key={index}>{this.getUserdetails(obj._id).name} <sub className="ml-1 text-black-50">@ {moment(obj.time).format('LLL')}</sub></a>)
                                                                                                    ) :
                                                                                                <div className="mt-n4">
                                                                                                    No other users
                                                                                                </div>

                                                                                        }
                                                                                    </div>
                                                                                </span>}
                                                                            </div>
                                                                            <div className="ml-3 float-right">

                                                                                <span class="dropdown">

                                                                                    <i class="fal fa-bell mt-1" data-toggle="dropdown" style={{ fontSize : '17px' }}></i>

                                                                                    <div class="dropdown-menu" style={{ minWidth: '435px' }}>
                                                                                        <div className="m-0">
                                                                                            <MessageReminder
                                                                                                chatroomID={this.state.chatRoomID}
                                                                                                messageID={message._id}
                                                                                                boardMembers={this.state.boardMembers}
                                                                                                rowInd={this.props.rowInd}
                                                                                                groupInd={this.props.groupInd}
                                                                                                reminderID={message.reminderID ? message.reminderID : ''}

                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </span>
                                                                            </div>

                                                                            <time className="float-right" data-tip={moment(message.createdAt).format('LLLL')}>
                                                                            <i class="fal fa-clock mt-2 mr-2"></i><Moment fromNow>{message.createdAt}</Moment>

                                                                            </time>
                                                                        </div>
                                                                    </div>


                                                                </div>


                                                                <div className="card-body msgbody p-2 ">  {ReactHtmlParser(message.body, { transform: this.htmlParserTransform })}

                                                                    <i class="fa fa-reply-all float-right ml-3 border-left cursor-pointer mt-n3 text-secondary small"
                                                                        onClick={event => this.replyWithQuote(message)}
                                                                    ></i>
                                                                </div>

                                                                {this.state.disableNesteChat && <div className="row mb-n3">
                                                                    <div className="col-xl-12">
                                                                        <Accordion defaultActiveKey={message._id}>
                                                                            <Accordion.Toggle className="mt-n5 float-right" as={Button} variant="link" eventKey={message._id}>
                                                                                <span className="cursor-pointer">
                                                                                    <i class="fa fa-reply mr-2" aria-hidden="true"> </i>

                                                                                </span>
                                                                            </Accordion.Toggle>
                                                                            <Accordion.Collapse eventKey={message._id}>
                                                                                <NestedMessageContainer
                                                                                    chatroomID={this.state.chatRoomID}
                                                                                    messageID={message._id}
                                                                                    boardMembers={this.state.boardMembers}
                                                                                />
                                                                            </Accordion.Collapse>
                                                                        </Accordion>
                                                                    </div>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        </VisibilitySensor>
                                    ) : null;
                                })
                            : (!this.state.checkingMessages && <div className="text-center mt-5" ref={el => { this.el = el; }}><div className="nomsg">No messages found</div></div>)}
                    </div>
                </div>
                <div className="chatfooter">
                    <div className="row">
                        {this.state.enableDropzone && <Previews mediaAttachementhandler={this.mediaAttachementhandler} />}

                        <div className="col-xl-3 col-3" style={{ 'display': 'flex', 'align-items': 'center' }}>
                            <a
                                class="round-icon float-left ml-2">
                                <i class="fal fa-microphone" aria-hidden="true"></i>
                            </a>
                            <a class="round-icon float-left" onClick={() => this.setState({ enableEmojis: !this.state.enableEmojis })}>
                                <i className={this.state.enableEmojis ? 'fa fa-times' : 'fal fa-smile-o'}></i>
                            </a>
                            <a class="round-icon float-left mr-3" onClick={() => this.setState({ enableDropzone: !this.state.enableDropzone })}>

                                <i className={this.state.enableDropzone ? 'fa fa-times' : 'fal fa-link'} ></i>

                            </a>
                            <a class="round-icon float-left mr-0" onClick={() => this.setState({ showToolbar: !this.state.showToolbar })}>
                                <i className={this.state.showToolbar ? 'fa fa-times' : 'fal fa-text-height'}></i>
                            </a>
                        </div>
                        <div className="col-xl-8 col-8 p-0">
                            {this.state.enableEmojis && <div className="emojicontainer"><Picker groupVisibility={{
                                flags: false,
                            }} onEmojiClick={this.onEmojiClick} /></div>}
                            {this.state.showQuotedMessage &&
                                <div className="refMessageBubble shadow py-2 px-3 mb-3 border small w-100">
                                    <div className="border-bottom py-2">{this.state.quotedFrom}
                                        <i className="fa fa-times float-right cursor-pointer"
                                            onClick={event => this.clearQuotedMsg(event)}
                                        ></i>
                                    </div>
                                    <div className="py-2">
                                        {this.state.quotedMsg}
                                    </div>
                                </div>
                            }
                            {this.state.showUserSuggestions &&
                                <div className="usersuggestions shadow py-2 px-3 mb-3 border w-100">
                                    <div className="border-bottom pb-1">Select User
                                        <i className="fa fa-times float-right cursor-pointer"
                                            onClick={event => this.clearUserSuggestions(event)}
                                        ></i>
                                    </div>
                                    {this.state.boardMembers
                                        .filter(member => !this.state.messageReceivers.includes(member._id))
                                        .filter(member => member.name.toLowerCase().includes(this.state.userFilterString.toLowerCase()))
                                        .map((member, index) =>
                                        (
                                            <div className="border-bottom py-2 cursor-pointer"
                                                onClick={event => this.addUsersToMessage(member)}
                                                key={index}
                                            >
                                                {member.name}
                                            </div>
                                        )
                                        )}
                                </div>
                            }
                            <SunEditor style={{ 'position' : 'relative' , 'top' : '-50px !important' }}
                                showToolbar={this.state.showToolbar}
                                onChange={this.handleChange}
                                onKeyUp={this.onKeyDown}
                                setContents={this.state.editorText}
                                setOptions={{
                                    height: 'auto',
                                    minHeight: 45,
                                    maxHeight: 150,
                                    buttonList: [['font', 'formatBlock', 'underline', 'align', 'bold', 'italic', 'strike', 'removeFormat', 'fontColor', 'hiliteColor', 'indent', 'outdent', 'list', 'horizontalRule', 'table', 'image', 'video', 'link', 'undo', 'redo']]
                                    // Or Array of button list, eg. [['font', 'align'], ['image']]
                                }}
                                showInline={this.showInline()}
                            />
                        </div>
                        <div className="col-xl-1 col-1" style={{ 'display': 'flex', 'align-items': 'center' }}>
                            <a onClick={event => this.onSendMessage(event)} class="round-button mt-1">
                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                </div>

               


        </div>
        <div id="menu1" className="tab-pane fade">

            <div class="row option-drp">

<div className="dropdown float-right dropleft add-column-drop-chat">
<a className="text-pink dropdown-toggle rightcolumn cursor-pointer" data-toggle="dropdown"> 
    Add Options <i class="fas fa-caret-down" aria-hidden="true"> </i> </a>
<div className="dropdown-menu animate slidein options-dropdown">
    <h5 class="chat-txt-hd">Item Cards</h5>
    <h5 class="chat-txt-form">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>
    <form className="mt-n3 mb-n2 options-form">
        {this.props.cellSuggestions
            .filter(cell => cell.title !== 'Assignee' && cell.title !== 'Followers')
            .map((cell, index) =>
            (<a className="mb-2">
                <div class="form-check form-field-design">


    <div className="checkbox-div-table-one">
        <label className="container-checkbox-one" style={{textTransform: 'none'}}>
            <input type="checkbox" className="form-check-input cursor-pointer"  checked={(this.state.chatRoom?.columns ? this.state?.chatRoom?.columns?.includes(cell._id) : false)}
                            onChange={this.handleInputChange}
                            value={cell._id} />
            <span className="checkmark" />
            {this.getCellNameFromSuggestionsArray(cell._id)}:
        </label>
    </div>

                </div>


            </a>))
        }
    </form>


</div>
</div>



            </div>

            
        <div className="row tabcolummns">
                            {this.props.cellItems
                                //   .filter(cellItem => this.state.chatRoom?.columns?.includes(cellItem.columnId) || cellItem.key==='people')
                                .map((item, index) => (

                                    <div key={index} className={"col-xl-4 mb-3 " + (this.state.chatRoom?.columns?.includes(item.columnId) || item.key === 'people' ? '' : 'd-none')}>
                                        <div className="row">
                                            <div className="col-xl-12 text-capitalize">
                                                <div className="font-weight-light float-left mt-2">{this.getCellNameFromSuggestionsArray(item.columnId)}:</div>
                                                <div className="cellitems">
                                                    <CellComponent
                                                        isWorkspaceOwner={this.props.isWorkspaceOwner}
                                                        isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                                                        isBoardAdmin={this.props.isBoardAdmin}
                                                        groupInd={this.props.groupInd}
                                                        lastUpdated={this.props.lastUpdated ? this.props.lastUpdated : null}
                                                        rowInd={this.props.rowInd}
                                                        colInd={index}
                                                        // colInd={parseInt(item.columnId.slice(item.columnId.lastIndexOf('_') + 1))}

                                                        item={item}
                                                        cellItems={this.props.cellItems} />
                                                </div>

                                            </div>
                                        </div>
                                    </div>))}
                        </div>
                   
                   
        </div>
      </div>
    </div>


                     
                   
                
                </div>

              
                <Modal isOpen={this.state.isModalOpen}>
                    <ModalHeader toggle={() => this.setState({ isModalOpen: false })}>
                        Delete this item?
                    </ModalHeader>
                    <ModalFooter>
                        <button className='btn btn-light' onClick={() => { this.setState({ isModalOpen: false }) }}>Cancel</button>{' '}
                        <button className='btn btn-primary'
                            onClick={() => {
                                this.deleteMessage(this.state.msgidToDelete)
                                this.setState({ isModalOpen: false })
                            }}
                        >Delete</button>
                    </ModalFooter>
                </Modal>

            </div>

</>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { sendBoardChatMessage, updateChatRoomColumns, updateMessageLikes, getChatRoomById, updateMessageSeenBy };

export default connect(mapStateToProps, mapDispatchToProps)(BoardMessageContainer);