import React  , {useState} from 'react';
import Moment from 'moment'; 
import { Pie } from 'react-chartjs-2';

const state = {
  labels: ['January', 'February', 'March',
    'April', 'May'],
  datasets: [
    {
      label: 'Tasks',
      backgroundColor: [
        '#B21F00',
        '#C9DE00',
        '#2FDE00',
        '#00A6B4',
        '#6800B4'
      ],
      hoverBackgroundColor: [
        '#501800',
        '#4B5000',
        '#175000',
        '#003350',
        '#35014F'
      ],
      data: [65, 59, 80, 81, 56]
    }
  ]
}

// const [data, setData] = useState([])
// useEffect(() => {
//   this.state.datasets.data = []
//   return () => {
    
//   }
// }, [])

function ReactJSBie(props) {
  return (
    <div>
      <Pie
        data={state}
        options={{
          title: {
            display: true,
            text: 'Average Tasks Done per month',
            fontSize: 20
          },
          legend: {
            display: true,
            position: 'right'
          }
        }}
      />
    </div>

  );
}

export default ReactJSBie;