import React from 'react';
import DateBottom from './DateBottom';
import DropdownBottom from './DropdownBottom';
import FormulaBottom from './FormulaBottom';
import NumbersBottom from './NumbersBottom';
import StatusBottom from './StatusBottom';
import TagsBottom from './TagsBottom';
import TimelineBottom from './TimelineBottom';

class BoardGroupCellBottom extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        switch (this.props.itemKey) {
            case 'numbers':
                return (<NumbersBottom id={this.props.id} columnId={this.props.columnId} groupId={this.props.groupId} groupItems={this.props.groupItems} colInd={this.props.colInd}/>)
            case 'status':
                return (<StatusBottom id={this.props.id} columnId={this.props.columnId} groupId={this.props.groupId} groupItems={this.props.groupItems} />)
            case 'date':
                return (<DateBottom id={this.props.id} columnId={this.props.columnId} groupId={this.props.groupId} groupItems={this.props.groupItems} />)
            case 'tags':
                return (<TagsBottom id={this.props.id} columnId={this.props.columnId} groupId={this.props.groupId} groupItems={this.props.groupItems} />)
            case 'dropdown':
                return (<DropdownBottom id={this.props.id} columnId={this.props.columnId} groupId={this.props.groupId} groupItems={this.props.groupItems} />)
            case 'timeline':
                return (<TimelineBottom id={this.props.id} columnId={this.props.columnId} groupId={this.props.groupId} groupItems={this.props.groupItems} />)
            case 'formula':
                return (<FormulaBottom id={this.props.id} columnId={this.props.columnId} groupId={this.props.groupId} groupItems={this.props.groupItems} />)
            default:
                return (<div className='empty-item'></div>)
        }
    }
}

export default BoardGroupCellBottom;