import React from 'react';
import DatePicker from "react-datepicker";
import { connect } from 'react-redux';
import { changeBoardItem, fetchError } from '../../../../actions';
//import '../style/react-datepicker.css'

class DateItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionPermission: 'view'
        }
    }
    render() {
        const { groupInd, rowInd, colInd, body, _id } = this.props;
        let value = "";
        if (body !== "") {
            value = new Date(body)
        }
        return (
            <div className=" date-component">
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <DatePicker
                        selected={value}
                        dateFormat="MMM d, y"
                        className='workspace-board'
                    />
                </div>
            </div>
        )

    }
}

const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = { changeBoardItem, fetchError };

export default connect(mapStateToProps, mapDispatchToProps)(DateItem);