import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Auth from './Auth';
import Common from './Common';
import boardData from './BoardData';
import discover from './discover';
import search from './GlobalSearch'
import taskmanager from './TaskManager'


export default (history) => combineReducers({
   router: connectRouter(history),
   auth: Auth,
   commonData: Common,
   boardData: boardData,
   discover: discover,
   search:search,
   taskmanager:taskmanager
});
