import { applyMiddleware, compose, createStore } from 'redux';
import reducers from '../reducers/index';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist'

import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

const history = createBrowserHistory();
const routeMiddleware = routerMiddleware(history);

let middlewares = [thunk, routeMiddleware, createLogger()];
if (process.env.NODE_ENV === 'production') {
   middlewares = [thunk, routeMiddleware];
}
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
   key: 'root',
   storage,
   whitelist: [],
   blacklist: ['boardData'],
   version: 0,
   debug: true,  
   stateReconciler: autoMergeLevel2

 }
  
 const persistedReducer = persistReducer(persistConfig, reducers(history))
  
export default function configureStore(initialState) {
   const store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(...middlewares)));
   let persistor = persistStore(store)

   if (module.hot) {
      // Enable Webpack hot module replacement for reducers
      module.hot.accept('../reducers/index', () => {
         const nextRootReducer = require('../reducers/index');
         store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
      });
   }
     return { store, persistor }

}
export { history };
