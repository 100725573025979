import React, { Component } from 'react';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

class PlanMaster extends Component {
  state = {}
  render() {
    return (

      <>
        <MainMenu link={`planmaster`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <div className="row dash-blue fixed-top">
                  <div className="col-md-1 col-md-1">
                  </div>
                  <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
                    <div>
                      <div className="inner-section">
                        <h3>Master Settings</h3>
                        <div style={{ marginTop: '-10px' }}>
                          <p className="mt-1">Plan Master</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
                    <table className="align-items-center table-dashboard-section-one">
                      <tbody><tr>
                        <td rowSpan={2} style={{ width: '300px', textAlign: 'left' }}>
                          <input className="search-dashboard" type="text" name="search" placeholder="Search Here.." />
                        </td>
                      </tr>
                        <tr style={{ fontWeight: 600 }}>
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                          <td id="value" />
                        </tr>
                      </tbody></table>
                    <table className="align-items-center table-dashboard-section-last d-flex">
                      <tbody><tr>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-27.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-38.png" /></a></td>
                        <td rowSpan={2}><a href><img src="/images/icon/dashboard-icons/module-icons/white icons-37.png" /></a></td>
                        <td rowSpan={2} style={{ fontWeight: 100, display: 'inline-flex' }}>&nbsp;&nbsp;Sort <img src="/images/icon/dashboard-icons/Panel icons-32.png" style={{ marginLeft: '10px' }} /></td>
                      </tr>
                        <tr>
                          <td>&nbsp;&nbsp;</td>
                        </tr>
                        <tr>
                        </tr></tbody></table>
                  </div>
                </div>
                <div className="content-wrapper">
                  <div className="company-table">
                    <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                      <div style={{ float: 'left' }}>
                        <h5 className="left-head">Plan Master</h5>
                      </div>
                      <div style={{ float: 'right', marginTop: '-2px', marginRight: '-15px' }}>
                        <a title="Reply" data-toggle="modal" data-target="#modal-planmaster">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;New Plan <i className="bx bxs-plus-circle" /></span>
                        </a>
                      </div>
                    </div>
                    {/* Start Table Content*/}
                    <table className="table-striped module-table" aria-describedby="example_info">
                      <thead>
                        <tr className="rounded">
                          <th style={{ paddingLeft: '15px', width: '80px' }}>Sl No.</th>
                          <th style={{ paddingLeft: '100px', width: '250px' }}>Plan Name</th>
                          <th style={{ textAlign: 'center', width: '100px' }}>User Limits</th>
                          <th style={{ textAlign: 'right', width: '150px' }}>Total Amount</th>
                          <th style={{ textAlign: 'right', width: '150px' }}>Created By</th>
                          <th style={{ textAlign: 'right', width: '150px' }}>Created On</th>
                          <th style={{ textAlign: 'right', width: '150px' }}>Modified By</th>
                          <th style={{ textAlign: 'right', width: '150px' }}>Modified On</th>
                          <th style={{ textAlign: 'center', width: '250px' }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr ng-repeat="item in list track by $index">
                          <td style={{ paddingLeft: '20px' }}></td>
                          <td style={{ paddingLeft: '100px' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'right' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}></td>
                          <td style={{ textAlign: 'center' }}>
                            <a data-target="#modal-planmaster" data-toggle="modal"><i className="bx bxs-pencil text-dark" /></a> &nbsp;&nbsp;
                    <a ng-click="Delete(item.id)"><i className="bx bxs-trash-alt text-dark" /></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* End Table Content*/}
                    <br />
                    <div className="row">
                      <div className="col-md-12 justify-content-center">
                        <div data-pagination data-num-pages="numOfPages()" data-current-page="curPage" data-max-size="maxSize" data-boundary-links="true" style={{ width: '80%', margin: '0 auto', justifyContent: 'center' }} className="mb-1">
                        </div>
                      </div>
                    </div>
                  </div> {/* End Top Section*/}
                </div> {/* ENd Company Table*/}
                <div className="modal fade" id="modal-planmaster" data-backdrop="true" data-keyboard="false">
                  <div className="modal-dialog modal-lg modal-plus">
                    <div className="modal-content" style={{ overflow: 'hidden' }}>
                      <div className="modal-header modal-bg">
                        <span>New Plan Master</span>
                        <div>
                          <button type="button" className="close-pink" data-dismiss="modal" aria-label="Close">
                            <div style={{ marginTop: '-4px', marginLeft: '1px' }}><span className="cross">×</span></div>
                          </button>
                        </div>
                      </div> {/* End Model-header*/}
                      <div className="modal-body" style={{ padding: '50px', overflowY: 'auto' }}>
                        <form className="form-horizontal" ng-submit="Create(planmasterForm.$valid)" noValidate name="planmasterForm">
                          <div className="form-group row">
                            <div className="row">
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="hidden" ng-model="Planmaster.Id" />
                                  <input type="text" ng-model="Planmaster.planName" ng-blur="CheckAvailability(Planmaster.planName)" className="form-control outline" ng-required="true" />
                                  <label>&nbsp;&nbsp;Plan Name<span>*</span>&nbsp;&nbsp;</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="number" ng-model="Planmaster.userLimits" ng-required="true" className="form-control outline" />
                                  <label>&nbsp;&nbsp;User Limits<span>*</span>&nbsp;&nbsp;</label>
                                </div>
                              </div>
                              <div className="col-md-6 col-12">
                                <div className="form-label-group">
                                  <input type="number" ng-model="Planmaster.totalAmount" className="form-control outline" ng-required="true" />
                                  <label>&nbsp;&nbsp;Total Amount<span>*</span>&nbsp;&nbsp;</label>
                                </div>
                              </div>
                              <div className="col-md-12 col-lg-12 col-12">
                                <div>
                                  <h6 style={{ textAlign: 'center' }}>PLAN MODULES</h6>
                                  <hr />
                                  <table className="table-striped module-table" aria-describedby="example_info" role="grid">
                                    <thead>
                                      <tr>
                                        <th style={{ paddingLeft: '50px' }}><i className="bx bxs-select-multiple" /></th>
                                        <th>Module Name</th>
                                        <th>Submodule Name</th>
                                        <th>Price</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr ng-repeat="item in Planmaster.planModules">
                                        {/*<td><input type="checkbox" ng-model="item.isChecked" /></td>*/}
                                        <td style={{ paddingLeft: '50px' }}><input type="checkbox" ng-model="item.isChecked" /></td>
                                        <td>{'{'}{'{'} item.moduleName {'}'}{'}'}</td>
                                        <td>{'{'}{'{'} item.submoduleName {'}'}{'}'}</td>
                                        <td>{'{'}{'{'} item.price {'}'}{'}'}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <div className="form-group row">
                                    <div className="col-lg-12 col-md-12" style={{ textAlign: 'right' }}>
                                      <button type="submit" className="btn-custom-1">Save</button> &nbsp;&nbsp;&nbsp;
                              <button type="button" data-dismiss="modal" className="btn-custom-2">Cancel</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="clearfix" />
                          </div>
                        </form>
                      </div> {/* End Model-body*/}
                    </div> {/* End Model-Content*/}
                  </div> {/* End Model-dialog*/}
                </div> {/* End Modal Fade*/}
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
}

export default PlanMaster;