import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs,
    onClickTag, onSavedSearchDelete, getHotTags,getFiles
} from '../../../actions/GlobalSearch'

class RelatedMe extends Component {
    constructor(props) {
        super(props)
        this.onTagClick = this.onTagClick.bind(this)
    }

    onTagClick = (i, value) => {
        
        this.props.onClickTag(i, value)
        this.props.onChangeSearch(this.props.search.savedsearch, value,
            this.props.search.userdetails._id, i,this.props.search.files)
            this.props.fnc(value)
    }
    render() {
        return (
            <div class="card-body pt-1" style={{ textAlign: 'left' }}>
                <p onClick={() => {
                    this.onTagClick(1, this.props.search.userdetails.firstname + ' ' + this.props.search.userdetails.lastname)
                }}><i class="bx bx-user-plus"></i> I'm assigned to</p>
                {/* <p onClick={() => {
                    this.onTagClick(3)
                }}><i class="bx bx-file-blank"></i> My Files</p> */}
                {/* <p onClick={() => {
                    this.onTagClick(1)
                }}><i class="bx bx-box"></i> Archived Boards</p> */}
                <p onClick={() => {
                    this.onTagClick(2, this.props.search.userdetails.firstname + ' ' + this.props.search.userdetails.lastname)
                }}><i class="bx bx-at"></i> I was mentioned</p>
                {/* <p onClick={() => {
                    this.onTagClick(2, this.props.search.userdetails.firstname + ' ' + this.props.search.userdetails.lastname)
                }}><i class="bx bx-at"></i> I was mentioned and didn't reply</p> */}
            </div>
        )
    }
}
const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs, onClickTag,
    onSavedSearchDelete, getHotTags
};

export default connect(mapStateToProps, mapDispatchToProps)(RelatedMe);