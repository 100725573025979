import React from 'react';
import Checkbox from 'react-checkbox-component'

//https://github.com/Hugand/react-checkbox-component#readme
function LoginCheckBoxInput({ label, text, isChecked, onValChange, openModal }) {
    return (
        <div className="form-group d-flex flex-md-row flex-column justify-content-between align-items-center" >
            <div className="text-left">
                <div
                    // data-toggle="modal" data-target="#forgotpass" 
                    onClick={openModal}
                    className="card-link text-white cursor-pointer" style={{ fontWeight: 300, cursor: 'pointer' }}>
                    <small style={{ fontWeight: 300, fontSize: '11px' }}>{text}</small>
                </div>
            </div>
            {/* <div className="text-right" style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox size="small" isChecked={isChecked} onChange={onValChange}
                    color="#fc14bb" />
                <label className="checkboxsmall text-white" htmlFor="exampleCheck1" style={{ marginBottom: '0px' }}>
                    <small style={{ fontWeight: '300', fontSize: '11px' }}>{label}</small>
                </label>
            </div> */}
        </div>
    );
}
export default LoginCheckBoxInput;