import * as workspaceApi from '../components/global-search/constants'
import http from '../components/services/http-service';
import db from '../components/global-search/db'
const getSavedSearchStatus = (savedSearch, value) => {
    let savedfound = false;
    if (value != '') {
        var iitem = savedSearch.findIndex(x => x.searchName === value);
        if (iitem > -1) {
            savedfound = true
        } else {
            savedfound = false
        }
    } else {
        savedfound = null
    }
    return savedfound;
}

export const onChangeSearch = (savedSearch, value, userid, searchtype, tags) => {
    return (dispatch) => {
        dispatch({
            type: "ONSEARCHCHANGE",
            payload: { value: value, savedfound: getSavedSearchStatus(savedSearch, value) }
        })
        let text1 = value
        let firstLtr=text1.charAt(0)
        if(firstLtr=="#"){
            text1=text1.substring(1)
        }
        if (searchtype == 1) {
            http.get(workspaceApi.GET_ALL_SEARCH_WORKSPACE + "?value=" + text1 + "&id=" + userid)
                .then((result) => {
                    console.log("--- result", result.data)
                    dispatch({
                        type: "ONSEARCHFOUND",
                        payload: { data: result.data }
                    })
                }).catch(error => {

                })
        }
        else if (searchtype == 2) {
            dispatch({
                type: "ONSEARCHCHANGE",
                payload: { value: value, savedfound: getSavedSearchStatus(savedSearch, value) }
            })
            http.get(workspaceApi.GET_ALL_SEARCH_UPDATES + "?value=" + value + "&id=" + userid)
                .then((result) => {
                    console.log("result",result)
                    
                    dispatch({
                        type: "ONSEARCHFOUND",
                        payload: { data: result.data }
                    })
                }).catch(error => {

                })
        }
        else if (searchtype == 5) {
            const res = tags.filter(obj => Object.values(obj).some(val => val.toLowerCase().includes(value.toLowerCase())));
            dispatch({
                type: "ONSEARCHCHANGE",
                payload: { value: value, savedfound: getSavedSearchStatus(savedSearch, value) }
            })
            dispatch({
                type: "ONSEARCHFOUND",
                payload: { data: { 'tags': res } }
            })
        }
        else if (searchtype == 3) {

            const res = tags.filter(obj => Object.values(obj).some(val => val.toLowerCase().includes(value.toLowerCase())));

            dispatch({
                type: "ONSEARCHCHANGE",
                payload: { value: value, savedfound: getSavedSearchStatus(savedSearch, value) }
            })
            dispatch({
                type: "ONSEARCHFOUND",
                payload: { data: { 'files': res } }
            })
        }
        else if (searchtype == 4) {

            const res = tags.filter(obj => Object.values(obj).some(val => val.toLowerCase().includes(value.toLowerCase())));
            dispatch({
                type: "ONSEARCHCHANGE",
                payload: { value: value, savedfound: getSavedSearchStatus(savedSearch, value) }
            })
            dispatch({
                type: "ONSEARCHFOUND",
                payload: { data: { 'people': res } }
            })
        }
    }
}
export const onTabChange = (value) => {
    return (dispatch) => {
        let classname = 'nav-link', tabcontentclass = 'tab-pane';
        if (value == 0) {
            classname = 'nav-link first-tab active'
            tabcontentclass = 'tab-pane active'
        } else if (value == 1 || value == 2 ||
            value == 3 || value == 4 || value == 5
            || value == 6) {
            classname = 'nav-link active'
            tabcontentclass = 'tab-pane active'
        }
        else {
            classname = 'nav-link active'
            tabcontentclass = 'tab-pane active'
        }
        dispatch({
            type: "ONTABCHANGE",
            payload: { value: value, classname: classname, tabcontentclass: tabcontentclass }
        })
    }
}
export const onFocusSearch = (savedsearch, search) => {
    return (dispatch) => {
        dispatch({
            type: "ONFOCUSSEARCH",
            payload: getSavedSearchStatus(savedsearch, search)
        })
    }
}
export const onSaveSearch = (value, userid) => {
    return (dispatch) => {
        const savedsearchData = {
            searchName: value,
            searchUser: userid,
        };
        http
            .post(workspaceApi.SAVE_SAVED_SEARCH, savedsearchData)
            .then((result) => {
                dispatch({
                    type: "ONSAVESEARCH",
                    payload: result.data.response
                })
            });
    }
}
export const getSavedSearchs = (userid) => {
    return (dispatch) => {
        db.table('saved')
            .get(userid)
            .then((data) => {
                if (data) {
                    dispatch({
                        type: "GETSAVEDSEARCH",
                        payload: data.response
                    })
                }
            })
        http.get(workspaceApi.GET_SAVED_SEARCH_BY_USER_ID + "/" + userid)
            .then((result) => {
                console.log("GETSAVEDSEARCH-result.data.response", result.data)
                result.data.userid = userid
                db.table('saved')
                    .put(result.data)
                    .then(() => {

                        dispatch({
                            type: "GETSAVEDSEARCH",
                            payload: result.data.response
                        })
                    })

            })
    }
}
export const getAllPeople = (userid) => {
    return (dispatch) => {
        http.get(workspaceApi.GET_ALL_USERS)
            .then((result) => {
                var people = []
                result.data.map((item) => {
                    people.push({
                        firstname: item.firstname,
                        lastname: item.lastname,
                        email: item.email,
                        mobileno: item.mobileno,
                        date: item.date
                    })
                })
                dispatch({
                    type: "GETPEOPLE",
                    payload: people
                })
            })
    }
}
export const onBlurSearch = (value, userid) => {
    return (dispatch) => {
        if (value != '') {
            const recentSearchData = {
                searchName: value,
                searchUser: userid,
            };
            http
                .post(workspaceApi.SAVE_RECENT_SEARCH, recentSearchData)
                .then((result) => {
                    dispatch({
                        type: "ONBLURSEARCH",
                        payload: result.data.response
                    })
                });
        }
    }
}

export const getRecentSearchs = (userid) => {
    return (dispatch) => {
        db.table('recent')
            .get(userid)
            .then((data) => {
                if (data) {
                    dispatch({
                        type: "GETRECENTSEARCH",
                        payload: data.response
                    })
                }
            })
        http.get(workspaceApi.GET_RECENT_SEARCH_BY_USER_ID + "/" + userid)
            .then((result) => {

                result.data.userid = userid
                db.table('recent')
                    .put(result.data)
                    .then(() => {

                        dispatch({
                            type: "GETRECENTSEARCH",
                            payload: result.data.response
                        })
                    })

                // dispatch({
                //     type: "GETRECENTSEARCH",
                //     payload: result.data.response
                // })
            })
    }
}
export const onClickTag = (i, value) => {
    return (dispatch) => {
        dispatch({
            type: "ONTAGCLICK",
            payload: {
                tabposition: i,
                search: value,
                classname: 'nav-link active',
                tabcontentclass: 'tab-pane active'
            }
        })
    }
}
export const onSavedSearchDelete = (id, savedsearch, userid) => {
    return (dispatch) => {

        http.post(workspaceApi.DELETE_SAVED_SEARCH, { id: id }).then((result) => {
            if (result.data.message == 'deleted') {
                var array = [...savedsearch];
                var iitem = savedsearch.findIndex(x => x._id === id);
                array.splice(iitem, 1);
                // console.log("ONSAVEDSEARCHDELETE",{"data":array,userid:userid})
                let data = { "response": array, "userid": userid }
                db.table('saved')
                    .put(data)
                    .then(() => {
                        dispatch({
                            type: "ONSAVEDSEARCHDELETE",
                            payload: array
                        })
                    })
            }
        })
    }
}
export const getHotTags = (userid) => {
    let dataArray = [];
    return (dispatch) => {
        db.table('hottags')
            .get(userid)
            .then((tags) => {
                if (tags) {
                    console.log("GETHOTTAGS-dataArray", tags)
                    //let dataArray = [];
                    tags.data.map((result) => {
                        let data = JSON.parse(result.group)
                        data.map((item) => {
                            item.groupItems.map((items) => {
                                if (items?.category == 'body') {
                                    items.cellItems.map((item2) => {
                                        if (item2.key == 'tags') {

                                            let tagdata = JSON.parse(item2.body)
                                            if (tagdata.length > 0) {
                                                tagdata.map((item3) => {
                                                    // var iitem = tagdata.findIndex(x => x._id === item3._id);
                                                    // if (iitem != 1) {
                                                    let bodyindex = item.members?.indexOf(userid)
                                                    if (bodyindex < 0 || (typeof bodyindex === 'undefined')) {

                                                        let dataindex = item2.restrictedMembers?.indexOf(userid)
                                                        if (dataindex < 0 || (typeof dataindex === 'undefined')) {
                                                            if (dataArray.filter(value => value.name == item3.name).length > 0) {

                                                            } else {
                                                                dataArray.push({
                                                                    name: item3.name,
                                                                    color: item3.color,
                                                                    _id: item3._id,
                                                                })
                                                            }

                                                        }
                                                    }
                                                    // }
                                                })
                                            }
                                        }
                                    })
                                }
                            })
                        })
                    })

                    dispatch({
                        type: "GETHOTTAGS",
                        payload: dataArray
                    })
                }


                http.get(workspaceApi.GET_ALL_WORKSPACE + "?id=" + userid)
                    .then((result) => {
                        let dataArray = []
                        result.data.userid = userid
                        db.table('hottags')
                            .put(result.data).then(() => { })
                        result.data.data.map((result) => {
                            let data = JSON.parse(result.group)
                            data.map((item) => {
                                item.groupItems.map((items) => {
                                    if (items?.category == 'body') {
                                        items.cellItems.map((item2) => {
                                            if (item2.key == 'tags') {

                                                let tagdata = JSON.parse(item2.body)

                                                if (tagdata.length > 0) {
                                                    tagdata.map((item3) => {

                                                        // var iitem = tagdata.findIndex(x => x._id === item3._id);
                                                        // console.log("item2.body-tags  -->"+item3._id,tagdata)
                                                        // if (iitem != 1) {
                                                        let bodyindex = item.members?.indexOf(userid)
                                                        if (bodyindex < 0 || (typeof bodyindex === 'undefined')) {

                                                            let dataindex = item2.restrictedMembers?.indexOf(userid)
                                                            if (dataindex < 0 || (typeof dataindex === 'undefined')) {

                                                                if (dataArray.filter(value => value.name == item3.name).length > 0) {

                                                                } else {
                                                                    dataArray.push({
                                                                        name: item3.name,
                                                                        color: item3.color,
                                                                        _id: item3._id,
                                                                    })
                                                                }

                                                            }
                                                        }
                                                        // }
                                                    })
                                                }
                                            }
                                        })
                                    }
                                })
                            })
                        })
                        dispatch({
                            type: "GETHOTTAGS",
                            payload: dataArray
                        });
                    })
            })
    }
}

export const onBoardDataChange = (data) => {
    // console.log("ONBOARDDATACHANGE==>",data)
    return (dispatch) => {
        dispatch({
            type: "ONBOARDDATACHANGE",
            payload: { data: data }
        })
    }
}

export const getFiles = (userid) => {
    console.log('-->hi')
    return (dispatch) => {
        http.get(workspaceApi.GET_ALL_WORKSPACE + "?id=" + userid)
            .then((result) => {
                let dataArray = [];
                console.log('this.props.search.data.files', result)
                result.data.data.map((result) => {
                    let data = JSON.parse(result.group)

                    data.map((item) => {
                        item.groupItems.map((items) => {
                            if (items?.category == 'body') {
                                items.cellItems.map((item2) => {
                                    if (item2.key == 'file') {

                                        // let tagdata =[]
                                        // console.log("bodyyyy",item2.body)
                                        // if(item2.body!=''){
                                        //     tagdata= JSON.parse(item2.body)
                                        // }

                                        if (item2.body.length > 0) {
                                            item2.body.map((item3) => {
                                                var iitem = item2.body.findIndex(x => x._id === item3._id);
                                                if (iitem != 1) {
                                                    let bodyindex = item.members?.indexOf(userid)
                                                    if (bodyindex < 0 || (typeof bodyindex === 'undefined')) {

                                                        let dataindex = item2.restrictedMembers?.indexOf(userid)
                                                        if (dataindex < 0 || (typeof dataindex === 'undefined')) {
                                                            dataArray.push({
                                                                name: item3.name,
                                                                path: item3.path,
                                                                typeSort: item3.typeSort,
                                                                boardId: result.boardId
                                                            })
                                                        }
                                                    }
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        })
                    })
                })
                dispatch({
                    type: "GETFILES",
                    payload: dataArray
                })
            })
    }
}