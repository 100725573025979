export const  getAllCompany = "company/getAllCompany"; 
export const  getCompanyById = "company/getCompanyById"; 
export const  saveCompany = "company/saveCompany" ; 
export const  updateCompany = "company/updateCompany" ; 
export const  deleteCompany = "company/deleteCompany" ;

export default {
    getAllCompany : getAllCompany , 
    getCompanyById : getCompanyById , 
    saveCompany : saveCompany ,  
    updateCompany : updateCompany , 
    deleteCompany : deleteCompany 
}
