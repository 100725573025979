import React from 'react'
import { saveAs } from 'file-saver'
import moment from 'moment';
const excel = require("exceljs");

const generateExcel = downloads => {
    let workbook = new excel.Workbook();
    workbook.created = new Date();
    workbook.modified = new Date();
    workbook.lastPrinted = new Date();
    let worksheet = workbook.addWorksheet("Scheduler");
    worksheet.autoFilter = {
        from: 'A1',
        to: 'D1',
    }
    worksheet.mergeCells("A1:D1");
    worksheet.getCell('A1').value = 'Scheduler - Calender Planner'

    /*Column headers*/
    worksheet.getRow(2).values = ['Si Nos', 'Title', 'Description', 'Date'];
    worksheet.columns = [
        { key: "slno", width: 8, style: { alignment: { wrapText: true } } },
        { key: "title", width: 30 },
        { key: "description", width: 40 },
        { key: "date", width: 20, style: { alignment: { wrapText: true, horizontal: 'center' } } }
    ];

    ['A2', 'B2', 'C2', 'D2',].map(key => {
        worksheet.getCell(key).font = { name: 'Calibri', family: 4, size: 10, bold: true, color: { argb: 'ffffff' } };
        worksheet.getCell(key).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'e8378f' }
        };

    });


    // Add Array Rows
    worksheet.addRows(downloads);
    var borderStyles = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
    };

    worksheet.eachRow(function (row, number) {

        row.getCell(1).border = borderStyles;
        row.getCell(2).border = borderStyles;
        row.getCell(3).border = borderStyles;
        row.getCell(4).border = borderStyles;
        if (number === 1) {
            row.getCell(1).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: '22386b' }
            }   //
            row.getCell(1).font = { name: 'Calibri', family: 4, size: 10, bold: true, color: { argb: 'ffffff' } };
            row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
            row.height = 22;
        }
        if (number > 1) {
            row.height = 20;
            row.getCell(1).alignment = { vertical: 'middle', horizontal: 'center' };
            row.getCell(2).alignment = { vertical: 'middle', horizontal: 'left' };
            row.getCell(3).alignment = { vertical: 'middle', horizontal: 'left' };
            row.getCell(4).alignment = { vertical: 'middle', horizontal: 'left' };
        }
        if (number > 2) {
            row.getCell(1).value = number - 2;
            row.getCell(4).value = moment(moment.utc(row.getCell(4).value).toDate()).local().format("YYYY-MM-DD HH:mm:ss");
        }


    });
    workbook.xlsx.writeBuffer().then(function (data) {
        var blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, 'Scheduler.xlsx');
    });



}
export default generateExcel;
