import React, { useState, useEffect, useRef } from 'react'
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import InboxList from './inbox-list'
import axios from '../../util/Api';
import './style.css'

const userId = JSON.parse(localStorage.getItem('authUser'))?._id
function Inbox() {
  const [resultData, setData] = useState([])
  const [boardData, setboardData] = useState([])
  const [filterData, setfilterData] = useState([])
  const [showData, setshowData] = useState(true)
  const [selectButton, setButton] = useState(null)
  const [selectFilter, setFilter] = useState(null)
  const [menTion, setMention] = useState([])
  const [showInbox, setshowInbox] = useState(true)
  const [allUpdate, setallUpdate] = useState([])
  const [showmenTion, setshowMention] = useState(false)
  const [showallUpdate, setshowallUpdate] = useState(false)
  const [title, setTitle] = useState(null)
  useEffect(() => {
    axios.get(`/inbox/getInboxByUserId?id=${userId}`).then(({ data }) => {
    console.log("inbox data==>", data)
      setData(data.inbox.sort((a,b)=>new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime()))
      setallUpdate(data.allupdate.sort((a,b)=>new Date(b.createdAt).getTime()-new Date(a.createdAt).getTime()))
      var unique = {};
      var boardTitle = [];
      data.inbox.forEach((item) => {
        if (!unique[item._id]) {
          boardTitle.push(item.title);
          unique[item._id] = true;
        }
      });
      setboardData(boardTitle)
      setButton('inbox')
      console.log("board title==>", boardTitle)
      setTitle('Inbox')
    }).catch(err => {
      console.log("Error:", err)
    })

  }, [])
  const filterByBoard = (boardtitle) => {
    setButton('inbox')
    console.log('boardtitle==>', boardtitle)
    console.log('resultData==>', resultData)
    console.log('filterData==>', filterData)
    if(filterData.length > 0) {
      const filterBoard = resultData.filter(boarddata => boarddata.title === boardtitle)
      console.log('filterBoard==>', filterBoard)
      if(JSON.stringify(filterBoard) === JSON.stringify(filterData))
      {
        setshowData(true)
        setfilterData([])
        setTitle('Inbox')
        setButton('inbox')
        setFilter(null)
        return
      }
      else
      {
      setFilter(boardtitle)
      setTitle('Inbox / '+boardtitle)
      setshowData(false)
      setfilterData(filterBoard)
      return
      }
    }
    else{
    if(showData===true) {
      setshowData(false)
      setTitle('Inbox / '+boardtitle)      
    }
    else {
      setshowData(true)
      setfilterData([])
      setTitle('Inbox')
      setButton('inbox')
      return
    }
  
    setFilter(boardtitle)
    setTitle('Inbox / '+boardtitle)
    const filterBoard = resultData.filter(boarddata => boarddata.title === boardtitle)
    console.log('filterBoard==>', filterBoard)
    setfilterData(filterBoard)
    
    }
  }

  const iwasMentioned = () => {
    
    if(filterData.length > 0) {
      setshowData(true)
      setfilterData([])
    }
    if(selectButton==='allupdate') {
      setButton('unselected')
      setshowMention(true)
    setshowallUpdate(false)
    setButton('mention')
    setTitle('Inbox (I was mentioned)')
    const mention = resultData.filter(boarddata => boarddata.to.includes(userId))
    console.log('mention==>', mention)
    setMention(mention)
    return
    }
    const mention = resultData.filter(boarddata => boarddata.to.includes(userId))
    console.log('mention==>', mention)
    setMention(mention)
    setshowInbox(!showInbox)
    setshowMention(!showmenTion)
    setshowallUpdate(false)
    setButton(showInbox ? 'mention' : 'unselected')
    setTitle(showInbox ? 'Inbox (I was mentioned)' : 'Inbox')
  }

  const allupdate = () => {
    if(filterData.length > 0) {
      setshowData(true)
      setfilterData([])
    }
    if(selectButton==='mention') {
      setButton('unselected')
      setshowallUpdate(true)
    setshowMention(false)
    setButton('allupdate')
    setTitle('All Updates')
    return
    }
    setshowInbox(!showInbox)
    setshowallUpdate(!showallUpdate)
    setshowMention(false)
    setButton(showInbox ? 'allupdate' : 'unselected')
    setTitle(showInbox ? 'All Updates' : 'Inbox')
  }

  const resetinbox = () => {
    setshowInbox(true)
    setButton('inbox')
    setTitle('Inbox')
    setshowData(true)
    setfilterData([])
    setFilter(null)
    setshowMention(false)
    setshowallUpdate(false)
  }

  const refresh = (item) => {
    setData(resultData.map(result => result.messageId === item.messageId ? { ...item } : result));
    setallUpdate(allUpdate.map(allupdate => allupdate.messageId === item.messageId ? { ...item } : allupdate));
    setfilterData(filterData.map(filterdata => filterdata.messageId === item.messageId ? { ...item } : filterdata));
    setMention(menTion.map(mention => mention.messageId === item.messageId ? { ...item } : mention));
  }

  const deleteInbox = (messageId) => {
    setData(resultData.filter(i => i.messageId !== messageId));
    setallUpdate(allUpdate.filter(i => i.messageId !== messageId));
    setfilterData(filterData.filter(i => i.messageId !== messageId))
    setMention(menTion.filter(i => i.messageId !== messageId))
  }

  const replyInbox = (message, messageId) => {
    setData(resultData.filter(i => i.messageId !== messageId));
    setData(prevState => [message, ...prevState])
    setallUpdate(prevState => [message, ...prevState]);
    // setfilterData(filterData.filter(i => i.messageId !== messageId))
    // setMention(menTion.filter(i => i.messageId !== messageId))
  }

  return (
    <>
      <MainMenu link={`inbox`} />
      <div id="whole-layout">
        <NavBar />
        <div className="row dash-blue fixed-top" id="bluetop">
        <div className="col-xl-12 col-sm-12 col-md-4 col-lg-4 col-12" id="dashboard-layout">
          <div>
            <div className="inner-section">
              <h3 style={{fontWeight: 600, marginTop: '10px'}}>Inbox</h3>
              <div style={{marginTop: '-10px'}}><p className="mt-1" /></div></div></div></div>
              
              <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard"><table className="align-items-center table-newdashboard-section-one" style={{marginLeft: '750px'}}><tbody><tr><td rowSpan={2} style={{width: '50px'}}><ul className="nav navbar-nav bookmark-icons"><li className="nav-item d-none d-lg-block nav-search" data-placement="top"><a className="nav-link nav-link-search cursor-pointer" data-toggle="tooltip" title data-original-title="Search your Project"><img src="./images/icon/dashboard-icons/white icons-39.png" /></a></li></ul></td><td rowSpan={2} style={{width: '50px'}}><a data-action="expand" ng-click="fullScreen(this)"><img src="./images/icon/dashboard-icons/module-icons/white icons-28.png" height="25px" style={{cursor: 'pointer'}} /></a></td><td rowSpan={2} style={{width: '50px'}}><a data-action="expand"><img src="./images/icon/dashboard-icons/module-icons/white icons-27.png" height="25px" style={{cursor: 'pointer'}} /></a></td><td rowSpan={2} style={{width: '50px'}}><a data-toggle="modal" data-target="#default"><img src="./images/icon/dashboard-icons/module-icons/white icons-38.png" height="25px" style={{cursor: 'pointer'}} /></a></td><td rowSpan={2} style={{width: '50px'}}><a><img src="./images/icon/dashboard-icons/module-icons/white icons-37.png" height="25px" style={{cursor: 'pointer'}} /></a></td></tr><tr style={{fontWeight: 600}}><td id="value" /><td id="value" /><td id="value" /><td id="value" /><td id="value" /></tr></tbody></table></div></div>

        <div id="appdata" className='app-content content fixed-top content-lay-out'>
          <div className="content-wrapper" style={{ 'padding': '20px' }}>
          <div className="row">
          <div className="col-8">
          <h2>{title}</h2>
          {showInbox ?
          <>
          {showData ? 
          <div class="tab-pane" role="tabpanel" style={{ 'box-shadow' : '0px 2px 6px 1px #dad6d6' }}>
            {
              resultData.length > 0 && resultData.map((item, index) => {
                
                return (<InboxList item={item} refresh={refresh} deleteInbox={deleteInbox} replyInbox={replyInbox} />)
              }

              )
            }
            </div>
            :
          <div class="tab-pane" role="tabpanel">
            {
              filterData.length > 0 && filterData.map((item, index) => {
                
                return (<InboxList item={item} refresh={refresh} deleteInbox={deleteInbox} />)
              }

              )
            }
            </div>
            }
            </>
            :
            <>
            {showmenTion && <div class="tab-pane" role="tabpanel">
            {
              menTion.length > 0 && menTion.map((item, index) => {
                
                return (<InboxList item={item} refresh={refresh} deleteInbox={deleteInbox} />)
              }

              )
            }
            </div>
            }
            {
           showallUpdate && <div class="tab-pane" role="tabpanel">
            {
              allUpdate.length > 0 && allUpdate.map((item, index) => {
                
                return (<InboxList item={item} refresh={refresh} deleteInbox={deleteInbox} />)
              }

              )
            }
            </div>
            }
            </>
            }
            
            </div>
            <div className="col-4">
            <div class="tab-pane" role="tabpanel">
            <h3>View Options</h3>
            <h6> 
            <div className="radio" style={{padding: 5}}> <input type="radio" value="Inbox" id="inbox" name="inbox" checked={selectButton === 'inbox'} onChange={()=>resetinbox()}/> <label class="radio" htmlFor="inbox">Inbox Updates</label></div>
            <div className="radio" style={{padding: 5}}><input type="radio" value="Allupdate" id="allupdate" name="inbox" checked={selectButton === 'allupdate'} onChange={()=>allupdate()}/><label class="radio" htmlFor="allupdate">All Updates</label></div>
            <div className="radio" style={{padding: 5}}><input type="radio" value="Mention" id="mention" name="inbox" checked={selectButton === 'mention'} onChange={()=>iwasMentioned()}/> <label class="radio" htmlFor="mention">I Was Mentioned</label></div>
            </h6>
            {/* <h6><button type='button' className={'allupdate' === selectButton ? 'selected' : 'unselected'} onClick={()=>allupdate()}>All Updates</button></h6>
            <h6><button type='button' className={'mention' === selectButton ? 'selected' : 'unselected'} onClick={()=>iwasMentioned()}>I Was Mentioned</button></h6> */}
            <h3>Filter</h3>
            {
              !showallUpdate && !showmenTion && boardData.length > 0 && boardData.map((item, index) => {
                
                return (<h6> <button type='button' className={item === selectFilter ? 'selected' : 'unselected'} onClick={()=>filterByBoard(item)}>{item}</button></h6>)
              }

              )
            }
            </div>
            </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Inbox
