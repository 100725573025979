import { appendLeadingZeroes, convertDateToString } from ".";

export const IF = (condition, valueTrue = true, valueFalse = false) => {
    try {
        condition.replace('""', '0');

        if (eval(condition)) {
            return valueTrue;
        } else {
            return valueFalse;
        }

    } catch (error) {
        console.log('Error: ', error)
        return 'Error'
    }

}

export const SWITCH = (property, items) => {
    try {
        let value = ''
        let newItems = [];
        if (items % 2 === 1) {
            return 'Error'
        }
        for (let i = 0; i < items.length / 2; i++) {
            let newItem = {
                key: items[2 * i],
                value: items[2 * i + 1]
            }

            newItems.push(newItem)
        }
        for (let i = 0; i < newItems.length; i++) {
            if (property === newItems[i].key) {
                value = newItems[i].value;
                break;
            }
        }
        return value;
    } catch (error) {
        console.log('Error: ', error)
        return 'Error'
    }
}

export const AND = (conditions) => {
    try {
        let flag = true;
        if (conditions.length > 0) {
            for (let i = 0; i < conditions.length; i++) {
                if (eval(conditions[i])) {
                    flag = true;
                } else {
                    return false;
                }
            }
            return flag;
        } else {
            return false;
        }
    } catch (error) {
        console.log('Error: ', error)
        return 'Error'
    }
}

export const OR = (conditions) => {
    try {
        let flag = false;
        if (conditions.length > 0) {
            for (let i = 0; i < conditions.length; i++) {
                if (eval(conditions[i])) {
                    return true;
                } else {
                    flag = false;
                }
            }
            return flag;
        } else {
            return false;
        }
    } catch (error) {
        console.log('Error: ', error)
        return 'Error'
    }
}

export const XOR = (conditions) => {
    try {
        let flag = true;
        if (conditions.length > 0) {
            let flag1 = eval(conditions[0]) ? true : false;
            flag = flag * flag1;
            for (let i = 1; i < conditions.length; i++) {
                let newFlag = flag ^ (eval(conditions[i]) ? true : false);
                flag = newFlag === 1 ? true : false;
            }

            return flag;
        }
        else {
            return false;
        }

    } catch (error) {
        console.log('Error: ', error)
        return 'Error'
    }

}

export const EXACT = (a, b) => {
    let value = a === b ? true : false;
    return value;
}

export const AVERAGE = (items) => {
    let sum = 0;
    if (items.length > 0) {
        for (let i = 0; i < items.length; i++) {
            sum = sum + items[i];
        }
        let average = sum / (items.length);
        return average;
    } else {
        return 'Error'
    }
}

export const COUNT = (items) => {
    let count = 0;
    for (let i = 0; i < items; i++) {
        if (Number.isInteger(items[i])) {
            count = count + 1;
        }
    }
    return count;
}

export const SUM = (items) => {
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
        sum = sum + items[i];
    }
    return sum;
}

export const MOD = (under, index) => {
    try {
        let mod = under % index;
        return mod;
    } catch (error) {
        console.log("Mod Error: ", error)
        return 'Error'
    }
}

export const ROUND = (decimalNumber, point) => {
    try {
        let num = parseFloat(Math.round((decimalNumber + Number.EPSILON) * (Math.pow(10, point))) / (Math.pow(10, point)).toFixed(point));
        return num;
    } catch (error) {
        console.log("ROUND Error: ", error)
        return 'Error'
    }
}

export const ROUNDUP = (decimalNumber, point) => {
    try {
        let num = parseFloat(Math.ceil((decimalNumber + Number.EPSILON) * (Math.pow(10, point))) / (Math.pow(10, point)).toFixed(point));
        return num;
    } catch (error) {
        console.log("ROUNDUP Error: ", error)
        return 'Error'
    }
}

export const ROUNDDOWN = (decimalNumber, point) => {
    try {
        let num = parseFloat(Math.ceil((decimalNumber + Number.EPSILON) * (Math.pow(10, point)) - 1) / (Math.pow(10, point)).toFixed(point));
        return num;
    } catch (error) {
        console.log("ROUNDDOWN Error: ", error)
        return 'Error'
    }
}

export const LOG = (bottomNumber, indexNumber) => {
    try {
        let num = Math.log(bottomNumber) / Math.log(indexNumber);
        return num;
    } catch (error) {
        console.log("LOG Error: ", error)
        return 'Error'
    }
}

export const MIN = (numbers) => {
    try {
        let num = Math.min(...numbers);
        return num;
    } catch (error) {
        console.log("MIN Error: ", error)
        return 'Error'
    }
}

export const MAX = (numbers) => {
    try {
        let num = Math.max(...numbers);
        return num;
    } catch (error) {
        console.log("MAX Error: ", error)
        return 'Error'
    }
}

export const MINUS = (a, b) => {
    try {
        let num = a - b;
        return num;
    } catch (error) {
        console.log("MINUS Error: ", error)
        return 'Error'
    }
}

export const MULTIPLY = (a, b) => {
    try {
        let num = a * b;
        return num;
    } catch (error) {
        console.log("MULTIPLY Error: ", error)
        return 'Error'
    }
}

export const DIVIDE = (a, b) => {
    try {
        if (b === 0) {
            return 'Error'
        } else {
            let num = a / b;
            return num;
        }
    } catch (error) {
        console.log("DIVIDE Error: ", error)
        return 'Error'
    }
}

export const SQRT = (number) => {
    try {
        let num = Math.sqrt(number);
        return num;
    } catch (error) {
        console.log("SQRT Error: ", error)
        return 'Error'
    }
}

export const POWER = (number, powerIndex) => {
    try {
        let num = Math.pow(number, powerIndex);
        return num;
    } catch (error) {
        console.log("POWER Error: ", error)
        return 'Error'
    }
}

export const ABS = (number) => {
    try {
        let num = Math.abs(number);
        return num;
    } catch (error) {
        console.log("ABS Error: ", error)
        return 'Error'
    }
}

export const CONCATENATE = (strings) => {
    try {
        let text = '';
        // console.log("strings===>", strings)
        for (let i = 0; i < strings.length; i++) {
            console.log("strings[i]", strings[i])
            text = text + strings[i];
        }
        return text;
    } catch (error) {
        console.log("CONCATENATE Error: ", error)
        return 'Error'
    }
}

export const REPLACE = (sourceString, start, end, newString) => {
    try {
        let replaceString = sourceString.slice(start - 1, end - 1);
        let text = sourceString.replace(replaceString, newString);
        return text;
    } catch (error) {
        console.log("REPLACE Error: ", error)
        return 'Error'
    }
}

export const SUBSTITUTE = (sourceString, oldString, newString) => {
    try {
        let text = sourceString.replace(oldString, newString);
        return text;
    } catch (error) {
        console.log("SUBSTITUTE Error: ", error)
        return 'Error'
    }
}

export const SEARCH = (searchText, sourceText, start) => {
    try {
        let newSourceText = sourceText.slice(start);
        let currentIndex = newSourceText.search(searchText);
        if (currentIndex > 0) {
            return currentIndex + start;
        } else {
            return 'Error'
        }
    } catch (error) {
        console.log("SEARCH Error: ", error)
        return 'Error'
    }
}

export const LEFT = (sourceText, length) => {
    try {
        let text = sourceText.slice(0, length);
        return text;
    } catch (error) {
        console.log("LEFT Error: ", error)
        return 'Error'
    }
}

export const RIGHT = (searchText, length) => {
    try {
        let stringCount = searchText.length;
        let text = searchText.slice(stringCount - length, length);
        return text;
    } catch (error) {
        console.log("RIGHT Error: ", error)
        return 'Error'
    }
}

export const LEN = (sourceText) => {
    try {
        return sourceText.length;
    } catch (error) {
        console.log("LEN Error: ", error)
        return 'Error'
    }
}

export const REPT = (sourceText, repeatNumber) => {
    try {
        return sourceText.repeat(repeatNumber)
    } catch (error) {
        console.log("REPT Error: ", error)
        return 'Error'
    }
}

export const TRIM = (sourceText) => {
    try {
        return sourceText.trim();
    } catch (error) {
        console.log("TRIM Error: ", error)
        return 'Error'
    }
}

export const UPPER = (sourceText) => {
    try {
        return sourceText.toUpperCase();
    } catch (error) {
        console.log("UPPER Error: ", error)
        return 'Error'
    }
}

export const LOWER = (sourceText) => {
    try {
        return sourceText.toLowerCase();
    } catch (error) {
        console.log("LOWER Error: ", error)
        return 'Error'
    }
}

export const DATE = (year, month, day) => {
    try {
        let date = new Date(year, month, day);
        let newDate = convertDateToString(date)
        return newDate;
    } catch (error) {
        console.log("DATEF Error: ", error)
        return 'Error'
    }
}

export const DAYS = (endDate, startDate) => {
    try {
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffTime = Math.abs(end - start);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    } catch (error) {
        console.log("DAYS Error: ", error)
        return 'Error'
    }
}

export const WORKDAYS = (end, start) => {
    try {
        let startDate = new Date(start);
        let endDate = new Date(end);
        if (!startDate || isNaN(startDate) || endDate < startDate) { return -1; }

        let frD = new Date(startDate.getTime()),
            toD = new Date(endDate.getTime()),
            numOfWorkingDays = 1;

        // reset time portion
        frD.setHours(0, 0, 0, 0);
        toD.setHours(0, 0, 0, 0);
        while (frD < toD) {
            frD.setDate(frD.getDate() + 1);
            let day = frD.getDay();
            if (day !== 0 && day !== 6) { numOfWorkingDays++; }
        }

        return numOfWorkingDays;
    } catch (error) {
        console.log("WORKDAYS Error: ", error)
        return 'Error'
    }
}

export const TODAY = () => {
    return new Date()
}

export const FORMAT_DATE = (stringDate, format) => {
    try {
        let strToDate = new Date(stringDate);
        let year = strToDate.getFullYear();
        let month = strToDate.getMonth();

        let date = strToDate.getDate();
        let months = ['Januray', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let reduceMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let weekday = weekdays[strToDate.getDay()];
        let strMonth = months[month];
        let strReduceMonth = reduceMonths[month];
        console.log("format===>", format);
        console.log("date===>", stringDate);
        switch (format) {
            case 'YYYY-MM-DD':
                return year + "-" + appendLeadingZeroes(month + 1) + "-" + appendLeadingZeroes(date);
            case 'dddd, MMMM Do YYYY':
                return weekday + ", " + strMonth + " " + appendLeadingZeroes(date) + "th " + year
            default:
                return strReduceMonth + " " + date + ", " + year;
        }
    } catch (error) {
        console.log("FORMAT_DATE Error: ", error)
        return 'Error'
    }
}

export const YEAR = (strDate) => {
    try {
        let date = new Date(strDate);
        return date.getFullYear();
    } catch (error) {
        console.log("YEAR Error: ", error)
        return 'Error'
    }
}

export const MONTH = (strDate) => {
    try {
        let date = new Date(strDate);
        return date.getMonth() + 1;
    } catch (error) {
        console.log("MONTH Error: ", error)
        return 'Error'
    }
}

export const WEEKNUM = (strDate) => {
    try {
        let date = new Date(strDate);
        let onejan = new Date(date.getFullYear(), 0, 1);
        let week = Math.ceil((((date.getTime() - onejan.getTime()) / 86400000) + onejan.getDay() + 1) / 7);
        return week;
    } catch (error) {
        console.log("MONTH Error: ", error)
        return 'Error'
    }
}

export const DAY = (strDate) => {
    try {
        let date = new Date(strDate);
        return date.getDate();
    } catch (error) {
        console.log("DAY Error: ", error)
        return 'Error'
    }
}

export const HOUR = (strDate) => {
    try {
        let date = new Date(strDate);
        return date.getHours();
    } catch (error) {
        console.log("HOUR Error: ", error)
        return 'Error'
    }
}

export const MINUTE = (strDate) => {
    try {
        let date = new Date(strDate);
        return date.getMinutes();
    } catch (error) {
        console.log("MINUTE Error: ", error)
        return 'Error'
    }
}

export const SECOND = (strDateTime) => {
    try {
        let date = new Date(strDateTime);
        return date.getSeconds();
    } catch (error) {
        console.log("SECOND Error: ", error)
        return 'Error'
    }
}

export const ADD_DAYS = (strDate, addDays) => {
    try {
        let date = new Date(strDate);
        date.setDate(date.getDate() + addDays);
        let newDate = convertDateToString(date);
        return newDate;
    } catch (error) {
        console.log("ADD_DAYS Error: ", error)
        return 'Error'
    }
}

export const SUBTRACT_DAYS = (strDate, subtractDays) => {
    try {
        let date = new Date(strDate);
        date.setDate(date.getDate() - subtractDays);
        let newDate = convertDateToString(date);
        return newDate;
    } catch (error) {
        console.log("SUBTRACT_DAYS Error: ", error)
        return 'Error'
    }
}

export const HOURS_DIFF = (endTime, startTime) => {
    try {
        let startHours = parseInt(startTime.slice(0, startTime.search(':')));
        let startMiutes = parseInt(startTime.slice(startTime.search(':') + 1));
        let endHours = parseInt(endTime.slice(0, endTime.search(':')));
        let endMinutes = parseInt(endTime.slice(endTime.search(':') + 1));
        if (startHours < endHours) {
            if (startMiutes > endMinutes) {
                let diffHours = endHours - startHours - 1;
                let diffMinutes = endMinutes + 60 - startMiutes;
                return appendLeadingZeroes(diffHours) + ":" + appendLeadingZeroes(diffMinutes);
            } else {
                return appendLeadingZeroes(endHours - startHours) + ':' + appendLeadingZeroes(endMinutes - startMiutes);
            }
        } else if (startHours === endHours) {
            if (startMiutes > endMinutes) {
                return 'Error'
            } else {
                return "00:" + appendLeadingZeroes(endMinutes - startMiutes)
            }
        }
        else {
            return 'Error';
        }
    } catch (error) {
        console.log("HOURS_DIFF Error: ", error)
        return 'Error'
    }
}