import React, { Component } from 'react';
import Joi from 'joi-browser';
import Input from './input';
import http from '../../services/http-service';
import SelectOption from '../common/select-option-control';

class countryForm extends Component {
  validateForm() {
    const options = { abortEarly: false }
    const { error } = Joi.validate(this.state.countryToValidate, this.schema, options);
    if (!error) return null;
    const errors = {};
    for (let item of error.details)
      errors[item.path[0]] = item.message;
    return errors;
  }

  handleSaveSubmit = (e) => {
    const errors = this.validateForm();
    this.setState({ errors: errors || {} })
    if (errors)
      return;
  }

  refreshPage = () => {
    window.location.reload();
  }

  onSort = (path) => {
    console.log(path);
  }

  renderCancelButton() {
    return (
      <button type="button" className="btn-custom-2" data-dismiss="modal" >Cancel</button>
    )
  }

renderInput(type = "text", value, label, error, onChange) {   //here default value for type is text it can be changed in any matter...
    return (
      <Input
        type={type}
        value={value}
        label={label}
        onChange={onChange}
        error={error}
      />
    );
  }

renderSelectOptionsControl(value  , onChange , options , label , error){
  return(
      <SelectOption 
          value={value} 
          onChange={onChange} 
          options={options}
          label = {label}
          error = {error}
          />
  )
}
                
  renderModalHeaderTitle(title) {
    return (
      <div className="modal-header modal-bg">
        <span>{title}</span>
        <div>
          <button type="button" className="close-pink" data-dismiss="modal" aria-label="Close">
            <div style={{ marginTop: '-4px', marginLeft: '1px' }}><span className="cross">×</span></div>
          </button>
        </div>
      </div>
    );
  }

  returnFormName(formName) {
    return formName;
  }

  renderSubmitButton(type, disabled, action, label) {
    return (
      <button type={type} className="btn-custom-1" disabled={disabled} onClick={action} >{label}</button>
    )
  }

  handlePageChange = (page) => {
    this.setState({
      currentPage: page
    })
  }

  doSaveOrUpdate = async (data, saveLink, updateLink) => {
    try {
      if (this.state.Id === "") {
        var key = "id";
        delete data[key];
        data.IsDeleted = false;
        data.IsActive = true;

        await http.post(saveLink, data)
          .then((response) => {
            console.log(">>>Data Saved", response.data.data)
          })
      } else {
        await http.put(updateLink, data)
          .then((response) => {
            console.log(">>>Data Updated", response.data.data)
          })
      }
    } catch (e) {
      //check
      // alert("Some Thing Went Wrong..May Be Name Already Exisit..Please Try Again Or Contact With Support")
      console.log(">>>Show the Error", e)
    }

  }

  doDeleteRecord = async (id, deleteLink) => {
    if (window.confirm("Do You Want To Delete This Record?")) {
      try {
        await http.delete(deleteLink + `?id=${id}`)
          .then((response) => {
            this.refreshPage();
            alert('Record Deleted Successfully...');
          })
      } catch (e) {
        alert('There is Error Happen In Deleting , Please Try Again...');
        this.refreshPage();
      }
    }
    else {
      return;
    }
  }

validateProperty = (targetValue, sechameName) => {
    const { error } = Joi.validate(targetValue, sechameName);
    return error ? error : null;
  }
}
export default countryForm;


  // validateProperty({name , value}){
  //     const obj = {[name] : value}; 
  //     const schema = {[name] : this.schema.Name}
  //     const {error } = Joi.validate(obj , schema); 
  //     return error ? error.details[0].message : null;
  // }