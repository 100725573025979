import config from '../../services/config.json';
import SavedSearch from '../../../data-services/saved-search-data-service';

export const GET_ALL_SAVED_SEARCH = config.apiEndPoint + SavedSearch.getAllSavedSearch;

export const GET_SAVED_SEARCH_BY_ID = config.apiEndPoint + SavedSearch.getSavedSearchById;

export const GET_MAX_SAVED_SEARCH = config.apiEndPoint + SavedSearch.getMAxSavedSearch;

export const SAVE_SAVED_SEARCH = config.apiEndPoint + SavedSearch.saveSavedSearch;

export const UPDATE_SAVED_SEARCH = config.apiEndPoint + SavedSearch.updateSavedSearch;

export const DELETE_SAVED_SEARCH = config.apiEndPoint + SavedSearch.deleteSavedSearch;

export const SAVED_SEARCH_SEARCH = config.apiEndPoint + SavedSearch.SavedSearch_Search;

