import React from 'react';

function ModalEvent(props) {
  return (
    <div className="modal fade" id="modal-event" data-backdrop="static">
      <div className="modal-dialog modal-lg">
        <div className="modal-content" style={{ padding: 0, width: '50%', margin: '0 auto', border: 'none', marginTop: '50px' }}>
          <div className="modal-header _600" style={{ backgroundColor: '#22386B', color: '#fff', borderRadius: '8px 8px 0px 0px', height: '60px' }}>
            Event
            <button type="button" className="close-pink" ng-click="closeeventPopup()" aria-label="Close">
              <div style={{ color: '#ffffff', marginTop: '-2px' }}><span aria-hidden="true">×</span></div>
            </button>
          </div>
          <form className="form-horizontal" noValidate name="eventForm">
            <div className="modal-body" style={{ padding: '40px' }}>
              <div className="form-group row">
                <label className="col-lg-2 form-control-label">Start:</label>
                <div className="col-lg-8">
                  <input type="date" ng-model="event.start" style={{ display: 'none' }} id="txtstart" className="form-control" />
                  <input type="datetime-local" ng-model="event.start" id="txtstart1" className="form-control" />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-2 form-control-label">End:</label>
                <div className="col-lg-8">
                  <input type="date" ng-model="event.end" style={{ display: 'none' }} id="txtend" className="form-control" />
                  <input type="datetime-local" ng-model="event.end" id="txtend1" className="form-control" />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-2 form-control-label">All day event:</label>
                <div className="col-lg-8">
                  <input type="checkbox" ng-model="event.isallday" ng-change="changeIsallday()" defaultChecked="checked" />
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-2 form-control-label">Repeat:</label>
                <div className="col-lg-8" ng-model="event.repeat">
                  <select className="form-control" ng-model="event.repeat" ng-change="changeRepeat()" style={{ height: '30px', fontSize: '12px' }}>
                    <option value={0}>Never</option>
                    <option value={1}>Hourly</option>
                    <option value={2}>Daily</option>
                    <option value={3}>Weekly</option>
                    <option value={4}>Monthly</option>
                    <option value={5}>Yearly</option>
                  </select>
                </div>
              </div>
              <div id="divhourly" style={{ display: 'none' }}>
                <div className="form-group row">
                  <label className="col-lg-2 form-control-label">Repeat every:</label>
                  <div className="col-lg-8">
                    <input type="number" ng-model="event.repeateveryhourly" min={1} className="form-control" /> hours
                  </div>
                </div>
              </div>
              <div id="divdaily" style={{ display: 'none' }}>
                <div className="form-group row">
                  <label className="col-lg-2 form-control-label">Repeat every:</label>
                  <div className="col-lg-8">
                    <input type="number" ng-model="event.repeateverydaily" min={1} className="form-control" /> days
                  </div>
                </div>
              </div>
              <div id="divweekly" style={{ display: 'none' }}>
                <div className="form-group row">
                  <label className="col-lg-2 form-control-label">Repeat every:</label>
                  <div className="col-lg-8">
                    <input type="number" ng-model="event.repeateveryweekly" min={1} className="form-control" /> weeks
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-2 form-control-label">Repeat on:</label>
                  <div className="col-lg-8">
                    <input type="checkbox" id="weeklysun" ng-model="event.repeatonweeklysun" />
                    <label htmlFor="weeklysun">Su</label>
                    <input type="checkbox" id="weeklymon" ng-model="event.repeatonweeklymon" />
                    <label htmlFor="weeklymon">Mo</label>
                    <input type="checkbox" id="weeklytue" ng-model="event.repeatonweeklytue" />
                    <label htmlFor="weeklytue">Tu</label>
                    <input type="checkbox" id="weeklywed" ng-model="event.repeatonweeklywed" />
                    <label htmlFor="weeklywed">We</label>
                    <input type="checkbox" id="weeklythu" ng-model="event.repeatonweeklythu" />
                    <label htmlFor="weeklythu">Th</label>
                    <input type="checkbox" id="weeklyfri" ng-model="event.repeatonweeklyfri" />
                    <label htmlFor="weeklyfri">Fr</label>
                    <input type="checkbox" id="weeklysat" ng-model="event.repeatonweeklysat" />
                    <label htmlFor="weeklysat">Sa</label>
                  </div>
                </div>
              </div>
              <div id="divmonthly" style={{ display: 'none' }}>
                <div className="form-group row">
                  <label className="col-lg-2 form-control-label">Repeat every:</label>
                  <div className="col-lg-8">
                    <input type="number" ng-model="event.repeateverymonthly" min={1} className="form-control" /> months
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-2 form-control-label">Repeat on:</label>
                  <div className="col-lg-8">
                    <input type="radio" name="repeatonmonthly" defaultValue={1} ng-click="changeRepeatOnWeekly()" /> Date : <input type="number" min={1} max={31} className="form-control" style={{ width: '50%' }} ng-model="event.repeatonmonthlydate" ng-disabled="event.repeatonmonthlydatedisabled" />
                    <br />
                    <input type="radio" name="repeatonmonthly" defaultValue={2} ng-click="changeRepeatOnWeekly()" />
                    <select ng-disabled="event.repeatonmonthlydisabled" ng-model="event.repeatonmonthlyweek" className="form-control" style={{ width: '50%' }}>
                      <option value={1}>first</option>
                      <option value={2}>second</option>
                      <option value={3}>third</option>
                      <option value={4}>fourth</option>
                      <option value={-1}>last</option>
                    </select>
                    <select ng-disabled="event.repeatonmonthlydisabled" ng-model="event.repeatonmonthlyday" className="form-control" style={{ width: '50%' }}>
                      <option value="SU,MO,TU,WE,TH,FR,SA">day</option>
                      <option value="MO,TU,WE,TH,FR">weekday</option>
                      <option value="SU,SA">weekend day</option>
                      <option value="SU">Sunday</option>
                      <option value="MO">Monday</option>
                      <option value="TU">Tuesday</option>
                      <option value="WE">Wednesday</option>
                      <option value="TH">Thursday</option>
                      <option value="FR">Friday</option>
                      <option value="SA">Saturday</option>
                    </select>
                  </div>
                </div>
              </div>
              <div id="divyearly" style={{ display: 'none' }}>
                <div className="form-group row">
                  <label className="col-lg-2 form-control-label">Repeat every:</label>
                  <div className="col-lg-8">
                    <input type="number" ng-model="event.repeateveryyearly" min={1} className="form-control" /> years
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-2 form-control-label">Repeat on:</label>
                  <div className="col-lg-8">
                    <input type="radio" name="repeatonyearly" defaultValue={1} ng-click="changeRepeatOnYearly()" />
                    Date :
                    <select className="form-control" style={{ width: '50%' }} ng-disabled="event.repeatonyearly1disabled" ng-model="event.repeatonyearly1month">
                      <option value={1}>January</option>
                      <option value={2}>February</option>
                      <option value={3}>March</option>
                      <option value={4}>April</option>
                      <option value={5}>May</option>
                      <option value={6}>June</option>
                      <option value={7}>July</option>
                      <option value={8}>August</option>
                      <option value={9}>September</option>
                      <option value={10}>October</option>
                      <option value={11}>November</option>
                      <option value={12}>December</option>
                    </select>
                    <input type="number" className="form-control" min={1} max={31} style={{ width: '50%' }} ng-disabled="event.repeatonyearly1disabled" ng-model="event.repeatonyearly1date" />
                    <br />
                    <input type="radio" name="repeatonyearly" defaultValue={2} ng-click="changeRepeatOnYearly()" />
                    <select className="form-control" style={{ width: '50%' }} ng-disabled="event.repeatonyearly2disabled" ng-model="event.repeatonyearly2week">
                      <option value={1}>first</option>
                      <option value={2}>second</option>
                      <option value={3}>third</option>
                      <option value={4}>fourth</option>
                      <option value={-1}>last</option>
                    </select>
                    <select className="form-control" style={{ width: '50%' }} ng-disabled="event.repeatonyearly2disabled" ng-model="event.repeatonyearly2day">
                      <option value="SU,MO,TU,WE,TH,FR,SA">day</option>
                      <option value="MO,TU,WE,TH,FR">weekday</option>
                      <option value="SU,SA">weekend day</option>
                      <option value="SU">Sunday</option>
                      <option value="MO">Monday</option>
                      <option value="TU">Tuesday</option>
                      <option value="WE">Wednesday</option>
                      <option value="TH">Thursday</option>
                      <option value="FR">Friday</option>
                      <option value="SA">Saturday</option>
                    </select> of
                    <select className="form-control" style={{ width: '50%' }} ng-disabled="event.repeatonyearly2disabled" ng-model="event.repeatonyearly2month">
                      <option value={1}>January</option>
                      <option value={2}>February</option>
                      <option value={3}>March</option>
                      <option value={4}>April</option>
                      <option value={5}>May</option>
                      <option value={6}>June</option>
                      <option value={7}>July</option>
                      <option value={8}>August</option>
                      <option value={9}>September</option>
                      <option value={10}>October</option>
                      <option value={11}>November</option>
                      <option value={12}>December</option>
                    </select>
                  </div>
                </div>
              </div>
              <div id="divrepeatend" style={{ display: 'none' }}>
                <div className="form-group row">
                  <label className="col-lg-2 form-control-label">End:</label>
                  <div className="col-lg-8">
                    <input type="radio" id="never" name="repeatend" defaultValue={1} defaultChecked="checked" ng-click="changeRepeatEnd()" />
                    <label htmlFor="never">Never</label>
                    <br />
                    <input type="radio" id="after" name="repeatend" defaultValue={2} ng-click="changeRepeatEnd()" />
                    <label htmlFor="after">After</label>
                    <input type="number" min={1} className="form-control" style={{ width: '50%' }} ng-model="event.repeatendoccurrence" ng-disabled="event.repeatendoccurrencedisabled" /> occurrence(s)
                    <br />
                    <input type="radio" id="on" name="repeatend" defaultValue={3} ng-click="changeRepeatEnd()" />
                    <label htmlFor="on">On</label>
                    <input type="date" ng-model="event.repeatendon" ng-disabled="event.repeatendondisabled" id="txtrepeatendon" className="form-control" style={{ width: '50%' }} />
                  </div>
                </div>
              </div>
              <br />
              <div className="form-group row">
                <div className="col-md-12 col-lg-12">
                  <button type="button" ng-click="createEvent()" className="btn-custom-1">Save</button> &nbsp;&nbsp;&nbsp;
                  <button type="button" className="btn-custom-2" ng-click="closeeventPopup()">Cancel</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default ModalEvent;