import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { filterBoardData, getColumnArray, getFilterColumns } from '../../../../../util/index';
import { searchFilterBoardData } from '../../../../../actions';
import { calculatorEquation } from '../../../../../util/bottom';

class FilterDropdownComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            groups: this.props.boardData.storeData,
            columns: this.props.boardData.cellSuggestions,
            columArray: {},
            filterOptions: []
        }
    }

    componentDidMount() {
        if (this.props.boardData.boardID !== "") {
            this.setState({ groups: this.props.groups })
        }

        if (this.state.groups.length > 0 && this.props.boardData.boardID !== "" && this.props.boardData.storeData.length > 0) {
            let columArray = getColumnArray(this.state.groups);
            // console.log("columns", columns);
            let columns = getFilterColumns(this.props.boardData.cellSuggestions, this.props.boardData.storeData[0].groupItems[0].cellItems);
            // console.log("columns====>", columns)
            this.setState({ columArray: columArray, columns: columns })
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (this.props.boardData.boardID !== '' && this.props.boardData.boardID !== prevProps.boardData.boardID) {
            let columns = getColumnArray(this.props.groups);
            this.setState({ groups: this.props.groups, columns: this.props.columns, columArray: columns });
        }


        if (this.state.groups.length > 0 && this.props.boardData.boardID !== "" && this.props.boardData.boardID !== prevProps.boardData.boardID) {
            let columns = getColumnArray(this.state.groups);
            this.setState({ columArray: columns })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.boardData.boardID !== "" && nextProps.boardData.storeData.length > 0) {
            let columns = getColumnArray(nextProps.groups);
            let newColumns = getFilterColumns(nextProps.columns, nextProps.boardData.storeData[0].groupItems[0].cellItems);
            this.setState({ groups: nextProps.groups, columns: newColumns, columArray: columns });
        }
    }

    getGroupFilterLists = (groups) => {
        let labels = {}, e;
        for (let i = 0; i < groups.length; i++) {
            e = groups[i];
            labels[e.label] = (labels[e.label] || 0) + 1;
        }

        let newGroups = [];
        let keys = Object.keys(labels);
        for (let i = 0; i < keys.length; i++) {
            let group = {
                title: keys[i],
                count: labels[keys[i]],
                option: 'group'
            }

            newGroups.push(group)
        }
        return newGroups;
    }

    getGroupItemFilterLists = (groups) => {
        let names = {};
        for (let i = 0; i < groups.length; i++) {
            for (let j = 0; j < groups[i].groupItems.length; j++) {
                if (groups[i].groupItems[j].category === 'body') {
                    let groupItem = groups[i].groupItems[j];
                    names[groupItem.title] = (names[groupItem.title] || 0) + 1;
                }
            }
        }

        let newItems = [];
        let keys = Object.keys(names);
        for (let i = 0; i < keys.length; i++) {
            let item = {
                title: keys[i],
                count: names[keys[i]],
                option: 'name'
            }

            newItems.push(item)
        }

        return newItems;
    }

    getColumnFilterLists = (columnArray) => {
        let array = [];
        if (columnArray !== undefined && columnArray !== null && JSON.stringify(columnArray) !== '{}') {
            let key = columnArray[0].key;
            if (key === 'tags' || key === 'dropdown') {
                let names = {};
                for (let i = 0; i < columnArray.length; i++) {
                    let cellItem = columnArray[i];
                    if (JSON.parse(cellItem.body).length === 0) {
                        names['blank'] = (names['blank'] || 0) + 1;
                    } else {
                        let cellbodies = JSON.parse(cellItem.body);
                        for (let j = 0; j < cellbodies.length; j++) {
                            names[cellbodies[j].name] = (names[cellbodies[j].name] || 0) + 1;
                        }
                    }
                }
                let keys = Object.keys(names);
                for (let i = 0; i < keys.length; i++) {
                    let item = {
                        title: keys[i],
                        count: names[keys[i]],
                        option: 'item'
                    }

                    array.push(item)
                }

            } else if (key === 'date') {
                let names = {};
                for (let i = 0; i < columnArray.length; i++) {
                    let cellItem = columnArray[i];
                    if (cellItem.body === "") {
                        names['blank'] = (names['blank'] || 0) + 1;
                    } else {
                        let bodyDate = new Date(cellItem.body);
                        let now = new Date();

                        if (bodyDate.getFullYear() < now.getFullYear()) {
                            names['Past Dates'] = (names['Past Dates'] || 0) + 1;
                        } else if (bodyDate.getFullYear() === now.getFullYear()) {
                            if (bodyDate.getMonth() < now.getMonth()) {
                                names['Past Dates'] = (names['Past Dates'] || 0) + 1;
                            } else if (bodyDate.getMonth() === now.getMonth()) {
                                names['This Month'] = (names['This Month'] || 0) + 1;
                            } else {
                                names['Future Dates'] = (names['Future Dates'] || 0) + 1;
                            }
                        } else {
                            names['Future Dates'] = (names['Future Dates'] || 0) + 1;
                        }
                    }
                }

                let keys = Object.keys(names);
                for (let i = 0; i < keys.length; i++) {
                    let item = {
                        title: keys[i],
                        count: names[keys[i]],
                        option: 'item'
                    }

                    array.push(item)
                }
            } else if (key === 'formula') {
                let names = {};
                for (let i = 0; i < columnArray.length; i++) {
                    let cellItem = columnArray[i];
                    if (cellItem.equation === undefined || cellItem.equation === '') {
                        names['blank'] = (names['blank'] || 0) + 1;
                    } else {
                        let value = calculatorEquation(cellItem.equation, cellItem.activeItems, cellItem.groupItem, cellItem.columns);
                        names[value] = (names[value] || 0) + 1;
                    }
                }

                let keys = Object.keys(names);
                for (let i = 0; i < keys.length; i++) {
                    let item = {
                        title: keys[i],
                        count: names[keys[i]],
                        option: 'item'
                    }

                    array.push(item)
                }
            } else if (key === 'file' || key === 'lastUpdated') {

            } else if(key === 'people') {
                //debugger;
                let names = {};
                for (let i = 0; i < columnArray.length; i++) {
                    let cellItem = columnArray[i];
                    if (JSON.parse(cellItem.body).length === 0) {
                        names['blank'] = (names['blank'] || 0) + 1;
                    } else {
                        let cellbodies = JSON.parse(cellItem.body);
                        for (let j = 0; j < cellbodies.length; j++) {
                            names[cellbodies[j].name] = (names[cellbodies[j].name] || 0) + 1;
                        }
                    }
                }
                let keys = Object.keys(names);
                for (let i = 0; i < keys.length; i++) {
                    let item = {
                        title: keys[i],
                        count: names[keys[i]],
                        option: 'item'
                    }

                    array.push(item)
                }
            }
            else if(key === 'reminder') {
              
                let names = {};
                for (let i = 0; i < columnArray.length; i++) {
                    let cellItem = columnArray[i];
                    if (typeof cellItem.body === 'string' || cellItem.body instanceof String) {
                        names['blank'] = (names['blank'] || 0) + 1;
                    } else {
                        // let cellbodies = cellItem;
                        // for (let j = 0; j < cellbodies.length; j++) {
                        //     names[cellbodies[j].body] = (names[cellbodies[j].body] || 0) + 1;
                        // }
                        names[JSON.stringify(cellItem.body)] = (names[JSON.stringify(cellItem.body)] || 0) + 1;
                    }
                }

                let keys = Object.keys(names);
                for (let i = 0; i < keys.length; i++) {
                    debugger
                    let item = {
                        title: keys[i],
                        count: names[keys[i]],
                        option: 'item',
                        isReminder: true,
                        dateTime: JSON.parse(JSON.stringify(keys[i])).dateTime
                    }

                    array.push(item)
                }
            }else {
                let names = {};
                for (let i = 0; i < columnArray.length; i++) {
                    let cellItem = columnArray[i];
                    if (cellItem.body === "") {
                        names['blank'] = (names['blank'] || 0) + 1;
                    } else {
                        names[cellItem.body] = (names[cellItem.body] || 0) + 1;
                    }
                }

                let keys = Object.keys(names);
                for (let i = 0; i < keys.length; i++) {
                    let item = {
                        title: keys[i],
                        count: names[keys[i]],
                        option: 'item'
                    }

                    array.push(item)
                }

            }
        }

        return array;
    }

    filter = (title, option, columnId = null) => {
        let filterOptions = this.state.filterOptions;
        let filterOptionCheck = filterOptions.filter(filter => filter.title === title && filter.option === option && filter.columnId === columnId);
        if (filterOptionCheck.length === 0) {
            let filterOption = {
                title: title,
                option: option,
                columnId: columnId
            }

            let filterItems = [...this.state.filterOptions, filterOption];
            this.setState({ filterOptions: filterItems })
            // let newGroups = filterBoardData(this.state.groups, this.state.filterOptions);
            // console.log("newGroups", newGroups)
            setTimeout(() => {
                this.filterWorkspaceBoard();
            }, 200)
        }

    }

    getSelectedFilterLists = (option, columnId) => {
        let filterOptions = this.state.filterOptions;
        // console.log("filterOptions", filterOptions);
        if (filterOptions.length > 0) {
            let selectedFilterLists = filterOptions.filter(filter => filter.option === option && filter.columnId === columnId);
            // console.log("selectedFilterLists", selectedFilterLists)
            return selectedFilterLists;
        } else {
            return [];
        }

    }

    removeSelectedOption = (option, columnId) => {
        // console.log("option===>", option);
        // console.log("columnId===>", columnId)
        let filterOptions = this.state.filterOptions;
        let filterOptionIndex = filterOptions.findIndex(filter => filter.option === option && filter.columnId === columnId);
        // console.log("filterOptions", filterOptions)
        // console.log("find index===>", filterOptionIndex)

        const { removeItem, } = filterOptions.splice(filterOptionIndex, 1);
        // console.log("removeItem", removeItem);

        // filterOptions.slice(0, filterOptionIndex).concat(filterOptions.slice(-filterOptionIndex));
        // console.log("removed option:", filterOptions)

        this.setState({
            filterOptions: filterOptions
        });
        setTimeout(() => {
            this.filterWorkspaceBoard();
        }, 200)
    }

    filterWorkspaceBoard = () => {
        let newGroups = filterBoardData(this.state.groups, this.state.filterOptions);
        // console.log("newGroups", newGroups);
        this.props.searchFilterBoardData({
            boardData: newGroups
        })
    }

    render() {
        let groups = this.getGroupFilterLists(this.state.groups);
        let items = this.getGroupItemFilterLists(this.state.groups);
        // console.log("this.state.groups", this.state.groups)
        // console.log("items", items)
        let columns = this.state.columArray;

        // console.log("this.state.columns", this.state.columns)
        return (
            <>
                  <h5 style={{fontSize: '14.5px', backgroundColor: '#21376b', padding: '13px 8px 11px', color: '#fff', borderRadius: '8px 8px 0px 0px' , 'height' : '45px'}}>Filter your Board</h5>
                <div className='filter-component' style={{ maxHeight: "360px", maxWidth: 'auto', display: 'flex', overflowX: 'auto', minHeight: '250px' }}>
                    <ul className="filter-list">
                        <h6 style={{ color: '#21386C' }}>Group</h6>
                        <div className='filter-cell' style={{ 'paddingRight' : '8px' }}>
                            {groups.map((group, index) => (
                                <div key={index} name="removableItem">
                                    <li data-tip={`${group.title}`} name="removableItem" onClick={() => this.filter(group.title, group.option)}>
                                        <button className='btn filter-button' name="removableItem"  >{group.title}</button>
                                        <span class="filter-count" name="removableItem">{group.count}</span>
                                    </li>
                                    <ReactTooltip place="left" />
                                </div>
                            ))}
                        </div>
                        {this.getSelectedFilterLists('group', null).length > 0 ? (
                            <>
                                <div>
                                    <li style={{ borderWidth: '0' , backgroundColor: '#4288f5' }}>
                                        <span style={{  
    'border': '1px solid #4288f5' ,
    'background-color': '#fff0 !important' ,
    'width': '100%' ,
    'border-radius': '5px' ,
    'color': '#fff' , 
    'font-weight': '500' , 
    }}>{this.getSelectedFilterLists('group', null).length} Selected</span>
                                    </li>
                                </div>
                                <div className='filter-cell' name="removableItem" style={{ maxHeight: '125px',  marginBottom: '10px' }}>

                                    {this.getSelectedFilterLists('group', null).map((item, index) => (
                                        <div key={index} name="removableItem">
                                            <li data-tip={`${item.title}`} name="removableItem" onClick={() => this.removeSelectedOption(item.option, item.columnId)}>
                                                <button className='btn ' name="removableItem" style={{ width: '100%', textAlign: 'inherit' }} >{item.title}</button>
                                            </li>
                                            {/* <ReactTooltip place="left" /> */}
                                        </div>
                                    ))}
                                </div>
                            </>

                        ) : ""}
                    </ul>

                    <ul className="filter-list">
                        <h6 style={{ color: '#21386C' }}> Name</h6>
                        <div className='filter-cell' style={{ 'paddingRight' : '8px' }}>
                            {items.map((item, index) => (
                                <div key={index} name="removableItem">
                                    <li data-tip={`${item.title}`} name="removableItem" onClick={() => this.filter(item.title, item.option)}>
                                        <button className='btn' name="removableItem" >{item.title}</button>
                                        <span class="filter-count" name="removableItem">{item.count}</span>
                                    </li>
                                    <ReactTooltip place="left" />
                                </div>
                            ))}
                        </div>
                        {this.getSelectedFilterLists('name', null).length > 0 ? (
                            <>
                                <div>
                                    <li style={{ borderWidth: '0'  , backgroundColor: '#4288f5'  }}>
                                        <span style={{  
    'border': '1px solid #4288f5' ,
    'background-color': '#fff0 !important' ,
    'width': '100%' ,
    'border-radius': '5px' ,
    'color': '#fff'  ,
    'font-weight': '500',
     }}>{this.getSelectedFilterLists('name', null).length} Selected</span>
                                    </li>
                                </div>
                                <div className='filter-cell' name="removableItem" style={{ maxHeight: '125px',  marginBottom: '10px' }}>
                                    {this.getSelectedFilterLists('name', null).map((item, index) => (
                                        <div key={index} name="removableItem">
                                            <li data-tip={`${item.title}`} name="removableItem" onClick={() => this.removeSelectedOption(item.option, item.columnId)}>
                                                <button className='btn' name="removableItem" style={{ width: '100%', textAlign: 'inherit' }}  >{item.title}</button>
                                            </li>
                                            {/* <ReactTooltip place="left" /> */}
                                        </div>
                                    ))}
                                </div>
                            </>

                        ) : ""}
                    </ul>
                    {this.state.columns.map((column, index) => (
                        <ul className="filter-list" key={index}>
                            {column.key !== 'file' ? (
                                <>
                                    <h6 style={{ color: '#21386C' }}>{column.title}</h6>
                                    <div className='filter-cell' style={{  'paddingRight' : '8px' }}>
                                        {this.getColumnFilterLists(columns[column._id]).map((item, index) => (
                                            item.isReminder?
                                                (
                                                <div key={index} name="removableItem">
                                                <li data-tip={`${item.title.split('user').shift().replace('{', " ").replace('"', " ").replace('":"', ": ").replace('","', ", ").replace('":"', ": ").replace('","', ", ")}`} name="removableItem" onClick={() => this.filter(item.title, item.option, column._id)}>
                                                    <button className='btn text-capitalize' name="removableItem" >{item.title.split('user').shift().replace('{', " ").replace('"', " ").replace('":"', ": ").replace('","', ", ").replace('":"', ": ")}</button>
                                                    <span class="filter-count" name="removableItem">{item.count}</span>
                                                </li>
                                                <ReactTooltip place="left" />
                                            </div>
                                            
                                            )
                                                
                                                :
                                           ( <div key={index} name="removableItem">
                                                <li data-tip={`${item.title}`} name="removableItem" onClick={() => this.filter(item.title, item.option, column._id)}>
                                                    <button className='btn' name="removableItem" >{item.title}</button>
                                                    <span class="filter-count" name="removableItem">{item.count}</span>
                                                </li>
                                                <ReactTooltip place="left" />
                                            </div>)
                                            
                                        )
                                        )}
                                    </div>
                                    {this.getSelectedFilterLists('item', column._id).length > 0 ? (
                                        <>
                                            <div>
                                                <li style={{ borderWidth: '0' , backgroundColor: '#4288f5' }}>
                                                    <span style={{  
    'border': '1px solid #4288f5' ,
    'background-color': '#fff0 !important' ,
    'width': '100%' ,
    'border-radius': '5px' ,
    'color': '#fff' ,
    'font-weight': '500',
      }}>{this.getSelectedFilterLists('item', column._id).length} Selected</span>
                                                </li>
                                            </div>
                                            <div className='filter-cell' name="removableItem" style={{ maxHeight: '125px',  marginBottom: '10px' }}>
                                                {this.getSelectedFilterLists('item', column._id).map((item, index) => (
                                                    <div key={index} name="removableItem">
                                                        <li data-tip={`${item.title.split('user').shift().replace('{', " ").replace('"', " ").replace('":"', ": ").replace('","', ", ").replace('":"', ": ").replace('","', ", ")}`} name="removableItem" onClick={() => this.removeSelectedOption(item.option, item.columnId)}>
                                                            <button className='btn' name="removableItem" style={{ width: '100%', textAlign: 'inherit' }}  >{item.title.split('user').shift().replace('{', " ").replace('"', " ").replace('":"', ": ").replace('","', ", ")}</button>
                                                        </li>
                                                        {/* <ReactTooltip place="left" /> */}
                                                    </div>
                                                ))}
                                            </div>
                                        </>

                                    ) : ""}
                                </>
                            ) : ''}

                            <ReactTooltip place="left" />
                        </ul>

                    ))}

                    {/* <ReactTooltip place="left" /> */}
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { searchFilterBoardData }

export default connect(mapStateToProps, mapDispatchToProps)(FilterDropdownComponent)