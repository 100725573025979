import React, { useState, useEffect } from 'react';
import ContentEditable from 'react-contenteditable';

import { connect } from 'react-redux';
import { changeGroupListRowTitle } from '../../../actions';
import { getMyPermission } from '../../../util/permission';
//import GroupRowEvent from './GroupRowEvent';
import ReactTooltip from 'react-tooltip';

class GroupListRowTitle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rowTitle: this.props.title,
            actionPermission: 'view'
        }
        this.timeout = 0;

        this.rowContentEditableRef = React.createRef();
    }


   
    onChangeListTitle = (event) => {
        this.setState({ rowTitle: event.target.value })
    }

    componentDidMount() {
        //alert(this.props.search.boardID)
        // if (this.props.boardData.boardID) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let permission = getMyPermission(boardPermission, members);
        //     if (permission === 'super' || permission === 'high') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentDidUpdate(prevProps, prevStates) {
        // if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let permission = getMyPermission(boardPermission, members);
        //     if (permission === 'super' || permission === 'high') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
     
      
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ rowTitle: nextProps.title })
    }

    onKeyPress = (event) => {
        const { title, groupInd, rowInd, id } = this.props;
            this.props.changeGroupListRowTitle({
                groupInd: groupInd,
                rowInd: rowInd,
                rowTitle: event.target.innerText,
                groupItemID: id,
                boardId: this.props.search.boardID
            })
        // if (this.timeout) clearTimeout(this.timeout);
    setTimeout(() => {
            ReactTooltip.rebuild();
         }, 500);
        
    }

    render() {
      
        // console.log("group list title props===>", this.props)
        return (
            
            <div className="d-inline-flex"
            
            >
                {/* <GroupRowEvent groupInd={this.props.groupInd} groupId={this.props.groupId} rowInd={this.props.rowInd} id={this.props.id} /> */}
                {/* {this.props.groupItemProvided !== null ? (
                    <div {...this.props.groupItemProvided.dragHandleProps} style={{ padding: '7px 1px', color: '#c4c4c4' , 'marginLeft' : '28px' , 'marginTop' : '3px' }}>
                        <i className='bx bx-grid-vertical' style={{ 'color' : '#707070' }}></i>
                    </div>
                ) : ''} */}

                <ContentEditable
                    className="holder__title board-name"
                    innerRef={this.rowContentEditableRef}
                    data-tip={this.state.rowTitle}
                    html={this.state.rowTitle}
                    
                  //  title={this.state.rowTitle}
                    disabled={this.state.actionPermission === 'view' ? true : false}
                  //  onChange={this.onChangeListTitle}
                    onBlur={event => this.onKeyPress(event)}
                    style={{ fontSize: '14px', padding: '3px 10px', width: '300px' , 'color' : '#f70808 !important' }}
                    tagName='div'
                />
            </div>
            
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeGroupListRowTitle };

export default connect(mapStateToProps, mapDispatchToProps)(GroupListRowTitle);