export const getAllSavedSearch = "savedSearch/getAllSavedSearch";
export const getSavedSearchById = "savedSearch/getSavedSearchById"
export const getSavedSearchByUserId = "savedSearch/getSavedSearchByUserId"
export const saveSavedSearch = "savedSearch/saveSavedSearch"
export const updateSavedSearch = "savedSearch/updateSavedSearch"
export const deleteSavedSearch = "savedSearch/deleteSavedSearch"
export const SavedSearch_Search = "savedSearch/SavedSearch_Search"
export const getMAxSavedSearch = "savedSearch/getMaxSavedSearch"

export default {
    getAllSavedSearch: getAllSavedSearch,
    getSavedSearchById: getSavedSearchById,
    getSavedSearchByUserId:getSavedSearchByUserId,
    saveSavedSearch: saveSavedSearch,
    updateSavedSearch: updateSavedSearch,
    deleteSavedSearch: deleteSavedSearch,
    SavedSearch_Search: SavedSearch_Search,
    getMAxSavedSearch: getMAxSavedSearch
}