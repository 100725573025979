import React, { Component } from "react";
import PropTypes from "prop-types";
import Autosuggest from "react-autosuggest";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import "react-tabs/style/react-tabs.css";
import http from "../../services/http-service";
import * as savedSearch from "../saved-search/constant";
import * as recentSearch from "../recent-search/constant";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import "../src/css/high-light.css";
import "../card.css";
import "../src/css/style.css";
import "react-tabs/style/react-tabs.css";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import "react-toggle/style.css"; // for ES6 modules
import Toggle from "react-toggle"; // http://aaronshaf.github.io/react-toggle/   //https://github.com/aaronshaf/react-toggle
import Checkbox from "rc-checkbox"; //https://github.com/react-component/checkbox
import * as File from "./constant";
import Moment from "moment";
import User from "../../auth/auth-services/user-service";

const suggestions = [];
let _currentUser = "";

function renderInput(inputProps) {
  const { classes, ref, ...other } = inputProps;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: ref,
        classes: {
          input: classes.input,
        },
        ...other,
      }}
    />
  );
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 300 }}>
              {part.text}
            </span>
          ) : (
            <strong
              key={String(index)}
              style={{ fontWeight: "bold", color: "purple", fontSize: "18" }}
            >
              {part.text}
            </strong>
          );
        })}
      </div>
    </MenuItem>
  );
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options;
  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
}

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

function getSuggestions(value) {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;
  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 &&
          suggestion.label.toLowerCase().slice(0, inputLength) === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    position: "relative",
    height: 250,
  },
  suggestionsContainerOpen: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  suggestion: {
    display: "block",
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
  },
});

class FileSearch extends Component {
  state = {
    value: "",
    suggestions: [],
    isSavedSearchChecked: false,
    isHorezintalDatePickerShow: false,
    selectedDate: "",
    tabIndex: 0,
    inputHighLightKeyWords: [],
    content: "New Group",
    groups: [],
    groupItemsData: [],
    error: false,
    userData: [],
    hintData: [],
    text: "",
    allPeopleData: [],
    allfiles: [],
    allFiltred: [],
    imgFiles: [],
    wordFiles: [],
    powerPointFiles: [],
    excelFiles: [],
    pdfFiles: [],
    zipFiles: [],
    musicFiles: [],
    vedioFiles: [],
    otherFiles: [],
    imgExt: [
      "jpg",
      "png",
      "gif",
      "webp",
      "tiff",
      "psd",
      "raw",
      "bmp",
      "heif",
      "indd",
      "jpeg",
    ],
    wordExt: ["docx", "doc", "txt"],
    powerPointExt: ["ppt", "pptx"],
    excelExt: ["xlxs", "xls"],
    pdfExt: ["pdf"],
    zipExt: ["rar", "zip", "iso", "tar"],
    musicExt: ["mp3", "mp4", "wav", "wma", "acc"],
    vedioExt: ["flv", "avi", "Webm", "mkv"],
    otherExt: ["*"],
    isImg: true,
    isWord: true,
    isPowerPoint: true,
    isExcel: true,
    isPdf: true,
    isZip: true,
    isMusic: true,
    isVedio: true,
    isOther: true,
    cheeseIsReady: false,
    totalRecords: 0,
  };

  async componentDidMount() {
    this._initFilesSearch();
    try {
      if (this.props.searchNameDataMyFiles === "QuickSearchMyFiles") {
        _currentUser = User.getUserByToken("token");
        let user = {
          user: _currentUser.id,
        };
        await http.post(File.GET_ALL_FILES_BY_USER, user).then((fileData) => {
          this.setState({
            allFiltred: fileData.data.response,
          });
          this.setState({
            totalRecords: this.state.allFiltred.length,
          });
          localStorage.removeItem("totalFileRecords");
          localStorage.setItem("totalFileRecords", this.state.totalRecords);
        });
      } else if (
        this.props.quickSearchSavedObj.quickSearchSavedType &&
        this.props.quickSearchSavedObj.quickSearchSavedType === "files" &&
        this.props.quickSearchSavedObj.searchNameDataSavedSearch
      ) {
        const fileNameDataByTime = {
          fileName: this.props.quickSearchSavedObj.searchNameDataSavedSearch,
          isRevelanceByTime: true,
        };
        const fileNameDatawithoutTime = {
          fileName: this.props.quickSearchSavedObj.searchNameDataSavedSearch,
          isRevelanceByTime: false,
        };
        await http
          .post(
            File.GET_FILE_BY_NAME,
            this.state.cheeseIsReady
              ? fileNameDataByTime
              : fileNameDatawithoutTime
          )
          .then((fileByName) => {
            this.setState({
              allFiltred: fileByName.data.file,
            });
            this.setState({
              totalRecords: this.state.allFiltred.length,
            });
            localStorage.setItem("totalFileRecords", 0);
            localStorage.setItem("totalFileRecords", this.state.totalRecords);
          });
      }
    } catch (ex) {}
  }

  errorHandler = ({ error }) => this.setState({ error });

  onChangeCallback = (selectedInterval) => this.setState({ selectedInterval });

  getSuggestionValue = (suggestion) => {
    this.setState({
      inputHighLightKeyWords: [
        ...this.state.inputHighLightKeyWords,
        suggestion.name,
      ],
    });
    return suggestion.name;
  };

  onChange = (event, { newValue, method }) => {
    this.setState({
      value: newValue,
    });
  };

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      inputHighLightKeyWords: [],
    });
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
    if (this.state.suggestions === []) {
      this.setState({
        inputHighLightKeyWords: [],
      });
    }
  };

  _initFilesSearch = async () => {
    await http.get(File.GET_ALL_FILES).then((fileData) => {
      let files = fileData.data.response;
      files.map((file) => {
        suggestions.push({ label: file.fileName });
        suggestions.push({ label: file.fileType });
      });
    });
  };

  searchResult = async (searchValue) => {
    //serach by file name
    const fileNameDataByTime = {
      fileName: searchValue,
      isRevelanceByTime: true,
    };
    const fileNameDatawithoutTime = {
      fileName: searchValue,
      isRevelanceByTime: false,
    };
    await http
      .post(
        File.GET_FILE_BY_NAME,
        this.state.cheeseIsReady ? fileNameDataByTime : fileNameDatawithoutTime
      )
      .then((fileByName) => {
        this.setState({
          allFiltred: fileByName.data.file,
        });
        this.setState({
          totalRecords: this.state.allFiltred.length,
        });
        localStorage.setItem("totalFileRecords", 0);
        localStorage.setItem("totalFileRecords", this.state.totalRecords);
      });

    this.saveRecentSavedSearch();
    if (this.state.allFiltred.length !== 0) return;
    //search by last name
    const fileTypeDataByTime = {
      fileTypes: searchValue,
      isRevelanceByTime: true,
    };
    const fileTypeDatawithoutTime = {
      fileTypes: searchValue,
      isRevelanceByTime: false,
    };
    await http
      .post(
        File.GET_FILES_BY_TYPE,
        this.state.cheeseIsReady ? fileTypeDataByTime : fileTypeDatawithoutTime
      )
      .then((fileByType) => {
        this.setState({
          allFiltred: fileByType.data.file,
        });
        this.setState({
          totalRecords: this.state.allFiltred.length,
        });
        localStorage.setItem("totalFileRecords", 0);
        localStorage.setItem("totalFileRecords", this.state.totalRecords);
      });
    this.saveRecentSavedSearch();
    if (this.state.allFiltred.length !== 0) return;
  };

  saveRecentSavedSearch = async () => {
    const savedsearchData = {
      searchName: this.state.value,
      searchUser: "5fe43e2d91056e9f478549c7",
    };
    if (this.state.isSavedSearchChecked === true) {
      http
        .post(savedSearch.SAVE_SAVED_SEARCH, savedsearchData)
        .then((result) => {
          console.log(result, "saved search is saved successfully");
        });
    }
    const recentSearchData = {
      searchName: this.state.value,
      searchUser: "5fe43e2d91056e9f478549c7",
    };
    http
      .post(recentSearch.SAVE_RECENT_SEARCH, recentSearchData)
      .then((result) => {
        console.log("saved search is saved successfully", result);
      });
  };

  onSavedSerachChange = () => {
    this.setState({ isSavedSearchChecked: !this.state.isSavedSearchChecked });
  };

  filterByDate = () => {
    this.setState({
      isHorezintalDatePickerShow: !this.state.isHorezintalDatePickerShow,
    });
  };

  onSelectedDay = (d) => {
    this.setState({ selectedDate: d });
  };

  getFileByTypes = (fileExt) => {
    const fileTypeDataByTime = {
      fileTypes: fileExt,
      isRevelanceByTime: true,
    };
    const fileTypeDatawithoutTime = {
      fileTypes: fileExt,
      isRevelanceByTime: false,
    };
    http
      .post(
        File.GET_FILES_BY_TYPE,
        this.state.cheeseIsReady ? fileTypeDataByTime : fileTypeDatawithoutTime
      )
      .then((result) => {
        let files = result.data.file;
        this.setState({
          allFiltred: files,
        });
        this.setState({
          totalRecords: this.state.allFiltred.length,
        });
        localStorage.setItem("totalFileRecords", 0);
        localStorage.setItem("totalFileRecords", this.state.totalRecords);
      });
  };

  onImgChange = () => {
    this.setState({ isImg: !this.state.isImg });
    if (this.state.isImg === true) {
      this.getFileByTypes(this.state.imgExt);
    }
  };

  onWordhange = () => {
    this.setState({ isWord: !this.state.isWord });
    if (this.state.isWord === true) {
      this.getFileByTypes(this.state.wordExt);
    }
  };

  onPowerPointChange = () => {
    this.setState({ isPowerPoint: !this.state.isPowerPoint });
    if (this.state.isPowerPoint === true)
      this.getFileByTypes(this.state.powerPointExt);
  };
  onExcelChange = () => {
    this.setState({ isExcel: !this.state.isExcel });
    if (this.state.isExcel === true) this.getFileByTypes(this.state.excelExt);
  };
  onPdfChange = () => {
    this.setState({ isPdf: !this.state.isPdf });
    if (this.state.isPdf === true) this.getFileByTypes(this.state.pdfExt);
  };
  onZipChange = () => {
    this.setState({ isZip: !this.state.isZip });
    if (this.state.isZip === true) this.getFileByTypes(this.state.zipExt);
  };
  onMusicChange = () => {
    this.setState({ isMusic: !this.state.isMusic });
    if (this.state.isMusic === true) this.getFileByTypes(this.state.musicExt);
  };
  onVedioChange = () => {
    this.setState({ isVedio: !this.state.isVedio });
    if (this.state.isVedio === true) this.getFileByTypes(this.state.vedioExt);
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = (event, { newValue }) => {
    this.setState({
      value: newValue,
    });
  };

  handleCheeseChange = () => {
    this.setState({
      cheeseIsReady: !this.state.cheeseIsReady,
    });
  };

  render() {
    const { classes } = this.props;
    const { allFiltred, isSavedSearchChecked, cheeseIsReady } = this.state;

    return (
      <>
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderInputComponent={renderInput}
          suggestions={this.state.suggestions}
          onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestionsContainer}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            classes,
            placeholder: "Type Your Text Search Here ...",
            value: this.state.value,
            onChange: this.handleChange,
          }}
        />

        <div className="row">
          <button
            className="btn btn-primary col-md-2"
            style={{ marginRight: "5px", height: "50px" }}
            onClick={() => this.searchResult(this.state.value)}
          >
            Search
          </button>
          <div style={{ marginTop: "10px" }}>
            <Checkbox
              size="small"
              isChecked={isSavedSearchChecked}
              onChange={this.onSavedSerachChange}
              color="#fc14bb"
            />
            <label style={{ marginTop: "3px" }}>Save search</label>
          </div>
        </div>

        <div
          className="col-md-12"
          style={{ marginTop: "100px", clear: "both" }}
        ></div>

        <div className="row">
          <div className="col-md-8" style={{ float: "left" }}>
            <table className="table table-striped">
              <thead>
                <tr>
                  <th scope="col">File Name</th>
                  <th scope="col">File Type</th>
                  {/* <th scope="col">Created By</th>
                  <th scope="col">Modified By</th> */}
                  <th scope="col">Created At</th>
                </tr>
              </thead>
              <tbody>
                {allFiltred.map((file, index) => (
                  <tr key={index}>
                    <th>{file.fileName}</th>
                    <th>{file.fileType}</th>
                    {/* <th>{file.CreatedBy.email}</th>
                    <th>{file.ModifiedBy.email}</th> */}
                    <th>
                      {Moment(file.createdAt).format("YYYY-MM-DD hh:mm:ss")}
                    </th>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-md-4" style={{ float: "right" }}>
            <div>
              <span
                style={{ fontSize: "20px", color: "black", fontWeight: "bold" }}
              >
                {this.state.totalRecords}
              </span>
              <label
                style={{ fontSize: "20px", color: "black", fontWeight: "bold" }}
              >
                Results
              </label>
            </div>
            <div>
              <label style={{ marginRight: "10px" }}>Sort By: </label>
              <label>Relevance:</label>
              <Toggle
                id="cheese-status"
                defaultChecked={cheeseIsReady}
                onChange={this.handleCheeseChange}
              />
              <label htmlFor="cheese-status">Time</label>
            </div>
            <ul style={{ listStyle: "none" }}>
              <li>
                <Checkbox onChange={this.onImgChange} />
                image
              </li>
              <li>
                <Checkbox onChange={this.onWordhange} />
                word
              </li>
              <li>
                <Checkbox onChange={this.onPowerPointChange} />
                powerpoint
              </li>
              <li>
                <Checkbox onChange={this.onExcelChange} />
                excel
              </li>
              <li>
                <Checkbox onChange={this.onPdfChange} />
                pdf
              </li>
              <li>
                <Checkbox onChange={this.onZipChange} />
                zip
              </li>
              <li>
                <Checkbox onChange={this.onMusicChange} />
                music
              </li>
              <li>
                <Checkbox onChange={this.onVedioChange} />
                vedio
              </li>
              {/* <li>
              <Checkbox onChange={this.onOtherChange} />
              other
            </li> */}
            </ul>
          </div>
        </div>
      </>
    );
  }
}

FileSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FileSearch);
