export const getAllWorkSpaces = "workspace/getAllWorkSpaces";
export const getAllWorkSpacesNested = "workspace/getAllWorkSpacesNested";
export const getAllSubWorkSpacesNested = "workspace/getAllSubWorkSpacesNested";
export const createWorkspace = "workspace/createWorkspace";
export const getWorkSpaceById = "workspace/getWorkSpaceById";
export const renameWorkspace = "workspace/renameWorkspace";
export const getWorkspaceBoardById = "workspace/getWorkspaceBoardById";

export const createSubworkspace = "workspace/createSubworkspace";
export const renameSubWorkspace = "workspace/renameSubWorkspace";
export const removeSubworkspace = "workspace/removeSubworkspace";
export const createSubInsideSubworkspace = "workspace/subWorkspaceCreateNewSub";
export const removeSubworkspaceFromSub = "workspace/removeSubworkspaceFromSub";
export const createBoard = "workspace/createBoard";
export const createBoardToWorkspace = "workspace/createBoardToWorkspace";
export const changeBoardTitle = "workspace/changeBoardTitle";
export const addWorkspaceBoardGroup = "workspace/addWorkspaceBoardGroup";
export const updateGroupTitle = "workspace/updateGroupTitle"
export const addWorkspaceBoardGroupItem = "workspace/addWorkspaceBoardGroupItem";
export const updateGroupItemTitle = "workspace/updateGroupItemTitle"
export const getAllWorkspaceWithoutPopulate = "workspace/getAllWorkspaceWithoutPopulate"
export const getAllWorkspaceAndSubworkspaces = "workspace/getAllWorkspaceAndSubworkspaces"
export const deleteWorkSpace = "workspace/deleteWorkSpace";
export const getAllMainWorkSpaces = "workspace/getAllMainWorkSpaces";
export const getAllSubWorkSpces = "workspace/getAllSubWorkSpces";

export const getLatest_10_UpdatedWorkSpaces = "workspace/getLatest_10_UpdatedWorkSpaces";
export const get_WorkSpaceTasksPerUser = "workspace/getWorkSpaceTasks";
export const getAllWorkSpaceTasks = "workspace/getAllWorkSpaceTasks";
export const getAllWorkSpaceByUser = "workspace/getAllWorkSpaceByUser";
export const getAllWorkspaceBoards = "workspace/getAllWorkspaceBoards";
export const getAllSubWorkSpaces = "workspace/getAllSubWorkSpaces";
export const getAllWorkspaceBoard = "workspace/getAllWorkspaceBoard";

export default {
  getAllWorkSpaces: getAllWorkSpaces,
  getAllWorkSpacesNested: getAllWorkSpacesNested,
  getAllSubWorkSpacesNested: getAllSubWorkSpacesNested,
  createWorkspace: createWorkspace,
  getWorkSpaceById: getWorkSpaceById,
  renameWorkspace: renameWorkspace,
  getWorkspaceBoardById: getWorkspaceBoardById,
  createSubworkspace: createSubworkspace,
  renameSubWorkspace: renameSubWorkspace,
  removeSubworkspace: removeSubworkspace,
  removeSubworkspaceFromSub: removeSubworkspaceFromSub,
  createSubInsideSubworkspace: createSubInsideSubworkspace,

  createBoard: createBoard,
  createBoardToWorkspace: createBoardToWorkspace,
  changeBoardTitle: changeBoardTitle,
  addWorkspaceBoardGroup: addWorkspaceBoardGroup,
  updateGroupTitle: updateGroupTitle,
  addWorkspaceBoardGroupItem: addWorkspaceBoardGroupItem,
  updateGroupItemTitle: updateGroupItemTitle,
  getAllWorkspaceWithoutPopulate: getAllWorkspaceWithoutPopulate,
  getAllWorkspaceAndSubworkspaces: getAllWorkspaceAndSubworkspaces,
  deleteWorkSpace: deleteWorkSpace,
  getAllMainWorkSpaces: getAllMainWorkSpaces,
  getAllSubWorkSpces: getAllSubWorkSpces,

  get_WorkSpaceTasksPerUser: get_WorkSpaceTasksPerUser,
  getLatest_10_UpdatedWorkSpaces: getLatest_10_UpdatedWorkSpaces,
  getAllWorkSpaceTasks: getAllWorkSpaceTasks,
  getAllWorkSpaceByUser: getAllWorkSpaceByUser,
  getAllWorkspaceBoards: getAllWorkspaceBoards,
  getAllSubWorkSpaces: getAllSubWorkSpaces,
  getAllWorkspaceBoard: getAllWorkspaceBoard,



}

