import React, { Component } from 'react'
import { connect } from 'react-redux';
import {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch, getRecentSearchs,
    onClickTag, onSavedSearchDelete, getHotTags
} from '../../../actions/GlobalSearch'

class RecentSearch extends Component {
    constructor(props) {
        super(props)
        this.onTagClick = this.onTagClick.bind(this)
    }
    onTagClick = (i, value) => {
        this.props.onClickTag(i, value)
        this.props.onChangeSearch(this.props.search.savedsearch, value,
            this.props.search.userdetails._id, 1,this.props.search.files)
            this.props.fnc(value)
    }
    componentDidMount() {
        this.props.getRecentSearchs(this.props.search.userdetails._id)
    }
    render() {
        return (
            <div class="card-body pt-1" style={{ textAlign: 'left' }}>
                {this.props.search.recentsearch.map((item,index) => (
                    <p key={index}>
                        <i class="bx bx-search" style={{ fontSize: '1rem', marginRight: '5px' }}></i>
                        <a onClick={() => this.onTagClick(1, item.searchName)}>{item.searchName}</a>
                    </p>
                ))
                }
            </div>
        )
    }
}
const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = {
    onChangeSearch, onTabChange, onFocusSearch,
    onSaveSearch, getSavedSearchs, onBlurSearch,
    getRecentSearchs, onClickTag,
    onSavedSearchDelete, getHotTags
};

export default connect(mapStateToProps, mapDispatchToProps)(RecentSearch);