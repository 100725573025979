import evaluatex from "evaluatex";
import { convertDateToString } from ".";
import {
    ABS, ADD_DAYS, AND, AVERAGE, CONCATENATE,
    COUNT, DATE, DAY, DAYS, DIVIDE, EXACT, FORMAT_DATE,
    HOUR, HOURS_DIFF,
    IF, LEFT, LEN, LOG, LOWER, MAX, MIN, MINUS, MINUTE, MOD,
    MONTH, MULTIPLY, OR, POWER, REPLACE, REPT, RIGHT,
    ROUND, ROUNDDOWN, ROUNDUP, SEARCH, SECOND, SQRT, SUBSTITUTE,
    SUBTRACT_DAYS,
    SUM, SWITCH, TODAY, TRIM, UPPER, WEEKNUM, WORKDAYS,
    XOR, YEAR
} from "./formula";

export const calculator = (strEquation) => {
    // console.log("strEquation", strEquation)
    if (strEquation === '') {
        return '';
    }

    let calculation = evaluatex(strEquation, {
        'IF': (condition, valueTrue, ValueFalse) => {            
            return IF(condition, valueTrue, ValueFalse)
        },
        'SWITCH': (property, ...cases) => {
            return SWITCH(property, cases);
        },
        'AND': (...conditions) => {
            return AND(conditions);
        },
        'OR': (...conditions) => {
            return OR(conditions);
        },
        'XOR': (...conditions) => {
            return XOR(conditions);
        },
        'EXACT': (a, b) => {
            return EXACT(a, b);
        },
        'AVERAGE': (...items) => {
            return AVERAGE(items);
        },
        'COUNT': (...items) => {
            return COUNT(items);
        },
        'SUM': (...items) => {
            return SUM(items);
        },
        'MOD': (under, index) => {
            return MOD(under, index);
        },
        'ROUND': (decimalMumber, position) => {
            return ROUND(decimalMumber, position);
        },
        'ROUNDUP': (decimalMumber, position) => {
            return ROUNDUP(decimalMumber, position);
        },
        'ROUNDDOWN': (decimalMumber, position) => {
            return ROUNDDOWN(decimalMumber, position);
        },
        'LOG': (bottomNumber, indexNumber) => {
            return LOG(bottomNumber, indexNumber);
        },
        'MIN': (...items) => {
            return MIN(items);
        },
        'MAX': (...items) => {
            return MAX(items);
        },
        'MINUS': (a, b) => {
            return MINUS(a, b);
        },
        'MULTIPLY': (a, b) => {
            return MULTIPLY(a, b)
        },
        'DIVIDE': (a, b) => {
            return DIVIDE(a, b);
        },
        'SQRT': (number) => {
            return SQRT(number);
        },
        'POWER': (a, b) => {
            return POWER(a, b);
        },
        'ABS': (number) => {
            return ABS(number);
        },
        'CONCATENATE': (...strings) => {
            return CONCATENATE(strings);
        },
        'REPLACE': (sourceString, start, end, replaceString) => {
            return REPLACE(sourceString, start, end, replaceString);
        },
        'SUBSTITUTE': (sourceString, oldString, newString) => {
            return SUBSTITUTE(sourceString, oldString, newString);
        },
        'SEARCH': (searchText, sourceText, start) => {
            return SEARCH(searchText, sourceText, start);
        },
        'LEFT': (sourceText, length) => {
            return LEFT(sourceText, length);
        },
        'RIGHT': (sourceText, length) => {
            return RIGHT(sourceText, length);
        },
        'LEN': (sourceText) => {
            return LEN(sourceText);
        },
        'REPT': (sourceText, repeatNumber) => {
            return REPT(sourceText, repeatNumber);
        },
        'TRIM': (sourceText) => {
            return TRIM(sourceText);
        },
        'UPPER': (sourceText) => {
            return UPPER(sourceText);
        },
        'LOWER': (sourceText) => {
            return LOWER(sourceText);
        },
        'DATE': (year, month, day) => {
            return DATE(year, month, day);
        },
        'DAYS': (endDate, startDate) => {
            return DAYS(endDate, startDate);
        },
        'WORKDAYS': (end, start) => {
            return WORKDAYS(end, start);
        },
        'TODAY': () => {
            return convertDateToString(new Date());
        },
        'FORMAT_DATE': (stringDate, format) => {
            return FORMAT_DATE(stringDate, format);
        },
        'YEAR': (stringDate) => {
            return YEAR(stringDate)
        },
        'MONTH': (strDate) => {
            return MONTH(strDate);
        },
        'WEEKNUM': (strDate) => {
            return WEEKNUM(strDate);
        },
        'DAY': (strDate) => {
            return DAY(strDate);
        },
        'HOUR': (strDate) => {
            return HOUR(strDate);
        },
        'MINUTE': (strDate) => {
            return MINUTE(strDate);
        },
        'SECOND': (strDateTime) => {
            return SECOND(strDateTime)
        },
        'ADD_DAYS': (strDate, addDays) => {
            return ADD_DAYS(strDate, addDays);
        },
        'SUBTRACT_DAYS': (strDate, subtractDays) => {
            return SUBTRACT_DAYS(strDate, subtractDays);
        },
        'HOURS_DIFF': (endTime, startTime) => {
            return HOURS_DIFF(endTime, startTime);
        },
        'TRUE': true,
        'FALSE': false
    });

    return calculation();
}