import React from 'react';

function ShowAttendanceMgmWidget(props) {
   const { ShowAttendanceManagementWidgetValue } = props;
   return (
      <>
         {
            ShowAttendanceManagementWidgetValue &&
            <div className="border fourth-first-section" style={{ padding: "5px", minHeight: "300px" }}>
               <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-12 mt-2" id="top-section">
                     <div style={{ float: 'left', marginLeft: '15px' }}>
                        <h5 className="left-head">Attendance Management</h5>
                     </div>
                     <div style={{ float: 'right', marginTop: '-2px' }}>
                        <a title="Reply" data-toggle="modal" data-target="#modal-City">
                           <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                        </a>
                     </div>
                  </div>
               </div>
               <div className="row mt-2">
                  <div className="col-md-5">
                     <span style={{ fontSize: '14px' }}>Punch In</span>
                     <div style={{ backgroundColor: '#EAEAEA', height: '100px', width: '110px', borderRadius: '15px' }}>
                     </div>
                  </div>
                  <div className="col-md-7" style={{ lineHeight: '12px' }}>
                     <span style={{ fontSize: '12.5px', paddingLeft: '5px' }}>08:05 A.M , 10 Jun 2020</span><br /><br />
                     <span style={{ fontSize: '12px', fontWeight: 100, marginTop: '10px', paddingLeft: '5px', textAlign: 'justify' }}>
                        Al Maeraj Cafeteria<br />
                      162 , Khalid bin walid road,
                      Dubai
                    </span>
                     <div className="d-flex ml-1 mt-1">
                        <div className="round" style={{ backgroundColor: '#336', width: '40px', height: '40px' }}>
                        </div>
                        <div className="round" style={{ backgroundColor: '#336', width: '40px', height: '40px', marginLeft: '15px' }}>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         }
      </>

   );
}

export default ShowAttendanceMgmWidget;