/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import http from '../../services/http-service';
import StaticHTML from '../common/static-html.jsx';
import CompanyTable from './companyTable';
import Modal from './modal';
import Joi from 'joi-browser';
import * as CompanyConstant from './constant';
import * as CountryConstant from '../country/constant.jsx';
import * as StateConstant from '../state/constant';
import * as CityConstant from '../city/constant.jsx';
import * as BusinessCategoryConstant from '../business-category/constant.jsx';
import Form from '../common/Form.jsx';
import MainMenu from '../../../shared/components/main-menu';
import NavBar from '../../../shared/components/navbar/navbar';
import Notification from '../../notification/notification';

//Options Data Lists
var options_businessCategory = [];
var options_country = [];
var options_state = [];
var options_city = [];
var options_gender = [];

class Company extends Form {
  constructor(props) {
    super(props)
    this.state = {
      Id: "",
      selectedOption_businessCategoty: null,
      selectedOption_country: null,
      selectedOption_state: null,
      selectedOption_city: null,
      selectedOption_gender: null,
      CreatedBy: "5fb568133676703aa0feea70",
      ModifiedBy: "5fb568133676703aa0feea70",
      IsDeleted: false,
      IsActive: true,
      pageSize: 5,
      currentPage: 1,
      CompanyData: [],
      TableData: [],

      //validation State
      companyToValidate: {
        Name: "", CompanyCode: "", CompanyEmail: "", CompanyPhone: "", BusinessCategoryId: "", CompanyTRN: "",
        StreetAdress: "", Location: "", Area: "", Building: "", CountryId: "", StateId: "", CityId: "", ZipCode: "", FirstName: "",
        LastName: "", PersonalEmail: "", PersonalPhoneNumber: "", Designation: "", Gender: "",
      },

      errorcompanyName: "", errorCompanyCode: "", errorCompanyEmail: "", errorCompanyPhone: "", errorBusinessCategoryId: "", errorCompanyTRN: "",
      errorStreetAdress: "", errorLocation: "", errorArea: "", errorBuilding: "", errorCountryId: "", errorStateId: "", errorCityId: "",
      errorZipCode: "", errorFirstName: "", errorLastName: "", errorPersonalEmail: "", errorPersonalPhoneNumber: "", errorDesignation: "",
      errorGender: "",
      isUpdate: false,
    }
  }
  //Define the Schema
  schema = {
    //Group_1
    Name: Joi.string().required().label('Name'),
    CompanyCode: Joi.string().required().label('Company Code'),
    CompanyEmail: Joi.string().email().required().label('Company Email'),
    CompanyPhone: Joi.string().required().label('Company Phone'),
    BusinessCategoryId: Joi.string().required().label('Business Category'),
    CompanyTRN: Joi.string().required().label('Company TRN'),
    //Group_2
    StreetAdress: Joi.string().required().label('Street Adress'),
    Location: Joi.string().required().label('Location'),
    Area: Joi.string().required().label('Area'),
    Building: Joi.string().required().label('Building'),
    CountryId: Joi.string().required().label('Country'),
    StateId: Joi.string().required().label('State'),
    CityId: Joi.string().required().label('City'),
    ZipCode: Joi.string().required().label('Zip Code'),
    //Group_3
    FirstName: Joi.string().required().label('First Name'),
    LastName: Joi.string().required().label('Last Name'),
    PersonalEmail: Joi.string().email().required().label('Personal Email'),
    PersonalPhoneNumber: Joi.string().required().label('Personal PhoneNumber'),
    Designation: Joi.string().required().label('Designation'),
    Gender: Joi.string().required().label('Gender'),
  }

  async componentDidMount() {
    this.loadFirstData();
  }

  loadFirstData = async () => {
    //Get Business Category Data
    await http.get(BusinessCategoryConstant.BUSNIESS_CATEGORY_GetALL_URL)
      .then((data) => {
        data.data.response.map((data) => {
          options_businessCategory.push(
            { value: data._id, label: data.Name },
          )
        })
      })
    //Get Country Data
    await http.get(CountryConstant.Country_GetALL_URL)
      .then((data) => {
        data.data.response.map((data) => {
          options_country.push(
            { value: data._id, label: data.Name },
          )
        })
      })
    //Gender
    options_gender.push(
      { value: 'Male', label: 'Male' },
      { value: 'Female', label: 'Female' },
    )
    await http.get(CompanyConstant.COMPANY_GetALL_URL)
      .then((data) => {
        this.setState({ CompanyData: data.data.response })
      })
    let companyList = this.state.CompanyData.filter(x => x.IsActive === true && x.IsDeleted === false && x.CreatedBy !== null & x.ModifiedBy !== null);
    companyList.map((data, index) => {
      this.state.TableData.push(
        {
          companyId: data._id, h_0: index + 1, h_1: data.CompanyCode, h_2: data.Name, h_3: data.CompanyEmail, h_4: data.CompanyPhone, h_5: data.CreatedBy.firstname,
          h_6: data.createdAt, h_7: data.ModifiedBy.firstname, h_8: data.updatedAt
        } ,
      )
    })
    this.setState({
      TableData: this.state.TableData
    })

  }

  async resetStateChange() {
    this.setState({ companyToValidate: { ...this.state.companyToValidate, StateId: "" } })
    this.setState({
      selectedOption_state: ""
    })
  }

  async resetCityChange() {
    this.setState({ companyToValidate: { ...this.state.companyToValidate, CityId: "" } })
    this.setState({
      selectedOption_city: ""
    })
  }

  checkBusinessCategoryIdError = () => {
    if (this.state.companyToValidate.BusinessCategoryId === "") {
      const error = this.validateProperty(this.state.companyToValidate.BusinessCategoryId, this.schema.BusinessCategoryId);
      this.setState({ errorBusinessCategoryId: error })
      this.setState({ isUpdate: false });
      return true;
    }
  }
  checkCountryIdError = () => {
    if (this.state.companyToValidate.CountryId === "") {
      const error = this.validateProperty(this.state.companyToValidate.CountryId, this.schema.CountryId);
      this.setState({ errorCountryId: error })
      this.setState({ isUpdate: false });
      return true;
    }
  }

  checkCityIdError = () => {
    if (this.state.companyToValidate.CityId === "") {
      const error = this.validateProperty(this.state.companyToValidate.CityId, this.schema.CityId);
      this.setState({ errorCityId: error })
      this.setState({ isUpdate: false });
      return true;
    }
  }

  checkStateIdError = () => {
    if (this.state.companyToValidate.StateId === "") {
      const error = this.validateProperty(this.state.companyToValidate.StateId, this.schema.StateId);
      this.setState({ errorStateId: error })
      this.setState({ isUpdate: false });
      return true;
    }
  }

  checkGenderIdError = () => {
    if (this.state.companyToValidate.Gender === "") {
      const error = this.validateProperty(this.state.companyToValidate.Gender, this.schema.Gender);
      this.setState({ errorGender: error })
      this.setState({ isUpdate: false });
      return true;
    }
  }

  SaveOrUpdate = async () => {
    this.checkStateIdError();
    this.checkCountryIdError();
    this.checkCityIdError();
    this.checkBusinessCategoryIdError();
    this.checkGenderIdError();
    if (this.checkStateIdError() || this.checkCountryIdError() || this.checkCityIdError() || this.checkBusinessCategoryIdError() || this.checkGenderIdError())
      return;
    const data = {
      //G_1
      id: this.state.Id,
      Name: this.state.companyToValidate.Name,
      CompanyCode: this.state.companyToValidate.CompanyCode,
      CompanyEmail: this.state.companyToValidate.CompanyEmail,
      CompanyPhone: this.state.companyToValidate.CompanyPhone,
      BusinessCategoryId: this.state.selectedOption_businessCategoty.value,
      CompanyTRN: this.state.companyToValidate.CompanyTRN,
      //G_2
      StreetAdress: this.state.companyToValidate.StreetAdress,
      Location: this.state.companyToValidate.Location,
      Area: this.state.companyToValidate.Area,
      Building: this.state.companyToValidate.Building,
      CountryId: this.state.selectedOption_country.value,
      StateId: this.state.selectedOption_state.value,
      CityId: this.state.selectedOption_city.value,
      ZipCode: this.state.companyToValidate.ZipCode,
      //G_3
      FirstName: this.state.companyToValidate.FirstName,
      LastName: this.state.companyToValidate.LastName,
      PersonalEmail: this.state.companyToValidate.PersonalEmail,
      PersonalPhoneNumber: this.state.companyToValidate.PersonalPhoneNumber,
      Designation: this.state.companyToValidate.Designation,
      Gender: this.state.selectedOption_gender.value,
      CreatedBy: this.state.CreatedBy,
      ModifiedBy: this.state.ModifiedBy,
      IsDeleted: this.state.IsDeleted,
      IsActive: this.state.IsActive,
    };
    this.doSaveOrUpdate(data, CompanyConstant.COMPANY_ADD_URL, CompanyConstant.COMPANY_UPDATE_URL);
  }

  loadCompany = async (id) => {
    await http.get(CompanyConstant.COMPANY_GetByID_URL + `?id=${id}`)
      .then((data) => {
        const dataVal = data.data.response;
        this.setState({
          companyToValidate: {
            Name: dataVal.Name, CompanyCode: dataVal.CompanyCode, CompanyEmail: dataVal.CompanyEmail, CompanyPhone: dataVal.CompanyPhone,
            BusinessCategoryId: dataVal.BusinessCategoryId, CompanyTRN: dataVal.CompanyTRN, StreetAdress: dataVal.StreetAdress, Location: dataVal.Location,
            Area: dataVal.Area, Building: dataVal.Building, CountryId: dataVal.CountryId, StateId: dataVal.StateId, CityId: dataVal.CityId, ZipCode: dataVal.ZipCode,
            FirstName: dataVal.FirstName, LastName: dataVal.LastName, PersonalEmail: dataVal.PersonalEmail, PersonalPhoneNumber: dataVal.PersonalPhoneNumber,
            Designation: dataVal.Designation, Gender: dataVal.Gender,
          }
        })
        this.setState({
          Id: dataVal._id
        })
        //Load Gender Data
        this.setState({ selectedOption_gender: { value: dataVal.Gender, label: dataVal.Gender } })
      }
      )

    //Get Business Category
    await http.get(BusinessCategoryConstant.BUSNIESS_CATEGORY_GetByID_URL + `?id=${this.state.companyToValidate.BusinessCategoryId}`)
      .then((data) => {
        this.setState({ selectedOption_businessCategoty: { value: data.data.response._id, label: data.data.response.Name } })
      })
    //Get Country
    await http.get(CountryConstant.Country_GetByID_URL + `?id=${this.state.companyToValidate.CountryId}`)
      .then((data) => {
        this.setState({ selectedOption_country: { value: data.data.response._id, label: data.data.response.Name } })
      })

    //Get State
    await http.get(StateConstant.State_GetByID_URL + `?id=${this.state.companyToValidate.StateId}`)
      .then((data) => {
        this.setState({ selectedOption_state: { value: data.data.response._id, label: data.data.response.Name } })
      })

    //Get city
    await http.get(CityConstant.City_GetByID_URL + `?id=${this.state.companyToValidate.CityId}`)
      .then((data) => {
        this.setState({ selectedOption_city: { value: data.data.response._id, label: data.data.response.Name } })
      })

    //Load State options Data
    await http.get(StateConstant.State_GetBYCOUNTR_URL + `?countryId=${this.state.companyToValidate.CountryId}`)
      .then((data) => {
        options_state.push(
          { value: data.data.message._id, label: data.data.message.Name },
        )
      })

    //Get City Data
    http.get(CityConstant.City_GetBySTATE_URL + `?stateId=${this.state.companyToValidate.StateId}`)
      .then((data) => {
        options_city.push(
          { value: data.data.message._id, label: data.data.message.Name },
        )
      })
  }

  //Soft Delete 
  deleteRecord = async (id) => {
    this.doDeleteRecord(id, CompanyConstant.COMPANY_DELETE_URL);
  }

  //Load Dependent DropDown Data (Country - State - State)
  handleChange_businessCategory = selectedOption_businessCategoty => {
    this.setState(
      { selectedOption_businessCategoty },
      () => console.log(`selectedOption_country selected:`, this.state.selectedOption_businessCategoty)
    );
    this.setState({ companyToValidate: { ...this.state.companyToValidate, BusinessCategoryId: selectedOption_businessCategoty.value } });

    if (this.state.companyToValidate.CountryId !== "" || this.state.companyToValidate.StateId !== "" || this.state.companyToValidate.CityId !== "" ||
      this.state.companyToValidate.Gender !== "")
      this.setState({ isUpdate: true });

  };

  handleChange_country = selectedOption_country => {
    this.resetStateChange();
    this.setState(
      { selectedOption_country },
      () => console.log(`selectedOption_country selected:`, this.state.selectedOption_country)
    );
    this.setState({ companyToValidate: { ...this.state.companyToValidate, CountryId: selectedOption_country.value } });
    options_state = []
    //Get State Data 
    http.get(StateConstant.State_GetBYCOUNTR_URL + `?countryId=${selectedOption_country.value}`)
      .then((data) => {
        data.data.message.map((data) => {
          options_state.push(
            { value: data._id, label: data.Name },
          )
        })
      })

    if (this.state.companyToValidate.BusinessCategoryId !== "" || this.state.companyToValidate.StateId !== "" || this.state.companyToValidate.CityId !== "" ||
      this.state.companyToValidate.Gender !== "")
      this.setState({ isUpdate: true });

  };

  handleChange_state = selectedOption_state => {
    //reset city options//
    this.resetCityChange();
    this.setState(
      { selectedOption_state },
      () => console.log(`selectedOption_state selected:`, this.state.selectedOption_state)
    );

    options_city = [];
    //Get City Data   
    http.get(CityConstant.City_GetBySTATE_URL + `?stateId=${selectedOption_state.value}`)
      .then((data) => {
        data.data.message.map((data) => {
          options_city.push(
            { value: data._id, label: data.Name },
          )
        })
      })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, StateId: selectedOption_state.value } });

    if (this.state.companyToValidate.BusinessCategoryId !== "" || this.state.companyToValidate.CountryId !== "" || this.state.companyToValidate.CityId !== "" ||
      this.state.companyToValidate.Gender !== "")
      this.setState({ isUpdate: true });

  };

  handleChange_city = selectedOption_city => {
    this.setState(
      { selectedOption_city },
      () => console.log(`selectedOption_city selected:`, this.state.selectedOption_city)
    );
    this.setState({ companyToValidate: { ...this.state.companyToValidate, CityId: selectedOption_city.value } });

    if (this.state.companyToValidate.BusinessCategoryId !== "" || this.state.companyToValidate.CountryId !== "" || this.state.companyToValidate.StateId !== "" ||
      this.state.companyToValidate.Gender !== "")
      this.setState({ isUpdate: true });
  };

  handleChange_gender = selectedOption_gender => {
    this.setState(
      { selectedOption_gender },
      () => console.log(`selectedOption_gender selected:`, this.state.selectedOption_gender)
    );
    this.setState({ companyToValidate: { ...this.state.companyToValidate, Gender: selectedOption_gender.value } });

    if (this.state.companyToValidate.BusinessCategoryId !== "" || this.state.companyToValidate.CountryId !== "" || this.state.companyToValidate.StateId !== "" ||
      this.state.companyToValidate.CityId !== "")
      this.setState({ isUpdate: true });

  };

  //Get Data From Inputs
  onChangeName = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, Name: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.Name);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorcompanyName: error })
  }
  onChangeCompanyCode = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, CompanyCode: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.CompanyCode);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorCompanyCode: error })
  }
  onChangeCompanyEmail = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, CompanyEmail: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.CompanyEmail);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorCompanyEmail: error })
  }
  onChangeCompanyPhone = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, CompanyPhone: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.CompanyPhone);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorCompanyPhone: error })
  }
  onChangeCompanyTRN = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, CompanyTRN: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.CompanyTRN);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorCompanyTRN: error })
  }
  onChangeStreetAdress = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, StreetAdress: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.StreetAdress);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorStreetAdress: error })
  }
  onChangeLocation = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, Location: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.Location);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorLocation: error })
  }
  onChangeArea = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, Area: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.Area);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorArea: error })
  }
  onChangeBuilding = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, Building: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.Building);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorBuilding: error })
  }
  onChangeZipCode = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, ZipCode: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.ZipCode);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorZipCode: error })
  }
  onChangeFirstName = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, FirstName: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.FirstName);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorFirstName: error })
  }
  onChangeLastName = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, LastName: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.LastName);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorLastName: error })
  }
  onChangePersonalEmail = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, PersonalEmail: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.PersonalEmail);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorPersonalEmail: error })
  }
  onChangePersonalPhoneNumber = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, PersonalPhoneNumber: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.PersonalPhoneNumber);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorPersonalPhoneNumber: error })
  }

  onChangeDesignation = (e) => {
    if (e.target.value !== "") this.setState({ isUpdate: true })
    this.setState({ companyToValidate: { ...this.state.companyToValidate, Designation: e.target.value } });
    const error = this.validateProperty(e.target.value, this.schema.Designation);
    if (error) this.setState({ isUpdate: false })
    this.setState({ errorDesignation: error })
  }

  //Reset The Form
  resetModal = () => {
    this.setState({
      companyToValidate: {
        Name: "", CompanyCode: "", CompanyEmail: "", CompanyPhone: "", BusinessCategoryId: "", CompanyTRN: "",
        StreetAdress: "", Location: "", Area: "", Building: "", CountryId: "", StateId: "", CityId: "", ZipCode: "", FirstName: "",
        LastName: "", PersonalEmail: "", PersonalPhoneNumber: "", Designation: "", Gender: "",
      }
    })
    this.setState({ selectedOption_state: null })
    this.setState({ selectedOption_country: null })
    this.setState({ selectedOption_gender: null })
    this.setState({ selectedOption_businessCategoty: null })
    this.setState({ selectedOption_city: null })
  }

  render() {
    const { companyToValidate, selectedOption_country, selectedOption_state, selectedOption_city, selectedOption_gender, selectedOption_businessCategoty,
      TableData, errorcompanyName, errorCompanyCode, errorCompanyEmail, errorCompanyPhone, errorBusinessCategoryId, errorCompanyTRN,
      errorStreetAdress, errorLocation, errorArea, errorBuilding, errorCountryId, errorStateId, errorCityId, errorZipCode,
      errorFirstName, errorLastName, errorPersonalEmail, errorPersonalPhoneNumber, errorDesignation, errorGender, isUpdate
    } = this.state;

    return (
      <>
        <MainMenu link={`company`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <StaticHTML />
                <div className="content-wrapper" >
                  <div className="company-table">
                    <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                      <div style={{ float: 'left' }}>
                        <h5 className="left-head">Company</h5>
                      </div>
                      <div style={{ float: 'right', marginTop: '-2px', marginRight: '-15px' }}>
                        <a onClick={this.resetModal} title="Reply" data-toggle="modal" data-target="#modal-Country">
                          <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;New Company <i className="bx bxs-plus-circle" /></span>
                        </a>
                      </div>
                    </div>
                    <CompanyTable TableData={TableData} loadCompany={this.loadCompany} deleteRecord={this.deleteRecord} />
                  </div> {/* End Top Section*/}
                </div> {/* ENd Company Table*/}

                <Modal
                  companyToValidate={companyToValidate} onChangeCompanyCode={this.onChangeCompanyCode} onChangeName={this.onChangeName}
                  onChangeCompanyPhone={this.onChangeCompanyPhone} onChangeCompanyEmail={this.onChangeCompanyEmail}
                  onChangeCompanyTRN={this.onChangeCompanyTRN} selectedOption_businessCategoty={selectedOption_businessCategoty}
                  handleChange_businessCategory={this.handleChange_businessCategory} options_businessCategory={options_businessCategory}
                  onChangeStreetAdress={this.onChangeStreetAdress} onChangeLocation={this.onChangeLocation}
                  onChangeArea={this.onChangeArea} onChangeBuilding={this.onChangeBuilding}
                  selectedOption_country={selectedOption_country} options_country={options_country} selectedOption_state={selectedOption_state}
                  options_state={options_state} selectedOption_city={selectedOption_city} options_city={options_city}
                  onChangeZipCode={this.onChangeZipCode} onChangeFirstName={this.onChangeFirstName}
                  onChangeLastName={this.onChangeLastName} onChangePersonalEmail={this.onChangePersonalEmail}
                  onChangePersonalPhoneNumber={this.onChangePersonalPhoneNumber}
                  onChangeDesignation={this.onChangeDesignation} selectedOption_gender={selectedOption_gender} options_gender={options_gender}
                  handleChange_country={this.handleChange_country} handleChange_state={this.handleChange_state} handleChange_city={this.handleChange_city}
                  handleChange_gender={this.handleChange_gender} SaveOrUpdate={this.SaveOrUpdate}
                  errorcompanyName={errorcompanyName} errorCompanyCode={errorCompanyCode} errorCompanyEmail={errorCompanyEmail}
                  errorCompanyPhone={errorCompanyPhone} errorBusinessCategoryId={errorBusinessCategoryId} errorCompanyTRN={errorCompanyTRN}
                  errorStreetAdress={errorStreetAdress} errorLocation={errorLocation} errorArea={errorArea} errorBuilding={errorBuilding}
                  errorCountryId={errorCountryId} errorStateId={errorStateId} errorCityId={errorCityId} errorZipCode={errorZipCode}
                  errorFirstName={errorFirstName} errorLastName={errorLastName} errorPersonalEmail={errorPersonalEmail}
                  errorPersonalPhoneNumber={errorPersonalPhoneNumber} errorDesignation={errorDesignation} errorGender={errorGender}
                  isUpdate={isUpdate}
                />


              </div>

            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Company;


