import React from 'react';
import Form from '../common/Form.jsx';

class Modal extends Form {
  render() {
    const { Name, BusinessCategoryCode, onChangeBusinessCategoryName, onChangeBusinessCategoryCode, SaveOrUpdate, errorBusinessCategoryName,
      errorBusinessCategoryCode, isUpdate } = this.props;

    return (
      //Note::mandatory to deny on click in any where in screen model close
      <div className="modal fade" id="modal-Country">
        <div className="modal-dialog modal-lg modal-plus">
          <div className="modal-content">
            {this.renderModalHeaderTitle("Add Business Category Details")}

            <div className="modal-body" style={{ marginLeft: '20px', marginRight: '20px' }}>
              <form className="form-horizontal" name={this.returnFormName("BusinessCategory")}>
                <div className="form-group row">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-label-group">

                        {this.renderInput("text", Name, "Name", errorBusinessCategoryName,
                          onChangeBusinessCategoryName)}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">

                        {this.renderInput("text", BusinessCategoryCode, "Business Category Code", errorBusinessCategoryCode,
                          onChangeBusinessCategoryCode)}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      {this.renderSubmitButton("submit", (errorBusinessCategoryName != null || errorBusinessCategoryCode != null) && isUpdate == false, SaveOrUpdate, "Save")}
                      {this.renderCancelButton()}
                    </div>

                    <div className="col-md-6 col-12">
                    </div>
                    <div className="col-md-12 col-lg-12 col-12">
                      <div style={{ float: 'left', marginLeft: '-15px', verticalAlign: 'baseline' }}>
                      </div>
                      <div style={{ float: 'right', marginRight: '-2px' }}>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default Modal;