import config from '../services/config.json';
import UserDataService from '../../data-services/users-data-services';

export const getAllUsers = config.apiEndPoint + UserDataService.getallUser;
export const getUserByFirstName = config.apiEndPoint + UserDataService.getUserByFirstName;
export const getUserByLastName = config.apiEndPoint + UserDataService.getUserByLastName;
export const getUserByEmail = config.apiEndPoint + UserDataService.getUserByEmail;
export const getUserByMobileNo = config.apiEndPoint + UserDataService.getUserByMobileNo;
export const getUserByPosition = config.apiEndPoint + UserDataService.getUserByPosition;







