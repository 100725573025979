import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import '../../../style/workspace.css'
import DateItem from '../items/DateItem';
import DropdownItem from '../items/DropdownItem';
import NumbersItem from '../items/NumbersItem';
import StatusItem from '../items/StatusItem';
import TagsItem from '../items/TagsItem';
import TextItem from '../items/TextItem';
import TimelineItem from '../items/TimelineItem';
import GroupColumnTitle from '../titles/GroupColumnTitle';
import GroupListRowTitle from '../titles/GroupListRowTitle';
import CellComponent from './CellComponent';
import BoardMessageContainer from '../chatIM/boardMessageContainer';
import { Resizable } from "re-resizable";
import { updateColumnWidth, fetchError } from '../../../../../actions';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import axios from '../../../../../util/Api';
import config from '../../../../services/config.json';
const socket = require('socket.io-client')(config.baseUrlEndPoint, {
    transports: ['websocket'],
    rejectUnauthorized: false,
})

const getColItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    backgroundColor: isDragging ? "lightgreen" : "white",
    'max-height': isDragging ? '100px !important' : '100px',
    'border' : isDragging ? '1px solid #000' : 'auto',

    ...draggableStyle
  });

//   const getColListStyle = isDraggingOver => ({
//     background: isDraggingOver ? 'lightblue' : 'lightgrey',
//     display: 'flex',
//   });


class DroppableRow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            cellHeaders: [],
            cellWidths: [],
            width: 200,
            height: 50,
            onColumnHeaderHover: false,
            showBoardMessages: false,
            messageID:'',
            msgCount: '',
            isWorkspaceOwner: this.props.isWorkspaceOwner,
            isWorkspaceAdmin: this.props.isWorkspaceAdmin,
            isBoardAdmin: this.props.isBoardAdmin,
            blinkRow: false,

        };
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
        this.style = {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "solid 1px #ddd",
            background: "#f0f0f0"
        }
        
        this.resizeDirection = { top: false, right: true, bottom: false, left: false, topRight: false, bottomRight: false, bottomLeft: false, topLeft: false }
    
    }

    componentDidMount() {
        const { cellItems, cellSuggestions } = this.props;
        this.setState({ items: cellItems });
        this.setState({ cellHeaders: cellSuggestions });
        this.setState({ isWorkspaceOwner: this.props.isWorkspaceOwner })
        this.setState({ isWorkspaceAdmin: this.props.isWorkspaceAdmin })
        this.setState({ isWorkspaceAdmin: this.props.isBoardAdmin })
        //console.log('ccccl', this.props)
        socket.emit('connectChatRoom', this.props.chatroom);
        socket.on("socketEvent_messageReceived", Obj => {

            if (Obj.message.from !== this.authUser._id) {
                axios.post('/workspace/getUnreadMsgCount', {
                    chatRoom: this.props.chatroom,
                    userId: this.authUser._id
                }).then(({ data }) => {
                  // console.log('bbbb',this.props.chatroom,data.unreadMsgCount)
                   this.setState({msgCount: data.unreadMsgCount})
                }).catch(error => {
                    //  dispatch({ type: FETCH_ERROR, payload: error.message });
                    console.log("Error****:", error.message);
                })
            }
        });
        axios.post('/workspace/getUnreadMsgCount', {
            chatRoom: this.props.chatroom,
            userId: this.authUser._id
        }).then(({ data }) => {
           //console.log('bbbb',this.props.chatroom,data.unreadMsgCount)
           this.setState({msgCount: data.unreadMsgCount})
        })

        for (let i = 0; i < this.props.cellSuggestions.length; i++) {

            //  this.setColumnWidthState(this.state.cellHeaders[i]._id,this.state.cellHeaders[i].width?this.state.cellHeaders[i].width:200)

            let obj = this.state.cellWidths
            obj[this.props.cellSuggestions[i]._id] = this.props.cellSuggestions[i].width ? parseInt(this.props.cellSuggestions[i].width) : 200
            this.setState({ cellWidths: obj })
        }

    }
  
    componentDidUpdate(prevProps, prevState) {
               
       
    if (JSON.stringify(this.props.cellSuggestions) !== JSON.stringify(prevProps.cellSuggestions)) {
            for (let i = 0; i < this.state.cellHeaders.length; i++) {
                //  this.setColumnWidthState(this.state.cellHeaders[i]._id,this.state.cellHeaders[i].width?this.state.cellHeaders[i].width:200)
                let obj = this.state.cellWidths
                obj[this.state.cellHeaders[i]._id] = this.state.cellHeaders[i].width ? parseInt(this.state.cellHeaders[i].width) : 200
                this.setState({ cellWidths: obj })
            }

           
        }
       

        // if(this.props.chatroom !== prevProps.chatroom){
        //     debugger;
        //     axios.post('/workspace/getUnreadMsgCount', {
        //         chatRoom: this.props.chatRoomID,
        //         userId: this.authUser._id
        //     }).then(({ data }) => {
        //        console.log('bbbb',this.props.chatRoomID,data.unreadMsgCount)
        //        this.setState({msgCount: data.unreadMsgCount})
        //     }).catch(error => {
                
        //         console.log("Error****:", error.message);
        //     })
        // }
    //     if(this.props.groupInd === parseInt(this.props.boardData.groupIndex) && this.props.rowInd === parseInt(this.props.boardData.rowIndex)){
               
    //         if(this.props.boardData.isChatNotification){
    //           this.setState({showBoardMessages:true})
    //           this.setState({messageID:this.props.boardData.messageID})
    //           // setTimeout(
    //           //     () => {
    //           //         this.openChatPanel();
    //           //     }, 
    //           //     4000
    //           //   );
             
    //       }else{
    //           this.setState({ blinkRow: true })
      
    //           setTimeout(
    //               () => {
    //                   this.setState({ blinkRow: false })
    //               }, 
    //               10000
    //             );
          
    //       }
     
    // }
    }

    componentWillReceiveProps(nextProps,nextState) {    

        this.setState({ items: nextProps.cellItems });
        this.setState({ cellHeaders: nextProps.cellSuggestions });


        axios.post('/workspace/getUnreadMsgCount', {
            chatRoom: nextProps.chatroom,
            userId: this.authUser._id
        }).then(({ data }) => {
          
           this.setState({msgCount: data.unreadMsgCount})
        })

        setTimeout(
            () => {
           
            this.setState({ isWorkspaceOwner: nextProps.isWorkspaceOwner })
            this.setState({ isWorkspaceAdmin: nextProps.isWorkspaceAdmin })
            this.setState({ isBoardAdmin: nextProps.isBoardAdmin })
            }, 
            100
          );
        // this.setState({ isWorkspaceOwner: nextProps.isWorkspaceOwner })
        // this.setState({ isWorkspaceAdmin: nextProps.isWorkspaceAdmin })
        // this.setState({ isBoardAdmin: nextProps.isBoardAdmin })
debugger;
          if(nextProps.groupInd === parseInt(nextProps.boardData.groupIndex) && nextProps.rowInd === parseInt(nextProps.boardData.rowIndex)){
               
                  if(nextProps.boardData.isChatNotification){
                    this.setState({showBoardMessages:true})
                    this.setState({messageID:nextProps.boardData.messageID})
                    setTimeout(
                        () => {
                            this.openChatPanel();
                        }, 
                        4000
                      );
                   
                }else{
                    this.setState({ blinkRow: true })
            
                    setTimeout(
                        () => {
                            this.setState({ blinkRow: false })
                        }, 
                        10000
                      );
                
                }
           
          }

       

    }

    updateColumnWidth = (width, columnId) => {

        this.props.updateColumnWidth({
            boardId: this.props.boardData.boardID,
            width: width,
            columnId: columnId
        });
    }

    getColumnWidth = (columnId) => {

        let columnIndex = this.state.cellHeaders.findIndex(ele => ele._id === columnId);
      //  console.log('cccc', this.state.cellHeaders[columnIndex])
        return parseInt(this.state.cellHeaders[columnIndex]?.width ? this.state.cellHeaders[columnIndex]?.width : 200)
    }

    setColumnWidthState = (key, val) => {
        console.log('keyval', key, val)
        let obj = this.state.cellWidths
        obj[key] = val
        this.setState({ cellWidths: obj })
    }

    
    closeModal = () => {
       
        this.setState({showBoardMessages: false});
      }

      openChatPanel = () => {
        axios.get('/workspace/getWorkSpaceById/', {
            params: { id: this.props.boardData.workspaceID }
        }).then(({ data }) => {
            let workspace = data.workspaces;           
            this.setState({ currentWorkspace: workspace })
            this.setState({ workspaceAdmins: workspace.admins })
            this.setState({ workspaceOwner: workspace.CreatedBy })
             if (this.authUser._id == workspace.CreatedBy) {
                this.setState({ isWorkspaceOwner: true })
                this.setState({ isWorkspaceAdmin: true })
            }else{
                this.setState({ isWorkspaceOwner: false })
                this.setState({ isWorkspaceAdmin: false })
            }
            if (workspace.admins.includes(this.authUser._id)) {
                this.setState({ isWorkspaceAdmin: true })
            }else{
                this.setState({ isWorkspaceAdmin: false }) 
            }

            //start opeing

            if (this.props.cellItems[0].body.includes(this.authUser._id) || this.props.cellItems[1].body.includes(this.authUser._id) || this.state.isWorkspaceOwner || this.state.isWorkspaceAdmin || this.state.isBoardAdmin ) {

                this.setState({showBoardMessages:!this.state.showBoardMessages})
             
            }else{
                toast.error("You don't have permission.");
                
            }
           //closing
        }).catch(err => {
            console.log("Error: ", err)
        })  
        
       
        
      }

    render() {
   
        const { _id, title } = this.props;
        
        return (
            
            <Droppable droppableId={_id} type='data' direction="horizontal">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}>
                        <div className={`d-flex board-table-row ${this.state.blinkRow?'blink_chatmsg':''}`} style={{ 'height' : '35px' , 'background-color' : '#ffffff' , 'position': 'relative'   }}>
                        
                       
                            <div 
                            className={`flex-item data-0 title-column  ${this.props.category === "header"?'bg-white':''}`} >
                                <div 
                                    style={{ opacity: this.props.category === "header" ? 1 : 1, minWidth: '400px', width: '100%', 'height': '34px' , 'position' : 'relative'   }}>
                                    <GroupListRowTitle
                                        id={_id}
                                        title={title}
                                        groupId={this.props.groupId}
                                        groupInd={this.props.groupInd}
                                        rowInd={this.props.rowInd}
                                        groupItemProvided={this.props.groupItemProvided}
                                        isHeader={this.props.category === "header" ? true : false}
                                        
                                    />
                                  {this.props.category !== "header" &&  <div id="chatIM" className="d-inline-flex"> 
                                    <div >
                                    {this.state.msgCount>0 &&
                                        <span className="badge badge-warning text-white bg-pink mr-2" title="Unread Count" >{this.state.msgCount}</span>}
                                        <i class="fal fa-comment-alt-dots cursor-pointer board-chat-icon" data-toggle="tooltip" data-title="Start Conversation"
                                        onClick={() => this.openChatPanel()} style={{ fontSize : '20px' }}
                                        ></i>
                                        
                                    </div> 
                                    {this.state.showBoardMessages  && <div className="backfrop" onClick={() => this.setState({showBoardMessages:!this.state.showBoardMessages})}></div>}
                                    {this.state.showBoardMessages && <BoardMessageContainer
                                    id={_id}
                                    title={title}
                                    groupId={this.props.groupId}
                                    groupInd={this.props.groupInd}
                                    rowInd={this.props.rowInd}
                                    groupItemProvided={this.props.groupItemProvided}
                                    cellSuggestions={this.props.cellSuggestions}
                                    cellItems={this.props.cellItems}
                                    chatRoomID={this.props.chatroom}
                                    isChatNotification={this.props.boardData.isChatNotification}
                                    message={this.props.boardData.message}
                                    handleClose={this.closeModal}
                                    messageID={this.props.boardData.messageID}
                                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                                    isBoardAdmin={this.props.isBoardAdmin}
                                    lastUpdated={this.props.lastUpdated ? this.props.lastUpdated : null} 
                                    ></BoardMessageContainer>}
                                    
                                    </div>   }                                
                                </div>
                            </div>
                            {this.props.cellItems
                                .filter(cellItems => cellItems.restrictedMembers ? !cellItems.restrictedMembers.includes(this.authUser._id) : true)
                                .map((item, index) => {
                                    if (this.props.category === "header") return (
                                        <Resizable
                                            //  size={{ width: this.getColumnWidth(item.columnId), height: this.state.height }}
                                            size={{ width: this.state.cellWidths[item.columnId] }}
                                            minWidth={120}
                                            maxWidth={700}
                                            //    bounds='parent'
                                            enable={this.resizeDirection}
                                            //   boundsByDirection={true}
                                            onResizeStop={(e, direction, ref, d) => {
                                                let items = this.state.cellWidths;
                                                items[item.columnId] = this.state.cellWidths[item.columnId] + d.width;
                                                this.setState({ cellWidths: items });
                                                
                                                this.setState({
                                                    width: this.state.cellWidths[item.columnId] + d.width,
                                                    //  height: this.state.height + d.height,
                                                });
                                                console.log('direc',this.state.cellWidths[item.columnId],d,this.state.width)
                                                this.updateColumnWidth(this.state.cellWidths[item.columnId], item.columnId)

                                            }}
                                            style={this.style}
                                            key={item._id}
                                            className={`flex-item columnbg mb-1 ${this.state.onColumnHeaderHover=== item._id?'bg-rgba-light':'bg-white'} ${this.props.targetAreaIndex === index ? "DragingHover" : "none"} data-${item._id} ${(item.restrictedMembers ? item.restrictedMembers.includes('ViewOnly_' + this.authUser._id) : false) ? "disablediv" : ""}`}
                                            onMouseOver={() => {
                                                this.setState({onColumnHeaderHover:item._id})
                                            }}
                                            onMouseOut={() => {this.setState({onColumnHeaderHover:false})}} 
                                            style={{ 'height' : '34px !important'  }} >

                                            <Draggable
                                                draggableId={item._id}
                                                index={index}
                                            >
                                                {(provided) => {
                                                    // console.log(" Provided: ", provided);
                                                    return (
                                                        <div
                                                            
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                            style={getColItemStyle(snapshot.isDragging,
                                                                provided.draggableProps.style)}
                                                        >
                                                            {/* {item.body} */}
                                                            

                                                            <GroupColumnTitle className={`  ${this.state.blinkRow?'blink_chatmsg':''}`} groupInd={this.props.groupInd} rowInd={this.props.rowInd}  cellItems={this.props.cellItems}
                                                            isViewOnly={(item.restrictedMembers ? item.restrictedMembers.includes('ViewOnly_' + this.authUser._id) : false)}
                                                            colInd={index} {...item}  />
                                                        </div>
                                                    );
                                                }}
                                            </Draggable>


                                        </Resizable>


                                    );
                                    else return (
                                        <div
                                            style={{ width: this.getColumnWidth(item.columnId) ,  'height'  : '35px' }}
                                            key={item._id} className={`flex-item  ${this.props.targetAreaIndex === index ? "DragingHover" : "none"} data-${item._id} ${(item.restrictedMembers ? item.restrictedMembers.includes('ViewOnly_' + this.authUser._id) : false) ? "disablediv" : ""}`}>
                                            <div  >
                                                {/* <div>{item.body}</div> */}
                                                <CellComponent
                                                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                                                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                                                    isBoardAdmin={this.props.isBoardAdmin}
                                                    groupInd={this.props.groupInd} 
                                                    lastUpdated={this.props.lastUpdated ? this.props.lastUpdated : null} 
                                                    rowInd={this.props.rowInd} 
                                                    colInd={index} 
                                                    item={item} 
                                                    cellItems={this.props.cellItems} />
                                            </div>
                                        </div>


                                       




                                    );
                                })}
                            {provided.placeholder}
                        </div>
                    </div>





                )
                }
            </Droppable >
            
        );
    }
}

//export default DroppableRow;

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { updateColumnWidth, fetchError }

export default connect(mapStateToProps, mapDispatchToProps)(DroppableRow);

