import React, { Component } from 'react';
class UserChatList extends Component {
   state = {}
   render() {
      return (
         <>

      <div className="col-md-3 user-display-section">
               <div className="col-md-12" style={{ padding: '10px 27px', minHeight: '61px' }}>
                  <ul className="nav nav-tabs" role="tablist">
                     <li className="nav-item">
                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" aria-controls="home" role="tab" aria-selected="true">
                           <span data-toggle="tooltip" data-placement="right" title="Chat List"><i className="bx bxs-chat align-middle" /></span>
                           <span className="align-middle"></span>
                        </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" aria-controls="profile" role="tab" aria-selected="false">
                           <span data-toggle="tooltip" data-placement="right" title="Group Members"><i className="bx bxs-group align-middle" /></span>
                           <span className="align-middle"></span>
                        </a>
                     </li>
                     <li className="nav-item">
                        <a className="nav-link" id="about-tab" data-toggle="tab" href="#about" aria-controls="about" role="tab" aria-selected="false">
                           <span data-toggle="tooltip" data-placement="right" title="Extras"><i className="bx bx-message-square align-middle" /></span>
                           <span className="align-middle"></span>
                        </a>
                     </li>
                  </ul>
               </div>


               <div className="col-md-12" style={{ padding: '0px 39px' }}>
                  <div className="row">
                     <div className="col-md-12" id="user-top-search" >
                        <input type="text" className="chat-dashboard" placeholder="Search name" />
                        <i className="bx bxs-plus-circle chat-plus-circle" data-toggle="modal" data-target="#exampleModalCenter"></i>
                     </div>
                  </div>
               </div>


               <div className="col-md-12">
                  <section id="basic-tabs-components">
                     <div className="card" style={{ border: 'none', background: 'none' }}>
                        <div className="card-content" style={{ background: 'none !important' }}>
                           <div className="card-body" style={{ background: 'none !important', padding: '0' }}>


                              <div className="tab-content messenger-tab-content">
                                 <div className="tab-pane active" id="home" aria-labelledby="home-tab" role="tabpanel">


                                    <div className="row user-list-wrapper">
                                       <div className="row user-list-section">
                                          <div className="col-md-12 recent-call-section d-flex user-chat-layout">
                                             <div className="avatar mr-75">
                                                <span className="avatar-status-online green-notification" />
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section" >
                                                <span className="user-chat-title">Jimmy Changa</span><br />
                                                <span className="user-chat-msg">Hi Iam Here</span>
                                             </div>
                                             <div className="align-items-center user-time-section">
                                                <span className="user-chat-time">04:28 PM</span>
                                             </div>
                                          </div>


                                          <div className="col-md-12 recent-call-section d-flex user-chat-layout" >
                                             <div className="avatar mr-75">
                                                <span className="avatar-status-online green-notification" />
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section">
                                                <span className="user-chat-title">Otto Matic</span><br />
                                                <span className="user-chat-msg">Fine</span>
                                             </div>
                                             <div className="align-items-center user-time-section">
                                                <span className="user-chat-time">04:10 PM</span>
                                             </div>
                                          </div>


                                          <div className="col-md-12 recent-call-section d-flex user-chat-layout" >
                                             <div className="avatar mr-75">
                                                <span className="avatar-status-online green-notification" />
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section">
                                                <span className="user-chat-title">Anna Mull</span><br />
                                                <span className="user-chat-msg">Let's do it...</span>
                                             </div>
                                             <div className="align-items-center user-time-section">
                                                <span className="user-chat-time">03:15 PM</span>
                                             </div>
                                          </div>



                                          <div className="col-md-12 recent-call-section d-flex user-chat-layout" >
                                             <div className="avatar mr-75">
                                                <span className="avatar-status-online green-notification" />
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section">
                                                <span className="user-chat-title">Barb Ackue</span><br />
                                                <span className="user-chat-msg">Busy</span>
                                             </div>
                                             <div className="align-items-center user-time-section">
                                                <span className="user-chat-time">03:07 PM</span>
                                             </div>
                                          </div>




                                          <div className="col-md-12 recent-call-section d-flex user-chat-layout" >
                                             <div className="avatar mr-75">
                                                <span className="avatar-status-online green-notification" />
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section">
                                                <span className="user-chat-title">Maya Didas</span><br />
                                                <span className="user-chat-msg">Sent</span>
                                             </div>
                                             <div className="align-items-center user-time-section" >
                                                <span className="user-chat-time">02:58 PM</span>
                                             </div>
                                          </div>




                                          <div className="col-md-12 recent-call-section d-flex user-chat-layout active">
                                             <div className="avatar mr-75">
                                                {/* <span className="avatar-status-online green-notification"/> */}
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section">
                                                <span className="user-chat-title active">Robin Banks</span><br />
                                                <span className="user-chat-msg active">Let's do it...</span>
                                             </div>
                                             <div className="align-items-center user-time-section">
                                                <span className="user-chat-time active">Yesterday</span>
                                             </div>
                                          </div>


                                          <div className="col-md-12 recent-call-section d-flex  user-chat-layout" >
                                             <div className="avatar mr-75">
                                                <span className="avatar-status-online green-notification" />
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section">
                                                <span className="user-chat-title">Peter Pants</span><br />
                                                <span className="user-chat-msg">I will check</span>
                                             </div>
                                             <div className="align-items-center user-time-section">
                                                <span className="user-chat-day">Yesterday</span>
                                             </div>
                                          </div>

                                          <div className="col-md-12 recent-call-section d-flex  user-chat-layout" >
                                             <div className="avatar mr-75">
                                                <span className="avatar-status-online green-notification" />
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section">
                                                <span className="user-chat-title">Anna Sthesia</span><br />
                                                <span className="user-chat-msg">Okay</span>
                                             </div>
                                             <div className="align-items-center user-time-section">
                                                <span className="user-chat-day">Sunday</span>
                                             </div>
                                          </div>




                                          <div className="col-md-12 recent-call-section d-flex  user-chat-layout" >
                                             <div className="avatar mr-75">
                                                <span className="avatar-status-online green-notification" />
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section">
                                                <span className="user-chat-title">Nick R. Bocker</span><br />
                                                <span className="user-chat-msg">Hi</span>
                                             </div>
                                             <div className="align-items-center user-time-section">
                                                <span className="user-chat-date">05 Oct 2020</span>
                                             </div>
                                          </div>


                                          <div className="col-md-12 recent-call-section d-flex  user-chat-layout" >
                                             <div className="avatar mr-75">
                                                <span className="avatar-status-online green-notification" />
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section">
                                                <span className="user-chat-title">Nick R. Bocker</span><br />
                                                <span className="user-chat-msg">Hi</span>
                                             </div>
                                             <div className="align-items-center user-time-section">
                                                <span className="user-chat-date">05 Oct 2020</span>
                                             </div>
                                          </div>


                                          <div className="col-md-12 recent-call-section d-flex  user-chat-layout" >
                                             <div className="avatar mr-75">
                                                <span className="avatar-status-online green-notification" />
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section">
                                                <span className="user-chat-title">Nick R. Bocker</span><br />
                                                <span className="user-chat-msg">Hi</span>
                                             </div>
                                             <div className="align-items-center user-time-section">
                                                <span className="user-chat-date">05 Oct 2020</span>
                                             </div>
                                          </div>


                                          <div className="col-md-12 recent-call-section d-flex  user-chat-layout" >
                                             <div className="avatar mr-75">
                                                <span className="avatar-status-online green-notification" />
                                                <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                             </div>
                                             <div className="align-items-center user-details-section">
                                                <span className="user-chat-title">Nick R. Bocker</span><br />
                                                <span className="user-chat-msg">Hi</span>
                                             </div>
                                             <div className="align-items-center user-time-section">
                                                <span className="user-chat-date">05 Oct 2020</span>
                                             </div>
                                          </div>


                                       </div>
                                    </div>
                                 </div>
                                 <div className="tab-pane" id="profile" aria-labelledby="profile-tab" role="tabpanel">



                                    <div className="row user-list-section">
                                       <div className="col-md-12 recent-call-section d-flex user-chat-layout">
                                          <div className="avatar mr-75">
                                             <span className="avatar-status-online green-notification" />
                                             <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                          </div>
                                          <div className="align-items-center user-details-section" >
                                             <span className="user-chat-title">Jimmy Changa</span><br />
                                             <span className="user-chat-msg">Hi Iam Here</span>
                                          </div>
                                          <div className="align-items-center user-time-section">
                                             <span className="user-chat-time">04:28 PM</span>
                                          </div>
                                       </div>


                                       <div className="col-md-12 recent-call-section d-flex user-chat-layout" >
                                          <div className="avatar mr-75">
                                             <span className="avatar-status-online green-notification" />
                                             <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                          </div>
                                          <div className="align-items-center user-details-section">
                                             <span className="user-chat-title">Otto Matic</span><br />
                                             <span className="user-chat-msg">Fine</span>
                                          </div>
                                          <div className="align-items-center user-time-section">
                                             <span className="user-chat-time">04:10 PM</span>
                                          </div>
                                       </div>


                                       <div className="col-md-12 recent-call-section d-flex user-chat-layout" >
                                          <div className="avatar mr-75">
                                             <span className="avatar-status-online green-notification" />
                                             <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                          </div>
                                          <div className="align-items-center user-details-section">
                                             <span className="user-chat-title">Anna Mull</span><br />
                                             <span className="user-chat-msg">Let's do it...</span>
                                          </div>
                                          <div className="align-items-center user-time-section">
                                             <span className="user-chat-time">03:15 PM</span>
                                          </div>
                                       </div>



                                       <div className="col-md-12 recent-call-section d-flex user-chat-layout" >
                                          <div className="avatar mr-75">
                                             <span className="avatar-status-online green-notification" />
                                             <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                          </div>
                                          <div className="align-items-center user-details-section">
                                             <span className="user-chat-title">Barb Ackue</span><br />
                                             <span className="user-chat-msg">Busy</span>
                                          </div>
                                          <div className="align-items-center user-time-section">
                                             <span className="user-chat-time">03:07 PM</span>
                                          </div>
                                       </div>



                                    </div>


                                 </div>
                                 <div className="tab-pane" id="about" aria-labelledby="about-tab" role="tabpanel">



                                    <div className="row user-list-section">
                                       <div className="col-md-12 recent-call-section d-flex user-chat-layout">
                                          <div className="avatar mr-75">
                                             <span className="avatar-status-online green-notification" />
                                             <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                          </div>
                                          <div className="align-items-center user-details-section" >
                                             <span className="user-chat-title">Jimmy Changa</span><br />
                                             <span className="user-chat-msg">Hi Iam Here</span>
                                          </div>
                                          <div className="align-items-center user-time-section">
                                             <span className="user-chat-time">04:28 PM</span>
                                          </div>
                                       </div>


                                       <div className="col-md-12 recent-call-section d-flex user-chat-layout" >
                                          <div className="avatar mr-75">
                                             <span className="avatar-status-online green-notification" />
                                             <img src="./images/usericon.png" alt="avtar images" className="user-avatar-list" />
                                          </div>
                                          <div className="align-items-center user-details-section">
                                             <span className="user-chat-title">Otto Matic</span><br />
                                             <span className="user-chat-msg">Fine</span>
                                          </div>
                                          <div className="align-items-center user-time-section">
                                             <span className="user-chat-time">04:10 PM</span>
                                          </div>
                                       </div>

                                    </div>


                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </section>

               </div>



            </div>





         </>
      );
   }
}

export default UserChatList;