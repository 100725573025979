import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { initialWorkspaceBoardGroupData } from '../../../../constants/Dump';
import axios from '../../../../util/Api';
import { changeWorkspaceBoard, reloadWorkspace, showMessage } from '../../../../actions';
import WorkspaceBoardAction from './workspaceNavbarComponent/WorkspaceBoardAction';
import WorkspaceAction from './workspaceNavbarComponent/WorkspaceAction';
import SubworkspaceAction from './workspaceNavbarComponent/SubworkspaceAction';
import SubfolderAction from './workspaceNavbarComponent/SubfolderAction';
import WorkspaceFilter from './WorkspaceFilter';
import config from '../../../services/config.json';
import WorkspaceSearch from './WorkspaceSearch';
const socket = require('socket.io-client')(config.baseUrlEndPoint, {
    transports: ['websocket'],
    rejectUnauthorized:   false,
  })
class WorkspaceSideCategories extends React.Component {
    constructor(props) {
        super(props);
        this.mounted = false;
        this.state = {
            categories: "",
            filteredCats:[],
            filteredSubCats:[],
            filteredNestedCats:[],
            searchKeyword:"",
            searchInWorkspaces: true

        }
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
        this.allowedCategories = {};
    }

    componentDidMount() {
        socket.emit('selfRoom',this.authUser._id);
      
            socket.on("socketEvent_workspaceUpdate", Obj => {
                axios.get('/workspace/getAllWorkSpaces').then(({ data }) => {
                   this.setState({ categories: data.workspaces })                  
                }).catch(err => {
                    console.log("error==>", err)
                })                  
  
          });
        

        this.mounted = true;
       
        axios.get('/workspace/getAllWorkSpaces').then(({ data }) => {

            if (this.mounted) this.setState({ categories: data.workspaces })
            //  this.getAllNestedWorkspaceBoards(1)

        }).catch(err => {
            console.log("error==>", err)
        })

    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.boardData.boardTitle !== this.props.boardData.boardTitle || prevProps.boardData.loadWorkspace !== this.props.boardData.loadWorkspace) {
    //         if(this.mounted) {
    //             axios.get('/workspace/getAllWorkSpaces').then(({ data }) => {
    //                 // console.log("response==> response", data)
    //                 if (this.mounted) this.setState({ categories: data.workspaces })
    //             }).catch(err => {
    //                 console.log("error==>", err)
    //             })

    //         }
    //     }
    // }
    componentWillReceiveProps(nextProps) {
        if(nextProps.boardData.loadWorkspace !== this.props.boardData.loadWorkspace) {
       
        axios.get('/workspace/getAllWorkSpaces').then(({ data }) => {
           
            if (this.mounted) this.setState({ categories: data.workspaces })
        }).catch(err => {
            console.log("error==>", err)
        })
        }
    }
    componentWillUnmount() {
        
      
   //     socket.emit('userDisconnectedWorkspaces');
        this.mounted = false;
    }

    getAllNestedWorkspaceBoards = (index) => {
        console.log('level_1', this.state.categories[index].workspaceBoards.length)
        let y = this.state.categories[index].workspaceBoards;
        for (let i = 0; i < this.state.categories[index].subworkspaces.length; i++) {

            var x = [...y, ...this.state.categories[index].subworkspaces[i].workspaceBoards];
            console.log('level_2', x.length)

            for (let j = 0; j < this.state.categories[index].subworkspaces[i].subworkspaces.length; j++) {

                x = [...x, ...this.state.categories[index].subworkspaces[i].subworkspaces[j].workspaceBoards];

                console.log('level_3', x.length)
            }
        }
        console.log('getAllNestedWorkspaceBoards', x)
        return x;
    }

    filterWorkspaces = (workspace) => {
      //console.log('workspaceee',workspace)
        this.allowedCategories[workspace._id] = [];
         
        let isLevel_1;
        let isLevel_2;
        let isLevel_3;  

        for (let x = 0; x < workspace.workspaceBoards.length; x++) {
            if (workspace.workspaceBoards[x].members.includes(this.authUser._id)
            ) {
                isLevel_1 = true;
                this.allowedCategories[workspace._id].push(1);             

            }
        }

        for (let i = 0; i < workspace.subworkspaces.length; i++) {

            for (let z = 0; z < workspace.subworkspaces[i].workspaceBoards.length; z++) {

                if (workspace.subworkspaces[i].workspaceBoards[z].members.includes(this.authUser._id)) {
                    isLevel_2 = true;
                  
                    this.allowedCategories[workspace._id].push(workspace.subworkspaces[i]._id);
                    this.subscribeToWorkspaceSocketRoom(workspace.subworkspaces[i]._id)
                }
            }

            for (let w = 0; w < workspace.subworkspaces[i].subworkspaces.length; w++) {

                for (let v = 0; v < workspace.subworkspaces[i].subworkspaces[w].workspaceBoards.length; v++) {

                    if (workspace.subworkspaces[i].subworkspaces[w].workspaceBoards[v].members.includes(this.authUser._id)) {
                        isLevel_3 = true;
                        this.allowedCategories[workspace._id].push(workspace.subworkspaces[i]._id);
                        this.allowedCategories[workspace._id].push(workspace.subworkspaces[i].subworkspaces[w]._id);
                        this.subscribeToWorkspaceSocketRoom(workspace.subworkspaces[i]._id)
                        this.subscribeToWorkspaceSocketRoom(workspace.subworkspaces[i].subworkspaces[w]._id)
                    }
                }

            }
        }

        if ((workspace.CreatedBy === this.authUser._id) || workspace.admins.includes(this.authUser._id)) {
            this.allowedCategories[workspace._id].push('admin');    
            this.subscribeToWorkspaceSocketRoom(workspace._id)      
            return true;
        } 
        if(isLevel_1 || isLevel_2 || isLevel_3){
            this.subscribeToWorkspaceSocketRoom(workspace._id)
        }
        return isLevel_1 || isLevel_2 || isLevel_3;


    }
    
    getFilteredCats= (cats,subcats,nestedcats) => {
        console.log('cats,subcats,nestedcats',cats,subcats,nestedcats)
        this.setState({ filteredCats: cats })
        this.setState({ filteredSubCats: subcats })
        this.setState({ filteredNestedCats: nestedcats })
    }

    getSearchKeywords= (keyword) => {
        this.setState({ searchKeyword: keyword })
    }

    subscribeToWorkspaceSocketRoom = (workspaceID) => {
        socket.emit('userConnectedWorkspaces',workspaceID);
    }
    //  str2bool = (value) => {
    //     if (value && typeof value === "string") {
    //          if (value.toLowerCase() === "true") return true;
    //          if (value.toLowerCase() === "false") return false;
    //     }
    //     return value;
    //  }
    // changeOptions = (ev) => {
       
    //     this.setState({ searchInWorkspaces: this.str2bool(ev.target.value) })
    // }

    render() {
        return (       
            
            <>
      
 <div className="category">
               {this.state.categories.length > 0 && <WorkspaceFilter categories={this.state.categories}  _onChange={this.getFilteredCats}></WorkspaceFilter>}
               <WorkspaceSearch onKeyUp={this.getSearchKeywords}></WorkspaceSearch>
               {/* <ul className="list-inline" >
                                <li className="list-inline-item">
                                    <div className="radio-field">       
                                        <input type="radio" value="true"
                                            id="status-option1"
                                            checked={this.state.searchInWorkspaces}
                                            onChange={this.changeOptions} />
                                        <label  className="ml-2" htmlFor="status-option1">Workspaces</label>
                                    </div>
                                </li>
                                <li className="list-inline-item">
                                    <div className="radio-field">
                                        <input type="radio" value="false"
                                            id="status-option2"
                                            checked={!this.state.searchInWorkspaces}
                                            onChange={this.changeOptions} />
                                        <label className="ml-2" htmlFor="status-option2"> Boards</label>
                                    </div>
                                </li>
                            </ul> */}
                <ul className='flex-column p-1 nav left-category-list'>
                    {this.state.categories.length > 0 ?
                        this.state.categories
                        .filter(category => this.state.filteredCats.length>0?this.state.filteredCats.includes(category._id):true)
                       //.filter(category => this.state.searchKeyword.length>0?category.title.includes(this.state.searchKeyword):true)
                        .map((category, index) =>
                        (
                            <li className='nav-item nav-inside-item' key={index}>

                                {this.filterWorkspaces(category) && category.members.includes(this.authUser._id) && category.title.startsWith(this.state.searchKeyword) && <WorkspaceAction workspaceInd={index} category={category} />}
                                {this.filterWorkspaces(category) && <div className="collapse show" id={`subcategory_${index}`} aria-expanded='false'>
                                    <ul className="flex-column nav pl-3">
                                    {category.workspaceBoards.length > 0 ?
                                            category.workspaceBoards
                                                .filter(workspaceBoards => workspaceBoards.members.includes(this.authUser._id))
                                                .filter(workspaceBoards => this.state.searchKeyword.length>0?workspaceBoards.title.startsWith(this.state.searchKeyword):true)
                                                .map((board, index) => {
                                                    return (
                                                        <li className={`nav-item nav-inside-item ${board._id === this.props.boardData.boardID ? 'active' : ''}`} key={category.subworkspaces.length + index}>

                                                            <WorkspaceBoardAction board={board} subWorkspaceID={null} workspaceID={category._id} workspace={category} actionType={`Workspace`} />
                                                        </li>
                                                    )
                                                })
                                            : ''}
                                        {category.subworkspaces
                                        .filter(subworkspace => this.state.filteredSubCats.length>0?this.state.filteredSubCats.includes(subworkspace._id):true)
                                       // .filter(subworkspace => this.state.searchKeyword.length>0?subworkspace.title.includes(this.state.searchKeyword):true)
                                            .map((subCategory, index_1) => (
                                                <li className="nav-item nav-inside-item" key={index_1}>

                                                    {(this.allowedCategories[category._id].includes('admin') || this.allowedCategories[category._id].includes(subCategory._id)) && subCategory.title.startsWith(this.state.searchKeyword) && <SubworkspaceAction categoryInd={index} subCategoryInd={index_1} subCategory={subCategory} category={category} />
                                                    }

                                                    {(this.allowedCategories[category._id].includes('admin') || this.allowedCategories[category._id].includes(subCategory._id)) &&

                                                        <div className="collapse show" id={`board_${index}_${index_1}`} aria-expanded='false'>
                                                            <ul className="flex-column nav pl-3">
                                                            {subCategory.workspaceBoards
                                                                    .filter(workspaceBoards => workspaceBoards.members.includes(this.authUser._id))
                                                                    .filter(workspaceBoards => this.state.searchKeyword.length>0?workspaceBoards.title.startsWith(this.state.searchKeyword):true)
                                                                    .map((board, index_2) => (
                                                                        <li className={`nav-item nav-inside-item ${board._id === this.props.boardData.boardID ? 'active' : ''}`} key={index_2}>
                                                                            <WorkspaceBoardAction board={board} subWorkspaceID={subCategory._id} workspaceID={category._id} workspace={category} actionType={`Subworkspace`} />
                                                                        </li>
                                                                    ))}
                                                                {subCategory.subworkspaces
                                                                .filter(subworkspace => this.state.filteredNestedCats.length>0?this.state.filteredNestedCats.includes(subworkspace._id):true)
                                                                .map((subworkspace, index_3) => (
                                                                    <li className="nav-item nav-inside-item" key={index_3}>

                                                                        {(this.allowedCategories[category._id].includes('admin') || this.allowedCategories[category._id].includes(subworkspace._id)) && <SubfolderAction categoryInd={index} subWorkspaceInd={index_1} subCategoryInd={index_3} subCategory={subworkspace} subworkspace={subCategory} category={category} />
                                                                        }
                                                                        {(this.allowedCategories[category._id].includes('admin') || this.allowedCategories[category._id].includes(subworkspace._id)) && <div className="collapse show" id={`board_${index}_${index_1}_${index_3}`} aria-expanded='false'>
                                                                            <ul className="flex-column nav pl-3">
                                                                                {subworkspace.workspaceBoards
                                                                                    .filter(workspaceBoards => workspaceBoards.members.includes(this.authUser._id))
                                                                                    .map((board, index_2) => (
                                                                                        <li className={`nav-item nav-inside-item ${board._id === this.props.boardData.boardID ? 'active' : ''}`} key={index_2}>
                                                                                            <WorkspaceBoardAction board={board} subWorkspaceID={subworkspace._id} workspaceID={category._id} workspace={category} actionType={`Subworkspace`} />
                                                                                        </li>
                                                                                    ))}
                                                                            </ul>
                                                                        </div>
                                                                        }
                                                                    </li>
                                                                ))}
                                                                
                                                            </ul>
                                                        </div>
                                                    }
                                                </li>
                                            ))}
                                       
                                    </ul>
                                </div>}
                            </li>
                        )) : ""
                    }
                </ul>
            </div>




            </>
    
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeWorkspaceBoard, reloadWorkspace, showMessage };

export default connect(mapStateToProps, mapDispatchToProps)(WorkspaceSideCategories);