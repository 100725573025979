import React from 'react';
import ContentEditable from 'react-contenteditable';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { changeBoardItem, fetchError } from '../../../../../actions';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../../util/permission';
import ReactTooltip from 'react-tooltip';
class TextItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: this.props.body,
            actionPermission: 'edit'
        }
        this.timeout = 0;

        this.itemTextEditableRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem?.cellItems || [];
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission !== 'low') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
            let cellItems = groupItem?.cellItems || [];
            let peoples = cellItems.find(cell => cell.key === 'people');
            let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
            let permission = getMyPermission(boardPermission, members, people);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    onChangeTextItem = (event) => {
        this.setState({ content: event.target.value })
    }

    onKeyUp = (event) => {
        console.log('llp6',this.props.boardData.data[this.props.groupInd]);
         
        console.log('llp6',this.props);
         
    
            if(this.state.actionPermission === 'edit'  || ( getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers))) {
              
              
                    const { groupInd, rowInd, colInd, _id } = this.props;
                    this.props.changeBoardItem({
                        groupInd: groupInd,
                        rowInd: rowInd,
                        colInd: colInd,
                        body: event.target.outerText,
                        cellItemID: _id,
                        boardId: this.props.boardData.boardID
                    })
             
            } else {
                this.props.fetchError("You don't have permission. You can only view.")
            }         
            ReactTooltip.rebuild();   
        
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.body !== prevProps.body) {
            this.setState({ content: this.props.body });
        }
    }

    render() {
        // console.log("text cellItem props==>", this.props)
        return (
            <div className="" style={{ padding: '3px 10px' , 'border': '1px solid #EAEAEA' ,
            'border-top' : 'none' ,
            'border-bottom' : 'none' ,
            'border-right' : 'none'
         }}>
                <ContentEditable
                    className="board-name text-items"
                    innerRef={r=>this.itemTextEditableRef=r}
                    data-tip={this.state.content}
                    html={this.state.content}
                    disabled={this.state.actionPermission === 'view' ? true : false}
                  //  onChange={this.onChangeTextItem}
                    onBlur={event => this.onKeyUp(event)}
                    style={{ width: '100%', fontSize: '16px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    tagName='div'
                />
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeBoardItem, fetchError };

export default connect(mapStateToProps, mapDispatchToProps)(TextItem);