import React, { Component } from 'react';
class ChatSection extends Component {
   state = {}
   render() {
      return (
         <>
            <div className="col-md-6 chatbg">
               <div className="chat-section-layout">
                  <div className="chat-msg-recieved-layout">
                     <div className="chat-msg-recieved">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit </span> <br />
                        <div className="chat-time">04:17 PM</div>
                     </div>
                  </div>

                  <div className="chat-msg-sent-layout">
                     <div className="chat-msg-sent">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit </span> <br />
                        <div className="chat-time">04:17 PM &nbsp;&nbsp; <i className="bx bx-check-double" style={{ color: '#31dde4', verticalAlign: 'middle', fontSize: '23px' }}></i></div>
                     </div>
                  </div>

                  <div className="chat-msg-recieved-layout">
                     <div className="chat-msg-recieved">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit </span> <br />
                        <div className="chat-time">04:17 PM</div>
                     </div>
                  </div>

                  <div className="chat-msg-sent-layout">
                     <div className="chat-msg-sent">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit </span> <br />
                        <div className="chat-time">04:17 PM &nbsp;&nbsp; <i className="bx bx-check-double" style={{ color: '#31dde4', verticalAlign: 'middle', fontSize: '23px' }}></i></div>
                     </div>
                  </div>

                  <div className="chat-msg-recieved-layout">
                     <div className="chat-msg-recieved">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit </span> <br />
                        <div className="chat-time">04:17 PM</div>
                     </div>
                  </div>

                  <div className="chat-msg-sent-layout">
                     <div className="chat-msg-sent">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit </span> <br />
                        <div className="chat-time">04:17 PM &nbsp;&nbsp; <i className="bx bx-check-double" style={{ color: '#31dde4', verticalAlign: 'middle', fontSize: '23px' }}></i></div>
                     </div>
                  </div>



                  <div className="chat-msg-recieved-layout">
                     <div className="chat-msg-recieved">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit </span> <br />
                        <div className="chat-time">04:17 PM</div>
                     </div>
                  </div>

                  <div className="chat-msg-sent-layout">
                     <div className="chat-msg-sent">
                        <span>Lorem ipsum dolor sit amet, consectetur adipiscing elit </span> <br />
                        <div className="chat-time">04:17 PM &nbsp;&nbsp; <i className="bx bx-check-double" style={{ color: '#31dde4', verticalAlign: 'middle', fontSize: '23px' }}></i></div>
                     </div>
                  </div>



               </div>

               <div className="chat-box-layout">
                  <div className="chat-msg-box">
                     <div className="chat-smiley">
                        <i className="bx bxs-smiley-happy"></i>
                     </div>

                     <div className="chat-input">
                        <input type="text" className="chat-input-message" placeholder="Type a Message here ..." />
                     </div>

                     <div>
                        <i className="bx bx-paper-plane chat-enter"></i>
                     </div>
                  </div>
               </div>
            </div>

         </>

      );
   }
}

export default ChatSection;