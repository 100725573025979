import React, { useState } from 'react';
import Checkbox from 'react-checkbox-component'
import Modal from 'react-awesome-modal';


function RegisterCheckBox({ label, isChecked, onValChange, ...rest }) {
  const [visible, setVisible] = useState(false);

  const openModal = () => {
    setVisible(true)
  }

  const closeModal = () => {
    setVisible(false)
  }


  return (
    <div className="form-row" style={{ marginTop: '15px', alignItems: 'center' }}>
      <div className="col-md-1">
        <Checkbox size="small" isChecked={isChecked} onChange={onValChange} color="#fc14bb" />
      </div>

      <div className="col-md-11">
        <label htmlFor="checkbox1" style={{ fontSize: '12.8px', fontWeight: 300, marginBottom: '0px' }}><a onClick={() => openModal()} href="#">{label}</a>
        </label>
      </div>

      <section>
        <Modal visible={visible} width="400" height="300" effect="fadeInUp" onClickAway={() => closeModal()}>
          <div style={{ padding: '20px' }}>
            <h1>Terms And Conditions</h1>
            <p>By Check Approve With Register , This Consider Taht You Agree For All Below Constions And Terms:</p>
            <a href="#;" onClick={() => closeModal()}>Close</a>
          </div>
        </Modal>
      </section>
    </div>
  );
}
export default RegisterCheckBox;