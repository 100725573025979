import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalFooter, ModalHeader } from 'reactstrap';
import { removeGroup, addGroup, fetchError } from '../../../../../actions';
import { makeid, initialGroupCellItemBody } from '../../../../../util/index';
import { exportExcelHeader, exportGroup } from '../../../../../util/ExportToExcel';
import { initialWorkspaceBoardGroupData } from '../../../../../constants/Dump';
import ReactExport from 'react-data-export';
import { getMyPermission, getMyGroupEditPermission } from '../../../../../util/permission';
import PeopleItemAdd from './restrictGroupMembers';
import GroupColorAdd from './GroupColorAdd';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

class GroupEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            isModalOpen: false,
            title: this.props.groupTitle,
            actionPermission: 'view',
          
         
        }
        this.dropdownContainer = React.createRef();
        this.modalEl = React.createRef();
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) this.setState({ open: false });
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
      
        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
         
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
     
        
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
           
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
       
        
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }
    
    isBoardAdmin = () => {
   
        let members = this.state.boardMembers;
        let Index = members.findIndex(member => member._id === this.authUser._id);
        if (Index == -1) {
            return false;
        }
        if (members[Index].is_bAdmin) {
            return true;
        } else {
            return false;
        }
    }
    closeModal = () => {
        this.setState({ isModalOpen: false })
    }

    addGroup = () => {
        const boardID = this.props.boardData.boardID;
        if (boardID !== '') {
            const group = this.props.boardData.data;
            let firstGroup;
            if (group.length > 0) {
                firstGroup = group[0];
            } else {
                firstGroup = initialWorkspaceBoardGroupData[0];
            }

            const newGroupItemHeader = firstGroup.groupItems[0];

            const groupItemHeaderCellItems = [];
            const groupItemBodyCellITems = [];
            for (let i = 0; i < newGroupItemHeader.cellItems.length; i++) {
                let body = initialGroupCellItemBody(newGroupItemHeader.cellItems[i].key);
                let newCellHeader = { _id: makeid('col', 7), key: newGroupItemHeader.cellItems[i].key, body: newGroupItemHeader.cellItems[i].body, columnId: newGroupItemHeader.cellItems[i].columnId }
                let newCellItem = { _id: makeid('col', 7), key: newGroupItemHeader.cellItems[i].key, body: body, columnId: newGroupItemHeader.cellItems[i].columnId };
                groupItemHeaderCellItems.push(newCellHeader);
                groupItemBodyCellITems.push(newCellItem);
            }

            const newGroup = {
                _id: makeid('group', 3),
                label: 'New Group',
                groupItems: [{
                    _id: makeid('row', 5),
                    title: "",
                    category: "header",
                    cellItems: groupItemHeaderCellItems
                }, {
                    _id: makeid('row', 5),
                    title: "New Item",
                    category: 'body',
                    cellItems: groupItemBodyCellITems
                }]
            }

            this.props.addGroup({
                boardID: boardID,
                newGroup: newGroup,
                addGroupIndex: 0
            })
        }
    }

    duplicateGroup = () => {
        const boardID = this.props.boardData.boardID;
        const thisGroup = this.props.boardData.data[this.props.groupInd];
        const newGroup = { ...thisGroup, _id: makeid('group', 3), label: "Copy of " + thisGroup.label };
        this.props.addGroup({
            boardID: boardID,
            newGroup: newGroup,
            addGroupIndex: this.props.groupInd + 1
        });
    }

    exportToExcel = () => {
        const thisGroup = this.props.boardData?.data[this.props.groupInd];
        const multiDataSet = [
            ...exportExcelHeader(this.props.boardData?.boardTitle, this.props.boardData?.boardDescription),
            ...exportGroup(thisGroup)
        ];

        return multiDataSet;
    }

    render() {
        return (
            <>


 {/*<i data-toggle="collapse" href="#table-collapse" className="bx bx-chevron-right collapse-icon table-collapse-icon" style={{fontSize: '18px', height: '18px', 'width' : '18px' , 'padding' : '1px 1px'  ,color: '#ffffff', 
            backgroundColor: '#707070', borderRadius: '20px', position: 'relative', top: '10px'}} />


<div class="dropdown table-drop" ref={this.dropdownContainer}>
  <a class="btn dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
  onClick={() => {
    console.log('llp1',getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members));
    if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members)) {
        this.setState({ open: !this.state.open })
    } else {
        this.props.fetchError("You don't have permission. You can only view.")
    }
}}
  >
    <i className='bx bx-dots-vertical-rounded' style={{ fontSize: '18px', color: '#ffffff' , 'backgroundColor'  : '#4189F6' , 'borderRadius'  : '20px' }} ></i>
  </a>

  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
  
                             <div className="dropdown">
                                <button className="dropdown-toggle dropdown-item btn-group-action" data-toggle="dropdown" data-hover="dropdown"  aria-haspopup="true" aria-expanded="false">
                                <i className="fal fa-ban" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>  Restrict Members
                                </button>
                                <div className="dropdown-menu">
                                <form>
                               
                                    <PeopleItemAdd 
                                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                                    isBoardAdmin={this.props.isBoardAdmin}
                                    groupItem={this.props.boardData.data[this.props.groupInd]}/>
                              
                                </form>
                                
                                </div>
                                </div>


                                <div className="dropright show">
                                <button className="dropdown-toggle dropdown-item btn-group-action" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fal fa-square" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>  Add Colors
                                </button>
                                <div className="dropdown-menu border-radius rounded">
                                <form>
                               
                                    <GroupColorAdd 
                                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                                    isBoardAdmin={this.props.isBoardAdmin}
                                    groupItem={this.props.boardData.data[this.props.groupInd]}
                                    groupInd={this.props.groupInd}
                                    />
                                       
                                </form>
                                
                                </div>
                                </div>



                            <button className="dropdown-item btn-group-action"
                                style={{ paddingRight: '50px' }}
                                onClick={() => {
                                    this.setState({ open: false })
                                    this.addGroup()
                                }}
                            >
                                <i className="fal fa-plus-circle" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>
                                Add Group
                            </button>


                            <button className="dropdown-item btn-group-action"
                                style={{ paddingRight: '50px' }}
                                onClick={() => {
                                    this.setState({ open: false })
                                    this.duplicateGroup()
                                }}
                            >
                                <i className="fal fa-copy" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>
                                Duplicate this Group
                            </button>


                            <button className="dropdown-item btn-group-action"
                                style={{ paddingRight: '50px' }}
                                onClick={() => {
                                    this.setState({ open: false })
                                    this.props.renameGroup()
                                }}
                            >
                                <i className="fal fa-pencil" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>
                                Rename Group
                            </button>

                            <ExcelFile element={
                                <div className="dropdown-item btn-group-action" style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        this.setState({ open: false })
                                    }}
                                >
                                    <i className="fal fa-file-excel" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>
                                    Export to Excel
                                    </div>
                            }>
                                <ExcelSheet dataSet={this.exportToExcel()} name="Organization" />
                            </ExcelFile>


                            <button className="dropdown-item btn-group-action"
                                style={{ paddingRight: '50px' }}
                                onClick={() => {
                                    this.setState({ open: false })
                                    this.setState({ isModalOpen: true })
                                }}
                            >
                                <i className="fal fa-trash" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }} ></i>Delete
                            </button>


  
  </div>

  <Modal isOpen={this.state.isModalOpen}>
                    <ModalHeader toggle={() => this.setState({ isModalOpen: false })}>
                        Delete <b>{this.state.title}</b> group?
                    </ModalHeader>
                    <ModalFooter>
                        <button className='btn btn-light' onClick={() => { this.setState({ isModalOpen: false }) }}>Cancel</button>{' '}
                        <button className='btn btn-primary'
                            onClick={() => {
                                this.props.removeGroup({
                                    groupInd: this.props.groupInd,
                                    groupId: this.props.id,
                                    boardID: this.props.boardData.boardID
                                })
                                this.setState({ isModalOpen: false })
                            }}
                        >Delete</button>
                    </ModalFooter>
                </Modal>

</div>

 */}








          <i data-toggle="collapse" href="#table-collapse" className="bx bx-chevron-right collapse-icon table-collapse-icon" style={{fontSize: '18px', height: '18px', 'width' : '18px' , 'padding' : '1px 1px'  ,color: '#ffffff', 
            backgroundColor: '#707070', borderRadius: '20px', position: 'relative', top: '10px'}} />

            <div className="dropdown table-drop" ref={this.dropdownContainer} style={{ 'width' : '33px' }}>
                <button className="btn dropdown-toggle" style={{ paddingLeft: '10px' }} type="button" onClick={() => {
                    console.log('llp1',getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members));
                    if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members)) {
                        this.setState({ open: !this.state.open })
                    } else {
                        this.props.fetchError("You don't have permission. You can only view.")
                    }
                }}>
                    <i className='bx bx-dots-vertical-rounded' style={{ fontSize: '18px', color: '#ffffff' , 'backgroundColor'  : '#4189F6' , 'borderRadius'  : '20px' }} ></i>
                </button>
                {this.state.open ? (

                    (this.props.isWorkspaceAdmin || this.props.isWorkspaceOwner || this.props.isBoardAdmin)?(
                    <>
                        <ul className={`dropdown-menu multi-level animate slideIn board-group-head-ellipsis  ${this.state.open ? `show` : ``}`} role="menu" aria-labelledby="dropdownMenu"
                            style={{ boxShadow: '0 4px 17px 6px rgba(0, 0, 0, 0.1)', width: "264px" }}>
                            <div className="dropright">
                                <button className="dropdown-toggle dropdown-item btn-group-action" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fal fa-ban" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>  Restrict Members
                                </button>
                                <div className="dropdown-menu">
                                <form>
                               
                                    <PeopleItemAdd 
                                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                                    isBoardAdmin={this.props.isBoardAdmin}
                                    groupItem={this.props.boardData.data[this.props.groupInd]}/>
                              
                                </form>
                                
                                </div>
                                </div>

                                <div className="dropright">
                                <button className="dropdown-toggle dropdown-item btn-group-action" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i className="fal fa-square" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>  Add Colors
                                </button>
                                <div className="dropdown-menu border-radius rounded">
                                <form>
                               
                                    <GroupColorAdd 
                                    isWorkspaceOwner={this.props.isWorkspaceOwner}
                                    isWorkspaceAdmin={this.props.isWorkspaceAdmin}
                                    isBoardAdmin={this.props.isBoardAdmin}
                                    groupItem={this.props.boardData.data[this.props.groupInd]}
                                    groupInd={this.props.groupInd}
                                    />
                                       
                                </form>
                                
                                </div>
                                </div>

                            <button className="dropdown-item btn-group-action"
                                style={{ paddingRight: '50px' }}
                                onClick={() => {
                                    this.setState({ open: false })
                                    this.addGroup()
                                }}
                            >
                                <i className="fal fa-plus-circle" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>
                                Add Group
                            </button>

                            <button className="dropdown-item btn-group-action"
                                style={{ paddingRight: '50px' }}
                                onClick={() => {
                                    this.setState({ open: false })
                                    this.duplicateGroup()
                                }}
                            >
                                <i className="fal fa-copy" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>
                                Duplicate this Group
                            </button>

                            
                            <button className="dropdown-item btn-group-action"
                                style={{ paddingRight: '50px' }}
                                onClick={() => {
                                    this.setState({ open: false })
                                    this.props.renameGroup()
                                }}
                            >
                                <i className="fal fa-pencil" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>
                                Rename Group
                            </button>

                            <ExcelFile element={
                                <div className="dropdown-item btn-group-action" style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        this.setState({ open: false })
                                    }}
                                >
                                    <i className="fal fa-file-excel" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>
                                    Export to Excel
                                    </div>
                            }>
                                <ExcelSheet dataSet={this.exportToExcel()} name="Organization" />
                            </ExcelFile>

                            <button className="dropdown-item btn-group-action"
                                style={{ paddingRight: '50px' }}
                                onClick={() => {
                                    this.setState({ open: false })
                                    this.setState({ isModalOpen: true })
                                }}
                            >
                                <i className="fal fa-trash" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }} ></i>Delete
                            </button>
                        </ul>
                    </>):
                    (<>
                        <ul className={`dropdown-menu multi-level animate slideIn ${this.state.open ? `show` : ``}`} role="menu" aria-labelledby="dropdownMenu"
                        style={{ marginLeft: '10px', boxShadow: '0 4px 17px 6px rgba(0, 0, 0, 0.1)', width: "264px" }}>

                       
                        <ExcelFile element={
                            <div className="dropdown-item btn-group-action" style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    this.setState({ open: false })
                                }}
                            >
                                <i className="fal fa-file-excel" style={{ verticalAlign: 'middle', fontSize: '18px', color: '#000000', paddingRight: '10px' }}></i>
                                Export to Excel
                                </div>
                        }>
                            
                            <ExcelSheet dataSet={this.exportToExcel()} name="Organization" />
                        </ExcelFile>

                    </ul>
                     </>)
               
                ) : ''}

                <Modal isOpen={this.state.isModalOpen}>
                    <ModalHeader toggle={() => this.setState({ isModalOpen: false })}>
                        Delete <b>{this.state.title}</b> group?
                    </ModalHeader>
                    <ModalFooter>
                        <button className='btn btn-light' onClick={() => { this.setState({ isModalOpen: false }) }}>Cancel</button>{' '}
                        <button className='btn btn-primary'
                            onClick={() => {
                                this.props.removeGroup({
                                    groupInd: this.props.groupInd,
                                    groupId: this.props.id,
                                    boardID: this.props.boardData.boardID
                                })
                                this.setState({ isModalOpen: false })
                            }}
                        >Delete</button>
                    </ModalFooter>
                </Modal>

            </div> 
            </>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData };
}

const mapDispatchToProps = { removeGroup, addGroup, fetchError };

export default connect(mapStateToProps, mapDispatchToProps)(GroupEvent);