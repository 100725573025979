/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import userDataService from '../../data-services/users-data-services'
import http from '../services/http-service'
import config from '../services/config.json'
import { propTypes } from 'react-bootstrap/esm/Image'

function People(params) {
  const [list, setList] = useState([])
  const [people, setPeople] = useState([])
  const [search, setSearch] = useState(null);

  useEffect(() => {
    http
      .get(config.apiEndPoint + userDataService.getallUser)
      .then(res => {
        console.log(res.data)
        setList(res.data)
      })
  }, [])

  const searchSpace = (event) => {
    let keyword = event.target.value;
    setSearch(keyword)
  }


  const items = list.filter((data) => {
    if (search == null)
      return data
    else if ((data.firstname + " " + data.lastname).toLowerCase().includes(search.toLowerCase()) ||
      (data.firstname + " " + data.lastname).toLowerCase().includes(search.toLowerCase())) {
      return data
    }
  }).map(data => {
    return (
      <>
        <a
          className='dropdown-item'
          onClick={() => {
            let id = data._id
            setPeople([
              ...people,
              {
                name: data.firstname + ' ' + data.lastname,
                id: data._id
              }
            ])
            setList(list.filter(item => item._id !== id))
            params.setMeetingMembers([
              ...people,
              {
                name: data.firstname + ' ' + data.lastname,
                id: data._id
              }
            ])
          }}
        >
          <div className='avatar mr-1 bg-primary bg-lighten-2'>
            <span
              className='avatar-content'
              style={{ width: '25px', height: '25px' }}
            >
              {data.firstname.charAt(0) + data.lastname.charAt(0)}
            </span>
          </div>{' '}
          {data.firstname + ' ' + data.lastname}
        </a>
      </>
    )
  })
  return (
    <>
      <ul
        className='list-unstyled users-list m-0 d-flex align-items-center'
        data-toggle='dropdown'
        aria-haspopup='true'
        aria-expanded='false'
      >
        {people.length <= 0 && (
          <li className='avatar pull-up my-0 bg-success'>
            <span className='avatar-content' style={{ width: '25px', height: '25px' }}>
              <i className='bx bx-user'></i>
            </span>
          </li>
        )}

        {people.map(item => (
          <li className='avatar pull-up my-0 bg-success' >
            <span className='avatar-content' style={{ width: '25px', height: '25px', textTransform: 'uppercase' }}>
              {item.name.split(' ').map(ltr => ltr.charAt(0))}
            </span>
          </li>
        ))}
      </ul>

      <div
        className='dropdown-menu dropdown-menu-right modal-smt'
        aria-labelledby='folder'
        x-placement='bottom-end'
        style={{
          position: ' absolute',
          ' will-change': 'transform',
          transform: 'translate3d(150px, 20px, 0px)',
          'height':'200px',
          'overflow':'auto'
        }}
      >
        {people.map(item => (
          <div className='chip mr-1'>
            <div className='chip-body'>
              <div className='avatar bg-primary'>
                <span>{item.name.split(' ').map(ltr => ltr.charAt(0))}</span>
              </div>
              <span className='chip-text'>{item.name}</span>
              <div
                className='chip-closeable'
                style={{ 'min-height': '17px', 'min-width': '17px' }}
                onClick={() => {
                  let id = item.id
                  setPeople(people.filter(items => items.id !== id))
                  setList([
                    ...list,
                    {
                      _id: id,
                      firstname: item.name.split(' ')[0],
                      lastname: item.name.split(' ')[1]
                    }
                  ])
                  params.setMeetingMembers(people.filter(items => items.id !== id))
                }}
                
                >
                <i className='bx bx-x'></i>
              </div>
            </div>
          </div>
        ))}

        <input
          type='text'
          style={{ border: 'none', width: '100%' }}
          placeholder='Enter name'
          onChange={(e) => searchSpace(e)}
        />
        {/* All Items Name */}
        <div className='dataset_title'>
          <span>People</span>
        </div>
        {items}
      </div>
    </>
  )
}
export default People
