import React, { useState } from 'react'
import DatePicker from 'react-datepicker'
import People from '../people/people'
import meetingDataService from '../../data-services/meeting-data-services'
import http from '../services/http-service'
import config from '../services/config.json'
import { el } from 'date-fns/locale'
function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
function Toolbar(props) {
    const [startDate, setStartDate] = useState(new Date());
    const [members, setMembers] = useState([])
    const [confname, setConfName] = useState('')
    const [room, setRoom] = useState(makeid(8))
    const setMeetingMembers = (member) => {
        //console.log(member)
        setMembers(member)
    }
    return (
        <div className="row dash-blue fixed-top">
           
            <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
                <div>
                    <div className="inner-section">
                        <h3>E-Connect</h3>
                        {/* <div style={{ marginTop: '-10px' }}>
              <p className="mt-1">Discover</p>
            </div> */}
                    </div>
                </div>
            </div>
            <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
                <button class="btn btn-danger" style={{ 'float': 'right' }} data-toggle="modal" data-target="#default">+Create Meeting</button>
            </div>
            <div class="modal fade text-left" id="default" tabindex="-1" aria-labelledby="myModalLabel1"
                style={{ "display": "none" }} aria-hidden="true">
                <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h3 class="modal-title" id="myModalLabel1">Create Meeting</h3>
                            <button type="button" class="close rounded-pill" data-dismiss="modal" aria-label="Close">
                                <i class="bx bx-x"></i>
                            </button>
                        </div>
                        <div class="modal-body" style={{ 'min-height': '300px' }}>
                            <label style={{ 'padding': '0px' }}>Pick Date: </label>
                            <div class="form-group d-flex">
                                <DatePicker
                                    selected={startDate}
                                    onChange={date => setStartDate(date)}
                                    showTimeSelect
                                    timeFormat="HH:mm"
                                    timeIntervals={15}
                                    timeCaption="time"
                                    dateFormat="MMMM d, yyyy h:mm aa"
                                />
                            </div>
                            <label style={{ 'padding': '0px' }}>Title: </label>
                            <div class="form-group">
                                <input type="text" placeholder="Title"
                                    class="form-control" value={confname} onChange={(e) => {
                                        setConfName(e.target.value)
                                    }} />
                            </div>
                            <label style={{ 'padding': '0px' }}>Room Name: </label>
                            <div class="form-group">
                                <input type="text" placeholder="Room Name"
                                    class="form-control" value={room} onChange={(e) => {
                                        setRoom(e.target.value)
                                    }} />
                            </div>

                            <label style={{ 'padding': '0px' }}>Teams: </label>
                            <div class="form-group">
                                <People setMeetingMembers={setMeetingMembers} />
                            </div>

                        </div>
                        <button class="btn btn-danger" onClick={() => {
                            if(confname!=''){
                                var d = startDate;
                                var milliseconds = d.getTime();
                                const hours = `0${new Date(milliseconds).getHours() - 1}`.slice(-2);
                                const minutes = `0${new Date(milliseconds).getMinutes()}`.slice(-2);
                                const time = `${hours}:${minutes}`
                                const date = d.getDate();
                                const month = d.getMonth();
                                const year = d.getFullYear();
                                const dates = year + "-" + (month + 1) + "-" + date;
                                let result = members.map(i=>i.id)
                                let data = {
                                    scheduledate:new Date(dates),
                                    scheduletime:time,
                                    meetingname:room,
                                    description:'',
                                    meetingtype:'Scheduled',
                                    usertype:'Registerd',
                                    userid:JSON.parse(localStorage.getItem('authUser'))._id,
                                    members:result,
                                    confname:confname
                                }
                                http
                                .post(config.apiEndPoint + meetingDataService.schedule,data)
                                .then(res => {
                                  alert(res.data.message)
                                  if(res.data.status==200){
                                     props.getMeetings()
                                     setStartDate(new Date())
                                     setConfName('')
                                     setRoom(makeid(8))
                                  }
                                })
                            }else{
                                alert('Please enter a title')
                            }
                            
                        }}>Create Meeting</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Toolbar