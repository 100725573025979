import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import axios from '../../../../../util/Api';
import { initialWorkspaceBoardGroupData, cellStatusInitalData } from '../../../../../constants/Dump';
import { showMessage, changeWorkspaceBoard, fetchStart, intialize, reloadWorkspace, updateWorkspaceBoard } from '../../../../../actions';

class SubworkspaceAction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false,
            header: "",
            boardName: "",
            boardDecs: "",
            modalContent: "",
            actionType: "",
            moveOpen: false,
            workspaces: null,
            open: false,
            isWorkspaceOwner: false,
            isWorkspaceAdmin: false
        }
        this.modalContainer = React.createRef();
        this.dropdownContainer = React.createRef();
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false });
        }
    }

    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
        // axios.get('/workspace/getAllWorkspaceWithoutPopulate').then(({ data }) => {
        //     this.setState({ workspaces: data.workspaces })
        // }).catch(err => {
        //     console.error(err)
        // })
    
        if (this.authUser._id == this.props.category.CreatedBy) {
            this.setState({ isWorkspaceOwner: true })
            this.setState({ isWorkspaceAdmin: true })
        }
        if (this.props.category.admins.includes(this.authUser._id)) {
            this.setState({ isWorkspaceAdmin: true })
        }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    createWorkspaceBoard = () => {
        this.setState({
            isModalOpen: true,
            header: "Create New Workspace Board",
            modalContent: "Board",
            actionType: 'create_board',
            open: false
        })
    }

    btnAction = () => {
        this.setState({ open: true })

        axios.get('/workspace/getAllWorkspaceWithoutPopulate').then(({ data }) => {
            this.setState({ workspaces: data.workspaces })
        }).catch(err => {
            console.error(err)
        })
    }
    createNewWorkspaceBoard = () => {
        if (this.state.boardName !== "") {
            this.setState({
                isModalOpen: false
            });
           
            this.props.fetchStart();
            let admins=[];
            if(this.props.category.CreatedBy != this.authUser._id){
                admins.push(this.props.category.CreatedBy);
            }
            
            axios.post('/workspace/createBoard', {
                subworkspace_id: this.props.subCategory._id,
                title: this.state.boardName,
                description: this.state.boardDecs,
                group: initialWorkspaceBoardGroupData,
                cellStatusItems: cellStatusInitalData,
                wsAdmins: admins.concat(this.props.category.admins)
            }).then(({ data }) => {
                if (data.success === 200) {
                    this.props.showMessage(`${data.workspaceBoard.title} created successfully!`);
                    this.props.updateWorkspaceBoard({
                        workspaceBoard: data.workspaceBoard,
                        workspaceID: this.props.boardData.workspaceID
                    })

                    this.setState({ boardName: '', boardDecs: '' })
                }
            }).catch(err => {
                console.log("Error===>", err)
            })
        }
    }

    remove = () => {
        this.setState({
            header: `Delete ${this.props.subCategory.title}?`,
            actionType: 'remove',
            isModalOpen: true,
            open: false
        })
    }

    removeSubworkspace = () => {
        this.setState({ isModalOpen: false });
        this.props.fetchStart();
        axios.post('/workspace/removeSubworkspace', {
            workspaceID: this.props.category._id,
            subworkspaceID: this.props.subCategory._id
        }).then(({ data }) => {
            if (data.success === 200) {
                this.props.showMessage(`${data.subworkspaceTitle} deleted successfully!`);
                this.props.intialize();
            }
        }).catch(err => {
            console.error(err)
        })

    }

    renameSubworkspace = () => {
        this.setState({
            modalContent: 'Subworkspace',
            actionType: 'rename',
            header: `Edit ${this.props.subCategory.title}`,
            boardName: this.props.subCategory.title,
            isModalOpen: true,
            open: false
        })
    }

    renameSave = () => {
        this.setState({ isModalOpen: false, boardName: "" });
        this.props.fetchStart();
        axios.post('/workspace/renameSubworkspace', {
            id: this.props.subCategory._id,
            title: this.state.boardName
        }).then(({ data }) => {
            if (data.success === 200) {
                this.props.showMessage('Subworkspace title changed successfully!');
                this.props.reloadWorkspace({
                    reload: !this.props.boardData.loadWorkspace
                })
            }
        }).catch(err => {
            console.error(err)
        })
    }

    moveSubworkspace = (destinationId) => {
        this.setState({ open: false })
        this.props.fetchStart();
        axios.post('/workspace/moveSubworkspace', {
            subworkspaceId: this.props.subCategory._id,
            sourceWorkspaceId: this.props.category._id,
            destinationId: destinationId
        }).then(({ data }) => {
            if (data.success === 200) {
                this.props.showMessage(`${data.subworkspaceTitle} moved successfully!`);
                this.props.reloadWorkspace({
                    reload: !this.props.boardData.loadWorkspace
                })
            }
        }).catch(err => {
            console.error(err)
        })
    }

    createSubworkspace = () => {
        this.setState({
            modalContent: 'Subworkspace',
            actionType: 'create_subworkspace',
            header: 'Create New Subworkspace',
            boardName: '',
            isModalOpen: true,
            open: false
        })
    }

    createNewSubworkspace = () => {
        if (this.state.boardName !== "") {
            axios.post('/workspace/subWorkspaceCreateNewSub', {
                title: this.state.boardName,
                subworkspaceId: this.props.subCategory._id,
                workspaceId: this.props.category._id
            }).then(({ data }) => {
                if (data.success === 200) {
                    this.props.showMessage('New Subworkspace created successfully!');
                    this.props.reloadWorkspace({
                        reload: !this.props.boardData.loadWorkspace
                    })
                }
            }).catch(err => {
                console.log("Error: ", err)
            })
        }

        this.setState({ isModalOpen: false, boardName: "" });

    }
    render() {
        return (
            <>
           
                <div className="d-flex just-content-sb">
                    <a className="nav-link collapsed p-1"
                        href={`#board_${this.props.categoryInd}_${this.props.subCategoryInd}`}
                        style={{ width: '100%' }}
                        data-toggle="collapse"
                        data-target={`#board_${this.props.categoryInd}_${this.props.subCategoryInd}`}>
                        <i className="bx bxs-folder"></i> {ReactHtmlParser(this.props.subCategory.title)}
                    </a>

                    
                    <div className="nav-toggle-workspace" ref={this.dropdownContainer}>
                       {(this.state.isWorkspaceOwner || this.state.isWorkspaceAdmin) && <button
                            className="btn dropdown-toggle btn-nav-toggle"
                            type="button"
                            onClick={() => this.btnAction()}
                            id={`dropdownMenu_${this.props.subCategoryInd}`}
                            style={{ display: 'flex' }}>
                            <i className="bx bx-dots-vertical-rounded dot-icons" ></i>
                        </button>

                        }
                        {this.state.open ? (
                            <ul className={`dropdown-menu multi-level animate SlideIn first-sub-workspace ${this.state.open ? 'show' : ''}`} role="menu" >
                                <li className="dropdown-item">
                                    <button className="btn-sub-nav"
                                        onClick={() => this.renameSubworkspace()}
                                    >
                                        <i className="fal fa-pencil icon-style" style={{ fontSize: '14px' }}></i>&nbsp;&nbsp;Rename</button>
                                </li>
                              {this.state.isWorkspaceAdmin && <li className="dropdown-item" style={{ position: 'relative' }}
                                    onMouseLeave={() => { this.setState({ moveOpen: false }) }}>
                                    <button className="btn-sub-nav sub-menu-nav"
                                        onMouseEnter={() => { this.setState({ moveOpen: true }) }}
                                    >
                                        <i className="fal fa-folder icon-style" style={{ fontSize: '14px' }}></i>&nbsp;&nbsp; Move to Workspace
                                        <i className="fal fa-chevron-right icon-style" aria-hidden="true" style={{ fontSize: '13px', float: 'right', marginTop: '10px' }}></i>
                                    </button>
                                    {this.state.workspaces !== null && this.state.workspaces.length > 1 ? (
                                        <ul className={`dropdown-menu multi-level first-subworkspace-dropdown ${this.state.moveOpen ? 'show' : ''}`} >
                                            {this.state.workspaces
                                             .filter(workspace => workspace.CreatedBy == this.authUser._id)
                                            .map((workspace, index) => (
                                                workspace._id.toString() !== this.props.category._id.toString() ?
                                                    (
                                                        <li className='dropdown-item' key={index}>
                                                            <button className='btn-sub-nav' onClick={() => this.moveSubworkspace(workspace._id)}>
                                                                <i className="fal fa-chevron-right"></i> {workspace.title}
                                                            </button>
                                                        </li>
                                                    ) : ''
                                            ))
                                            }
                                        </ul>
                                    ) : ''}

                                </li>
                        }
                                <li className="dropdown-item">
                                    <button className="btn-sub-nav"
                                        onClick={() => this.createSubworkspace()}
                                    >
                                        <i className="fal fa-plus-circle icon-style" style={{ fontSize: '14px', color: '#000000' }}></i>  &nbsp;Add New Subworkspace</button>
                                </li>
                                <li className="dropdown-item">
                                    <button className="btn-sub-nav"
                                        onClick={() => this.createWorkspaceBoard()}
                                    >
                                        <i className="fal fa-plus-circle  icon-style" style={{ fontSize: '14px', color: '#000000' }}></i>&nbsp;Add New Workspace Board</button>
                                </li>

                                <li className="dropdown-item">
                                    <button className="btn-sub-nav"
                                        onClick={() => this.remove()}
                                    >
                                        <i className="fal fa-trash icon-style" style={{ fontSize: '14px', color: '#000000' }}></i>&nbsp;&nbsp;Delete</button>
                                </li>
                            </ul>
                        ) : ''}

                    </div>
                </div>
                                        
                <Modal isOpen={this.state.isModalOpen} ref={this.modalContainer}>
                    <ModalHeader toggle={() => this.setState({ isModalOpen: false })}>
                        {this.state.header}
                    </ModalHeader>
                    <ModalBody style={{ display: this.state.actionType === 'remove' ? 'none' : 'block' }}>
                        <div className='row'>
                            <label className='col-4' htmlFor='workspace-title'>{this.state.modalContent} Title</label>
                            <div className='col-8'>
                                <input className='form-control' value={this.state.boardName} id='workspace-title' onChange={(e) => { this.setState({ boardName: e.target.value }) }} />
                            </div>
                        </div>
                        <div className='row mt-2' style={{ display: this.state.actionType === 'create_board' ? 'flex' : 'none' }}>
                            <label className='col-4' htmlFor='workspace-desc'>{this.state.modalContent} Description</label>
                            <div className='col-8'>
                                <textarea className='form-control' value={this.state.boardDecs} id='workspace-desc' onChange={(e) => { this.setState({ boardDecs: e.target.value }) }} ></textarea>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className='btn btn-light' onClick={() => { this.setState({ isModalOpen: false }) }}>Cancel</button>{' '}
                        <button className='btn btn-primary'
                            style={{ display: this.state.actionType === 'create_board' ? 'block' : 'none' }}
                            onClick={() => this.createNewWorkspaceBoard()}
                        >Create</button>
                        <button className='btn btn-primary'
                            style={{ display: this.state.actionType === 'create_subworkspace' ? 'block' : 'none' }}
                            onClick={() => this.createNewSubworkspace()}
                        >Create</button>
                        <button className='btn btn-danger'
                            style={{ display: this.state.actionType === 'remove' ? 'block' : 'none' }}
                            onClick={() => this.removeSubworkspace()}
                        >Delete</button>
                        <button className='btn btn-success'
                            style={{ display: this.state.actionType === 'rename' ? 'block' : 'none' }}
                            onClick={() => this.renameSave()}
                        ><i className="fa fa-floppy-o"></i> Save</button>
                    </ModalFooter>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { showMessage, changeWorkspaceBoard, fetchStart, intialize, reloadWorkspace, updateWorkspaceBoard };

export default connect(mapStateToProps, mapDispatchToProps)(SubworkspaceAction);