import React from "react";
import Form from "../common/Form.jsx";

class Modal extends Form {
  render() {
    const {
      companyToValidate,
      onChangeCompanyCode,
      onChangeName,
      onChangeCompanyPhone,
      onChangeCompanyEmail,
      onChangeCompanyTRN,
      selectedOption_businessCategoty,
      handleChange_businessCategory,
      options_businessCategory,
      onChangeStreetAdress,
      onChangeLocation,
      onChangeArea,
      onChangeBuilding,
      selectedOption_country,
      options_country,
      selectedOption_state,
      options_state,
      selectedOption_city,
      options_city,
      onChangeZipCode,
      onChangeFirstName,
      onChangeLastName,
      onChangePersonalEmail,
      onChangePersonalPhoneNumber,
      onChangeDesignation,
      selectedOption_gender,
      options_gender,
      handleChange_country,
      handleChange_state,
      handleChange_city,
      handleChange_gender,
      SaveOrUpdate,

      errorcompanyName,
      errorCompanyCode,
      errorCompanyEmail,
      errorCompanyPhone,
      errorBusinessCategoryId,
      errorCompanyTRN,
      errorStreetAdress,
      errorLocation,
      errorArea,
      errorBuilding,
      errorCountryId,
      errorStateId,
      errorCityId,
      errorZipCode,
      errorFirstName,
      errorLastName,
      errorPersonalEmail,
      errorPersonalPhoneNumber,
      errorDesignation,
      errorGender,
      isUpdate,
    } = this.props;

    return (
      <div className="modal fade" id="modal-Country">
        <div className="modal-dialog modal-lg modal-plus">
          <div className="modal-content">
            {this.renderModalHeaderTitle("Add Company Details")}
            <div className="modal-body" style={{ marginLeft: '20px', marginRight: '20px' }}>
              <form className="form-horizontal" name={this.returnFormName("companyName")}>
                <div className="form-group row">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.CompanyCode,
                          "Company Code",
                          errorCompanyCode,
                          onChangeCompanyCode
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.Name,
                          "Company Name",
                          errorcompanyName,
                          onChangeName
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.CompanyPhone,
                          "Company Phone",
                          errorCompanyPhone,
                          onChangeCompanyPhone
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.CompanyEmail,
                          "Company Email",
                          errorCompanyEmail,
                          onChangeCompanyEmail
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.CompanyTRN,
                          "Company TRN",
                          errorCompanyTRN,
                          onChangeCompanyTRN
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      {/* <label className="col-md-6 col-12">Business Category<span>*</span></label> */}
                      <div className="form-label-group">
                        {this.renderSelectOptionsControl(
                          selectedOption_businessCategoty,
                          handleChange_businessCategory,
                          options_businessCategory,
                          "Business Category",
                          errorBusinessCategoryId
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">Adress Details</div>
                    <br />
                    <br />
                    <br />

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.StreetAdress,
                          "Street Adress",
                          errorStreetAdress,
                          onChangeStreetAdress
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.Location,
                          "Location",
                          errorLocation,
                          onChangeLocation
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.Area,
                          "Area",
                          errorArea,
                          onChangeArea
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.Building,
                          "Building",
                          errorBuilding,
                          onChangeBuilding
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderSelectOptionsControl(
                          selectedOption_country,
                          handleChange_country,
                          options_country,
                          "Country",
                          errorCountryId
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderSelectOptionsControl(
                          selectedOption_state,
                          handleChange_state,
                          options_state,
                          "State",
                          errorStateId
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderSelectOptionsControl(
                          selectedOption_city,
                          handleChange_city,
                          options_city,
                          "City",
                          errorCityId
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.ZipCode,
                          "Zip Code",
                          errorZipCode,
                          onChangeZipCode
                        )}
                      </div>
                    </div>

                    <div className="col-md-12">Contact Person Info</div>
                    <br />
                    <br />
                    <br />

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.FirstName,
                          "First Name",
                          errorFirstName,
                          onChangeFirstName
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.LastName,
                          "Last Name",
                          errorLastName,
                          onChangeLastName
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.PersonalEmail,
                          "Personal Email",
                          errorPersonalEmail,
                          onChangePersonalEmail
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.PersonalPhoneNumber,
                          "Personal Phone Number",
                          errorPersonalPhoneNumber,
                          onChangePersonalPhoneNumber
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderInput(
                          "text",
                          companyToValidate.Designation,
                          "Designation",
                          errorDesignation,
                          onChangeDesignation
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-label-group">
                        {this.renderSelectOptionsControl(
                          selectedOption_gender,
                          handleChange_gender,
                          options_gender,
                          "Gender",
                          errorGender
                        )}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      {this.renderSubmitButton(
                        "submit",
                        (errorcompanyName != null ||
                          errorCompanyCode != null ||
                          errorCompanyEmail != null ||
                          errorCompanyPhone != null ||
                          errorBusinessCategoryId != null ||
                          errorCompanyTRN != null ||
                          errorStreetAdress != null ||
                          errorLocation != null ||
                          errorArea != null ||
                          errorBuilding != null ||
                          errorCountryId != null ||
                          errorStateId != null ||
                          errorCityId != null ||
                          errorZipCode != null ||
                          errorFirstName != null ||
                          errorLastName != null ||
                          errorPersonalEmail != null ||
                          errorPersonalPhoneNumber != null ||
                          errorDesignation != null ||
                          errorGender != null) &&
                          isUpdate == false,
                        SaveOrUpdate,
                        "Save"
                      )}
                      {this.renderCancelButton()}
                    </div>

                    <div className="col-  md-6 col-12"></div>
                    <div className="col-md-12 col-lg-12 col-12">
                      <div
                        style={{
                          float: "left",
                          marginLeft: "-15px",
                          verticalAlign: "baseline",
                        }}
                      ></div>
                      <div
                        style={{ float: "right", marginRight: "-2px" }}
                      ></div>
                    </div>
                  </div>
                  <div className="clearfix" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
