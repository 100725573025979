import React, { createContext, useState , useEffect } from "react";
import config from '../../src/components/services/config.json';
import http from '../../src/components/services/http-service';
import globalsettingsDataService from "../data-services/globalsettings-data-services";
export const SettingsContext = createContext();
export const SettingsProvider = ({ children }) => {
 const [general,setGeneral] = useState({baseFilePath:'',pageSize:{generalList:0,taskList:0,userList:0,dashboard:0,discoverHorizondal:0,discoverVertical:0},
 erpIntegration:{dbName:'',conString:''}});
 const [email,setEmail] = useState({smtpGeneral:{smtpHost:'',smtpPort:0,smtpUserName:'',smtpPassword:''},
 smtpDueDateChange:{smtpHost:'',smtpPort:0,smtpUserName:'',smtpPassword:''},smtpAssigneeChange:{smtpHost:'',smtpPort:0,smtpUserName:'',smtpPassword:''}});
useEffect(() => {
    
    getSettingsAll();
    // return () => {
      
    // }
  }, []);
  const getSettingsAll = async () => {
    await http.get(config.apiEndPoint + globalsettingsDataService.getSettingsAll)
    .then((response) =>{

         let id=response.data.response[0]._id;
        setGeneral({...response.data.response[0].general});
        setEmail({...response.data.response[0].email});
    })
    .catch((error ) =>{
     console.log(error)
    })
   };

   const saveSettings = async (settingsData)=>{
           
       await http.post(config.apiEndPoint + globalsettingsDataService.saveSettings,settingsData)
       .then((response)=>{
          console.log(response.data.message);
       })
       .catch((error) =>{
           console.log(error)
       })
   }

   return (
    <SettingsContext.Provider value={{ general ,email ,saveSettings}}>
      {children}
    </SettingsContext.Provider>
  );

};
export default SettingsProvider;