import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"; //https://github.com/reactjs/react-tabs
import "react-tabs/style/react-tabs.css";
import QuickSearch from "./quick-search.jsx";
import "react-horizontal-strip-datepicker/dist/ReactHorizontalDatePicker.css";
import GlobalSearchBoard from "../workspace/components/board/global-search-board";
import FileSearch from "./file-search/files-search";
import PeopleSearch from "./people-search.jsx";
import "./src/css/high-light.css";
import "./card.css";
import "./src/css/style.css";
import axios from "../../util/Api";
import TagsSearch from "./tag-search/tags-search";
import MessageSearch from "./messages-search";
import { FaSearch } from "react-icons/fa";
import { GrTasks } from "react-icons/gr";
import { BiFileFind } from "react-icons/bi";
import { IoMdPeople } from "react-icons/io";
import { CgHashtag } from "react-icons/cg";
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

const searchData = [];

class GeneralSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSavedSearchChecked: false,
      isHorezintalDatePickerShow: false,
      selectedDate: "",
      tabIndex: 0,
      value: "",
      suggestions: [],
      inputHighLightKeyWords: [],
      content: "New Group",
      groups: [],
      groupItemsData: [],
      error: false,

      //Quick Search
      //Hot_Tag
      quickSearchDataTabIndexHotTag: null,
      searchNameDataHotTag: "",
      //MyFiles
      quickSearchDataTabIndexMyFiles: null,
      searchNameDataMyFiles: "",

      //IwasMentioned
      quickSearchDataTabIndexIwasMentioned: null,
      searchNameDataIwasMentioned: "",

      //IDidn't Reply
      quickSearchDataTabIndexIdidntReply: null,
      searchNameDataIDidntReply: "",

      //Saved Search
      quickSearchDataTabIndexSavedSearch: null,
      searchNameDataSavedSearch: "",
      quickSearchSavedType: "",
      quickSearchSavedObj: null,
    };
  }

  componentDidMount() {
    this._initDataCrossBoardsSearch();
  }

  handleTabHotTag = (quickSearchDataTabIndexHotTag, searchNameDataHotTag) => {
    this.setState({ quickSearchDataTabIndexHotTag });
    this.setState({ searchNameDataHotTag });
    this.setState({ tabIndex: quickSearchDataTabIndexHotTag });
  };

  handleTabMyFiles = (
    quickSearchDataTabIndexMyFiles,
    searchNameDataMyFiles
  ) => {
    this.setState({ quickSearchDataTabIndexMyFiles });
    this.setState({ searchNameDataMyFiles });
    this.setState({ tabIndex: quickSearchDataTabIndexMyFiles });
  };

  handleTabIwasMentioned = (
    quickSearchDataTabIndexIwasMentioned,
    searchNameDataIwasMentioned
  ) => {
    //empty didn't reply
    this.setState({ searchNameDataIDidntReply: "" });
    this.setState({ quickSearchDataTabIndexIwasMentioned });
    this.setState({ searchNameDataIwasMentioned });
    this.setState({ tabIndex: quickSearchDataTabIndexIwasMentioned });
  };

  handleTabIDidntReply = (
    quickSearchDataTabIndexIdidntReply,
    searchNameDataIDidntReply
  ) => {
    //empty was mentioned
    this.setState({ searchNameDataIwasMentioned: "" });
    this.setState({ quickSearchDataTabIndexIdidntReply });
    this.setState({ searchNameDataIDidntReply });
    this.setState({ tabIndex: quickSearchDataTabIndexIdidntReply });
  };

  handleTabSavedSearch = (
    quickSearchDataTabIndexSavedSearch,
    searchNameDataSavedSearch,
    quickSearchSavedType
  ) => {
    // try {
    this.setState({ quickSearchDataTabIndexSavedSearch });
    this.setState({ searchNameDataSavedSearch });
    this.setState({ quickSearchSavedType });
    this.setState({ tabIndex: quickSearchDataTabIndexSavedSearch });

    this.setState({
      quickSearchSavedObj: {
        searchNameDataSavedSearch: searchNameDataSavedSearch,
        quickSearchSavedType: quickSearchSavedType,
      },
    });

    try {
      switch (this.state.quickSearchSavedObj.quickSearchSavedType) {
        case "files":
          this.setState({ searchNameDataMyFiles: "" });
          break;
        case "Message":
          this.setState({ searchNameDataIDidntReply: "" });
          this.setState({ searchNameDataIwasMentioned: "" });
          break;
        // case "Tag":
        //   this.setState({ quickSearchDataTabIndexHotTag: "" });
        //   this.setState({ searchNameDataHotTag: "" });
        //   break;
        default:
      }
    } catch (ex) { }
    // } catch (ex) {}
  };

  _initDataCrossBoardsSearch = async () => {
    axios
      .get(`/workspace/getWorkspaceBoardById/5feaab7ac574a835ac9f1e16`)
      .then(({ data }) => {
        this.setState({
          groups: JSON.parse(data.workspaceBoard.group),
        });
        let boardData = this.state.groups;
        this.setState({
          groupItemsData: boardData,
        });
        this.state.groupItemsData.map((items) => {
          searchData.push({ name: items.label });

          items.groupItems.map((groupItem) => {
            searchData.push({ name: groupItem.title });
            groupItem.cellItems.map((cell) => {
              searchData.push({ name: cell.body });
            });
          });
        });
      })
      .catch((err) => {
        console.log("Error:", err);
      });
  };

  onSelectedDay = (d) => {
    this.setState({ selectedDate: d });
  };

  changTabs = (tabIndex) => {
    this.setState({
      tabIndex: tabIndex,
    });
  };
  render() {
    const {
      quickSearchDataTabIndexHotTag,
      searchNameDataHotTag,
      quickSearchDataTabIndexMyFiles,
      searchNameDataMyFiles,
      quickSearchDataTabIndexIwasMentioned,
      searchNameDataIwasMentioned,
      quickSearchDataTabIndexIdidntReply,
      searchNameDataIDidntReply,
      quickSearchSavedObj,
    } = this.state;
    const totalFileRecords = localStorage.getItem("totalFileRecords");
    const totalPeopleRecords = localStorage.getItem("totalPeopleRecords");
    const totalHotTagsRecords = localStorage.getItem("totalHotTagsRecords");
    const totalMessagesRecords = localStorage.getItem("totalMessagesRecords");

    return (
      <React.Fragment>
        <MainMenu link={`general-search`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top messenger-layout'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div style={{ marginTop: "50px" }}>
                <div
                  className="col-md-12"
                  style={{ marginTop: "100px", clear: "both" }}
                >
                  <Tabs
                    selectedIndex={this.state.tabIndex}
                    onSelect={(index) => this.setState({ tabIndex: index })}
                  >
                    <TabList>
                      <Tab>
                        <FaSearch />
                      </Tab>
                      <Tab>
                        <GrTasks />
                        <span style={{ marginLeft: "10px" }}>Cross Boards/0</span>
                      </Tab>
                      <Tab>
                        <BiFileFind />
                        <span style={{ marginLeft: "10px" }}>
                          Files/{totalFileRecords ? totalFileRecords : 0}
                        </span>
                      </Tab>
                      <Tab>
                        <IoMdPeople />
                        <span style={{ marginLeft: "10px" }}>
                          People/{totalPeopleRecords ? totalPeopleRecords : 0}
                        </span>
                      </Tab>
                      <Tab>
                        <CgHashtag />
                        <span style={{ marginLeft: "10px" }}>
                          Tags/{totalHotTagsRecords ? totalHotTagsRecords : 0}
                        </span>
                      </Tab>
                      <Tab>
                        Messages/{totalMessagesRecords ? totalMessagesRecords : 0}{" "}
                      </Tab>
                    </TabList>
                    <TabPanel>
                      <QuickSearch
                        quickSearchHotTag={this.handleTabHotTag}
                        quickSearchMyFiles={this.handleTabMyFiles}
                        quickSearchIwasMentioned={this.handleTabIwasMentioned}
                        quickSearchIDidntReply={this.handleTabIDidntReply}
                        quickSearchSavedSearch={this.handleTabSavedSearch}
                      />
                    </TabPanel>

                    <TabPanel>
                      <GlobalSearchBoard />
                    </TabPanel>

                    <TabPanel>
                      <FileSearch
                        searchNameDataMyFiles={searchNameDataMyFiles}
                        quickSearchDataTabIndexMyFiles={quickSearchDataTabIndexMyFiles}
                        quickSearchSavedObj={quickSearchSavedObj}
                      />
                    </TabPanel>
                    <TabPanel>
                      <PeopleSearch quickSearchSavedObj={quickSearchSavedObj} />
                    </TabPanel>
                    <TabPanel>
                      <TagsSearch
                        searchName={searchNameDataHotTag}
                        tabIndex={quickSearchDataTabIndexHotTag}
                        quickSearchSavedObj={quickSearchSavedObj}
                      />
                    </TabPanel>
                    <TabPanel>
                      <MessageSearch
                        quickSearchDataTabIndexIwasMentioned={
                          quickSearchDataTabIndexIwasMentioned
                        }
                        searchNameDataIwasMentioned={searchNameDataIwasMentioned}
                        quickSearchDataTabIndexIdidntReply={
                          quickSearchDataTabIndexIdidntReply
                        }
                        searchNameDataIDidntReply={searchNameDataIDidntReply}
                        quickSearchSavedObj={quickSearchSavedObj}
                      />
                    </TabPanel>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>

      </React.Fragment>

    );
  }
}
export default GeneralSearch;
