import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import axios from '../util/Api'
import LoadingComponent from './components/LoadingComponent';

export default class CheckInvitation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            success: false,
            rememberToken: "",
            email: ''
        }
    }

    componentDidMount() {       
        const { rememberToken } = this.props.match.params;
        this.setState({rememberToken: rememberToken})
        this.setState({ isLoading: true })
        axios.post('/users/getInvitationToken', { rememberToken: rememberToken })
            .then(({ data }) => {
                console.log("data: ", data)
                this.setState({ success: data.success, email: data.email })
                setTimeout(() => {
                    this.setState({isLoading: false})
                })
            })
            .catch(error => {
                this.setState({ isLoading: false })
            })
    }
    render() {        
        if (this.state.isLoading) return (<LoadingComponent />);
        else if (this.state.success) return <Redirect to={{ pathname: "/register", state: { token: this.state.rememberToken, email: this.state.email } }} />
        else return <Redirect to={{ pathname: "/register", state: { token: "", email: "" } }} />
    }
}
