import React, { Component } from 'react';
import AddChatUser from './adduser';
import UserChatList from './Chat-User-List';
import ChatSection from './Chat-Section';
import ChatProfileSection from './Chat-Profile-Section';
import MainMenu from '../../shared/components/main-menu';
import Notification from '../notification/notification';

class Messenger extends Component {
   state = {}
   render() {
      return (
         <>
            <MainMenu link={`messenger`} />
            <div id="whole-layout">
               <div id="appdata" className='app-content content fixed-top messenger-layout'>
                  <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
                  <div className="content-wrapper">
                     <div id="mySidenav" className="sidenav">
                        <a href="#" className="closebtn" onClick={() => {
                           document.getElementById("mySidenav").style.width = "0%";
                        }}>&times;</a>
                     </div>
                     <div className="row messenger">
                        <UserChatList></UserChatList>
                        <ChatSection></ChatSection>
                        <ChatProfileSection></ChatProfileSection>
                     </div>

                     <AddChatUser></AddChatUser>
                  </div>
               </div>
            </div>
         </>
      );
   }
}

export default Messenger;