import React from 'react';
import { connect } from 'react-redux';
import { addColumn } from '../../../../../actions/BoardData';
import { initialGroupCellItemBody } from '../../../../../util/index';
import { getMyPermission } from '../../../../../util/permission';


class AddColumn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            myPermission: 'super'
        };
    }

    componentDidMount() {
        if (this.props.boardData.boardID !== '') {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            this.setState({ myPermission: permission })
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {

            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            console.log("permission: ", permission)
            this.setState({ myPermission: permission })
        }
    }

    addColumn = (key) => {
        const boardID = this.props.boardData.boardID;
        const body = initialGroupCellItemBody(key);
        if (this.props.leftIndex !== 'none') {
            this.props.addColumnEvent()
        }
        if (boardID !== "") {
            this.props.addColumn({
                boardID: boardID,
                key: key,
                body: body,
                leftIndex: this.props.leftIndex,
                addType: "add"
            })
        }
    }

    render() {
        return (
            <>
                {this.state.myPermission === 'super' ? (
                    <>

                <div class="d-flex">
                    <div>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('status')} style={{ paddingRight: '50px' }}><img src="/images/icon/color-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#11dd80', borderRadius: '2px' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Status</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('text')} >  <img src="/images/icon/text-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#00a0ff', borderRadius: '2px' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Text</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('timeline')} ><img src="/images/icon/tag-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#a358df', borderRadius: '2px' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Timeline</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('people')} >  <img src="/images/icon/multiple-person-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#00cff4', borderRadius: '2px' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;People</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('date')} >  <img src="/images/icon/date-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#11dd80', borderRadius: '2px' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('reminder')} >  <img src="/images/icon/pulse-updated-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#f74875', borderRadius: '2px' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Reminder</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('tags')} > <img src="/images/icon/tag-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#a358df', borderRadius: '2px' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Tags</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('numbers')} >  <img src="/images/icon/numeric-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#ffcc00', borderRadius: '2px' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Numbers</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('dropdown')} ><img src="/images/icon/dropdown-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#f74875', borderRadius: '2px' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dropdown</button>
                    </div>   
                        
                    <div>    
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('formula')} > <img src="/images/icon/formula-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#595ad4', borderRadius: '2px' }} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Formula</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('file')} > <img src="/images/icon/file-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#00cff4', borderRadius: '2px' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;File</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('longText')} >  <img src="/images/icon/long-text-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#595ad4', borderRadius: '2px' }} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LongText</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('progressTracking')} > <img src="/images/icon/columns-battery-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#a358df', borderRadius: '2px' }} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Progress Tracking</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('lastUpdated')} > <img src="/images/icon/pulse-updated-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#f74875', borderRadius: '2px' }} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Last Updated</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('rating')} > <img src="/images/icon/rating-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#00a9e1', borderRadius: '2px' }} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Rating</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('autoNumber')} > <img src="/images/icon/autonumber-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#ffcc00', borderRadius: '2px' }} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Auto Number</button>
                        <button className="dropdown-item btn-add-column" onClick={() => this.addColumn('location')} > <img src="/images/icon/location-column-icon.svg" style={{ width: '20px', height: '20px', backgroundColor: '#f24875', borderRadius: '2px' }} /> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Location</button>
                    </div>
                 </div>   



                        {/* <div className='border-top'>
                        <div className='p-1 mt-2'>
                            <button type='button' className='btn btn-more w-100'>More Columns</button>
                        </div>
                    </div> */}
                    </>
                ) : ' No Permission.'}

            </>
        )
    }
}

const mapStateToProps = ({ auth, commonData, boardData }) => {
    return { auth, commonData, boardData }
}

const mapDispatchToProps = { addColumn };

export default connect(mapStateToProps, mapDispatchToProps)(AddColumn)

