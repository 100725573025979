import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fetchError, updateWorkspaceBoard } from '../../../../../actions';
import axios from '../../../../../util/Api';

class BoardItemTerminology extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: '',
            terminology: '',
            customValue: '',
            prevTerminology: ''
        }

        this.modalContainer = React.createRef()
    }

    componentDidMount() {
        let workspaceBoard = this.props.boardData.workspaceBoard;
        let boardTerminology = workspaceBoard.terminology ? workspaceBoard.terminology : 'Item';
        if (boardTerminology !== 'Item' && boardTerminology !== 'Task' && boardTerminology !== 'Client' &&
            boardTerminology !== 'Employee' && boardTerminology !== 'Project' && boardTerminology !== 'Customer' &&
            boardTerminology !== 'Lead'
        ) {
            this.setState({ terminology: boardTerminology, prevTerminology: boardTerminology, selectedOption: 'Custom' })
        } else {
            this.setState({ terminology: boardTerminology, prevTerminology: boardTerminology, selectedOption: boardTerminology })
        }
    }

    handleOptionChange = (event) => {
        if (event.target.value === 'Custom') {
            this.setState({ selectedOption: event.target.value, terminology: this.state.customValue })
        } else {
            this.setState({ selectedOption: event.target.value, terminology: event.target.value })
        }
    }

    changeTerminology = () => {
        if (this.state.terminology !== '') {
            axios.post('/workspace/changeTerminology', {
                boardId: this.props.boardData.boardID,
                terminology: this.state.terminology
            }).then(({ data }) => {
                this.props.updateWorkspaceBoard({
                    workspaceBoard: data.board,
                    workspaceID: this.props.boardData.workspaceID
                })
            }).catch(err => {
                this.props.fetchError(err)
            })
        }
        this.props.closeModal()
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} ref={this.modalContainer}>
                    <ModalHeader toggle={() => this.props.closeModal()}>
                        Change Terminology
                    </ModalHeader>
                    <ModalBody>
                        <div className="mb-4">Make your board terminology relevant to your board's content</div>
                        <div className="p-2">
                            <div className='row'>
                                <div className='col-6'>
                                    <div className='radio'>
                                        <input type="radio" value="Item" id="item"
                                            checked={this.state.selectedOption === 'Item'}
                                            onChange={this.handleOptionChange} />
                                        <label className="terminology" htmlFor="item">Item</label>
                                    </div>
                                    <div className='radio'>
                                        <input type="radio" value="Task" id="task"
                                            checked={this.state.selectedOption === 'Task'}
                                            onChange={this.handleOptionChange} />
                                        <label className='terminology' htmlFor="task">Task</label>
                                    </div>
                                    <div className='radio'>
                                        <input type="radio" value="Client" id="client"
                                            checked={this.state.selectedOption === 'Client'}
                                            onChange={this.handleOptionChange} />
                                        <label className='terminology' htmlFor="client">Client</label>
                                    </div>
                                    <div className='radio'>
                                        <input type="radio" value="Employee" id="employee"
                                            checked={this.state.selectedOption === 'Employee'}
                                            onChange={this.handleOptionChange} />
                                        <label className='terminology' htmlFor="employee">Employee</label>
                                    </div>
                                </div>
                                <div className='col-6'>
                                    <div className='radio'>
                                        <input type="radio" value="Project" id="project"
                                            checked={this.state.selectedOption === 'Project'}
                                            onChange={this.handleOptionChange} />
                                        <label className='terminology' htmlFor="project">Project</label>
                                    </div>
                                    <div className='radio'>
                                        <input type="radio" value="Customer" id="customer"
                                            checked={this.state.selectedOption === 'Customer'}
                                            onChange={this.handleOptionChange} />
                                        <label className='terminology' htmlFor="customer">Customer</label>
                                    </div>
                                    <div className='radio'>
                                        <input type="radio" value="Lead" id="lead"
                                            checked={this.state.selectedOption === 'Lead'}
                                            onChange={this.handleOptionChange} />
                                        <label className='terminology' htmlFor="lead">Lead</label>
                                    </div>
                                    <div className='radio'>
                                        <input type="radio" value="Custom" id="custom"
                                            checked={this.state.selectedOption === 'Custom'}
                                            onChange={this.handleOptionChange} />
                                        <label className='terminology' htmlFor="custom">
                                        {this.state.selectedOption !== 'Custom' && <span>Custom</span>}
                                           {this.state.selectedOption === 'Custom' && <input value={this.state.terminology}
                                                onChange={(event) => { this.setState({ terminology: event.target.value }) }}
                                                placeholder="Custom"
                                                onClick={() => this.setState({ selectedOption: 'Custom' })}
                                            />}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className='btn btn-light' onClick={() => this.props.closeModal()}>Cancel</button>
                        <button className='btn btn-primary' onClick={() => this.changeTerminology()} disabled={this.state.prevTerminology === this.state.terminology ? 'disabled' : ''}>Change</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { fetchError, updateWorkspaceBoard }

export default connect(mapStateToProps, mapDispatchToProps)(BoardItemTerminology)