import React from 'react';
import { connect } from 'react-redux'
import axios from '../../../../../util/Api';
import { getRandomColor, validateEmail } from '../../../../../util/People';
import { showMessage } from '../../../../../actions';
import { format } from 'date-fns';
import { getLocationTime } from '../../../../../util';
import { changeGroupMembers } from '../../../../../actions';

class PeopleItemAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openEdit: false,
            isChecked: false,
            inviteEmail: '',
            suggestUsers: [],
            allSuggestions: [],
            boardData: [],
            groupItem: [],
            people: [],
            searchText: ''
        }
        this.groupMembers = this.props.groupItem.members?this.props.groupItem.members:[];
        this.handleInputChange = this.handleInputChange.bind(this); 
        this.handleInputChangeViewOnly = this.handleInputChangeViewOnly.bind(this); 
        this.timeout = 0
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }

    componentDidMount() {

        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
         
           // this.people= JSON.parse(workspaceBoard.members);
           this.setState({ people: members })
            
           
        }
        this.setState({ boardData: this.props.boardData })
        this.setState({ groupItem: this.props.boardData })
        console.log('lll',this.props.boardData);
        console.log('lll',this.props.groupItem)
        axios.get('/workspace/getWorkSpaceById/', {
            params: { id: this.props.boardData.workspaceID }
        }).then(({ data }) => {
            let workspace = data.workspaces;
            let members = workspace.members;
            console.log("workspace===>", data);
            let users = this.getUserLists(members);
             users = this.removeDuplicates(users, 'email')

            console.log('peop',users);

            this.setState({ suggestUsers: users, allSuggestions: users })
        }).catch(err => {
            console.log("Error: ", err)
        })
    }

     removeDuplicates = (originalArray, prop) =>{
        var newArray = [];
        var lookupObject  = {};
   
        for(var i in originalArray) {
           lookupObject[originalArray[i][prop]] = originalArray[i];
        }
   
        for(i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
         return newArray;
    }
    getUserLists = (members) => {
        let userLists = [];
        let peoples = this.props.people;
        for (let i = 0; i < members.length; i++) {
            let member = members[i];
            let authUser = JSON.parse(localStorage.getItem('authUser'));
            let color = member._id === authUser._id ? '#13a3e3' : getRandomColor();
            let timezoneOffset = member.timezoneOffset ? parseFloat(member.timezoneOffset) : new Date().getTimezoneOffset();

            let findPeople = peoples.find(people => people._id === member._id);
            if (findPeople === undefined) {
                let user = {
                    _id: member._id,
                    name: `${member.firstname ? member.firstname : ''} ${member.lastname ? member.lastname : ''}`,
                    email: member.email,
                    color: color,
                    timezoneOffset: timezoneOffset
                }
                userLists.push(user);
            }
        }

        return userLists;
    }

    handleInputChange = (event) => {
      
       
       // groupMembers= this.props.groupItem.members;
        const member = event.target
        const memberID = member.value
        
        if(this.props.groupItem.members?this.props.groupItem.members.includes(memberID):false){
            this.groupMembers.pop(memberID);
          //  this.props.groupItem.members.pop(memberID);
            event.target.checked =false;
        }else{
            this.groupMembers.push(memberID);
         //   this.props.groupItem.members.push(memberID);
            event.target.checked =true;
        }           
        
       
        this.props.changeGroupMembers({
            groupInd: String(this.props.groupItem._id.charAt(this.props.groupItem._id.length - 1) - 1),
            groupMembers: this.groupMembers,
            groupID: this.props.groupItem._id,
            boardId: this.props.boardData.boardID
        });
 
      
      }

      handleInputChangeViewOnly = (event) => {
      
       
        // groupMembers= this.props.groupItem.members;
         const member = event.target
         let memberID = member.value
         memberID = 'ViewOnly_'+memberID
         if(this.props.groupItem.members?this.props.groupItem.members.includes(memberID):false){
             this.groupMembers.pop(memberID);
           //  this.props.groupItem.members.pop(memberID);
             event.target.checked =false;
         }else{
             this.groupMembers.push(memberID);
          //   this.props.groupItem.members.push(memberID);
             event.target.checked =true;
         }           
         
        
         this.props.changeGroupMembers({
             groupInd: String(this.props.groupItem._id.charAt(this.props.groupItem._id.length - 1) - 1),
             groupMembers: this.groupMembers,
             groupID: this.props.groupItem._id,
             boardId: this.props.boardData.boardID
         });
          
       }
    
    render() {
        return (
            <div className="p-2" style={{width: '300px' }}>
                {/* <div className="pb-2 border-bottom">
                    Restrict Members
                </div> */}
                <div className="pt-2" style={{ flexWrap: 'inherit' }}>
                <table className="table table-striped table-bordered">
    <thead>
      <tr>
        <th>Members</th>
        <th>Hidden <i className="fa fa-info-circle" title="Group will be removed from restricted member's workspace" aria-hidden="true"></i></th>
        <th>View Only <i className="fa fa-info-circle" title="Group will be in view only mode in restricted member's workspace" aria-hidden="true"></i></th>
      </tr>
    </thead>
    <tbody>
                    {this.state.people
                   // .filter(people => people._id != this.authUser._id)
                    .filter(member => (member._id != this.authUser._id) && (member._id != this.props.boardData?.workspace?.CreatedBy) && !member.is_bAdmin && !this.props.boardData?.workspace?.admins.includes(member._id))
                    // && people._id == this.props.boardData.workspace.CreatedBy
                    .map((people, index) => (
                        <tr key={index}>
                        <td> {people.name !== '' ? people.name : people.email}</td>
                        <td>
                                     <input
                                            className="form-check-input cursor-pointer"                            
                                            type="checkbox"
                                            checked={this.props.groupItem.members?this.props.groupItem.members.includes(people._id):false}
                                            onChange={this.handleInputChange} 
                                            value={people._id}
                                            disabled={this.groupMembers.includes('ViewOnly_'+people._id)}
                                            /> 
                        </td>
                        <td>
                        <input
                                            className="form-check-input cursor-pointer"                            
                                            type="checkbox"
                                            checked={this.props.groupItem.members?this.props.groupItem.members.includes('ViewOnly_'+people._id):false}
                                            onChange={this.handleInputChangeViewOnly} 
                                            value={people._id}
                                            disabled={this.groupMembers.includes(people._id)}
                                            /> 
                        </td>
                    </tr>                       
                       
                    ))}
      
     
    </tbody>
  </table>
                    
                </div>
               
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeGroupMembers };

export default connect(mapStateToProps, mapDispatchToProps)(PeopleItemAdd);