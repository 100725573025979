import React from 'react';
import { connect } from 'react-redux';
import { changeBoardItem } from '../../../../../actions'


class FileItem extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className='auto-number-item'>
                {this.props.rowInd}
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeBoardItem }

export default connect(mapStateToProps, mapDispatchToProps)(FileItem)