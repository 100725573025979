import React from 'react';
import ContentEditable from 'react-contenteditable';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { connect } from 'react-redux';
import '../../style/workspace.css';
import '../../style/sidebar.css';
import MainAction from './header/MainAction';
import NewItem from './header/NewItem';
import PersonAction from './header/PersonAction';
import FilterDropdown from './header/FilterDropdown';
import SortDropdwon from './header/SortDropdown';
import BoardSecondEllipsis from './header/BoardSecondEllipsis';
import BoardFirstEllipsis from './header/BoardFirstEllipsis';
import BoardMembersPopup from './header/BoardMembersPopup';
import BoardTable from './body/BoardTable';
import { changeWorkspaceBoardTitle, changeWorkspaceBoardDesc } from '../../../../actions';
import axios from '../../../../util/Api';
import SearchBoard from './header/SearchBoard';
import BoardActivity from './header/BoardActivity';
import BoardPermission from './header/BoardPermission';
import BoardType from './header/BoardType';
import BoardItemTerminology from './header/BoardItemTerminology';
import BoardDuplicate from './header/BoardDuplicate';
import AddColumn from './body/AddColumn';
import config from '../../../services/config.json';

const FullBoard = (props) => {
    const handle = useFullScreenHandle();
    return (
        <FullScreen handle={handle}>
            <Board1 {...props} fullScrean={handle.enter} />
        </FullScreen>
    );
}
class Board extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            boardName: "New Blank Board",
            boardDesc: "Add description",
            groups: [],
            columns: [],
            boardMemberOpen: false,
            boardActivity: false,
            boardPermission: false,
            boardType: {
                open: false,
                to: ''
            },
            boardItemTerminology: false,
            boardDuplicate: false,
            
            isWorkspaceOwner: false,
            isWorkspaceAdmin: false,
            boardMembers: [],
            workspaceAdmins: [],
            currentWorkspace: [],
            workspaceOwner: '',  
        }
        this.timeout = 0;

        this.boardNameEditable = React.createRef();
        this.descritionEditTable = React.createRef();
        this.printComponentRef = React.createRef();
        this.printButtonRef = React.createRef();

        this.onChangeBoardName = this.onChangeBoardName.bind(this);
        this.onBoardDescChange = this.onBoardDescChange.bind(this);
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
    }



    componentDidMount() {


        const boardName = this.props.boardData.boardTitle;
        this.setState({ boardName: boardName, boardDesc: this.props.boardData.boardDescription })
        if (this.props.boardData.boardID !== '') {
            this.setState({ groups: JSON.parse(this.props.boardData.workspaceBoard.group), columns: this.props.boardData.workspaceBoard.columnHeaders })

            console.log("getWorkspaceBoardById",this.props)
            axios.get(`/workspace/getWorkspaceBoardById/${this.props.boardData.boardID}`).then(({ data }) => {
                this.setState({ groups: JSON.parse(data.workspaceBoard.group), columns: data.workspaceBoard.columnHeaders })
            }).catch(err => {
                console.log("Error:", err)
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.boardData.boardID !== '') {
            this.setState({ groups: JSON.parse(nextProps.boardData.workspaceBoard.group), columns: nextProps.boardData.workspaceBoard.columnHeaders })
    
            axios.get(`/workspace/getWorkspaceBoardById/${nextProps.boardData.boardID}`).then(({ data }) => {
                this.setState({ groups: JSON.parse(data.workspaceBoard.group), columns: data.workspaceBoard.columnHeaders })
            }).catch(err => {
                console.log("Error:", err)
            })
        }
    }
    isBoardAdmin = () => {
   
        let members = this.state.boardMembers;
        let Index = members.findIndex(member => member._id === this.authUser._id);
        if (Index == -1) {
            return false;
        }
        if (members[Index].is_bAdmin) {
            return true;
        } else {
            return false;
        }
    }
    onChangeBoardName = (event) => {
        this.setState({
            boardName: event.target.value
        });
    }

    onBoardDescChange = (e) => {
        this.setState({
            boardDesc: e.target.value
        })
    }

    onKeyUpChangeBoardName = () => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.changeWorkspaceBoardTitle({
                boardId: this.props.boardData.boardID,
                boardTitle: this.state.boardName
            })
        }, 800);
    }

    onkeyUpDescChange = () => {
        //this is update
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.props.changeWorkspaceBoardDesc({
                boardId: this.props.boardData.boardID,
                boardDesc: this.state.boardDesc
            })
        }, 800);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.boardData.boardTitle !== prevProps.boardData.boardTitle) {
            this.setState({ boardName: this.props.boardData.boardTitle });
                      
        }

        if (this.props.boardData.boardDescription !== prevProps.boardData.boardDescription) {
            this.setState({ boardDesc: this.props.boardData.boardDescription });
        }
        //console.log('workspaceaction',this.props)

        if(this.props.boardData.workspaceID !== prevProps.boardData.workspaceID){

            let boardMembers = this.props.boardData.workspaceBoard.members ? JSON.parse(this.props.boardData.workspaceBoard.members) : [];
            this.setState({ boardMembers: boardMembers })
        axios.get('/workspace/getWorkSpaceById/', {
            params: { id: this.props.boardData.workspaceID }
        }).then(({ data }) => {
            let workspace = data.workspaces;           
            this.setState({ currentWorkspace: workspace })
            this.setState({ workspaceAdmins: workspace.admins })
            this.setState({ workspaceOwner: workspace.CreatedBy })
             if (this.authUser._id == workspace.CreatedBy) {
                this.setState({ isWorkspaceOwner: true })
                this.setState({ isWorkspaceAdmin: true })
            }else{
                this.setState({ isWorkspaceOwner: false })
                this.setState({ isWorkspaceAdmin: false })
            }
            if (workspace.admins.includes(this.authUser._id)) {
                this.setState({ isWorkspaceAdmin: true })
            }else{
                this.setState({ isWorkspaceAdmin: false }) 
            }
           
        }).catch(err => {
            console.log("Error: ", err)
        })   
    }


       
    }

    renameBoard = () => {
        if (this.props.boardData.boardID !== '') {
            this.boardNameEditable.current.focus();
            setTimeout(() => {
                this.boardNameEditable.current.selectionStart = this.state.boardName.length
                this.boardNameEditable.current.selectionEnd = this.state.boardName.length
            }, 10)
        }
    }

    boardMembers = () => {
        if (this.props.boardData.boardID !== '') {
            this.setState({ boardMemberOpen: true })
        }
    }


    render() {
        return (
            <div id="content" style={{ overflowX: 'auto' , 'padding-top' : '8px' , 'padding-bottom' : '0px' , 'padding-left' : '20px' , 'padding-right' : '20px' }}>
                <ReactToPrint content={() => this.printComponentRef}>
                    <PrintContextConsumer>
                        {({ handlePrint }) => (
                            <button onClick={handlePrint} style={{display: 'none'}} ref={this.printButtonRef}>Print this out!</button>
                        )}
                    </PrintContextConsumer>
                </ReactToPrint>

                <div className="workspace-layout">
                    <div className="first-div">
                        <div className="workspace-layout-section-one">
                            <div className="workspace-layout-header">
                                <ContentEditable
                                    className="board-name draw"
                                    innerRef={this.boardNameEditable}
                                    html={this.state.boardName}
                                
                                    disabled={!(this.state.isWorkspaceAdmin || this.state.isWorkspaceOwner || this.isBoardAdmin()) ? true : false}
                                    onChange={this.onChangeBoardName}
                                    onKeyUp={this.onKeyUpChangeBoardName}
                                    tagName='h5'
                                />

                                {/* <div id="favourites-star" data-toggle="tooltip" data-placement="right" title="Add to favorites">
                                    <i className="bx bxs-star p-2 h2" style={{ color: '#efefef' }}></i>
                                </div> */}
                            </div>
                           {(this.state.isWorkspaceAdmin || this.state.isWorkspaceOwner || this.isBoardAdmin()) && <div
                                id="top-right-content"
                                style={{ padding: '8px 12px' }}
                                onClick={() => {
                                    if (this.props.boardData.boardID !== '') {
                                        this.setState({ boardActivity: true })
                                    }
                                }}
                            >
                                <i className="fa fa-star board-header-icons"></i>
                                <span>Activities</span>
                            </div>}
                            {this.state.boardActivity ? (
                                <BoardActivity
                                    modalOpen={this.state.boardActivity}
                                    closeModal={() => this.setState({ boardActivity: false })}
                                    boardTitle={this.props.boardData.boardTitle}
                                />
                            ) : ''}
                            <div className="activity-right-div">
                                <h5>Tasks Log</h5>
                                <hr />
                            </div>

                            <div
                                id="top-right-content-one"
                                style={{ padding: '8px 12px' }}
                                onClick={() => this.boardMembers()}
                            >
                                <div
                                    data-toggle="tooltip"
                                    data-title="Who is on this Board ?"
                                    data-placement="top"
                                >
                                    <i className="fa fa-users board-header-icons"></i>
                                <span>Board Members</span>
                            </div>
                            </div>
                            {this.state.boardMemberOpen ? (
                                <BoardMembersPopup
                                    isWorkspaceOwner={this.state.isWorkspaceOwner}
                                    isWorkspaceAdmin={this.state.isWorkspaceAdmin}
                                    isBoardAdmin={this.isBoardAdmin()}
                                    currentWorkspace={this.state.currentWorkspace}
                                    modalOpen={this.state.boardMemberOpen}
                                    closeModal={(event) => this.setState({ boardMemberOpen: event })}
                                ></BoardMembersPopup>
                            ) : ''}
                            {this.state.boardPermission ? (
                                <BoardPermission
                                    modalOpen={this.state.boardPermission}
                                    closeModal={() => { this.setState({ boardPermission: false }) }}
                                />
                            ) : ''}
                            {this.state.boardType.open ? (
                                <BoardType
                                    boardType={this.state.boardType}
                                    closeModal={() => { this.setState({ boardType: { open: false, to: '' } }) }}
                                />
                            ) : ''}
                            {this.state.boardItemTerminology ? (
                                <BoardItemTerminology
                                    open={this.state.boardItemTerminology}
                                    closeModal={() => { this.setState({ boardItemTerminology: false }) }}
                                />
                            ) : ''}
                            {this.state.boardDuplicate ? (
                                <BoardDuplicate 
                                    open={this.state.boardDuplicate}
                                    closeModal={() => { this.setState({ boardDuplicate: false }) }}
                                />
                            ): ''}
                           {(this.state.isWorkspaceAdmin || this.state.isWorkspaceOwner || this.state.isBoardAdmin) &&   <BoardFirstEllipsis
                                isWorkspaceOwner={this.state.isWorkspaceOwner}
                                isWorkspaceAdmin={this.state.isWorkspaceAdmin}
                                isBoardAdmin={this.isBoardAdmin()}
                                renameBoard={() => this.renameBoard()}
                                boardMember={() => this.boardMembers()}
                                boardActivity={() => { if (this.props.boardData.boardID !== '') { this.setState({ boardActivity: true }) } }}
                                boardPermission={() => { if (this.props.boardData.boardID !== '') { this.setState({ boardPermission: true }) } }}
                                changeBoardType={(to) => {
                                    if (this.props.boardData.boardID !== '') {
                                        this.setState({
                                            boardType: {
                                                open: true,
                                                to: to
                                            }
                                        })
                                    }
                                }}
                                boardItemTerminology={() => { if (this.props.boardData.boardID !== '') { this.setState({ boardItemTerminology: true }) } }}
                                fullScrean={() => this.props.fullScrean()}
                                print={() => {this.printButtonRef.current.click() }}
                                boardDuplicate={() => { if (this.props.boardData.boardID !== '') { this.setState({ boardDuplicate: true }) }}}
                            >
                            </BoardFirstEllipsis>
    }
                        </div>

                        <div className="workspace-layout-section-two d-flex">
                            <div className="workspace-layout-header">
                                <ContentEditable
                                    className="board-description"
                                    innerRef={this.descritionEditTable}
                                    html={this.state.boardDesc}
                                    disabled={false}
                                    onChange={this.onBoardDescChange}
                                    onKeyUp={this.onkeyUpDescChange}
                                    tagName='div'
                                />
                            </div>

                            <div>
                                
                            </div>
                           </div> 

                           <div className="d-flex bg-white pl-3 board-detail-strip" style={{ 'width' : '99%' }}>
                           {(this.state.isWorkspaceAdmin || this.state.isWorkspaceOwner || this.isBoardAdmin()) && <NewItem></NewItem>}
                           <div className="workspace-layout-section-three pr-1">

                            <div className="pr-5 d-flex main-action-layout">
                            <MainAction></MainAction>
                            {/* {(this.state.isWorkspaceAdmin || this.state.isWorkspaceOwner || this.isBoardAdmin()) && <PersonAction></PersonAction>} */}
                            <FilterDropdown
                            opdown groups={this.state.groups} columns={this.state.columns}></FilterDropdown>
                            <SortDropdwon groups={this.state.groups} columns={this.state.columns}></SortDropdwon>
                            </div>

                            <SearchBoard groups={this.state.groups} columns={this.state.columns} />
                        
                         
                        <div className="btn-group dropleft">
                        <button className="btn dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="bx bxs-plus-circle dot-icons" ></i>
                        </button>
                        <div className="dropdown-menu animate slidein status-drp" 
                        role="menu" aria-labelledby="dropdownMenu" style={{ 'padding' : '0' }}>
                            <AddColumn leftIndex={"none"} />
                        </div>
                       </div>             
                            <BoardSecondEllipsis></BoardSecondEllipsis>

                        </div>
                        </div>
                    </div>
                </div>

                {/* <BoardTable></BoardTable> */}
                <BoardTable ref={el => (this.printComponentRef = el)}></BoardTable>
            </div>

        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeWorkspaceBoardTitle, changeWorkspaceBoardDesc };

const Board1 = connect(mapStateToProps, mapDispatchToProps)(Board);

// export default Board1;
export default FullBoard;