export const getAllDashBoardSettings = "dashboardSettings/getAllDashBoardSettings";
export const getDashBoardSettingsById = "dashboardSettings/getDashBoardSettingsById";
export const saveDashBoardSettings = "dashboardSettings/saveDashBoardSettings";
export const updateDashBoardSettings = "dashboardSettings/updateDashBoardSettings";
export const deleteDashBoardSettings = "dashboardSettings/deleteDashBoardSettings";
export const getDashBoardSettingsByUserId = "dashboardSettings/getDashBoardSettingsByUserId";
export const saveDashboardLayoutSettings = "dashboardSettings/saveDashboardLayoutSettings"
export default {
    getAllDashBoardSettings: getAllDashBoardSettings,
    getDashBoardSettingsById: getDashBoardSettingsById,
    saveDashBoardSettings: saveDashBoardSettings,
    updateDashBoardSettings: updateDashBoardSettings,
    deleteDashBoardSettings: deleteDashBoardSettings,
    getDashBoardSettingsByUserId: getDashBoardSettingsByUserId,
    saveDashboardLayoutSettings: saveDashboardLayoutSettings,
}