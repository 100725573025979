import React from 'react';
import { connect } from 'react-redux'
import { createImageFromInitials } from '../../../../util/People';
//import PeopleItemAdd from './PeopleItemAdd';
//import PeopleItemShowDetail from './PeopleItemShowDetail';
import { changeBoardItem, fetchError } from '../../../../actions';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../util/permission';

class PeopleItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAdd: false,
            openPeopleId: '',
            openEdit: false,
            inviteEmail: '',
            people: [],
            actionPermission: 'edit',
            addColumnOpen: false
        }

        this.dropdownContainer = React.createRef();
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.openAdd && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ openAdd: false, openEdit: false });
        }
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
        let people = JSON.parse(this.props.body);
        this.setState({ people: people })
        // if (this.props.boardData.boardID) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem?.cellItems || [];
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     // console.log("peoples:", peoples)
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentDidUpdate(prevProps, prevStates) {
        // if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem.cellItems;
        //     let peoples = cellItems.find(cell => cell.key === 'people');           
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    componentWillReceiveProps(nextProps) {
        let people = JSON.parse(nextProps.body);
        this.setState({ people: people })
    }

    showPeopleInfo = (people) => {
        this.setState({
            openPeopleId: people._id
        })
    }

    addPeople = (people) => {
        let peoples = [...this.state.people, people];
        this.props.changeBoardItem({
            groupInd: this.props.groupInd,
            rowInd: this.props.rowInd,
            colInd: this.props.colInd,
            body: JSON.stringify(peoples),
            cellItemID: this.props.columnId,
            boardId: this.props.search.boardID
        })
    }

    removePeople = (people) => {
        let peoples = this.state.people;
        // console.log("init peoples", peoples)
        let removeIndex = peoples.findIndex(item => item._id === people._id);
        const { removeItem, } = peoples.splice(removeIndex, 1);
        // console.log("peoples", peoples)

        this.props.changeBoardItem({
            groupInd: this.props.groupInd,
            rowInd: this.props.rowInd,
            colInd: this.props.colInd,
            body: JSON.stringify(peoples),
            cellItemID: this.props.columnId,
            boardId: this.props.search.boardID
        }) 
    }

    render() {
        return (
            <div className='dropdown-items' ref={this.dropdownContainer} style={{ position: 'relative' , 'border' : '1px solid #EAEAEA' }}>
                <div className="dropdown-cell-item" onClick={() => {
                    // if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.search.boarddata[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.search.boarddata[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
                    //     this.setState({ openAdd: !this.state.openAdd })
                    // } else {
                    //     this.props.fetchError("You don't have permission. You can only view")
                    // }
                }}>
                    <div className="add-hint-component add-button">
                        <i className='fa fa-plus-circle' style={{ color: '#007bff !important' }}></i>
                    </div>
                    <div className="dropdown-option-wrapper">
                        {this.state.people.length === 0 ? (
                            <div>
                                <img className='img-circle' src='/images/users/user.png' style={{ width: '28px', borderRadius: '50%' }} />
                            </div>
                        ) : (
                                <>
                                    {this.state.people.length <= 2 ? (
                                        <div className='d-flex'>
                                            {this.state.people.map((people, index) => (
                                                <div key={index} style={{ marginLeft: index === 1 ? '-5px' : '0' }}
                                                    onMouseEnter={() => this.showPeopleInfo(people)}
                                                    onMouseLeave={() => this.setState({ openPeopleId: '' })}
                                                >{people.name ? (
                                                    <img src={createImageFromInitials(28, people.name, people.color)} style={{ width: '28px', borderRadius: '50%' }} />
                                                ) : (
                                                        <img className='img-circle' src='/images/users/dark-user.png' style={{ width: '28px', borderRadius: '50%' }} />
                                                    )}

                                                    {/* {this.state.openPeopleId === people._id ? (
                                                        <PeopleItemShowDetail people={people} />
                                                    ) : ''} */}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className='d-flex'  onMouseLeave={() => { this.setState({ addColumnOpen: false }) }} >
                                                                                             
                                                <div>
                                                    <img src={createImageFromInitials(28, this.state.people[0].name, this.state.people[0].color)} style={{ width: '28px', borderRadius: '50%' }} />
                                                </div>
                                                <div style={{ marginLeft: '-5px' }}>
                                                    <img src={createImageFromInitials(28, this.state.people.length-1, '#111111')} style={{ width: '28px', borderRadius: '50%' }} onMouseEnter={() => { this.setState({ addColumnOpen: true }) }}/>
                                                </div>                                                   
                                             
                                                <ul className={`dropdown-menu multi-level ${this.state.addColumnOpen ? 'show' : ''}`} style={{ position: 'absolute', marginLeft: '140px', marginTop: '-8px' }}>
                                                {this.state.people.map((people, index) => (
                                                    <a className="dropdown-item" key={index} href="#">{index+1}. {people.name}</a>
                                                  
                                                ))}
                                                </ul>
                                         
                                                
                                            </div>
                                        )}
                                </>
                            )}
                    </div>
                </div>
                {/* {this.state.openAdd ? (
                    <div className={`drowpdown-edit ${this.state.openAdd ? 'show' : ''}`} style={{ top: '38px', width: '280px' }}>
                        <PeopleItemAdd people={this.state.people} groupItem={this.props.search.data[this.props.groupInd].groupItems[this.props.rowInd]}
                            addPeople={(people) => this.addPeople(people)} removePeople={(people) => this.removePeople(people)} />
                    </div>
                ) : ''} */}
            </div>
        )
    }
}

const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = { changeBoardItem, fetchError };

export default connect(mapStateToProps, mapDispatchToProps)(PeopleItem);