import config from '../../services/config.json';
import UsersDataService from '../../../data-services/users-data-services';

export const REGISTER = config.apiEndPoint + UsersDataService.register ; 
export const LOGIN = config.apiEndPoint + UsersDataService.login ; 
export const GET_ALL_USER = config.apiEndPoint + UsersDataService.getallUser ; 
export const GET_USER_BY_ID = config.apiEndPoint + UsersDataService.getUserById ; 
export const UPDATE_USER = config.apiEndPoint + UsersDataService.updateUser ; 
export const DELETE_USER = config.apiEndPoint + UsersDataService.deleteUser ; 
export const GET_USER_COLUMN = config.apiEndPoint + UsersDataService.getuserCoulmn ; 
export const ADD_COLUMN = config.apiEndPoint + UsersDataService.addCoulmn ; 
export const RESET_PASSWORD = config.apiEndPoint + UsersDataService.resetpassword ; 
export const TOKEN_NAME = "token"; 
