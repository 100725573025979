import React from 'react';
import ContentEditable from 'react-contenteditable';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import { changeBoardItem, changeBoardColumnTitle, fetchError } from '../../../../../actions';
import {  getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission} from '../../../../../util/permission';
import GroupColumnEvent from './GroupColumnEvent';
import ReactTooltip from 'react-tooltip';
class GroupColumnTitle extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: this.props.body,
            actionPermission: 'view'
        }
        this.timeout = 0;

        this.rowTitleEditableRef = React.createRef();
    }

    componentDidMount() {
        if (this.props.boardData.boardID) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
       
            if (permission == 'super') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' || permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ title: nextProps.body })
    }

    onChangeListTitle = (event) => {
        if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members) && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
            this.setState({ title: event.target.value })
        } else {
            this.props.fetchError("You don't have permission. You can only view.")
        }
    }

    onKeyUp = (event) =>  {
        console.log('llp3',getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members));
                       
        if (this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.boardData.data[this.props.groupInd].members) && getMyColumnEditPermission(this.props.boardData.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {           
                const { groupInd, rowInd, colInd, _id } = this.props;
                this.props.changeBoardColumnTitle({
                    boardId: this.props.boardData.boardID,
                    rowInd: rowInd,
                    colInd: colInd,
                    body: event.target.outerText,
                    cellItemID: _id
                })
       
        } else {
            this.props.fetchError("You don't have permission. You can only view.")
        }
        

    }

    render() {
      
        return (
            <div className="d-inline-flex column-header" 
            //  data-title={`${ReactHtmlParser(this.state.title)}`}
            >
                {!this.props.isViewOnly && <i class="bx bx-grid-vertical grid-vertical text-black-50 cursor-pointer drag-vertical-column" aria-hidden="true"></i>}
                <ContentEditable
                    className="board-name column-text"
                    data-tip={this.state.title}
                    innerRef={r=>this.rowTitleEditableRef=r}
                    html={this.state.title}
                    disabled={(this.state.actionPermission === 'view' || this.state.title==='Assignee' || this.state.title==='Followers') ? true : false}
                 //   onChange={this.onChangeListTitle}
                   
                    onBlur={event => this.onKeyUp(event)}
                    style={{ fontSize: '13.5px', padding: '3px 10px', color: '#000', 'display' : 'flex' , 'justify-content' : 'center' , 'position' : 'static' ,     'width': '70%' ,
                    'margin': '0 auto' ,
                   ' align-content' : 'center' ,
                    'align-items': 'center'  }}
                    tagName='div'
                />
                <GroupColumnEvent colInd={this.props.colInd} groupInd={this.props.groupInd} columnId={this.props.columnId} cellItemID={this.props._id} title={this.props.body}  cellItems={this.props.cellItems} />
               
            
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { changeBoardItem, changeBoardColumnTitle, fetchError };

export default connect(mapStateToProps, mapDispatchToProps)(GroupColumnTitle);