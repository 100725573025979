import { format } from 'date-fns';
import React from 'react';
import { connect } from 'react-redux'
import { getLocationTimeFromDate, getUTCDateTime, timeSince } from '../../../../../util';
import { createImageFromInitials } from '../../../../../util/People';

class LastUpdatedItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lastUpdated: null,
            update: false
        }

        this.timeout = 0;
        this.countDown = 0;
    }

    componentDidMount() {
        let groups = this.props.boardData.data;
        let groupItem = groups[this.props.groupInd].groupItems[this.props.rowInd];
        if (groupItem.lastUpdated) {
            let lastUpdated = groupItem.lastUpdated;

            this.setState({ lastUpdated: lastUpdated })
        } else {
            let authUser = JSON.parse(localStorage.getItem("authUser"));
            let lastUpdated = {
                name: `${authUser.firstname} ${authUser.lastname}`,
                color: authUser.color ? authUser.color : '#13a3e3',
                updated: getUTCDateTime()
            }

            this.setState({ lastUpdated: lastUpdated })
        }

        this.countDown = setInterval(() => {
            this.setState({ update: !this.state.update })
        }, 10000)
    }

    componentWillUnmount() {
        clearInterval(this.countDown);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.boardData.boardID !== '') {
            if (nextProps.lastUpdated !== null) {
                this.setState({ lastUpdated: nextProps.lastUpdated })
            } else {
                let authUser = JSON.parse(localStorage.getItem("authUser"));
                let lastUpdated = {
                    name: `${authUser.firstname} ${authUser.lastname}`,
                    color: authUser.color ? authUser.color : '#13a3e3',
                    updated: getUTCDateTime()
                }

                this.setState({ lastUpdated: lastUpdated })
            }
        }
    }

    render() {
        // console.log("this.state: ", this.state.lastUpdated);
        let date = this.state.lastUpdated ? getLocationTimeFromDate(new Date(this.state.lastUpdated.updated), new Date().getTimezoneOffset()) : new Date()
        return (
            <div className="last-updated-item" style={{ 'border' : '1px solid #EAEAEA'  , 'border-top' :' none' ,
            'border-bottom' : 'none' ,
            'border-right' : 'none' }}>
                {this.state.lastUpdated !== null ? (
                    <div className='last-updated-item-content data-title'
                        data-title={`Updated by ${this.state.lastUpdated.name} on ${format(date, 'MMM d, y')} at ${format(date, 'hh:mm a')} `}
                    >
                        <div className='last-updated-item-body'>
                            <img src={createImageFromInitials(32, this.state.lastUpdated.name, this.state.lastUpdated.color)} style={{ borderRadius: '50%' }} />
                            <div>{timeSince(date)} ago</div>
                        </div>

                    </div>
                ) : ''}

            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

export default connect(mapStateToProps)(LastUpdatedItem);