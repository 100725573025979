import React from 'react';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { hideMessage } from '../../actions';

class ToastrComponent extends React.Component {
    constructor(props) {
        super(props);
        this.toastrId = React.createRef();
    }

    componentDidMount() {
        let message = this.props.commonData.message;
        // console.log("message===>", message)
        if (message !== '') {
            toast.success(message);
        }

        if (this.props.commonData.error !== '') {
            toast.error(this.props.commonData.error)
        }
    }

    componentDidUpdate(prevProps) {
        // console.log("message, ", this.props.commonData)
        if (this.props.commonData.message !== prevProps.commonData.message) {
            if (this.props.commonData.message !== '') {
                console.log("hello test")                
                toast.success(this.props.commonData.message, { toastId: this.toastrId });

            }
        }
        if (this.props.commonData.error !== prevProps.commonData.error) {
            if (this.props.commonData.error !== '') {
                toast.error(this.props.commonData.error, { toastId: this.toastrId });
            }
        }
    }

    componentWillUnmount() {
        this.props.hideMessage();
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer />
            </React.Fragment>
        )
    }
}

const mapStateToProps = ({ commonData }) => {
    return { commonData }
}

const mapDispatchToProps = { hideMessage }

export default connect(mapStateToProps, mapDispatchToProps)(ToastrComponent);
