import React, { Component,useContext,useState,useEffect } from 'react'
import { SettingsContext } from "../../contexts/settings-context";

const GlobalEmailForm = () => {
  const { email , saveSettings} = useContext(SettingsContext);
  const [emailData,setEmailData] = useState({});
  
  useEffect(() => {
    
    setEmailData({...email});
    return () => {
     
    }
  
}, [email]);

const updateState = (event) =>{
  const path = event.target.name.split('.');
  const depth = path.length;
  const state = { ...emailData };
  let ref = state;
  for (let i = 0; i < depth; i += 1) {
    if (i === depth - 1) {
      ref[path[i]] = event.target.value;
    } else {
      ref = ref[path[i]];
    }
  }
  setEmailData(state);
}
const saveEmailSettings = e => {
  e.preventDefault();
  emailData.type = 'email';
  
  saveSettings(emailData);
  
};
  return ( 
    <>
                        <div role="tabpanel" className="tab-pane" id="settings-vertical-email" aria-labelledby="settings-pill-email" aria-expanded="false">
                          <div className="row mb-1" style={{ backgroundColor: '#4189f6', margin: '10px 1px 1px 1px', borderRadius: '8px' , paddingTop : '10px' , height : '40px' }}>
                            <div className="col-12">
                              <h6 style={{color: '#ffffff', fontWeight: 300, fontSize: '14.5px'}}>Email Settings</h6>
                            </div>
                          </div>
                          <br />
                          <form name="emailForm" noValidate onSubmit={saveEmailSettings}>
                            <fieldset>
                              <legend style={{fontSize: '14px', textDecoration: 'underline', color: '#336'}}> SMTP - General</legend>
                              <div className="row">
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}>SMTP Host</label>
                                    <div className="controls">
                                      <input type="text" name="smtpGeneral.smtpHost" autoComplete="off" className="form-control" 
                                      value={email.smtpGeneral.smtpHost} 
                                      onChange={updateState}
                                      />
                                     
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}> SMTP Port</label>
                                    <div className="controls">
                                      <input type="text" name="smtpGeneral.smtpPort" autoComplete="off" className="form-control"  aria-invalid="false"  value={email.smtpGeneral.smtpPort} 
                                      onChange={updateState}
                                      
                                      />
                                     
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}>SMTP User Name</label>
                                    <div className="controls">
                                      <input type="text" name="smtpGeneral.smtpUserName" autoComplete="off"  className="form-control" 
                                      value={email.smtpGeneral.smtpUserName} 
                                      onChange={updateState}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}>SMTP Password</label>
                                    <div className="controls">
                                      <input type="password" name="smtpGeneral.smtpPassword" autoComplete="off" className="form-control"
                                       value={email.smtpGeneral.smtpPassword} 
                                       onChange={updateState}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <br />
                            <fieldset>
                              <legend style={{fontSize: '14px', textDecoration: 'underline', color: '#336'}}> SMTP - Due Date Change</legend>
                              <div className="row">
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}>SMTP Host</label>
                                    <div className="controls">
                                      <input type="text" name="smtpDueDateChange.smtpHost" autoComplete="off"  className="form-control"
                                       value={email.smtpDueDateChange.smtpHost} 
                                       onChange={updateState}
                                      />
                                     
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}>SMTP Port</label>
                                    <div className="controls">
                                      <input type="text" name="smtpDueDateChange.smtpPort" autoComplete="off"  className="form-control"  aria-invalid="false"
                                      value={email.smtpDueDateChange.smtpPort} 
                                      onChange={updateState}
                                      />
                                     
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}>SMTP User Name</label>
                                    <div className="controls">
                                      <input type="text" name="smtpDueDateChange.smtpUserName" autoComplete="off"  className="form-control"
                                      value={email.smtpDueDateChange.smtpUserName} 
                                      onChange={updateState}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}>SMTP Password</label>
                                    <div className="controls">
                                      <input type="password" name="smtpDueDateChange.smtpPassword" autoComplete="off"  className="form-control"
                                       value={email.smtpDueDateChange.smtpPassword} 
                                       onChange={updateState}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <br />
                            <fieldset>
                              <legend style={{fontSize: '14px', textDecoration: 'underline', color: '#336'}}> SMTP - Assignee Change</legend>
                              <div className="row">
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}>SMTP Host</label>
                                    <div className="controls">
                                      <input type="text" name="smtpAssigneeChange.smtpHost" autoComplete="off"  className="form-control" 
                                        value={email.smtpAssigneeChange.smtpHost} 
                                        onChange={updateState}
                                      />
                                    
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}>SMTP Port</label>
                                    <div className="controls">
                                      <input type="text" name="smtpAssigneeChange.smtpPort" autoComplete="off"  className="form-control"  aria-invalid="false"
                                       value={email.smtpAssigneeChange.smtpPort} 
                                       onChange={updateState}
                                      />
                                     
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}>SMTP User Name</label>
                                    <div className="controls">
                                      <input type="text" name="smtpAssigneeChange.smtpUserName" autoComplete="off" className="form-control" 
                                      value={email.smtpAssigneeChange.smtpUserName} 
                                      onChange={updateState}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-6 col-md-3">
                                  <div className="form-group">
                                    <label style={{fontSize: '12px', textTransform: 'none', fontWeight: 100}}>SMTP Password</label>
                                    <div className="controls">
                                      <input type="password" name="smtpAssigneeChange.smtpPassword" autoComplete="off" className="form-control"
                                      value={email.smtpAssigneeChange.smtpPassword} 
                                      onChange={updateState}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                            <div className="row">
                              <div className="col-12 justify-content-end">
                                <button type="submit" className="btn-custom-1">Save</button> &nbsp;&nbsp;
                                <button type="reset" className="btn-custom-2">Cancel</button>
                              </div>
                            </div>
                          </form>
                        </div>
              
    
    </>
    
             );
}

export default GlobalEmailForm;