const initialState = {
    resultData: [],
    filterData:[]
}
export default (state = initialState, action) => {
    ///console.log("action.payload",action.payload)
    // console.log("item.groups",action.payload.workspaceBoard)
    switch (action.type) {
        
        case "ONGETDATA": {
            console.log("ONFILTERGETDATA")
            return {
                ...state,
                resultData: action.payload.workspaceBoard
            }
        }
        case "ONGETFILTERDATA": {
            
            return {
                ...state,
                filterData: action.payload.workspaceBoard
            }
        }
        case "ONUNREADGETDATA": {
            
            return {
                ...state,
                resultData: action.payload.workspaceBoard,
                chatRoom: action.payload.chatrooms
            }
        }
        case "ONFILTERGETDATA": {
            return {
                ...state,
                resultData:action.payload.workspaceBoard
            }
        }
        default:
            return state;
    }
}