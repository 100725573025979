import React from 'react';

function ModalActivity(props) {
   return (
      <div className="modal fade" id="modal-activity" data-backdrop="true" style={{ position: 'fixed', top: '50px', left: '-50px' }}>
         <div className="modal-dialog modal-lg" style={{ overflowY: 'initial !important' }}>
            <div className="modal-content" style={{ padding: 0, width: '130%', margin: '0 auto', border: 'none', marginTop: '30px', borderRadius: '15px 15px 15px 15px', minHeight: '650px', boxShadow: '0px 0px 9px 0px rgb(29 44 80)' }}>
               <div className="modal-header _600" style={{ backgroundColor: '#22386B', color: '#fff', borderRadius: '15px 15px 0px 0px', height: '70px' }}>
                  <div style={{ display: 'inline-flex', margin: '10px auto' }}>
                     <span>Add Project</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <label className="switch">
                        <input type="checkbox" defaultChecked id="ckmainadd" />
                        <span className="slider round" />
                     </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <span style={{}}>Add Task</span>
                  </div>
                  <button type="button" className="close-pink" ng-click="clearTask()" aria-label="Close" data-dismiss="modal">
                     <div style={{ color: '#ffffff', marginTop: '-2px' }}><span aria-hidden="true">×</span></div>
                  </button>
               </div>
               <div id="divTask">
                  <form className="form-horizontal" ng-submit="createTask(taskForm.$valid)" noValidate name="taskForm">
                     <div className="modal-body" style={{ padding: '10px 20px 0px 0px', overflowY: 'auto' }}>
                        <div className="form-group row">
                           <div className="col-md-12 col-lg-12 d-flex">
                              <div className="col-md-2 col-lg-2">
                                 <label>Task Title:<span className="required" style={{ color: 'red' }}> *</span></label>
                              </div>
                              <div className="col-md-10 col-lg-10">
                                 <input type="text" className="text-line" autoComplete="off" style={{ width: '100%', borderBottom: '1px solid #e5e2e2', borderLeft: 'none', borderRight: 'none', borderTop: 'none' }} />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row">
                           <div className="col-md-12 col-lg-12 d-flex">
                              <div className="col-md-2 col-lg-2">
                                 <label>Task Details:</label>
                              </div>
                              <div className="col-md-10 col-lg-10">
                                 <textarea className="form-control" style={{ height: '70px', backgroundColor: '#e5e2e2', fontSize: '12.5px', outline: 'none', border: 'none !important' }} />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row">
                           <div className="col-md-12 col-lg-12 d-flex">
                              <div className="col-md-2 col-lg-2">
                                 <label className="form-control-label">Visiblity:</label>
                              </div>
                              <div className="col-md-4 col-lg-4">
                                 <div style={{ marginLeft: '-15px' }}>
                                    <label ng-repeat="item in visibility" ng-model="task.visibilitytypeid">
                                       <input type="radio" name="visibility" defaultChecked="checked" defaultValue="{{item.id}}" style={{ verticalAlign: 'text-bottom' }} />  {'{'}{'{'}item.visibleName{'}'}{'}'} &nbsp; &nbsp;
                            </label>
                                 </div>
                              </div>
                              <div className="col-md-6 col-lg-6">
                                 <label className="form-control-label">Project Cost:</label>
                                 <input type="number" ng-model="task.projectcost" className="text-line" style={{ width: '120px' }} />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <select id="ddlcurrency" className="form-control select-textline">
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div className="form-group row">
                           <div className="col-md-12 col-lg-12 col-12 d-flex">
                              <div className="col-md-2 col-lg-2 d-flex">
                                 <label>Client Name:</label>
                              </div>
                              <div className="col-md-10 col-md-10 col-12">
                                 <input type="text" className="text-line-two" readOnly="readonly" id="txtcustomername" ng-model="task.customername" autoComplete="off" />
                          &nbsp;&nbsp;
                          <a style={{ width: '5%' }} className="icon" ng-click="openclientPopup()">
                                    <span className="hidden-sm-down right-head"><i className="bx bxs-plus-circle" /></span>
                                 </a>
                          &nbsp;
                          <span style={{ width: '10%', verticalAlign: 'bottom', fontWeight: 300 }}>(OR)</span>
                                 <select id="ddlcustomer" className="form-control">
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div className="form-group row">
                           <div className="col-md-12 col-lg-12 d-flex">
                              <div className="col-md-2 col-lg-2 col-12 d-flex">
                                 <label>Project Name:</label>
                              </div>
                              <div className="col-md-10 col-lg-10 col-12">
                                 <input type="text" className="text-line-two" readOnly="readonly" id="txtprojectname" ng-model="task.projectname" autoComplete="off" />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span style={{ width: '10%', verticalAlign: 'bottom', fontWeight: 300 }}>(OR)</span>
                                 <select id="ddproject" className="form-control">
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ margin: '-10px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12">
                              <label>Category Name</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12">
                              <div className="form-label-group">
                                 <select id="ddlcategories" className="select-textline" style={{ height: '25px', fontSize: '12.5px' }}>
                                    <option />
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ margin: '-10px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12">
                              <label>Assignees</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12">
                              <div className="form-label-group">
                                 <select className="form-control" id="ddlassignees" style={{ height: '25px', fontSize: '12.5px' }}>
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ margin: '-10px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12">
                              <label>Task Managers</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12">
                              <div className="form-label-group">
                                 <select className="form-control" id="ddltaskmanagers" style={{ height: '25px', fontSize: '12.5px' }} />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ margin: '-10px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12">
                              <label>Collaborators</label>
                           </div>
                           <div className="col-md-10 col-12">
                              <div className="form-label-group">
                                 <select className="form-control" id="ddlcollaborators" />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ margin: '-10px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12">
                              <label>Followers</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12">
                              <div className="form-label-group">
                                 <select className="form-control" id="ddlfollowers" />
                              </div>
                           </div>
                        </div>
                        <br />
                        <div className="form-group row" style={{ marginTop: '20px auto' }}>
                           <div className="col-md-12 col-12">
                              <div className="form-label-group d-flex">
                                 <div className="col-md-2 col-lg-2" style={{ display: 'inline-block', marginTop: '10px', paddingLeft: '30px' }}>
                                    <img src="/images/Mobile icons-37.png" style={{ width: '20px', height: '20px' }} /><label>Forecast</label>
                                 </div>
                                 <div className="col-md-10 col-lg-10 d-flex" style={{ backgroundColor: '#e5e5e5', padding: '15px', margin: '0 auto', borderRadius: '5px' }}>
                                    <div className="col-md-5 col-lg-5 col-12">
                                       <span style={{ fontSize: '12.5px', fontWeight: 300, verticalAlign: 'baseline' }}>Start</span> &nbsp;&nbsp;&nbsp;&nbsp;
                              <input type="date" ng-model="task.forecaststart" id="txtforecaststart" autoComplete="off" className="text-line" style={{ fontSize: '12px', fontWeight: 300 }} />
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-12">
                                    </div>
                                    <div className="col-md-5 col-lg-5 col-12">
                                       <div>
                                          <span style={{ fontSize: '12.5px', fontWeight: 300, verticalAlign: 'baseline' }}>End</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="date" ng-model="task.forecastend" id="txtforecastend" autoComplete="off" className="text-line" style={{ fontSize: '12px', fontWeight: 300 }} />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ marginTop: '-20px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12" style={{ paddingLeft: '30px' }}>
                              <label>Priority</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12">
                              <div style={{ marginLeft: '-15px' }}>
                                 <label htmlFor="high" style={{ verticalAlign: 'text-bottom' }}>High</label>
                                 <input type="radio" id="high" name="priority" defaultValue={3} />
                                 <label htmlFor="medium" style={{ verticalAlign: 'text-bottom' }}>Medium</label>
                                 <input type="radio" id="medium" name="priority" defaultValue={2} />
                                 <label htmlFor="low" style={{ verticalAlign: 'text-bottom' }}>Low</label>
                                 <input type="radio" id="low" name="priority" defaultValue={1} defaultChecked />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row">
                           <div className="col-lg-2">
                           </div>
                           <div className="col-md-10 col-lg-10">
                              <div style={{ marginLeft: '-15px' }}>
                                 <label>Focus</label>
                                 <input type="text" ng-model="task.focus" className="text-line-three" ng-maxlength={50} />
                                 <label>Stage</label>
                                 <input type="text" ng-model="task.stage" className="text-line-three" ng-maxlength={50} />
                                 <label>Effort</label>
                                 <input type="text" ng-model="task.effort" className="text-line-three" ng-maxlength={50} />
                                 <label>Type</label>
                                 <input type="text" ng-model="task.type" className="text-line-three" ng-maxlength={50} />
                                 <label>Hours</label>
                                 <input type="text" ng-model="task.hours" className="text-line-three" ng-maxlength={50} />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ marginTop: '0 auto', display: 'none' }}>
                           <div className="col-md-2 col-lg-2 col-12" style={{ paddingLeft: '30px' }}>
                              <label htmlFor="ckextras">Extras</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12" style={{ marginTop: '10px' }}>
                              <input type="checkbox" id="ckextras" onChange={(ev) => { console.log("ev", ev.target.value) }} />
                           </div>
                        </div>
                        <div className="form-group row" style={{ marginTop: '0 auto' }}>
                           <div className="col-md-2 col-lg-2 col-12" style={{ display: 'inline-block', paddingLeft: '45px' }}>
                              <img src="/images/Mobile icons-37.png" style={{ width: '20px', height: '20px' }} /><label>Reminders</label>
                           </div>
                           <div className="col-md-10 col-lg-10 d-flex" style={{ backgroundColor: '#e5e5e5', padding: '15px', margin: '0 auto', borderRadius: '5px' }}>
                              <div className="col-md-4 col-lg-4 col-12">
                              </div>
                              <div className="col-md-6 col-lg-6 col-12">
                              </div>
                              <div className="col-md-2 col-lg-2 col-12">
                                 {/* <button type="button" class="btn btn-light-danger mr-1 mb-1" ng-click="addReminder()" style="width:100%">
                    Add Reminder&nbsp;&nbsp;&nbsp;<i class="bx bxs-plus-circle" style="vertical-align:middle"></i>
                </button>*/}
                                 <label style={{ background: 'none' }}>Add Reminder</label>&nbsp;<i className="bx bxs-plus-circle" ng-click="addReminder()" style={{ verticalAlign: 'middle', color: '#e8378f' }} />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ float: 'right' }}>
                           <div className="col-md-12 col-lg-12 col-12">
                              {/*<input type="submit" class="btn btn-primary btn-sm p-x-md" value="Submit" />*/}
                              <button type="submit" className="btn-custom-1" value="Submit">Submit</button> &nbsp;&nbsp;&nbsp;
                      </div>
                        </div>

                        <div id="divextras" style={{ display: 'none' }}>
                           <div className="form-group row">
                              <div className="col-lg-2">
                              </div>
                              <label className="col-lg-2 form-control-label">Outstanding</label>
                              <div className="col-lg-3">
                                 <input type="number" id="txtoutsanding" className="text-line" />
                              </div>
                              <label className="col-lg-2 form-control-label">Overdue</label>
                              <div className="col-lg-3">
                                 <input type="number" id="txtoverdue" className="text-line" />
                              </div>
                           </div>
                           <div className="form-group row">
                              <div className="col-lg-2">
                              </div>
                              <label className="col-lg-2 form-control-label">Expected</label>
                              <div className="col-lg-3">
                                 <input type="number" id="txtexpected" className="text-line" />
                              </div>
                              <label className="col-lg-2 form-control-label">Collected</label>
                              <div className="col-lg-3">
                                 <input type="number" id="txtcollected" className="text-line" />
                              </div>
                           </div>
                           <div className="form-group row">
                              <div className="col-lg-2">
                              </div>
                              <label className="col-lg-2 form-control-label">Sales Contact</label>
                              <div className="col-lg-3">
                                 <input type="text" id="txtsalescontact" className="text-line" />
                              </div>
                              <label className="col-lg-2 form-control-label">Coll. Contact</label>
                              <div className="col-lg-3">
                                 <input type="text" id="txtcollcontact" className="text-line" />
                              </div>
                           </div>
                           <div className="form-group row">
                              <div className="col-lg-2">
                              </div>
                              <label className="col-lg-2 form-control-label">Sales Contact No.</label>
                              <div className="col-lg-3">
                                 <input type="number" id="txtsalecontactno" className="text-line" />
                              </div>
                              <label className="col-lg-2 form-control-label">Coll. Contact No.</label>
                              <div className="col-lg-3">
                                 <input type="number" id="txtcollcontactno" className="text-line" />
                              </div>
                           </div>
                           <div className="form-group row">
                              <div className="col-lg-2">
                              </div>
                              <label className="col-lg-2 form-control-label">Email Id.</label>
                              <div className="col-lg-3">
                                 <input type="email" id="txtsalesemail" className="text-line" />
                              </div>
                              <label className="col-lg-2 form-control-label">Email Id.</label>
                              <div className="col-lg-3">
                                 <input type="email" id="txtcollemail" className="text-line" />
                              </div>
                           </div>
                        </div>
                     </div>
                  </form>
               </div>
               <div id="divProject" style={{ display: 'none' }}>
                  <form className="form-horizontal" ng-submit="createProject(projectForm.$valid)" noValidate name="projectForm">
                     <div className="modal-body" style={{ padding: '10px 20px 0px 0px' }}>
                        <div className="form-group row">
                           <div className="col-md-12 col-lg-12 d-flex">
                              <div className="col-md-2 col-lg-2">
                                 <label>Project Title:<span className="required" style={{ color: 'red' }}> *</span></label>
                              </div>
                              <div className="col-md-10 col-lg-10">
                                 <input type="text" ng-model="project.name" className="text-line" autoComplete="off" ng-required="true" ng-maxlength={100} style={{ width: '100%', borderBottom: '1px solid #e5e2e2' }} />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row">
                           <div className="col-md-12 col-lg-12 d-flex">
                              <div className="col-md-2 col-lg-2">
                                 <label>Project Details:</label>
                              </div>
                              <div className="col-md-10 col-lg-10">
                                 <textarea className="form-control" ng-model="project.description" ng-maxlength={200} style={{ height: '70px', backgroundColor: '#e5e2e2', fontSize: '12.5px', outline: 'none', border: 'none' }} defaultValue={""} />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row">
                           <div className="col-md-12 col-lg-12 d-flex">
                              <div className="col-md-2 col-lg-2">
                                 <label className="form-control-label">Visiblity:</label>
                              </div>
                              <div className="col-md-4 col-lg-4">
                                 <div style={{ marginLeft: '-15px' }}>
                                    <label ng-repeat="item in visibility" ng-model="project.visibilitytypeid">
                                       <input type="radio" name="visibility1" defaultChecked="checked" defaultValue="{{item.id}}" style={{ verticalAlign: 'text-bottom' }} />  {'{'}{'{'}item.visibleName{'}'}{'}'} &nbsp; &nbsp;
                            </label>
                                 </div>
                              </div>
                              <div className="col-md-6 col-lg-6">
                                 <label className="form-control-label">Project Cost:</label>
                                 <input type="number" ng-model="project.projectcost" className="text-line" style={{ width: '120px' }} />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          <select id="ddlcurrency1" className="form-control">
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div className="form-group row">
                           <div className="col-md-12 col-lg-12 col-12 d-flex">
                              <div className="col-md-2 col-lg-2 d-flex">
                                 <label>Client Name:</label>
                              </div>
                              <div className="col-md-10 col-md-10 col-12">
                                 <input type="text" className="text-line-two" readOnly="readonly" id="txtcustomername1" ng-model="project.customername" autoComplete="off" />
                          &nbsp;&nbsp;
                          <a style={{ width: '5%' }} className="icon" ng-click="openclientPopup()">
                                    <span className="hidden-sm-down right-head"><i className="bx bxs-plus-circle" /></span>
                                 </a>
                          &nbsp;
                          <span style={{ width: '10%', verticalAlign: 'bottom', fontWeight: 300 }}>(OR)</span>
                                 <select id="ddlcustomer1" className="form-control">
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ margin: '-10px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12">
                              <label>Category Name</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12">
                              <div className="form-label-group">
                                 <select id="ddlcategories1" style={{ height: '25px', fontSize: '12.5px' }}>
                                    <option />
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ margin: '-10px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12">
                              <label>Assignees</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12">
                              <div className="form-label-group">
                                 <select className="form-control" id="ddlassignees1" style={{ height: '25px', fontSize: '12.5px' }}>
                                 </select>
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ margin: '-10px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12">
                              <label>Task Managers</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12">
                              <div className="form-label-group">
                                 <select className="form-control" id="ddltaskmanagers1" style={{ height: '25px', fontSize: '12.5px' }} />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ margin: '-10px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12">
                              <label>Collaborators</label>
                           </div>
                           <div className="col-md-10 col-12">
                              <div className="form-label-group">
                                 <select className="form-control" id="ddlcollaborators1" />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ margin: '-10px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12">
                              <label>Followers</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12">
                              <div className="form-label-group">
                                 <select className="form-control" id="ddlfollowers1" />
                              </div>
                           </div>
                        </div>
                        <br />
                        <div className="form-group row" style={{ marginTop: '20px auto' }}>
                           <div className="col-md-12 col-12">
                              <div className="form-label-group d-flex">
                                 <div className="col-md-2 col-lg-2" style={{ display: 'inline-block', marginTop: '10px', paddingLeft: '30px' }}>
                                    <img src="/images/Mobile icons-37.png" style={{ width: '20px', height: '20px' }} /><label>Forecast</label>
                                 </div>
                                 <div className="col-md-10 col-lg-10 d-flex" style={{ backgroundColor: '#e5e5e5', padding: '15px', margin: '0 auto', borderRadius: '5px' }}>
                                    <div className="col-md-5 col-lg-5 col-12">
                                       <span style={{ fontSize: '12.5px', fontWeight: 300, verticalAlign: 'baseline' }}>Start</span> &nbsp;&nbsp;&nbsp;&nbsp;
                              <input type="date" ng-model="project.forecastfrom" id="txtforecaststart" autoComplete="off" className="text-line" style={{ fontSize: '12px', fontWeight: 300 }} />
                                    </div>
                                    <div className="col-md-2 col-lg-2 col-12">
                                    </div>
                                    <div className="col-md-5 col-lg-5 col-12">
                                       <div>
                                          <span style={{ fontSize: '12.5px', fontWeight: 300, verticalAlign: 'baseline' }}>End</span>&nbsp;&nbsp;&nbsp;&nbsp;
                                <input type="date" ng-model="project.forecastend" id="txtforecastend" autoComplete="off" className="text-line" style={{ fontSize: '12px', fontWeight: 300 }} />
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ marginTop: '-20px auto' }}>
                           <div className="col-md-2 col-lg-2 col-12" style={{ paddingLeft: '30px' }}>
                              <label>Priority</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12">
                              <div style={{ marginLeft: '-15px' }}>
                                 <label htmlFor="high1" style={{ verticalAlign: 'text-bottom' }}>High</label>
                                 <input type="radio" id="high" name="priority1" defaultValue={3} />
                                 <label htmlFor="medium1" style={{ verticalAlign: 'text-bottom' }}>Medium</label>
                                 <input type="radio" id="medium" name="priority1" defaultValue={2} />
                                 <label htmlFor="low1" style={{ verticalAlign: 'text-bottom' }}>Low</label>
                                 <input type="radio" id="low" name="priority1" defaultValue={1} defaultChecked />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row">
                           <div className="col-lg-2">
                           </div>
                           <div className="col-md-10 col-lg-10">
                              <div style={{ marginLeft: '-15px' }}>
                                 <label>Focus</label>
                                 <input type="text" ng-model="project.focus" className="text-line-three" ng-maxlength={50} />
                                 <label>Stage</label>
                                 <input type="text" ng-model="project.stage" className="text-line-three" ng-maxlength={50} />
                                 <label>Effort</label>
                                 <input type="text" ng-model="project.effort" className="text-line-three" ng-maxlength={50} />
                                 <label>Type</label>
                                 <input type="text" ng-model="project.type" className="text-line-three" ng-maxlength={50} />
                                 <label>Hours</label>
                                 <input type="text" ng-model="project.hours" className="text-line-three" ng-maxlength={50} />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ marginTop: '0 auto', display: 'none' }}>
                           <div className="col-md-2 col-lg-2 col-12" style={{ paddingLeft: '30px' }}>
                              <label htmlFor="ckextras">Extras</label>
                           </div>
                           <div className="col-md-10 col-lg-10 col-12" style={{ marginTop: '10px' }}>
                              <input type="checkbox" id="ckextras" onChange={(ev) => { console.log("ev", ev.target.value) }} />
                           </div>
                        </div>
                        <div className="form-group row" style={{ marginTop: '0 auto' }}>
                           <div className="col-md-2 col-lg-2 col-12" style={{ display: 'inline-block', paddingLeft: '45px' }}>
                              <img src="/images/Mobile icons-37.png" style={{ width: '20px', height: '20px' }} /><label>Reminders</label>
                           </div>
                           <div className="col-md-10 col-lg-10 d-flex" style={{ backgroundColor: '#e5e5e5', padding: '15px', margin: '0 auto', borderRadius: '5px' }}>
                              <div className="col-md-4 col-lg-4 col-12">
                              </div>
                              <div className="col-md-6 col-lg-6 col-12">
                              </div>
                              <div className="col-md-2 col-lg-2 col-12">

                                 <label style={{ background: 'none' }}>Add Reminder</label>&nbsp;<i className="bx bxs-plus-circle" ng-click="addReminder()" style={{ verticalAlign: 'middle', color: '#e8378f' }} />
                              </div>
                           </div>
                        </div>
                        <div className="form-group row" style={{ float: 'right' }}>
                           <div className="col-md-12 col-lg-12 col-12">
                              <button type="submit" className="btn-custom-1" value="Submit">Submit</button> &nbsp;&nbsp;&nbsp;
                      </div>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>

   );
}

export default ModalActivity;