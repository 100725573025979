import config from '../../services/config.json';
import RecentSearch from '../../../data-services/recent-search-data-service';

export const GET_ALL_RECENT_SEARCH = config.apiEndPoint + RecentSearch.getAllRecentSearch;
export const GET_MAX_RECENT_SEARCH = config.apiEndPoint + RecentSearch.getMaxRecentSearch;
export const GET_WORKSPACE_BY_ID = config.apiEndPoint + RecentSearch.getWorkspaceBoardyById;
export const SAVE_RECENT_SEARCH = config.apiEndPoint + RecentSearch.saveRecentSearch;
export const UPDATE_RECENT_SEARCH = config.apiEndPoint + RecentSearch.updateRecentSearch;
export const DELETE_RECENT_SEARCH = config.apiEndPoint + RecentSearch.deleteRecentSearch;
export const SEARCH_RECENT_SEARCH = config.apiEndPoint + RecentSearch.recentSearch_Search
