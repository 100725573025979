export const formulaFunctions = [
    {
        _id: 'formula_1',
        name: 'IF',
        funcType: 'if',
        content: 'Checks if a condition is met, if so, returns the first value, o/w returns the other.',
        example: 'IF({Some column} > 100, "Big Deal", "Small Deal")',
        expression: 'IF()'
    },
    {
        _id: 'formula_2',
        name: 'SWITCH',
        funcType: 'switch',
        content: 'Checks if a condition on a specific value is met, if so, returns the result of that value, o/w returns the default result (if exists). The pattern is: SWITCH({Column}, "val1", "result1", ["val2", "result2"], ..., ["default"])',
        example: 'SWITCH({Priority}, "High", 3, "Medium", 2, "Low", 1, 0) => 2 (in case priority is "Medium")',
        expression: 'SWITCH()'
    },
    {
        _id: 'formula_3',
        name: 'AND',
        funcType: 'and',
        content: 'Checks if all the given logical conditions are true and if so returns true.',
        example: 'AND(3 > 1, 4 > 2) => true',
        expression: 'AND()'
    },
    {
        _id: 'formula_4',
        name: 'OR',
        funcType: 'or',
        content: 'Checks if one the given logical conditions are true and if so returns true.',
        example: 'OR(3 > 10, 4 > 2) => true',
        expression: 'OR()'
    },
    {
        _id: 'formula_5',
        name: 'XOR',
        funcType: 'xor',
        content: 'Returns a logical Exclusive Or of all arguments.',
        example: 'XOR(3>0, 2>9) => TRUE',
        expression: 'XOR()'
    },
    {
        _id: 'formula_6',
        name: 'EXACT',
        funcType: 'exact',
        content: 'Compares two values, returns TRUE if they are the same and FALSE if they are different.',
        example: 'EXACT({Status},{Status})) =>TRUE',
        expression: 'EXACT()'
    },
    {
        _id: 'formula_7',
        name: 'AVERAGE',
        funcType: 'average',
        content: 'Returns the average of the given numeric values.',
        example: 'AVERAGE(1,2,3) => 2',
        expression: 'AVERAGE()'
    },
    {
        _id: 'formula_8',
        name: 'COUNT',
        funcType: 'count',
        content: 'Counts the number of numerical items',
        example: 'COUNT(1, 2, "a") => 2',
        expression: 'COUNT()'
    },
    {
        _id: 'formula_9',
        name: 'SUM',
        funcType: 'sum',
        content: 'Sums up all the given numbers.',
        example: 'SUM(2, 3, 8) => 13',
        expression: 'SUM()'
    },
    {
        _id: 'formula_10',
        name: 'MOD',
        funcType: 'mod',
        content: 'Returns the remainder of the division of the given number in the divisor.',
        example: 'MOD(10, 3) => 1',
        expression: 'MOD()'
    },
    {
        _id: 'formula_11',
        name: 'ROUND',
        funcType: 'round',
        content: 'Rounds a number to a specific number of digits.',
        example: 'ROUND(1.12345, 3) => 1.123',
        expression: 'ROUND()'
    },
    {
        _id: 'formula_12',
        name: 'ROUNDUP',
        funcType: 'roundup',
        content: 'Always rounds a number up.',
        example: 'ROUNDUP(1.1, 0) => 2',
        expression: 'ROUNDUP()'
    },
    {
        _id: 'formula_13',
        name: 'ROUNDDOWN',
        funcType: 'rounddown',
        content: 'Always rounds a number down.',
        example: 'ROUNDDOWN(1.1, 0) => 1',
        expression: 'ROUNDDOWN()'
    },
    {
        _id: 'formula_14',
        name: 'LOG',
        funcType: 'log',
        content: 'Gets the logarithm of a number.',
        example: 'LOG(16, 2) => 4',
        expression: 'OR()'
    },
    {
        _id: 'formula_15',
        name: 'MIN',
        funcType: 'min',
        content: 'Returns the smallest value from a set of data.',
        example: 'MIN(1, 3, 5, 9) => 1',
        expression: 'LOG()'
    },
    {
        _id: 'formula_16',
        name: 'MAX',
        funcType: 'max',
        content: 'Returns the largest value from a set of data.',
        example: 'MAX(1, 3, 5, 9) => 9',
        expression: 'MAX()'
    },
    {
        _id: 'formula_17',
        name: 'MINUS',
        funcType: 'minus',
        content: 'Difference of two numbers.',
        example: 'MINUS(5, 3) => 2',
        expression: 'MINUS()'
    },
    {
        _id: 'formula_18',
        name: 'MULTIPLY',
        funcType: 'multiply',
        content: 'Product of two numbers.',
        example: 'MULTIPLY(5, 2) => 10',
        expression: 'MULTIPLY()'
    },
    {
        _id: 'formula_19',
        name: 'DIVIDE',
        funcType: 'divide',
        content: 'One number divided by another.',
        example: 'DIVIDE(10, 5) => 2',
        expression: 'DIVIDE()'
    },
    {
        _id: 'formula_20',
        name: 'SQRT',
        funcType: 'sqrt',
        content: 'Positive square root of a positive number.',
        example: 'SQRT(9) => 3',
        expression: 'SQRT()'
    },
    {
        _id: 'formula_21',
        name: 'POWER',
        funcType: 'power',
        content: 'A number raised to a power.',
        example: 'POWER(2,3) => 8',
        expression: 'POWER()'
    },
    {
        _id: 'formula_22',
        name: 'ABS',
        funcType: 'abs',
        content: 'Returns the absolute value of a given number.',
        example: 'ABS(-1) => 1',
        expression: 'ABS()'
    },
    {
        _id: 'formula_23',
        name: 'CONCATENATE',
        funcType: 'concatenate',
        content: 'Joins several strings into one string.',
        example: 'CONCATENATE("I", "Heart", "monday.com") => "IHeartmonday.com"',
        expression: 'CONCATENATE()'
    },
    {
        _id: 'formula_24',
        name: 'REPLACE',
        funcType: 'replace',
        content: 'Replaces a part of a string with the new string.',
        example: 'REPLACE("Goat",1, 2,"Fl") => "Flat"',
        expression: 'REPLACE()'
    },
    {
        _id: 'formula_25',
        name: 'SUBSTITUTE',
        funcType: 'substitute',
        content: 'Replace text in a given text string by matching.',
        example: 'SUBSTITUTE("goodmorning", "morning", "night") => goodnight',
        expression: 'SUBSTITUTE()'
    },
    {
        _id: 'formula_26',
        name: 'SEARCH',
        funcType: 'search',
        content: 'Searches a string within another string',
        syntax: 'Searches a string within another string',
        example: 'IF(SEARCH("love", "I love monday", 1) > 0, "Exist", "Not") => "exist"',
        expression: 'SEARCH()'
    },
    {
        _id: 'formula_27',
        name: 'LEFT',
        funcType: 'left',
        content: 'Extracts a given number of characters from the left side.',
        example: 'LEFT("monday.com", 3) => mon',
        expression: 'LEFT()'
    },
    {
        _id: 'formula_28',
        name: 'RIGHT',
        funcType: 'right',
        content: 'Extracts a number of characters from the right side of a given text string.',
        example: 'RIGHT("monday", 3) => day',
        expression: 'RIGHT()'
    },
    {
        _id: 'formula_29',
        name: 'LEN',
        funcType: 'len',
        content: 'Returns the amount of characters of a given text string.',
        example: 'LEN("hello") => 5',
        expression: 'LEN()'
    },
    {
        _id: 'formula_30',
        name: 'REPT',
        funcType: 'rept',
        content: 'Repeats a string a given number of times.',
        example: 'REPT("monday",3) =>mondaymondaymonday',
        expression: 'REPT()'
    },
    {
        _id: 'formula_31',
        name: 'TRIM',
        funcType: 'trim',
        content: 'Removes all spaces from a string except for single spaces between words.',
        example: 'TRIM(" I love MDY ") => "I love MDY"',
        expression: 'TRIM()'
    },
    {
        _id: 'formula_32',
        name: 'UPPER',
        funcType: 'upper',
        content: 'Converts a specified string to uppercase.',
        example: 'UPPER("monday.com") => "MONDAY.COM"',
        expression: 'UPPER()'
    },
    {
        _id: 'formula_33',
        name: 'LOWER',
        funcType: 'lower',
        content: 'Converts a specified string to lowercase.',
        example: 'LOWER("Some STRING") => "some string"',
        expression: 'LOWER()'
    },
    {
        _id: 'formula_34',
        name: 'DATE',
        funcType: 'date',
        content: 'Returns the date value for the given parameters.',
        example: 'DATE(2018, 5, 30) => Wed May 30 2018 00:00:00 GMT+0300 (Israel Daylight Time)',
        expression: 'DATE()'
    },
    {
        _id: 'formula_35',
        name: 'DAYS',
        funcType: 'days',
        content: 'Returns the number of days between the two dates.',
        example: 'DAYS({end date column name}, {start date column name}) => diff between dates in days',
        expression: 'DAYS()'
    },
    {
        _id: 'formula_36',
        name: 'WORKDAYS',
        funcType: 'workdays',
        content: 'Returns the number of working days between two dates. "Working days" are defined as (inclusive), according to your account settings.',
        example: 'WORKDAYS({TO_DATE}, {FROM_DATE}) => working days between dates in days',
        expression: 'WORKDAYS()'
    },
    {
        _id: 'formula_37',
        name: 'TODAY',
        funcType: 'today',
        content: 'Gets the current date (client time) - same as NOW().',
        example: 'TODAY(1) => Fri Jul 06 2018 15:43:31 GMT-0700 (Pacific Daylight Time)',
        expression: 'TODAY(1)'
    },
    {
        _id: 'formula_38',
        name: 'FORMAT_DATE',
        funcType: 'format_date',
        content: 'Returns a formatted date.',
        example: 'FORMAT_DATE(TODAY()) => "Feb 16, 2020" (using default format) FORMAT_DATE(TODAY(), "YYYY-MM-DD") => "2020-02-16" FORMAT_DATE(TODAY(), "dddd, MMMM Do YYYY") => Sunday, February 16th 2020',
        expression: 'FORMAT_DATE()'
    },
    {
        _id: 'formula_39',
        name: 'YEAR',
        funcType: 'year',
        content: 'Returns the year of a given date.',
        example: 'YEAR({created at}) => 2018',
        expression: 'YEAR()'
    },
    {
        _id: 'formula_40',
        name: 'MONTH',
        funcType: 'month',
        content: 'Returns the month of a given date.',
        example: 'MONTH({created at}) => 7',
        expression: 'MONTH()'
    },
    {
        _id: 'formula_41',
        name: 'WEEKNUM',
        funcType: 'weeknum',
        content: 'Returns the yearly week number of a given date.',
        example: 'WEEKNUM({created at}) => 42',
        expression: 'WEEKNUM()'
    },
    {
        _id: 'formula_42',
        name: 'DAY',
        funcType: 'day',
        content: 'Returns the day of the month of a given date.',
        example: 'DAY({created at}) => 31',
        expression: 'DAY()'
    },
    {
        _id: 'formula_43',
        name: 'HOUR',
        funcType: 'hour',
        content: 'Returns the hour as a number from 0 (12:00 A.M.) to 23 (11:00 P.M.).',
        example: "HOUR(NOW()) => 23 (in case it's 23: 00 - 23: 59 o'clock now)",
        expression: 'HOUR()'
    },
    {
        _id: 'formula_44',
        name: 'MINUTE',
        funcType: 'minute',
        content: 'Returns the minute as a number from 0 to 59.',
        example: "MINUTE(NOW()) => 10 (in case it's 23:10 now)",
        expression: 'MINUTE()'
    },
    {
        _id: 'formula_45',
        name: 'SECOND',
        funcType: 'second',
        content: 'Returns the second as a number from 0 to 59.',
        example: "SECOND(NOW()) => 1 (in case it's 23:00:01 now)",
        expression: 'SECOND()'
    },
    {
        _id: 'formula_46',
        name: 'ADD_DAYS',
        funcType: 'add_days',
        content: 'Adds days to the given date. Returns the new date.',
        example: 'ADD_DAYS("2019-01-20", 5) ADD_DAYS(TODAY(), 5) FORMAT_DATE(ADD_DAYS("2019-01-20", 5) => "2019-01-25',
        expression: 'ADD_DAYS()'
    },
    {
        _id: 'formula_47',
        name: 'SUBTRACT_DAYS',
        funcType: 'subtract_days',
        content: 'Subtract days from the given date. Returns the new date.',
        example: 'SUBTRACT_DAYS("2019-01-20", 5) SUBTRACT_DAYS(TODAY(), 5) FORMAT_DATE(SUBTRACT_DAYS("2019-01-20", 5) => "2019-01-15',
        expression: 'SUBTRACT_DAYS()'
    },
    {
        _id: 'formula_48',
        name: 'HOURS_DIFF',
        funcType: 'hours_diff',
        content: 'Returns the difference between two hour columns',
        example: 'HOURS_DIFF("23:00", "20:00") => 03:00',
        expression: 'HOURS_DIFF()'
    }
]

export const constants = [
    {
        _id: 'formula_50',
        name: 'TRUE',
        funcType: 'true',
        content: 'Logical true value',
        example: 'formula.constants.true.example',
        expression: 'TRUE'
    },
    {
        _id: 'formula_51',
        name: 'FALSE',
        funcType: 'false',
        content: 'Logical false value',
        example: 'formula.constants.false.example',
        expression: 'FALSE'
    },
    {
        _id: 'formula_52',
        name: 'PI',
        funcType: 'pi',
        content: 'PI value (3.14159..)',
        example: 'formula.constants.pi.example',
        expression: 'PI'
    }
]