import React, { Component } from 'react';
import MainMenu from '../../shared/components/main-menu';
import NavBar from '../../shared/components/navbar/navbar';
import Notification from '../notification/notification';

class searchGeneral extends Component {
  state = {}
  render() {
    return (
      <>
        <MainMenu link={`search`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div>
                <header className="codrops-header">
                  <div className="search-wrap">
                    {/* <svg className="icon icon--search"><use xlinkHref="#icon-search" /></svg> */}
                    <button id="btn-search" className="btn btn--search">Search</button>
                  </div>
                </header>
                <div className="search">
                  <button id="btn-search-close" className="btn btn--search-close" aria-label="Close search form"><svg className="icon icon--cross"><use xlinkHref="#icon-cross" /></svg></button>
                  <form className="search__form" action>
                    <input className="search__input" name="search" type="search" placeholder="drones" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" />
                    <span className="search__info">Hit enter to search or ESC to close</span>
                  </form>
                  <div className="search__related">
                    <div className="search__suggestion">
                      <h3>May We Suggest?</h3>
                      <p>#drone #funny #catgif #broken #lost #hilarious #good #red #blue #nono #why #yes #yesyes #aliens #green</p>
                    </div>
                    <div className="search__suggestion">
                      <h3>Is It This?</h3>
                      <p>#good #red #hilarious #blue #nono #why #yes #yesyes #aliens #green #drone #funny #catgif #broken #lost</p>
                    </div>
                    <div className="search__suggestion">
                      <h3>Needle, Where Art Thou?</h3>
                      <p>#broken #lost #good #red #funny #hilarious #catgif #blue #nono #why #yes #yesyes #aliens #green #drone</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </>
    );
  }
}

export default searchGeneral;