import React from 'react';

function AllTaskTableWidget(props) {
    const { ShowAllTasksWidgetValue, ShowABValue, ShowTaskValue, ShowProjectValue, ShowCategoryValue, ShowPriorityValue, ShowDueDateValue, ShowStatusValue,
        ShowTotalMessagesValue, ShowAssignessValue, getAllTasks
    } = props
    return (
        <>
            {
                ShowAllTasksWidgetValue &&
                <div className="row border small-responsive-inner-second" style={{ borderRadius: '15px', boxShadow: '0px 0px 9px 2px #EAEAEA', marginRight: '10px', padding: '10px' , marginLeft : '2px' }}>
                    <div className="col-md-12 col-lg-12 col-xl-12" id="top-section">
                        <div style={{ float: 'left' }}>
                            <h5 className="left-head">All Tasks</h5>
                        </div>
                        <div style={{ float: 'right', marginTop: '-2px' }}>
                            <a title="Reply" data-toggle="modal" data-target="#modal-City">
                                <span className="hidden-sm-down right-head">&nbsp;&nbsp;&nbsp;View All</span>
                            </a>
                        </div>
                    </div>
                    <div className="row" style={{ padding: '0px 26px 0px 26px' }}>
                        <table className="task-table responsive">
                            <tbody><tr style={{ padding: '5px' }}>
                                {ShowABValue && <th style={{ textAlign: 'center' }}>AB</th>}
                                {ShowTaskValue && <th style={{ width: '170px', textAlign: 'left' }}>Task</th>}
                                {ShowProjectValue && <th style={{ textAlign: 'left' }}>Project</th>}
                                {ShowCategoryValue && <th style={{ width: '100px', textAlign: 'center' }}>Category</th>}
                                {ShowPriorityValue && <th style={{ width: '100px', textAlign: 'center' }}>Priority</th>}
                                {ShowDueDateValue && <th style={{ width: '100px', textAlign: 'center' }}>Due Date</th>}
                                {ShowStatusValue && <th style={{ textAlign: 'center' }}>Status</th>}
                                {ShowTotalMessagesValue && <th style={{ width: '120px', textAlign: 'center' }}>Total Messages</th>}
                                {ShowAssignessValue && <th style={{ textAlign: 'center' }}>Assignees</th>}
                            </tr>
                            </tbody><tbody>

                                {
                                    getAllTasks.map((data) => (
                                        <tr className="first-row">
                                            {ShowABValue &&
                                                <a href="/tasks">
                                                    <td style={{ fontWeight: 500 }}>
                                                        <img className="round" src="./images/usericon.png" alt="avatar" style={{ width: '30px', height: '30px' }} />
                                                    </td>
                                                </a>
                                            }
                                            {ShowTaskValue &&
                                                <td style={{ fontWeight: 300, textAlign: 'left', fontSize: '12px', color: '#575756' }}>
                                                    {data.title}
                                                </td>
                                            }
                                            {ShowProjectValue && <td style={{ textAlign: 'left', fontSize: '13px', fontWeight: 600, color: '#575756' }}>---</td>}

                                            {ShowCategoryValue &&
                                                <td style={{ textAlign: 'center' }}>
                                                    <div style={{ backgroundColor: '#E8378F', minHeight: '20px', color: '#ffffff', borderRadius: '5px', textAlign: 'center', width: 'fit-content', margin: '0 auto', fontWeight: '300', }}>&nbsp;&nbsp;---&nbsp;&nbsp;</div>
                                                </td>
                                            }
                                            {ShowPriorityValue &&
                                                <td>
                                                    <div style={{ backgroundColor: '#f35800d1', minHeight: '20px', color: '#ffffff', borderRadius: '5px', padding: '1px 3px', textAlign: 'center', width: 'fit-content', margin: '0 auto', fontWeight: '300', }}>&nbsp;&nbsp;---&nbsp;&nbsp;</div>
                                                </td>
                                            }
                                            {ShowDueDateValue &&
                                                <td>
                                                    <span>---</span>
                                                </td>
                                            }
                                            {ShowStatusValue &&
                                                <td>
                                                    <span>{data.cellItems.body}</span>
                                                </td>
                                            }
                                            {ShowTotalMessagesValue &&
                                                <td>---</td>
                                            }
                                            {ShowAssignessValue &&
                                                <td>
                                                    <ul className="list-unstyled users-list m-0  d-flex align-items-center">
                                                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Lai Lewandowski" className="avatar pull-up">
                                                            <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ height: '25px', width: '25px' }} />
                                                        </li>
                                                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Elicia Rieske" className="avatar pull-up">
                                                            <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ height: '25px', width: '25px' }} />
                                                        </li>
                                                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Darcey Nooner" className="avatar pull-up">
                                                            <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ height: '25px', width: '25px' }} />
                                                        </li>
                                                        <li data-toggle="tooltip" data-popup="tooltip-custom" data-placement="bottom" data-original-title="Darcey Nooner" className="avatar pull-up">
                                                            <img className="media-object rounded-circle" src="./images/usericon.png" alt="Avatar" style={{ height: '25px', width: '25px' }} />
                                                        </li>
                                                        <li className="avatar pull-up">
                                                            <span className="badge badge-pill badge-light-primary badge-round">+7</span>
                                                        </li>
                                                    </ul>
                                                </td>
                                            }
                                            <td><a className=" ml-75" /></td>
                                        </tr>
                                    ))}
                            </tbody></table>

                    </div>
                </div>
            }
        </>
    );
}
export default AllTaskTableWidget;