export const  getAllCountry = "country/getAllCountry"; 
export const  getCountryById = "country/getCountryById"; 
export const  saveCountry = "country/saveCountry" ; 
export const  updateCountry = "country/updateCountry" ; 
export const  deleteCountry = "country/deleteCountry" ;

export default {
    getAllCountry : getAllCountry , 
    getCountryById : getCountryById , 
    saveCountry : saveCountry ,  
    updateCountry : updateCountry , 
    deleteCountry : deleteCountry 
}

