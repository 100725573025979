import React, { Component } from 'react'
import { EditorState, convertToRaw } from 'draft-js'
import Editor, { createEditorStateWithText } from 'draft-js-plugins-editor'
import createToolbarPlugin from 'draft-js-static-toolbar-plugin'
import createMentionPlugin, {
  defaultSuggestionsFilter
} from 'draft-js-mention-plugin'
import editorStyles from './editorStyle.css'
import 'draft-js-static-toolbar-plugin/lib/plugin.css'
import 'draft-js-mention-plugin/lib/plugin.css'
import { colorStyleMap } from 'draft-js-color-picker-plugin'
import 'draft-js-link-plugin/lib/plugin.css'
import createLinkPlugin from 'draft-js-link-plugin'


import $ from 'jquery'
import userDataService from '../../data-services/users-data-services'
import http from '../services/http-service'
import config from '../services/config.json'
//import api from '../actions/action.team'

const staticToolbarPlugin = createToolbarPlugin()
const { Toolbar } = staticToolbarPlugin
const linkPlugin = createLinkPlugin()

//const plugins = [staticToolbarPlugin]
const mention = []
const mentions = [
  {
    name: 'Matthew Russell'
  },
  {
    name: 'Julian Krispel-Samsel'
  },
  {
    name: 'Jyoti Puri'
  },
  {
    name: 'Max Stoiber'
  },
  {
    name: 'Nik Graf'
  },
  {
    name: 'Pascal Brandt'
  }
]
class RichEditor extends Component {
  constructor (props) {
    super(props)
    this.mentionPlugin = createMentionPlugin()

    // mentions.map(item => {
    //   mention.push({
    //     //name: item.first_name + ' ' + item.last_name
    //     // ,
    //     // id: item._id
    //     name:item.name
    //   })
    // })
    this.state = {
      editorState: EditorState.createEmpty(),
      suggestions: []
    }
  }
  componentDidMount () {
    http.get(config.apiEndPoint + 'users/getAllUsers').then(res => {
      res.data.map(item => {
        mention.push({
          name: item.firstname,
          value: item._id
          //,
          //value: item._id
        })
      })
      this.setState({
        suggestions: mention
      })
      //console.log("mention=>",mention)
      //alert(mention)
    })
  }
  onEditorStateChange = editorState => {
    this.setState({ editorState })
  }
  onSearchChange = ({ value }) => {
    //if (value) {
      this.setState({
        suggestions: defaultSuggestionsFilter(value, mention)
      })
    //}
  }
  onAddMention = object => {
    console.log(object)
  }

  focus = () => {
    this.editor.focus()
  }

  render () {
    const { MentionSuggestions } = this.mentionPlugin
    const plugins = [this.mentionPlugin, staticToolbarPlugin, linkPlugin]
    return (
      <div style={{ 'min-width': '94%' }}>
        <div className='editor' onClick={this.focus}>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onEditorStateChange}
            plugins={plugins}
            ref={element => {
              this.editor = element
            }}
            customStyleMap={colorStyleMap}
          />
          <MentionSuggestions
            onSearchChange={this.onSearchChange}
            suggestions={this.state.suggestions}
            onAddMention={this.onAddMention}
          />
          <Toolbar />
          <div
            style={{ height: '36px' }}
            onClick={() => {
              let contentArray = convertToRaw(
                this.state.editorState.getCurrentContent()
              ).blocks
              let message = ''
              contentArray.map(item => {
                message += item.text + ' '
              })
              let data = {
                taskid: '5f7ea700fae78bcf266e6d36',
                createdby: '5fdb5722b6624b3c12cd85e0',
                message:
                  $('#replyDiv').html() +
                  $('.DraftEditor-editorContainer').html(),
                messagedet: ' ',
                priority: 0,
                type:1
              }
              //   api.addMessage(data).then(res => {
              //     this.setState({ editorState: EditorState.createEmpty() })
              //     $('#replyDiv').empty()
              //     this.editor.focus()
              //   })
              http
                .post(config.apiEndPoint + 'message/createmessage', data)
                .then(res => {
                  this.setState({ editorState: EditorState.createEmpty() })
                  $('#replyDiv').empty()
                  this.editor.focus()
                })
            }}
          >
            <img
              src='./icons/sendmsg.png'
              width='26'
              style={{ margin: '6px' }}
            />
          </div>
        </div>
      </div>
    )
  }
}
export { RichEditor }
