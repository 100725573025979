import React, { useState, useEffect } from 'react';
import ContentEditable from 'react-contenteditable';
import ReactHtmlParser from 'react-html-parser';
import { connect } from 'react-redux';
import { sendMessageReply, updateNestedMessageLikes } from '../../../actions';
//import '../../style/BoardMessages.css'
import ReactTooltip from 'react-tooltip';
import axios from '../../../util/Api';
import Moment from 'react-moment';
import moment from 'moment';
import config from '../../services/config.json';
const socket = require('socket.io-client')(config.baseUrlEndPoint, {
    transports: ['websocket'],
    rejectUnauthorized: false,
})

class NestedMessageContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            messageID: this.props.messageID,
            replies: [],
            editorText: '',
            showUserSuggestions: false,
            userFilterString: '',
            showQuotedMessage: false,
            quotedFrom: '',
            quotedMsg: '',
            messageReceivers: [],
            chatroomID: this.props.chatroomID

        }
        this.timeout = 0;
        this.authUser = JSON.parse(localStorage.getItem('authUser'));
        this.onChange = this.onChange.bind(this);
        this.contentEditable = React.createRef();
    }

  
    componentDidMount() {

        socket.emit('connectNestedMessageRoom', this.state.messageID);
        socket.on("socketEvent_nestedMessageReceived", Obj => {

            if (Obj.message.from !== this.authUser._id) {               
           
                this.setState({ replies: [...this.state.replies, Obj.message] })
            }
        });

        axios.post('/workspace/getRepliesByMessageId', {
            messageID: this.state.messageID,
        }).then(({ data }) => {
            this.setState({ replies: data.replies })
            ReactTooltip.rebuild();
        }).catch(error => {
            console.log("Error****:", error.message);
        })


    }

    componentDidUpdate(prevProps, prevStates) {

        if (this.props.messageID === prevProps.messageID) {

            // if(this.props.socketReply?._id  !== prevProps.socketReply?._id ){
            //     axios.post('/workspace/getRepliesByMessageId', {
            //         messageID: this.props.socketReply?.parent,
            //     }).then(({ data }) => {
    
            //         this.setState({ replies: data.replies })
            //         ReactTooltip.rebuild();
            //     }).catch(error => {
    
            //         console.log("Error****:", error.message);
            //     })
               // this.setState({ replies: [...this.state.replies, this.props.socketReply] })
           // }
            
        }
    }

    componentWillReceiveProps(nextProps) {


        this.setState({ messageID: nextProps.messageID })
        this.setState({ chatroomID: nextProps.chatroomID })

        // if(nextProps?.boardData?.reply?.from){
        //     this.setState({ replies: [...this.state.replies, nextProps?.boardData?.reply] })

        // }

        if (nextProps?.boardData?.reply?.from) {
            axios.post('/workspace/getRepliesByMessageId', {
                messageID: this.state.messageID,
            }).then(({ data }) => {

                this.setState({ replies: data.replies })
                ReactTooltip.rebuild();
            }).catch(error => {

                console.log("Error****:", error.message);
            })

        }

    }

    onSendReply = (event) => {

        if (this.state.showQuotedMessage) {
            this.SendReplyWithQuote();
        } else {
            let reply= {
                parent: this.state.messageID,
                to: this.state.messageReceivers,
                from: this.authUser._id,
                body: this.state.editorText,
                likes: [],
                chatroomID:this.state.chatroomID
            }
            this.props.sendMessageReply(reply)
            this.setState({ editorText: '' })
            this.setState({ messageReceivers: [] })
            this.setState({ replies: [...this.state.replies, reply] })
        }
       // this.scrollToBottom()
       
    }

    getUserdetails = (userID) => {
        let Index = this.props.boardMembers.findIndex(member => member._id === userID);
        return this.props.boardMembers[Index];
    }

    onChange(e) {
        let value = e.target.value;
        this.setState({ editorText: value })
  
        if (value.includes('@') && !value.includes('@ ')) {

            this.setState({ userFilterString: value.split('@').pop() })
            this.setState({ showUserSuggestions: true })
        } else {
            this.setState({ showUserSuggestions: false })
        }
        
    }


    onKeyDown =(e) =>{
    
        let messageReceivers = []
          if( e.key == "Backspace"  ){
           
              var selection = ReactHtmlParser(this.state.editorText);
              
              for (let i = 0; i < selection.length; i++) {
                  if(selection[i]?.props?.uid){
                      messageReceivers.push(selection[i]?.props?.uid)
                  }
                }
                this.setState({ messageReceivers: messageReceivers })
                console.log('messageReceivers',messageReceivers)   
                        
      }
      console.log('messageReceivers1',this.state.messageReceivers)  
    }
      
    clearUserSuggestions = () => {
        this.setState({ showUserSuggestions: false })
    }

    updateLikestatue = (message, likedUserID) => {

        if (message.likes.includes(likedUserID)) {
            message.likes.pop(likedUserID)
        } else {
            message.likes.push(likedUserID)
        }

        this.props.updateNestedMessageLikes({
            messageID: message._id,
            likes: message.likes
        });
    }

    replyWithQuote = (message) => {
        this.setState({ showQuotedMessage: true })
        let fromName = this.getUserdetails(message.from).name
        this.setState({ quotedFrom: fromName })
        this.setState({ quotedMsg: message.body.replace(/<(?:.|\n)*?>/gm, '').replace(fromName, "") })

        this.scrollToBottom()
    }

    SendReplyWithQuote = () => {
        let QuotedMessage = "<div class='quotedmessage'><div class='quoteduser border-bottom'>" + this.state.quotedFrom + "</div><div class='quotedmessagebody'><i class='fa fa-quote-left small text-black-50'></i> " + this.state.quotedMsg + " <i class='fa fa-quote-right small text-black-50'></i></div></div>"
        //let messageBody = this.state.editorText;
        let reply ={
            parent: this.state.messageID,
            to: this.state.messageReceivers,
            from: this.authUser._id,
            likes: [],
            body: QuotedMessage.concat(this.state.editorText),
            chatroomID:this.state.chatroomID
        }
        this.props.sendMessageReply(reply)
        this.setState({ editorText: '' })
        this.setState({ quotedFrom: '' })
        this.setState({ quotedMsg: '' })
        this.setState({ showQuotedMessage: false })
        this.setState({ messageReceivers: [] })
        this.setState({ replies: [...this.state.replies, reply] })
    }

    clearQuotedMsg = () => {
        this.setState({ quotedFrom: '' })
        this.setState({ quotedMsg: '' })
        this.setState({ showQuotedMessage: false })
    }

    scrollToBottom = () => {
        this.el.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    addUsersToMessage = (member) => {
        let msg = this.state.editorText
        msg = msg.substring(0, msg.lastIndexOf("@"));
        this.setState({editorText: msg.concat("<span class='badge badge-primary' uid='"+member._id+"' contenteditable='false'>"+member.name+"</span>&nbsp;")})
        let receivers = this.state.messageReceivers
        receivers.push(member._id)
        this.setState({ messageReceivers: receivers})
        this.setState({userFilterString: ''})
        this.setState({showUserSuggestions:false})
    }


    deleteMessage = (messageID) => {
        axios.post('/workspace/removeBoardNestedMessage', {
            messageID: messageID,

        }).then(({ data }) => {
            this.setState({ replies: this.state.replies.filter(i => i._id !== data.deletedMessage._id) })
        }).catch(error => {
            //  dispatch({ type: FETCH_ERROR, payload: error.message });
            console.log("Error****:", error.message);
        })
    }

    render() {

        return (
            <div className="p-4">
                {this.state.replies
                    .map((reply, index) =>
                    (
                        <div className="bg-light border-radius py-2 px-3 mb-3 border" key={index}>
                            <div className="row">
                                <div className="col-xl-6 small text-bold-600">
                                    {this.getUserdetails(reply?.from)?.name}
                                </div>

                                <div className="col-xl-6">
                                <i className="fa fa-trash float-right mx-3 mt-1 cursor-pointer"
                                                        onClick={event => this.deleteMessage(reply._id)}
                                                        ></i>

                                    <i class="fa fa-reply-all float-right ml-3 border-left cursor-pointer mt-1 text-secondary"
                                        onClick={event => this.replyWithQuote(reply)}
                                    ></i>

                                    <div className="border-right float-right ml-3 border-left small">

                                        {reply.likes.includes(this.authUser._id) ?
                                            (<span className="cursor-pointer text-primary"
                                                onClick={() => this.updateLikestatue(reply, this.authUser._id)}


                                            >
                                                <i class="fa fa-check mr-2" aria-hidden="true"></i>You Liked</span>


                                            ) :
                                            (<span className="cursor-pointer"
                                                onClick={() => this.updateLikestatue(reply, this.authUser._id)}

                                            >
                                                <i class="fa fa-thumbs-up mr-2" aria-hidden="true"></i>Like</span>)
                                        }

                                        {reply.likes.length > 0 && <span class="dropdown dropright">
                                            <span class="badge badge-pill badge-circle-light-white dropdown-toggle ml-2" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                + {reply.likes.length}
                                            </span>
                                            <div class="dropdown-menu" >
                                                {reply.likes
                                                    .map((like, index) =>
                                                    (
                                                        <a class="dropdown-item" key={index}>{this.getUserdetails(like).name}</a>)
                                                    )}
                                            </div>
                                        </span>}
                                    </div>

                                    <time className="float-right small text-black-50"  data-tip={moment(reply.createdAt).format('LLLL')}>
                                        <Moment fromNow>{reply.createdAt}</Moment>

                                    </time>
                                </div>
                            </div>
                            <div className="mt-2">{ReactHtmlParser(reply.body)}</div>


                        </div>
                    ))}

                <div class="input-group input-group-lg mb-3" ref={el => { this.el = el; }}>

                    {this.state.showQuotedMessage &&
                        <div className="refMessageBubble shadow py-2 px-3 mb-3 border small">
                            <div className="border-bottom pb-1">{this.state.quotedFrom}
                                <i className="fa fa-times float-right cursor-pointer"
                                    onClick={event => this.clearQuotedMsg(event)}
                                ></i>
                            </div>
                            <div className="pb-1">
                                {this.state.quotedMsg}
                            </div>
                        </div>
                    }


                    {this.state.showUserSuggestions &&
                        <div className="usersuggestions shadow py-2 px-3 mb-3 border">
                            <div className="border-bottom pb-1">Select User
                                <i className="fa fa-times float-right cursor-pointer"
                                    onClick={event => this.clearUserSuggestions(event)}
                                ></i>
                            </div>
                            {this.props.boardMembers
                                .filter(member => !this.state.messageReceivers.includes(member._id))
                                .filter(member => member.name.toLowerCase().includes(this.state.userFilterString.toLowerCase()))
                                .map((member, index) =>
                                (
                                    <div className="border-bottom py-2 cursor-pointer"
                                        onClick={event => this.addUsersToMessage(member)}
                                        key={index}
                                    >
                                        {member.name}
                                    </div>
                                )
                                )}
                        </div>
                    }

                    <ContentEditable
                        className="form-control border-radius-25"
                        innerRef={this.contentEditable}
                        html={this.state.editorText} 
                        onKeyUp={this.onKeyDown}
                        onChange={this.onChange} 
                        placeholder="Reply"
                    />
                    {/* <input type="text" class="form-control border-radius-25" value={this.state.editorText} onChange={this.onChange} placeholder="Reply" /> */}
                    <div class="input-group-append">
                        <button class="btn btn-success border-radius-25 ml-2" type="submit"
                            onClick={event => this.onSendReply(event)}
                        >Send</button>
                    </div>
                </div>
            </div>



        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { sendMessageReply, updateNestedMessageLikes };

export default connect(mapStateToProps, mapDispatchToProps)(NestedMessageContainer);