import React, { Component } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
class MainMenu extends Component {
   state = {};
   render() {
      return (
         <React.Fragment>
            <div className="main-menu menu-fixed  menu-light  menu-accordion menu-shadow" data-scroll-to-active="true" style={{ float: 'left' }}>
               <div className="navbar-header">
                  <ul className="nav navbar-nav flex-row">
                     <li className="nav-item nav-toggle">
                        <a className="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
                           <i className="bx bx-x d-block d-xl-none font-medium-4 primary" />
                           <i className="toggle-icon bx bx-disc font-medium-1 d-none d-xl-block primary" data-ticon="bx-disc" style={{ color: '#000000' }} />
                        </a>
                     </li>

                     <li className="nav-item mr-auto">
                      
                        <a className="navbar-brand">
                           <div className="brand-logo">
                              <span><img className="profile-round" src="./images/usericon.png" alt="avatar" /></span>
                           </div>
                        </a>                    
                     </li>
                  </ul>
               </div>
               <div className="shadow-bottom" />
               <div id="mySidebar" className="sidebar">
                  <a href="#" className="closebtn">×</a>
                  <a href="#">About</a>
                  <a href="#">Services</a>
                  <a href="#">Clients</a>
                  <a href="#">Contact</a>
               </div>
               <div className="main-menu-content">
                  <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation" data-icon-style="lines">
                     <li className={`nav-item ${this.props.link === 'dashbaord' ? 'active' : ''}`}>
                        <Link to="/dashboard">
                           <img src="./images/icon/panel-icons/Panel icons-33.png" className="panel-icon" style={{ width: '30px' }} alt="" data-toggle="tooltip" data-title="Dashboard" />
                           <span className="menu-title">Dashboard</span>
                        </Link>
                     </li>

                     <li className={`nav-item ${this.props.link === 'board' ? 'active' : ''}`}>
                        <Link to="/board" id="workspace" >
                           <img src="./images/icon/panel-icons/Panel icons-29.png" className="panel-icon" style={{ width: '30px' }} alt="" data-toggle="tooltip" data-title="Workspace" />
                           <span className="menu-title">Workspace</span>
                        </Link>
                     </li>


                     <li className="nav-item nav-toggle">
                        <a style={{ 'cursor' : 'pointer' }} className="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
                           <img src="./images/icon/panel-icons/Panel icons-02.png" className="panel-icon" style={{ width: '30px' }} alt="" data-toggle="tooltip" data-title="Task Manager"/>
                           <div style={{ marginLeft: '-19px' }} ><span className="menu-title"><i className="bx bx-chevrons-down" style={{ verticalAlign: 'bottom', marginLeft: '3px' }} /> Task Manager</span></div>
                        </a>
                        <ul className="menu-content">
                           <li>
                              <Link to="/discover" >
                                 <i className="bx bx-chevrons-right" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Discover</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/tasks">
                                 <i className="bx bxs-pencil" aria-hidden="true" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;All Task</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="#!Task/1">
                                 <i className="bx bx-pencil" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Created Task</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="#!Task/2">
                                 <i className="bx bx-heart" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Following Task</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/assigned">
                                 <i className="bx bx-paper-plane" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Assigned Task</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="#!Task">
                                 <i className="bx bxs-group" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Shared Task</span>
                              </Link>
                           </li>
                           
                           <li>
                              <Link to="/unread">
                                 <i className="bx bx-album" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Unread Task</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/ongoing">
                                 <i className="bx bx-loader" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Ongoing Task</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/completed">
                                 <i className="bx bx-check-double" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Completed Task</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/inbox">
                                 <i className="bx bx-message" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Inbox</span>
                              </Link>
                           </li>
                        </ul>
                     </li>

                     <li className="nav-item ">
                        <Link to="/econnect">
                           <img src="./images/icon/panel-icons/Panel icons-04.png" className="panel-icon" style={{ width: '30px' }} alt="" data-toggle="tooltip" data-title="E-Connect"/>
                           <span className="menu-title">E-Connect</span>
                        </Link>
                     </li>
                     <li  className={`nav-item ${this.props.link === 'messenger' ? 'active' : ''}`}>
                        <Link to="/messenger">
                           <img src="./images/icon/panel-icons/messenger-icon.png" className="panel-icon" style={{ width: '30px' }} alt="" data-toggle="tooltip" data-title="Messenger"/>
                           <span className="menu-title">Messenger</span>
                        </Link>
                     </li>
                     <li className={`nav-item ${this.props.link === 'scheduler' ? 'active' : ''}`}>
                        <Link to="/scheduler">
                           <img src="./images/icon/panel-icons/Panel icons-03.png" className="panel-icon" style={{ width: '30px' }} alt="" data-toggle="tooltip" data-title="Scheduler"/>
                           <span className="menu-title">Scheduler</span>
                        </Link>
                     </li>
                     <li className="nav-item ">
                        <a style={{ 'cursor' : 'pointer' }}>
                           <img src="./images/icon/panel-icons/Panel icons-06.png" className="panel-icon" style={{ width: '30px' }} data-toggle="tooltip" data-title="Master Settings"/>
                           <div style={{ marginLeft: '-19px' }}><span className="menu-title"><i className="bx bx-chevrons-down" style={{ verticalAlign: 'bottom', marginLeft: '3px' }} />Master Settings</span></div>
                           {/*-<span className="badge badge-light-danger badge-pill badge-round float-right mr-2">2</span>*/}
                        </a>
                        <ul className="menu-content">
                           <li>
                              <Link to="/Company">
                                 <i className="bx bx-briefcase" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Company</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/planmaster">
                                 <i className="bx bx-bar-chart-alt" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Plan Master</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/planapproval">
                                 <i className="bx bx-task" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Plan Approval</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/module">
                                 <i className="bx bxs-component" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Module</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/submodule">
                                 <i className="bx bxl-redux" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Submodule</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/BusinessCategory">
                                 <i className="bx bx-briefcase-alt" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Business Category</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/Department">
                                 <i className="bx bxs-landmark" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Department</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/country">
                                 <i className="bx bxs-flag-alt" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Country</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/state">
                                 <i className="bx bxs-bank" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;State</span>
                              </Link>
                           </li>

                           <li>
                              <Link to="/city">
                                 <i className="bx bxs-home-circle" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;City</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/rolemaster">
                                 <i className="bx bx-user-plus" aria-hidden="true" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Role Master</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/userlist">
                                 <i className="bx bxs-file" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;User List</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/userleveltype">
                                 <i className="bx bx-columns" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;User Level Type</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/forms">
                                 <i className="bx bx-spreadsheet" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Forms</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/currency">
                                 <i className="bx bx-dollar-circle" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Currency</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/visibility">
                                 <i className="bx bx-show" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Visibility</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/entitytype">
                                 <i className="bx bxs-book" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Entity Type</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/license">
                                 <i className="bx bxs-id-card" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;License</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/defaultmenu">
                                 <i className="bx bx-menu" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;Default Menu</span>
                              </Link>
                           </li>
                           <li>
                              <Link to="/usermenulist">
                                 <i className="bx bx-detail" id="icon-rotate" />
                                 <span className="menu-item">&nbsp;User Menu List</span>
                              </Link>
                           </li>
                        </ul>
                     </li>
                     <li className="nav-item ">
                        <a href="#!/Client">
                           <img src="./images/icon/panel-icons/Panel icons-07.png" className="panel-icon" style={{ width: '30px' }} alt="" data-toggle="tooltip" data-title="Client Desk"/>
                           <span className="menu-title">&nbsp;Client Desk</span>
                        </a>
                     </li>

                     <li className="nav-item ">
                        <a style={{ 'cursor' : 'pointer' }}>
                           <img src="./images/icon/panel-icons/Panel icons-13.png" className="panel-icon" style={{ width: '30px' }} alt="" data-toggle="tooltip" data-title="User Settings"/>
                           <div style={{ marginLeft: '-19px' }}><span className="menu-title"><i className="bx bx-chevrons-down" style={{ verticalAlign: 'bottom', marginLeft: '3px' }} /> User Settings</span></div>
                        </a>
                        <ul className="menu-content">
                           <li>
                              <Link to="/globalsettings">
                                 <i className="bx bx-dashboard" id="icon-rotate" />
                                 <span className="menu-item">Global Settings</span>
                              </Link>
                           </li>

                           <li>
                              <Link to="/dashboard-setting">
                                 <i className="bx bx-pencil" id="icon-rotate" />
                                 <span className="menu-item">Dashboard Settings</span>
                              </Link>
                           </li>

                           <li>
                              <Link to="/profileuser">
                                 <i className="bx bx-pencil" id="icon-rotate" />
                                 <span className="menu-item">My Profile</span>
                              </Link>
                           </li>

                        </ul>
                     </li>


                  </ul>

                  <div style={{ marginLeft: '20px' , marginTop : '12px' }}>
                     <span style={{ fontSize: '12.5px', cursor: 'pointer', color: '#fff', fontWeight: '300' }} onClick={() => {
                        document.getElementById("globalSearchNav").style.width = "100%";
                     }}><img src="./images/icon/panel-icons/Panel icons-30.png" className="panel-icon" style={{ width: '30px' }} alt="" data-toggle="tooltip" data-title="General Search"/>
                        General Search
                     </span>
                  </div>
               </div>
            </div>
            <div>
            </div>
         </React.Fragment>

      );
   }
}

export default withRouter(MainMenu);
