export const getAllWorkSpaceBoard = "search/getAllWorkSpacesBordByUser";
export const getAllSearchWorkSpaceBoard = "search/getSearchWorkspaceBoardyById";
export const getSearchUpdates = "search/getSearchUpdates";
export const getWorkspaceByBoardId = "search/getWorkspaceByBoardId";

export default {
    getAllWorkSpaceBoard: getAllWorkSpaceBoard,
    getAllSearchWorkSpaceBoard:getAllSearchWorkSpaceBoard,
    getSearchUpdates:getSearchUpdates,
    getWorkspaceByBoardId:getWorkspaceByBoardId
}