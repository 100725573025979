import React from 'react';
import { connect } from 'react-redux';
import { changeBoardItem, fetchError } from '../../../../actions'
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import { getMyPermission, getMyGroupEditPermission, getMyColumnEditPermission } from '../../../../util/permission';

class FileItem extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            address: '',
            searchInputFocus: false,
            actionPermission: 'edit'
        };
    }

    componentDidMount() {
        this.setState({ address: this.props.body || '' });

        // if (this.props.boardData.boardID) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem.cellItems;
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    componentDidUpdate(prevProps, prevStates) {
        // if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {
        //     let workspaceBoard = this.props.boardData.workspaceBoard;
        //     let boardPermission = workspaceBoard.permission;
        //     let members = JSON.parse(workspaceBoard.members);
        //     let groupItem = this.props.boardData.data[this.props.groupInd].groupItems[this.props.rowInd];
        //     let cellItems = groupItem.cellItems;
        //     let peoples = cellItems.find(cell => cell.key === 'people');
        //     let people = peoples !== undefined ? JSON.parse(peoples.body) : [];
        //     let permission = getMyPermission(boardPermission, members, people);
        //     if (permission !== 'low') {
        //         this.setState({ actionPermission: 'edit' })
        //     } else {
        //         this.setState({ actionPermission: 'view' })
        //     }
        // }
    }

    handleChange = address => {
        // if(this.state.actionPermission === 'edit' && getMyGroupEditPermission(this.props.search.boarddata[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.search.data[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
        //     this.setState({ address });
        // } else {
        //     this.props.fetchError("You don't have permission. You can only view.")
        // }        
    };
     
    handleSelect = address => {
        if(this.state.actionPermission === 'edit'  && getMyGroupEditPermission(this.props.search.boarddata[this.props.groupInd].members)  && getMyColumnEditPermission(this.props.search.boarddata[this.props.groupInd].groupItems[0].cellItems[this.props.colInd].restrictedMembers)) {
            this.setState({ address });
            const { groupInd, rowInd, colInd, _id } = this.props;
            this.props.changeBoardItem({
                groupInd: groupInd,
                rowInd: rowInd,
                colInd: colInd,
                body: address,
                cellItemID: _id,
                boardId: this.props.search.boardID
            });
            geocodeByAddress(address)
                .then(results => getLatLng(results[0]))
                .then(latLng => console.log('Success', latLng))
                .catch(error => console.error('Error', error));            
        } else {
            this.props.fetchError("You don't have permission. You can only view.")
        }  
    };

    render() {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{ height: '94%' }}>
                <PlacesAutocomplete
                    value={this.state.address}
                    onChange={this.handleChange}
                    onSelect={this.handleSelect}
                    shouldFetchSuggestions={this.state.address.length >= 0}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className="location-element d-flex align-items-center" style={{ position: 'relative', margin: '0 4px' }}>
                        {
                            this.state.address === '' ? (
                                <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                    <path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602"/>
                                </svg>
                            ) : (
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                    <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/>
                                </svg>
                            )
                        }
                        <input
                        style={{ width: '100%' }}
                        {...getInputProps({
                            placeholder: 'Search location',
                            className: 'location-search-input',
                            autoFocus: true
                        })}
                        />
                        <div className="autocomplete-dropdown-container"
                        style={{ position: 'absolute', top: '24px', right: '0', width: '300px', zIndex: 9,
                        boxShadow: 'rgba(55, 75, 90, 0.8) 1px -4px 20px -2px', borderRadius: '8px', background: 'white', marginTop: '8px' }}>
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                ? 'suggestion-item--active'
                                : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                <div
                                    {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                    })}
                                    key={index}
                                    style={{ background: 'transparent', cursor: 'pointer' }}
                                >
                                    { index !== 0 && <hr className="my-1"></hr> }
                                    <div className="px-2 d-flex align-items-center" style={{ marginTop: index === 0 ? '4px' : '', marginBottom: index === (suggestions.length - 1) ? '4px' : '' }}>
                                        
                                        {/* <svg className="svg-icon" viewBox="0 0 20 20" style={{ fontSize: '20px', width: '20px', height: '20px' }}>
                                            <path d="M10,1.375c-3.17,0-5.75,2.548-5.75,5.682c0,6.685,5.259,11.276,5.483,11.469c0.152,0.132,0.382,0.132,0.534,0c0.224-0.193,5.481-4.784,5.483-11.469C15.75,3.923,13.171,1.375,10,1.375 M10,17.653c-1.064-1.024-4.929-5.127-4.929-10.596c0-2.68,2.212-4.861,4.929-4.861s4.929,2.181,4.929,4.861C14.927,12.518,11.063,16.627,10,17.653 M10,3.839c-1.815,0-3.286,1.47-3.286,3.286s1.47,3.286,3.286,3.286s3.286-1.47,3.286-3.286S11.815,3.839,10,3.839 M10,9.589c-1.359,0-2.464-1.105-2.464-2.464S8.641,4.661,10,4.661s2.464,1.105,2.464,2.464S11.359,9.589,10,9.589"></path>
                                        </svg> */}
                                        <div>
                                            <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                <path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602"/>
                                            </svg>
                                        </div>
                                        <div className="text-truncate">
                                            <span style={{ fontWeight: 'bold' }}> {suggestion.formattedSuggestion.mainText} </span>
                                            <span> {suggestion.formattedSuggestion.secondaryText} </span>
                                        </div>
                                    </div>
                                </div>
                                );
                            })}
                        </div>
                    </div>
                    )}
                </PlacesAutocomplete>
            </div>
        )
    }
}

const mapStateToProps = ({ search }) => {
    return { search }
}

const mapDispatchToProps = { changeBoardItem, fetchError }

export default connect(mapStateToProps, mapDispatchToProps)(FileItem)