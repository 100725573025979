import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import { connect } from 'react-redux'
import Workspace from "./components/workspace/WorkSpace";
import Messenger from './components/messenger/messenger';
import LoginPage from "./components/auth/login/login.jsx";
import Logout from './components/auth/login/logout';
import ProfileUser from "./components/auth/profile-user/profile-user.jsx";
import Discover from './components/discover/discover';
import DiscoverWorkspace from './components/discover/discover-workspace';
import DiscoverSubWorkspace from './components/discover/discover-subworkspace';
import DiscoverSubWorkspaceL2 from './components/discover/discover-subworkspace-l2';
import DiscoverBoard from './components/discover/discover-board';
import DiscoverSubBoard from './components/discover/discover-subboard';
import DiscoverGroup from './components/discover/discover-group';
import EngageScheduler from "./components/scheduler/scheduler"
import GlobalSettings from "./components/globalsettings/globalsettings"
import AddActivity from './components/tasks/addactivity';
import Company from './components/master/company/company.jsx';
import PlanMaster from './components/master/planmaster.jsx';
import Country from './components/master/country/country.jsx';
import City from './components/master/city/city.jsx';
import State from './components/master/state/state';
import BusinessCategory from './components/master/business-category/business-category.jsx';
import Module from './components/master/Module.jsx';
import SubModule from './components/master/Submodule.jsx';
import UserList from './components/master/Userlist.jsx';
import UserMenuList from './components/master/Usermenulist.jsx';
import License from './components/master/License.jsx';
import Forms from './components/master/Forms.jsx';
import PlanApproval from './components/master/planapproval.jsx';
import EntityType from './components/master/Entitytype.jsx';
import Currency from './components/master/currency.jsx';
import Visibility from './components/master/Visibility.jsx';
import DefaultMenu from './components/master/Defaultmenu.jsx';
import UserLevelType from './components/master/Userleveltype.jsx';
import RoleMaster from './components/master/Rolemaster.jsx';
import Department from './components/master/Department.jsx';
import AllTasks from './components/tasks/alltasks';
import Assigned from './components/tasks/taskassigned';
import OnGoing from './components/tasks/ongoing';
import Completed from './components/tasks/completed';
import UnreadTask from './components/tasks/unread';
import Econnect from './components/e-connect';
import Inbox from './components/inbox/inbox';
import StickyMenu from './components/Extras/StickyMenu';
import Register from './components/auth/register/register.jsx';
import DashBoardSetting from './components/user-settings/dashboard-settings/dashboard-setting';
import GeneralSearch from './components/general-search/general-search.jsx'
import DashBoardCharts from './components/dashboard/dashboard-charts';
import NotFound from './not-found/not-found';
import FAQ from './components/faq/faq.jsx';
import *  as UserConstant from './components/auth/constant/users-contant';
import * as UserService from './components/auth/auth-services/user-service.jsx';
import "./App.css";
import ToastrComponent from "./shared/components/ToastrComponent";
import { getUser, setInitUrl, changeWorkspaceBoard,navigateToBoardFromNotification } from "./actions";
import axios from "./util/Api";
import UserProfile from './components/user-settings/user-profile';
import ResponsiveLocalStorageLayout from '../src/components/dashboard/draggable.jsx'
import searchGeneral from './components/general-search/searchGeneral';
import CheckInvitation from "./shared/CheckInvitation";
import Home from './components/home/Landing';
import GlobalSearch from './components/global-search'
// import { useLocation } from 'react-router-dom'
// const location = useLocation();

const RestrictedRoute = ({ component: Component,path, token, ...rest }) => {
    return (
        <Route
            {...rest}
            key={path}
            render={props =>
                token ?
                    <Component {...props} />
                    : <Redirect
                        to={{
                            pathname: '/',
                            state: { from: props.location }
                        }}
                    />}
        />
    );
};

const history = createBrowserHistory()
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null
        }
    }

    componentDidMount() {
        // try {
        //get the current user from token
        const user = UserService.getUserByToken(UserConstant.TOKEN_NAME);
        this.setState({ user })

        //redirect to home page if user already register
        // if (user && (history.location.pathname === "/login" || history.location.pathname === "/register")) 
        //   window.location = "/"

        //   else
        //     history.push('/login')
        // } catch (ex) {
        //     history.replace('/login')
        //  }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.token !== this.props.token) {
            if (this.props.token) {
                axios.defaults.headers.common['authorization'] = "Bearer " + this.props.token;
            }
            if (this.props.token && !this.props.authUser) {
                this.props.getUser()
            }
        }
    
           // if (prevProps.location.pathname !== this.props.location.pathname) {
                console.log('rroww1',this.props)
                if(this.props?.location?.pathname.includes('/board/')){
                    if(this.props.location.state.groupIndex){
                        if(this.props.location.state.isChatNotification){
                            
                            //console.log("this.props.location.state",this.props.location.state)
                            this.props.navigateToBoardFromNotification({
                                id: this.props.location.pathname.replace("/board/", ""),
                                groupIndex: this.props.location.state.groupIndex,
                                rowIndex: this.props.location.state.rowIndex,
                                messageID:this.props.location.state.messageID,
                                isChatNotification:true
                            })
                            document.getElementById("globalSearchNav").style.width = "0%"
                        }else{
                            console.log("this.props.location.state",this.props.location.state)
                            this.props.navigateToBoardFromNotification({
                                id: this.props.location.pathname.replace("/board/", ""),
                                groupIndex: this.props.location.state.groupIndex,
                                rowIndex: this.props.location.state.rowIndex
                            })
                        }
                        
                    }else{
                        
                        this.props.changeWorkspaceBoard({
                            id: this.props.location.pathname.replace("/board/", ""),
                            workspaceID: this.props.location.state.workspaceID,
                            workspace: this.props.location.state.workspace
                        })
                    }
                
            }
            //}
        
    }
    render() {
        const { user } = this.state;
        const { match, location, token, authUser, initURL } = this.props;
        if (location.pathname === '/') {
            if (token === null) {
                console.error("++++++++++ TOKEN IS NULL SO REDIRET TO -> /landing +++++++++++");
                return (<Redirect to={'/home'} />);
            }
            else if (initURL === '' || initURL === '/' || initURL === '/login') {
                console.error("++++++++++ TOKEN EXIST AND INITURL IS EMPLY SO REDIRET TO -> /pr/ +++++++++++");
                return (<Redirect to={'/dashboard'} />);
            }
            else if (initURL !== '' || initURL !== '/' || initURL !== '/login') {
                console.error("++++++++++ TOKEN & INITURL EXIST SO REDIRET TO -> initURL  +++++++++++");
                return (<Redirect to={initURL} />);
            }
        }
        return (
            <>
                <Switch>
                    <Redirect exact path="/" to="/home" />
                    <Route exact path="/home" component={Home} />
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/register" component={Register} />
                    <RestrictedRoute exact path="/profileuser" token={token} component={ProfileUser} />
                    <RestrictedRoute exact path="/dashboard" token={token} component={ResponsiveLocalStorageLayout} />

                    <RestrictedRoute exact path="/board/:board" token={token} component={Workspace} />
                    <RestrictedRoute exact path="/board" token={token} component={Workspace} />
                    
                    <RestrictedRoute exact path="/globalsettings" token={token} component={GlobalSettings} />
                    <RestrictedRoute exact path="/scheduler" token={token} component={EngageScheduler} />
                    <RestrictedRoute exact path="/discover" token={token} component={Discover} />
                    <Route exact path="/logout" component={withRouter(Logout)} />
                    <RestrictedRoute exact path="/dashboard-setting" token={token} component={DashBoardSetting} />
                    <RestrictedRoute exact path="/general-search" token={token} component={GeneralSearch} />
                    <RestrictedRoute exact path="/dashboard-charts" token={token} component={DashBoardCharts} />
                    <RestrictedRoute exact path="/discover-workspace" token={token} component={DiscoverWorkspace} />                  
                    <RestrictedRoute exact path="/discover-subworkspace" token={token} component={DiscoverSubWorkspace} />
                    <RestrictedRoute exact path="/discover-subworkspace-l2" token={token} component={DiscoverSubWorkspaceL2} />
                    <RestrictedRoute exact path="/discover-board" token={token} component={DiscoverBoard} />
                    <RestrictedRoute exact path="/discover-subboard" token={token} component={DiscoverSubBoard} />
                    <RestrictedRoute exact path="/discover-group" token={token} component={DiscoverGroup} />
                    <RestrictedRoute exact path="/messenger" token={token} component={Messenger} />
                    <RestrictedRoute exact path="/addactivity" token={token} component={AddActivity} />
                    <RestrictedRoute exact path="/company" token={token} component={Company} />
                    <RestrictedRoute exact path="/planmaster" token={token} component={PlanMaster} />
                    <RestrictedRoute exact path="/planapproval" token={token} component={PlanApproval} />
                    <RestrictedRoute exact path="/module" token={token} component={Module} />
                    <RestrictedRoute exact path="/submodule" token={token} component={SubModule} />
                    <RestrictedRoute exact path="/BusinessCategory" token={token} component={BusinessCategory} />

                    {/* <ProtectedRoute exact path="/BusinessCategory" component={BusinessCategory} /> */}

                    <RestrictedRoute exact path="/Department" token={token} component={Department} />
                    <RestrictedRoute exact path="/country" token={token} component={Country} />
                    <RestrictedRoute exact path="/city" token={token} component={City} />
                    <RestrictedRoute exact path="/state" token={token} component={State} />
                    <RestrictedRoute exact path="/rolemaster" token={token} component={RoleMaster} />
                    <RestrictedRoute exact path="/userlist" token={token} component={UserList} />
                    <RestrictedRoute exact path="/userleveltype" token={token} component={UserLevelType} />
                    <RestrictedRoute exact path="/forms" token={token} component={Forms} />
                    <RestrictedRoute exact path="/currency" token={token} component={Currency} />
                    <RestrictedRoute exact path="/visibility" token={token} component={Visibility} />
                    <RestrictedRoute exact path="/entitytype" token={token} component={EntityType} />
                    <RestrictedRoute exact path="/license" token={token} component={License} />
                    <RestrictedRoute exact path="/defaultmenu" token={token} component={DefaultMenu} />
                    <RestrictedRoute exact path="/usermenulist" token={token} component={UserMenuList} />
                    <RestrictedRoute exact path="/tasks" token={token} component={AllTasks} />
                    <RestrictedRoute exact path="/econnect" token={token} component={Econnect} />
                    <RestrictedRoute exact path="/assigned" token={token} component={Assigned} />
                    <RestrictedRoute exact path="/completed" token={token} component={Completed} />
                    <RestrictedRoute exact path="/unread" token={token} component={UnreadTask} />
                    <RestrictedRoute exact path="/ongoing" token={token} component={OnGoing} />
                    <RestrictedRoute exact path="/inbox" token={token} component={Inbox} />
                    <RestrictedRoute exact path="/stickymenu" token={token} component={StickyMenu} />
                    <RestrictedRoute exact path="/faq" token={token} component={FAQ} />
                    <RestrictedRoute exact path="/userprofile" token={token} component={UserProfile} />
                    <RestrictedRoute exact path="/draggable-board" token={token} component={ResponsiveLocalStorageLayout} />
                    <RestrictedRoute exact path="/search" token={token} component={searchGeneral} />
                    <Route exact path="/invitation/:rememberToken" component={CheckInvitation} />
                    <Route component={NotFound} />
                </Switch>
                <ToastrComponent />
                {localStorage.authUser&&<GlobalSearch/>}
            </>

        );
    }
}
const mapStateToProps = ({ auth }) => {
    const { authUser, token, initURL } = auth;
    return { token, authUser, initURL }
};
const mapDispatchToProps = { setInitUrl, getUser,changeWorkspaceBoard,navigateToBoardFromNotification };
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));