import React from 'react';
import ContentEditable from 'react-contenteditable';
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip';

class DropdownItemEdit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            content: this.props.label.name
        }
        this.timeout = 0;

        this.editContainer = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.label.name !== this.props.label.name) {
            this.setState({ content: this.props.label.name })
        }
    }

    onChangeTextItem = (event) => {
        this.setState({ content: event.target.value })
    }

    onKeyUp = (event) => {
        if (this.state.content !== "") {
            if (this.timeout) clearTimeout(this.timeout);

            this.timeout = setTimeout(() => {
                this.props.updateLabel(this.state.content);
            }, 800);
        }
    }

    removeSuggestionLabel = () => {
        this.props.removeLabel();
    }

    checkLabels = (label) => {
        let boardGroups = this.props.boardData.data;
        for (let i = 0; i < boardGroups.length; i++) {
            let groupItems = boardGroups[i].groupItems;
            for (let j = 0; j < groupItems.length; j++) {
                if (groupItems[j].category === 'body') {
                    let cellItems = groupItems[j].cellItems;
                    for (let k = 0; k < cellItems.length; k++) {
                        if (cellItems[k].key === 'dropdown') {
                            // console.log("cellItems[k]", cellItems[k].body)
                            let labels = JSON.parse(cellItems[k].body);
                            // console.log("labels===>", labels)
                            for (let h = 0; h < labels.length; h++) {
                                // console.log("labels===>, ", labels);
                                // console.log("label===>", label)
                                if (labels[h]._id === label._id) {
                                    return true;
                                }
                            }
                        }


                    }
                }

            }
        }

        return false;

    }

    render() {
        // console.log("label edit props label==>", this.props.label);
        let check = this.checkLabels(this.props.label);
        // console.log("check===>", check)
        return (
            <div className="d-flex p-2">
                <ContentEditable
                    className="board-name dropdown-label-edit-text"
                    innerRef={this.itemTextEditableRef}
                    html={this.state.content}
                    onChange={this.onChangeTextItem}
                    onKeyUp={this.onKeyUp}
                    style={{ width: '80%', fontSize: '16px', display: 'flex', alignItems: 'center' }}
                    tagName='div'
                />
                {check ? (
                    <div className="single-box-item-component item-token can-change-content">
                        <div className="clear-button" data-tip="Can't delete in use">
                            <i className="fa fa-close"></i>
                        </div>
                        <ReactTooltip />
                    </div>
                ) : (
                        <div className="single-box-item-component item-token can-change-content">
                            <div className="clear-button" data-tip="Delete a label" onClick={() => this.removeSuggestionLabel()}>
                                <i className="fa fa-close"></i>
                            </div>
                            <ReactTooltip />
                        </div>
                    )}

            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(DropdownItemEdit)