import React from 'react';
import { connect } from 'react-redux'
import Select from 'react-select';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import axios from '../../../../../util/Api';
import { fetchError, showMessage, changeWorkspaceBoard } from '../../../../../actions'

const options = [
    { value: 'structure', label: 'Structure Only' },
    { value: 'withItems', label: 'Structure & Items' },
];

class BoardDuplicate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            duplicateType: null,
            useMembers: false
        }

        this.modalContainer = React.createRef();
    }

    duplicateBoard = () => {
        if (this.state.duplicateType !== null) {
            this.props.closeModal()
            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardTitle = `Duplicate of ${workspaceBoard.title}`;
            let description = workspaceBoard.description;
            let members = JSON.parse(workspaceBoard.members);
            let user = JSON.parse(localStorage.getItem('authUser'));
            let member = members.find(member => member._id === user._id);
            let newMembers = this.useMembers ? workspaceBoard.members : JSON.stringify([member]);
            let newGroups = [];
            
            if (this.state.duplicateType.value === 'structure') {
                let groups = this.props.boardData.data;

                for (let i = 0; i < groups.length; i++) {
                    let groupItems = groups[i].groupItems;
                    let newGroupItems = [groupItems[0]]
                    let group = {
                        _id: groups[i]._id,
                        label: groups[i].label,
                        groupItems: newGroupItems
                    }
                    newGroups.push(group);
                }
            } else {
                newGroups = this.props.boardData.data
            }

            let duplicatedWorkspaceBoard = {
                title: boardTitle,
                description: description,
                members: newMembers,
                group: JSON.stringify(newGroups),
                columnHeaders: workspaceBoard.columnHeaders
            }

            axios.post('/workspace/duplicateBoard', {
                boardId: this.props.boardData.boardID,
                duplicatedData: duplicatedWorkspaceBoard,
                workspaceId: this.props.boardData.workspaceID
            }).then(({ data }) => {
                this.props.showMessage(`Duplicated the ${this.props.boardData.boardTitle}`);
                this.props.changeWorkspaceBoard({
                    id: data.boardId,
                    workspaceID: data.workspaceId
                })
            }).catch(err => {
                this.props.fetchError(err)
            })
        }
    }

    handleChange = (duplicateType) => {
        this.setState({ duplicateType: duplicateType });
    };

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.checked;
        this.setState({ useMembers: value })
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.open} ref={this.modalContainer}>
                    <ModalHeader toggle={() => this.props.closeModal()}>
                        Duplicate {this.props.boardData.boardTitle}
                    </ModalHeader>
                    <ModalBody>
                        <div className='p-3'>
                            <div className='w-100'>
                                <h5>Select what to duplicate</h5>
                                <div className='w-100'>
                                    <Select
                                        value={this.state.duplicateType}
                                        onChange={this.handleChange}
                                        options={options}
                                    />
                                </div>
                                <div className='form-group d-flex align-items-center justfy-content-center mt-2'>
                                    <input
                                        className='form-control'
                                        style={{ width: '20px', height: '20px' }}
                                        id='addMembers'
                                        type="checkbox"
                                        checked={this.state.useMembers}
                                        onChange={this.handleInputChange} />
                                    <label className='form-control-lobel' htmlFor='addMembers' style={{ fontSize: '16px', color: '#000000' }}>Keep board subscribers</label>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-light" onClick={() => this.props.closeModal()}>Cancel</button>
                        <button className='btn btn-primary' onClick={() => this.duplicateBoard()}>Duplicate</button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

const mapDispatchToProps = { fetchError, showMessage, changeWorkspaceBoard }

export default connect(mapStateToProps, mapDispatchToProps)(BoardDuplicate)