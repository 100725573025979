import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';

class StatusBottom extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            totalCount: 0,
            allLabel: "option1",
            open: false
        }

        this.dropdownContainer = React.createRef();
    }

    onClickOutsideHandler = (ev) => {
        if (this.state.open && !this.dropdownContainer.current.contains(ev.target)) {
            this.setState({ open: false });
        }
    }
    componentDidMount() {
        window.addEventListener('click', this.onClickOutsideHandler);
    }
    componentWillUnmount() {
        window.removeEventListener('click', this.onClickOutsideHandler);
    }

    getGroupStatus = (groupItems, allLabel) => {
        let totalCount = groupItems.length - 1;
        let items = {};
        let cellItemIndex = groupItems[0].cellItems.findIndex(cell => cell._id === this.props.id);
        for (let i = 0; i < groupItems.length; i++) {
            let groupItem = groupItems[i];
            if (groupItem.category !== 'header') {
                let cellItem = groupItem.cellItems[cellItemIndex];
                items[cellItem.body] = (items[cellItem.body] || 0) + 1;
            }
        }

        let newItems = [];
        let keys = Object.keys(items);
        let cellSuggestions = this.props.boardData.cellSuggestions;
        let statusSuggestion = cellSuggestions.find(x => x._id === this.props.columnId);
        let suggestions = statusSuggestion.suggestions;
        for (let i = 0; i < keys.length; i++) {
            let cellStatus = suggestions.find(cell => cell.type === keys[i]);
            let backgroundColor;
            // console.log("cellStatus", cellStatus)
            if (keys[i] === "") {
                backgroundColor = "#c4c4c4";
            } else {
                if (cellStatus !== undefined) {
                    backgroundColor = cellStatus.color
                } else {
                    backgroundColor = "#c4c4c4";
                }

            }

            let item = {
                title: keys[i],
                count: items[keys[i]],
                backgroundColor: backgroundColor,
                percent: parseFloat((parseInt(items[keys[i]]) / totalCount) * 100).toFixed(2)
            }

            if (allLabel === "option1") {
                newItems.push(item)
            } else {
                if (item.title === "" || item.title === 'Done') {
                    newItems.push(item)
                }
            }
        }
        newItems.sort((a, b) => {
            if (a.title === "") {
                return 1
            } else {
                return -1;
            }
        })
        return newItems;
    }

    changeOptions = (ev) => {
        // console.log("value===>", ev.target.value);
        this.setState({ allLabel: ev.target.value })
    }

    render() {
        let statusColumns = this.getGroupStatus(this.props.groupItems, this.state.allLabel);
        // console.log("statusColumns===>", statusColumns)
        return (
            <div className="status-bottom p-1" ref={this.dropdownContainer} style={{ position: 'relative' }}>
                <div className="bottom-content" onClick={() => this.setState({ open: !this.state.open })}>
                    {statusColumns.map((item, index) => (
                        <div className="status-item" key={index} style={{ backgroundColor: item.backgroundColor, width: item.percent + '%', height: '100%' }}
                            data-title={`${item.title} ${item.count}/${this.props.groupItems.length - 1} ${item.percent}%`}
                        // data-tip={`${item.title} ${item.count}/${this.props.groupItems.length} ${item.percent}%`}
                        >
                            <div style={{ width: '100%', height: '30px' }}></div>
                        </div>
                    ))}
                    {/* <ReactTooltip place="top" /> */}
                </div>
                {this.state.open ?
                    (
                        <div className="p-2 status-bottom-dropdown">
                            <ul className={`dropdown-menu multi-level animate slideIn ${this.state.open ? 'show' : ''}`} role="menu"  >
                                <li>
                                    <div className="radio-field">
                                        <input type="radio" value="option1"
                                            id="status-option1"
                                            checked={this.state.allLabel === 'option1'}
                                            onChange={this.changeOptions} />
                                        <label htmlFor="status-option1">All Labels</label>
                                    </div>
                                </li>
                                <li>
                                    <div className="radio-field">
                                        <input type="radio" value="option2"
                                            id="status-option2"
                                            checked={this.state.allLabel === 'option2'}
                                            onChange={this.changeOptions} />
                                        <label htmlFor="status-option2"> What's Done</label>
                                    </div>
                                </li>
                            </ul>
                        </div>

                    ) : ''}

            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData }
}

export default connect(mapStateToProps)(StatusBottom);
