import React from 'react';
import '../../style/sidebar.css'

class WorkspaceSearch extends React.Component {
    constructor(props) {
        super(props);
    }

    onKeyUp = (event) => {
        this.props.onKeyUp( event.target.value);

    }

    render() {
        return (
            <div>
                <div>
                    <div className="search-outer">
                        {/* <form
                            role="search"
                            method="get"
                            id="searchform"
                            className="searchform"
                            action=""
                            autocomplete="off"
                        >
                            <i className="bx bx-search search-icon-style" style={{ top: '110px', left: '15px' }}></i>
                            <input
                                type="text"
                                className='search-category'
                                onChange={this.onKeyUp}
                                name="search"
                                id="category_search"
                                placeholder="Search Here . . ."
                                style={{ paddingLeft: '30px' , 'width' : '97%' , 'margin-top' : '12px' }}
                            />
                        </form> */}


                        <form role="search" method="get" id="searchform" className="searchform" action autoComplete="off">
                                     <img src="./images/icon/header-icons/search.png" alt="" className="search-icon-style" style={{width: '16px', height: '16px' ,     'position': 'relative' ,
    'top': '21px' ,
    'left': '10px'  }} />
                                     <input type="text" className="search-category" name="search" id="category_search" placeholder="Search Here . . ." style={{paddingLeft: '30px', width: '97%'}} />
                        </form>



                        

                      
                    </div>

                    <ul className="data-list">
                        {/* {this.state.task.map((item, index) => (
                            <li className={"block-" + index}>
                                <a className="title" href={item.link}>
                                    <h6>{item.Name}</h6>

                                </a>
                                <a className="link" href={item.link}>

                                </a>
                            </li>
                        ))} */}
                    </ul>
                </div>
            </div>
        )
    }
}

export default WorkspaceSearch;