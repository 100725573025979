const initialState = {
    tags: [],
    files:[],
    isopened: false,
    tabposition: -1,
    classname: 'nav-link',
    tabcontentclass: 'tab-pane',
    search: '',
    userdetails: JSON.parse(localStorage.authUser ? localStorage.authUser : null),
    savedsearch: [],
    savedfound: null,
    recentsearch: [],
    data: '',
    boarddata: '',
    storeData: '',
    boardID: '',
    workspaceID: '',
    workspaceBoard: '',
    boardTitle: 'Board Title',
    boardDescription: 'Add description',
    numbersProperties: [],
    cellSuggestions: [],
    people:[]

}
export default (state = initialState, action) => {
    switch (action.type) {
        case "ONSEARCHCHANGE": {
            
            return {
                ...state,
                search: action.payload.value,
                savedfound: action.payload.savedfound
            }
        }
        case "ONSEARCHFOUND": {
            return {
                ...state,
                data: action.payload.data
            }
        }
        case "ONTABCHANGE": {
            return {
                ...state,
                tabposition: action.payload.value,
                classname: action.payload.classname,
                tabcontentclass: action.payload.tabcontentclass,
                savedfound: null
            }
        }
        case "ONFOCUSSEARCH": {
            return {
                ...state,
                savedfound: action.payload
            }
        }
        case "ONSAVESEARCH": {
            return {
                ...state,
                savedsearch: [...state.savedsearch, action.payload],
                savedfound: true
            }
        }
        case "GETSAVEDSEARCH": {
            return {
                ...state,
                savedsearch: action.payload
            }
        }
        case "ONBLURSEARCH": {
            return {
                ...state,
                recentsearch: action.payload
            }
        }
        case "GETRECENTSEARCH": {
            return {
                ...state,
                recentsearch: action.payload
            }
        }
        case "ONTAGCLICK": {
            return {
                ...state,
                tabposition: action.payload.tabposition,
                search: action.payload.search,
                savedfound: null,
                classname: action.payload.classname,
                tabcontentclass: action.payload.tabcontentclass
            }
        }
        case "ONSAVEDSEARCHDELETE": {
            return {
                ...state,
                savedsearch: action.payload
            }
        }
        case "GETHOTTAGS": {
            return {
                ...state,
                tags: action.payload
            }
        }
        case "GETFILES": {
            return {
                ...state,
                files: action.payload
            }
        }
        case "GETPEOPLE": {
            return {
                ...state,
                people: action.payload
            }
        }
        case "ONBOARDDATACHANGE": {
            
            return {
                ...state,
                boarddata: JSON.parse(action.payload.data.group),
                storeData: JSON.parse(action.payload.data.group),
                boardID: action.payload.data._id,
                boardTitle: action.payload.data.title,
                boardDescription: action.payload.data.description,
                cellSuggestions: action.payload.data.columnHeaders,
                // workspaceID: action.payload.workspaceID,
                //workspace: action.payload.workspace,
                workspaceBoard: action.payload.data
            }
        }
        default:
            return state;
    }
}