import React from 'react';
import { connect } from 'react-redux';
import { addGroup, addRow, fetchError } from '../../../../../actions';
import { initialWorkspaceBoardGroupData } from '../../../../../constants/Dump';
import { initialGroupCellItemBody, makeid } from '../../../../../util/index';
import { getMyPermission } from '../../../../../util/permission';

class NewItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            terminology: 'Item'
        }
    }

    componentDidMount() {
        let workspaceBoard = this.props.boardData.workspaceBoard;
        if (workspaceBoard !== '') {
            let terminology = workspaceBoard.terminology || 'Item';
            this.setState({ terminology: terminology })
        }

        if (this.props.boardData.boardID !== '') {
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' && permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentDidUpdate(prevProps, prevStates) {
        if (prevProps.boardData.workspaceBoard.boardType && prevProps.boardData.workspaceBoard.boardType !== this.props.boardData.workspaceBoard.boardType) {
            this.setState({ boardType: this.props.boardData.workspaceBoard.boardType })
        }

        if (this.props.boardData.boardID !== '' && this.props.boardData.workspaceBoard.permission !== prevProps.boardData.workspaceBoard.permission) {

            let workspaceBoard = this.props.boardData.workspaceBoard;
            let boardPermission = workspaceBoard.permission;
            let members = JSON.parse(workspaceBoard.members);
            let permission = getMyPermission(boardPermission, members);
            if (permission === 'super' && permission === 'high') {
                this.setState({ actionPermission: 'edit' })
            } else {
                this.setState({ actionPermission: 'view' })
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.boardData.workspaceBoard.terminology && nextProps.boardData.workspaceBoard.terminology !== this.props.boardData.workspaceBoard.terminology) {
            let terminology = nextProps.boardData.workspaceBoard.terminology;
            this.setState({ terminology: terminology })
        }
    }

    createNewGroup = () => {
        console.log("test")
        const boardID = this.props.boardData.boardID;
        if (boardID !== '') {
            const group = this.props.boardData.data;
            let firstGroup;
            if (group.length > 0) {
                firstGroup = group[0];
            } else {
                firstGroup = initialWorkspaceBoardGroupData[0];
            }

            const newGroupItemHeader = firstGroup.groupItems[0];

            const groupItemHeaderCellItems = [];
            const groupItemBodyCellITems = [];
            for (let i = 0; i < newGroupItemHeader.cellItems.length; i++) {
                let body = initialGroupCellItemBody(newGroupItemHeader.cellItems[i].key);
                console.log("body===>", body);
                let newCellHeader = { _id: makeid('col', 7), key: newGroupItemHeader.cellItems[i].key, body: newGroupItemHeader.cellItems[i].body, columnId: newGroupItemHeader.cellItems[i].columnId }
                let newCellItem = { _id: makeid('col', 7), key: newGroupItemHeader.cellItems[i].key, body: body, columnId: newGroupItemHeader.cellItems[i].columnId };
                groupItemHeaderCellItems.push(newCellHeader);
                groupItemBodyCellITems.push(newCellItem);
            }

            const newGroup = {
                _id: makeid('group', 3),
                label: 'New Group',
                groupItems: [{
                    _id: makeid('row', 5),
                    title: "New Item",
                    category: "header",
                    cellItems: groupItemHeaderCellItems
                }, {
                    _id: makeid('row', 5),
                    title: "New Item",
                    category: 'body',
                    cellItems: groupItemBodyCellITems
                }]
            }

            console.log("newGroup==>", newGroup);
            console.log("boardID==>", boardID)
            this.props.addGroup({
                boardID: boardID,
                newGroup: newGroup
            })
        }
    }

    addGroupRecord = () => {
        if (this.props.boardData.boardID !== '') {
            let groups = this.props.boardData.data;
            if (groups.length > 0) {
                let firstGroup = groups[0];
                const groupItems = firstGroup.groupItems;
                const newGroupItemHeader = firstGroup.groupItems[0];
                const groupItemBodyCellITems = [];

                for (let i = 0; i < newGroupItemHeader.cellItems.length; i++) {
                    let body = initialGroupCellItemBody(newGroupItemHeader.cellItems[i].key);
                    console.log("body===>", body);
                    let newCellItem = { _id: makeid('col', 7), key: newGroupItemHeader.cellItems[i].key, body: body, columnId: newGroupItemHeader.cellItems[i].columnId };
                    groupItemBodyCellITems.push(newCellItem);
                }

                let newGroupItem = {
                    _id: makeid('row', 5),
                    title: `New ${this.state.terminology}`,
                    category: 'body',
                    cellItems: groupItemBodyCellITems
                }

                this.props.addRow({
                    boardId: this.props.boardData.boardID,
                    groupID: firstGroup._id,
                    newGroupItem: newGroupItem,
                    groupInd: 0
                })
            }
        }
    }

    render() {
        return (
            <div className="btn-group newitem-btn" style={{ position: "relative" }}>
                <button
                    type="button"
                    className="btn btn-danger"
                    style={{
                        minWidth: "100px",
                        height: "30px",
                        borderBottomLeftRadius: "6px",
                        borderTopLeftRadius: "6px",
                        marginTop: "8px",
                        fontSize: "12.5px",
                        fontWeight: "bold",
                        padding: 0,
                    }}
                    onClick={() => {
                        if (this.props.actionPermission === 'edit') {
                            this.addGroupRecord()
                        } else {
                            this.addGroupRecord()
                           // this.props.fetchError("You don't have permission. Only you can view!")
                        }
                    }}
                >
                    New {this.state.terminology}
                </button>
                <button
                    type="button"
                    className="btn btn-danger dropdown-toggle px-2"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{
                        height: "30px",
                        borderBottomRightRadius: "6px",
                        borderTopRightRadius: "6px",
                        marginTop: "8px",
                        borderLeft: "1px solid #EAEAEA !important"
                    }}
                >
                    <span className="sr-only">Toggle Dropdown</span>
                </button>
                <div
                    className="dropdown-menu animate SlideIn"
                    style={{ marginLeft: "100px" }}
                >
                    <button
                        className="dropdown-item"
                        style={{ textTransform: "none" }}

                        onClick={() => {
                            if (this.props.actionPermission === 'edit') {
                                this.createNewGroup()
                            } else {
                                this.createNewGroup()
                               // this.props.fetchError("You don't have permission. Only you can view!")
                            }
                        }}
                    >
                        <i class="fal fa-user-friends" style={{ 'color' : '#000' }}></i> <span style={{ 'color' : '#000' , marginLeft : '10px' }}>New Group of Items</span>
                    </button>
                    {/* <a
                        className="dropdown-item"
                        href="#"
                        style={{ "text-transform": "none" }}
                        >
                        <i className="bx bx-import"></i>Import Items
                        </a> */}
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ boardData }) => {
    return { boardData };
};

const mapDispatchToProps = { addGroup, addRow, fetchError };

export default connect(mapStateToProps, mapDispatchToProps)(NewItem);