import React, { Component } from 'react';
import Select from 'react-select';
import { jsPDF } from 'jspdf';
import  html2canvas from 'html2canvas';
import MainMenu from '../../../shared/components/main-menu';
import NavBar from '../../../shared/components/navbar/navbar';
import Notification from '../../notification/notification';
import ProfileForm from './profile-form.jsx';
import axios from '../../../util/Api.js';
import config from '../../services/config.json';

var Group_options = ["Profile Details", "National Ids", "Asset Management", "Education & Qualification Details", "Additional Details"];
class ProfileUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageURL: '../images/Profile.png',
      percentage: '0%',
      selectedGroupOption: null
    }

    this.authUser = JSON.parse(localStorage.getItem('authUser'));
    this.handleUploadImage = this.handleUploadImage.bind(this);
   
}
handler = (val) => {
  // console.log('handler==>', val)
  // console.log('html width==>', document.querySelector("#appdata").offsetWidth);
  //   console.log('html height==>', document.querySelector("#appdata").offsetHeight);
  if(val!==null){
  this.setState({imageURL: config.baseUrlEndPoint+val})
  }
}
percentage = (val) => {
  // console.log('percentage==>', val)
  if(val!==null){
  this.setState({percentage: val+'%', number: val})
  }
}
handleUploadImage(ev) {
  ev.preventDefault();

  const data = new FormData();
  data.append('file', this.uploadInput.files[0]);
  data.append('filename', 'random');
  axios.post('/users/saveProfilePic', 
    data
  ).then(({ data }) => {
      this.setState({ imageURL: config.baseUrlEndPoint+data.file });
  }).catch(err => {
    console.log("Error: ", err)
  })
}

fileupload = () => {
  document.getElementById('upload').click(); 
  return false
}
handleRemoveImage(ev) {
  ev.preventDefault();

  axios.put('/users/updateProfilePic'
  ).then(({ data }) => {
      this.setState({ imageURL: '../images/Profile.png' });
  }).catch(err => {
    console.log("Error: ", err)
  })
}
 
toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
  } else {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }
}

reload = () => {
  window.location.reload();
  return false; 
}

downloadPDF = () => {
  this.setState({ pdf: true})  
};
handleChange_Group = selectedGroupOption => {
  this.setState({ pdf: false}) 
  this.resetSelectGroupDropDown();
  this.setState(
    { selectedGroupOption },
    () => console.log(`selectedGroupOption selected:`, this.state.selectedGroupOption)
  );
  
}

async resetSelectGroupDropDown() {
  this.setState({ Group: "" })
  this.setState({
    selectedGroupOption: null
  })
}
  render() {
    const { selectedGroupOption, selectedGroupError } = this.state;
    return (
      <>
        <MainMenu link={`profileuser`} />
        <div id="whole-layout">
          <NavBar />
          <div id="appdata" className='app-content content fixed-top content-lay-out'>
            <div style={{ position: 'absolute', marginTop: '-200px' }}><Notification /></div>
            <div className="content-wrapper">
              <div id="mySidenav" className="sidenav">
                <a href="#" className="closebtn" onClick={() => {
                  document.getElementById("mySidenav").style.width = "0%";
                }}>&times;</a>
              </div>
              <div className="row dash-blue fixed-top">               
                <div className="col-xl-12 col-sm-12 col-md-3 col-lg-3 col-12" id="dashboard-layout">
                  <div>
                    <div className="inner-section">
                      <h3>Profile User Management</h3>
                      <div style={{ marginTop: '-10px' }}>
                        <p className="mt-1"> </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-9 col-md-8 col-sm-12 col-lg-8 col-12 dashboard-greetings" id="second-div-dashboard">
                  <table className="align-items-center table-newdashboard-section-scheduler-one">
                    <tbody><tr>
                      <td rowSpan={2} style={{ width: '50px' }}>
                        <ul className="nav navbar-nav bookmark-icons">
                          <li className="nav-item d-none d-lg-block nav-search" data-placement="top">
                            <a className="nav-link nav-link-search cursor-pointer" data-toggle="dropdown" aria-expanded="false" title="Search">
                              <img src="./images/icon/dashboard-icons/scheduler-icons/search copy.png" style={{ 'width' : '25px' , 'height' : '25px' }} />
                            </a>
                            <div className="dropdown-menu text-color w-xl animated fadeInUp" style={{ right: 'auto', width: '240px' }}>
                              {/* <form className="navbar-form form-inline navbar-item m-a-0 p-x v-m ng-pristine ng-valid" role="search"> */}
                                {/* <div className="form-group l-h m-a-0">
                                  <div className="input-group"> */}
                                    {/* <input type="text" className="form-control" placeholder="Search Group"

                                      id="search-box" /> */}
                                      <Select placeholder={''}  options={Group_options.map(option => ({ value: option, label: option }))} onChange={this.handleChange_Group}  />
                                    {/* <span className="input-group-btn">
                                      <button type="submit" className="btn blue-A200 b-a no-shadow" id="tbSearch">
                                        <i className="bx bx-search"></i>
                                      </button>
                                    </span> */}
                                  {/* </div>
                                </div> */}
                              {/* </form> */}
                            </div>
                          </li>
                        </ul></td>
                      <td rowSpan={2} style={{ width: '50px' }}><a href="" data-action="expand" ><img src="./images/icon/dashboard-icons/scheduler-icons/refresh copy.png"  style={{ cursor: 'pointer' , 'width' : '25px' , 'height' : '25px' }} onClick={()=>this.reload()} /></a></td>
                      <td rowSpan={2} style={{ width: '50px' }}><a href="" data-action="expand" onClick={()=>this.toggleFullScreen()}>
                        <img
                          src='/images/icon/dashboard-icons/scheduler-icons/zoomin copy.png'
                       style={{ cursor: 'pointer' , 'width' : '25px' , 'height' : '25px' }} /></a></td>

                      <td rowSpan={2} style={{ width: '50px' }}><a ><img src="/images/icon/dashboard-icons/module-icons/white icons-38.png"  style={{ cursor: 'pointer' , 'width' : '25px' , 'height' : '25px' }} onClick={()=>this.downloadPDF()}/></a></td>


                      <td rowSpan={2} style={{ width: '50px' }}><a href="mailto:?subject=My Profile&amp;body=Check out my profile attached" data-toggle="tooltip" data-target="Filter"  ><img src="./images/icon/dashboard-icons/module-icons/share-white.png"  style={{ cursor: 'pointer' , 'width' : '25px' , 'height' : '25px' }} /></a></td>

                      <td>

                      </td>


                    </tr>
                      <tr style={{ fontWeight: 600 }}>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                      </tr>
                    </tbody></table>

                </div>
              </div>
              <div className="content-wrapper">
        <div class="row">
            <div class="col-md-12 user-profile-banner">
              <div class="user-profile-layout">

                  <div class="user-profile-avatar">
                  
                    
<div class="c-dropdown dropdown">
    <div class="c-avatar has-dropdown dropdown-toggle" id="dropdownMenuAvatar" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
       <img class="profile-av" src={this.state.imageURL} alt="Name" /> 
                <div class="user-profile-edit">
                        <div id="user-profile-click">
                          <i class="bx bx-user-plus" style={{ 'font-size': '40px' ,  'color':' #ffffff' }}></i><br/>
                          <span style={{ 'color': '#ffffff' }}>Change Profile Picture</span>
                        </div>

                      </div>
                 </div>
    <div class="c-dropdown__menu dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuAvatar">
    <input type="file" id="upload" name="upload" ref={(ref) => { this.uploadInput = ref; }} accept="image/*" style={{ 'display' : 'none' }} 
      onChange={(event)=> { 
        this.handleUploadImage(event) 
      }}
      onClick={(event)=> { 
            event.target.value = null
      }}
    />
       <a class="c-dropdown__item dropdown-item" href="#" style={{ 'text-transform' : 'Capitalize' }} onClick={this.fileupload} data-toggle="modal"
              data-target="#avatarmodal" > <i class="bx bxs-image" style={{ 'color':'#e53c8d' , 'margin-right' : '10px' }}></i> Change Picture</a>
       <a class="c-dropdown__item dropdown-item" href="#" style={{ 'text-transform' : 'Capitalize' }} onClick={(event)=>this.handleRemoveImage(event)}> <i class="bx bxs-trash" style={{ 'color':'#e53c8d' , 'margin-right' : '10px' }}></i> Remove Picture</a>
    </div>
</div>

                  </div>
                  <div class="user-profile-head">
                    <h4>{this.authUser.firstname} {this.authUser.lastname}</h4>
                    <span>Admin</span>
                  </div>

                  <div class="user-profile-progress">
                    <span>{Math.floor(Number(this.state.number))} % Completed</span>
                    <div class="progress bar user-progress" style={{ 'height' : '7px' , 'width' : '200px' }}><div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ 'width': this.state.percentage , 'height' : '7px' , 'background-color' : '#e53c8d' }}></div></div>
                  </div>

              </div>
            </div>
        </div>



            <div class="row" style={{ 'padding' : '80px 50px' }}>


            <ProfileForm handler={this.handler} percentage={this.percentage} pdf={this.state.pdf} search={this.state.selectedGroupOption?.value} />
            </div>
    </div>
    </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProfileUser;